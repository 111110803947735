import React,{ useState, useEffect, useRef } from "react";
import { Card,CardBody,CardHeader,Row,Col,Progress} from "reactstrap";
import CountUp from 'react-countup';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import moment from "moment";
import personIcon from "../../assets/images/person-icon.png";
import {timeFromNumber} from "../../helpers/globalFunctions";
import "./style.scss";
import badge1 from "../../assets/images/badge/efficiency.svg";
import badge2 from "../../assets/images/badge/productivity.svg";
import badge3 from "../../assets/images/badge/time.svg";
import badge4 from "../../assets/images/badge/quality.svg";
import badge5 from "../../assets/images/badge/adaptability.svg";


const TVStaffDashboard = (props)=>{
    const { accessToken,code } = props.match.params;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [details, setDetails] = useState({productivity:0});
    const [flowCountTable, setflowCountTable] =useState([]);
    const [assigneeDataTable, setassigneeDataTable] =useState([]);
    const [userImage, SetUserImage] =useState(null);
    const [totStaff,setTotStaff] = useState(null);
    const [counter, setCounter] = useState(1);
    const flow_count_data = {
      columns: [
        {
          label: "Flow",
          field: "item_name",
          sort: "disabled",
          width: 90,
        },
        {
          label: "Tot",
          field: "total",
          sort: "disabled",
          width: 90,
        },
        {
          label: "Cmp",
          field: "completed",
          sort: "disabled",
          width: 90,
        },
        {
          label: "Icmp",
          field: "incomplete",
          sort: "disabled",
          width: 90,
        },
        {
          label: "OD",
          field: "overdue",
          sort: "disabled",
          width: 90,
        },
        {
          label: "OE",
          field: "overeffort",
          sort: "disabled",
          width: 90,
        },
      ],
      rows: flowCountTable,
    };
    const handleTableData = () => {
        axios
          .get(`${API_URL}tv/staff_view?code=`+code, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            let result = res.data.data;
            setTotStaff(res.data.total_staff_count);
            var productivity_count = res.data.productivity_count;
            if(productivity_count>0)
            result.productivity = ((productivity_count/result.tot_tasks)*100).toFixed(2);
            else
            result.productivity = 0;
            if(typeof result.user_image!=="undefined" && result.user_image!="")
            {
              var image = (
                <img src={`${API_URL}uploads/user_images/${result.user_image}`} style={{height:"100px"}}/>
              )
            }
            else
            {
              var image = (
                <img src={personIcon}style={{height:"100px"}}  />
              )
            }
            SetUserImage(image);

            setDetails(result);  
            setassigneeDataTable(res.data.assingee_data);
            res.data.flow_data[res.data.flow_data.length]={index:"",
            item_name:(<><b>Total</b></>),
            total:(<><b>{result.tot_tasks}</b></>),
            completed:(<><b>{result.completed_tasks}</b></>),
            incomplete:(<><b>{(result.incompleted_tasks)}</b></>),
            overdue:(<><b>{result.overdue_tasks}</b></>),
            overeffort:(<><b>{result.overeffort_tasks}</b></>)};
          setflowCountTable(res.data.flow_data);           
          });
      };
      function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          let id = setInterval(() => {
            savedCallback.current();
          }, delay);
          return () => clearInterval(id);
        }, [delay]);
    }
    useEffect(()=>{
      handleTableData();
    },[])
    useInterval(() => {
      handleTableData();
      setCounter(counter + 1);
      if(counter==totStaff)
      {
        window.location.href="/tv-project-dashboard/"+accessToken+"/"+code
      }
    }, 10000);
    return(
        <React.Fragment>
            <div className="page-content tv-page-content">
                <div className="container-fluid">
                {/* <h2 style={{ fontWeight:"bold", textAlign:"center"}}>{details.firstName} - {details.userId} <span className="badge bg-dark">P <i class="fas fa-clock"></i> {details.tot_plan}/200</span> <span className="badge bg-dark">E <i class="fas fa-clock"></i> {Math.round(details.tot_effort)}</span> <span className="badge bg-info">20%</span></h2> */}
                <Row>
                  <Col md="5">
                    <Row>
                    <Col md={4} xl={4} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{counter}{details.tot_modules}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Total</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-primary me-1"}><i className={"fas fa-laptop-code me-1"}></i>{details.tot_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                      <Col md={4} xl={4} style={{ cursor:"pointer"}} >
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{details.completed_modules}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Completed</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-success me-1"}><i className={"fas fa-tasks me-1"}></i>{details.completed_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                      <Col md={4} xl={4} style={{ cursor:"pointer"}} >
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{details.incompleted_modules}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">InCompleted</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span style={{color: "#f1b44c"}} className={"me-1"}><i className={"fas fa-times me-1"}></i>{details.incompleted_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                    <Col md={4} xl={4} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{details.overdue_modules}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Over Due</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span style={{ color: "#2f88d3"}} className={"me-1"}><i className={"fas fa-calendar-alt me-1"}></i>{details.overdue_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                    <Col md={4} xl={4} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{details.overeffort_modules}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Over Effort</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-danger me-1"}><i className={"fas fa-fire me-1"}></i>{details.overeffort_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                    <Col md={4} xl={4} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span>{details.today_tasks}</span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Today</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-info me-1"}><i className={"mdi mdi-arrow-down-bold me-1"}></i>{details.today_completed}</span>{" "}Completed
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                    </Row>
                  </Col>
                  <Col md="7">
                    <Card>
                      <CardBody style={{ padding:"5px"}}>
                        <Row>
                          <Col md="3" style={{ textAlign:"center"}}>
                          {userImage}
                          </Col>
                          <Col md="9">
                            <table id="TVstaffDashboardProfile" style={{width:"100%"}}>
                              <tr>
                                <th colspan="4" style={{textAlign:"center"}}>{details.firstName} {details.lastName} - {details.userId}</th>
                              </tr>
                              <tr>
                                <td style={{fontSize:"16px"}}><b><i className="uil-envelope"></i></b></td><td> {details.email_2} </td>
                                <td style={{fontSize:"16px"}}><b><i className="dripicons-user-group"></i></b></td><td> {details.team_name} </td>

                              </tr>
                              <tr>
                                <td style={{fontSize:"16px"}}><b><i className="dripicons-user-id"></i></b></td><td> {details.designation_name} </td>
                                <td style={{fontSize:"16px"}}><b><i className="mdi mdi-av-timer"></i></b></td><td> {details.working_frm} to {details.working_to}</td>
                              </tr>
                                                         
                              <tr>
                                <td style={{fontSize:"16px"}}><b>P <i className="mdi mdi-alarm-check"></i></b></td><td> <span className="badge bg-primary" style={{fontSize:"12px"}}>{details.tot_plan}/200 hrs</span> </td>
                                <td style={{fontSize:"16px"}}><b>E <i className="mdi mdi-fire"></i></b></td><td> <span className="badge bg-primary" style={{fontSize:"12px"}}>{Math.round(details.tot_effort)} hrs</span> </td>
                              </tr>
                             
                              
                            </table>
                          </Col>
                          
                          
                        </Row>
                        <center>
                        <table width="100%" id="tableBadges">
                            <tr>
                             
                              {
                              details.tot_plan>0?( <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge1} className="badge-image"/><br></br><b>Efficiency</b><br></br><span className="badge-percenatge">{100+((1-(Math.round(details.tot_effort/details.tot_plan)))*100)}%</span></CardBody></Card></td>
                              ):""
                            }
                            <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge2} className="badge-image"/><br></br><b>Productivity</b><br></br><span className="badge-percenatge">{Math.round(details.productivity)}%</span></CardBody></Card></td>
                            {
                                Math.round(details.tot_effort)>=200?(
                                  <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge3} className="badge-image"/><br></br><b>Time Hero</b><br></br><span className="badge-percenatge">{Math.round(((details.tot_effort)/200)*100)}%</span></CardBody></Card></td>
                                ):""
                              }  
                              {/* <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge4} className="badge-image"/><br></br><b>Quality of O/P</b><br></br><span className="badge-percenatge">YES</span></CardBody></Card></td>
                              <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge5} className="badge-image"/><br></br><b>Adaptability</b><br></br><span className="badge-percenatge">NO</span></CardBody></Card></td> */}
                            </tr>
                          </table>
                          </center>

                      </CardBody>
                    </Card>
                  </Col>

                                    <Col md="4">
                                  <Card>
                                    <CardBody>
                                    <MDBDataTable
                                              id="flowCountTableId"
                                              responsive
                                              bordered
                                              data={flow_count_data}
                                              searching={false}
                                              disableRetreatAfterSorting={true}
                                              paging={false}
                                              info={false}
                                            /> 
                                    </CardBody>
                                  </Card>
 
                                </Col>
                                <Col md="8">
                                 

                                 <Card>
                                   <CardBody>
                                   <table>
                                   <tr>
                                     <td><div style={{ padding:"7px",backgroundColor:"#2fa77b54"}}></div></td><td>Completed</td>
                                     <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#40a17d8f"}}></div></td><td>Reported</td>
                                     <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#c5b150c2"}}></div></td><td>Over Effort</td>
                                     <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#b73838b8"}}></div></td><td>Over Due</td>
                                   </tr>
                                   </table>
                                     {assigneeDataTable.length>0?(
                                       <table class="table table-bordered" id="StaffAssignlistTableId">
                                       <thead>
                                         <tr>
                                         <th>Project</th>
                                         <th>Task</th>
                                         <th>Flow</th>
                                         <th>Plan</th>
                                         <th>Effort</th>
                                         <th>%</th>
                                         </tr>
                                       </thead>
                                       <tbody>
                                         {
                                           assigneeDataTable.map((item,index)=>{
                                                 item.id=index+1;
                                                 if(typeof item.assign_time!=="undefined")
                                                 item.assign_time1 = timeFromNumber(item.assign_time);
                                                 else
                                                 item.assign_time1 = "00:00";
                                                 var backgroundColor = "";
                                                 if(item.assign_start_stop==1)
                                                 backgroundColor="#3f51b52e";
                                                 if(typeof item.assign_effort_hr!=="undefined")
                                                 item.assign_effort_hr1 = item.assign_effort_hr.substring(0,item.assign_effort_hr.lastIndexOf(":"));
                                                 else
                                                 item.assign_effort_hr1="00:00";
                                                 if(item.assign_effort_h1r=="")
                                                 item.assign_effort_hr1="00:00";
                                                 var colors="#5b73e8";
                                                 if(item.isovereffort==1)
                                                 {
                                                   colors="#c5b150c2";
                                                 }
                                                 else if(item.isoverdue==1)
                                                 {
                                                   colors="#b73838b8";
                                                 }
                                                 else if(item.assign_complete_status==1)
                                                 {
                                                   colors="#40a17d8f";
                                                 }
                                                 else if(item.iscompleted==1)
                                                 {
                                                   colors="#2fa77b54";
                                                 }
                                                 if(item.assign_complete_percentage>0)
                                                 var padding="7px";
                                                 else
                                                 var padding="0px";
                                             return(
                                               <>
                                               <tr style={{backgroundColor:backgroundColor}}>
                                                <td>{item.project_name}</td>
                                                 <td>{item.task}</td>
                                                 <td>{item.flow}</td>
                                                 <td>{item.assign_time1}</td>
                                                 <td>{item.assign_effort_hr1}</td>
                                                 <td>
                                                 <div class="progress" style={{ height:"14px", border:`${colors} thin solid`}}><div class="progress-bar"  role="progressbar" aria-valuenow={item.assign_complete_percentage} aria-valuemin="0" aria-valuemax="100" style={{width:`${item.assign_complete_percentage}%`,padding:padding, backgroundColor:colors}}>{item.assign_complete_percentage}%</div></div>
                                                 </td> 
                                               </tr>
                                               </>
                                             )
                                           })
                                         }
                                       </tbody>
                                     </table>
                                     ):(
                                       <table class="table table-bordered">
                                       <thead>
                                         <tr>
                                         <th>Task</th>
                                         <th>Flow</th>
                                         <th>Staff</th>
                                         <th>Plan</th>
                                         <th>Effort</th>
                                         </tr>
                                       </thead>
                                       <tbody>
                                        <tr>
                                         <td colSpan={5} align={"center"}>No matching records found</td>
                                       </tr>
                                       </tbody>
                                     </table>
                                     )}
                                   </CardBody>
                                 </Card>
 
                               </Col>
                </Row>
                   
                   
                    
                    
                </div>
            </div>
        </React.Fragment>
    )
}
export default TVStaffDashboard;