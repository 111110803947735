import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import "./Cost_Analysis.scss";

import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

import {
  getDate,
  getFirstday,
  getSalaryPeriodStart,
  getSalaryPeriodEnd
} from "../../helpers/globalFunctions";
import $ from "jquery";

const Cost_Analysis = () => {
 const[details,setDetails]=useState([])

  const[selectedStaff,setSelectedStaff]=useState("")
  const[staff,setStaff]=useState([])
  const[selectedTeam,setSelectedTeam]=useState("")
  const[team,setTeam]=useState([])


  const [searchObject, setSearchObject] = useState({
    from_date: getSalaryPeriodStart(),
    to_date: getSalaryPeriodEnd(),
    user_id:"",
    team:""});

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  
  useEffect(() => {
    
    
    fetch_staff();
    fetch_team();
    handleTableData(getSalaryPeriodStart(), getSalaryPeriodEnd(),"","");
  },[]);
  
  // function fetch_staff(){
  //   axios
  //   .get(`${API_URL}user/options`,{
  //       headers:{
  //           "x-access-token":accessToken,
  //       }
  //   })
  //   .then((res)=>{
  //       var staff =
  //       res.data.data &&
  //       res.data.data.map((el) => {
  //        if(el.lastName)
  //         return {
  //           label: el?.firstName  + " " + el?.lastName ,
  //           value: el?._id,
  //         };
  //         else{
  //           return {
  //             label: el?.firstName ,
  //             value: el?._id,
  //           };
  //         }
  //       });
  
  //   setStaff([{options:staff}])
  //   })
  // }

  const fetch_team = () =>{
    axios
    .get(`${API_URL}teamManage/teamlist`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      var Data =
        res.data.data &&
        res.data.data.map((el) => {
          return {
            label:el.name,
            value:el._id
          };
        });
        setTeam([{options: Data}]);

    
    });
    
  }

  function fetch_staff(team=""){
    axios
    .get(`${API_URL}project_dashboard/staff_options?team=${team}`,{
        headers:{
            "x-access-token":accessToken,
        }
    })
    .then((res)=>{
        var staff =
        res.data.data &&
        res.data.data.map((el) => {
         if(el.lastName)
          return {
            label: el?.firstName  + " " + el?.lastName ,
            value: el?._id,
          };
          else{
            return {
              label: el?.firstName ,
              value: el?._id,
            };
          }
        });
  
    setStaff([{options:staff}])
    })
  }

function handleTableData(from_date,to_date,user_id="",team=""){
  var url = `${API_URL}costanalysis?from_date=${from_date}&to_date=${to_date}&user_id=${user_id}&team=${team}`;
  axios
  .get(url, {
    headers: {
      "x-access-token": accessToken,
    },
  })
  .then((res) => {
    var result = res.data.data;
    // console.log("result",result)
    setDetails(result)
    
  });
}

let handleDate = (e) => {
  
  var from_date = $("#from_date").val();
  var to_date = $("#to_date").val(); 
  // let user_id =searchObject?.user_id ? searchObject.user_id : "";
  // let team =searchObject?.team ? searchObject.team : "";

  let toDate = moment(from_date);
  let fromDate = moment(to_date);
  let result = fromDate.diff(toDate, "days");
  if (result + 1 <= 0) {
    setSearchObject({
      ...searchObject,
      [e.target.name]: e.target.value,
      ["to_date"]: "",
    });
    to_date = "";
  } else {
    setSearchObject({
      ...searchObject,
      [e.target.name]: e.target.value,
    });
  }
  // handleTableData(from_date, to_date, user_id, team);
};

const handleSearch =() =>{
      let from_date =searchObject?.from_date ? searchObject.from_date : "";
      let to_date =searchObject?.to_date ? searchObject.to_date : "";
      let user_id =searchObject?.user_id ? searchObject.user_id : "";
      let team =searchObject?.team ? searchObject.team : "";
      
      handleTableData(from_date,to_date,user_id,team);
}

const resetSearch=() =>{
  setSelectedStaff("");
  setSelectedTeam("");
  setSearchObject({
    from_date: getSalaryPeriodStart(),
    to_date: getSalaryPeriodEnd(),
    user_id:"",
    team:""});
  handleTableData(getSalaryPeriodStart(), getSalaryPeriodEnd(),"","")
  fetch_staff()
 }

 const handleSelectSearchChange =(selected,name)=>{
  switch(name){
   
    case "user_id":
      setSelectedStaff(selected);
      setSearchObject({
        ...searchObject,
        user_id:selected.value,
      });
    break;

    case "team":
      setSelectedTeam(selected);
      setSearchObject({
        ...searchObject,
        team:selected.value,
      });
      fetch_staff(selected.value)
    break;

    default:
      break;
  }

}

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>  
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Cost Analysis" />
          <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                    <Row>
                    <Col md="2"> 
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                             From 
                          </Label>
                          <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchObject?.from_date}
                          onChange={handleDate}
                        />
                        </div>
                      </Col>
                      <Col md="2"> 
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                             To
                          </Label>
                          <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchObject?.to_date}
                          min={searchObject?.from_date}
                          onChange={handleDate}
                        />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Team
                          </Label>
                          <Select
                            name="team"
                            value={selectedTeam}
                            onChange={(selected) => {
                              handleSelectSearchChange(selected, "team");
                            }}
                             options={team}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2"> 
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                             Staff
                          </Label>
                          <Select
                            name="user_id"
                            value={selectedStaff}
                            onChange={(selected) => {
                              handleSelectSearchChange(selected, "user_id");
                            }}
                             options={staff}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    
                  
                      
                      <Col
                        md="2"
                        style={{ paddingTop: "25px" }}
                      >
                          <div className="mb-3">
                         <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                             onClick={() => handleSearch()}
                          >
                            {"  "}
                            Filter
                          </Button>
                          <Button
                            color="danger"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            onClick={() => resetSearch()}
                          >
                            {"  "}
                            Reset
                          </Button>
                      
                          </div>
                          </Col>
                          <Col>
                          <div className="mb-3" >
                         </div>
                      
                      </Col>
                  
                    </Row>
                     
                    </CardBody>
                  </Card>
                </Col>
              </Row>

          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div
                    className="table-responsive col-md-12"
                    style={{ userSelect: "auto" }}
                  >
                    <table
                      className="table table-hover table-bordered"
                      style={{ userSelect: "auto" }}
                    >
                      <thead style={{ userSelect: "auto" }}>
                      
                        <tr style={{ userSelect: "auto" }}>
                          <td colSpan={1} style={{ userSelect: "auto" }}>
                            {" "}
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: "center",
                              userSelect: "auto",
                              fontWeight:"500",
                              fontFamily:"IBM Plex Sans, sans-serif"
                            }}
                          >
                            {" "}
                            Days
                          </td>
                          <td colSpan={5} style={{ userSelect: "auto" ,fontWeight:"500"}}>
                            {" "}
                          </td>
                          <td
                            colSpan={3}
                            style={{
                              textAlign: "center",
                              userSelect: "auto",
                              fontWeight:"500",
                              fontFamily:"IBM Plex Sans, sans-serif"
                            }}
                          >
                            Salary
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: "center",
                              userSelect: "auto",
                              fontWeight:"500",
                              fontFamily:"IBM Plex Sans, sans-serif"
                            }}
                          >
                            {" "}
                            Cost{" "}
                          </td>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: "center",
                              userSelect: "auto",
                              fontWeight:"500",
                              fontFamily:"IBM Plex Sans, sans-serif"
                            }}
                          >
                            {" "}
                            Rate{" "}
                          </td>
                        </tr>
                        <tr style={{ userSelect: "auto" , fontWeight:"500", fontFamily:"IBM Plex Sans, sans-serif"}}>
                          <td width="1%" style={{ userSelect: "auto", fontWeight:"500", fontFamily:"IBM Plex Sans, sans-serif"}}></td>
                          <td width="1%" style={{ userSelect: "auto" }}>
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Working Days"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              T
                            </a>
                          </td>
                          
                          <td width="1%" style={{ userSelect: "auto" }}>
                            {" "}
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Planned Time(Hrs)"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              P
                            </a>
                          </td>
                          <td width="1%" style={{ userSelect: "auto" }}>
                            {" "}
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Effort Taken(Hrs)"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              E
                            </a>
                          </td>
                          <td width="1%" style={{ userSelect: "auto" }}>
                            {" "}
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Salary/Hr"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              S
                            </a>
                          </td>
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Cost/Hr"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              C
                            </a>
                          </td>
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            <a
                              href="#"
                              className="options_link"
                              role="button"
                              data-toggle="popover"
                              data-trigger="focus"
                              title="Total Rate/Hr"
                              style={{
                                whiteSpace: "nowrap",
                                userSelect: "auto",
                              }}
                            >
                              R
                            </a>
                          </td>
                          {/* Salary */}
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            Basic{" "}
                          </td>
                          <td width="1%" style={{ userSelect: "auto" ,  fontWeight:"500"}}>
                            P x S{" "}
                          </td>
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            E x S{" "}
                          </td>
                          {/* Cost */}
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            P x C{" "}
                          </td>
                          <td width="1%" style={{ userSelect: "auto" ,  fontWeight:"500"}}>
                            E x C{" "}
                          </td>
                          {/* Rate */}
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            P x R{" "}
                          </td>
                          <td width="1%" style={{ userSelect: "auto",  fontWeight:"500" }}>
                            E x R{" "}
                          </td>
                        </tr>
                        </thead>
                        <tbody style={{ userSelect: "auto" }}>
                        
                      
                      {details.map((item,index)=>(
                        <>
                        <tr>
                        <td>{(item.firstName || '') + (item.lastName || '')}</td>
                        <td>{item.totaldays}</td>
                        <td>{item.assign_time}</td>
                        <td>{item.assign_effort_hr}</td>
                        <td>{item.hourly_salary}</td>
                        <td>{item.hourly_cost}</td>
                        <td>{item.hourly_rate}</td>
                        <td>{item.basic_salary}</td>
                        <td>{item.calc1}</td>
                        <td>{item.calc2}</td>
                        <td>{item.calc3}</td>
                        <td>{item.calc4}</td>
                        <td>{item.calc5}</td>
                        <td>{item.calc6}</td>
                        </tr>
                        </>
                      ))}
                     
                 
                       
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cost_Analysis;
