import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";
import CronJobActivity from "../pages/Security/Cron Job Activity/CronJobActivity";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

/*---------------------
|Master Settings
|---------------------*/
import Company from "../pages/MasterSettings/Company";
import Branch from "../pages/MasterSettings/Branch/Branch";
import Privilage from "../pages/MasterSettings/Privilage/index";
import Rules from "../pages/MasterSettings/Rules";
import Modules from "../pages/MasterSettings/Module/Modules";

/*---------------------
|User
|---------------------*/
// import Add_Staff from "../pages/Staff/Add Staff/addStaff";
// import View_Staff from "../pages/Staff/View Staff/ViewStaff";
// import Manage_Staff from "../pages/Staff/Manage Team/manageStaff";
//Staff
import Add_Staff from "../pages/Staff/Add Staff/addStaff";
import AddStaffDetails from "../pages/Staff/Add Staff/Staff/Staff Detail/StaffDetails";
import View_Staff from "../pages/Staff/View Staff/ViewStaff";
import ViewStaffDetails from "../pages/Staff/View Staff/Staff/Staff Detail/StaffDetails";
import Manage_Staff from "../pages/Staff/Manage Team/manageStaff";

/*---------------------
|Manage
|---------------------*/
import LeadsFor from "../pages/Manage/Lead Source/Leads";
import ManageCategory from "../pages/Manage/Category/Category";
import ManageSubCategory from "../pages/Manage/Sub Category/SubCategory";
import ManageItems from "../pages/Manage/Items/Items";
import Tax from "../pages/Manage/Tax/Tax";
import ClientIndustry from "../pages/Manage/Client Industry/ClientIndustry";
import TaskChecklist from "../pages/Manage/Checklist/TaskChecklist";
import Taskflow from "../pages/Manage/Taskflow/Taskflow";
import Taskstatus from "../pages/Manage/Taskstatus/Taskstatus";
import Technology from "../pages/Manage/technology/technology";
import TaskSubcategory from "../pages/Manage/TaskSubcategory/index";

// import Mangeleads from "../pages/Manage/Lead Source/ManageLead/Mangeleads";

import ManageleadsCopy from "../pages/Manage/Lead Source/ManageLead/ManageleadsCopy";

/*---------------------
|People
|---------------------*/
import People from "../pages/People/People";

/*---------------------
|CRM
|---------------------*/
import Leads from "../pages/Leads/Leads";
import AddClient from "../pages/Clients/Add Client/AddClient";
import ViewClient from "../pages/Clients/View Client/ViewClient";
import ClientDashboard from "../pages/Clients/dashboard";
import AddProject from "../pages/PMO/Add Project/addProject";
import OurProjects from "../pages/OurProjects/OurProject";
import AddProjectDashboard from "../pages/PMO/Add Project/Details/index";
import AddProjectDashboardNew from "../pages/PMO/Add Project/Details/index_new";
import RenewalReport from "../pages/Clients/RenewalReport/RenewalReport";

/*---------------------
|Account
|---------------------*/
import Cost_Analysis from "../pages/Cost_Analysis/Cost_Analysis";
import AccountType from "../pages/Accounts/Account-Type";
import AccountDetails from "../pages/Accounts/AccountDetails";
import Chart from "../pages/Accounts/Chart";
import FundTransfer from "../pages/Accounts/FundTransfer";
import CreditDebit from "../pages/Accounts/CreditDebit";
import IncomeExpenses from "../pages/Accounts/IncomeExpenses";
import TransactionLog from "../pages/Accounts/TransactionLog";
import ProfitAndLoss from "../pages/Accounts/ProfitAndLoss";

/*---------------------
|HRMS
|---------------------*/
import My_Task from "../pages/My_Task/Task";
import AttendanceSummery from "../pages/Attendance/Attendance Summery/Attendance_Summery";
import manage_Holidays from "../pages/Manage/Holidays/Holidays";
import ManageDesignation from "../pages/Manage/Designation/ManageDesignation";
import Apply_leave from "../pages/Leave/Apply_Leave/applyLeave";
import Manage_Leave from "../pages/Leave/Manage_Leave/manageLeave";
import History from "../pages/Leave/History/history";
import Leave_Summary from "../pages/Leave/Leave_Summary/leaveSummary";
import SalaryModulecalculation from "../pages/Salary Module/Salary Calculation/Salary_Calculation";
import StaffSalary from "../pages/Salary Module/Staff Salary/Staff_salary";
import SalaryAdvance from "../pages/Salary Module/Advance/Advance";

import TimeSheet from "../pages/Attendance/Time-sheet/TimeSheet";
import WorkLog from "../pages/Activity/Screenshort/workLog";

/*---------------------
|Project
|---------------------*/
import ProjectDashboard from "../pages/Project Dashboard/index";
import AllTasks from "../pages/All Tasks/index";
import OtherTasks from "../pages/Other Tasks/index";
import TaskRunTimeLog from "../pages/TaskRuntimeLog/index";
import AllWorkupdate from "../pages/Workupdate Tasks/index";

/*---------------------
|Testimonials
|Website
|---------------------*/
import Testimonial from "../pages/Website/Testimonial/Testimonial";
import Portfolio from "../pages/Website/Portfolio/Portfolio";
import Casestudy from "../pages/Website/Casestudy/Casestudy";

/*---------------------
|Leads
|---------------------*/
import Newleads from "../pages/Leads/Lead/Lead";

/*---------------------
|TV APP
|---------------------*/
import TVProjectDashboard from "../pages/TV/project_dashboard";
import TVStaffDashboard from "../pages/TV/staff_dashboard";
import TVEventDashboard from "../pages/TV/evenet_dashboard";

import CRMTVProjectDashboard from "../pages/TV CRM/project_dashboard";
import CRMTVStaffDashboard from "../pages/TV CRM/staff_dashboard";
import CRMTVEventDashboard from "../pages/TV CRM/evenet_dashboard";
/*---------------------
|SupportTicket
|---------------------*/
import SupportTicket from "../pages/SupportTicket/SupportTicket";
/*---------------------
|Activity
|---------------------*/
import AllScreenshort from "../pages/Activity/Screenshort/all_list";
import Screenshort from "../pages/Activity/Screenshort";
/*---------------------
|Notification
|---------------------*/
import Notification from "../pages/Notification";
import ViewAlltodo from "../pages/TodoView/ViewAlltodo";

const userRoutes = [
  {path:"/view-all-to-do",component:ViewAlltodo},
  { path: "/ui-general", component: UiGeneral },
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/blockedip", component: BlockedIP },
  { path: "/userblock", component: UserBlock },
  { path: "/UserActivityLog", component: UserActivityLog },
  { path: "/CronJobActivity", component: CronJobActivity },

  /*---------------------
  |Master Settings
  |---------------------*/
  { path: "/company", component: Company },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/master_modules", component: Modules },
  { path: "/branch", component: Branch },
  /*---------------------
  |Notifications
  |---------------------*/
  { path: "/notifications", component: Notification },

  /*---------------------
  |Users
  |---------------------*/

  // { path: "/add_Staff", component: Add_Staff },
  // { path: "/view_manage_staff", component: View_Staff },
  // { path: "/manage_Team", component: Manage_Staff },
  //Staff
  { path: "/apply_leave", component: Apply_leave },
  { path: "/add_Staff", component: Add_Staff },
  { path: "/add_staff/:AddStaffid", component: AddStaffDetails },
  { path: "/view_manage_staff", component: View_Staff },
  { path: "/view_manage_staff/:ViewStaffid", component: ViewStaffDetails },
  { path: "/manage_Team", component: Manage_Staff },
  /*---------------------
  |Manage
  |---------------------*/
  { path: "/manage_leadsource", component: LeadsFor },
  { path: "/manage_category", component: ManageCategory },
  { path: "/manage_SubCategory", component: ManageSubCategory },
  { path: "/manage_items", component: ManageItems },
  { path: "/manage_tax", component: Tax },
  { path: "/manage_client_industry", component: ClientIndustry },
  { path: "/manage_checklist_items", component: TaskChecklist },
  { path: "/manage/taskflow", component: Taskflow },
  { path: "/manage/taskcat", component: TaskSubcategory },
  { path: "/manage/taskstatus", component: Taskstatus },
  { path: "/manage/technology", component: Technology },

  { path: "/manageleads/boards", component: ManageleadsCopy },

  // {path:"/manageleads-copy/boards",component:ManageleadsCopy},

  /*---------------------
|People
|---------------------*/

  { path: "/add_People", component: People },
  /*---------------------
  |CRM
  |---------------------*/

  { path: "/leads", component: Leads },
  { path: "/client", component: AddClient },
  { path: "/view-clients", component: ViewClient },
  { path: "/view-clients/:clientID", component: ClientDashboard },
  { path: "/project", component: AddProject },
  { path: "/view-projects", component: OurProjects },
  { path: "/project/:addProject", component: AddProjectDashboard },
  { path: "/project_new/:addProject", component: AddProjectDashboardNew },
  { path: "/renew-report", component: RenewalReport },
  { path: "/testimonials", component: Testimonial },
  { path: "/portfolio", component: Portfolio },
  { path: "/casestudies", component: Casestudy },
  { path: "/newleads", component: Newleads },

  /*---------------------
  |HRMS
  |---------------------*/
  { path: "/my-task", component: My_Task },
  { path: "/attendance_summery", component: AttendanceSummery },
  { path: "/manage_holidays", component: manage_Holidays },
  { path: "/manage_designation", component: ManageDesignation },
  { path: "/apply_leave", component: Apply_leave },
  { path: "/manage_leave", component: Manage_Leave },
  { path: "/history", component: History },
  { path: "/leave_summary", component: Leave_Summary },
  { path: "/salary_calculation", component: SalaryModulecalculation },
  { path: "/staff_salary", component: StaffSalary },
  { path: "/salary_advance", component: SalaryAdvance },

  { path: "/time-sheet", component: TimeSheet },

  /*---------------------
  |Account
  |---------------------*/
  { path: "/cost_analysis", component: Cost_Analysis },
  { path: "/account-type", component: AccountType },
  { path: "/account-details", component: AccountDetails },
  { path: "/accounts-chart", component: Chart },
  { path: "/fund-transfer", component: FundTransfer },
  { path: "/credit-debit-fund", component: CreditDebit },
  { path: "/income-expense", component: IncomeExpenses },
  { path: "/transaction-log", component: TransactionLog },
  { path: "/transaction-log/:chartId", component: TransactionLog },
  { path: "/profitAndLoss", component: ProfitAndLoss },

  /*---------------------
  |Project
  |---------------------*/
  { path: "/project-dashboard", component: ProjectDashboard },
  { path: "/all-tasks", component: AllTasks },
  { path: "/other-tasks", component: OtherTasks },
  { path: "/task-run-time-logs", component: TaskRunTimeLog },
  { path: "/all-workupdate", component: AllWorkupdate },
  { path: "/staff-work-time", component: WorkLog },

  /*---------------------
  |Website
  |---------------------*/

  /*---------------------
|People
|---------------------*/

  { path: "/support-ticket", component: SupportTicket },

  { path: "/tv-project-dashboard", component: CRMTVProjectDashboard },
  { path: "/tv-staff-dashboard", component: CRMTVStaffDashboard },
  { path: "/tv-event-dashboard", component: CRMTVEventDashboard },

  /** Screenshort */
  { path: "/screen-shots", component: AllScreenshort },
  { path: "/staff-screen-shots", component: Screenshort },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={localStorage.getItem("module_redirect")} />,
  },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify-otp", component: VerifyOtp },
  { path: "/reset-password", component: ResetPassword },
  {
    path: "/tv-project-dashboard/:accessToken/:code",
    component: TVProjectDashboard,
  },
  {
    path: "/tv-staff-dashboard/:accessToken/:code",
    component: TVStaffDashboard,
  },
  {
    path: "/tv-event-dashboard/:accessToken/:code",
    component: TVEventDashboard,
  },
];

export { userRoutes, authRoutes };
