import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Resete from "@mui/icons-material/ResetTvRounded";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

//Dialogue box content's
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

//Dialogue box table content's
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Calculated_Salary.scss";
import { Grid } from "@mui/material";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DatePicker from "@mui/lab/DatePicker";

import Modal from "react-bootstrap/Modal";

//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const options = [
  { value: "data", label: "data" },
  { value: "data", label: "data" },
  { value: "data", label: "data" },
];

const rows = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const rows1 = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const rows2 = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const CheckinCheckout = () => {
  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleChange = (selected) => {
    setType(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [type1, setType1] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleChange1 = (selected) => {
    setType1(selected);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const [value1, setValue1] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate1 = (newValue1) => {
    setValue1(newValue1);
  };

  const [form, setForm] = React.useState(false);

  const handleClickOpenForm = () => {
    setForm(true);
  };

  const handleCloseForm = () => {
    setForm(false);
  };
  const reset = () => {
    setValue("");
    setType(null);
    setType1(null);
  };

  const [show, setShow] = useState(false);
  const [showPay, setShowPay] = useState(false);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleClosePayModal = () => setShowPay(false);
  const handleShowPayModal = () => setShowPay(true);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },

      {
        label: "Created By",
        field: "createdBy",
        sort: "asc",
        width: 150,
      },
      {
        label: "No. of Staff",
        field: "noOfStaff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Amount",
        field: "totalAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        date: "01-07-2022",
        time: "10:00 AM",
        month: "Jun 2022",
        createdBy: "Nlk Admin",
        noOfStaff: "7 (3/10)",
        totalAmount: "500000",
        status: "Unpaid",
        field: "action",
        field: "action",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fa fa-credit-card"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowPayModal}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        date: "01-06-2022",
        time: "10:00 AM",
        month: "May 2022",
        createdBy: "Nlk Admin",
        noOfStaff: "5 (5/10)",
        totalAmount: "500000",
        status: "Paid",
        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={handleShowModal}
            ></i>
          </>
        ),
      },
      {
        id: "3",
        date: "02-05-2022",
        time: "10:00 AM",
        month: "April 2022",
        createdBy: "Nlk Admin",
        noOfStaff: "3 (7/10)",
        totalAmount: "250000",
        status: "Paid",
        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={handleShowModal}
            ></i>
          </>
        ),
      },
      {
        id: "4",
        date: "07-05-2022",
        time: "09:00 AM",
        month: "April 2022",
        createdBy: "Nlk Admin",
        noOfStaff: "7 (3/10)",
        totalAmount: "350000",
        status: "Paid",
        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={handleShowModal}
            ></i>
          </>
        ),
      },
      {
        id: "5",
        date: "11-05-2022",
        time: "11:00 AM",
        month: "April 2022",
        createdBy: "Nlk Admin",
        noOfStaff: "6 (4/10)",
        totalAmount: "400000",
        status: "Paid",
        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={handleShowModal}
            ></i>
          </>
        ),
      },
    ],
  };

  const tableStyle = {
    paddingRight: "14%",
  };
  const tableStyle1 = {
    paddingRight: "25%",
  };
  //   Modal Table Data
  const data1 = {
    columns: [
      // {
      //   label: (
      //     <>
      //       <FormGroup>
      //         <FormControlLabel
      //           value="start"
      //           control={<Checkbox />}
      //           labelPlacement="start"
      //         />
      //       </FormGroup>
      //     </>
      //   ),
      //   field: "checkBox",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Salary",
        field: "basicSalary",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">Attendance</th>
                      </tr>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td>P</td>
                        <td>L</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
                </Row>

                {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: "Calculated",
        field: "calc.Salary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Advance",
        field: "pre.advance",
        sort: "asc",
        width: 150,
      },

      {
        label: "Extra",
        field: "extraPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentive",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pay",
        field: "toPay",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        name: "Anitha",
        Salarytype: "Online",
        basicSalary: "5000/Month",
        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                      <Label className="flabel">Attendance</Label>
                      {"  "}
                      <Label>LOP</Label>
                    </Col> */}
                </Row>

                {/* <Label>P</Label>
                  {"  "}
                  <Label>L</Label>
                  {"  "}
                  <Label>H</Label>
                  {"  "} */}
              </Col>
            </Row>
          </>
        ),
        lop: "2500",
        "calc.Salary": "4800",
        "pre.advance": "1000",
        advanceDue: "2000",
        extraPayment: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        incentive: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        toPay: "5000",
      },
      {
        id: "2",
        name: "Beena",
        Salarytype: "Online",
        basicSalary: "5000/Month",
        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                      <Label className="flabel">Attendance</Label>
                      {"  "}
                      <Label>LOP</Label>
                    </Col> */}
                </Row>

                {/* <Label>P</Label>
                  {"  "}
                  <Label>L</Label>
                  {"  "}
                  <Label>H</Label>
                  {"  "} */}
              </Col>
            </Row>
          </>
        ),
        lop: "2500",
        "calc.Salary": "4800",
        "pre.advance": "1000",
        advanceDue: "2000",
        extraPayment: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        incentive: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        toPay: "5000",
      },
      {
        id: "3",
        name: "Shobha",
        Salarytype: "Online",
        basicSalary: "5000/Month",
        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                      <Label className="flabel">Attendance</Label>
                      {"  "}
                      <Label>LOP</Label>
                    </Col> */}
                </Row>

                {/* <Label>P</Label>
                  {"  "}
                  <Label>L</Label>
                  {"  "}
                  <Label>H</Label>
                  {"  "} */}
              </Col>
            </Row>
          </>
        ),
        lop: "2500",
        "calc.Salary": "4800",
        "pre.advance": "1000",
        advanceDue: "2000",
        extraPayment: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        incentive: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        toPay: "5000",
      },
      {
        id: "4",
        name: "Mayur",
        Salarytype: "Online",
        basicSalary: "5000/Month",
        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                      <Label className="flabel">Attendance</Label>
                      {"  "}
                      <Label>LOP</Label>
                    </Col> */}
                </Row>

                {/* <Label>P</Label>
                  {"  "}
                  <Label>L</Label>
                  {"  "}
                  <Label>H</Label>
                  {"  "} */}
              </Col>
            </Row>
          </>
        ),
        lop: "2500",
        "calc.Salary": "4800",
        "pre.advance": "1000",
        advanceDue: "2000",
        extraPayment: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        incentive: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        toPay: "5000",
      },
      {
        id: "5",
        name: "nizam",
        Salarytype: "Online",
        basicSalary: "5000/Month",
        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                      <Label className="flabel">Attendance</Label>
                      {"  "}
                      <Label>LOP</Label>
                    </Col> */}
                </Row>

                {/* <Label>P</Label>
                  {"  "}
                  <Label>L</Label>
                  {"  "}
                  <Label>H</Label>
                  {"  "} */}
              </Col>
            </Row>
          </>
        ),
        lop: "2500",
        "calc.Salary": "4800",
        "pre.advance": "1000",
        advanceDue: "2000",
        extraPayment: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        incentive: (
          <>
            <input
              defaultValue="45000"
              style={{ textAlign: "right" }}
              disabled
              className="form-control"
              type="text"
            />
          </>
        ),
        toPay: "5000",
      },
      {
        incentive: (
          <>
            <b>Total</b>
          </>
        ),
        toPay: "25000",
      },
    ],
  };

  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff Name",
        field: "Sname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Paying Amount",
        field: "PAmount",
        sort: "asc",
        width: 270,
      },
    ],
    rows: [
      {
        id: "1",
        Sname: "Anitha",
        PAmount: "4800",
      },
      {
        id: "2",
        Sname: "Beena",
        PAmount: "4500",
      },
      {
        id: (
          <>
            <b>Sub Total </b>
          </>
        ),
        PAmount: "9300",
      },
      {
        id: (
          <>
            <b>Service Charge </b>
          </>
        ),
        PAmount: "1000",
      },
      {
        id: (
          <>
            <b>Total </b>
          </>
        ),
        PAmount: "10300",
      },
    ],
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content" style={{ padding: "initial" }}>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Home" breadcrumbItem="calculated Salary" /> */}
            <h4 class="mb-0" style={{ padding: "18px 1px" }}></h4>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}
                    <MDBDataTable
                      id="calculatedTableId"
                      responsive
                      
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />

                    {/* <Row>
                      <Col className="col-2" style={{ paddingTop: "4px" }}>
                        <div className="mb-2">
                          <Button color="primary" type="submit">
                            {"  "}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered={true}
        style={{ width: "-webkit-fill-available" }}
      >
        <div
          className="modal-content"
          // style="user-select: auto;width: max-content;"
          style={{ width: "max-content", alignSelf: "center" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Item Details </h5>
            <button
              type="button"
              onClick={handleCloseModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              {/* payment details */}
              <Col xl="12">
                <Col md="6">
                  <h5>Payment Details:</h5>
                  <Stack direction="row" spacing={2}>
                    <Col xl="3">
                      <p>Fund Source</p>
                    </Col>
                    <Col xl="2">
                      <p>SBI Bank</p>
                    </Col>
                    <Col xl="3">
                      <p>Date & Time</p>
                    </Col>
                    <Col xl="3">
                      <p>11-07-2022 10:00 AM</p>
                    </Col>
                    <Col xl="3">
                      <p>Created By</p>
                    </Col>
                    <Col xl="3">
                      <p>Admin</p>
                    </Col>
                    <Col xl="3">
                      <p>Payment Status</p>
                    </Col>
                    <Col xl="3">
                      <p>Paid</p>
                    </Col>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Col xl="3">
                      <p>Amount</p>
                    </Col>
                    <Col xl="3">
                      <p>200000</p>
                    </Col>
                  </Stack>
                </Col>
              </Col>

              {/* payment details */}
              {/* Modal Body Start */}
              <MDBDataTable
                id="calculated1TableId"
                responsive
                
                bordered
                data={data1}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
              {/* Modal Body End */}
            </div>
          </AvForm>
        </div>
      </Modal>

      <div>
        <Dialog open={form} onClose={handleCloseForm}>
          <DialogTitle style={{ textAlign: "center" }}>
            Check In/Check Out Log Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Check In Date:</TableCell>
                      <TableCell align="left">08-11-2021</TableCell>
                      <TableCell align="left">Check In Time:</TableCell>
                      <TableCell align="left">23:26:45</TableCell>
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                        <TableCell align="left">{row.fat}</TableCell>
                        <TableCell align="left">{row.carbs}</TableCell>
                        <TableCell align="left">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="success">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* modal data dialogue */}
      <div>
        <Dialog open={form} onClose={handleCloseForm}>
          <DialogTitle style={{ textAlign: "center" }}>
            Check In/Check Out Log Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Check In Date:</TableCell>
                      <TableCell align="left">08-11-2021</TableCell>
                      <TableCell align="left">Check In Time:</TableCell>
                      <TableCell align="left">23:26:45</TableCell>
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.map((row1) => (
                      <TableRow key={row1.name}>
                        <TableCell component="th" scope="row">
                          {row1.name}
                        </TableCell>
                        <TableCell align="left">{row1.calories}</TableCell>
                        <TableCell align="left">{row1.fat}</TableCell>
                        <TableCell align="left">{rows1.carbs}</TableCell>
                        <TableCell align="left">{row1.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="success">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* pay modal start */}

      <Modal size="lg" show={showPay} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClosePayModal}>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col xl="4" style={{ marginBottom: "2%" }}>
            <Label>Fund Source</Label>
            <Select options={options} />
          </Col>
          {/* Modal Body Start */}
          <MDBDataTable
            MDBDataTable
            responsive
            bordered
            searching={false}
            paging={false}
            data={data2}
          />
          {/* Modal Body End */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{ background: "#5b73e8" }}
            onClick={handleClosePayModal}
          >
            Pay Now
          </Button>
          <Button variant="secondary" onClick={handleClosePayModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* pay modal end */}
    </>
  );
};

export default CheckinCheckout;
