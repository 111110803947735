import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";

function ViewAlltodo() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [optionTeam, setOptionTeam] = useState([]);
  const [staffOptions, setstaffOptions] = useState([]);
  const [dates, setdates] = useState({});
  const [master, setmaster] = useState({});
  const [selectedStaff, setselectedStaff] = useState(null);
  const [selectedTeam, setselectedTeam] = useState(null);
  const [statusOptions, setstatusOptions] = useState([]);
  const [statusnumber, setstatusnumber] = useState(null);
  const [tableDetails, settableDetails] = useState([]);
  const [userId, setUserId] = useState();
  const useRid = JSON.parse(localStorage.getItem("authUser"))._id;

  console.log("localsuerid", useRid);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserId(obj._id);
    }
  }, []);

  function rest() {
    setmaster({});
    setdates({});
    setselectedStaff(null);
    setselectedTeam(null);
    setstatusnumber(null);
    fetch_all_staff();
  }
  useEffect(() => {
    handleTeamOptions();
    fetch_all_staff();
  }, []);

  function handleTeamOptions() {
    axios
      .get(`${API_URL}project_dashboard/team_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var list = [];
        var data = response.data.data;
        data.map((item, index) => {
          list.push({ label: item.name, value: item._id });
        });
        setOptionTeam(list);
      });
  }

  function fetch_all_staff(team = "") {
    axios
      .get(`${API_URL}project_dashboard/staff_options?team=${team}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        var list = [];
        result.map((item) => {
          list.push({ label: item.firstName, value: item._id });
        });
        setstaffOptions(list);
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "To-do",
        field: "description",
        width: 150,
      },

      {
        label: "List",
        field: "addedForName",
        width: 150,
      },
      {
        label: "Assigned",
        field: "addedByName",
        width: 150,
      },

      {
        label: "Completed",
        field: "completedstatus",
        width: 150,
      },
      {
        label: "Verified",
        field: "verifyStatus",
        width: 150,
      },
      {
        label: "Action ",
        field: "Action",
        width: 150,
      },
    ],

    rows: tableDetails,
  };

  function handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setdates({ ...dates, [name]: value });
  }

  function handleselectchange(selected, name) {
    switch (name) {
      case "team":
        setselectedTeam(selected);
        setmaster({ ...master, team: selected.value });
        fetch_all_staff(selected.value);
        break;
      case "staff":
        setselectedStaff(selected);
        setmaster({ ...master, staff: selected.value });
      default:
        break;
    }
  }

  function taskname(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmaster({ ...master, [name]: value });
  }
  useEffect(() => {
    handleTabledata(dates, master, statusnumber);
    handleStaus(dates, master, statusnumber);
  }, [dates, master, statusnumber]);

  console.log("mster", master);

  function handleTabledata(dat, mast, num) {
    axios
      .get(
        `${API_URL}todo?description=${mast.description}&&fromDate=${dat.from_date}&&toDate=${dat.to_date}&&team=${mast.team}&&todoStatus=${num}&&staff=${mast.staff}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data;
        let table = [];

        result.map((item, index) => {
          console.log("item", item);
          item.id = index + 1;
          item.date = moment(item.createdDate).format("DD-MM-YYYY");
          item.time = moment(item.createdTime, "hhmmss").format("hh:mm a");

          if (item.addedFor.length == 0) {
            item.addedForName = item.addedByName;
            delete item.addedByName;
          }

          if (item.completed == true) {
            item.completedstatus = "Completed";
          } else if (item.completed == false) {
            item.completedstatus = "Not Completed";
          }

          if (item.verified == true) {
            item.verifyStatus = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button style={{ padding: "0px 5px" }} color="info" size="sm">
                  Verified
                </Button>
              </div>
            );
          } else if (
            item.verified == false &&
            item.completed == true &&
            item.addedFor.length > 0 &&
            item.addedBy == useRid
          ) {
            item.verifyStatus = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    veriftCompleted(item._id);
                  }}
                  style={{ padding: "0px 5px" }}
                  color="danger"
                  size="sm"
                >
                  Verify
                </Button>
              </div>
            );
          }

          table.push(item);
        });
        settableDetails(table);
      });
  }
  console.log("userid", userId);
  function handleStaus(dat, msobj) {
    axios
      .get(
        `${API_URL}todo/countStatus?fromDate=${dat.from_date}&&toDate=${dat.to_date}&&team=${msobj.team}&&staff=${msobj.staff}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setstatusOptions(res.data);
      });
  }

  function handlepassNewstatus(num) {
    setstatusnumber(num);
    // handleTabledata(dates,master,num)
  }

  function veriftCompleted(id) {
    axios
      .put(
        `${API_URL}todo/${id}`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        handleTabledata(dates, master, statusnumber);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  return (
    <>
      <div>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <AvForm>
                      <Row>
                        <Col md="2">
                          <Label>From date</Label>
                          <input
                            type="date"
                            name="from_date"
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            value={dates?.from_date || ""}
                          />
                        </Col>
                        <Col md="2">
                          <Label>To date</Label>
                          <input
                            type="date"
                            name="to_date"
                            className="form-control"
                            onChange={(e) => handleInputChange(e)}
                            value={dates?.to_date || ""}
                          />
                        </Col>
                        <Col md="2">
                          <Label>Team</Label>
                          <Select
                            placeholder="Team"
                            classNamePrefix="select2-selection"
                            value={selectedTeam}
                            options={optionTeam}
                            onChange={(selected) => {
                              handleselectchange(selected, "team");
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>Staff</Label>
                          <Select
                            placeholder="satff"
                            classNamePrefix="select2-selection"
                            value={selectedStaff}
                            options={staffOptions}
                            onChange={(selected) => {
                              handleselectchange(selected, "staff");
                            }}
                          />
                        </Col>

                        <Col md="2">
                          <div style={{ marginTop: "5px" }}>
                            <Button
                              className="mt-4"
                              style={{ paddingTop: "10px" }}
                              color="danger"
                              type="submit"
                              onClick={() => rest()}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col md="12">
                        <ul className="fiter-5-sub">
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(1);
                              }}
                              className={`status-a ${
                                statusnumber === 1 ? "active-status-a" : ""
                              }`}
                            >
                              New{" "}
                              <span className="mx-1">{statusOptions?.new}</span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(2);
                              }}
                              className={`status-a ${
                                statusnumber === 2 ? "active-status-a" : ""
                              }`}
                            >
                              Pending{" "}
                              <span className="mx-1">
                                {" "}
                                {statusOptions?.pending}
                              </span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(4);
                              }}
                              className={`status-a ${
                                statusnumber === 4 ? "active-status-a" : ""
                              }`}
                            >
                              Completed{" "}
                              <span className="mx-1">
                                {" "}
                                {statusOptions?.completed}
                              </span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(3);
                              }}
                              className={`status-a ${
                                statusnumber === 3 ? "active-status-a" : ""
                              }`}
                            >
                              InComplete{" "}
                              <span className="mx-1">
                                {" "}
                                {statusOptions?.incomplete}
                              </span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(5);
                              }}
                              className={`status-a ${
                                statusnumber === 5 ? "active-status-a" : ""
                              }`}
                            >
                              Verified{" "}
                              <span className="mx-1">
                                {" "}
                                {statusOptions.verified}
                              </span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => {
                                handlepassNewstatus(6);
                              }}
                              className={`status-a ${
                                statusnumber === 6 ? "active-status-a" : ""
                              }`}
                            >
                              Not Verified{" "}
                              <span className="mx-1">
                                {" "}
                                {statusOptions?.not_verified}
                              </span>{" "}
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <MDBDataTable
                      id="todotable"
                      bordered
                      responsive
                      searching={true}
                      entries={20}
                      disableRetreatAfterSorting={true}
                      data={data}
                    ></MDBDataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewAlltodo;
