import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

export let fetchTeamsOptions = () =>
  axios
    .get(`${API_URL}project_dashboard/team_options`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => {
      return res.data.data.map((data, idx) => {
        let details = {
          label: data.name,
          value: data._id,
          name: "team",
        };
        // if (idx === 0) {
        fetchStaffOptions(data._id, 0).then((res) => (details.staffs = res));
        // }
        return details;
      });
    })
    .catch((err) => console.log(err.response));

export let fetchStaffOptions = (team = "", mthd) =>
  axios
    .get(`${API_URL}project_dashboard/staff_options?team=${team}`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => {
      if (mthd === 0) {
        return res.data.data.map((item) => item._id);
      } else {
        return res.data.data.map((data, idx) => {
          let details = {
            label: data.firstName,
            value: data._id,
            name: "staff",
          };
          return details;
        });
      }
    })
    .catch((err) => console.log(err.response));

export let fetchTableData = (data = {}) =>
  axios
    .post(`${API_URL}monthly-attendance`, data, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data.data)
    .catch((err) => console.log(err.response));

export let refreshDatabase = () =>
  axios
    .get(`${API_URL}monthly-attendance`, {
      headers: { "x-access-token": accessToken },
    })
    .then((res) => res.data.status)
    .catch((err) => console.log(err.response));
