import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Casestudy.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";
import { ImageSearch } from "@material-ui/icons";

const Casestudy = () => {
  const [masterObject, setmasterObject] = useState({});
  const [selectedClient,setSelectedClient]=useState(null);
  const [selectedTechnology,setSelectedTechnology]=useState(null);
  const [selectedCategory,setSelectedCategory]=useState(null);
  const [client,setClient]=useState([]);
  const [technology,setTechnology]=useState([]);
  const [category,setCategory]=useState([]);
  const [errors, setErrors] = useState({
    clientError: "",
    technologyError:"",
    categoryError:"",
    imageError:""
  });
  const[values,setValues]=useState([{service_name:"",images:""}])
  const[orders,setOrders]=useState([{video_links:""}])
  const addCase =useSelector((state)=>state.addPortfolio);


  const[caseStudyToUpdate,setcaseStudyToUpdate]=useState(null);
  const[caseStudyIdToBeDeleted,setcaseStudyIdToBeDeleted]=useState(null)
  const[confirmDeleteAlert,setConfirmDeleteAlert]=useState(null);
  const[tableData,setTableData]=useState([]);
  
  
  const [uploadProgress, setUploadProgress] = useState(); 
  const [showPopup, setShowPopup] = useState(false);//for model
  const [caseDetails, setcaseDetails] = useState({});//modelview

  const [popupTableValues, setPopupTableValues] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  
  
  
  useEffect(() => {
      fetch_client();
      fetch_technology();
      fetch_pdtcat();
      handleTableData();
    }, 
    []
  );

  function fetch_client(){
      axios
      .get(`${API_URL}client/options`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.name,
              value:el._id
          }})

      setClient([{options:data}])
      })
  }

  function fetch_technology(){
      axios
      .get(`${API_URL}technology/options`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.name,
              value:el._id
          }})

        setTechnology([{options:data}])
      })
  }

  function fetch_pdtcat(){
      axios
      .get(`${API_URL}category/options`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.name,
              value:el._id
          }})

          setCategory([{options:data}])
      })
  }

  const handleSelectChange =(selected,name)=>{
    switch(name){
      case "client_id":
        setSelectedClient(selected);
        setmasterObject({
          ...masterObject,
          client_id:selected.value
        });
        setErrors({
          ...errors,
          clientError: "",
        });
      break;
      case "technoligies_id":
        setSelectedTechnology(selected);
        let techArray = [];
        selected &&
          selected.map((element) => {
            techArray.push(element.value);
          });
       setmasterObject({
          ...masterObject,
          technoligies_id:techArray
        });
        setErrors({
          ...errors,
          technologyError: "",
        });
      break;
      case "works":
        setSelectedCategory(selected);
        let workArray = [];
        selected &&
          selected.map((element) => {
            workArray.push(element.value);
          });
       setmasterObject({
          ...masterObject,
          works:workArray
        });
        setErrors({
          ...errors,
          categoryError: "",
        });
      break;
      default:
        break;
    }
  }
  const handleFormData = e =>{
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({
      ...masterObject,
      [name]:value
    }) 
  }
 
  function handleTableData() {
    var url = `${API_URL}casestudies/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];
        result.map((item,index)=>{
          item.action=(
            <div style={{display:"flex",justifyContent:"center"}}>
                <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}
              ></i>
               <i 
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateCase(item);
                }}
            >
              </i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={()=>{
                  setcaseStudyIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
                >
              </i>
            </div>
          )
          item.id=index+1;
          if(item?.client_id){
            item.client=item.client_id.short_name
          }
          item.tech=(
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
              {item.technoligies_id &&
                item.technoligies_id.map((el) => {
                  let indus=el?.name || {}
                  return(
                    <li className="mb-2">
                        <span>
                          {indus}
                        </span>
                      </li>
                  )
                })
              }
              </ul>
            </div>
          );
          item.work = (
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
                {item.works &&
                  item.works.map((el) => {
                    let works= el?.name || {}
                    return (
                      <li className="mb-2">
                        <span>
                          {works}
                        </span>
                      </li>
                    );
                  })} 
              </ul>
            </div>
          );
          
               
           
          if (item?.updatedby) {
              let values = item?.updatedby || {};
                item.staff = (values.firstName || '') + (values.lastName || '');
          } else {
              let values = item?.addedby || {};
                item.staff = (values.firstName || '')+ (values.lastName || '');
          }
          resData.push(item)
          })
        setTableData(resData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Client",
        field: "client",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Technology",
      //   field: "tech",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Works",
        field: "work",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leads",
        field: "leads_delivered",
        sort: "asc",
        width: 150,
      },
      {
        label: "Traffic",
        field: "traffic_increase",
        sort: "asc",
        width: 150,
      },
      {
        label: "Score",
        field: "sec_score",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:tableData,
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateCase = (item) =>{
    setcaseStudyToUpdate(item._id);
    axios
    .get(`${API_URL}casestudies/data?_id=` + item._id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      let result = res.data.data;


    let client=result?.client_id
    let clientopt={
      label:client?.name,
      value:client?._id
    };
    setSelectedClient(clientopt);

      let cat=[];
    result.works &&
    result.works.map((el)=>{
      let array={
        label:el.name,
        value:el._id,
      }
      cat.push(array)
    })
    setSelectedCategory(cat);
  
    let tech=[];
    item.technoligies_id &&
    item.technoligies_id.map((el)=>{
      let array={
        label:el.name,
        value:el._id,
      }
      tech.push(array)
    })
    setSelectedTechnology(tech);

       let tableData =[];
     
      result.services.map((data,index)=>{
      let val = {};
    
      val.service_name=data?.service_name || "";
      val.images = data?.images || "";
    
      tableData.push(val);
    })
 
   setValues(tableData);
    
   let orderTable =[];
   result.video_links.map((data,index)=>{
   let val = {};
   val.video_links=data ;
   orderTable.push(val);
 })
 setOrders(orderTable);



    setmasterObject(result);
    
  //   let tableData =[];
  //   item.portfolio.map((data,index)=>{
  //     let val = {};
  //     let port=data?.portfolio_type_id || {} ;
  //     val.portfolio_type_id=port?._id;
  //     val.type_name=port?.type_name;
  //     val.image = data?.image || "";
  //     tableData.push(val);
  //   })
  //  setValues(tableData);
  //  setmasterObject(item);
      // result[0].item_checklist &&
      //   result[0].item_checklist.map((cont, index) => {
        
        //   val.checklist_name = Flow?.checklist_name;
         
          
        //  val.checklist_id = Flow?._id;
        //  val.weightage = cont?.weightage;      
        //   tableData.push(val);
        // });
        

     
    });
    
  

  };

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}caseStudies/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let Update = result.updatedby || {};
        result.edited =(Update.firstName || '') + (Update.lastName || '')
        let Add = result.addedby || {};
        result.add =(Add.firstName || '') + (Add.lastName || '')
        let Client = result.client_id || {};
        result.client = Client?.name;
        let upTime = result.up_time || {};
        let upDate =result.up_date || {};
        result.update = (moment(upDate).format("DD-MM-YYYY") )+" "+( moment(upTime, "HHmmss").format("hh:mm a"));
        let Time = result.time || {};
        let Date =result.date || {};
        result.time = moment(Time, "HHmmss").format("hh:mm a");
        result.day = moment(Date).format("DD-MM-YYYY") ;
        result.technology=(
         
            <ul> 
            {
              result.technoligies_id &&
              result.technoligies_id.map((el) => {

                let indus=el?.name || {}
                return(
                  <li className="mb-2">
                      <span>
                        {indus}
                      </span>
                    </li>
                )
              })
            }
            </ul>
        );
        result.work= (
        
            <ul>
              {result.works &&
                result.works.map((el) => {
                  let works= el?.name || {}
                  return (
                    <li className="mb-2">
                      <span>
                        {works}
                      </span>
                    </li>
                  );
                })} 
            </ul>
        
        );
       result.link=(
     
        <ul>
          {result.video_links &&
            result.video_links.map((el) => {
              return (
                <li className="mb-2">
                  <span>
                    {el} 
                  </span>
                </li>
              );
            })} 
        </ul> 
       );

      
       let tableValues = [];

       result.services &&
       result.services.forEach((el, index) => {
        
                 let val = {};
                 val.id = index + 1
                  let imageType = el?.images || [];
              
                val.images =  (
                    imageType.map((image) => {
                      return (
                <img
                src={`${API_URL}uploads/services_images/${image}`}
                style={{ width:"75px",height:"75px",cursor: "pointer" ,paddingRight:"10px"}}
               onClick={()=> window.open(`${API_URL}uploads/services_images/${image}`, "_blank")}
              />
                      )
                     }) 
                     )
                
                
                val.service_name = el.service_name;
                tableValues.push(val);
                });
            

        setShowPopup(true);
        setcaseDetails(result);
        setPopupTableValues(tableValues);
   
          
      });
       
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("caseStudies_image", e.target.files[0]);
    axios
      .post(`${API_URL}casestudies/imagecaseStudies/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`image`]: response.data.file.filename,
          });
          setErrors({
            ...errors,
            imageError: "",
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setmasterObject({
      ...masterObject,
      [`image`]: "",
    });
  };

  const handleTableInputchange = (e,index)=>{
    const list =[...values];
    let name = e.target.name;
    let value = e.target.value;
    list[index][name]=value;
    setValues(list);
    setmasterObject({
      ...masterObject,
      services:list
    });
  }

  const handleTableInputchangeNew = (e,index)=>{
 
    const list =[...orders];
    let name = e.target.name;
    let value = e.target.value;
    list[index][name]=value;
    setOrders(list);
    let valueArray=[];
      list &&
    list.map((element) => {
      valueArray.push(element.video_links);
    });
    setmasterObject({
      ...masterObject,
      ["video_links"]:valueArray
    });
  }
  
  const uploadTableImage = (e,index) => {
    const fd = new FormData();
    fd.append("services_image", e.target.files[0]);
    axios
      .post(`${API_URL}casestudies/imageservices`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          const list=[...values];   
          let arr = (list[index].images === "") ? []: list[index].images;
        if(arr.length >3){
          toastr.error("Image upload limit exeeded");
            return;
        }else{
          arr.push(response.data.file.filename)
           list[index]["images"]=arr;
          setValues(list);
          setmasterObject({
            ...masterObject,
            services:list
          });
        }
      }else {
          toastr.error(response.data.message);
        }
      });
 }

  
  const deleteTableImage =(index1,index)=>{
      const list=[...values];
      const imgs=list[index]["images"]
      imgs.splice(index1,1)
      list[index]["images"]=imgs;
      setValues(list);
      setmasterObject({
        ...masterObject,
        services:list
      });
  }

const handleValidSubmit =()=>{
  let flag = true;
  let validImg = true;
  let vflag =true;
  values &&
    values.forEach((element) => {
      if (element.service_name === "") {
        flag = false;
      } else if (element.images === "") {
        validImg = false;
      }
    });

    orders &&
    orders.forEach((element) => {
      if (element.video_links === "") {
        vflag = false;}
    });

    if(!masterObject.image || selectedClient === null || selectedTechnology === null || selectedCategory === null || flag === false|| validImg === false || vflag === false){
      let errorVal = errors;
      if(selectedClient === null){
      errorVal.clientError ="Please Select Client";
      setErrors({
        ...errorVal,
      });
      }
    if(selectedTechnology === null){
    errorVal.technologyError ="Please Select Technology";
    setErrors({
      ...errorVal,
    });
   }
   if(selectedCategory === null){
    errorVal.categoryError ="Please Select Category";
    setErrors({
      ...errorVal,
    });
   }
   if(!masterObject.image){
    errorVal.imageError ="Please Select Image";
    setErrors({
      ...errorVal,
    });
  }
      if (flag === false) {
        toastr.error("Service Name required");
        return;
      } 
      if(validImg === false) {
        toastr.error("Image required");
        return;
       }
       if(vflag === false) {
        toastr.error("Video Links required");
        return;
       }
    
    
  }

      else {
        if(caseStudyToUpdate) {
        axios
          .put(
            `${API_URL}casestudies` ,
            masterObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Case Study updated successfully");
              handleTableData();
         
              setSelectedClient(null);
              setSelectedTechnology(null);
              setSelectedCategory(null);
            
              setValues([{service_name:"",images:""}]);
              setOrders([{video_links:""}]);
              formRef.current.reset();
              setErrors({
                ...errors,
                clientError: "",
                technologyError:"",
                categoryError:"",
                imageError:""
              });
              setmasterObject({});
              setcaseStudyToUpdate(null);
            } else {
              toastr.error("Failed to update Case Study");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } 
      else {
        axios
          .post(`${API_URL}casestudies`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
          
            if (res.data.status === 200) {
              toastr.success("Case Study created successfully");
              handleTableData();
            
              setSelectedClient(null);
              setSelectedTechnology(null);
              setSelectedCategory(null);
          
              setValues([{service_name:"",images:""}]);
              setOrders([{video_links:""}]);
              formRef.current.reset();
              setErrors({
                ...errors,
                clientError: "",
                technologyError:"",
                categoryError:"",
                imageError:""
              });
              setmasterObject({});
            } else {
              toastr.error("Failed to create Case Study");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  }
const handleRemove =(index) =>{
  const list = [...values];
  list.splice(index, 1);
  let valueArray=[];
  list &&
  list.map((element) => {
    valueArray.push({service_name:element.service_name,images:element.images});
  });
  setmasterObject({
    ...masterObject,
    services: valueArray,
  });
  setValues(list);
}
const addMore = () => {
  setValues([...values, { service_name: "", images: "" }]);
};

const handleRemoveNew =(index) =>{
  const list = [...orders];
  list.splice(index, 1);
  let valueArray=[];
  list &&
  list.map((element) => {
    valueArray.push(element.video_links);
  });
  setmasterObject({
    ...masterObject,
   ["video_links"]: valueArray,
  });
  setOrders(list);
}
const addMoreNew = () => {
  setOrders([...orders, { video_links:""}]);
};
const popupTableData = {
  columns: [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Service Name",
      field: "service_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Service Images",
      field: "images",
      sort: "asc",
      width: 150,
    },
  ],
  rows: popupTableValues,
};
  const reset=() =>{
    handleTableData();
    setSelectedClient(null);
    setSelectedTechnology(null);
    setSelectedCategory(null);
    formRef.current.reset();
    setValues([{service_name:"",images:""}]);
    setOrders([{video_links:""}]);
    setErrors({
      ...errors,
      clientError: "",
      technologyError:"",
      categoryError:"",
      imageError:""
    });
     setmasterObject({});
  }
  const customStyles = {
    lineHeight: "1.8",
  };
  return (
      <>
        <React.Fragment>
          {confirmDeleteAlert ? (
            <SweetAlert
              title=""
              showCancel
              confirmButtonText="Delete"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                axios
                  .delete(
                    `${API_URL}casestudies` + "/" + caseStudyIdToBeDeleted,
                    {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === 200) {
                      toastr.success("Case study deleted successfully");
                      if (
                        masterObject &&
                        masterObject._id === caseStudyIdToBeDeleted
                      ) {
                        formRef.current.reset();
                        setmasterObject({});
                        setcaseStudyToUpdate(null);
                        setSelectedClient(null);
                        setSelectedTechnology(null);
                        setSelectedCategory(null);
                        setValues([{service_name:"",images:""}]);
                         setOrders([{video_links:""}]);
                        setErrors({
                          ...errors,
                          clientError: "",
                          technologyError:"",
                          categoryError:"",
                          imageError: ""
                        });
                      }
                      handleTableData();
                    } else {
                      toastr.error(
                        res.data.message,
                        "Failed to delete Casestudy"
                      );
                      return;
                    }
                  });
                setConfirmDeleteAlert(false);
              }}
              onCancel={() => setConfirmDeleteAlert(false)}
            >
              Are you sure you want to delete it?
            </SweetAlert>
          ) : null}
            <Modal show={showPopup} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Case Study Details</h5>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
                <tr>
                <td style={{ padding: "10px"}}>Date</td>
                <td>: {caseDetails?.day}</td>
                <td>Time</td>
                <td style={{ textAlign: "left" }}>: {caseDetails?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px"}}>
                  Client
                </td>
                <td>: {caseDetails?.client}</td>
                <td>Service Year</td>
                <td style={{ textAlign: "left" }}>
                  : {caseDetails?.service_year}
                </td>
              </tr>
              <tr >
                <td style={{padding: "10px"}}>Description</td> 
                <td colSpan={3} style={{ textAlign: "left" }}>: {caseDetails?.description}</td>

              </tr>
            
                <tr>
                <td style={{ padding: "10px"}}>
                   Leads Delivered</td>
                <td style={{ textAlign: "left" }}>
                  : {caseDetails?.leads_delivered}
                </td>
                <td > SEO Score</td>
                <td style={{ textAlign: "left" }}>
                  : {caseDetails?.sec_score}
                </td>
                </tr>
             
              
          
              <tr>
              <td style={{ padding: "10px"}}>
                Works
                </td>
                <td>{caseDetails?.work}</td>
                <td > Traffic Increase</td>
                <td style={{ textAlign: "left" }}>
                  : {caseDetails?.traffic_increase}
                </td>
               
                </tr>          
              <tr>
              <td style={{ padding: "10px"}}>
                  Video Links
                </td>
                <td>{caseDetails?.link}</td>
                <td style={{ padding: "10px"}}>Image</td>
                <td style={{ textAlign: "left" }}  className="mb-2">
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      paddingLeft: "0px",
                       cursor: "pointer" 
                    }}
                    src={`${API_URL}uploads/caseStudies_images/${caseDetails?.image}`}
                    onClick={()=> window.open(`${API_URL}uploads/caseStudies_images/${caseDetails.image}`, "_blank")}
                  ></img>
                </td>
              </tr>
              <tr >
              <td  style={{padding: "10px"}}>
                Technologies
                </td>
                <td style={{ textAlign: "left" }}>{caseDetails?.technology}</td>
              
                <td>Added By</td>
                <td style={{ textAlign: "left" }}>: {caseDetails?.add}</td>
              </tr>
              <tr hidden={!caseDetails?.updatedby}>
                <td style={{ padding: "10px"}}>Updated At</td>
                <td>: {caseDetails?.update}</td>
                <td>Updated By</td>
                <td style={{ textAlign: "left" }}>
                  : {caseDetails?.edited}
                </td>
              </tr>   
            </table>
            <Row className="mt-3">
                <MDBDataTable
                  id="casePopupTableId"
                  responsive
                  bordered
                  data={popupTableData}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                  //entries={10}
                />
              </Row>
          </div>
        </AvForm>
      </Modal>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Home" breadcrumbItem="Case Studies" />

              <Row>
                <Col className="col-12">
                  <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody>
                      <AvForm
                        ref={formRef}
                        className="needs-validation"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        <Row>
                          
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Client  
                              </Label>
                              <Select
                                name="client_id"
                                value={selectedClient}
                                options={client}
                                 classNamePrefix ="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "client_id");
                                }}
                              />
                                <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedClient === null ? errors.clientError : ""}
                              </p>
                            </div>
                          </Col>
                         
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                              Technology  
                              </Label>
                              <Select
                                isMulti
                                name="technoligies_id"
                                value={selectedTechnology}
                                options={technology}
                                classNamePrefix ="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "technoligies_id");
                                }}
                              />
                                <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedTechnology === null ? errors.technologyError : ""}
                              </p>
                            </div>
                          </Col>
                        
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Leads Delivered 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="leads_delivered"
                              placeholder="Leads Delivered "
                              type="number"
                              errorMessage="Enter Leads Delivered "
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.leads_delivered}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>   <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Traffic Increase 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="traffic_increase"
                              placeholder="Traffic Increase "
                              type="number"
                              errorMessage="Enter Traffic Increase "
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.traffic_increase}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>   
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              SEO Score 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="sec_score"
                              placeholder="SEO Score "
                              type="number"
                              errorMessage="Enter SEO Score "
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.sec_score}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                            Service Year
                            </Label>
                            <AvField
                              style={customStyles}
                              name="service_year"
                              placeholder="Service Year"
                              type="number"
                              errorMessage="Enter Service Year"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.service_year}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                              Works  
                              </Label>
                              <Select
                                isMulti
                                name="works"
                                value={selectedCategory}
                                options={category}
                                classNamePrefix ="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "works");
                                }}
                              />
                                <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedCategory === null ? errors.categoryError : ""}
                              </p>
                            </div>
                          </Col>
                          <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {masterObject?.image ? (
                              <div div className="img-wraps">
                           
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}uploads/caseStudies_images/${masterObject?.image}`}
                                  />
                              
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteImage}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                              
                            )}
                            {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                            <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!masterObject.image ? errors.imageError : ""}
                          </p>
                          </div>
                        </Col> 
                        <Col md="6">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Description 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="description"
                              placeholder="Description"
                              // type="text"
                              type="textarea"
                              errorMessage="Enter Description"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.description}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>
</Row>
<Row>



                        <Col className="col-6 ">
                            <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            
                              <Row
                               style={{
                                width: "90px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                               color:"#495057",
                              
                                margin:"6px"
                                     }}>Services :</Row>
                              <Row>
                                <Col xl="12">
                                  <Table
                                    style={{ textAlign: "center"}}
                                    id="services"
                                    className="table table-bordered dataTable"
                                  >
                                    <TableHead>
                                      <TableRow style={{ textAlign: "center" }}>
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          No.
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "90px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          Name
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "100px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                        Images
                                        </TableCell>
                                        
                                        {values.length !== 1 ? (
                                          <TableCell
                                            style={{
                                              width: "0px",
                                              textAlign: "center",
                                              fontWeight: "500",
                                              paddingLeft: "0rem",
                                              paddingRight: "0rem",
                                              fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057",
                                            }}
                                          >
                                            Action
                                          </TableCell>
                                        ) : null}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values &&
                                        values.map((item, index) => (
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              style={{ textAlign: "center", fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057", fontWeight: "500" }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell>
                                            <AvField
                                                style={customStyles}
                                                name="service_name"
                                                placeholder="Service Name "
                                                type="text"
                                                // errorMessage="Enter Service Name "
                                                className="form-control"
                                                // validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={masterObject?.services?.[index]?.service_name}
                                                onChange={(e) =>
                                                  handleTableInputchange(e, index)
                                                }

                                              />
                                            </TableCell>                                       
                                              <TableCell>
                                              <AvField
                                                  name="images"
                                                  type="file"
                                                  className="form-control"
                                                  id="validationCustom03"
                                                  onChange={(e) =>
                                                   uploadTableImage(e,index)
                                                  }
                                                  rows="1"
                                                />
                               <Row>                
                          { masterObject?.services?.[index]?.images &&
                            masterObject?.services?.[index]?.images.map((images, index1) => {
                              return (
                                <>
                               
                                  <Col md="3" style={{ paddingRight: "0",paddingTop:"13px"}}>
                                    <div className="mb-3">
                                      <img
                                        alt=""
                                        width="45"
                                        height="45"
                                        src={`${API_URL}uploads/services_images/${images}`}
                                      />
                                      <button
                                        type="button"
                                       
                                        className="upload_image_close"
                                        onClick={() =>
                                          deleteTableImage(index1,index)
                                        }
                                       
                                      
                                      >
                                        
                                      </button>
                                    </div>
                                  </Col>
                                </>
                              );
                            })}
                            </Row> 
                          </TableCell>

                                            {values.length !== 1 && (
                                              <i
                                              className="uil uil-times"
                                              style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    paddingLeft: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                              onClick={() => handleRemove(index)}
                                              ></i>
                                           )}
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Col>
                              </Row>
                              <span
                                onClick={() => addMore()}
                                style={{ width: "190px", cursor: "pointer", fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color:"#495057", fontWeight: "500",//float:"right" 
                               }}
                              >
                                {" "}
                                + Add New Services {" "}
                              </span>
                              <br />
                           
                            </CardBody>
                          </Col> 




                          <Col className="col-6 ">
                            <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            
                              <Row
                               style={{
                                width: "90px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                               color:"#495057",
                              
                                margin:"6px"
                                     }}>Videos :</Row>
                              <Row>
                                <Col xl="12">
                                  <Table
                                    style={{ textAlign: "center"}}
                                    id="video_links"
                                    className="table table-bordered dataTable"
                                  >
                                    <TableHead>
                                      <TableRow style={{ textAlign: "center" }}>
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          No.
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "90px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          URL
                                        </TableCell>
                                     
                                        
                                        {orders.length !== 1 ? (
                                          <TableCell
                                            style={{
                                              width: "0px",
                                              textAlign: "center",
                                              fontWeight: "500",
                                              paddingLeft: "0rem",
                                              paddingRight: "0rem",
                                              fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057",
                                            }}
                                          >
                                            Action
                                          </TableCell>
                                        ) : null}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {orders &&
                                        orders.map((item, index) => (
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              style={{ textAlign: "center", fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057", fontWeight: "500" }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell>
                                            <AvField
                                                style={customStyles}
                                                name="video_links"
                                                placeholder="Video URL "
                                                type="text"
                                                // errorMessage="Enter Video URL "
                                                className="form-control"
                                                // validate={{ required: { value: true } }}
                                                 id="validationCustom01"
                                                value={item?.video_links?.[index]? item.video_links : "" }
                                                onChange={(e) =>
                                                  handleTableInputchangeNew(e, index)
                                                }

                                              />
                                            </TableCell>                                       
                                             

                                            {orders.length !== 1 && (
                                              <i
                                              className="uil uil-times"
                                              style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    paddingLeft: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                              onClick={() => handleRemoveNew(index)}
                                              ></i>
                                           )}
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Col>
                              </Row>
                              <span
                                onClick={() => addMoreNew()}
                                style={{ width: "190px", cursor: "pointer", fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color:"#495057", fontWeight: "500",
                                //float:"right"
                                }}
                              >
                                {" "}
                                + Add New Video Links {" "}
                              </span>
                              <br />
                           
                            </CardBody>
                          </Col> 


 
                          <Col md="3">
                            <div className="mb-2" style={{ paddingTop: "25px" }}>
                              {caseStudyToUpdate ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                   disabled={addCase ? true : false} 
                                 > 
                                   {addCase ? "Updating" : "Update"} 
                                </Button>
                              ) : ( 
                                  
                                <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                  disabled={addCase ? true : false}
                                >
                                  {addCase ? "Adding" : "Submit"}
                                </Button>
                              )} 
                              <Button
                                color="danger"
                                type="reset"
                                onClick={() =>
                                  reset()}
                              >
                                {"  "}
                                Reset
                              </Button>
                            </div>
                          </Col>



                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody>
                      <MDBDataTable
                        id="CaseTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>

        {/* pay modal end */}
      </>
  );
};

  export default Casestudy;