import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table as table,
  Input,
} from "reactstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Staff_Salary.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const [values, setValues] = useState([{ leaveType: "", leaveDays: null }]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "Basic Salary",
        field: "BSalary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Hr Cost",
        field: "HrCost",
        sort: "asc",
        width: 100,
      },
      {
        label: "Hr Rate",
        field: "HrRate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Hr Salary",
        field: "HrSalary",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "ABy",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      {
        id: "1",
        date: "25-04-2011",
        staff: "Mythree",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        date: "28-04-2011",
        staff: "Nihal",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "3",
        date: "29-04-2011",
        staff: "Abhirag",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "4",
        date: "28-04-2011",
        staff: "Namitha",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "5",
        date: "26-04-2011",
        staff: "Harshita",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available Leaves(days)",
        field: "ALeaves",
        sort: "asc",
        width: 270,
      },
    ],
    rows1: [
      {
        id: "1",
        name: "Anitha",
        Salarytype: "",
      },
      {
        id: "1",
        LType: "Sick Leave",

        ALeaves: "12",
      },
      {
        id: "2",
        LType: "Casual Leave",

        ALeaves: "5",
      },
    ],
  };

  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      options: [
        { label: "Admin", value: "admin" },
        { label: "Cashier", value: "cashier" },
        { label: "Receptionist", value: "receptionist" },
        { label: "Hr", value: "hr" },
        { label: "Pr", value: "pr" },
      ],
    },
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };

  const decreamentCount = () => {
    setCount1(count1 - 1);
  };

  const decreamentCount1 = () => {
    setCount2(count2 - 1);
  };

  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const addMore = () => {
    setValues([...values, { leaveType: "", leaveDays: null }]);
  };
  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];
    let freebagArray = [];

    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
        freebagArray.push(element.freeBag);
      });

    setValues(list);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Staff Salary" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Salary Type</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Basic Salary
                          </Label>
                          <AvField
                            name="basic_salary"
                            placeholder="Basic Salary"
                            type="text"
                            errorMessage="Enter Basic Salary"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Hourly Cost
                          </Label>
                          <AvField
                            name="hourly_cost"
                            placeholder="Hourly Cost"
                            type="text"
                            errorMessage="Enter Hourly Cost"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Hourly Rate
                          </Label>
                          <AvField
                            name="hourly_rate"
                            placeholder="Hourly Rate"
                            type="text"
                            errorMessage="Enter Hourly Rate"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Hourly Salary
                          </Label>
                          <AvField
                            name="hourly_salary"
                            placeholder="Hourly Salary"
                            type="text"
                            errorMessage="Enter Hourly Salary"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      {/* Leave type start*/}
                      <Row>
                        <Col xl="6">
                          <Table
                            style={{ textAlign: "center" }}
                            id="waste_id"
                            className="table table-bordered dataTable"
                          >
                            <TableHead>
                              <TableRow style={{ textAlign: "center" }}>
                                <TableCell
                                  style={{ width: "10px", textAlign: "center" }}
                                >
                                  #
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "280px",
                                    textAlign: "center",
                                  }}
                                >
                                  Leave Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "123px",
                                    textAlign: "center",
                                  }}
                                >
                                  Avail No.of Leave in days
                                </TableCell>
                                {values.length !== 1 ? (
                                  <TableCell
                                    style={{
                                      width: "10px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Action
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ textAlign: "center" }}
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell>
                                      <Select
                                        // onChange={(selected) =>
                                        //   handleChangeWasteItem(selected, index)
                                        // }
                                        name="wasteName"
                                        classNamePrefix="select2-selection"
                                        // options={wasteItemOptions}
                                        options={[
                                          {
                                            label: "Seek leave",
                                            value: 0,
                                          },
                                        ]}
                                        value={{
                                          label: item.wasteName,
                                          value: item.wastename_id,
                                        }}
                                        id={`validationCustom03${index}`}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <AvField
                                        name="freeBag"
                                        value={item.freeBag}
                                        type="number"
                                        min="0"
                                        errorMessage=""
                                        className="form-control"
                                        id="validationCustom05"
                                        // onChange={(e) =>
                                        //   handleTableInputchange(e, index)
                                        // }
                                      />
                                    </TableCell>
                                    {values.length !== 1 && (
                                      <i
                                        className="uil uil-times"
                                        style={{
                                          fontSize: "20px",
                                          cursor: "pointer",
                                          paddingLeft: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => handleRemove(index)}
                                      ></i>
                                    )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Col>
                      </Row>
                      <span
                        onClick={() => addMore()}
                        style={{ width: "190px", cursor: "pointer" }}
                      >
                        {" "}
                        + Add New Row{" "}
                      </span>
                      <br />
                      {/*leave type End*/}
                    </Row>
                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-2">
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          {/* <Save></Save> */}
                          {"  "}
                          Submit
                        </Button>
                        <Button color="danger" type="submit">
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="staffTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton onClick={handleCloseModal}>
            <Modal.Title>Staff Salary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack direction="row" spacing={2} style={{ flexDirection: "row" }}>
              <div style={{ padding: "20px" }}>
                <b>Staff:</b>
                <p>Mythree</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Salary Type:</b>
                <p>Monthly</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Basic Salary : </b>
                <p>100000</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Cost : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Rate : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Salary: </b>
                <p>300</p>
              </div>
            </Stack>
            {/* Modal Body Start */}
            <MDBDataTable responsive bordered data={data1} />
            {/* Modal Body End */}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
