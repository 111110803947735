import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment"
import { timeSince } from "../../../helpers/globalFunctions";
import { withTranslation } from "react-i18next"
import toastr from "toastr";
const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const location = useLocation();
  const [menu, setMenu] = useState(false)
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const getNotificationCount = () => {
    axios.get(API_URL + "notification/get-count", {
      headers: {
        'x-access-token': accessToken
      }
    }).then((res) => {
      setCount(res.data.data.length);
      setList(res.data.data);
    });
  }
  const handleClickReadAll = () => {
    axios.post(API_URL + "notification/read-all", {}, {
      headers: {
        'x-access-token': accessToken
      }
    }).then((res) => {
      if (location.pathname == "/notifications")
        window.location.href = "/notifications";
      setMenu(false);
    });
  }
  useEffect(() => {
    getNotificationCount();
  }, [])
  useEffect(() => {
    // const sockets = io(API_URL, { transports: ["websocket"] });
    // sockets.on("receipt", (msg) => {
    //   dispatch(getAllMenstions());
    // });
    // return () => sockets.disconnect();

    // const sockets = io(API_URL, {
    //   reconnect: false,
    //   transports: ["websocket"],
    //   allowUpgrades: false,
    //   pingTimeout: 180000,
    //   pingInterval: 25000,
    // });
    // sockets.on("notification", (msg) => {
    //   getNotificationCount();
    // });
    // return () => sockets.disconnect();
    setInterval(function () {
      getNotificationCount();
    }, 60000);

  }, []);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (count == 0)
            setMenu(false);
          else
            setMenu(!menu);
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-danger rounded-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small" onClick={handleClickReadAll}>
                  {" "}
                  Mark all as read
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar>
            {
              list && list.map((item, index) => {
                return (
                  <Link to="" className="text-reset notification-item">
                    <div className="d-flex align-items-start">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle font-size-16" style={{ backgroundColor: "#1a71b5" }}>
                          <i className="fas fa-bell" />
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1">
                          {item?.title}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {item?.body}
                          </p>
                          <Row>
                            <Col md="5">
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline" />{" "}
                                {timeSince(new Date(moment(item?.date).format("YYYY-MM-DD HH:mm:ss")), new Date(moment().format("YYYY-MM-DD HH:mm:ss")))}
                              </p>
                            </Col>
                            <Col md="7">
                              <p className="mb-0">
                                {moment(item?.date).format("DD-MM-YYYY hh:mm a")}
                              </p>
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </div>
                  </Link>
                )

              })
            }

          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notifications"
            ><i className="uil-arrow-circle-right me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}