import {
  GET_ACCDETAILS,
  GET_ACCDETAILS_FAIL,
  GET_ACCDETAILS_SUCCESS,
  ADD_ACCDETAIL,
  ADD_ACCDETAIL_FAIL,
  ADD_ACCDETAIL_SUCCESS,
  DELETE_ACCDETAIL,
  DELETE_ACCDETAIL_SUCCESS,
  DELETE_ACCDETAIL_FAIL,
  UPDATE_ACCDETAIL,
  UPDATE_ACCDETAIL_SUCCESS,
  UPDATE_ACCDETAIL_FAIL,
  GET_ACCDETAIL_OPTIONS_SUCCESS,
  GET_ACCDETAIL_OPTIONS_FAIL
} from './actionTypes';

const INIT_STATE = {
  accdetails: [],
  addingAccdetail: false,
  deletingAccdetail: false,
  addAccdetailResponse: {},
  updateAccdetailResponse: {},
  deleteAccdetailResponse: {},
  accdetailIdToBeDeleted: '',
  accdetail: {},
  error: {},
 accdetailOptions: [],
};

const accdetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCDETAILS:
      return {
        ...state,
        params: action.payload,
      };

    case GET_ACCDETAILS_SUCCESS:
      return {
        ...state,
        accdetails: action.payload.data,
      };

    case GET_ACCDETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_ACCDETAIL:
      return {
        ...state,
        addingAccdetail: true,
        accdetail: action.payload,
      };

    case ADD_ACCDETAIL_SUCCESS:
      /*let newAccdetails = state.accdetails.filter((accdetail) => {
          return accdetail;
         });*/
      let newAccdetails = [JSON.parse(JSON.stringify(action.payload.data[0]))];
      newAccdetails.push(...state.accdetails);
      //console.log(...state.accdetails);
      //console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
      return {
        ...state,
        addingAccdetail: false,
        addAccdetailResponse: {
          type: 'success',
          message: 'Account Detail Type added successfully',
        },
        accdetails: newAccdetails,
        error: {},
      };

    case ADD_ACCDETAIL_FAIL:
      return {
        ...state,
        addingAccdetail: false,
        addAccdetailResponse: { type: 'failure', message: 'Adding accdetail failed' },
        error: action.payload,
      };
    case DELETE_ACCDETAIL:
      return {
        ...state,
        deletingAccdetail: true,
        accdetailIdToBeDeleted: action.payload,
      };

    case DELETE_ACCDETAIL_SUCCESS:
      let newAccdetails2 = state.accdetails.filter((accdetail) => {
        return accdetail.accdetail_id != state.accdetailIdToBeDeleted;
      });
      return {
        ...state,
        deletingAccdetail: false,
        accdetailIdToBeDeleted: '',
        deletingAccdetail: false,
        deleteAccdetailResponse: {
          type: 'success',
          message: 'Account Detail Type deleted successfully',
        },
        accdetails: newAccdetails2,
      };

    case DELETE_ACCDETAIL_FAIL:
      return {
        ...state,
        deletingAccdetail: false,
        deleteAccdetailResponse: {
          type: 'failure',
          message: 'Deleting Account Detail Type failed',
        },
        error: action.payload,
      };

    case UPDATE_ACCDETAIL:
      return {
        ...state,
        updatingAccdetail: true,
        accdetail: action.payload,
      };

    case UPDATE_ACCDETAIL_SUCCESS:
      let newaccdetailarr = [];
      let newAccdetails1 = state.accdetails.filter((accdetail) => {
        if (accdetail.accdetail_id == state.accdetail.accdetail_id) {
          newaccdetailarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
        } else {
          //console.log(accdetail);
          newaccdetailarr.push(accdetail);
        }
      });

      return {
        ...state,
        updatingAccdetail: false,
        accdetail: '',
        updatingAccdetail: false,
        updateAccdetailResponse: {
          type: 'success',
          message: 'Account Detail Type updated successfully',
        },
        accdetails: newaccdetailarr,
      };

    case UPDATE_ACCDETAIL_FAIL:
      return {
        ...state,
        updatingAccdetail: false,
        updateAccdetailResponse: {
          type: 'failure',
          message: 'Updating accdetail failed',
        },
        error: action.payload,
      };
      case GET_ACCDETAIL_OPTIONS_SUCCESS:
        return {
          ...state,
          accdetailOptions: action.payload,
        };
  
      case GET_ACCDETAIL_OPTIONS_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    default:
      return state;
  }
};

export default accdetails;
