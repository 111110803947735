import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Row,Col,Card,CardBody } from "reactstrap";
const MyTask = (props)=>{
  return (
    <>
    <React.Fragment>
    <div class="page-content">
      <div className="container-fluid">
      <Breadcrumbs title="Home" breadcrumbItem="My Task" />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>

            </CardBody>
          </Card>
        </Col>
      </Row>
      </div>
    </div>
    </React.Fragment>
    </>
  )

}
export default MyTask;