//LOGIN
export const POST_LOGIN = "/login";

export const CHECK_AUTH = "/auth";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

// Menu Options

export const GET_Menu_OPTIONS = '/menu/list';

//USERS
export const GET_USERS = "/user/list";
export const ADD_USER = "/user";
export const GET_USER = "/user";
export const UPDATE_USER = "/user";
export const DELETE_USER = "/user";
export const GET_USERS_PROFILE = "api/staff/user_profile";
export const GET_USERS_STATUS = "user/status";

//DISTRICT
export const GET_DISTRICTS = "/district/list";
export const ADD_DISTRICT = "/district";
export const GET_DISTRICT = "/district";
export const UPDATE_DISTRICT = "/district";
export const DELETE_DISTRICT = "/district";
export const GET_DISTRICT_OPTIONS = "/district/list/options";

//WARDS
export const GET_WARDS = "/ward/list";
export const ADD_WARD = "/ward";
export const GET_WARD = "/ward";
export const UPDATE_WARD = "/ward";
export const DELETE_WARD = "/ward";


//LOCALBODY
export const GET_TYPES_LOCALBODIES="localbody/list/options/typesLocalbody"
export const GET_LOCALBODY_TYPES="/localbody/list/options/types"
export const GET_LOCALBODIES = "/localbody/list";
export const ADD_LOCALBODY = "/localbody";
export const GET_LOCALBODY = "/localbody/list";
export const UPDATE_LOCALBODY = "/localbody";
export const DELETE_LOCALBODY = "/localbody";
export const GET_WARD_OPTIONS="/ward/list/options";
export const GET_LOCALBODY_OPTIONS="/localbody/list/options";


// Privilages options
export const GET_PRIVILAGES = '/privilage/list';
export const ADD_PRIVILAGE = '/privilage';
export const GET_PRIVILAGE = '/privilage';
export const UPDATE_PRIVILAGE = '/privilage/update';
export const DELETE_PRIVILAGE = '/privilage';
export const GET_PRIVILAGES_OPTIONS = "/privilage/list";
export const GET_PRIVILAGES_MODULES = "privilage/module_options";
// Companies options
export const GET_COMPANIES_OPTIONS = "/company/list/options";
export const GET_COMPANIES_MASTER_OPTIONS = "/company/list/single_options";
export const GET_LOCALBODIES_MASTER_OPTIONS = "/company/list/localbodies";
export const ADD_COMPANY = "/company/";
export const UPDATE_COMPANY = "company/update";
export const DELETE_COMPANY = "/company";

// Branches options
export const GET_BRANCHES_OPTIONS = "/branch/list/options";

export const GET_USER_PROFILE = "/user";

//Groups
export const GET_GROUPS= "/group/list";
export const ADD_GROUP = "/group";
export const GET_GROUP = "/group";
export const UPDATE_GROUP = "/group";
export const DELETE_GROUP = "/group";
export const GET_GROUP_OPTIONS="/group/list/options"
export const GET_WARDS_GROUP_OPTIONS="/group/list/options"
//get customers
export const GET_CUSTOMERS = "/customers/list";
export const GET_CUST_VISITLOG = "/customer/visitLog";
export const GET_CUST_RECEIPTS = "/customer/receipts";
export const GET_CUST_INVOICE = "/customer/invoice";
export const GET_CUST_STATEMENT = "/customer/statement";
export const ADD_CUSTOMERS = "/customers";
export const UPDATE_CUSTOMERS = "/customers/update";
export const GET_CUSTOMER_TYPE = "api/staff/customertypes";
export const DELETE_CUSTOMERS = "/customers";

//get wasteitems
export const GET_WASTE_ITEMS_OPTIONS="/wasteItems/list/options"
export const GET_WASTEITEMS= "/wasteItems/list";
export const GET_WASTE_TYPES= "/wasteItems/list/types";
export const GET_WASTE_CATEGORIES= "/wasteItems/list/categories";
export const ADD_WASTE_ITEM = "/wasteItems/upload";
export const UPDATE_WASTE_ITEM = "/wasteItems/update";
export const DELETE_WASTE_ITEM = "/wasteItems";

//get custome categories
export const GET_CUST_CATEGORIES_OPTIONS="/customerCategory/list/options"
export const GET_CUST_CATEGORIES="/customerCategory/list";
export const ADD_CUST_CATEGORY = "/customerCategory";
export const GET_CUST_CATEGORY = "/customerCategory";
export const UPDATE_CUST_CATEGORY = "/customerCategory";
export const DELETE_CUST_CATEGORY = "/customerCategory";

export const GET_QRCODE="/qrcode/list";
export const ADD_QRCODE = "/qrcode";

export const GET_TARIFF="/tariff/list";
export const GET_TARIFF_OPTIONS="/tariff/list/options";
export const UPDATE_TARIFF_STATUS = "/tariff";

export const GET_INVOICE="/invoice/list";
export const GET_INVOICE_LIST="/invoice/list/invoices";

export const GET_RECEIPTS="/receipt/list";

//Add Tariff

export const GET_PACKAGES="/addTariff/list";
export const ADD_PACKAGE="/addTariff";
export const UPDATE_PACKAGE="/addTariff";
export const DELETE_PACKAGE="/addTariff"
export const UPDATE_PACKAGE_STATUS = "/addtariff/update";

//account type
export const GET_ACCTYPES = '/account_type';
export const ADD_ACCTYPE = '/account_type';
export const GET_ACCTYPE = '/account_type';
export const UPDATE_ACCTYPE = '/account_type';
export const DELETE_ACCTYPE = '/account_type';
export const GET_ACCTYPE_OPTIONS = '/account_type/options';

//account DETAIL
export const GET_ACCDETAILS = '/account_detail';
export const ADD_ACCDETAIL = '/account_detail';
export const GET_ACCDETAIL = '/account_detail';
export const UPDATE_ACCDETAIL = '/account_detail';
export const DELETE_ACCDETAIL = '/account_detail';

// chart of accounts
export const ADD_CHARTOFACC = "/chart_of_account";
export const GET_CHARTOFACC = "/chart_of_account";
export const UPDATE_CHARTOFACC = "/chart_of_account";
export const DELETE_CHARTOFACC = "/chart_of_account";

// credit debit fund
export const GET_CREDIT_DEBIT = "/credit_debit";
export const ADD_CREDIT_DEBIT = "/credit_debit";

// fund transfer
export const GET_FUND_TRANSFER = "/fund_transfer";
export const ADD_FUND_TRANSFER = "/fund_transfer";
export const GET_FUND_SOURCE = "/chart_of_account/fund_source";

//Income Expenses
export const GET_INCOME_EXPENSE = "/income_expense";
export const GET_INCOME_EXPENSE_ACC_TYPE = "/account_type/income_expense_acc_types";
export const ADD_INCOME_EXPENSE = "/income_expense";

//popup
export const ADD_POPUP = "/popup";
export const GET_POPUP = "/popup/list";
export const UPDATE_POPUP = "/popup";
export const DELETE_POPUP = "/popup";

//menstions
export const GET_MENTIONS = '/twitter/getmention';