import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, Collapse, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import Select from "react-select";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./screenshort.scss"
import SimpleImageSlider from "react-simple-image-slider";
const Screenshort = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [from_date, setFromDate] = useState(moment().format("YYYY-MM-DD"));
    const [from_time, setFromTime] = useState();
    const [to_time, setToTime] = useState();
    const [listData, setListData] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [optionTeam, setOptionTeam] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [optionStaff, setOptionStaff] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [optionProjects, setOptionProjects] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [optionTasks, setOptionTasks] = useState([]);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [optionFlows, setOptionFlows] = useState([]);
    const [images, setImages] = useState([]);
    const [openModel, setOpenModel] = useState(false);
    const [sliderStartIndex, setSliderStartIndex] = useState(0);

    const getData = (date = "", ftime = "", ttime = "", team = "", staff = "", project = "", task = "", flow = "") => {
        if (date == "")
            date = moment().format("YYYY-MM-DD");
        axios.get(`${API_URL}activity/screenshort-list?date=` + date + "&team=" + team + "&staff=" + staff + "&from_time=" + ftime + "&to_time=" + ttime + "&project=" + project + "&task=" + task + "&flow=" + flow, {
            headers: {
                "x-access-token": accessToken,
            },
        }).then((response) => {
            var data = response.data.data;
            setListData(data);
        });
    }
    function handleTeamOptions() {
        axios
            .get(`${API_URL}activity/team_options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((response) => {
                var list = [];
                var data = response.data.data;
                data.map((item, index) => {
                    list.push({ label: item.name, value: item._id });
                });
                setOptionTeam(list);
                if (data.length == 1) {
                    setSelectedTeam(list[0]);
                    getData(from_date, from_time, to_time, data[0]._id, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value);
                }
                else {
                    getData(from_date, from_time, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value);
                }

            })
    }
    function handleStaffOptions(team = "") {
        axios
            .get(`${API_URL}activity/staff_options?team=` + team, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((response) => {
                var list = [];
                var data = response.data.data;
                data.map((item, index) => {
                    if (data.length == 1) {
                        setSelectedStaff({ label: item.firstName, value: item._id });
                    }
                    list.push({ label: item.firstName, value: item._id });
                });
                setOptionStaff(list);
                if (team == "") {
                    if (data.length == 1) {
                        getData(from_date, from_time, to_time, selectedTeam?.value, data[0]._id, selectedProject?.value, selectedTask?.value, selectedFlow?.value);
                    }
                    else {
                        getData(from_date, from_time, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value);
                    }
                }


            })
    }
    function getProjects() {
        axios
            .get(`${API_URL}activity/project_options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((response) => {
                var list = [];
                list.push({ label: "Non Project", value: "0" });
                var data = response.data.data;
                data.map((item, index) => {
                    list.push({ label: item.shortcode, value: item._id });
                });
                setOptionProjects(list);
            })
    }
    function getTasks(project_id) {
        axios
            .get(`${API_URL}activity/task_options?project_id=` + project_id, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((response) => {
                var list = [];
                var data = response.data.data;
                data.map((item, index) => {
                    list.push({ label: item.task_name, value: item._id, cate_id: item?.task_subcategory_id });
                });
                setOptionTasks(list);
            })
    }
    function getFlows(task_id, category_id) {
        axios
            .get(`${API_URL}activity/flow_options?task_id=` + task_id + "&category=" + category_id, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((response) => {
                var list = [];
                var data = response.data.data;
                data.map((item, index) => {
                    list.push({ label: item.item_name, value: item._id });
                });
                setOptionFlows(list);
            })
    }
    function setTab(index, value) {
        console.log(value);
        var list = [...listData];
        list[index].isShow = value;
        console.log(list);
        setListData(list);
    }
    function openSlider(index, index1) {
        var list = [...listData];
        var images = list[index].images;
        setImages(images);
        setSliderStartIndex(index1)
        setOpenModel(true);
    }
    function reset() {
        setFromDate(moment().format("YYYY-MM-DD"));
        setFromTime("");
        setToTime("");
        setSelectedTeam(null);
        setSelectedStaff(null);
        setSelectedProject(null);
        setSelectedTask(null);
        setOptionTasks([]);
        setSelectedFlow(null);
        setOptionFlows([]);
        getData();
    }
    useEffect(() => {
        // getData();
        handleStaffOptions();
        handleTeamOptions();
        getProjects();

    }, [])

    return (
        <React.Fragment>
            <Modal show={openModel} size="lg" centered={true} id="SliderModal">
                <div className="modal-header">
                    <button
                        type="button"
                        onClick={() => {
                            setOpenModel(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ padding: '15px' }}
                    ></button>
                </div>
                <div className="modal-body">
                    <SimpleImageSlider
                        width={896}
                        height={504}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        startIndex={sliderStartIndex}
                    />
                </div>
            </Modal>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Screenshot" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <Label>Date</Label>
                                            <input type="date"
                                                value={from_date}
                                                name="from_date"
                                                className="form-control"
                                                onChange={(e) => {
                                                    getData(e.target.value, from_time, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value)
                                                    setFromDate(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>From</Label>
                                            <input type="time"
                                                value={from_time}
                                                name="from_date"
                                                className="form-control"
                                                onChange={(e) => {
                                                    getData(from_date, e.target.value, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value)
                                                    setFromTime(moment(e.target.value, "HH:mm").format("HH:mm:ss").toString());
                                                }}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>To</Label>
                                            <input type="time"
                                                value={to_time}
                                                name="to_date"
                                                className="form-control"
                                                onChange={(e) => {
                                                    getData(from_date, from_time, e.target.value, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value)
                                                    setToTime(moment(e.target.value, "HH:mm").format("HH:mm:ss").toString());
                                                }}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Team</Label>
                                            <Select
                                                placeholder="Team"
                                                value={selectedTeam}
                                                onChange={(e) => {

                                                    setSelectedTeam(e);
                                                    setSelectedStaff(null);
                                                    setOptionStaff([]);
                                                    handleStaffOptions(e.value);
                                                    getData(from_date, from_time, to_time, e.value, "", selectedProject?.value, selectedTask?.value, selectedFlow?.value);
                                                }}
                                                options={optionTeam}
                                                classNamePrefix="select2-selection"
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Staff</Label>
                                            <Select
                                                placeholder="Staff"
                                                value={selectedStaff}
                                                onChange={(e) => {
                                                    getData(from_date, from_time, to_time, selectedTeam?.value, e.value, selectedProject?.value, selectedTask?.value, selectedFlow?.value)
                                                    setSelectedStaff(e);
                                                }}
                                                options={optionStaff}
                                                classNamePrefix="select2-selection"
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Project</Label>
                                            <Select
                                                placeholder="project"
                                                value={selectedProject}
                                                onChange={(e) => {
                                                    getData(from_date, from_time, to_time, selectedTeam?.value, selectedStaff?.value, e.value, "", "")
                                                    setSelectedProject(e);
                                                    setOptionTasks([]);
                                                    setSelectedTask(null);
                                                    setSelectedFlow(null);
                                                    setOptionFlows([]);
                                                    getTasks(e.value);
                                                }}
                                                options={optionProjects}
                                                classNamePrefix="select2-selection"
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Tasks</Label>
                                            <Select
                                                placeholder="Tasks"
                                                value={selectedTask}
                                                onChange={(e) => {
                                                    getData(from_date, from_time, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, e.value, "")
                                                    setSelectedTask(e);
                                                    setSelectedFlow(null);
                                                    setOptionFlows([]);
                                                    getFlows(e.value, e.cate_id)
                                                }}
                                                options={optionTasks}
                                                classNamePrefix="select2-selection"
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Flows</Label>
                                            <Select
                                                placeholder="Flows"
                                                value={selectedFlow}
                                                onChange={(e) => {
                                                    getData(from_date, from_time, to_time, selectedTeam?.value, selectedStaff?.value, selectedProject?.value, selectedTask?.value, e.value)
                                                    setSelectedFlow(e);
                                                }}
                                                options={optionFlows}
                                                classNamePrefix="select2-selection"
                                                menuPosition="fixed"
                                            />
                                        </Col>
                                        <Col md="3" className="mt-2" style={{ paddingTop: "20px" }}>
                                            <div className="mb-2">

                                                <Button color="danger" type="submit"
                                                    onClick={reset}
                                                >
                                                    {"  "}
                                                    Reset
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>

                        <Col md="12">

                            {
                                listData.map((item, index) => {
                                    return (
                                        <>
                                            <div className="accordion" id="accordionFlushExample">
                                                <div className="accordion-item" style={{ marginBottom: "5px" }}>
                                                    {
                                                        item.isShow == false ? (
                                                            <>
                                                                <h2 className="accordion-header" id="flush-headingOne" style={{ backgroundColor: "white" }}>
                                                                    <button className="accordion-button collapsed" type="button"
                                                                        onClick={() => {
                                                                            setTab(index, !item.isShow)
                                                                        }}
                                                                        style={{ cursor: "pointer" }}>
                                                                        #{index + 1} {item.firstName} {item.lastName}
                                                                    </button>
                                                                </h2></>
                                                        ) : (
                                                            <>
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button" type="button"
                                                                        onClick={() => {
                                                                            setTab(index, !item.isShow)
                                                                        }}
                                                                        style={{ cursor: "pointer" }}>
                                                                        #{index + 1} {item.firstName} {item.lastName}
                                                                    </button>
                                                                </h2>
                                                            </>


                                                        )
                                                    }

                                                    <Collapse id="flush-collapseOne" className={item.isShow == true ? "accordion-collapse show" : "accordion-collapse"}
                                                        isOpen={item.isShow}>
                                                        <div className="accordion-body" style={{ backgroundColor: "white" }}>
                                                            <Row>
                                                                {
                                                                    item.data?.map((sub, index1) => (
                                                                        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 margin-bottom-xs">
                                                                            <Card>

                                                                                <div className="activity-screenshot">
                                                                                    <div title="" class="label-project-name" data-original-title={sub.firstName + " " + sub.lastName}>{sub.firstName + " " + sub.lastName}</div>
                                                                                </div>
                                                                                <div className="top-shot-body" onClick={() => {
                                                                                    openSlider(index, index1);
                                                                                }}>
                                                                                    <div className="shot-body">
                                                                                        <div className="state_shot">
                                                                                            <img class="no-action" src={API_URL + `uploads/screenshorts/` + sub.image} />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="screen-count-pill">{subsub.count} Screens</div> */}
                                                                                    <div class="meta">
                                                                                        <div class="meta-head">
                                                                                            <div class="meta-interval">{moment(sub.time, "HH:mm:ss").format("hh:mm a")}</div>
                                                                                        </div>
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            overlay={<Tooltip id="tooltip-disabled">Overall: {Math.round(sub.overall) + "%"},<br></br> Mouse: {Math.round(sub.mouse) + "%"}, Keyboard:  {Math.round(sub.keyboard) + "%"}</Tooltip>}
                                                                                        >
                                                                                            <div class="mb-2 progress-sm progress" style={{ height: "5px", cursor: "pointer" }}>
                                                                                                <div class={(Math.round(sub.overall) >= 51) ? "progress-bar bg-success" : ((Math.round(sub.overall) >= 21) ? "progress-bar bg-warning" : "progress-bar bg-danger")} role="progressbar" aria-valuenow={Math.round(sub.overall)} aria-valuemin="0" aria-valuemax="100" style={{ width: Math.round(sub.overall) + "%" }}>
                                                                                                </div>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                        <div class="meta-head">
                                                                                            <div class="meta-interval">{Math.round(sub.overall)}% of {Math.round(sub.min)} min</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card>

                                                                        </div>
                                                                    ))
                                                                }
                                                            </Row>

                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </>

                                    )

                                })
                            }

                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment >
    )
}
export default Screenshort;