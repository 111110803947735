import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./manageStaff.scss";
import axios from "axios";
import toastr from "toastr";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SweetAlert from "react-bootstrap-sweetalert";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const Manage_Staff = () => {
  const [masterObject, setmasterObject] = useState({});
  const [teamIdTobeUpdated, setTeamIdToBeUpdated] = useState(null);
  const[teamIdToBeDeleted,setTeamIdToBeDeleted]=useState(null)
  const[confirmDeleteAlert,setConfirmDeleteAlert]=useState(null);
  const[confirmDeleteMemberAlert,setConfirmDeleteMemberAlert]=useState(null);
  const[memberIdToBeDeleted,setMemberIdToBeDeleted]=useState({})


  const [selectedManager,setSelectedManager]=useState(null);
  const [selectedCordinator,setSelectedCordinator]=useState(null);
  const [selectedLeader,setSelectedLeader]=useState(null);
  const [selectedMembers,setSelectedMembers]=useState(null);
  const [users,setUsers]=useState([]);
  const [leader,setLeader]=useState([]);
  const [manager,setManager]=useState([]);

  const[tableData,setTableData]=useState([]);

  const [errors, setErrors] = useState({
    managerError: "",
    cordinatorError:"",
    leaderError:"",
    membersError:""
  });
  

  const addTeam =useSelector((state)=>state.addTeam);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    fetch_user();
    fetch_manager();
    fetch_leader();
    handleTableData();
  }, 
  []
);
function fetch_user(){
  axios
  .get(`${API_URL}user/options`,{
      headers:{
          "x-access-token":accessToken,
      }
  })
  .then((res)=>{
      var staff =
      res.data.data &&
      res.data.data.map((el) => {
       if(el.lastName)
        return {
          label: el?.firstName  + " " + el?.lastName ,
          value: el?._id,
        };
        else{
          return {
            label: el?.firstName ,
            value: el?._id,
          };
        }
      });

  setUsers([{options:staff}])
  })
}

function fetch_leader(){
  axios
  .get(`${API_URL}project/leader_list`,{
      headers:{
          "x-access-token":accessToken,
      }
  })
  .then((res)=>{
      var staff =
      res.data.data &&
      res.data.data.map((el) => {
       if(el.lastName)
        return {
          label: el?.firstName  + " " + el?.lastName ,
          value: el?._id,
        };
        else{
          return {
            label: el?.firstName ,
            value: el?._id,
          };
        }
      });

  setLeader([{options:staff}])
  })
}

function fetch_manager(){
  axios
  .get(`${API_URL}project/manager_list`,{
      headers:{
          "x-access-token":accessToken,
      }
  })
  .then((res)=>{
      var staff =
      res.data.data &&
      res.data.data.map((el) => {
       if(el.lastName)
        return {
          label: el?.firstName  + " " + el?.lastName ,
          value: el?._id,
        };
        else{
          return {
            label: el?.firstName ,
            value: el?._id,
          };
        }
      });

  setManager([{options:staff}])
  })
}


const toTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
function handleTableData() {
  var url = `${API_URL}teamManage/list`;
  axios
    .get(url, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      
      var result = res.data.data;
      let resData = [];
      result.map((item,index)=>{
        item.action=(
          <div style={{display:"flex",justifyContent:"center"}}>
             <i 
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                 toTop();
                preUpdateTeam(item);
              }}
          >
            </i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={()=>{
                setTeamIdToBeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}
              >
            </i>
          </div>
        )
        item.id=index+1;
        if(item?.manager){
          item.team_manager=(item.manager.firstName || '') + (item.manager.lastName || '')
      
        }
        if(item?.cordinator){
          item.team_cordinator=(item.cordinator.firstName || '') + (item.cordinator.lastName || '')
         
        }
        if(item?.leader){
          item.team_leader=(item.leader.firstName || '') + (item.leader.lastName || '')
          
        }
        item.team_member=(
        
          <div>
            <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
         
            {item.member &&
              item.member.map((el) => {
                let uid = item._id
                let itemd= el
                let indus=(el.firstName || '') + (el.lastName || '')
                return(
                  <li className="mb-2">
                      <span>
                      <RemoveCircleIcon 
                      style={{ color: "#f46a6a" ,cursor: "pointer",height:"16px"}}
                      itemd={itemd}
                      uid={uid}
                      onClick={() => {
                        //  deleteMember(itemd,uid);
                         setMemberIdToBeDeleted({_id:uid,member:itemd._id})
                         setConfirmDeleteMemberAlert(true);
                        }}
                     
                    />
                        {indus}
                     
                      </span>
                    </li>
                )
              })
            }
            </ul>
          </div>
        );

        
             
         

        resData.push(item)
        })
      setTableData(resData);
    });
}
 
// const deleteMember=(itemd,uid) =>{

//   let data={
//     _id:uid,
//     member:itemd._id
//   }
 

//   axios
//   .put(`${API_URL}teamManage/member/remove`, data, {
//     headers: {
//       "x-access-token": accessToken,
//     },
//     })
//     .then((res) => {
//       if (res.data.status === 200) {
    
//       handleTableData(); 
      
        
//       } else {
//         toastr.error(res.data.message);
//         return;
//       }
//     })
//     .catch((err) => {
//       toastr.error(err.response.data.message);
//       return;
//     });
// }




let preUpdateTeam = (item) =>{
  setTeamIdToBeUpdated(item._id);
  
 


  let manger=item?.manager
  let mangeropt={
    label:(manger.firstName || '') + (manger.lastName || ''),
    value:manger?._id
  };
  setSelectedManager(mangeropt);
  let cordinator=item?.cordinator
  let cordinatoropt={
    label:(cordinator.firstName || '') + (cordinator.lastName || ''),
    value:cordinator?._id
  };
  setSelectedCordinator(cordinatoropt);
  let leader=item?.leader
 

  let leaderopt={
    label:(leader.firstName || '') + (leader.lastName || ''),
    value:leader?._id
  };
  setSelectedLeader(leaderopt);


  let staffs=[];
    item.member &&
    item.member.map((el)=>{
      let array={
        label:(el.firstName || '') + (el.lastName || ''),
        value:el._id,
      }
      staffs.push(array)
    })
  setSelectedMembers(staffs);

  item.manager=item?.manager?._id
  item.cordinator=item?.cordinator?._id
  item.leader=item?.leader?._id
  item.member= item.member.map((el)=>{return(el._id)})
    

  setmasterObject(item);

  
};



const data = {
  columns: [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Team Name",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Team Manager",
      field: "team_manager",
      sort: "asc",
      width: 150,
    },
    {
      label: "Team Coordinator",
      field: "team_cordinator",
      sort: "asc",
      width: 150,
    },
    {
      label: "Team Leader",
      field: "team_leader",
      sort: "asc",
      width: 150,
    },
    {
      label: "Members",
      field: "team_member",
      sort: "asc",
      width: 150,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    },
  ],
  rows:tableData,
};

const handleFormData = e =>{
  let name = e.target.name;
  let value = e.target.value;
  setmasterObject({
    ...masterObject,
    [name]:value
  }) 
}

const handleSelectChange =(selected,name)=>{
  switch(name){
    case "manager":
      setSelectedManager(selected);
      setmasterObject({
        ...masterObject,
        manager:selected.value
      });
      setErrors({
        ...errors,
        managerError: "",
      });
    break;

    case "cordinator":
      setSelectedCordinator(selected);
      setmasterObject({
        ...masterObject,
        cordinator:selected.value
      });
      setErrors({
        ...errors,
        cordinatorError: "",
      });
    break;

    case "leader":
      setSelectedLeader(selected);
      setmasterObject({
        ...masterObject,
        leader:selected.value
      });
      setErrors({
        ...errors,
        leaderError: "",
      });
    break;

    case "member":
      setSelectedMembers(selected);
      let memberArray = [];
      selected &&
        selected.map((element) => {
          memberArray.push(element.value);
        });
     setmasterObject({
        ...masterObject,
        member:memberArray
      });
      setErrors({
        ...errors,
        membersError: "",
      });
    break;
  
    default:
      break;
  }
}

const handleValidSubmit =()=>{

    if( selectedManager === null || selectedCordinator === null || selectedLeader === null ||  selectedMembers === null){
      let errorVal = errors;
      if(selectedManager === null){
      errorVal.managerError ="Please Select a Team Manager";
      setErrors({
        ...errorVal,
      });
      }
    if(selectedCordinator === null){
    errorVal.cordinatorError ="Please Select a Team Coordinator";
    setErrors({
      ...errorVal,
    });
   }
   if(selectedLeader === null){
    errorVal.leaderError ="Please Select a Team Leader";
    setErrors({
      ...errorVal,
    });
   }
   if(selectedMembers === null){
    errorVal.membersError ="Please Select Members";
    setErrors({
      ...errorVal,
    });
   }
    
    
    
  }

      else {
        if(teamIdTobeUpdated) {
        axios
          .put(
            `${API_URL}teamManage` ,
            masterObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Team updated successfully");
              handleTableData();
         
              setSelectedManager(null);
              setSelectedCordinator(null);
              setSelectedLeader(null);
              setSelectedMembers(null);
            
              
              formRef.current.reset();

              setErrors({
                ...errors,
                managerError: "",
                cordinatorError:"",
                leaderError:"",
                membersError:""
              });

              setmasterObject({});

              setTeamIdToBeUpdated(null);
            } else {
              toastr.error("Failed to update Team");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } 
      else {
        axios
          .post(`${API_URL}teamManage`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
          
            if (res.data.status === 200) {
              toastr.success("Team created successfully");
              handleTableData();
            
              setSelectedManager(null);
              setSelectedCordinator(null);
              setSelectedLeader(null);
              setSelectedMembers(null);

              formRef.current.reset();

              setErrors({
                ...errors,
                managerError: "",
                cordinatorError:"",
                leaderError:"",
                membersError:""
              });

              setmasterObject({});
            } else {
              toastr.error("Failed to create Team");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  }

  const reset=() =>{
    handleTableData();
    setSelectedManager(null);
    setSelectedCordinator(null);
    setSelectedLeader(null);
    setSelectedMembers(null);
    formRef.current.reset();
    setErrors({
      ...errors,
      managerError: "",
      cordinatorError:"",
      leaderError:"",
      membersError:""
    });
     setmasterObject({});
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>
        {confirmDeleteMemberAlert ? (
            <SweetAlert
              title=""
              showCancel
              confirmButtonText="Delete"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                 axios
              .put(`${API_URL}teamManage/member/remove`,memberIdToBeDeleted , {
                headers: {
                  "x-access-token": accessToken,
                },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                
                  handleTableData(); 
                  
                    
                  } else {
                    toastr.error(res.data.message);
                    return;
                  }
                })
                .catch((err) => {
                  toastr.error(err.response.data.message);
                  return;
                });
                setConfirmDeleteMemberAlert(false);
              }}
              onCancel={() => setConfirmDeleteMemberAlert(false)}
            >
              Are you sure you want to delete it?
            </SweetAlert>
          ) : null}
        {confirmDeleteAlert ? (
            <SweetAlert
              title=""
              showCancel
              confirmButtonText="Delete"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                axios
                  .delete(
                    `${API_URL}teamManage` + "/" + teamIdToBeDeleted,
                    {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === 200) {
                      toastr.success("Team deleted successfully");
                      if (
                        masterObject &&
                        masterObject._id === teamIdToBeDeleted
                      ) {
                        formRef.current.reset();
                        setmasterObject({});
                        setTeamIdToBeUpdated(null);
                        setSelectedManager(null);
                        setSelectedCordinator(null);
                        setSelectedLeader(null);
                        setSelectedMembers(null);
                      
                        setErrors({
                          ...errors,
                          managerError: "",
                          cordinatorError:"",
                          leaderError:"",
                          membersError:""
                        });
                      }
                      handleTableData();
                    } else {
                      toastr.error(
                        res.data.message,
                        "Failed to delete Team"
                      );
                      return;
                    }
                  });
                setConfirmDeleteAlert(false);
              }}
              onCancel={() => setConfirmDeleteAlert(false)}
            >
              Are you sure you want to delete it?
            </SweetAlert>
          ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Team" />
          <Row>
          <Col className="col-12">
                  <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody>
                      <AvForm
                        ref={formRef}
                        className="needs-validation"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                    <Row>
                    <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Team Name
                            </Label>
                            <AvField
                               style={customStyles}
                              name="name"
                              placeholder="Team Name "
                              type="text"
                              errorMessage="Enter Team Name "
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.name}
                              onChange={handleFormData}
                            />
                          </div>
                        </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Manager</Label>
                          <Select
                            name="manager_team"
                             value={selectedManager}
                            onChange={(selected) => {
                              handleSelectChange(selected, "manager");
                            }}
                             options={manager}
                            classNamePrefix="select2-selection"
                          />
                             <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedManager === null ? errors.managerError : ""}
                              </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Coordinator</Label>
                          <Select
                            name="cordinator_team"
                            value={selectedCordinator}
                            onChange={(selected) => {
                              handleSelectChange(selected, "cordinator");
                            }}
                            options={users}
                            classNamePrefix="select2-selection"
                          />
                             <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedCordinator === null ? errors.cordinatorError : ""}
                              </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Leader</Label>
                          <Select
                            name="leader_team"
                            value={selectedLeader}
                            onChange={(selected) => {
                              handleSelectChange(selected, "leader");
                            }}
                            options={leader} 
                            classNamePrefix="select2-selection"
                          />
                           <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedLeader === null ? errors.leaderError : ""}
                              </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Members</Label>
                          <Select
                           isMulti
                            name="member_team"
                            value={selectedMembers}
                            onChange={(selected) => {
                              handleSelectChange(selected, "member");
                            }}
                            options={users}
                            classNamePrefix="select2-selection"
                          />
                             <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedMembers === null ? errors.membersError : ""}
                              </p>
                        </div>
                      </Col>
                      <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {teamIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                             
                                disabled={addTeam ? true : false}
                              >
                                {addTeam ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                              
                                disabled={addTeam ? true : false}
                              >
                                {addTeam ? "Adding" : "Assign"}
                              </Button>
                            )}
                         
                          <Button
                                color="danger"
                                type="reset"
                                style={{ marginLeft: "1%" }}
                                onClick={() =>
                                  reset()}
                              >
                                {"  "}
                                Reset
                              </Button>
                              </div>
                        </Col>
                       
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageStaffTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Manage_Staff;
