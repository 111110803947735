import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import moment from "moment";

import "./Modules.scss";

import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, CardTitle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getAllModules } from "../../../store/actions";
const Modules = () => {
  const [modulesObject, setModulesObject] = useState({});
  const [modulesIdTobeUpdated, setModulesIdToBeUpdated] = useState(null);
  const [modulesIdToBeDeleted, setModulesIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [modulesForTable, setModulesForTable] = useState([]);
  const [modulesValue, setmodulesValue] = useState("");

  const {
    districts,
    addingModules,

    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleTableData() {
    var url = `${API_URL}modules/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let modulesData = [];

        result.map((item, index) => {
          if(item.icon!="" && typeof item.icon!="undefined")
          {
            item.image = (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={`${API_URL}uploads/module_images/${item.icon}`} height="23"
                ></img>
              </div>
            );
          }
          else{
            item.image="";
          }
          
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateModules(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setModulesIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + "" + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + "" + values.lastName;
            else item.staff = values?.firstName;
          }

          modulesData.push(item);
        });
        setModulesForTable(modulesData);
      });
  }
  let preUpdateModules = (item) => {
    setmodulesValue(item?.name);

    setModulesIdToBeUpdated(item._id);
    setModulesObject(item);
  };
  const reset = () => {
    formRef.current.reset();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Module Name",
        field: "moduleName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Icon",
        field: "image",
        sort: "asc",
        width: 270,
      },


      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: modulesForTable,
  };

  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmodulesValue(value);
    setModulesObject({ ...modulesObject, [name]: value });
  }
  const handleValidSubmitModules = () => {
    if (modulesIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}modules`, modulesObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Modules updated successfully");
            setmodulesValue("");
            formRef.current.reset();
            handleTableData();
            setModulesObject({});
            setModulesIdToBeUpdated(null);
            if (localStorage.getItem("authUser")) {
              var data = localStorage.getItem("authUser");
              var user_obj = JSON.parse(data);
                dispatch(getAllModules(user_obj?.privilage));
            }

          } else {
            toastr.error("Failed to update Modules");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}modules`, modulesObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Modules added succesfully");
            handleTableData();
            setmodulesValue("");
            formRef.current.reset();
            setModulesObject({});
            formRef.current.reset();
          } else {
            toastr.error("Failed to add Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const uploadImage = (e) => {
    let val = e.target.files[0];
    let dataVal = val.type.split("/");
    let type = dataVal[0];

    if (type == "video") {
      toastr.error("Please upload image");
    } else {
      const fd = new FormData();
      fd.append("module_image", e.target.files[0]);
      axios
        .post(`${API_URL}modules/image/`, fd, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            setModulesObject({
              ...modulesObject,
              [`icon`]: response.data.file.filename,
            });
          } else {
            toastr.error(response.data.message);
          }
        });
    }
  };
  const deleteModuleImage = () => {
    setModulesObject({
      ...modulesObject,
      icon: "",
    });
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}modules` + "/" + modulesIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Modules deleted successfully");
                  handleTableData();
                  if (localStorage.getItem("authUser")) {
                    var data = localStorage.getItem("authUser");
                    var user_obj = JSON.parse(data);
                    dispatch(getAllModules(user_obj?.privilage));
                  }
                } else {
                  toastr.error("Failed to delete Modules");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deletePrivilege(privilegeIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Modules" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitModules(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Module Name
                          </Label>

                          <AvField
                            name="moduleName"
                            placeholder="Module Name"
                            type="text"
                            errorMessage="Enter Module Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={modulesObject?.moduleName}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="shortCode"
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={modulesObject?.shortCode}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Icon</Label>
                          {modulesObject?.icon ? (
                            <div div className="img-wraps">
                              {modulesObject.icon &&
                              modulesObject.icon.startsWith("http") ? (
                                <img
                                  alt=""
                                  src={`${modulesObject.icon}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={`${API_URL}uploads/module_images/${modulesObject.icon}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteModuleImage}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="icon"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {modulesIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingModules ? true : false}
                            >
                              {addingModules ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingModules ? true : false}
                            >
                              {addingModules ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="modulesTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modules;