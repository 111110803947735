import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Holidays.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";

const Holidays = () => {
  // const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [holidaysObject, setHolidaysObject] = useState({});
  const [holidaysIdTobeUpdated, setHolidaysIdToBeUpdated] = useState(null);
  const [holidaysIdToBeDeleted, setHolidaysIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [holidaysForTable, setHolidaysForTable] = useState([]);
  const [holidaysValue, setholidaysValue] = useState("");

  const [selectedType, setSelectedType] = useState(null);

  const [selectedYearSearch, setSelectedYearSearch] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);

  const [errors, setErrors] = useState({
    holidayDate: "",
    holidayType: "",
  });

  const {
    districts,
    addingHolidays,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}holidays/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let holidaysData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateHolidays(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setHolidaysIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          // if (item.Hdate)
          //   item.Holidaydate = moment(item.Hdate).format("DD-MM-YYYY");
          // else item.Holidaydate = "";

          // if (item?.up_time)
          //   item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          // else item.time = moment(item.up_time, "HHmmss").format("hh:mm a");

          item.Holidaydate = item?.Hdate
            ? moment(item.Hdate).format("DD-MM-YYYY")
            : "";

          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          let monthData = item?.Hdate ? item.Hdate.split("-") : "";
          let monthVal = monthData[1];
          let month = moment(monthVal, "M").format("MMMM");
          item.month = month;

          // let monthData = item?.Holidaydate;
          // const [first, second] = monthData;
          // let month = moment(second, "MM").format("MMMM");
          // item.month = month;

          var day = moment(item?.Hdate).format("dddd");
          item.day = day;

          if (item?.updatedby[0]) {
            let values = item?.updatedby[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          holidaysData.push(item);
        });

        setHolidaysForTable(holidaysData);
      });
  }

  function handleTableListData(year = "") {
    var url = `${API_URL}holidays/list?year=` + year;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let holidaysData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateHolidays(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setHolidaysIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          // if (item.Hdate)
          //   item.Holidaydate = moment(item.Hdate).format("DD-MM-YYYY");
          // else item.Holidaydate = "";

          // if (item?.up_time)
          //   item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          // else item.time = moment(item.up_time, "HHmmss").format("hh:mm a");

          item.Holidaydate = item?.Hdate
            ? moment(item.Hdate).format("DD-MM-YYYY")
            : "";

          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          let monthData = item?.Hdate ? item.Hdate.split("-") : "";
          let monthVal = monthData[1];
          let month = moment(monthVal, "M").format("MMMM");
          item.month = month;

          // let monthData = item?.Holidaydate;
          // const [first, second] = monthData;
          // let month = moment(second, "MM").format("MMMM");
          // item.month = month;

          var day = moment(item?.Hdate).format("dddd");
          item.day = day;

          if (item?.updatedby[0]) {
            let values = item?.updatedby[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          holidaysData.push(item);
        });

        setHolidaysForTable(holidaysData);
      });
  }

  let preUpdateHolidays = (item) => {
    if (item.name) {
      setholidaysValue(item?.name);
    } else if (item.Hdate) {
      setholidaysValue(item?.Hdate);
    }
    let values = {};
    if (item?.type === "Festival") {
      values = {
        label: "Festival",
        value: 0,
      };
    } else if (item?.type === "National") {
      values = {
        label: "National",
        value: 1,
      };
    } else if (item?.type === "Others") {
      values = {
        label: "Others",
        value: 2,
      };
    }

    setSelectedType(values);
    setHolidaysIdToBeUpdated(item._id);
    setHolidaysObject(item);
  };
  console.log("obj", holidaysObject);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },
      {
        label: "Holiday",
        field: "Holidaydate",
        sort: "asc",
        width: 150,
      },

      {
        label: "Day",
        field: "day",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: holidaysForTable,
  };

  function handleChangeHolidays(e) {
    let name = e.target.name;
    let value = e.target.value;
    setholidaysValue(value);
    setHolidaysObject({ ...holidaysObject, [name]: value });
  }

  const handleDate = (e) => {
    setHolidaysObject({
      ...holidaysObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      holidayDate: "",
    });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "type":
        setSelectedType(selected);
        setHolidaysObject({
          ...holidaysObject,
          type: selected.label,
        });
        setErrors({
          ...errors,
          holidayType: "",
        });
        break;

      case "year":
        setSelectedYearSearch(selected);
        setHolidaysObject({
          ...holidaysObject,
          year: selected.label,
        });
        break;

      default:
        break;
    }
  };

  const handleValidSubmit = () => {
    if (!holidaysObject?.Hdate || selectedType === null) {
      let errorVal = errors;
      if (!holidaysObject?.Hdate)
        errorVal.holidayDate = "Please Select Holiday Date";
      if (selectedType === null)
        errorVal.holidayType = "Please Select Holiday Type";

      setErrors({
        ...errorVal,
      });
    } else {
      if (holidaysIdTobeUpdated) {
        // let bodydata = {
        //   id: designationIdTobeUpdated,
        //   name: designationObject.name,
        // };

        axios
          .put(`${API_URL}holidays`, holidaysObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Holiday updated successfully");
              setholidaysValue("");
              formRef.current.reset();
              handleTableData();
              setHolidaysObject({
                Hdate: "",
              });
              setErrors({
                holidayDate: "",
                holidayType: "",
              });
              setHolidaysIdToBeUpdated(null);
              setSelectedType(null);
            } else {
              toastr.error("Failed to update Holidays");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}holidays`, holidaysObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Holidays added succesfully");
              handleTableData();
              setholidaysValue("");
              formRef.current.reset();
              setHolidaysObject({
                Hdate: "",
              });
              setErrors({
                holidayDate: "",
                holidayType: "",
              });
              setSelectedType(null);
            } else {
              toastr.error("Failed to add Holidays");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const handleSearch = () => {
    let year = holidaysObject?.year ? holidaysObject.year : "";

    handleTableListData(year);
  };

  const reset = () => {
    handleTableData();
    setSelectedYearSearch("");
    setHolidaysObject({
      ...holidaysObject,
      year: "",
    });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}holidays` + "/" + holidaysIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Holidays deleted successfully");
                    if (
                      holidaysObject &&
                      holidaysObject._id === holidaysIdToBeDeleted
                    ) {
                      setholidaysValue("");
                      formRef.current.reset();
                      setHolidaysObject({});
                      setHolidaysIdToBeUpdated(null);
                      setErrors({
                        holidayDate: "",
                        holidayType: "",
                      });
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Holidays");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Holidays" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          // 5) Date Picker 
                          <Controller
                            render={(props) => (
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Date of Admission"
                                value={props.value}
                                onChange={props.onChange}
                                fullWidth
                                error={"Date Required"}
                                // helperText={errors.date_of_admission?.message}
                              />
                            )}
                            name="date_of_admission"
                            defaultValue={null}
                            control={control}
                            rules={{
                              required: "Date of Admission is required.",
                            }}
                          />
                        </MuiPickersUtilsProvider> */}

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Holiday Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="holiday_date"
                              name="Hdate"
                              required={true}
                              value={holidaysObject?.Hdate}
                              onChange={handleDate}
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedDate === null ? errors.holidayDate : ""}
                            </p>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Holiday Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Holidays name"
                              type="text"
                              errorMessage="Enter Holiday Name"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={holidaysValue}
                              onChange={handleChangeHolidays}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Holiday Type</Label>
                            <Select
                              name="type"
                              errorMessage="Select Holiday Type"
                              validate={{ required: { value: true } }}
                              value={selectedType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "type");
                              }}
                              options={[
                                {
                                  label: "Festival",
                                  value: 0,
                                },
                                {
                                  label: "National",
                                  value: 1,
                                },
                                {
                                  label: "Others",
                                  value: 2,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedType === null ? errors.holidayType : ""}
                            </p>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {holidaysIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingHolidays ? true : false}
                              >
                                {addingHolidays ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingHolidays ? true : false}
                              >
                                {addingHolidays ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}

                    {/* <Row>
                      <Col className="col-2" style={{ paddingTop: "4px" }}>
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                          >
                            
                     
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Subject</Label>
                        <Select
                          name="cust_type_id"
                          value={selectedCustomerTypeSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Select Year
                          </Label>
                          <Select
                            name="year"
                            value={selectedYearSearch}
                            onChange={(selected) => {
                              handleSelectChange(selected, "year");
                            }}
                            options={[
                              {
                                label: "2000",
                                value: 0,
                              },
                              {
                                label: "2001",
                                value: 1,
                              },
                              {
                                label: "2002",
                                value: 2,
                              },
                              {
                                label: "2003",
                                value: 3,
                              },
                              {
                                label: "2004",
                                value: 4,
                              },
                              {
                                label: "2005",
                                value: 5,
                              },
                              {
                                label: "2006",
                                value: 6,
                              },
                              {
                                label: "2007",
                                value: 7,
                              },
                              {
                                label: "2008",
                                value: 8,
                              },
                              {
                                label: "2009",
                                value: 9,
                              },
                              {
                                label: "2010",
                                value: 10,
                              },
                              {
                                label: "2011",
                                value: 11,
                              },
                              {
                                label: "2012",
                                value: 12,
                              },
                              {
                                label: "2013",
                                value: 13,
                              },
                              {
                                label: "2014",
                                value: 14,
                              },
                              {
                                label: "2015",
                                value: 15,
                              },
                              {
                                label: "2016",
                                value: 16,
                              },
                              {
                                label: "2017",
                                value: 17,
                              },
                              {
                                label: "2018",
                                value: 18,
                              },
                              {
                                label: "2019",
                                value: 19,
                              },
                              {
                                label: "2020",
                                value: 20,
                              },
                              {
                                label: "2021",
                                value: 21,
                              },
                              {
                                label: "2022",
                                value: 22,
                              },
                              {
                                label: "2023",
                                value: 23,
                              },
                              {
                                label: "2024",
                                value: 24,
                              },
                              {
                                label: "2025",
                                value: 25,
                              },
                              {
                                label: "2026",
                                value: 26,
                              },
                              {
                                label: "2027",
                                value: 27,
                              },
                              {
                                label: "2028",
                                value: 28,
                              },
                              {
                                label: "2029",
                                value: 29,
                              },
                              {
                                label: "2030",
                                value: 30,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            onClick={() => handleSearch()}
                          >
                            {"  "}
                            Filter
                          </Button>
                          <Button
                            color="danger"
                            type="submit"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <MDBDataTable
                      id="holidayTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Holidays;