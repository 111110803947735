import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./Branch.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";

const ManageBranch = () => {
  const [state, setState] = useState("");
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [branchIdToBeUpdated, setBranchIdToBeUpdated] = useState(null);
  const [branchIdToBeDeleted, setBranchIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  const [companyName, setCompanyName] = useState([]);
  const [selectedcompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    handleTableData();
    fetch_all_states();
    fetch_all_Company();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateBranch = (item) => {
    setBranchIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}branch?branch_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res:details ", res);
        let result = res.data.data;
        // let stateVal = result.state || {};
        // let stateOptions = {
        //   label: stateVal?.name ? stateVal.name : "",
        //   value: stateVal?._id,
        // };

        let Company = result?.company ? result.company : {};
        let CompanyValue = {
          label: Company?.company_name,
          value: Company?._id,
        };
        setSelectedCompany(CompanyValue);

        let stateData = {
          label: result?.state,
        };
        setState(stateData);
        result.branch_id = item?._id;
        setMaster(result);
      });
  };
  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}branch?branch_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        console.log(result, ">>>>>>>>");
        // let stateVal = result.state;
        // result.state = stateVal?.state;
        let Company = result?.company || {};
        result.company = Company?.company_name;
        let addedBy = result.branch_addedby || {};
        result.addedBy = addedBy?.firstName;
        setPopupData(result);
        setPopupView(true);
      });
  };
  function handleTableData() {
    axios
      .get(`${API_URL}branch/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            let addedby = item.branch_addedby || {};
            item.addedby = addedby?.firstName;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateBranch(item);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setBranchIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const fetch_all_states = () => {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let state_data =
          res.data.data &&
          res.data.data.map((state) => {
            return {
              label: state?.name,
              value: state?.id,
            };
          });
        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  };
  function fetch_all_Company() {
    axios
      .get(`${API_URL}company/list/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var company_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.company_name,
              value: el._id,
            };
          });
        setCompanyName([{ options: company_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Company":
        setSelectedCompany(selected);
        setMaster({
          ...master,
          company: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (selected) => {
    setState(selected);
    setMaster({
      ...master,
      state: selected.label,
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Branch ID",
      //   field: "cid",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Branch",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "contactPerson",
        sort: "asc",
        width: 100,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 10,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "addedby",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (branchIdToBeUpdated) {
      axios
        .put(`${API_URL}branch`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Branch updated successfully");
            formRef.current.reset();
            handleTableData();
            setMaster({});
            setState(null);
            setBranchIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update branch");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}branch`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Branch added successfully");
            formRef.current.reset();
            handleTableData();
            setMaster({});
            setState(null);
          } else {
            toastr.error("Failed to add branch");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customestyle = {
    height: "auto",
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}branch` + "/" + branchIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Branch deleted successfully");
                  if (master && master?._id === branchIdToBeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setState(null);
                    setBranchIdToBeUpdated(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete branch");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Branch Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Branch ID :
                </td>
                <td>{popupData?.branchId}</td>
                <td style={{ paddingLeft: "25px" }}>Branch :</td>
                <td style={{ textAlign: "left" }}>{popupData?.name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Code :</td>
                <td>{popupData?.shortCode}</td>
                <td style={{ paddingLeft: "25px" }}>Company :</td>
                <td style={{ textAlign: "left" }}>{popupData?.company}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Designation :
                </td>
                <td>{popupData?.designation}</td>
                <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}>{popupData?.mobile}</td>
              </tr>
              <tr hidden={!popupData?.landLineNumber && !popupData?.email}>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Landline No :
                </td>
                <td>{popupData?.landLineNumber}</td>
                <td style={{ paddingLeft: "25px" }}>Email :</td>
                <td style={{ textAlign: "left" }}>{popupData?.email}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Address :
                </td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupData?.address}
                </td>
                <td style={{ paddingLeft: "25px" }}>State :</td>
                <td style={{ textAlign: "left" }}>{popupData?.state}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>City :</td>
                <td>{popupData?.city}</td>
                <td style={{ paddingLeft: "25px" }}>Pincode :</td>
                <td style={{ textAlign: "left" }}>{popupData?.pincode}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Latitude :
                </td>
                <td>{popupData?.latitude}</td>
                <td style={{ paddingLeft: "25px" }}>Longitude :</td>
                <td style={{ textAlign: "left" }}>{popupData?.longitude}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Contact Person :
                </td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.contactPerson}
                </td>
                <td style={{ paddingLeft: "25px" }}>Added By :</td>
                <td style={{ textAlign: "left" }}>{popupData?.addedBy}</td>
                {/* <td>Added At :</td>
                <td style={{ textAlign: "left" }}>{popupData?.addedat}</td> */}
              </tr>
              {/* <tr>
                <th>Updated By :</th>
                <td style={{ textAlign: "left" }}>{popupData?.updatedby}</td>
                <th>Updated At :</th>
                <td style={{ textAlign: "left" }}>{popupData?.updatedat}</td>
              </tr> */}
            </table>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Branch" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Branch Name
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Branch Name"
                            type="text"
                            errorMessage="Enter Branch Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Company</Label>
                          <Select
                            name="Company"
                            errorMessage="Select Company"
                            value={selectedcompany}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Company");
                            }}
                            options={companyName}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="shortCode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.shortCode}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Contact Person
                          </Label>
                          <AvField
                            name="contactPerson"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.contactPerson}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Designation
                          </Label>
                          <AvField
                            name="designation"
                            placeholder="Designation"
                            type="text"
                            // errorMessage="Enter Designation"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.designation}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      {/*Text field 2*/}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile No."
                            type="text"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={master?.mobile}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Landline No.
                          </Label>
                          <AvField
                            name="landLineNumber"
                            placeholder="Landline No."
                            type="text"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            // id="validationCustom04"
                            // minLength={11}
                            // maxLength={11}
                            value={master?.landLineNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage=" Please provide a valid Email ID."
                            className="form-control"
                            value={master?.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>

                          <Select
                            name="state"
                            value={state}
                            onChange={(selected) => {
                              handleChange(selected);
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      {/*Text field 3*/}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>City</Label>
                          <AvField
                            name="city"
                            placeholder="City"
                            type="text"
                            // errorMessage="Enter Your City"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={master?.city}
                            onChange={handleInputChange}
                          />
                          {/* <Select
                            name="city_id"
                            value={city}
                            onChange={(selected) => {
                              handleChange1(selected);
                            }}
                            // options={[
                            //   {
                            //     label: "City1",
                            //     value: 1,
                            //   },
                            //   {
                            //     label: "City2",
                            //     value: 2,
                            //   },
                            //   {
                            //     label: "City3",
                            //     value: 3,
                            //   },
                            // ]}
                            classNamePrefix="select2-selection"
                          /> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Pincode</Label>
                          <AvField
                            name="pincode"
                            placeholder="Pincode"
                            type="text"
                            // errorMessage="Please provide a Pincode."
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "PIN must be only with numbers",
                              },
                            }}
                            id="validationCustom05"
                            value={master?.pincode}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="latitude"
                            placeholder="Latitude"
                            type="number"
                            errorMessage="Enter Latitude"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            // id="validationCustom03"
                            value={master?.latitude}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter Longitude"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            // id="validationCustom04"
                            value={master?.longitude}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      {/*Text field 4*/}

                      {/*Text field 5*/}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            style={customestyle}
                            name="address"
                            placeholder="Address"
                            type="textarea"
                            errorMessage="Enter Your Address"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={master?.address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "2.5%" }}>
                        {branchIdToBeUpdated ? (
                          <Button color="primary" type="submit">
                            {"Update"}
                          </Button>
                        ) : (
                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        )}
                      </Col>
                    </Row>

                    {/*Text Field End*/}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="branchTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageBranch;