import moment from "moment";

export const getDate = (date) => {
  if (!date) {
    return null;
  }
  let dateValue =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM-DD");
};
export const getSalaryPeriodStart = () => {
  var startdate = moment().format("YYYY-MM") + "-16";
  var start_date = moment(startdate).subtract(1, 'months').format('YYYY-MM-DD');
  return start_date;
}
export const getSalaryPeriodEnd = () => {
  var end_date = moment().format("YYYY-MM") + "-15";
  return end_date;
}
export const getFirstday = (date) => {
  if (!date) {
    return null;
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format("YYYY-MM-DD");
};
export const getMonth = (date) => {
  if (!date) {
    return null;
  }
  let dateValue =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM");
};
const pad = (number) => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};
export const getDateTime = (date) => {
  if (!date) {
    return null;
  }

  let seconds =
    pad(String(date.getMilliseconds()).padStart(3, "0")).length > 3
      ? pad(String(date.getMilliseconds()).padStart(2, "0"))
      : pad(String(date.getMilliseconds()).padStart(3, "0"));
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    ":" +
    seconds
  );
};

export const setCompany = (companyID) => {
  localStorage.setItem("companyID", companyID);
};
export const getCompany = () => {
  return localStorage.getItem("companyID");
};

export const setLocalbody = (localbodyID) => {
  localStorage.setItem("localbodyID", localbodyID);
};
export const getLocalbody = () => {
  return localStorage.getItem("localbodyID");
};
export const getRouteName = (props) => {
  let path = props.location.pathname;
  var res = path.split("/");
  return res[1];
};
export const getArraySumByKey = (arr, keyName) =>
  arr
    .map((item) => parseFloat(item[keyName] ? item[keyName] : 0))
    .reduce((a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0), 0);

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    if (amount === "-" || amount === "+") amount = amount + "0";

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : "")
    );
  } catch (e) { }
};

export const getDateAndTime = (date) => {
  if (!date) {
    return null;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes())
  );
};
export const setAssignCust = (customer) => {
  localStorage.setItem("customer", customer);
};
export const getAssignCust = () => {
  return localStorage.getItem("customer");
};
export const setMyObject = (myObject) => {
  localStorage.setItem("myObject", JSON.stringify(myObject));
};
export const getMyObject = () => {
  return localStorage.getItem("myObject");
};
export const setBrowserToken = (token) => {
  localStorage.setItem("browserToken", token);
};
export const timeFromNumber = (floating) => {
  var rounded = Math.round(floating * 100) / 100;
  var remaining = rounded - Math.floor(rounded);
  var minutes = 60 * remaining;
  var hours = Math.floor(rounded).toString().padStart(2, '0');
  minutes = Math.floor(minutes).toString().padStart(2, '0');
  return hours + ":" + minutes
}
export const addTimes = (times = []) => {

  const z = (n) => (n < 10 ? '0' : '') + n;

  let hour = 0
  let minute = 0
  let second = 0
  for (const time of times) {
    const splited = time.split(':');
    hour += parseInt(splited[0]);
    minute += parseInt(splited[1])
    second += parseInt(splited[2])
  }
  const seconds = second % 60
  const minutes = parseInt(minute % 60) + parseInt(second / 60)
  const hours = hour + parseInt(minute / 60)

  return z(hours) + ':' + z(minutes) + ':' + z(seconds)
}
export const timeSince = (date, now_date) => {
  var seconds = Math.floor((now_date - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " yrs";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hrs";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return Math.floor(seconds) + " sec";
}
export const timeInMinute = (hms) => {
  if (typeof hms !== "undefined" && hms != "") {
    var a = hms.split(':');
    var minutes = (+a[0]) * 60 + (+a[1]);
    var sec = ((+a[2]) / 60);
    var c = minutes + sec;
  }
  else {
    var c = 0;
  }

  return c;
}