import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Attendance_Summery.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 270,
      },
      {
        label: "Present",
        field: "present",
        sort: "asc",
        width: 200,
      },
      {
        label: "Halfday",
        field: "halfday",
        sort: "asc",
        width: 100,
      },
      {
        label: "Leave",
        field: "leave",
        sort: "asc",
        width: 150,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      {
        id: "1",
        staff: "Mythree",
        present: "10",
        halfday: "0",
        leave: "20",
        lop: "10",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        staff: "Abhirag",
        present: "20",
        halfday: "0",
        leave: "10",
        lop: "10",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "3",
        staff: "Namitha",
        present: "10",
        halfday: "0",
        leave: "20",
        lop: "10",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "4",
        staff: "Nihal",
        present: "10",
        halfday: "0",
        leave: "20",
        lop: "10",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "5",
        staff: "Harshita",
        present: "10",
        halfday: "0",
        leave: "20",
        lop: "10",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={handleShowModal}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };
  const tableStyle = {
    textAlignLast: "start",
    paddingLeft: "15%",
  };
  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Attendance",
        field: "attendance",
        sort: "asc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        date: "15-08-2021",
        attendance: "Present",
      },
      {
        id: "2",
        date: "16-08-2021",
        attendance: "Absent",
      },
      {
        id: "3",
        date: "17-08-2021",
        attendance: "Halfday",
      },
    ],
  };

  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      options: [
        { label: "Admin", value: "admin" },
        { label: "Cashier", value: "cashier" },
        { label: "Receptionist", value: "receptionist" },
        { label: "Hr", value: "hr" },
        { label: "Pr", value: "pr" },
      ],
    },
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };

  const decreamentCount = () => {
    setCount1(count1 - 1);
  };

  const decreamentCount1 = () => {
    setCount2(count2 - 1);
  };

  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Attendance Summery" />
          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Amount</Label>

                          <AvField
                            name="lastname"
                            placeholder={count}
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Fund source
                          </Label>

                          <AvField
                            name="lastname"
                            placeholder={count}
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Remarks</Label>

                          <AvField
                            name="lastname"
                            placeholder={count}
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-2">
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                    
                          {"  "}
                          Submit
                        </Button>
                        <Button color="danger" type="submit">
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>View Advance List</Label>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="month"
                          value={value}
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          onChange={handleChangeDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="month"
                          value={value}
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          onChange={handleChangeDate}
                        />
                      </div>
                    </Col>
                    {/* <Col md="3">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          {/* <Save></Save> */}
                          {"  "}
                          Filter
                        </Button>
                        <Button color="danger" type="submit">
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="attendanceTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton onClick={handleCloseModal}>
            <Modal.Title>View Attendance Summery</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Modal Body Start */}
            <MDBDataTable
              id="attendance1TableId"
              responsive
              
              bordered
              data={data1}
              searching={true}
              info={true}
              disableRetreatAfterSorting={true}
              entries={20}
            />
            {/* Modal Body End */}
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
