import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Category.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const CheckinCheckout = () => {
  const [categoryObject, setCategoryObject] = useState({});
  const [categoryIdTobeUpdated, setCategoryIdToBeUpdated] = useState(null);
  const [categoryIdToBeDeleted, setCategoryIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [categoryForTable, setCategoryForTable] = useState([]);
  const [categoryValue, setcategoryValue] = useState("");

  const [selectedType, setSelectedType] = useState(null);

  const {
    districts,
    addingCategory,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}category/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let categoryData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateCategory(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setCategoryIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + "" + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + "" + values.lastName;
            else item.staff = values?.firstName;
          }

          categoryData.push(item);
        });
        setCategoryForTable(categoryData);
      });
  }

  let preUpdateCategory = (item) => {
    setcategoryValue(item?.name);

    let values = {};
    if (item?.type === "Services") {
      values = {
        label: "Services",
        value: 0,
      };
    } else if (item?.type === "Product") {
      values = {
        label: "Product",
        value: 1,
      };
    }

    setSelectedType(values);
    setCategoryIdToBeUpdated(item._id);
    setCategoryObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: categoryForTable,
  };

  function handleChangeCategory(e) {
    let name = e.target.name;
    let value = e.target.value;
    setcategoryValue(value);
    setCategoryObject({ ...categoryObject, [name]: value });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "type":
        setSelectedType(selected);
        setCategoryObject({
          ...categoryObject,
          type: selected.label,
        });
        break;

      default:
        break;
    }
  };

  const handleValidSubmit = () => {
    if (categoryIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}category`, categoryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Category updated successfully");
            setcategoryValue("");
            formRef.current.reset();
            handleTableData();
            setCategoryObject({});
            setCategoryIdToBeUpdated(null);
            setSelectedType(null);
          } else {
            toastr.error("Failed to update Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}category`, categoryObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Category added succesfully");
            handleTableData();
            setcategoryValue("");
            formRef.current.reset();
            setCategoryObject({});
            setSelectedType(null);
          } else {
            toastr.error("Failed to add Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}category` + "/" + categoryIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Category deleted successfully");
                    if (
                      categoryObject &&
                      categoryObject._id === categoryIdToBeDeleted
                    ) {
                      setcategoryValue("");
                      formRef.current.reset();
                      setCategoryObject({});
                      setCategoryIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Category");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Product Category" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Category Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Category name"
                              type="text"
                              errorMessage="Enter Category Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={categoryValue}
                              onChange={handleChangeCategory}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Type</Label>
                            <Select
                              name="type"
                              errorMessage="Select Category Type"
                              validate={{ required: { value: true } }}
                              value={selectedType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "type");
                              }}
                              options={[
                                {
                                  label: "Services",
                                  value: 0,
                                },
                                {
                                  label: "Product",
                                  value: 1,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {categoryIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingCategory ? true : false}
                              >
                                {addingCategory ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingCategory ? true : false}
                              >
                                {addingCategory ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="categoryTableId"
                        responsive
                        
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CheckinCheckout;
