import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Progress, PopoverBody, UncontrolledPopover, Button, Input } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { timeFromNumber } from "../../helpers/globalFunctions";
import toastr from "toastr";
import moment from "moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
import "./style.scss";
import { timeSince } from "../../helpers/globalFunctions";
import { AvForm } from "availity-reactstrap-validation";
const AllTasks = (props) => {
  const [tableData, setTableData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [taskName, setTaskName] = useState("");
  const [optionProject, setOptionProject] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState({ label: "All Task", value: 1 });
  const [selectedUser, setSelectedUser] = useState(null);
  const [optionStaff, setOptionStaff] = useState([]);
  const [userId, setUserId] = useState();
  const [checklist_info, setChecklist_info] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [attachCount, setAttachCount] = useState("");
  const [msgObject, setMsgObject] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatboxData, setChatboxData] = useState({});
  const [isProjectChat, setisProjectChat] = useState(false);
  const [flowOptions, setFlowOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [optionTeam, setOptionTeam] = useState([]);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState({ label: "In completed", value: 2 });
  const [timeline, setTimeline] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const bottomRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const handleTableData = (
    project_id = "",
    staff_id = "",
    from_date = "",
    to_date = "",
    task_name = "",
    flow = [],
    team = "",
    staff_arr = [],
    status = "",
    task_type = "",
    type = ""
  ) => {
    var flows = [];
    setTableData([]);
    if (flow && flow.length > 0) {
      if (type != "reset") {
        flow.map((item) => {
          flows.push(item.value);
        });
      }
    }
    var data = {
      project_id: project_id,
      staff_id: staff_id,
      from_date: from_date,
      to_date: to_date,
      task_name: task_name,
      flow: flows,
      staff_arr: staff_arr,
      team: team,
      status: status,
      task_type: task_type,
    };
    axios
      .post(`${API_URL}project_dashboard/all_tasks`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data;
        setTableData(data);
      });
  };
  const handleProjectOptions = () => {
    axios
      .get(`${API_URL}project_dashboard/project_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var list = [];
        data.map((item, index) => {
          list.push({ label: item.shortcode, value: item._id });
        });
        setOptionProject(list);
      });
  };
  function handleStaffOptions(team = "") {
    axios
      .get(`${API_URL}project_dashboard/staff_options?team=` + team, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var list = [];
        var data = response.data.data;
        data.map((item, index) => {
          if (data.length == 1) {
            setSelectedStaff({ label: item.firstName, value: item._id });
            setSelectedUser({ label: item.firstName, value: item._id });
          }
          list.push({ label: item.firstName, value: item._id });
        });
        setOptionStaff(list);
        if (data.length == 1) {
          handleTableData(selectedProject?.value, data[0]._id, from_date, to_date, taskName, selectedFlow?.selected, team, list, 2, selectedTaskType.value);
        } else {
          handleTableData(
            selectedProject?.value,
            selectedStaff?.value,
            from_date,
            to_date,
            taskName,
            selectedFlow?.selected,
            team,
            list,
            2,
            selectedTaskType.value
          );
          //handleTableData();
        }
      });
  }
  function handleTeamOptions() {
    axios
      .get(`${API_URL}project_dashboard/team_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var list = [];
        var data = response.data.data;
        data.map((item, index) => {
          list.push({ label: item.name, value: item._id });
        });
        setOptionTeam(list);
      });
  }
  const handleInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (name == "from_date") {
      handleTableData(
        selectedProject?.value,
        selectedStaff?.value,
        value,
        to_date,
        taskName,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
      setFromDate(value);
    } else if (name == "to_date") {
      setToDate(value);
      handleTableData(
        selectedProject?.value,
        selectedStaff?.value,
        from_date,
        value,
        taskName,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
    } else if (name == "taskname") {
      handleTableData(
        selectedProject?.value,
        selectedStaff?.value,
        from_date,
        to_date,
        value,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
      setTaskName(value);
    }
  };
  const handleSearch = () => {
    //handleTableData(selectedProject?.value,selectedStaff?.value,from_date,to_date,taskName,selectedFlow?.selected,selectedFlo);
  };
  const reset = () => {
    setSelectedProject(null);
    setFromDate("");
    setToDate("");
    setTaskName("");
    setSelectedFlow(null);
    setSelectedFilterStatus({ label: "In completed", value: 2 });
    setSelectedTeam(null);
    if (!selectedUser) {
      setSelectedStaff(null);
      handleTableData("", "", "", "", "", "", "", [], 2, "", "reset");
    } else {
      handleTableData("", selectedUser?.value, "", "", "", "", "", [], 2, "", "reset");
    }
  };
  const handleSelectProject = (e) => {
    setSelectedProject(e);
    handleTableData(
      e.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      selectedFlow?.selected,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      selectedTaskType.value
    );
  };
  const handleSelectStaff = (e) => {
    setSelectedStaff(e);
    handleTableData(
      selectedProject?.value,
      e.value,
      from_date,
      to_date,
      taskName,
      selectedFlow?.selected,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      selectedTaskType.value
    );
  };
  const handleSelectFlow = (e) => {
    setSelectedFlow(e);
    handleTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      e,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      selectedTaskType.value
    );
  };
  const handleSelectTaskType = (e) => {
    setSelectedTaskType(e);
    handleTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      selectedFlow?.selected,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      e.value
    );
  };
  const handleSelectFilterStatus = (e) => {
    setSelectedFilterStatus(e);
    handleTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      e,
      selectedTeam?.value,
      optionStaff,
      e.value,
      selectedTaskType.value
    );
  };
  const showTask = (index) => {
    var tasks = [...tableData];
    tasks[index].expand = "show";
    setTableData(tasks);
  };
  const hideTask = (index) => {
    var tasks = [...tableData];
    tasks[index].expand = "hidden";
    setTableData(tasks);
  };
  const handleShow = (item, index) => {
    setMsgObject({
      ...msgObject,
      ["assign_id"]: item._id,
      ["flow_id"]: item.task_flow_id,
      ["task_id"]: item._id,
      ["project_id"]: item.project_id,
      ["subcategory_id"]: item.task_subcategory_id,
    });
    axios
      .get(`${API_URL}taskcomment/list?task_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var data = response.data.data;
        setisProjectChat(response.data.non_project);
        setMessages(response.data.data);
        if (typeof response.data.project_info.assignees !== "undefined") {
          response.data.project_info.assignees.map((item, index) => {
            item.id = index + 1;
            if (typeof item.assign_time !== "undefined") item.assign_time1 = timeFromNumber(item.assign_time);
            else item.assign_time1 = timeFromNumber(parseFloat(item.duration));

            if (typeof item.assign_effort_hr !== "undefined")
              item.assign_effort_hr1 = item.assign_effort_hr.substring(0, item.assign_effort_hr.lastIndexOf(":"));
            else item.assign_effort_hr1 = "00:00";
            if (item.assign_effort_h1r == "") item.assign_effort_hr1 = "00:00";
          });
        }
        setChatboxData(response.data.project_info);
        setShow(true);
        scrollTop();
      });
  };
  const popoverData = (fid, aid) => {
    setChecklist_info("");
    axios
      .get(API_URL + "project_dashboard/checklist_info?flow=" + fid + "&assign=" + aid, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var checklist_data = `<table class="table table-bordered" style="width:'100%'"><tr><th>#</th><th>Checklist</th><th>Date</th><th>Time</th></tr>`;
        res.data?.data &&
          res.data?.data.map((cnkitem, l) => {
            if (cnkitem?.date)
              checklist_data =
                checklist_data +
                "<tr><td><i class='fas fa-check text-success'></i> " +
                (l + 1) +
                "</td><td>" +
                cnkitem.checklist_name +
                "</td><td>" +
                (cnkitem?.date ? moment(cnkitem?.date).format("DD-MM-YYYY") : "") +
                "</td><td>" +
                (cnkitem?.date ? moment(cnkitem?.time, "HH:mm:ss").format("hh:mm a") : "") +
                "</td></tr>";
            else
              checklist_data =
                checklist_data +
                "<tr><td><i class='fas fa-times text-danger'></i> " +
                (l + 1) +
                "</td><td>" +
                cnkitem.checklist_name +
                "</td><td>" +
                (cnkitem?.date ? moment(cnkitem?.date).format("DD-MM-YYYY") : "") +
                "</td><td>" +
                (cnkitem?.date ? moment(cnkitem?.time, "HH:mm:ss").format("hh:mm a") : "") +
                "</td></tr>";
          });
        checklist_data = checklist_data + "</table>";
        setChecklist_info(checklist_data);
      });
  };
  const chat_flow_count_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 90,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 90,
      },
      {
        label: "Assignee",
        field: "name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Plan",
        field: "assign_time1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Effort",
        field: "assign_effort_hr1",
        sort: "asc",
        width: 90,
      },
    ],
    rows: chatboxData.assignees,
  };
  const uploadAttachment = (e) => {
    const fd = new FormData();
    for (var i = 0; i < e.target.files.length; i++) {
      fd.append("images", e.target.files[i]);
    }
    axios
      .post(`${API_URL}taskcomment/images/`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAttachCount(response.data.file.length);
          setMsgObject({
            ...msgObject,
            ["images"]: response.data.file,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleMessage = (e) => {
    setMsgObject({
      ...msgObject,
      ["comment"]: e.target.value,
    });
  };
  const handleSendMessage = () => {
    axios
      .post(`${API_URL}taskcomment/`, msgObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var new_message = response.data.data;
        var messages_list = [...messages];
        messages_list.push(new_message);
        setMessages(messages_list);
        setMsgObject({
          ...msgObject,
          ["comment"]: "",
          ["images"]: [],
        });
        setAttachCount("");
        scrollTop();
      });
  };
  function scrollTop() {
    const curr = bottomRef.current;
    if (curr) {
      curr.scrollTop = 560 * 1000;
    }
  }
  const handleAssignComplete = (e, index, index1, item, subItem) => {
    if (e.target.checked === true) {
      var list = [...tableData];
      list[index].flows_all[index1].assign_complete_percentage = 100;
      list[index].flows_all[index1].assign_complete_status = 1;

      axios
        .post(
          `${API_URL}taskassign/update_assign_completion`,
          { assign_id: list[index].flows_all[index1].assign_id, status: 1, workeffort: subItem.workeffort, tot_workeffor: item.workeffort },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          list[index].tot_percenatge = response.data.data.total_percentage;
          if (response.data.data.efficiency > 100) list[index].flows_all[index1].efficiency = 100;
          else list[index].flows_all[index1].efficiency = response.data.data.efficiency;

          if (response.data.data.tot_efficiency > 100) list[index].efficiency = 100;
          else list[index].efficiency = response.data.data.tot_efficiency;

          setTableData(list);
        });
    } else {
      var list = [...tableData];
      axios
        .post(
          `${API_URL}taskassign/update_assign_completion`,
          { assign_id: list[index].flows_all[index1].assign_id, status: 0, workeffort: subItem.workeffort, tot_workeffor: item.workeffort },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          list[index].flows_all[index1].assign_complete_percentage = response.data.data.prev_per;
          if (response.data.data.efficiency > 100) list[index].flows_all[index1].efficiency = 100;
          else list[index].flows_all[index1].efficiency = response.data.data.efficiency;
          list[index].flows_all[index1].assign_complete_status = 0;
          list[index].tot_percenatge = response.data.data.total_percentage;
          if (response.data.data.tot_efficiency > 100) list[index].efficiency = 100;
          else list[index].efficiency = response.data.data.tot_efficiency;
          setTableData(list);
        });
    }
  };
  function fetchFlow() {
    axios
      .get(`${API_URL}taskFlow/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var FlowID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.item_name,
              value: el._id,
            };
          });
        setFlowOptions([{ options: FlowID }]);
      });
  }
  const handleSelectTeam = (e) => {
    setSelectedTeam(e);
    if (!selectedUser) {
      setSelectedStaff(null);
    }
    handleStaffOptions(e.value);
  };
  const handleTimeline = (staff = "", task = "", flow = "") => {
    axios
      .get(
        `${API_URL}taskassign/tasktimeline/list?user_id=` +
          staff +
          "&team_id=" +
          "" +
          "&from_date=" +
          "" +
          "&to_date=" +
          "" +
          "&task_id=" +
          task +
          "&flow_id=" +
          flow,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        let tabledata = [];
        result &&
          result.map((item, index) => {
            item.id = index + 1;

            if (item?.date) {
              item.date1 = moment(item.date).format("DD-MM-YYYY");
            } else {
              item.date1 = moment(item.date1).format("DD-MM-YYYY");
            }

            //  if(!item.project){
            //   item.project = "Non-Project"
            //  }

            if (item?.status === 1) {
              item.status1 = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span className="badge rounded-pill bg-primary">Start</span>
                </div>
              );
            }

            if (item?.status === 2) {
              item.status1 = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span className="badge rounded-pill bg-danger">Stop</span>
                </div>
              );
            }

            tabledata.push(item);
          });
        setShowPopup(true);
        setTimeline(tabledata);
      });
  };

  const datatimeline = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Staff",
      //   field: "user_details",
      //   sort: "asc",
      //   width: 150
      // },
      // {
      //   label: "Flow",
      //   field: "flow_name",
      //   sort: "asc",
      //   width: 150
      // },
      // {
      //   label: "Task",
      //   field: "task_name",
      //   sort: "asc",
      //   width: 150
      // },
      // {
      //   label: "Project",
      //   field: "project",
      //   sort: "asc",
      //   width: 150
      // },
    ],
    rows: timeline,
  };

  useEffect(async () => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserId(obj._id);
    }
    handleTeamOptions();
    fetchFlow();
    handleProjectOptions();
    handleStaffOptions();
  }, []);

  return (
    <React.Fragment>
      <Modal show={showPopup} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Time Log</h5>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <Row className="mt-3">
              <MDBDataTable
                id="tasktimeline"
                data={datatimeline}
                responsive
                bordered
                searching={false}
                info={false}
                paging={false}
                disableRetreatAfterSorting={true}
              />
            </Row>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="All Tasks" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <input type="date" value={from_date} name="from_date" className="form-control" onChange={(e) => handleInputChange(e)} />
                    </Col>
                    <Col md="2">
                      <input type="date" name="to_date" className="form-control" value={to_date} onChange={(e) => handleInputChange(e)} />
                    </Col>
                    <Col md="2">
                      <Select
                        placeholder="Team"
                        value={selectedTeam}
                        onChange={(e) => {
                          handleSelectTeam(e);
                        }}
                        options={optionTeam}
                        classNamePrefix="select2-selection"
                        menuPosition="fixed"
                      />
                    </Col>
                    <Col md="2">
                      <Select
                        placeholder="Staff"
                        value={selectedStaff}
                        onChange={(e) => {
                          handleSelectStaff(e);
                        }}
                        options={optionStaff}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md="2">
                      <input
                        type="text"
                        value={taskName}
                        name="taskname"
                        placeholder="Task name"
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>

                    <Col md="2">
                      <Select
                        placeholder="Project"
                        value={selectedProject}
                        onChange={(e) => {
                          handleSelectProject(e);
                        }}
                        options={optionProject}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md="2" className="mt-2">
                      <Select
                        placeholder="Status"
                        value={selectedFilterStatus}
                        onChange={(e) => {
                          handleSelectFilterStatus(e);
                        }}
                        options={[
                          { label: "Completed", value: 1 },
                          { label: "In completed", value: 2 },
                          { label: "Unassigned", value: 3 },
                          { label: "Over Due", value: 4 },
                          { label: "Over Effort", value: 5 },
                          { label: "Running", value: 6 },
                          { label: "Not Running", value: 7 },
                        ]}
                        classNamePrefix="select2-selection"
                        menuPosition="fixed"
                      />
                    </Col>

                    <Col md="3" className="mt-2">
                      <Select
                        placeholder="Flows"
                        value={selectedFlow}
                        onChange={(e) => {
                          handleSelectFlow(e);
                        }}
                        options={flowOptions}
                        isMulti
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md="2" className="mt-2">
                      <Select
                        placeholder="All Task"
                        value={selectedTaskType}
                        onChange={(e) => {
                          handleSelectTaskType(e);
                        }}
                        options={[
                          { label: "All Task", value: 1 },
                          { label: "Other Task", value: 4 },
                          { label: "Work Updates", value: 2 },
                          { label: "Main Task", value: 3 },
                        ]}
                        classNamePrefix="select2-selection"
                        menuPosition="fixed"
                      />
                    </Col>
                    <Col md="3" className="mt-2">
                      <div className="mb-2">
                        {/* <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                  onClick={handleSearch}
                                >
                            
                                  {"  "}
                                  Filter
                                </Button> */}
                        <Button color="danger" type="submit" onClick={reset}>
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <table class="table table-bordered" id="allTaskListTabless">
                      <thead>
                        <tr>
                          {/* <th>#</th> */}
                          <th>Project</th>
                          <th>TaskName</th>
                          <th width={"100px"}>Flow</th>
                          <th width={"100px"}>Assignee</th>
                          <th width={"50px"}>
                            <i className="fa fa-clock" title="Plan" style={{ fontSize: "10px" }}></i> P
                          </th>
                          <th width={"50px"}>
                            <i className="fa fa-clock" title="Effort" style={{ fontSize: "10px" }}></i> E
                          </th>
                          {/* <th><i className='fas fa-user-cog' title="Efficiency" style={{ fontSize:"13px"}}></i></th> */}
                          <th width={"100px"}>Start</th>
                          <th width={"100px"}>End</th>
                          <th width={"100px"}>%</th>
                          <th width={"45px"}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((project, k) => {
                          var i = 0;
                          if (project?.project_name)
                            project.project_name1 = (
                              <a target={"_blank"} href={"/project/" + project?.project_code}>
                                {project?.project_name}
                              </a>
                            );
                          else project.project_name1 = "Non Project";
                          return (
                            <>
                              {project?.tasks.map((item, index) => {
                                var j = 0;
                                return (
                                  <>
                                    {item?.flows_all?.map((subItem, index1) => {
                                      i++;
                                      j++;
                                      if (subItem.firstName == "0") subItem.firstName1 = "";
                                      else
                                        subItem.firstName1 = (
                                          <a target={"_blank"} href={"/add_staff/" + subItem?.staff_id}>
                                            {subItem?.firstName}
                                          </a>
                                        );
                                      if (subItem?.assign_startdate)
                                        subItem.assign_startdate1 = moment(subItem?.assign_startdate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                      if (subItem?.assign_enddate) subItem.assign_enddate1 = moment(subItem?.assign_enddate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                      if (subItem?.assign_time) subItem.assign_time1 = timeFromNumber(subItem?.assign_time);
                                      else subItem.assign_time1 = timeFromNumber(0);
                                      if (typeof subItem.assign_effort_hr !== "undefined") {
                                        subItem.assign_effort_hr1 = subItem?.assign_effort_hr.substring(0, subItem?.assign_effort_hr?.lastIndexOf(":"));
                                      } else {
                                        subItem.assign_effort_hr1 = "00:00";
                                      }
                                      if (subItem?.assign_effort_hr1 == "") subItem.assign_effort_hr1 = "00:00";

                                      if (i == 1 && index == 0) {
                                        let user = subItem.staff_id;
                                        let task = item._id;
                                        let flow = subItem.flow_id;
                                        return (
                                          <>
                                            <tr>
                                              <td rowSpan={project?.count}>{project?.project_name1}</td>
                                              <td rowSpan={item.flows_all.length}>{item.task_name}</td>
                                              <td>
                                                <button
                                                  class="homeTaskLink"
                                                  onClick={() => {
                                                    popoverData(subItem.flow_id, subItem?.assign_id);
                                                  }}
                                                  id={"task" + index + index1 + item._id}>
                                                  {subItem.flow_name}
                                                </button>
                                              </td>
                                              <td>{subItem.firstName1}</td>
                                              <td>{subItem.assign_time1}</td>
                                              <td
                                                user={user}
                                                task={task}
                                                flow={flow}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handleTimeline(user, task, flow);
                                                }}>
                                                {subItem.assign_effort_hr1}
                                              </td>
                                              <td>{subItem.assign_startdate1}</td>
                                              <td>{subItem.assign_enddate1}</td>
                                              <td>
                                                <Progress
                                                  color="primary"
                                                  style={{ height: "14px", border: subItem.due_status }}
                                                  value={subItem.assign_complete_percentage}>
                                                  {subItem.assign_complete_percentage}%
                                                </Progress>
                                              </td>
                                              <td rowSpan={item.flows_all.length}>
                                                <div class="chat-icon">
                                                  {item?.unread_comments > 0 ? (
                                                    <>
                                                      <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                      <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                    </>
                                                  ) : item?.total_comments > 0 ? (
                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                  ) : (
                                                    <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                  )}
                                                </div>
                                              </td>
                                              <UncontrolledPopover trigger="focus" target={"task" + index + index1 + item._id} placement="right">
                                                <PopoverBody>
                                                  <div dangerouslySetInnerHTML={{ __html: checklist_info }}></div>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </tr>
                                          </>
                                        );
                                      } else if (j == 1) {
                                        let user = subItem.staff_id;
                                        let task = item._id;
                                        let flow = subItem.flow_id;
                                        return (
                                          <>
                                            <tr>
                                              <td rowSpan={item.flows_all.length}>{item.task_name}</td>
                                              <td>
                                                <button
                                                  class="homeTaskLink"
                                                  onClick={() => {
                                                    popoverData(subItem.flow_id, subItem?.assign_id);
                                                  }}
                                                  id={"task" + index + index1 + item._id}>
                                                  {subItem.flow_name}
                                                </button>
                                              </td>
                                              <td>{subItem.firstName1}</td>
                                              <td>{subItem.assign_time1}</td>
                                              <td
                                                user={user}
                                                task={task}
                                                flow={flow}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handleTimeline(user, task, flow);
                                                }}>
                                                {subItem.assign_effort_hr1}
                                              </td>
                                              <td>{subItem.assign_startdate1}</td>
                                              <td>{subItem.assign_enddate1}</td>
                                              <td>
                                                <Progress
                                                  color="primary"
                                                  style={{ height: "14px", border: subItem.due_status }}
                                                  value={subItem.assign_complete_percentage}>
                                                  {subItem.assign_complete_percentage}%
                                                </Progress>
                                              </td>
                                              <td rowSpan={item.flows_all.length}>
                                                <div class="chat-icon">
                                                  {item?.unread_comments > 0 ? (
                                                    <>
                                                      <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                      <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                    </>
                                                  ) : item?.total_comments > 0 ? (
                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                  ) : (
                                                    <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                  )}
                                                </div>
                                              </td>
                                              <UncontrolledPopover trigger="focus" target={"task" + index + index1 + item._id} placement="right">
                                                <PopoverBody>
                                                  <div dangerouslySetInnerHTML={{ __html: checklist_info }}></div>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </tr>
                                          </>
                                        );
                                      } else {
                                        let user = subItem.staff_id;
                                        let task = item._id;
                                        let flow = subItem.flow_id;
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                <button
                                                  class="homeTaskLink"
                                                  onClick={() => {
                                                    popoverData(subItem.flow_id, subItem?.assign_id);
                                                  }}
                                                  id={"task" + index + index1 + item._id}>
                                                  {subItem.flow_name}
                                                </button>
                                              </td>
                                              <td>{subItem.firstName1}</td>
                                              <td>{subItem.assign_time1}</td>
                                              <td
                                                user={user}
                                                task={task}
                                                flow={flow}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  handleTimeline(user, task, flow);
                                                }}>
                                                {subItem.assign_effort_hr1}
                                              </td>
                                              <td>{subItem.assign_startdate1}</td>
                                              <td>{subItem.assign_enddate1}</td>
                                              <td>
                                                <Progress
                                                  color="primary"
                                                  style={{ height: "14px", border: subItem.due_status }}
                                                  value={subItem.assign_complete_percentage}>
                                                  {subItem.assign_complete_percentage}%
                                                </Progress>
                                              </td>
                                              <UncontrolledPopover trigger="focus" target={"task" + index + index1 + item._id} placement="right">
                                                <PopoverBody>
                                                  <div dangerouslySetInnerHTML={{ __html: checklist_info }}></div>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </tr>
                                          </>
                                        );
                                      }
                                    })}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement={"end"} style={{ width: "1200px" }}>
        <Row>
          <Col md="7">
            {/* <Offcanvas.Header closeButton style={{ padding:"5px"}}>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header> */}
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  {isProjectChat ? (
                    <>
                      <table id="projectInfoTable" width={"100%"}>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Project Name</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.project_name}</td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Shortcode</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.shortcode}</td>
                        </tr>
                        {/* <tr>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                  Client
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  : {chatboxData?.clients}
                                </td>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                Type
                                </td>
                                <td style={{ textAlign: "left" }}>
                                : {chatboxData?.types}
                                </td>
                              </tr> */}

                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Technologies</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.TechForTbl}</td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Domain</td>
                          <td style={{ textAlign: "left" }}>
                            <a target="_blank" href={chatboxData?.domain} style={{ paddingLeft: "0%" }}>
                              : {chatboxData?.domain}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Quotation No</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.quotation_no}</td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Invoice No</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.invoice_no}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Kick Off</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.kickoff}</td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>End</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.End}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Project Status</td>
                          <td style={{ textAlign: "left" }}>
                            :
                            <p
                              className="badge rounded-pill "
                              style={{
                                fontSize: "initial",
                                marginBottom: "auto",
                              }}>
                              {chatboxData?.ProjectStatus}
                            </p>
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Billing Status</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.bstatus}</td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Manager</td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_manager?.firstName} {chatboxData.project_manager?.lastName}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Team Leader</td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_leader?.firstName} {chatboxData.project_leader?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Hours</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.hours}</td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>Remark</td>
                          <td style={{ textAlign: "left" }}>: {chatboxData?.remarks}</td>
                        </tr>
                      </table>
                    </>
                  ) : (
                    ""
                  )}
                  <MDBDataTable
                    id="flowCountTableIds"
                    responsive
                    bordered
                    data={chat_flow_count_data}
                    searching={false}
                    disableRetreatAfterSorting={true}
                    paging={true}
                    info={false}
                    entriesOptions={[5, 10]}
                  />
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  <div className="chat-input-section">
                    <Row>
                      <div className="px-lg-2">
                        <div className="chat-conversation pt-2">
                          <ScrollBar style={{ height: "455px" }} className="list-unstyled mb-0" containerRef={(el) => (bottomRef.current = el)}>
                            {messages.map((message, index) => {
                              if (message.comment) {
                                return (
                                  <>
                                    <li key={"test_k" + index} className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content">
                                            <h5 className="font-size-14 conversation-name">
                                              <Link to="#" className="text-dark">
                                                {message.createdBy.firstName}
                                              </Link>
                                              <span className="d-inline-block font-size-12 text-muted ms-2">
                                                {moment(message.createdAt).format("DD-MM-YYYY hh:mm a")} -{" "}
                                                {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                              </span>
                                            </h5>
                                            <p className="mb-0 remarks-comment">{message.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                      } else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                      } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                      } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">
                                                      {message.createdBy.firstName}
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                      } else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                      } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                      } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">
                                                      {message.createdBy.firstName}
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })}
                          </ScrollBar>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <Input
                            value={msgObject?.comment}
                            type="textarea"
                            id="comment"
                            name="comment"
                            className="form-control chat-input rounded"
                            onChange={(e) => handleMessage(e)}
                            rows="1"
                            placeholder="Enter Message..."
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div class="image-upload">
                          <label for="file-input">
                            {attachCount} <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                          <input id="file-input" type="file" multiple onChange={uploadAttachment} />
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button type="submit" color="primary" onClick={handleSendMessage} className="chat-send waves-effect waves-light">
                          <i className="mdi mdi-send float-end"></i>
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Offcanvas>
    </React.Fragment>
  );
};
export default AllTasks;
