import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Advance.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";
import { borderColor } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Fund Source",
        field: "FSource",
        sort: "asc",
        width: 100,
      },
      {
        label: "Remarks",
        field: "remarks",
        sort: "asc",
        width: 100,
      },
      {
        label: "Addedd By",
        field: "ABy",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      {
        id: "1",
        date: "25-04-2011",
        time: "09:24 am",
        amount: "20000",
        staff: "Mythree",
        FSource: "Service",
        remarks: "Good",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        date: "25-04-2011",
        time: "09:24 am",
        amount: "20000",
        staff: "Namitha",
        FSource: "Service",
        remarks: "Good",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "3",
        date: "25-04-2011",
        time: "09:24 am",
        amount: "20000",
        staff: "Abhirag",
        FSource: "Service",
        remarks: "Good",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "4",
        date: "25-04-2011",
        time: "09:24 am",
        amount: "20000",
        staff: "Harshita",
        FSource: "Service",
        remarks: "Good",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "5",
        date: "25-04-2011",
        time: "09:24 am",
        amount: "20000",
        staff: "Nihal",
        FSource: "Service",
        remarks: "Good",
        type: "Monthly",
        BSalary: "10000",
        HrCost: "300",
        HrRate: "300",
        HrSalary: "300",
        ABy: "ABC",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available Leaves(days)",
        field: "ALeaves",
        sort: "asc",
        width: 270,
      },
    ],
    rows1: [
      {
        id: "1",
        name: "Anitha",
        Salarytype: "",
      },
      {
        id: "1",
        LType: "Sick Leave",

        ALeaves: "12",
      },
      {
        id: "2",
        LType: "Casual Leave",

        ALeaves: "5",
      },
    ],
  };

  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      options: [
        { label: "Admin", value: "admin" },
        { label: "Cashier", value: "cashier" },
        { label: "Receptionist", value: "receptionist" },
        { label: "Hr", value: "hr" },
        { label: "Pr", value: "pr" },
      ],
    },
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };

  const decreamentCount = () => {
    setCount1(count1 - 1);
  };

  const decreamentCount1 = () => {
    setCount2(count2 - 1);
  };

  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const customStyles = {
    lineHeight: "1.8",
  };
  const textAreaStyles = {
    borderColor: "#c0c0c099",
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Advance" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup();
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Amount</Label>

                          <AvField
                            style={customStyles}
                            name="amount"
                            placeholder="Amount"
                            type="text"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Fund source
                          </Label>
                          <Select
                            name="type"
                            errorMessage="Select Category Type"
                            validate={{ required: { value: true } }}
                            // value={selectedType}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected, "type");
                            // }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Remarks</Label>

                          <textarea
                            style={textAreaStyles}
                            name="remarks"
                            placeholder="Remarks"
                            type="text"
                            errorMessage="Enter Remarks"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-2">
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          {/* <Save></Save> */}
                          {"  "}
                          Submit
                        </Button>
                        <Button color="danger" type="submit">
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>View Advance List</Label>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="month"
                          value={value}
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          onChange={handleChangeDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="month"
                          value={value}
                          defaultValue="mm/dd/yyyy"
                          id="example-date-input"
                          onChange={handleChangeDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          {/* <Save></Save> */}
                          {"  "}
                          Filter
                        </Button>
                        <Button color="danger" type="submit">
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="advanceTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Staff Salary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack direction="row" spacing={2} style={{ flexDirection: "row" }}>
              <div style={{ padding: "20px" }}>
                <b>Staff:</b>
                <p>Mythree</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Salary Type:</b>
                <p>Monthly</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Basic Salary : </b>
                <p>100000</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Cost : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Rate : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Salary: </b>
                <p>300</p>
              </div>
            </Stack>
            {/* Modal Body Start */}
            <MDBDataTable responsive bordered data={data1} />
            {/* Modal Body End */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
