import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import {
    Row, Col, Card, CardBody, Button, Label, Container, Progress, Input, CardHeader, Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ButtonDropdown
} from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tabs from '@material-ui/core/Tabs';
import TabPanel from "@mui/lab/TabPanel";
import "./kanban.scss";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";

import { Link } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
    getRouteName,
    timeFromNumber, addTimes, timeInMinute, timeSince
} from "../../../../helpers/globalFunctions";
import CountUp from 'react-countup';
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import $ from "jquery";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";

import SweetAlert from "react-bootstrap-sweetalert";
import { unset } from "lodash";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { setValue } from "@syncfusion/ej2-base";
// Tab UI
// import List from "././component/List/List";
// import Dashboard from "././Dashboard1/index";

import Offcanvas from 'react-bootstrap/Offcanvas';
import ScrollBar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
const ProjectDetails = (props) => {
    const { addProject } = props.match.params;
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [taskCategoryOptions, setTaskCategoryOptions] = useState([]);
    const [taskAssigneeOptions, setTaskAssigneeOptions] = useState([]);
    const [taskRows, setTaskRows] = useState([]);
    const [taskPermission, SetTaskPermission] = useState(true);
    const [taskIdToBeDeleted, setTaskIdToBeDeleted] = useState({});
    const [confirmDeleteTaskAlert, setConfirmDeleteTaskAlert] = useState(null);
    const [assignIdToBeDeleted, setAssignIdToBeDeleted] = useState({});
    const [confirmDeleteAssignAlert, setConfirmDeleteAssignAlert] = useState(null);
    const [canvasshow, setCanvasShow] = useState(false);
    const handleClose = () => setCanvasShow(false);
    const [attachCount, setAttachCount] = useState("");
    const [msgObject, setMsgObject] = useState({});
    const [messages, setMessages] = useState([]);
    const [chatboxData, setChatboxData] = useState({});
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [isExpand, setIsExpand] = useState(true);
    const [isExpandSub, setisExpandSub] = useState(false);
    const bottomRef = useRef();
    const addNewTaskRow = () => {
        setTaskRows([...taskRows, {
            subcategory: null,
            task_name: "",
            task_tot_time: 0,
            task_start_date: "",
            task_end_date: "",
            expand: "hidden",
            flows: []
        }])
    }
    function scrollTop() {
        const curr = bottomRef.current
        if (curr) {
            curr.scrollTop = 560 * 1000
        }
    }
    const handleAssignTask = (selected, index, index1, item, subItem, type) => {
        var token = localStorage.getItem("authUser");
        var user_obj = JSON.parse(token);
        var user_id = user_obj._id;

        var tasks = [...taskRows];
        var parameters = { task_id: item._id, user_id: user_id, task_subcategory_id: item.task_subcategory_id, task_flow_id: subItem.flow_id, assign_id: subItem?.assign_id };
        var logdate = {}
        var worklog = {}
        if (type == "staff") {
            var value = selected.value;
            tasks[index].flows[index1].staff_id = value;
            tasks[index].flows[index1].assignee = selected;
            parameters.staff_id = value;
            parameters.assign_time = tasks[index].flows[index1].assign_time;



        }
        else if (type == "time") {
            var value = selected.target.value;
            if (value != "") {

                var old_time = tasks[index].flows[index1].assign_time;
                if (typeof old_time === "undefined" || old_time == "") {
                    old_time = 0;
                }
                var diff = parseFloat(value) - old_time;
                tasks[index].flows[index1].assign_time = value;
                tasks[index].task_tot_time = tasks[index].task_tot_time + parseFloat(diff);
            }
            else {
                var old_time = tasks[index].flows[index1].assign_time;
                tasks[index].task_tot_time = tasks[index].task_tot_time - parseFloat(old_time);
                tasks[index].flows[index1].assign_time = value;
            }

            parameters.assign_time = value;

        }
        else if (type == "start") {
            var value = selected.target.value;
            var end_date = tasks[index].flows[index1].assign_enddate;
            if (value > end_date) {
                tasks[index].flows[index1].assign_enddate = value;
                parameters.assign_enddate = value;
            }
            tasks[index].flows[index1].assign_startdate = value;
            if (tasks[index].task_start_date > value) {
                tasks[index].task_start_date = value;
            }
            else {
                var start = [];
                tasks[index].flows.map((item, index) => {
                    if (item.assign_startdate)
                        start.push(item.assign_startdate);
                });
                start.sort();
                tasks[index].task_start_date = start[0];
            }
            parameters.assign_startdate = value;

        }
        else if (type == "end") {
            var value = selected.target.value;
            tasks[index].flows[index1].assign_enddate = value;
            if (tasks[index].task_end_date < value) {
                tasks[index].task_end_date = value;
            }
            else {
                var end = [];
                tasks[index].flows.map((item, index) => {
                    if (item.assign_enddate)
                        end.push(item.assign_enddate);
                });
                end.sort();
                tasks[index].task_end_date = end[end.length - 1];
            }
            parameters.assign_enddate = value;
        }
        else if (type == "status") {
            var value = selected.value;
            tasks[index].flows[index1].assign_current_status = value;
            tasks[index].flows[index1].currentstatus = selected;
            parameters.assign_current_status = value;
        }
        parameters.flow_row = tasks[index].flows[index1];
        axios
            .post(`${API_URL}taskassign/`, parameters, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var tasks = [...taskRows];
                tasks[index].flows[index1].assign_id = res.data.data._id;
                setTaskRows(tasks);
            });


        // logdate = parameters.flow_row
        // axios
        // .post(`${API_URL}taskassign/work/logdate`,logdate, {

        //   headers: {
        //     "x-access-token": accessToken,
        //   },
        // })
        // .then((res) => {

        //     let datevalue=moment(res.data.data.date,"YYYY-MM-DD").format("YYYY-MM-DD");
        //      tasks[index].flows[index1].assign_startdate = datevalue;
        //      parameters.flow_row.assign_startdate = datevalue;
        // });

        // console.log("parameters",parameters)
        // let log = parameters.flow_row
        // console.log("logdate22",log)

        // worklog.staff_id=log.staff_id;
        // worklog.assign_time=log.assign_time;
        // worklog.assign_startdate=parameters.flow_row.assign_startdate;
        // worklog.assign_enddate=log.assign_enddate;
        // worklog.assign_id=log.assign_id;

        // console.log("worklog",worklog)
        // axios
        // .post(`${API_URL}taskassign/work/log`,worklog, {

        //   headers: {
        //     "x-access-token": accessToken,
        //   },
        // })
        // .then((res) => {
        //   if (res.data.status === 200) {

        //   }
        //   // console.log("res.data",res.data)
        // else {
        //               toastr.error("Free slot not available");
        //               return;
        //             }

        // })
        // .catch((err) => {
        //   toastr.error(err.response.data.message);
        //   return;
        // });

        setTaskRows(tasks);
        console.log(tasks);
    }
    const handleAddTask = (selected, index, item, type) => {
        var token = localStorage.getItem("authUser");
        var user_obj = JSON.parse(token);
        var user_id = user_obj._id;

        var tasks = [...taskRows];
        if (type == "task_name") {
            var task_name = selected.target.value;
            tasks[index].task_name = task_name;
        }
        else {
            var category_id = selected.value;
            tasks[index].task_subcategory_id = category_id;
            tasks[index].subcategory = selected;
            tasks[index].flows = [];
            tasks[index].expand = "show";
        }
        setTaskRows(tasks);

        if (typeof item._id !== "undefined") {
            if (type == "task_name") {
                var parameters = { task_id: item._id, user_id: user_id, task_name: task_name }
                axios
                    .put(`${API_URL}taskassign/update_task`, parameters, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    })
                    .then((res) => {
                    });
            }
            else {
                var parameters = { task_id: item._id, user_id: user_id, task_subcategory_id: category_id }
                axios
                    .put(`${API_URL}taskassign/update_task`, parameters, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    })
                    .then((res) => {
                        var tasks = [...taskRows];
                        tasks[index]._id = res.data.data._id;
                        setTaskRows(tasks);
                        fetchTaskFlows(tasks[index]._id, category_id, index)
                    });
            }
        }
        else {
            if (item.subcategory) {
                if (type == "task_name") {
                    var parameters = { project_id: addProject, user_id: user_id, task_name: task_name, task_subcategory_id: item.subcategory?.value }
                    axios
                        .post(`${API_URL}taskassign/add_task`, parameters, {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        })
                        .then((res) => {
                            var tasks = [...taskRows];
                            tasks[index]._id = res.data.data._id;
                            setTaskRows(tasks);
                        });
                }
                else {
                    var parameters = { project_id: addProject, user_id: user_id, task_subcategory_id: category_id, task_name: item.task_name }
                    axios
                        .post(`${API_URL}taskassign/add_task`, parameters, {
                            headers: {
                                "x-access-token": accessToken,
                            },
                        })
                        .then((res) => {
                            var tasks = [...taskRows];
                            tasks[index]._id = res.data.data._id;
                            setTaskRows(tasks);
                            fetchTaskFlows(res.data.data._id, category_id, index)
                        });
                }

            }
        }

    }
    const showTask = (index) => {
        setisExpandSub(true);
        var tasks = [...taskRows];
        tasks[index].expand = "show";
        setTaskRows(tasks);
    }
    const hideTask = (index) => {
        setisExpandSub(true);
        var tasks = [...taskRows];
        tasks[index].expand = "hidden";
        setTaskRows(tasks);
    }
    const handleTaskTable = (id, type = "") => {
        axios
            .get(`${API_URL}taskassign/` + id + "?type=" + type, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var data = res.data.data;
                SetTaskPermission(res.data.permission);
                if (data.length == 0) {
                    setTaskRows([{
                        subcategory: null,
                        task_name: "",
                        expand: "hidden",
                        flows: []
                    }])
                }
                else {
                    var tasks = [];
                    data.map((item, index) => {
                        item.subcategory = { label: item.subcat_name, value: item.task_subcategory_id };
                        item.expand = "show";
                        if (typeof item.flows === "undefined") {
                            item.flows = [];
                        }
                        else {
                            item.task_tot_time = 0;
                            item.task_start_date = "";
                            item.task_end_date = "";
                            var start_date = [];
                            var end_date = [];

                            var total_assign = 0;

                            item.flows.map((subItem, index) => {

                                total_assign++;

                                if (subItem.assign_startdate)
                                    start_date.push(subItem.assign_startdate);
                                if (subItem.assign_enddate)
                                    end_date.push(subItem.assign_enddate);
                                subItem.assign_time = subItem.assign_time ? subItem.assign_time : subItem.duration;
                                subItem.assign_time = parseFloat(subItem.assign_time);
                                var assign_time = subItem.assign_time ? subItem.assign_time : 0;
                                item.task_tot_time = item.task_tot_time + assign_time;
                                var status_options = [];
                                subItem.assignee = { label: subItem.firstName, value: subItem.staff_id };
                                subItem.flow_status.map((statusItem) => {
                                    if (!res.data.permission)
                                        status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority });
                                    else
                                        status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority, isDisabled: true });
                                })
                                subItem.statusOption = status_options;
                                subItem.currentstatus = { label: subItem?.assign_current_status_name, value: subItem?.assign_current_status };
                            });
                            start_date.sort();
                            end_date.sort();
                            //console.log(end_date);
                            item.task_start_date = start_date[0] ? start_date[0] : "";
                            item.task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";
                        }
                        tasks.push(item);
                    });
                    setTaskRows(tasks);
                }

            });
    }
    const fetchTaskFlows = (task_id, category_id, index) => {
        axios
            .get(`${API_URL}taskassign/getFlows/` + task_id + `/` + category_id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var tasks = [...taskRows];
                tasks[index].task_tot_time = 0;
                var start_date = [];
                var end_date = [];
                var flow_ids = [];
                res.data.data.map((item, index1) => {
                    flow_ids.push();
                    start_date.push(item.assign_startdate);
                    end_date.push(item.assign_enddate);
                    item.assign_time = item.assign_time ? item.assign_time : item.duration;
                    item.assign_time = parseFloat(item.assign_time);
                    var assign_time = item.assign_time ? item.assign_time : 0;
                    tasks[index].task_tot_time = tasks[index].task_tot_time + assign_time;
                    item.assignee = { label: item?.firstName, value: item?.staff_id };
                    var status_options = [];
                    item.flow_status.map((statusItem) => {
                        status_options.push({ label: statusItem.type_name, value: statusItem._id });
                    })
                    item.statusOption = status_options;
                    item.currentstatus = { label: item?.assign_current_status_name, value: item?.assign_current_status, priority: item.type_priority };
                })
                start_date.sort();
                end_date.sort();
                tasks[index].task_start_date = start_date[0] ? start_date[0] : "";
                tasks[index].task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";
                tasks[index].flows = res.data.data;
                setTaskRows(tasks);
            });
    }
    const [manageTaskType, setManageTaskType] = useState("All task");
    const [drp_secondary_sm, setDrp_secondary_sm] = useState(false)
    const [userId, setUserId] = useState();
    const handleexpandAll = (val) => {
        setIsExpand(val);
        setisExpandSub(false);
    }
    const handleShow = (item, index) => {

        setMsgObject({
            ...msgObject,
            ["assign_id"]: item._id,
            ["flow_id"]: item.task_flow_id,
            ["task_id"]: item._id,
            ["project_id"]: item.project_id,
            ["subcategory_id"]: item.task_subcategory_id,
        });
        axios
            .get(`${API_URL}taskcomment/list?task_id=` + item._id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((response) => {
                var data = response.data.data;
                setMessages(response.data.data);
                if (typeof response.data.project_info.assignees !== "undefined") {
                    response.data.project_info.assignees.map((item, index) => {
                        item.id = index + 1;
                        if (item.assign_time)
                            item.assign_time1 = timeFromNumber(item.assign_time);
                        else
                            item.assign_time1 = "00:00";

                        if (item.assign_effort_hr)
                            item.assign_effort_hr1 = item.assign_effort_hr.substring(0, item.assign_effort_hr.lastIndexOf(":"));
                        else
                            item.assign_effort_hr1 = "00:00";
                        if (item.assign_effort_h1r == "")
                            item.assign_effort_hr1 = "00:00";
                    })

                }
                setChatboxData(response.data.project_info);
                setCanvasShow(true);
                scrollTop()
            });



    }
    const fetchTaskCategory = () => {
        axios
            .get(`${API_URL}taskassign/category/options?project_id=` + addProject, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var permission = res.data.permission;
                if (permission) {
                    var SearchType_data =
                        res.data.data &&
                        res.data.data.map((el) => {
                            return {
                                label: el.subcat_name,
                                value: el._id,
                            };
                        });
                }
                else {
                    var SearchType_data =
                        res.data.data &&
                        res.data.data.map((el) => {
                            return {
                                label: el.subcat_name,
                                value: el._id,
                                isDisabled: true
                            };
                        });
                }

                setTaskCategoryOptions([{ options: SearchType_data }]);
            });
    }
    const fetchTaskAssignee = () => {
        axios
            .get(`${API_URL}taskassign/staff/options?project_id=` + addProject, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var permission = res.data.permission;
                if (permission) {
                    var SearchType_data =
                        res.data.data &&
                        res.data.data.map((el) => {
                            return {
                                label: el.firstName,
                                value: el._id,
                            };
                        });
                }
                else {
                    var SearchType_data =
                        res.data.data &&
                        res.data.data.map((el) => {
                            return {
                                label: el.firstName,
                                value: el._id,
                                isDisabled: true
                            };
                        });
                }
                setTaskAssigneeOptions([{ options: SearchType_data }]);
            });
    }
    const handleDeleteTask = (id, index) => {
        axios
            .post(`${API_URL}taskassign/delete_task`, { id: id }, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [...taskRows];
                list.splice(index, 1);
                setTaskRows(list);
                setConfirmDeleteTaskAlert(false);
                toastr.success("Task deleted successfully");
            })
    }
    const handleDeleteAssign = (id, index1, index) => {
        axios
            .post(`${API_URL}taskassign/delete_assign`, { id: id }, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [...taskRows];
                list[index].flows[index1].assign_id = null;
                list[index].flows[index1].assign_startdate = "";
                list[index].flows[index1].assign_enddate = "";
                var start_date = [];
                var end_date = [];
                list[index].flows && list[index].flows.map((item, indexs) => {
                    if (item?.assign_startdate && indexs != index1)
                        start_date.push(item.assign_startdate);
                    if (item?.assign_enddate && indexs != index1)
                        end_date.push(item.assign_enddate);
                });
                start_date.sort();
                end_date.sort();
                console.log(start_date)
                list[index].task_start_date = start_date[0];
                list[index].task_end_date = end_date[end_date.length - 1];
                setTaskRows(list);
                setConfirmDeleteAssignAlert(false);
                // handleStaffOptions();
                handleTaskTable(addProject);
                setAssignIdToBeDeleted({});
                toastr.success("Task deleted successfully");
            })
    }
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            if (obj.allow_assign)

                setUserId(obj._id);
        }
        if (addProject) {
            fetchTaskAssignee();
            fetchTaskCategory();
            handleTaskTable(addProject);
        }
    }, [addProject]);

    return (
        <React.Fragment>
            {confirmDeleteTaskAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleDeleteTask(taskIdToBeDeleted.id, taskIdToBeDeleted.index)
                    }}
                    onCancel={() => setConfirmDeleteTaskAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            {confirmDeleteAssignAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleDeleteAssign(assignIdToBeDeleted?.aid, assignIdToBeDeleted?.index1, assignIdToBeDeleted?.index)
                    }}
                    onCancel={() => setConfirmDeleteAssignAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <div className="page-content">
                <>
                    <Card>
                        <CardBody>
                            {(isExpand == null || isExpand == false) ? (
                                <>
                                    <button type="button"
                                        className="btn btn-primary btn-sm"
                                        style={{ marginBottom: "8px" }}
                                        onClick={(e) => handleexpandAll(true)}
                                    >Expand</button>

                                </>
                            ) : (
                                <>
                                    <button type="button"
                                        className="btn btn-primary btn-sm"
                                        style={{ marginBottom: "8px" }}
                                        onClick={(e) => handleexpandAll(null)}
                                    >Shrink</button>
                                </>
                            )}
                            <div className="btn-group me-1 mt-2">

                                <ButtonDropdown
                                    isOpen={drp_secondary_sm}
                                    toggle={(e) => {
                                        setDrp_secondary_sm(!drp_secondary_sm)
                                    }}
                                    className="btn-sm "
                                    style={{ marginBottom: "14px" }}
                                >
                                    <DropdownToggle
                                        caret
                                        color="primary"
                                        className="btn btn-primary btn-sm"
                                    >
                                        {manageTaskType}

                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(e) => {
                                            setManageTaskType("All Task");
                                            handleTaskTable(addProject, 1)
                                        }}>All Task</DropdownItem>
                                        <DropdownItem onClick={(e) => {
                                            setManageTaskType("Work Updates");
                                            handleTaskTable(addProject, 2)
                                        }}
                                        >Work Updates</DropdownItem>
                                        <DropdownItem onClick={(e) => {
                                            setManageTaskType("Main Task");
                                            handleTaskTable(addProject, 3)
                                        }}
                                        >Main Task</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>

                            {/* <BootstrapTreeTable columns={treeColumns} tableData={[...taskRows]} control={treeControls}/>  */}
                            <div>
                                <table class="table table-bordered" id="taskListTable">
                                    <thead>
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th width="200px">Category</th>
                                            <th width="250px">TaskName</th>
                                            <th width="130px">Flow</th>
                                            <th>Assignee</th>
                                            <th width="60px">Time</th>
                                            <th width="95px">Start</th>
                                            <th width="95px">End</th>
                                            {/* <th>Status</th> */}
                                            <th width="55px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            taskRows.map((item, index) => (
                                                <>
                                                    <tr class="shown">
                                                        <td class="">
                                                            <Row>
                                                                <Col md="1" style={{ cursor: "pointer", paddingTop: "5px", paddingLeft: "8px", minWidth: "12px" }}>
                                                                    <span >
                                                                        {
                                                                            (item.flows.length > 1) ? (
                                                                                <>
                                                                                    {((isExpand == true && isExpandSub == false) || (isExpandSub == true && item?.expand == "show")) ? (

                                                                                        <>
                                                                                            <svg onClick={() => hideTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="collapsePoint">
                                                                                                <path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                                                                            </svg>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <svg onClick={() => showTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" id="expandPoint">
                                                                                                <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                                            </svg>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : ""
                                                                        }
                                                                    </span>
                                                                </Col>
                                                                <Col md="11" style={{ minWidth: "150px" }}>
                                                                    <Select
                                                                        menuPosition="fixed"
                                                                        name="subcategory"
                                                                        value={item.subcategory}
                                                                        onChange={(selected) => {
                                                                            handleAddTask(selected, index, item, 'category');
                                                                        }}
                                                                        options={taskCategoryOptions}
                                                                        classNamePrefix="select2-selection"
                                                                        disabled={taskPermission}
                                                                    />
                                                                </Col>
                                                            </Row>


                                                        </td>
                                                        <td class="" ><input disabled={taskPermission} type="text" name="task_name" class="form-control" value={item.task_name} onChange={(e) => handleAddTask(e, index, item, 'task_name')}></input></td>
                                                        <td class="">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" id="defaultCheck2" value="" checked="" />
                                                                <label class="form-check-label" for="defaultCheck2">{item.flows[0]?.flow_name}</label>
                                                            </div>
                                                        </td>
                                                        <td class="">
                                                            <Select
                                                                menuPosition="fixed"
                                                                name="staff_id"
                                                                placeholder=""
                                                                value={item.flows[0]?.assignee}
                                                                onChange={(selected) => {

                                                                    handleAssignTask(selected, index, 0, item, item.flows[0], "staff");
                                                                }}
                                                                options={taskAssigneeOptions}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </td>
                                                        {/* <td class=""><input type="text" name="task_tot_time" class="form-control" value={item?.task_tot_time} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_start_date" class="form-control" value={item?.task_start_date} style={{ width: '115px' }} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_end_date" class="form-control" value={item?.task_end_date} style={{ width: '115px' }} readOnly></input></td>
                                                         */}
                                                        <td class=""><input disabled={taskPermission} name="assign_time" class="form-control" value={item.flows[0]?.assign_time} onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "time")}></input></td>
                                                        <td class=""><input disabled={taskPermission} min={moment().format("YYYY-MM-DD")} type='date' name="assign_startdate" class="form-control" value={item.flows[0]?.assign_startdate} onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "start")} style={{ width: '115px' }}></input></td>
                                                        <td class=""><input disabled={taskPermission} min={item.flows[0]?.assign_startdate ? item.flows[0]?.assign_startdate : moment().format("YYYY-MM-DD")} type='date' name="assign_enddate" class="form-control" value={item.flows[0]?.assign_enddate} onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "end")} style={{ width: '115px' }}></input></td>

                                                        <td style={{ textAlign: "center" }}>
                                                            {item._id ? (
                                                                <Row>
                                                                    <Col md="4">
                                                                        {
                                                                            (item?.unread_comments > 0) ? (
                                                                                <div class="chat-icon">
                                                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                                    <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                                                </div>
                                                                            ) : ((item?.total_comments > 0) ? (
                                                                                <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                            ) : (
                                                                                <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                                            ))
                                                                        }
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <i className="fas fa-trash" title="Delete Assign"
                                                                            onClick={() => {
                                                                                setAssignIdToBeDeleted({ aid: item.flows[0].assign_id, index1: 0, index: index });
                                                                                setConfirmDeleteAssignAlert(true);
                                                                            }}></i>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <i className="fas fa-trash" title="Delete Main Task"
                                                                            onClick={() => {
                                                                                setTaskIdToBeDeleted({ id: item._id, index: index });
                                                                                setConfirmDeleteTaskAlert(true);
                                                                            }}></i>
                                                                    </Col>
                                                                </Row>



                                                            ) : ""}

                                                        </td>
                                                    </tr>
                                                    {
                                                        item.flows && item.flows.map((subItem, index1) => {
                                                            if (index1 != 0)
                                                                return (
                                                                    <>
                                                                        <tr class={((isExpand == true && isExpandSub == false) || (isExpandSub == true && item?.expand == "show")) ? "show" : "hidden"}>
                                                                            <td class=""></td>
                                                                            <td class=""></td>
                                                                            <td class="">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="checkbox" id="defaultCheck2" value="" checked="false" />
                                                                                    <label class="form-check-label" for="defaultCheck2">{subItem?.flow_name}</label>
                                                                                </div>
                                                                            </td>
                                                                            <td class="">
                                                                                <Select
                                                                                    menuPosition="fixed"
                                                                                    name="staff_id"
                                                                                    placeholder=""
                                                                                    value={subItem?.assignee}
                                                                                    onChange={(selected) => {

                                                                                        handleAssignTask(selected, index, index1, item, subItem, "staff");
                                                                                    }}
                                                                                    options={taskAssigneeOptions}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </td>
                                                                            <td class=""><input disabled={taskPermission} name="assign_time" class="form-control" value={subItem?.assign_time} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "time")}></input></td>
                                                                            <td class=""><input disabled={taskPermission} min={moment().format("YYYY-MM-DD")} type='date' name="assign_startdate" class="form-control" value={subItem?.assign_startdate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "start")} style={{ width: '115px' }}></input></td>
                                                                            <td class=""><input disabled={taskPermission} min={subItem?.assign_startdate ? subItem?.assign_startdate : moment().format("YYYY-MM-DD")} type='date' name="assign_enddate" class="form-control" value={subItem?.assign_enddate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "end")} style={{ width: '115px' }}></input></td>

                                                                            <td>
                                                                                <i className="fas fa-trash" title="Delete Assign"
                                                                                    onClick={() => {
                                                                                        setAssignIdToBeDeleted({ aid: subItem.assign_id, index1: index1, index: index });
                                                                                        setConfirmDeleteAssignAlert(true);
                                                                                    }}></i>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                        })
                                                    }

                                                </>
                                            )
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                taskPermission === false ? (
                                    <>
                                        <button type="button"
                                            className="btn btn-primary btn-sm"
                                            style={{ marginBottom: "8px" }}
                                            onClick={(e) => addNewTaskRow()}
                                        >Add Task</button>
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        </CardBody>
                    </Card>
                </>
            </div>
        </React.Fragment>
    )
}
export default ProjectDetails;