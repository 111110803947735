import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./TaskSubcategory.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const TaskSubcategory = () => {
  // const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [subcategObject, setSubcategObject] = useState({});
  const [subcategIdTobeUpdated, setSubcategIdToBeUpdated] = useState(null);
  const [subcategIdToBeDeleted, setSubcategIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [subcategForTable, setSubcategForTable] = useState([]);
  const [subcategValue, setSubcategValue] = useState("");

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [flow, setFlow] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);

  const [values, setValues] = useState([
    {
      flow_id: "",
      duration: "",
    },
  ]);

  // const [errors, setErrors] = useState({
  //   categoryID: "",
  //   FlowID: "",
  // });

  const {
    districts,
    addingSubcategory,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_category_id();
    fetch_flow_id();
  }, []);

  function handleTableData() {
    var url = `${API_URL}taskSubcategory/list`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let SubcategData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateSubcategory(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setSubcategIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          item.Flow = (
            <div>
              <ul style={{ listStyleType: "none" }}>
                {item.subcat_flow &&
                  item.subcat_flow.map((el) => {
                    let FlowCate = el?.flow_id || {};
                    return (
                      <li style={{ textAlign: "left" }} className="mb-2">
                        <span style={{ marginLeft: "-27px" }}>
                          {FlowCate?.item_name}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );

          item.Duration = (
            <div>
              <ul style={{ listStyleType: "none" }}>
                {item.subcat_flow &&
                  item.subcat_flow.map((el) => {
                    return (
                      <li style={{ textAlign: "left" }} className="mb-2">
                        <span style={{ marginLeft: "-27px" }}>
                          {el?.duration}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );

          let Category = item?.category_id || {};
          item.Categ = Category?.category_name;

          if (item?.updatedBy) {
            let values = item?.updatedBy || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.createdBy || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          SubcategData.push(item);
        });

        setSubcategForTable(SubcategData);
      });
  }

  let preUpdateSubcategory = (item) => {
    formRef.current.reset();
    setSubcategValue(item?.name);
    setSubcategIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}taskSubcategory/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let ClientCateg = result?.category_id || {};
        let ClientCate = ClientCateg?.category_name;
        let ClientCat = {
          label: ClientCate,
          value: ClientCate?._id,
        };
        setSelectedCategory(ClientCat);

        // var dataValue =
        //   result.subcat_flow &&
        //   result.subcat_flow.map((itm) => ({
        //     let Flow = itm?.flow_id||{};
        //     flowName: itm.item_name,
        //     flowName_id: itm._id,
        //   }));

        let tableData = [];
        result.subcat_flow &&
          result.subcat_flow.map((cont, index) => {
            let val = {};

            let Flow = cont?.flow_id || {};
            val.flowName = Flow?.item_name;
            val.flowName_id = Flow?._id;
            val.flow_id = Flow?._id;
            val.duration = cont?.duration;

            tableData.push(val);
            console.log("AllFlow:", val);
          });
        setValues(tableData);

        // element.client_cat_id = element?._id;
      });
    setSubcategObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Category",
      //   field: "Categ",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Name",
        field: "subcat_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Flow",
        field: "Flow",
        sort: "asc",
        width: 150,
      },

      {
        label: "Duration",
        field: "Duration",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: subcategForTable,
  };

  function handleChangeSubcateg(e) {
    let name = e.target.name;
    let value = e.target.value;
    setSubcategValue(value);
    setSubcategObject({ ...subcategObject, [name]: value });
  }

  function fetch_category_id() {
    axios
      .get(`${API_URL}taskcategory/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var CategoryID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });
        setCategory([{ options: CategoryID }]);
      });
  }

  function fetch_flow_id() {
    axios
      .get(`${API_URL}taskFlow/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var FlowID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.item_name,
              value: el._id,
            };
          });
        setFlow([{ options: FlowID }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Category":
        setSelectedCategory(selected);
        setSubcategObject({
          ...subcategObject,
          category_id: selected.value,
        });
        // setErrors({
        //   ...errors,
        //   categoryID: "",
        // });
        break;

      default:
        break;
    }
  };

  const handleValidSubmit = () => {
    console.log("Put:", subcategObject);
    if (subcategIdTobeUpdated) {
      axios
        .put(`${API_URL}taskSubcategory/`, subcategObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Task Category updated successfully");
            setSubcategValue("");
            formRef.current.reset();
            handleTableData();
            setSubcategObject({});
            setValues([
              {
                flow_id: "",
                duration: "",
              },
            ]);
            setSelectedCategory(null);
            setSelectedFlow(null);
            setSubcategIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Task Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}taskSubcategory/`, subcategObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Task Category added succesfully");
            handleTableData();
            setSubcategValue("");
            formRef.current.reset();
            setSubcategObject({});
            setValues([
              {
                flow_id: "",
                duration: "",
              },
            ]);
            setSelectedCategory(null);
            setSelectedFlow(null);
          } else {
            toastr.error("Failed to add Task Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
      // }
    }
  };

  const reset = () => {
    handleTableData();
    setSelectedCategory(null);
    setSelectedFlow(null);
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const addMore = () => {
    setValues([
      ...values,
      {
        flow_id: "",
        duration: "",
      },
    ]);
  };

  function handleTableInputchange(e, index) {
    const { name, value } = e.target;

    const list = [...values];

    list[index][name] = value;

    setValues(list);

    // let valueArray = [];
    // list &&
    //   list.forEach((element) => {
    //     valueArray.push(element.freeBag);
    //   });
    setSubcategObject({ ...subcategObject, subcat_flow: list });
  }

  function handleChangeSelectItem(selected, index) {
    const list = [...values];

    list[index]["flowName"] = selected.label;
    list[index]["flowName_id"] = selected.value;
    list[index]["flow_id"] = selected.value;

    setSubcategObject({ ...subcategObject, subcat_flow: list });
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];
    let freebagArray = [];

    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
        freebagArray.push(element.freeBag);
      });

    setValues(list);
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}taskSubcategory` + "/" + subcategIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Task Category deleted successfully");
                    if (
                      subcategObject &&
                      subcategObject._id === subcategIdToBeDeleted
                    ) {
                      setSubcategValue("");
                      formRef.current.reset();
                      setSubcategObject({});
                      setSubcategIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Task Category"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Task Category" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <Row>
                            {/* <Col md="6">

                            <Label>Category</Label>
                            <Select
                              name="Category"
                              value={selectedCategory}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Category");
                              }}
                              options={category}
                              classNamePrefix="select2-selection"
                            />
                         
                        </Col> */}
                            <Col md="6">

                              <Label htmlFor="validationCustom01">
                                Category Name
                              </Label>
                              <AvField
                                style={customStyles}
                                name="subcat_name"
                                placeholder="Category Name"
                                type="text"
                                errorMessage="Enter Category Name"
                                validate={{ required: { value: true } }}
                                className="form-control"
                                id="validationCustom01"
                                value={subcategObject?.subcat_name}
                                onChange={handleChangeSubcateg}
                              />

                            </Col>
                            <Col md="12">
                              <div style={{ paddingTop: "25px" }}>
                                {subcategIdTobeUpdated ? (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginRight: "5%" }}
                                    disabled={addingSubcategory ? true : false}
                                  >
                                    {addingSubcategory ? "Updating" : "Update"}
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="submit"
                                    style={{ marginRight: "5%" }}
                                    disabled={addingSubcategory ? true : false}
                                  >
                                    {addingSubcategory ? "Adding" : "Submit"}
                                  </Button>
                                )}
                                <Button
                                  color="danger"
                                  type="reset"
                                  onClick={() => reset()}
                                >
                                  {"  "}
                                  Reset
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6">
                          <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            {/* Add Person start*/}
                            <Row>
                              <Col xl="12">
                                <Table
                                  style={{ textAlign: "center" }}
                                  id="waste_id"
                                  className="table table-bordered dataTable"
                                >
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontSize: "12.5px",
                                          color: "#495057",
                                          fontFamily:
                                            "IBM Plex Sans, sans-serif",
                                        }}
                                      >
                                        No.
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "90px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.5px",
                                          color: "#495057",
                                          fontFamily:
                                            "IBM Plex Sans, sans-serif",
                                        }}
                                      >
                                        Flow
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.5px",
                                          color: "#495057",
                                          fontFamily:
                                            "IBM Plex Sans, sans-serif",
                                        }}
                                      >
                                        Duration (in hr)
                                      </TableCell>

                                      {values.length !== 1 ? (
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontWeight: "500",
                                            fontSize: "12.5px",
                                            color: "#495057",
                                            fontFamily:
                                              "IBM Plex Sans, sans-serif",
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values &&
                                      values.map((item, index) => (
                                        <TableRow>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              textAlign: "center", fontWeight: "500",
                                              fontSize: "12.5px",
                                              color: "#495057",
                                              fontFamily:
                                                "IBM Plex Sans, sans-serif",
                                            }}
                                          >
                                            {index + 1}
                                          </TableCell>

                                          <TableCell>
                                            <Select
                                              onChange={(selected) =>
                                                handleChangeSelectItem(
                                                  selected,
                                                  index
                                                )
                                              }
                                              name="flowName"
                                              classNamePrefix="select2-selection"
                                              options={flow}
                                              value={{
                                                label: item.flowName,
                                                value: item.flowName_id,
                                              }}
                                              id={`validationCustom04${index}`}
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <AvField
                                              name="duration"
                                              value={item.duration}
                                              type="text"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              // errorMessage="Enter Designation"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>

                                          {values.length !== 1 && (
                                            <i
                                              className="uil uil-times"
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                paddingLeft: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                                fontWeight: "500",
                                                color: "#495057",
                                                fontSize: "12.5px",
                                                fontFamily:
                                                  "IBM Plex Sans, sans-serif",

                                              }}
                                              onClick={() =>
                                                handleRemove(index)
                                              }
                                            ></i>
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </Col>
                            </Row>
                            <span
                              onClick={() => addMore()}
                              style={{
                                width: "190px", cursor: "pointer", fontWeight: "500",
                                fontSize: "12.5px",
                                color: "#495057",
                                fontFamily:
                                  "IBM Plex Sans, sans-serif",
                              }}
                            >
                              {" "}
                              + Add New{" "}
                            </span>
                            <br />
                            {/*Add Person End*/}
                          </CardBody>
                        </Col>


                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <MDBDataTable
                      id="TaskSubcategTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default TaskSubcategory;