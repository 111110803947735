import React, { useState, useEffect, useRef, useDebugValue } from "react";
import "./KanbanTask.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import toastr from "toastr";
import accessToken from "../../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { timeSince, timeFromNumber } from "../../../../../helpers/globalFunctions";
import { MDBDataTable } from "mdbreact";
import ScrollBar from "react-perfect-scrollbar";
import { AvForm, AvField } from "availity-reactstrap-validation";
function KanbanTask({ projectDetails }) {
  const [columns, setColumns] = useState({});
  const [dateChnage, setdateChnage] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [checkBoxName, setCheckBoxName] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
  const bottomRef = useRef();
  const [userId, setUserId] = useState();
  const [messages, setMessages] = useState([]);
  const [chatboxData, setChatboxData] = useState({});
  const [msgObject, setMsgObject] = useState({});
  const [checkBox, setcheckBox] = useState([
    "64e056238a3a0d5dc3e48191",
    "64e0563a8a3a0d5dc3e48192",
    "64e056428a3a0d5dc3e48193",
    "64e056548a3a0d5dc3e48194",
    "64e0565c8a3a0d5dc3e48195",
    "64e056658a3a0d5dc3e48196",
  ]);
  const statusOrder = {
    "64e056238a3a0d5dc3e48191": 0,
    "64e0563a8a3a0d5dc3e48192": 1,
    "64e056428a3a0d5dc3e48193": 2,
    "64e056548a3a0d5dc3e48194": 3,
    "64e0565c8a3a0d5dc3e48195": 4,
    "64e056658a3a0d5dc3e48196": 5,
  };
  const [attachCount, setAttachCount] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    axios
      .get(`${API_URL}task-kanban/status`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setCheckBoxName(res.data.data);
      });
  }, []);
  const projectId = projectDetails._id;
  useEffect(() => {
    fetch_Lead(checkBox, dateChnage);
  }, [checkBox, dateChnage, dataLoaded]);

  function handleFollowupDate(e) {
    console.log("name", e.target.name);
    console.log("value", e.target.value);

    setdateChnage({ ...dateChnage, [e.target.name]: e.target.value });
  }

  function fetch_Lead(checkBox, { from_date = "", to_date = "" }) {
    const checkBoxQueryString = checkBox.join(",");
    let url = `${API_URL}task-kanban?from_date=${from_date || ""}&to_date=${to_date || ""}&status=${checkBoxQueryString}&project_id=${projectId}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const result = res.data.data;

        const newData = checkBox.reduce((el, item) => {
          const matchingStatus = result.find((statusId) => statusId._id === item);
          const statusName = matchingStatus ? matchingStatus.name : getCheckBoxName(item);

          el[statusOrder[item]] = {
            name: statusName,
            items: matchingStatus ? matchingStatus.items : [],
          };

          return el;
        }, []);

        setColumns(newData);
        setDataLoaded(true);
      });
  }

  function getCheckBoxName(statusId) {
    const foundStatus = checkBoxName?.find((status) => status._id === statusId);
    return foundStatus ? foundStatus.name : "Status Not Found";
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const statusOrder = {
      0: "64e056238a3a0d5dc3e48191",
      1: "64e0563a8a3a0d5dc3e48192",
      2: "64e056428a3a0d5dc3e48193",
      3: "64e056548a3a0d5dc3e48194",
      4: "64e0565c8a3a0d5dc3e48195",
      5: "64e056658a3a0d5dc3e48196",
    };

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      const cardId = removed._id;
      const destinationBoxId = statusOrder[destination.droppableId];

      console.log("Card ID:", cardId);
      console.log("Destination Box ID:", destinationBoxId);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      axios
        .post(
          `${API_URL}task-kanban/status-update`,
          {
            task_id: cardId,
            status: destinationBoxId,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          fetch_Lead(checkBox, dateChnage);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const cardId = removed._id;
      const destinationBoxId = statusOrder[destination.droppableId];
      console.log("Card ID:", cardId);
      console.log("Destination Box ID:", destinationBoxId);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      axios
        .post(
          `${API_URL}task-kanban/status-update`,
          {
            task_id: cardId,
            status: destinationBoxId,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          fetch_Lead(checkBox, dateChnage);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  function checkboxclick(e) {
    const { checked, value } = e.target;
    if (checked) {
      setcheckBox((prevCheckBox) => {
        if (!prevCheckBox.includes(value)) {
          return [...prevCheckBox, value];
        }
        return prevCheckBox;
      });
    } else {
      setcheckBox((prevCheckBox) => prevCheckBox.filter((id) => id !== value));
    }
  }
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  const chat_flow_count_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 90,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 90,
      },
      {
        label: "Assignee",
        field: "name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Plan",
        field: "assign_time1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Effort",
        field: "assign_effort_hr1",
        sort: "asc",
        width: 90,
      },
    ],
    rows: chatboxData.assignees,
  };
  const handleShow = (item, index) => {
    setMsgObject({
      ...msgObject,
      ["assign_id"]: item._id,
      ["flow_id"]: item.task_flow_id,
      ["task_id"]: item._id,
      ["project_id"]: item.project_id,
      ["subcategory_id"]: item.task_subcategory_id,
    });
    axios
      .get(`${API_URL}taskcomment/list?task_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var data = response.data.data;
        setMessages(response.data.data);
        if (typeof response.data.project_info.assignees !== "undefined") {
          response.data.project_info.assignees.map((item, index) => {
            item.id = index + 1;
            if (item.assign_time) item.assign_time1 = timeFromNumber(item.assign_time);
            else item.assign_time1 = "00:00";

            if (item.assign_effort_hr) item.assign_effort_hr1 = item.assign_effort_hr.substring(0, item.assign_effort_hr.lastIndexOf(":"));
            else item.assign_effort_hr1 = "00:00";
            if (item.assign_effort_h1r == "") item.assign_effort_hr1 = "00:00";
          });
        }
        setChatboxData(response.data.project_info);
        // setCanvasShow(true);
        scrollTop();
      });

    console.log(chatboxData, "chattt");
  };

  const uploadAttachment = (e) => {
    const fd = new FormData();
    for (var i = 0; i < e.target.files.length; i++) {
      fd.append("images", e.target.files[i]);
    }
    axios
      .post(`${API_URL}taskcomment/images/`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAttachCount(response.data.file.length);
          setMsgObject({
            ...msgObject,
            ["images"]: response.data.file,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleSendMessage = () => {
    axios
      .post(`${API_URL}taskcomment/`, msgObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var new_message = response.data.data;
        var messages_list = [...messages];
        messages_list.push(new_message);
        setMessages(messages_list);
        setMsgObject({
          ...msgObject,
          ["comment"]: "",
          ["images"]: [],
        });
        setAttachCount("");
        scrollTop();
      });
  };
  function scrollTop() {
    const curr = bottomRef.current;
    if (curr) {
      curr.scrollTop = 560 * 1000;
    }
  }
  const handleMessage = (e) => {
    setMsgObject({
      ...msgObject,
      ["comment"]: e.target.value,
    });
  };
  return (
    <React.Fragment>
      {dataLoaded ? (
        <div className="page-content" style={{ background: "white" }}>
          <div className="container-fluid" style={{ padding: "0px" }}>
            <div className="" style={{ display: "flex", alignItems: "center", marginTop: "-6rem" }}>
              {checkBoxName?.map((item, index) => {
                const isChecked = checkBox.includes(item?._id);
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input name={item?.name} onClick={checkboxclick} key={index} type="checkbox" checked={isChecked} value={item?._id} />{" "}
                    <p className="mx-3 mb-0">{item?.name}</p>
                  </div>
                );
              })}
              <div>
                <AvForm style={{ display: "flex" }}>
                  <div style={{ display: "flex" }} className="mx-2">
                    <label style={{ marginTop: ".5rem" }}>From</label>
                    <AvField
                      style={{ width: "115px", marginLeft: "10px" }}
                      name="from_date"
                      type="date"
                      onChange={(e) => {
                        handleFollowupDate(e);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }} className="mx-2">
                    <label style={{ marginTop: ".5rem" }}>To</label>
                    <AvField
                      style={{ width: "115px", marginLeft: "10px" }}
                      name="to_date"
                      type="date"
                      onChange={(e) => {
                        handleFollowupDate(e);
                      }}
                    />
                  </div>
                </AvForm>
              </div>
            </div>
            <div className="kanboard mt-3">
              <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                {Object.entries(columns)?.map(([columnId, column], index) => {
                  return (
                    <div key={columnId} className={`kanboard_child `}>
                      <div className="kanboard_header">
                        <div className="board_header_left">{column?.name}</div>
                        <div className="board_header_right">
                          <h6 className="card_icon kanboard_close">{column?.items?.length}</h6>
                          <h6 className="card_icon kanboard_view">
                            <i class="fa fa-angle-right"></i>
                          </h6>
                        </div>
                      </div>
                      <div className="kanboard_body">
                        <h6 className="board_name">{column?.name}</h6>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  width: 215,
                                  minHeight: 200,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                }}>
                                {column?.items?.map((item, index) => {
                                  console.log("cardmao", item);
                                  return (
                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...provided.draggableProps.style,
                                            }}
                                            className="kanboard_card"
                                            onClick={() => {
                                              console.log("Clicked item:", item);
                                              console.log("Clicked index:", index);
                                              handleShow(item, index);
                                              toggleModal();
                                            }}>
                                            <h5 className="mb-0">{item?.task_name}</h5>
                                            <p className="mb-0">{item?.status}</p>
                                            <div>
                                              {item.staffs.length > 0 ? (
                                                <div>
                                                  {item.staffs.some((staffImage) => staffImage.trim() !== "") ? (
                                                    item.staffs.map((staffImage, index) =>
                                                      staffImage.trim() !== "" ? (
                                                        <img
                                                          key={index}
                                                          src={`https://erpapi.srvinfotech.com/uploads/user_images/${staffImage}`}
                                                          alt="Staff"
                                                          style={{ borderRadius: "50%", marginRight: "5px", width: "30px", height: "30px" }}
                                                        />
                                                      ) : (
                                                        <img
                                                          key={index}
                                                          src="../images/avatar.avif"
                                                          alt="No Staffss"
                                                          style={{ borderRadius: "50%", marginRight: "5px", width: "30px", height: "30px" }}
                                                        />
                                                      )
                                                    )
                                                  ) : (
                                                    <img
                                                      src="../images/avatar.avif"
                                                      alt="No Staff"
                                                      style={{ borderRadius: "50%", marginRight: "5px", width: "30px", height: "30px" }}
                                                    />
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="flex_align">
                                              {item.start_date && <p className="mb-0">{moment(item.start_date).format("DD-MM-YYYY")}</p>}
                                              {item.end_date && <p className="mb-0">{moment(item.end_date).format("DD-MM-YYYY")}</p>}
                                              {/* <i style={{ cursor: "pointer" }} className="fa fa-eye icon_eye"></i> */}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  );
                })}
              </DragDropContext>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {/* Popup */}
      <Offcanvas show={isModalVisible} onHide={toggleModal} placement={"end"} style={{ width: "1200px" }}>
        <Row>
          <Col md="7">
            {/* <Offcanvas.Header closeButton style={{ padding:"5px"}}>
          <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header> */}
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  <table id="projectInfoTable" width={"100%"}>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Project Name</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.project_name}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Shortcode</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.shortcode}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Client</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.clients}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Type</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.types}</td>
                    </tr>

                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Technologies</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.TechForTbl}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Domain</td>
                      <td style={{ textAlign: "left" }}>
                        <a target="_blank" href={chatboxData?.domain} style={{ paddingLeft: "0%" }}>
                          : {chatboxData?.domain}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Quotation No</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.quotation_no}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Invoice No</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.invoice_no}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Kick Off</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.kickoff}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>End</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.End}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Project Status</td>
                      <td style={{ textAlign: "left" }}>
                        :
                        <p
                          className="badge rounded-pill "
                          style={{
                            fontSize: "initial",
                            marginBottom: "auto",
                          }}>
                          {chatboxData?.ProjectStatus}
                        </p>
                      </td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Billing Status</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.bstatus}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Manager</td>
                      <td style={{ textAlign: "left" }}>
                        : {chatboxData.project_manager?.firstName} {chatboxData.project_manager?.lastName}
                      </td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Team Leader</td>
                      <td style={{ textAlign: "left" }}>
                        : {chatboxData.project_leader?.firstName} {chatboxData.project_leader?.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Hours</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.hours}</td>
                      <td style={{ padding: "10px", fontWeight: "500" }}>Remark</td>
                      <td style={{ textAlign: "left" }}>: {chatboxData?.remarks}</td>
                    </tr>
                  </table>
                  <MDBDataTable
                    id="flowCountTableIds"
                    responsive
                    bordered
                    data={chat_flow_count_data}
                    searching={false}
                    disableRetreatAfterSorting={true}
                    paging={true}
                    info={false}
                    entriesOptions={[5, 10]}
                  />
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  <div className="chat-input-section">
                    <Row>
                      <div className="px-lg-2">
                        <div className="chat-conversation pt-2">
                          <ScrollBar style={{ height: "455px" }} className="list-unstyled mb-0" containerRef={(el) => (bottomRef.current = el)}>
                            {messages.map((message, index) => {
                              if (message.comment) {
                                return (
                                  <>
                                    <li key={"test_k" + index} className={message?.createdBy?._id?.toString() == userId?.toString() ? "right" : ""}>
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content">
                                            <h5 className="font-size-14 conversation-name">
                                              <Link to="#" className="text-dark">
                                                {message.createdBy.firstName}
                                              </Link>
                                              <span className="d-inline-block font-size-12 text-muted ms-2">
                                                {moment(message.createdAt).format("DD-MM-YYYY hh:mm a")} -{" "}
                                                {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                              </span>
                                            </h5>
                                            <p className="mb-0 remarks-comment">{message.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                      } else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                      } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                      } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={message?.createdBy?._id?.toString() == userId?.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">
                                                      {message.createdBy.firstName}
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                      } else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                      } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                      } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={message?.createdBy?._id?.toString() == userId?.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">
                                                      {message.createdBy.firstName}
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })}
                          </ScrollBar>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <Input
                            value={msgObject?.comment}
                            type="textarea"
                            id="comment"
                            name="comment"
                            className="form-control chat-input rounded"
                            onChange={(e) => handleMessage(e)}
                            rows="1"
                            placeholder="Enter Message..."
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div class="image-upload">
                          <label for="file-input">
                            {attachCount} <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                          <input id="file-input" type="file" multiple onChange={uploadAttachment} />
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button type="submit" color="primary" onClick={handleSendMessage} className="chat-send waves-effect waves-light">
                          <i className="mdi mdi-send float-end"></i>
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Offcanvas>
    </React.Fragment>
  );
}

export default KanbanTask;
