import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Leads.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";
import './Leads.scss';
//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const Leads = () => {
  const [leadsForObject, setLeadsForObject] = useState({});
  const [leadsForIdTobeUpdated, setLeadsForIdToBeUpdated] = useState(null);
  const [leadsForIdToBeDeleted, setLeadsForIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [leadsForForTable, setLeadsForForTable] = useState([]);
  const [leadsForValue, setleadsForValue] = useState("");

  const {
    districts,
    addingLeadsFor,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}leadsFor/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let leadsForData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateLeadsFor(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setLeadsForIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          leadsForData.push(item);
        });
        setLeadsForForTable(leadsForData);
      });
  }

  let preUpdateLeadsFor = (item) => {
    setleadsForValue(item?.name);

    setLeadsForIdToBeUpdated(item._id);
    setLeadsForObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Lead Source",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: leadsForForTable,
  };

  function handleChangeLeadsFor(e) {
    let name = e.target.name;
    let value = e.target.value;
    setleadsForValue(value);
    setLeadsForObject({ ...leadsForObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (leadsForIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}leadsFor`, leadsForObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Lead Source updated successfully");
            setleadsForValue("");
            formRef.current.reset();
            handleTableData();
            setLeadsForObject({});
            setLeadsForIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Lead Source");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}leadsFor`, leadsForObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Lead Source added succesfully");
            handleTableData();
            setleadsForValue("");
            formRef.current.reset();
            setLeadsForObject({});
          } else {
            toastr.error("Failed to add Lead Source");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}leadsFor` + "/" + leadsForIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Lead Source deleted successfully");
                    if (
                      leadsForObject &&
                      leadsForObject._id === leadsForIdToBeDeleted
                    ) {
                      setleadsForValue("");
                      formRef.current.reset();
                      setLeadsForObject({});
                      setLeadsForIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Lead Source");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Lead Source" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Lead Source</Label>
                            <AvField
                              //style={customStyles}
                              name="name"
                              placeholder="Lead Source"
                              type="text"
                              errorMessage="Enter Lead Source"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={leadsForValue}
                              onChange={handleChangeLeadsFor}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {leadsForIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingLeadsFor ? true : false}
                              >
                                {addingLeadsFor ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingLeadsFor ? true : false}
                              >
                                {addingLeadsFor ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                  <MDBDataTable
                      id="leadsForTableId"
                      responsive
                      
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Leads;
