import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Portfolio.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";


const Portfolio = () => {
  
  const [masterObject, setmasterObject] = useState({});
  const[portfolioToUpdate,setPortfolioToUpdate]=useState(null);
  const[portfolioIdToBeDeleted,setPortfolioIdToBeDeleted]=useState(null)
  const[confirmDeleteAlert,setConfirmDeleteAlert]=useState(null);
  const[tableData,setTableData]=useState([]);
  const addPortfolio =useSelector((state)=>state.addPortfolio);
  const [selectedClient,setSelectedClient]=useState(null);
  const [selectedIndustry,setSelectedIndustry]=useState(null);
  const [selectedType,setSelectedType]=useState(null);
  const [client,setClient]=useState([]);
  const [industry,setIndustry]=useState([]);
  const [type,setType]=useState([]);
  const[values,setValues]=useState([{portfolio_type_id:"",image:""}])
  const [uploadProgress, setUploadProgress] = useState();
  const [errors, setErrors] = useState({
    clientError: "",
    industryError:"",
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const formRef = useRef();
  
  
  
  useEffect(() => {
      fetch_client();
      fetch_industry();
      fetch_type();
      handleTableData();
    }, 
    []
  );

  function fetch_client(){
      axios
      .get(`${API_URL}client/options`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.name,
              value:el._id
          }})

      setClient([{options:data}])
      })
  }
  function fetch_industry(){
      axios
      .get(`${API_URL}client/category`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.client_category,
              value:el._id
          }})

      setIndustry([{options:data}])
      })
  }
  function fetch_type(){
      axios
      .get(`${API_URL}portfolio/option`,{
          headers:{
              "x-access-token":accessToken,
          }
      })
      .then((res)=>{
          var data= res.data.data &&
          res.data.data.map((el)=>{return{
              label:el.type_name,
              value:el._id
          }})

      setType([{options:data}])
      })
  }
  const handleSelectChange =(selected,name)=>{
    switch(name){
      case "client_id":
        setSelectedClient(selected);
        setmasterObject({
          ...masterObject,
          client_id:selected.value
        });
        setErrors({
          ...errors,
          clientError: "",
        });
      break;
      case "client_category_id":
        setSelectedIndustry(selected);
        let indusArray = [];
        selected &&
          selected.map((element) => {
            indusArray.push(element.value);
          });
       setmasterObject({
          ...masterObject,
          client_category_id:indusArray
        });
        setErrors({
          ...errors,
          industryError: "",
        });
      break;
      default:
        break;
    }
  }

  function handleTableData() {
    var url = `${API_URL}portfolioType/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];
        result.map((item,index)=>{
          item.action=(
            <div style={{display:"flex",justifyContent:"center"}}>
              <i className="uil-edit-alt"
                style={{
                  fontSize:"1.2em",
                  cursor:"pointer",
                  marginLeft:"0.5em",
                  marginRight:"0.5em"
                }}
                onClick={() => {
                  toTop();
                  preUpdatePortfolio(item);
                }}
            >
              </i>
              <i className="uil-trash-alt"
                style={{
                  fontSize:"1.2em",
                  cursor:"pointer",
                }}
                onClick={()=>{
                  setPortfolioIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
                >
              </i>
            </div>
          )
          item.id=index+1;
          if(item?.client_id){
            item.client=item.client_id.name
          }
          item.industry=(
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
              {item.client_category_id &&
                item.client_category_id.map((el) => {
                  let indus=el?.client_category || {}
                  return(
                    <li className="mb-2">
                        <span>
                          {indus}
                        </span>
                      </li>
                  )
                })
              }
              </ul>
            </div>
          )
          item.type = (
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
                {item.portfolio &&
                  item.portfolio.map((el) => {
                    let porttype= el?.portfolio_type_id || {}
                    return (
                      <li className="mb-2">
                        <span>
                          {porttype?.type_name}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
          item.image = (
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
                {item.portfolio &&
                  item.portfolio.map((el) => {
                    let logo =el?.image || ""
                    if(typeof logo!="undefined" && logo!=""){
                      return(
                          <li className="mb-2">
                          <span>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                          <img
                            src={`${API_URL}uploads/portfolioType_images/${logo}`}
                            style={{ height:"25px",  cursor: "pointer" }}
                            onClick={()=> window.open(`${API_URL}uploads/portfolioType_images/${logo}`, "_blank")}
                          />
                          </div>
                          </span>
                          </li>  
                      )
                    }
                    else{
                      return(
                          <li className="mb-2">
                          <span>
                            {""}
                          </span>
                        </li>
                      )
                    }
                  //  let porttype= el?.portfolio_type_id || {}
                    // return (
                    //   <li className="mb-2">
                    //     <span>
                    //       {porttype?.type_name}
                    //     </span>
                    //   </li>
                    // );
                  })}
              </ul>
            </div>
          );
          if (item?.updatedby) {
              let values = item?.updatedby || {};
                item.staff = (values.firstName || '') + (values.lastName || '');
          } else {
              let values = item?.addedby || {};
                item.staff = (values.firstName || '')+ (values.lastName || '');
          }
          resData.push(item)
          })
        setTableData(resData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Client",
        field: "client",
        sort: "asc",
        width: 150,
      },
      {
        label: "Industries",
        field: "industry",
        sort: "asc",
        width: 150,
      },

      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:tableData,
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdatePortfolio = (item) =>{
    setPortfolioToUpdate(item._id);
    let client=item?.client_id
    let clientopt={
      label:client?.name,
      value:client?._id
    };
    setSelectedClient(clientopt);
    let indusAr=[];
    item.client_category_id &&
    item.client_category_id.map((el)=>{
      let array={
        label:el.client_category,
        value:el._id,
      }
      indusAr.push(array)
    })
    setSelectedIndustry(indusAr);
    
    let tableData =[];
    item.portfolio.map((data,index)=>{
      let val = {};
      let port=data?.portfolio_type_id || {} ;
      val.portfolio_type_id=port?._id;
      val.type_name=port?.type_name;
      val.image = data?.image || "";
      tableData.push(val);
    })
   setValues(tableData);
   setmasterObject(item);

  };

 
  const deleteImage=(e,index) =>{
    const list=[...values];
    list[index]["image"]="";
    setValues(list);
    setmasterObject({
      ...masterObject,
      portfolio:list
    });
  }


  const uploadImage = (e,index) => {
    const fd = new FormData();
    fd.append("portfolioType_image", e.target.files[0]);
    axios
      .post(`${API_URL}portfolioType/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          const list=[...values];
           list[index]["image"]=response.data.file.filename;
          //  console.log( list[index]["image"]=response.data.file.filename)
          setValues(list);
          setmasterObject({
            ...masterObject,
            portfolio:list
          });
        } else {
          toastr.error(response.data.message);
        }
      });



}
function handleChangeSelectItem(selected,index){
  const list =[...values];
    list[index]["type_name"] = selected.label;
    list[index]["portfolio_type_id"] = selected.value;
    setValues(list);
    setmasterObject({ ...masterObject, portfolio: list});
    setSelectedType(selected.value)
  }

const handleValidSubmit =()=>{
  let flag = true;
  let validImg = true;
  values &&
    values.forEach((element) => {
      if (element.portfolio_type_id === "") {
        flag = false;
      } else if (element.image === "") {
        validImg = false;
      }
    });
    if(selectedClient === null || selectedIndustry === null|| flag === false|| validImg === false){
      let errorVal = errors;
      if(selectedClient === null){
      errorVal.clientError ="Please Select Client";
      setErrors({
        ...errorVal,
      });
      }
    if(selectedIndustry === null){
    errorVal.industryError ="Please Select Industry";
    setErrors({
      ...errorVal,
    });
   }
      if (flag === false) {
        toastr.error("Portfolio Type required");
        return;
      } else {
        toastr.error("Image required");
        return;
       }
    
  }

      else {
        if(portfolioToUpdate) {
        axios
          .put(
            `${API_URL}portfolioType` ,
            masterObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Portfolio updated successfully");
              handleTableData();
              formRef.current.reset();
              setSelectedClient(null);
              setSelectedIndustry(null);
              setValues([{ portfolio_type_id: "", image: "" }]);
              setmasterObject({});
              setPortfolioToUpdate(null)
              setErrors({
                ...errors,
                clientError: "",
                industryError:""
              });
            } else {
              toastr.error("Failed to update Portfolio");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } 
      else {
        axios
          .post(`${API_URL}portfolioType`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
          
            if (res.data.status === 200) {
              toastr.success("Portfolio created successfully");
              handleTableData();
              formRef.current.reset();
              setSelectedClient(null);
              setSelectedIndustry(null);
              setValues([{ portfolio_type_id: "", image: "" }]);
              setmasterObject({});
              setErrors({
                ...errors,
                clientError: "",
                industryError:""
              });
            } else {
              toastr.error("Failed to create Portfolio");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  }
const handleRemove =(index) =>{
  const list = [...values];
  list.splice(index, 1);
  let valueArray=[];
  list &&
  list.map((element) => {
    valueArray.push({portfolio_type_id:element.portfolio_type_id,image:element.image});
  });
  setmasterObject({
    ...masterObject,
    portfolio: valueArray,
  });
  setValues(list);
}
const addMore = () => {
  setValues([...values, { portfolio_type_id: "", image: "" }]);
};

  const reset=() =>{
    handleTableData();
    setSelectedClient(null);
    setSelectedIndustry(null);
    formRef.current.reset();
    setValues([{ portfolio_type_id: "", image: "" }]);
    setErrors({
      ...errors,
      clientError: "",
      industryError:""
    });
     setmasterObject({});
  }
  const customStyles = {
    lineHeight: "1.8",
  };
  return (
      <>
        <React.Fragment>
          {confirmDeleteAlert ? (
            <SweetAlert
              title=""
              showCancel
              confirmButtonText="Delete"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                axios
                  .delete(
                    `${API_URL}portfolioType` + "/" + portfolioIdToBeDeleted,
                    {
                      headers: {
                        "x-access-token": accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.data.status === 200) {
                      toastr.success("Portfolio deleted successfully");
                      if (
                        masterObject &&
                        masterObject._id === portfolioIdToBeDeleted
                      ) {
                        formRef.current.reset();
                        setmasterObject({});
                        setPortfolioToUpdate(null);
                        setSelectedClient(null);
                        setSelectedIndustry(null);
                        setValues([{ portfolio_type_id: "", image: "" }]);
                        setErrors({
                          ...errors,
                          clientError: "",
                          industryError:""
                        });
                      }
                      handleTableData();
                    } else {
                      toastr.error(
                        res.data.message,
                        "Failed to delete Portfolio"
                      );
                      return;
                    }
                  });
                setConfirmDeleteAlert(false);
              }}
              onCancel={() => setConfirmDeleteAlert(false)}
            >
              Are you sure you want to delete it?
            </SweetAlert>
          ) : null}
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Home" breadcrumbItem="Portfolio" />

              <Row>
                <Col className="col-12">
                  <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody>
                      <AvForm
                        ref={formRef}
                        className="needs-validation"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        <Row>
                          
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Client  
                              </Label>
                              <Select
                                name="client_id"
                                value={selectedClient}
                                options={client}
                                 classNamePrefix ="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "client_id");
                                }}
                              />
                                <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedClient === null ? errors.clientError : ""}
                              </p>
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                              Industries  
                              </Label>
                              <Select
                                isMulti
                                name="client_category_id"
                                value={selectedIndustry}
                                options={industry}
                                classNamePrefix ="select2-selection"
                                onChange={(selected) => {
                                  handleSelectChange(selected, "client_category_id");
                                }}
                              />
                                <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedIndustry === null ? errors.industryError : ""}
                              </p>
                            </div>
                          </Col>
                          {/* Table Fields */}
                          {/* <Row> */}
                          <Col className="col-12 ">
                            <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                              {/* Add Person start*/}
                              <Row>
                                <Col xl="12">
                                  <Table
                                    style={{ textAlign: "center"}}
                                    id="portfolio_id"
                                    className="table table-bordered dataTable"
                                  >
                                    <TableHead>
                                      <TableRow style={{ textAlign: "center" }}>
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          No.
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "90px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          Type
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: "100px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                        Image
                                        </TableCell>
                                        
                                        {values.length !== 1 ? (
                                          <TableCell
                                            style={{
                                              width: "0px",
                                              textAlign: "center",
                                              fontWeight: "500",
                                              paddingLeft: "0rem",
                                              paddingRight: "0rem",
                                              fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057",
                                            }}
                                          >
                                            Action
                                          </TableCell>
                                        ) : null}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {values &&
                                        values.map((item, index) => (
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              style={{ textAlign: "center", fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color:"#495057", fontWeight: "500" }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell>
                                              <Select
                                                onChange={(selected) =>
                                                  handleChangeSelectItem(
                                                    selected,
                                                    index
                                                  )
                                                }
                                                name="portfolio_type_id"
                                                classNamePrefix="select2-selection"
                                                options={type}
                                                value={{
                                                  label: item.type_name,
                                                  value: item.portfolio_type_id,
                                                }}
                                                id={`validationCustom04${index}`}
                                              />
                                            </TableCell>                                       
                                              <TableCell>
                                              {masterObject?.portfolio?.[index]?.image ?                                              (
                                              <div div className="img-wraps">
                                              <div>     
                                                {masterObject.portfolio[index].image &&
                                                masterObject.portfolio[index].image.startsWith("http") ? (
                                                  <img
                                                    alt=""
                                                    width="250"
                                                    height="150"
                                                    src={`${masterObject.portfolio[index].image}`}
                                                  />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    width="150"
                                                    height="150"
                                                    src={`${API_URL}uploads/portfolioType_images/${masterObject.portfolio[index]?.image}`}
                                                  />
                                                )}
                                               </div>
                                                <div>
                                                <button
                                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                 // onClick={deleteImage}
                                                 onClick={(e) =>
                                                  deleteImage(e,index)
                                                 }
                                                  style={{ width: "150px" }}
                                                >
                                                  Delete
                                                </button>
                                              </div>
                                              </div>
                                              ):
                                              (<AvField
                                                  name="portfolioType_image"
                                                  type="file"
                                                  className="form-control"
                                                  id="validationCustom03"
                                                  onChange={(e) =>
                                                   uploadImage(e,index)
                                                  }
                                                  rows="1"
                                                />)
                                              }
                                              </TableCell>

                                            {values.length !== 1 && (
                                              <i
                                              className="uil uil-times"
                                              style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    paddingLeft: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                              onClick={() => handleRemove(index)}
                                              ></i>
                                           )}
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Col>
                              </Row>
                              <span
                                onClick={() => addMore()}
                                style={{ width: "190px", cursor: "pointer", fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color:"#495057", fontWeight: "500"  }}
                              >
                                {" "}
                                + Add  {" "}
                              </span>
                              <br />
                              {/*Add Person End*/}
                            </CardBody>
                          </Col>
                          {/* </Row> */}
                          {/* Table Fields */}
                          <Col md="3">
                            <div className="mb-2" style={{ paddingTop: "25px" }}>
                              {portfolioToUpdate ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                   disabled={addPortfolio ? true : false} 
                                 > 
                                   {addPortfolio ? "Updating" : "Update"} 
                                </Button>
                              ) : ( 
                                  
                                <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                  disabled={addPortfolio ? true : false}
                                >
                                  {addPortfolio ? "Adding" : "Submit"}
                                </Button>
                              )} 
                              <Button
                                color="danger"
                                type="reset"
                                onClick={() =>
                                  reset()}
                              >
                                {"  "}
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody>
                      <MDBDataTable
                        id="PortfolioTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>

        {/* pay modal end */}
      </>
  );
};

  export default Portfolio;