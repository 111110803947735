import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap";

const SalesAnalyticsChart = () => {
  const [sortBy, setSortBy] = useState("Monthly");
  const [MonthlyData, setMonthlyData] = useState([]);
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  function getCurrentMonthDates() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const today = currentDate.getDate();

    const datesArray = [];
    for (let day = 1; day <= today; day++) {
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 9 ? `0${month + 1}` : month + 1;
      datesArray.push(`${year}-${formattedMonth}-${formattedDay}`);
    }

    return datesArray;
  }

  const options = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#5b73e8", "#dfe2e6", "#f1b44c", "#FF0000", "#00FF00", "#FFFF00", "#ADD8E6", "#7FFFD4"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },

    markers: {
      size: 6,
      strokeWidth: 2,
      hover: {
        size: 8,
      },
    },
    xaxis: {
      categories: labels,
      title: {
        text: getXAxisTitleText(sortBy),
      },
      tickAmount: Math.min(labels.length, 20),
      labels: {
        show: true,
        trim: false,
        style: {
          fontSize: "10px",
        },
      },
      axisTicks: {
        show: true,
        showAlways: true,
      },
    },
    yaxis: {
      title: {
        text: "Leads",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
  };

  function dailyData() {
    axios
      .get("https://erpapi.srvinfotech.com/newLeads/daily-report", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMonthlyData(res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error fetching daily data:", err);
      });
  }
  function weeklyData() {
    axios
      .get("https://erpapi.srvinfotech.com/newLeads/weekly-report", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMonthlyData(res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error fetching daily data:", err);
      });
  }

  function fetchMonthlyData() {
    axios
      .get("https://erpapi.srvinfotech.com/newLeads/monthly-report", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMonthlyData(res.data.data[0]);
      })
      .catch((err) => {
        console.log("Error fetching monthly data:", err);
      });
  }

  useEffect(() => {
    if (sortBy === "Daily") {
      dailyData();
    } else if (sortBy === "Weekly") {
      weeklyData();
    } else {
      fetchMonthlyData();
    }
  }, [sortBy]);

  function fetchChartData(apiEndpoint, setDataCallback) {
    axios
      .get(apiEndpoint, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const results = res.data.data[0].data;
        let newLabels;

        if (sortBy === "Weekly") {
          newLabels = results.map((item) => item.week);
        } else {
          newLabels = results.map((item) => item._id);
        }

        setLabels(newLabels);

        let newSeries = [];
        if (results.length > 0) {
          const metricsToExclude = ["_id", "total", "total_client"];
          if (sortBy === "Weekly") {
            metricsToExclude.push("week", "year", "startOfWeek", "monthName");
          }

          const firstResultItem = results[0];

          newSeries = Object.keys(firstResultItem).reduce((seriesArray, metric) => {
            if (!metricsToExclude.includes(metric)) {
              const seriesData = results.map((item) => item[metric]);
              seriesArray.push({
                name: metric,
                type: "line",
                data: seriesData,
              });
            }
            return seriesArray;
          }, []);
        }

        setSeries(newSeries);
        setDataCallback(results);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function fetchDailyData(currentMonthDates) {
    const apiEndpoint = "https://erpapi.srvinfotech.com/newLeads/daily-report";

    axios
      .get(apiEndpoint, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const dailyData = res.data.data[0];
        const dailyDataMap = new Map(dailyData.data.map((entry) => [entry._id, entry]));

        const seriesData = currentMonthDates.map(
          (date) =>
            dailyDataMap.get(date) || {
              _id: date,
              New: 0,
              "Requirement Collection": 0,
              Proposal: 0,
              Demo: 0,
              Client: 0,
              Invoiced: 0,
              Rejected: 0,
              Cancelled: 0,
            }
        );

        const newSeries = Object.keys(seriesData[0]).reduce((seriesArray, metric) => {
          if (metric !== "_id") {
            const seriesMetricData = seriesData.map((entry) => entry[metric]);
            seriesArray.push({
              name: metric,
              type: "line",
              data: seriesMetricData,
            });
          }
          return seriesArray;
        }, []);

        setLabels(currentMonthDates);
        setSeries(newSeries);
        setData(seriesData);
      })
      .catch((err) => {
        console.log("Error fetching daily data:", err);
      });
  }

  useEffect(() => {
    if (sortBy === "Daily") {
      const currentMonthDates = getCurrentMonthDates();
      setLabels(currentMonthDates);
      fetchDailyData(currentMonthDates);
    } else {
      let apiEndpoint;

      if (sortBy === "Monthly") {
        apiEndpoint = "https://erpapi.srvinfotech.com/newLeads/monthly-report";
      } else if (sortBy === "Quarterly") {
        apiEndpoint = "https://erpapi.srvinfotech.com/newLeads/quarterly-report";
      } else if (sortBy === "Weekly") {
        apiEndpoint = "https://erpapi.srvinfotech.com/newLeads/weekly-report";
      }

      fetchChartData(apiEndpoint, setData);
    }
  }, [sortBy]);

  function getXAxisTitleText(sortBy) {
    switch (sortBy) {
      case "Monthly":
        return "Months";
      case "Quarterly":
        return "Quarters";
      case "Weekly":
        return "Weeks";
      case "Daily":
        return "Days";
      default:
        return "X Axis Label";
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="float-end">
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret>
                <span className="fw-semibold">Sort By:</span>{" "}
                <span className="text-muted">
                  {sortBy}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => handleSortChange("Quarterly")}>Quarterly</DropdownItem>
                <DropdownItem onClick={() => handleSortChange("Monthly")}>Monthly</DropdownItem>
                <DropdownItem onClick={() => handleSortChange("Weekly")}>Weekly</DropdownItem>
                <DropdownItem onClick={() => handleSortChange("Daily")}>Daily</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle className="mb-4 h4">Sales Analytics</CardTitle>
          <div className="mt-1">
            <ul className="list-inline main-chart mb-0">
              <li className="list-inline-item chart-border-left me-0 border-0">
                <h3 className="text-primary">
                  <span>{MonthlyData?.total}</span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">Total</span>
                </h3>
              </li>{" "}
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">{MonthlyData?.total_client}</span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">Total Clients</span>
                </h3>
              </li>{" "}
              <li className="list-inline-item chart-border-left me-0">
                <h3>
                  <span data-plugin="counterup">{MonthlyData?.ratio !== undefined ? MonthlyData.ratio.toFixed(1) + "%" : ""}</span>
                  <span className="text-muted d-inline-block font-size-15 ms-3">Conversation Ratio</span>
                </h3>
              </li>
            </ul>
          </div>

          <div className="mt-3 ">
            <ReactApexChart options={options} series={series} height="339" className="apex-charts" />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalyticsChart;
