import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Progress,
  PopoverBody,
  UncontrolledPopover,
  Button,
  Input,
} from "reactstrap";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { Link } from "react-router-dom";
import moment from "moment";
import { AvForm } from "availity-reactstrap-validation";
import { Button as MaterialUIButton, TextField } from "@material-ui/core";
import io from 'socket.io-client';

function Todolist() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [userId, setUserId] = useState();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserId(obj._id);
    }
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(-1);
  const [toDO, settoDO] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [masterobje, setmasterobje] = useState({});
  const [confirmDeleteAlertTodo, setconfirmDeleteAlertTodo] = useState(false);
  const [todoIdToBeDeleted, settodoIdToBeDeleted] = useState(null);
  const [completedTodo, setcompletedTodo] = useState([]);
  useEffect(() => {
    fetch_all_to_do();
  }, []);

  function fetch_all_to_do() {
    axios
      .get(`${API_URL}todo/incomplete`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        console.log("result", result);
        settoDO(res.data);
      });
  }

  const handleInputChange = (event) => {
    const text = event.target.value;
    setInputValue(text);
    setmasterobje({ ...masterobje, description: text });

    if (selectedEmail) {
      // An email is already selected, don't make an API call.
      setOptions([]);
      return;
    }

    const hashtags = text.match(/#(\w+)/g); // Extract hashtags from the text.
    if (hashtags && hashtags.length > 0) {
      // Extract the last hashtag in the text.
      const lastHashtag = hashtags[hashtags.length - 1];
      const searchTerm = lastHashtag.substring(1);
      // console.log("search term", searchTerm);

      // Make an API call using Axios to get options based on the searchTerm.
      axios
        .get(`${API_URL}todo/userSearch?query=${searchTerm}`, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          setOptions(response.data);
          setSelectedEmailIndex(-1);
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      setOptions([]);
      setSelectedEmailIndex(-1);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    const emailToAdd = selectedOption.firstName + selectedOption.lastName;
    const objectIdToAdd = selectedOption._id;
    const updatedValue = inputValue.replace(/#(\w+)$/, `#${emailToAdd} `);
    // const updatedValue = inputValue.replace(/#(\w+)$/, '') + emailToAdd;
    // const updatedValue = inputValue.trim() === '' ? emailToAdd : `${inputValue} ${emailToAdd}`;
    setInputValue(updatedValue);
    setSelectedEmail(emailToAdd);
    setOptions([]);
    setSelectedEmailIndex(-1);
    setmasterobje({
      ...masterobje,
      description: updatedValue,
      addedFor: [objectIdToAdd],
    });
  };

  const handleKeyDown = (event) => {
    if (options.length === 0 && event.key === "Enter") {
      axios
        .post(`${API_URL}todo`, masterobje, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Task added successfully");
          fetch_all_to_do();
          setmasterobje({});
          setSelectedEmail("");
          setSelectedEmailIndex(-1);
          setInputValue("");
        })
        .catch((err) => {
          toastr.error("Faild to add task");
        });
    }

    if (event.key === "ArrowDown") {
      // Move selection down.
      setSelectedEmailIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
      event.preventDefault(); // Prevent page scrolling with down arrow.
    } else if (event.key === "ArrowUp") {
      // Move selection up.
      setSelectedEmailIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      event.preventDefault(); // Prevent page scrolling with up arrow.
    } else if (event.key === "Enter" && selectedEmailIndex >= 0) {
      // If an email is selected using arrow keys, choose it on Enter.
      handleOptionSelect(options[selectedEmailIndex]);
    }
  };

  const clearInput = () => {
    setInputValue("");
    setOptions([]);
    setSelectedEmail("");
    setSelectedEmailIndex(-1);
  };

  function deletetodoList(item) {
    setconfirmDeleteAlertTodo(true);
    settodoIdToBeDeleted(item._id);
  }

  // function handleStatusChange(id){
  //   axios.put(`${API_URL}todo/${id}`,{
  //     headers:{
  //       "x-access-token":accessToken
  //     }
  //   }).then((res)=>{
  //     console.log("resupdate",res);
  //     fetch_all_to_do()
  //   })
  // }

  function handleStatusChange(id) {
    axios
      .put(
        `${API_URL}todo/${id}`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        fetch_all_to_do();
        fetch_all_complete();
      });
  }

  function fetch_all_complete() {
    axios
      .get(`${API_URL}todo/completed`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res.data);
        setcompletedTodo(res.data);
      });
  }

  useEffect(() => {
    fetch_all_complete();
  }, []);


  useEffect(() => {

    const socket = io(`${API_URL}?token=${accessToken}`); // Replace with your server URL
   
    socket.on('new-todo', (number) => {
      
      fetch_all_complete()
      fetch_all_to_do()

    });

    socket.on('connect_error', (err) => {
      console.log("socket error");
    })
    return () => {
      socket.disconnect();
    };
  }, []);


  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader
              style={{
                padding: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h6>
                {" "}
                <i className="fa fa-history fa-w blue mx-1"></i>To-do list
              </h6>
              <Link to="/view-all-to-do">
                <h6 className="view-all">View All</h6>
              </Link>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  id="standard-basic"
                  autoComplete="off"
                  placeholder="Add tasks"
                  style={{ width: "360px" }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />

                {inputValue && (
                  <CloseOutlinedIcon
                    className="to-do-text-close"
                    onClick={clearInput}
                  />
                )}
              </div>

              {options.length > 0 && (
                <div className="fetch-names-div">
                  {options.map((item, index) => (
                    <p
                      className={`select-email-p ${
                        selectedEmailIndex === index ? "active-email" : ""
                      }`}
                      onClick={() => handleOptionSelect(item)}
                      key={item._id}
                    >
                      {item.email_2}
                    </p>
                  ))}
                </div>
              )}

              <ul className="to-do-ul mt-3">
                {toDO &&
                  toDO.map((item) => (
                    <li
                      className="mt-2"
                      key={item}
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        fontSize: "1rem",
                        color: "#333",
                      }}
                    >
                      <input
                        onClick={() => {
                          handleStatusChange(item._id);
                        }}
                        type="checkbox"
                        disabled={
                          userId == item.addedBy && item.addedFor.length > 0
                            ? true
                            : false
                        }
                        checked={item?.completed}
                      />
                      <span
                        style={{
                          color:
                            userId === item.addedBy && item.addedFor.length > 0
                              ? "#dd6f6f"
                              : "",
                        }}
                        className={`mx-2 ${
                          userId === item.addedBy ? "" : "span-assin"
                        } `}
                      >
                        {userId === item.addedBy ? (
                          item.description
                        ) : (
                          <>
                            {item.description} - {`by ${item?.addedByName}`}
                          </>
                        )}
                      </span>
                      {userId == item.addedBy &&
                        item.completed == true &&
                        item.addedFor.length > 0 && (
                          <input
                            type="checkbox"
                            checked={item?.verified}
                            onClick={() => {
                              handleStatusChange(item._id);
                            }}
                          />
                        )}
                    </li>
                  ))}
              </ul>
              <hr className="mt-3" />
              {completedTodo &&
                completedTodo.map((item) => (
                  <li
                    className="mt-2"
                    key={item}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      fontSize: "1rem",
                      color: "#333",
                    }}
                  >
                    <input
                      onClick={() => {
                        handleStatusChange(item._id);
                      }}
                      type="checkbox"
                      disabled={true}
                      checked={item?.completed}
                    />
                    <span className="mx-2" style={{ color: "#b39f9f" }}>
                      {userId === item.addedBy ? (
                        item.description
                      ) : (
                        <>
                          {item.description} - {`by ${item?.addedByName}`}
                        </>
                      )}
                    </span>
                    {userId == item.addedBy &&
                      item.completed == true &&
                      item.addedFor.length > 0 && (
                        <input
                          type="checkbox"
                          checked={item?.verified}
                          onClick={() => {
                            handleStatusChange(item._id);
                          }}
                          disabled={true}
                        />
                      )}
                  </li>
                ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Todolist;
