import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Progress
} from "reactstrap";

import KanbanSupportTicket from "./KanbanSupportTicket/KanbanSupportTicket";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper, Box, Divider } from "@mui/material";
import moment from "moment";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./SupportTicket.scss";
import { timeFromNumber, timeSince } from "../../helpers/globalFunctions";
import $ from "jquery";
const SupportTicket = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const formRef1 = useRef();
  const [masterObject, setmasterObject] = useState({ date_and_time: moment().format("YYYY-MM-DD HH:mm:ss") });
  const [selectedProject, setSelectedProject] = useState(null)
  const [errors, setErrors] = useState({
    projectError: "",
  });
  const [supportIdTobeUpdated, setsupportIdTobeUpdated] = useState(null);
  const [selectedBill, setSelectedBill] = useState({ label: "Unpaid", value: 0 })
  const [selectedMode, setSelectedMode] = useState("")
  const [selectedTeam, setSelectedTeam] = useState("")
  const [displayreMob, setdisplayreMob] = useState(false);
  const [receMobileOp, setreceMobileOp] = useState([]);
  const [searchreceMob, setsearchreceMob] = useState("");
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [supportIdToBeDeleted, setsupportIdToBeDeleted] = useState(null)
  const [tableData, setTableData] = useState([]);
  const [showPopup, setShowPopup] = useState(false)
  const [supportDetails, setsupportDetails] = useState({});
  const [project, setProject] = useState([])
  const [Mode, setMode] = useState([])
  const [team, setTeam] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const handleValidSubmit = () => {
    if (masterObject.isquick) {
      masterObject.tasks = taskRows;
    }
    else {
      masterObject.tasks = [];
    }
    if (selectedProject === null) {
      let errorVal = errors;
      errorVal.projectError = "Please Select Project"
      setErrors({
        ...errorVal,
      });
    } else {
      if (supportIdTobeUpdated) {
        axios
          .put(
            `${API_URL}support_ticket/`,
            masterObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Support Ticket Updated Successfully");
              reset();
              setsupportIdTobeUpdated(null);
              handleTableData();
              setIsChecked(false);
              setTaskRows([{
                subcategory: null,
                task_name: "",
                task_tot_time: 0,
                task_start_date: "",
                task_end_date: "",
                expand: "hidden",
                flows: []
              }])
            } else {
              toastr.error("Failed to Update Support Ticket");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}support_ticket/add_ticket`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Support Ticket Added Succesfully");
              reset()
              handleTableData();
              setIsChecked(false);
              setTaskRows([{
                subcategory: null,
                task_name: "",
                task_tot_time: 0,
                task_start_date: "",
                task_end_date: "",
                expand: "hidden",
                flows: []
              }])
            } else {
              toastr.error("Failed to Add Support Ticket");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const reset = () => {
    formRef.current.reset();
    setSelectedBill(null);
    setSelectedProject(null);
    setSelectedMode(null);
    setSelectedTeam(null);
    setsearchreceMob(null)
    setmasterObject({});
    setmasterObject({ date_and_time: moment().format("YYYY-MM-DD HH:mm:ss"), support_issue: "" });
    setsupportIdTobeUpdated(null);
    setsupportIdToBeDeleted(null);
    handleTableData();
  }

  const customStyles = {
    lineHeight: "1.8",
  };
  function handleTableData() {
    var url = `${API_URL}support_ticket/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];

        result.map((item, index) => {

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-comment-medical"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpen(item._id);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTicket(item);
                }}
              >
              </i>

              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setsupportIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              >
              </i>
              {/* {(item?.status == 4)  ? null:(

          <i
          className="fa fa-info-circle"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.2em",
            marginRight: "0.5em",
          }}
          onClick={() => {
            // handleClickOpen(item);
          }}
          ></i>

) }  */}

            </div>

          )
          item.id = index + 1;
          if (item?.date_and_time) {
            item.day = (moment(item.date_and_time).format("DD-MM-YYYY"));
            item.time = (moment(item.date_and_time).format("hh:mm a"));
          }
          item.client_name = (<a href="/client" target={"_blank"}>{item.client_name}</a>)
          item.project_name = (<a href={"/project/" + item.project_unq_id} target={"_blank"}>{item.project_name}</a>)
          // if(item?.client_id){
          //   item.client_name=item.client_id?.name
          // }
          // if(item?.project_id){
          //   item.project_name=item.project_id?.project_name
          // }
          // if(item?.mode_id){
          //   item.mode_name=item.mode_id?.name
          // }
          // if(item?.team_id){
          //   item.team_name=item.team_id?.name
          // }

          // if(item?.status == 0){item.support_status = "New"}
          //  if(item?.status == 2){item.support_status = "Assigned"}
          //  if(item?.status == 3){item.support_status = "Completed"}
          // if(item?.status == 4){item.support_status = "Informed"}
          if (item.status == 0) {
            var status_name = "New";
            var color = "#527dff";
          }
          else if (item.status == 2) {
            var status_name = "Pending";
            var color = "#f19696";
          }
          else if (item.status == 3) {
            var status_name = "Completed";
            var color = "#34c38f";
          }
          else if (item.status == 4) {
            var status_name = "Hold";
            var color = "#e69900";
          }
          else if (item.status == 5) {
            var status_name = "Solved";
            var color = "#00ccff";
          }
          else if (item.status == 6) {
            var status_name = "Closed";
            var color = "#c70a0a";
          }
          else if (item.status == 7) {
            var status_name = "No Issue";
            var color = "#ab7e2b";
          }
          else if (item.status == 8) {
            var status_name = "Informed";
            var color = "#8695e7";
          }

          item.support_status = (<span className="badge" style={{ background: color, fontSize: "12px" }}>{status_name}</span>)

          if (item?.tasks == 0) {
            item.task_status = "No Task";
            //item.support_status = "New";
          }
          else if (item?.tot_completed == item?.tot_assign) {
            item.task_status = item?.tot_completed + "/" + item?.tot_assign + " Completed";
            //item.support_status = "Completed";
          }
          else if (item?.tot_unassigned > 0) {
            item.task_status = item?.tot_unassigned + "/" + item?.tot_assign + " Unassigned";
            //item.support_status = "Pending";
          }
          else if (item?.tot_unassigned == 0) {
            var pending = item?.tot_assign - item?.tot_completed;
            item.task_status = pending + "/" + item?.tot_assign + " Pending";
            //item.support_status = "Pending";
          }
          var start_date = new Date(item.date_and_time);
          if (typeof item.last_followup !== "undefined" && item.status == 6) {
            var end_date = new Date(item?.last_followup.date + " " + item?.last_followup.time);
          }
          else {
            var end_date = new Date();
          }
          item.tat = (<span className="badge bg-dark" style={{ fontSize: "12px" }}>{timeSince(start_date, end_date)}</span>)



          resData.push(item)
        })
        setTableData(resData);

      });
  }
  const handleClickOpen = (id) => {
    setFollowupObject({
      support_id: id
    });
    axios
      .get(`${API_URL}support_ticket/detailed-view?support_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let item = res.data.data;
        var all_followup = res.data.followup;
        if (item?.date_and_time) {
          item.day = (moment(item.date_and_time).format("DD-MM-YYYY"));
          item.time = (moment(item.date_and_time).format("hh:mm a"));
        }

        if (item?.bill_status == 0) { item.bill_status1 = "Unpaid"; setSelectedBill({ label: "Unpaid", value: 0 }) }
        if (item?.bill_status == 1) { item.bill_status1 = "Paid"; setSelectedBill({ label: "Paid", value: 1 }) }
        item.edited = item?.updatedby_name;
        item.add = item?.addedby_name;

        let upTime = item?.up_time || {};
        let upDate = item?.up_date || {};
        item.update = (moment(upDate).format("DD-MM-YYYY")) + " " + (moment(upDate, "HHmmss").format("hh:mm a"));

        let Time = item.time || {};
        let Dates = item.date || {};
        item.create = (moment(Dates).format("DD-MM-YYYY")) + " " + (moment(Time, "HHmmss").format("hh:mm a"));

        if (item.status == 0) {
          var status_name = "New";
          var color = "#527dff";
        }
        else if (item.status == 2) {
          var status_name = "Pending";
          var color = "#f19696";
        }
        else if (item.status == 3) {
          var status_name = "Completed";
          var color = "#34c38f";
        }
        else if (item.status == 4) {
          var status_name = "Hold";
          var color = "#e69900";
        }
        else if (item.status == 5) {
          var status_name = "Solved";
          var color = "#00ccff";
        }
        else if (item.status == 6) {
          var status_name = "Closed";
          var color = "#c70a0a";
        }
        else if (item.status == 7) {
          var status_name = "No Issue";
          var color = "#ab7e2b";
        }
        else if (item.status == 8) {
          var status_name = "Informed";
          var color = "#8695e7";
        }

        item.support_status = (<span className="badge" style={{ background: color, fontSize: "12px", padding: "3px" }}>{status_name}</span>)
        if (item?.tasks == 0) {
          item.task_status = "No Task";
          //item.support_status = "New";
        }
        else if (item?.tot_completed == item?.tot_assign) {
          item.task_status = item?.tot_completed + "/" + item?.tot_assign + " Completed";
          // item.support_status = "Completed";
        }
        else if (item?.tot_unassigned > 0) {
          item.task_status = item?.tot_unassigned + "/" + item?.tot_assign + " Unassigned";
          //item.support_status = "Pending";
        }
        else if (item?.tot_unassigned == 0) {
          var pending = item?.tot_assign - item?.tot_completed;
          item.task_status = pending + "/" + item?.tot_assign + " Pending";
          //item.support_status = "Pending";
        }

        var start_date = new Date(item.date_and_time);
        if (typeof item.last_followup !== "undefined" && item.status == 6) {
          var end_date = new Date(item?.last_followup.date + " " + item?.last_followup.time);
        }
        else {
          var end_date = new Date();
        }
        item.tat = (<span className="badge bg-dark" style={{ fontSize: "12px", padding: "3px" }}>{timeSince(start_date, end_date)}</span>)
        setShowPopup(true);
        setsupportDetails(item);
        var followups = [];
        all_followup.sort(function (a, b) {
          var x = a._id;
          var y = b._id;
          // factor -1 because you want them sorted DESC
          return -1 * ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        all_followup.map((el, index) => {
          if (el.followup_status == 0) {
            var status_name = "New";
            var color = "#527dff";
          }
          else if (el.followup_status == 2) {
            var status_name = "Pending";
            var color = "#f19696";
          }
          else if (el.followup_status == 3) {
            var status_name = "Completed";
            var color = "#34c38f";
          }
          else if (el.followup_status == 4) {
            var status_name = "Hold";
            var color = "#e69900";
          }
          else if (el.followup_status == 5) {
            var status_name = "Solved";
            var color = "#00ccff";
          }
          else if (el.followup_status == 6) {
            var status_name = "Closed";
            var color = "#c70a0a";
          }
          else if (el.followup_status == 7) {
            var status_name = "No Issue";
            var color = "#ab7e2b";
          }
          else if (item.status == 8) {
            var status_name = "Informed";
            var color = "#8695e7";
          }

          followups.push({
            id: index + 1,
            date: moment(el.date).format("DD-MM-YYYY"),
            time: moment(el.time, "HH:mm:ss").format("hh:mm a"),
            username: el?.addedby?.firstName,
            status: (
              <span className="badge" style={{ background: color, fontSize: "12px" }}>{status_name}</span>
            ),
            comment: el?.comments
          })
        });
        setFollowupTableData(followups);


      });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  let preUpdateTicket = (item) => {
    setsupportIdTobeUpdated(item._id)

    axios
      .get(`${API_URL}support_ticket/view?support_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let client = result?.client_id
        let clien = {
          label: client?.name,
          value: client?._id
        };
        setsearchreceMob(result?.client_id?.name);

        let project = result?.project_id
        let projec = {
          label: project?.project_name,
          value: project?._id
        };
        setSelectedProject(projec);

        let mode = result?.mode_id
        let mod = {
          label: mode?.name,
          value: mode?._id
        };
        setSelectedMode(mod);

        let team = result?.team_id
        let tea = {
          label: team?.name,
          value: team?._id
        };
        setSelectedTeam(tea);

        // let bill=result?.bill_status;
        // let bil={
        //   value:bill
        // };

        let bil = {}
        if (result?.bill_status === 0) {
          bil = {
            label: "Unpaid",
            value: 0,
          };
        }
        else if (result?.bill_status === 1) {
          bil = {
            label: "Paid",
            value: 1,
          };
        }
        else if (!result.bill_status) {
          bil = null
        }
        setSelectedBill(bil);
        setmasterObject(result);
      });

  };
  const handleChangeSupport = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({
      ...masterObject,
      [name]: value
    });
  }
  const handleChangeClient = (e) => {
    let value = e.target.value;
    axios
      .get(`${API_URL}support_ticket/get_client?clientName=${value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        let recmb = [];
        var data = res.data.data
        console.log(data)
        data.map((rmb) => {
          let rcmob = {
            label: rmb?.name,
            value: rmb?._id
          }
          recmb.push(rcmob);
        })
        setreceMobileOp(recmb)
      })

  }
  const handleSuggestion = (arg, val) => {
    setsearchreceMob(arg)
    setdisplayreMob(false);
    setmasterObject({
      ...masterObject,
      ["client_id"]: val
    })
    axios
      .get(`${API_URL}project/options?client_id=${val}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.project_name,
              value: el._id
            }
          })

        setProject([{ options: data }])
      })
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "project_id":
        setSelectedProject(selected);
        setmasterObject({
          ...masterObject,
          project_id: selected.value
        });

        break;

      case "mode_id":
        setSelectedMode(selected);
        setmasterObject({
          ...masterObject,
          mode_id: selected.value
        });

        break;

      case "team_id":
        setSelectedTeam(selected);
        setmasterObject({
          ...masterObject,
          team_id: selected.value
        });

        break;

      case "bill_status":
        setSelectedBill(selected);
        setmasterObject({
          ...masterObject,
          bill_status: selected.value
        });
        break;

      default:
        break;
    }

  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "day",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact",
        field: "support_contact_person",
        sort: "asc",
        width: 150,
      },
      {
        label: "Client",
        field: "client_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Project",
        field: "project_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mode",
        field: "mode_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "support_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "TAT",
        field: "tat",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  };
  function fetch_Mode() {

    axios
      .get(`${API_URL}support_ticket_modes/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setMode([{ options: data }])
      })
  }
  function fetch_team() {

    axios
      .get(`${API_URL}project_dashboard/team_options`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setTeam([{ options: data }])
      })
  }
  const handleOnChangeCheckbox = () => {
    setmasterObject({
      ...masterObject,
      isquick: !isChecked
    });
    setIsChecked(!isChecked);
  }
  //Start Task Section
  const [taskRows, setTaskRows] = useState([{
    subcategory: null,
    task_name: "",
    task_tot_time: 0,
    task_start_date: "",
    task_end_date: "",
    expand: "hidden",
    flows: []
  }]);
  const [taskCategoryOptions, setTaskCategoryOptions] = useState([]);
  const [taskAssigneeOptions, setTaskAssigneeOptions] = useState([]);
  const [followupObject, setFollowupObject] = useState({});
  const [followupstatusError, setfollowupstatusError] = useState("");
  const [followupTableData, setFollowupTableData] = useState([]);

  const addNewTaskRow = () => {
    setTaskRows([...taskRows, {
      subcategory: null,
      task_name: "",
      task_tot_time: 0,
      task_start_date: "",
      task_end_date: "",
      expand: "hidden",
      flows: []
    }])
  }
  const showTask = (index) => {
    var tasks = [...taskRows];
    tasks[index].expand = "show";
    setTaskRows(tasks);
  }
  const hideTask = (index) => {
    var tasks = [...taskRows];
    tasks[index].expand = "hidden";
    setTaskRows(tasks);
  }
  const handleAddTask = (selected, index, item, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    if (type == "task_name") {
      var task_name = selected.target.value;
      tasks[index].task_name = task_name;
    }
    else {
      var category_id = selected.value;
      tasks[index].task_subcategory_id = category_id;
      tasks[index].subcategory = selected;
      tasks[index].flows = [];
      fetchTaskFlows(category_id, index)
    }
    setTaskRows(tasks);


  }
  const handleAssignTask = (selected, index, index1, item, subItem, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    if (type == "staff") {
      var value = selected.value;
      tasks[index].flows[index1].staff_id = value;
      tasks[index].flows[index1].assignee = selected;
    }
    else if (type == "time") {
      var value = selected.target.value;
      if (value != "") {

        var old_time = tasks[index].flows[index1].assign_time;
        if (typeof old_time === "undefined" || old_time == "") {
          old_time = 0;
        }
        var diff = parseFloat(value) - old_time;
        tasks[index].flows[index1].assign_time = value;
        tasks[index].task_tot_time = tasks[index].task_tot_time + parseFloat(diff);
      }
      else {
        var old_time = tasks[index].flows[index1].assign_time;
        tasks[index].task_tot_time = tasks[index].task_tot_time - parseFloat(old_time);
        tasks[index].flows[index1].assign_time = value;
      }

    }
    else if (type == "start") {
      var value = selected.target.value;
      var end_date = tasks[index].flows[index1].assign_enddate;
      if (value > end_date) {
        tasks[index].flows[index1].assign_enddate = value;
      }
      tasks[index].flows[index1].assign_startdate = value;
      if (tasks[index].task_start_date > value) {
        tasks[index].task_start_date = value;
      }
      else {
        var start = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_startdate)
            start.push(item.assign_startdate);
        });
        start.sort();
        tasks[index].task_start_date = start[0];
      }
    }
    else if (type == "end") {
      var value = selected.target.value;
      tasks[index].flows[index1].assign_enddate = value;
      if (tasks[index].task_end_date < value) {
        tasks[index].task_end_date = value;
      }
      else {
        var end = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_enddate)
            end.push(item.assign_enddate);
        });
        end.sort();
        tasks[index].task_end_date = end[end.length - 1];
      }
    }
    else if (type == "status") {
      var value = selected.value;
      tasks[index].flows[index1].assign_current_status = value;
      tasks[index].flows[index1].currentstatus = selected;
    }

    setTaskRows(tasks);
  }
  const fetchTaskFlows = (category_id, index) => {
    axios
      .get(`${API_URL}support_ticket/flow_list?category_id=` + category_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tasks = [...taskRows];
        res.data.data.map((item, index1) => {
          // item.assign_time = item.assign_time ? item.assign_time : item.duration;
          item.assign_time = item.assign_time ? item.assign_time : 0;
          item.assign_time = parseFloat(item.assign_time);
          var assign_time = item.assign_time ? item.assign_time : 0;
          tasks[index].task_tot_time = tasks[index].task_tot_time + assign_time;
          var status_options = [];
          item.flow_status.map((statusItem) => {
            status_options.push({ label: statusItem.type_name, value: statusItem._id });
          })
          item.statusOption = status_options;
        });
        tasks[index].flows = res.data.data;

        setTaskRows(tasks);
        console.log(res.data.data)
      });
  }
  const fetchTaskCategory = () => {
    axios
      .get(`${API_URL}other-tasks/category/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
              };
            });
        }
        else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
              };
            });
        }

        setTaskCategoryOptions([{ options: SearchType_data }]);
      });
  }
  const fetchTaskAssignee = () => {
    axios
      .get(`${API_URL}other-tasks/staff/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
              };
            });
        }
        else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
              };
            });
        }
        setTaskAssigneeOptions([{ options: SearchType_data }]);
      });
  }
  const handleFollowupSubmit = () => {
    if (typeof followupObject.status !== "undefined") {
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    }
    else {
      $("#followupstatus").attr(
        "class",
        "select2-selection border border-danger"
      );
      setfollowupstatusError("Select Status");
      return;
    }
    axios
      .post(`${API_URL}support_ticket/add-followup`, followupObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        formRef1.current.reset();
        setFollowupObject({
          ...followupObject,
          status: null,
          status_id: null,
          comment: ""
        })
        handleClickOpen(followupObject.support_id);
        toastr.success("Submitted Successfully");
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });;

  }
  const handleFollowupData = (e, type) => {
    if (type == "followup_status") {
      setFollowupObject({
        ...followupObject,
        status_id: e.value,
        status: e
      });
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    }
    else if (type == "comment") {
      setFollowupObject({
        ...followupObject,
        comment: e.target.value,
      });
    }
  }
  const followreset = () => {
    formRef1.current.reset();
    setFollowupObject({
      ...followupObject,
      status_id: null,
      status: null,
      comment: ""
    })
  }
  const followup_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comments",
        field: "comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "username",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followupTableData
  }
  const handleSelectBill = (selected) => {
    setSelectedBill(selected);
    axios
      .post(`${API_URL}support_ticket/update-bill-status`, { status: selected.value, support_id: followupObject.support_id }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        handleClickOpen(followupObject.support_id);
        setsupportDetails({
          ...supportDetails,
          bill_status: selected.value
        })
        toastr.success("Submitted Successfully");
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });;
  }
  useEffect(() => {
    fetch_Mode();
    fetch_team();
    handleTableData();
    fetchTaskAssignee();
    fetchTaskCategory();
  },
    []
  );
  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}support_ticket` + "/" + supportIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                console.log("res: ", res);
                if (res.data.status === 200) {
                  toastr.success("Support ticket deleted successfully");
                  reset()
                } else {
                  toastr.error(
                    res.data.message,
                    "Failed to delete Support ticket"
                  );
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}



      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Support Ticket" />
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-xl-12" style={{ marginTop: "-17px" }}>
                  <Box sx={{ width: "100%" }}>
                    <TabContext value={tab3}>
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                      >
                        <TabList style={{ padding: "0px 0px 0px 0px" }}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          onChange={handleChangeTab}
                          aria-label="lab API tabs example">
                          <Tab label="Manage Support Ticket" value="1" size="small"
                            style={{
                              paddingTop: "26px",
                              marginLeft: "18px",
                              textTransform: "capitalize",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }} />
                          <Tab label="Board Design" value="2" size="small"
                            style={{
                              paddingTop: "26px",
                              marginLeft: "18px",
                              textTransform: "capitalize",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }} />

                        </TabList>
                      </Box>
                      <TabPanel value="1" style={{ padding: "14px 0px 0px 0px" }}>
                        <Row>
                          <Col md="12">
                            <Card>
                              <CardBody>
                                <AvForm
                                  ref={formRef}
                                  className="needs-validation"
                                  onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v);
                                  }}
                                >
                                  <Row>
                                    <Col md="3">
                                      <Label htmlFor="validationCustom05">Date & Time</Label>
                                      <input
                                        className="form-control"
                                        type="datetime-local"
                                        value={masterObject?.date_and_time}
                                        id="date_and_time"
                                        name="date_and_time"
                                        onChange={handleChangeSupport}
                                      />
                                    </Col>
                                    <Col md="2">
                                      <Label htmlFor="validationCustom01">
                                        Client
                                      </Label>
                                      <AvField
                                        style={customStyles}
                                        name="client_id"
                                        placeholder="Client"
                                        type="text"
                                        errorMessage="Enter Client"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom01"
                                        value={searchreceMob}
                                        onChange={handleChangeClient}
                                        autocomplete="off"
                                        onClick={() => setdisplayreMob(!displayreMob)}
                                      />

                                      <div className='dropdownAuto'>
                                        {displayreMob && receMobileOp.map((it) => (
                                          <div className='dropdownItem' onClick={() => handleSuggestion(it.label, it.value)}>
                                            <span >
                                              {it?.label}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <Label htmlFor="validationCustom01">
                                        Contact Person
                                      </Label>
                                      <AvField
                                        name="support_contact_person"
                                        placeholder="Contact Person"
                                        type="text"
                                        className="form-control"
                                        id="validationCustom01"
                                        value={masterObject?.support_contact_person}
                                        onChange={handleChangeSupport}
                                      />
                                    </Col>
                                    <Col md="2">
                                      <Label htmlFor="validationCustom01">
                                        Project
                                      </Label>
                                      <Select
                                        name="project_id"
                                        value={selectedProject}
                                        options={project}
                                        classNamePrefix="select2-selection"
                                        onChange={(selected) => {
                                          handleSelectChange(selected, "project_id");
                                        }}
                                      />
                                      <p
                                        className="text-danger"
                                        style={{ fontSize: "11px" }}
                                      >
                                        {selectedProject === null ? errors.projectError : ""}
                                      </p>
                                    </Col>
                                    <Col md="2">
                                      <Label htmlFor="validationCustom01">
                                        Mode
                                      </Label>
                                      <Select
                                        name="mode_id"
                                        value={selectedMode}
                                        options={Mode}
                                        classNamePrefix="select2-selection"
                                        onChange={(selected) => {
                                          handleSelectChange(selected, "mode_id");
                                        }}
                                      />
                                    </Col>
                                    <Col md="2">
                                      <Label htmlFor="validationCustom01">
                                        Team
                                      </Label>
                                      <Select
                                        name="team_id"
                                        value={selectedTeam}
                                        options={team}
                                        classNamePrefix="select2-selection"
                                        onChange={(selected) => {
                                          handleSelectChange(selected, "team_id");
                                        }}
                                      />
                                    </Col>
                                    <Col md="6">
                                      <Label htmlFor="validationCustom01">
                                        Support Issues
                                      </Label>
                                      <AvField
                                        name="support_issue"
                                        placeholder="Support Issues"
                                        type="textarea"
                                        className="form-control"
                                        id="validationCustom01"
                                        value={masterObject?.support_issue}
                                        onChange={handleChangeSupport}
                                        style={{ height: "38px" }}
                                      />
                                    </Col>
                                    {/* <Col md="2">
                            <Label htmlFor="validationCustom01">
                              Bill Status  
                            </Label>
                            <Select
                              name="bill_status"
                              value={selectedBill}
                              options={[
                                { label: "Unpaid ", value: 0 },
                                { label: "Paid", value: 1 },
                              ]}
                              classNamePrefix ="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "bill_status");
                              }}
                            />
                        </Col> */}
                                    {/* {
                          (supportIdTobeUpdated!="" && supportIdTobeUpdated!=null)?"":(
                            <Col md="2">
                          <div className="mb-3" style={{marginTop:"2.75rem"}}>
                            <input
                                                type="checkbox"
                                                className="mb-2"
                                                name="billing"
                                                id="billing"                                         
                                                 checked={isChecked}
                                                onChange={
                                                    handleOnChangeCheckbox
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                              />
                                              <Label htmlFor="validationCustom01">
                                                Quick Issue
                                              </Label>
                          </div>
                        </Col>
                          )
                        } */}
                                    <Col md="2">
                                      <div className="mb-3" style={{ marginTop: "2.75rem" }}>
                                        <input
                                          type="checkbox"
                                          className="mb-2"
                                          name="billing"
                                          id="billing"
                                          checked={isChecked}
                                          onChange={
                                            handleOnChangeCheckbox
                                          }
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <Label htmlFor="validationCustom01">
                                          Quick Issue
                                        </Label>
                                      </div>
                                    </Col>
                                    {
                                      isChecked == true ? (
                                        <>
                                          <Col md="12">
                                            <table class="table table-bordered" id="taskListTable">
                                              <thead>
                                                <tr>
                                                  {/* <th>#</th> */}
                                                  <th width="200px">Category</th>
                                                  <th width="300px">TaskName</th>
                                                  <th>Assignee</th>
                                                  <th width="60px">Time</th>
                                                  <th width="95px">Start</th>
                                                  <th width="95px">End</th>
                                                  {/* <th>Status</th> */}
                                                  <th width="55px"></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  taskRows.map((item, index) => (
                                                    <>
                                                      <tr class="shown">
                                                        <td class="">
                                                          <Row>
                                                            <Col md="1" style={{ cursor: "pointer", paddingTop: "5px", paddingLeft: "8px", minWidth: "12px" }}>
                                                              <span >
                                                                {
                                                                  (item.flows.length > 0) ? (
                                                                    <>
                                                                      {item.expand === "hidden" ? (
                                                                        <>
                                                                          <svg onClick={() => showTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" id="expandPoint">
                                                                            <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                          </svg>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <svg onClick={() => hideTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="collapsePoint">
                                                                            <path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                                                          </svg>
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  ) : ""
                                                                }
                                                              </span>
                                                            </Col>
                                                            <Col md="11" style={{ minWidth: "150px" }}>
                                                              <Select
                                                                menuPosition="fixed"
                                                                name="subcategory"
                                                                value={item.subcategory}
                                                                onChange={(selected) => {
                                                                  handleAddTask(selected, index, item, 'category');
                                                                }}
                                                                options={taskCategoryOptions}
                                                                classNamePrefix="select2-selection"
                                                              />
                                                            </Col>
                                                          </Row>


                                                        </td>
                                                        <td class="" ><input type="text" name="task_name" class="form-control" value={item.task_name} onChange={(e) => handleAddTask(e, index, item, 'task_name')}></input></td>
                                                        <td class=""></td>
                                                        <td class=""><input type="text" name="task_tot_time" class="form-control" value={item?.task_tot_time} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_start_date" class="form-control" value={item?.task_start_date} style={{ width: '115px' }} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_end_date" class="form-control" value={item?.task_end_date} style={{ width: '115px' }} readOnly></input></td>
                                                        {/* <td class="">
                                  
                                </td> */}
                                                        <td style={{ textAlign: "center" }}>
                                                          <Row>
                                                            <Col>
                                                              <i className="fas fa-trash"
                                                                onClick={() => {
                                                                  var list = [...taskRows];
                                                                  list.splice(index, 1);
                                                                  setTaskRows(list);
                                                                }}></i>
                                                            </Col>
                                                          </Row>

                                                        </td>
                                                      </tr>
                                                      {
                                                        item.flows && item.flows.map((subItem, index1) => (
                                                          <>
                                                            <tr class={item.expand}>
                                                              <td class=""></td>
                                                              <td class="" style={{ paddingLeft: "16px" }}>{subItem?.flow_name}</td>
                                                              <td class="">
                                                                <Select
                                                                  menuPosition="fixed"
                                                                  name="staff_id"
                                                                  placeholder=""
                                                                  value={subItem?.assignee}
                                                                  onChange={(selected) => {
                                                                    handleAssignTask(selected, index, index1, item, subItem, "staff");
                                                                  }}
                                                                  options={taskAssigneeOptions}
                                                                  classNamePrefix="select2-selection"
                                                                />
                                                              </td>
                                                              <td class=""><input name="assign_time" class="form-control" value={subItem?.assign_time} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "time")}></input></td>
                                                              <td class=""><input type='date' min={moment().format("YYYY-MM-DD")} name="assign_startdate" class="form-control" value={subItem?.assign_startdate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "start")} style={{ width: '115px' }}></input></td>
                                                              <td class=""><input type='date' min={subItem?.assign_startdate ? subItem?.assign_startdate : moment().format("YYYY-MM-DD")} name="assign_enddate" class="form-control" value={subItem?.assign_enddate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "end")} style={{ width: '115px' }}></input></td>
                                                              {/* <td class="">
                                              <Select
                                                menuPosition="fixed"
                                                name="assign_current_status"
                                                placeholder=""
                                                value={subItem?.currentstatus}
                                                onChange={(selected) => {
                                                  handleAssignTask(selected,index,index1,item,subItem,"status");
                                                }}
                                                options={subItem.statusOption}
                                                classNamePrefix="select2-selection"
                                              />
                                              </td> */}
                                                              <td></td>
                                                            </tr>
                                                          </>
                                                        ))
                                                      }

                                                    </>
                                                  )
                                                  )
                                                }
                                              </tbody>
                                            </table>

                                            <button type="button"
                                              className="btn btn-primary"
                                              style={{ marginBottom: "8px" }}
                                              onClick={(e) => addNewTaskRow()}
                                            >Add Task</button>
                                          </Col>
                                        </>
                                      ) : ""
                                    }

                                    <Col>
                                      <div className="mb-3" style={{ paddingTop: "25px" }}>
                                        {supportIdTobeUpdated ? (
                                          <Button
                                            color="primary"
                                            type="submit"
                                            style={{ marginRight: "2%" }}
                                          >
                                            Update
                                          </Button>
                                        ) : (
                                          <Button
                                            color="primary"
                                            type="submit"
                                            style={{ marginRight: "2%" }}
                                          >
                                            Submit
                                          </Button>
                                        )}
                                        <Button
                                          color="danger"
                                          type="reset"
                                          onClick={() =>
                                            reset()}
                                        >
                                          {"  "}
                                          Reset
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col className="col-12">
                            <Card style={{ padding: "0px 0px 2px 0px" }}>
                              <CardBody>
                                <MDBDataTable
                                  id="SupportTicketTableID"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  entries={20}
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel value="2" style={{ padding: "14px 0px 0px 0px" }}>
                        <KanbanSupportTicket/>
                        {/* <div><h1>Kanban</h1></div> */}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal show={showPopup} size="xl" centered={true} className="modal-fullscreen">
        <div className="modal-header">
          <div>Support Ticket <b>#{supportDetails?.unique_id}</b></div>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
              handleTableData();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>


        <div className="modal-body">
          <Row>
            <Col md="3">
              <Card>
                <CardBody style={{ padding: "0px" }}>
                  <table id="support_view" className="table">
                    <tr>
                      <td ><b>TAT</b></td><td >{supportDetails?.tat}</td></tr>
                    <tr><td ><b>Date</b></td><td >{supportDetails?.day}</td></tr>
                    <tr><td ><b>Time</b></td><td >{supportDetails?.time}</td></tr>
                    <tr><td ><b>Client</b></td><td >{supportDetails?.client_name} </td></tr>
                    <tr><td ><b>Contact Person</b></td><td >{supportDetails?.support_contact_person} </td></tr>
                    <tr> <td ><b>Project</b></td><td >{supportDetails?.project_name}</td>
                    </tr>
                    <tr>
                      <td ><b>Mode</b></td><td >{supportDetails?.mode_name}</td></tr>
                    <tr><td ><b>Team</b></td><td >{supportDetails?.team_name} </td></tr>
                    <tr><td ><b>Bill Status</b></td><td >{supportDetails?.bill_status1}</td></tr>
                    <tr><td ><b>Status</b></td><td >{supportDetails?.support_status}</td>
                    </tr>

                    <tr>
                      <td ><b>Task Status</b></td><td >{supportDetails?.task_status}</td></tr>
                    <tr><td ><b>Created At</b></td><td >{supportDetails?.create}</td></tr>
                    <tr><td ><b>CreatedBy</b></td><td >{supportDetails?.add} </td></tr>
                    <tr> <td  ><b>UpdatedBy</b></td><td  >{supportDetails?.edited}</td>
                    </tr>
                    <tr>
                      <td ><b>Comment</b></td><td ><div style={{ width: "132px", padding: "0px" }}>{supportDetails?.support_issue}</div></td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Bill Status</b>
                        <Select
                          name="bill_status"
                          value={selectedBill}
                          options={[
                            { label: "Unpaid ", value: 0 },
                            { label: "Paid", value: 1 },
                          ]}
                          classNamePrefix="select2-selection"
                          onChange={(selected) => {
                            handleSelectBill(selected);
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef1}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleFollowupSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="2">
                        <Label>Status</Label>
                        <Select
                          id="followupstatus"
                          name="followupstatus"
                          value={followupObject?.status}
                          onChange={(selected) => {
                            handleFollowupData(selected, "followup_status");
                          }}
                          options={[{ label: "New", value: 0 },
                          { label: "Pending", value: 2 },
                          { label: "Completed", value: 3 },
                          { label: "Hold", value: 4 },
                          { label: "Solved", value: 5 },
                          { label: "Informed", value: 8 },
                          { label: "Closed", value: 6 },
                          { label: "No Issue", value: 7 }]}
                          classNamePrefix="select2-selection"
                          menuPosition="fixed"
                        />
                        <div className="text-danger"
                          style={{ fontSize: "11px" }}
                        >
                          {followupstatusError != ""
                            ? followupstatusError
                            : ""}
                        </div>
                      </Col>
                      <Col md="6">
                        <Label>Comments</Label>
                        <AvField
                          name="follow_comment"
                          placeholder="Comments"
                          type="textarea"
                          className="form-control"
                          value={followupObject?.comment}
                          onChange={(selected) => {
                            handleFollowupData(selected, "comment");
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter your comments"
                          rows="1"
                        />
                      </Col>
                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "10px" }}
                        >
                          Submit
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => {
                            followreset();
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <br></br>
                  <MDBDataTable
                    id="supportfollowupModalTable"
                    responsive
                    bordered
                    data={followup_data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <table class="table table-bordered" id="taskListTable">
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th width="250px" style={{ textAlign: "center" }}>TaskName</th>
                        <th width="100px" style={{ textAlign: "center" }}>Flow</th>
                        <th width={"100px"} style={{ textAlign: "center" }}>Assignee</th>
                        <th width={"50px"} style={{ textAlign: "center" }}><i className='fa fa-clock' title="Plan" style={{ fontSize: "10px" }}></i> P</th>
                        <th width={"50px"} style={{ textAlign: "center" }}><i className='fa fa-clock' title="Effort" style={{ fontSize: "10px" }}></i> E</th>
                        {/* <th><i className='fas fa-user-cog' title="Efficiency" style={{ fontSize:"13px"}}></i></th> */}
                        <th width={"100px"} style={{ textAlign: "center" }}>Start</th>
                        <th width={"100px"} style={{ textAlign: "center" }}>End</th>
                        <th width={"100px"} style={{ textAlign: "center" }}>%</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        supportDetails.all_tasks?.map((item, index) => {
                          var i = 0;
                          return (
                            <>
                              {
                                item.assigns.map((subItem, index1) => {
                                  if (subItem.staff_name?.length > 0)
                                    subItem.firstName1 = <a target={"_blank"} href={"/add_staff/" + subItem.staff_id}>{subItem.staff_name[0]}</a>
                                  if (subItem.assign_startdate)
                                    subItem.assign_startdate1 = moment(subItem.assign_startdate, "YYYY-MM-DD").format("DD-MM-YYYY")
                                  if (subItem.assign_enddate)
                                    subItem.assign_enddate1 = moment(subItem.assign_enddate, "YYYY-MM-DD").format("DD-MM-YYYY")
                                  if (subItem.assign_time)
                                    subItem.assign_time1 = timeFromNumber(subItem.assign_time);
                                  else
                                    subItem.assign_time1 = "00:00";
                                  if (typeof subItem.assign_effort_hr !== "undefined") {
                                    subItem.assign_effort_hr1 = subItem.assign_effort_hr.substring(0, subItem.assign_effort_hr.lastIndexOf(":"));
                                  }
                                  else {
                                    subItem.assign_effort_hr1 = "00:00";
                                  }
                                  if (subItem.assign_effort_hr1 == "")
                                    subItem.assign_effort_hr1 = "00:00";
                                  if (index1 == 0) {
                                    return (
                                      <tr>
                                        <td rowSpan={item.assigns?.length}>{item.task_name}</td>
                                        <td>{(subItem.flow_name?.length > 0) ? subItem.flow_name[0] : ""}</td>
                                        <td>{subItem.firstName1}</td>
                                        <td>{subItem.assign_time1}</td>
                                        <td>{subItem.assign_effort_hr1}</td>
                                        <td>{subItem.assign_startdate1}</td>
                                        <td>{subItem.assign_enddate1}</td>
                                        <td><Progress style={{ height: "14px" }} color="primary" value={subItem.assign_complete_percentage}>{subItem.assign_complete_percentage}%</Progress></td>
                                      </tr>
                                    )
                                  }
                                  else {
                                    return (
                                      <tr>
                                        <td>{(subItem.flow_name?.length > 0) ? subItem.flow_name[0] : ""}</td>
                                        <td>{subItem.firstName1}</td>
                                        <td>{subItem.assign_time1}</td>
                                        <td>{subItem.assign_effort_hr1}</td>
                                        <td>{subItem.assign_startdate1}</td>
                                        <td>{subItem.assign_enddate1}</td>
                                        <td><Progress style={{ height: "14px" }} color="primary" value={subItem.assign_complete_percentage}>{subItem.assign_complete_percentage}%</Progress></td>
                                      </tr>
                                    )
                                  }

                                })
                              }

                            </>
                          )

                        })
                      }


                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>


      </Modal>

    </React.Fragment>
  )
};
export default SupportTicket;