import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import DashboardIcon from "@mui/icons-material/Dashboard";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./addProject.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import $ from "jquery";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { elementAcceptingRef } from "@mui/utils";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const AddProject = () => {
  const [addProjectObject, setAddProjectObject] = useState({});
  const [addProjectIdTobeUpdated, setAddProjectIdToBeUpdated] = useState(null);
  const [addProjectIdToBeDeleted, setAddProjectIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [addProjectForTable, setAddProjectForTable] = useState([]);
  const [addProjectValue, setAddProjectValue] = useState("");

  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});

  const [type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const [techno, setTechno] = useState([]);
  const [selectedTechno, setSelectedTechno] = useState(null);

  const [typeSearch, setTypeSearch] = useState([]);
  const [selectedTypeSearch, setSelectedTypeSearch] = useState(null);

  const [productOptions, setProductOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedManager, setselectedManager] = useState(null);
  const [leaderOptions, setLeaderOptions] = useState([]);
  const [selectedLeader, setselectedLeader] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeams, setselectedTeams] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedStatusSearch, setSelectedStatusSearch] = useState(null);
  const [searchData, setSearchData] = useState({});

  const [projectName, setProjectName] = useState([]);
  const [selectedProjectName, setSelectedProjectName] = useState(null);

  const [client, setClient] = useState([]);
  const [selectedclient, setSelectedClient] = useState(null);

  const [branch, setBranch] = useState([]);
  const [selectedbranch, setSelectedBranch] = useState(null);
  const [errors, setErrors] = useState({
    clientType: "",
  });

  const [values, setValues] = useState([
    {
      product_id: "",
      product_details: "",
      product_amount: "",
      product_validity_no: "",
      product_validity_unit: "",
      product_renewable: "",
    },
  ]);

  const [billingStatus, setBillingStatus] = useState([]);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState(null);

  const {
    addingAddProject,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  let history = useHistory();
  useEffect(() => {
    fetch_products();
    fetch_managers();
    fetch_leaders();
    handleTableListData();
    fetch_all_ProjectName();
    fetch_all_Client();
    fetch_all_Branch();
    fetch_all_Types();
    fetch_Seach_Types();
    fetch_all_teams();
    fetch_all_Techno();
    fetch_billing_status();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function fetch_billing_status() {
    axios
      .get(`${API_URL}project/billingstatus/`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Bill =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            };
          });
        setBillingStatus([{ options: Bill }])
      });
  }


  function handleTableListData(Status = "", Type = "") {
    var url = `${API_URL}project/list?prj_status=` + Status + "&type=" + Type;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let LeadsData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/project/" + item.unique_id);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateAddProject(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setAddProjectIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          if (item.prj_status === 1) {
            item.Status = "Hold";
          } else if (item.prj_status === 2) {
            item.Status = "Completed";
          } else if (item.prj_status === 3) {
            item.Status = "Pending";
          } else if (item.prj_status === 4) {
            item.Status = "Suspended";
          } else if (item.prj_status === 5) {
            item.Status = "Rejected";
          } else if (item.prj_status === 6) {
            item.Status = "Live";
          } else {
            item.Status = "";
          }
          // if (item.type === 0) {
          //   item.Type = "Mobile Application";
          // } else if (item.type === 1) {
          //   item.Type = "Web Application";
          // } else if (item.type === 2) {
          //   item.Type = "Website";
          // } else {
          //   item.Type = "";
          // }

          // let LeadsValue = item?.leads_for || [];

          // item.LeadsForTbl = "";
          // LeadsValue &&
          //   LeadsValue.map((el) => {
          //     if (item.LeadsForTbl === "") item.LeadsForTbl = el?.name;
          //     else item.LeadsForTbl = item.LeadsForTbl + "," + el?.name;
          //   });

          let Client = item?.client || {};
          item.client = Client?.short_name;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          LeadsData.push(item);
        });
        setAddProjectForTable(LeadsData);
      });
  }

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}leads/data?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result.date = moment(result.date).format("DD-MM-YYYY");

        if (result?.time)
          result.time = moment(result.time, "HHmmss").format("hh:mm a");
        else result.time = "";

        result.up_date = moment(result.up_date).format("DD-MM-YYYY");

        if (result?.up_time)
          result.up_time = moment(result.up_time, "HHmmss").format("hh:mm a");
        else result.up_time = "";

        let addedBy = result.addedby || {};
        result.addedBy = addedBy?.firstName;

        let updatedBy = result.addedby || {};
        result.updatedBy = updatedBy?.firstName;

        setPopupData(result);
        setPopupView(true);
      });
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setAddProjectObject({
      ...addProjectObject,
      [e.target.name]: e.target.value,
    });
  };

  function fetch_Seach_Types() {
    axios
      .get(`${API_URL}project/projectoption`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var SearchType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setTypeSearch([{ options: SearchType_data }]);
      });
  }
  function fetch_products() {
    axios
      .get(`${API_URL}item/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var itemsData =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
              validity_unit: el.validity,
              validity_no: el.validity_no,
              renewable: el.renewable
            };
          });
        setProductOptions([{ options: itemsData }]);
      });
  }
  function fetch_managers() {
    axios
      .get(`${API_URL}project/manager_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list =
          res.data.data &&
          res.data.data.map((el) => {
            if (el.lastName)
              var name = el.firstName + " " + el.lastName;
            else
              var name = el.firstName;
            return {
              label: name,
              value: el._id,
            };
          });
        setManagerOptions([{ options: list }]);
      });
  }
  function fetch_leaders() {
    axios
      .get(`${API_URL}project/leader_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list =
          res.data.data &&
          res.data.data.map((el) => {
            if (el.lastName)
              var name = el.firstName + " " + el.lastName;
            else
              var name = el.firstName;
            return {
              label: name,
              value: el._id,
            };
          });
        setLeaderOptions([{ options: list }]);
      });
  }

  function fetch_all_ProjectName() {
    axios
      .get(`${API_URL}project/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ProjectName_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.project_name,
              value: el._id,
            };
          });
        setProjectName([{ options: ProjectName_data }]);
      });
  }

  function fetch_all_Client() {
    axios
      .get(`${API_URL}client/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Client_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setClient([{ options: Client_data }]);
      });
  }

  function fetch_all_Branch() {
    axios
      .get(`${API_URL}branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setBranch([{ options: Branch_data }]);
      });
  }

  function fetch_all_Types() {
    axios
      .get(`${API_URL}project/projectoption`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Type_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setType([{ options: Type_data }]);
      });
  }

  function fetch_all_Techno() {
    axios
      .get(`${API_URL}project/technologiesoption`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Techno_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setTechno([{ options: Techno_data }]);
      });
  }
  function fetch_all_teams() {
    axios
      .get(`${API_URL}project_dashboard/team_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var team_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setTeamOptions([{ options: team_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Manager":
        setselectedManager(selected);
        setAddProjectObject({
          ...addProjectObject,
          project_manager: selected.value,
        });
        break;
      case "Teams":
        setselectedTeams(selected);
        let teams = [];
        selected &&
          selected.forEach((element) => {
            teams.push(element.value);
          });
        setAddProjectObject({
          ...addProjectObject,
          ["teams"]: teams,
        });
        break;
      case "Leader":
        setselectedLeader(selected);
        setAddProjectObject({
          ...addProjectObject,
          project_leader: selected.value,
        });
        break;
      case "Type":
        setSelectedType(selected);
        setAddProjectObject({
          ...addProjectObject,
          type: selected.value,
        });
        break;
      case "Status":
        setSelectedStatus(selected);
        setAddProjectObject({
          ...addProjectObject,
          prj_status: selected.value,
        });
        break;
      case "Techno":
        setSelectedTechno(selected);
        let TechnoArray = [];
        selected &&
          selected.forEach((element) => {
            TechnoArray.push(element.value);
          });
        setAddProjectObject({
          ...addProjectObject,
          ["technologies"]: TechnoArray,
        });
        break;
      // case "Name":
      //   setSelectedProjectName(selected);
      //   setAddProjectObject({
      //     ...addProjectObject,
      //     project_name: selected.label,
      //   });
      //   break;
      case "Client":
        setSelectedClient(selected);
        setAddProjectObject({
          ...addProjectObject,
          client: selected.value,
        });
        setErrors({
          ...errors,
          clientType: "",
        });
        break;
      case "billing_status":
        setSelectedBillingStatus(selected);
        setAddProjectObject({
          ...addProjectObject,
          billing_status: selected.value,
        });
        break;
      default:
        break;
    }
  };

  const handleSelectedForSearch = (selected, name) => {
    switch (name) {
      case "Types":
        setSelectedTypeSearch(selected);
        setAddProjectObject({
          ...addProjectObject,
          type: selected.value,
        });
        break;
      case "ProjectStatus":
        setSelectedStatusSearch(selected);
        setAddProjectObject({
          ...addProjectObject,
          prj_status: selected.value,
        });
        break;

      default:
        break;
    }
  };

  const handleTableInputchange = (selected, type, index) => {
    var list = [...values];

    if (type == "Product") {
      list[index].product_id = selected.value;
      list[index].selectedproduct = selected;
      list[index].product_validity_no = selected.validity_no;
      list[index].product_validity_unit = selected.validity_unit;
      list[index].selectedValType = { label: selected.validity_unit, value: selected.validity_unit };
      list[index].product_renewable = selected.renewable;
      if (selected.renewable == "Yes") {
        list[index].isRenewable = true;
      }
      else {
        list[index].isRenewable = false;
      }
    }
    else if (type == "Details") {
      list[index].product_details = selected.target.value;
    }
    else if (type == "validity unit") {
      list[index].product_validity_unit = selected.value;
      list[index].selectedValType = { label: selected.value, value: selected.value };
    }
    else if (type == "validity no") {
      list[index].product_validity_no = selected.target.value;
    }
    setValues(list);
  }
  const handleProductRenewable = (event, index) => {
    var list = [...values];
    if (event.target.checked == true) {
      list[index].product_renewable = "Yes";
      list[index].isRenewable = true;
    }
    else {
      list[index].product_renewable = "No";
      list[index].isRenewable = false;
    }
    setValues(list);
  }

  let preUpdateAddProject = (item) => {
    setAddProjectIdToBeUpdated(item._id);
    setAddProjectValue(item?.name);

    axios
      .get(`${API_URL}project/view?project_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((element) => {
            let branch = element?.branch ? element.branch : {};
            let branchValue = {
              label: branch?.name,
              value: branch?._id,
            };

            let Client = element?.client ? element.client : {};
            let ClientValue = {
              label: Client?.name,
              value: Client?._id,
            };
            let Bill = element?.billing_status ? element.billing_status : {};
            let BillStatusValue = {
              label: Bill?.name,
              value: Bill?._id,
            };

            let Statusvalues = {};
            if (element?.prj_status == 1) {
              Statusvalues = {
                label: "Hold",
                value: 1,
              };
            } else if (element?.prj_status == 2) {
              Statusvalues = {
                label: "Completed",
                value: 2,
              };
            } else if (element?.prj_status == 3) {
              Statusvalues = {
                label: "Pending",
                value: 3,
              };
            } else if (element?.prj_status == 4) {
              Statusvalues = {
                label: "Suspended",
                value: 4,
              };
            } else if (element?.prj_status == 5) {
              Statusvalues = {
                label: "Rejected",
                value: 5,
              };
            }
            else if (element?.prj_status == 6) {
              Statusvalues = {
                label: "Live",
                value: 6,
              };
            }

            element.project_id = element?._id;

            let Project_Name = element.project_name;
            let ProjectValueValue = {
              label: Project_Name,
            };

            let Type = element?.type || {};
            let Types = {
              label: Type?.name,
              value: Type?._id,
            };

            // let Techno = element?.technologies[0] || {};
            // let Technos = {
            //   label: Techno?.name,
            //   value: Techno?._id,
            // };
            let TechnoArray = [];
            element.technologies &&
              element.technologies.map((el) => {
                let val = {};
                val.label = el.name;
                val.value = el._id;
                TechnoArray.push(val);
              });
            let teams = [];
            element.teams &&
              element.teams.map((el) => {
                let val = {};
                val.label = el.name;
                val.value = el._id;
                teams.push(val);
              });
            var products = [];
            element.products && element.products.map((elemen) => {

              if (elemen.product_renewable == "Yes")
                var isrenew = true;
              else
                var isrenew = false;

              products.push(
                {
                  product_id: elemen.product_id._id,
                  product_details: elemen.product_details,
                  selectedproduct: { label: elemen.product_id.name, value: elemen.product_id._id, validity_unit: elemen.product_validity_unit, validity_no: elemen.product_validity_no, renewable: elemen.product_renewable },
                  product_validity_no: elemen.product_validity_no,
                  product_validity_unit: elemen.product_validity_unit,
                  selectedValType: { label: elemen.product_validity_unit, value: elemen.product_validity_unit },
                  product_renewable: elemen.product_renewable,
                  isRenewable: isrenew
                })
            })
            if (element.project_manager) {
              if (element.project_manager.lastName)
                var name = element.project_manager.firstName + " " + element.project_manager.lastName;
              else
                var name = element.project_manager.firstName;

              setselectedManager({ label: name, value: element.project_manager._id });
              element.project_manager = element.project_manager._id;
            }
            if (element.project_leader) {
              if (element.project_leader.lastName)
                var name = element.project_leader.firstName + " " + element.project_leader.lastName;
              else
                var name = element.project_leader.firstName;

              setselectedLeader({ label: name, value: element.project_leader._id });
              element.project_leader = element.project_leader._id;
            }
            setValues(products);
            setSelectedTechno(TechnoArray);
            setselectedTeams(teams);

            setSelectedType(Types);

            setSelectedProjectName(ProjectValueValue);

            setSelectedStatus(Statusvalues);

            setSelectedClient(ClientValue);
            setSelectedBranch(branchValue);
            setAddProjectObject(element);
            setSelectedBillingStatus(BillStatusValue);
          });

        // let districts = result?.district ? result.district : {};
        // let DistrictValue = {
        //   label: districts?.district_name,
        //   value: districts?._id,
        // };

        // setSelectedState(StateValue);
      });
  };

  function handleChangeAddProject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setAddProjectValue(value);
    setAddProjectObject({ ...addProjectObject, [name]: value });
  }

  const handleValidSubmit = () => {
    addProjectObject.products = values;
    if (selectedclient === null) {
      let errorVal = errors;
      if (selectedclient === null) errorVal.clientType = "Please Select Client";
      setErrors({
        ...errorVal,
      });
    } else {
      if (addProjectIdTobeUpdated) {
        axios
          .put(`${API_URL}project/update`, addProjectObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Project updated successfully");
              setAddProjectValue("");
              formRef.current.reset();
              handleClickOpenForm(data);
              setAddProjectObject({
                ["kick_off"]: 0,
                ["end"]: 0,
              });
              setErrors({
                clientType: "",
              });
              setAddProjectIdToBeUpdated(null);
              setSelectedBranch(null);
              setSelectedClient(null);
              setSelectedType(null);
              setSelectedTechno(null);
              setSelectedStatus(null);
              setselectedLeader(null);
              setselectedManager(null);
              setSelectedBillingStatus(null);
              handleTableListData();
              setValues([{
                product_id: "",
                product_details: "",
                product_amount: "",
                product_validity_no: "",
                product_validity_unit: "",
                product_renewable: "",
              },])
            } else {
              toastr.error("Failed to update Project");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}project/`, addProjectObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Project added Successfully");
              handleClickOpenForm(data);
              setAddProjectValue("");
              formRef.current.reset();
              setAddProjectObject({
                ["kick_off"]: 0,
                ["end"]: 0,
              });
              setErrors({
                clientType: "",
              });
              setSelectedBranch(null);
              setSelectedClient(null);
              setSelectedType(null);
              setSelectedTechno(null);
              setSelectedStatus(null);
              setselectedLeader(null);
              setselectedManager(null);
              setSelectedBillingStatus(null);
              handleTableListData();
              setValues([{
                product_id: "",
                product_details: "",
                product_amount: "",
                product_validity_no: "",
                product_validity_unit: "",
                product_renewable: "",
              },])
            } else {
              toastr.error("Failed to add Project");
              return;
            }
          })
          .catch((err) => {
            // toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Date",
      //   field: "date",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "ID",
      //   field: "unique_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "project_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shortcode",
        field: "shortcode",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Type",
      //   field: "type",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Client",
        field: "client",
        sort: "asc",
        width: 150,
      },

      {
        label: "Domain",
        field: "domain",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Quotation",
      //   field: "quotation_no",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Invoice",
      //   field: "invoice_no",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: addProjectForTable,
  };

  const customStyles = {
    lineHeight: "1.8",
  };
  const customStyles1 = {
    borderColor: "#e5e5e5",
    height: "auto",
  };

  const handleSearch = () => {
    let Status = addProjectObject?.prj_status
      ? addProjectObject.prj_status
      : "";
    let Type = addProjectObject?.type ? addProjectObject.type : "";

    handleTableListData(Status, Type);
  };

  const reset = () => {
    handleTableListData();
    setSelectedTypeSearch(null);
    setSelectedStatusSearch(null);
    setSelectedBranch(null);
    setSelectedClient(null);
    setSelectedProjectName(null);
    setSelectedStatus(null);
    setSelectedType(null);
    setSelectedBillingStatus(null);
    setAddProjectObject({});
  };

  const addMore = () => {
    setValues([...values, {
      product_id: "",
      product_details: "",
      product_amount: "",
      product_validity_no: "",
      product_validity_unit: "",
      product_renewable: ""
    }]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];
    let freebagArray = [];

    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
        freebagArray.push(element.freeBag);
      });

    setValues(list);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}project` + "/" + addProjectIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Project deleted successfully");
                  if (
                    addProjectObject &&
                    addProjectObject._id === addProjectIdToBeDeleted
                  ) {
                    setAddProjectValue("");
                    formRef.current.reset();
                    setAddProjectObject({});
                    setAddProjectIdToBeUpdated(null);

                    // setSelectedSubCat(null);
                  }
                  handleTableListData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Project");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Project" />
          <Row>
            <Col xl="12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Project Name
                          </Label>
                          <AvField
                            style={customStyles}
                            name="project_name"
                            placeholder="Project Name"
                            type="text"
                            errorMessage="Enter Project Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.project_name}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Shotcode
                          </Label>
                          <AvField
                            style={customStyles}
                            name="shortcode"
                            placeholder="Project Shotcode"
                            type="text"
                            errorMessage="Enter Project Shotcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.shortcode}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Client</Label>
                          <Select
                            name="Client"
                            value={selectedclient}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Client");
                            }}
                            options={client}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedclient === null ? errors.clientType : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Domain</Label>
                          <AvField
                            style={customStyles}
                            name="domain"
                            placeholder="Domain Name"
                            type="text"
                            className="form-control"
                            // errorMessage="Enter Domain Name"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.domain}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="Type"
                            value={selectedType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Type");
                            }}
                            displayValue="Type"
                            options={type}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Technology</Label>
                          <Select
                            isMulti
                            name="Techno"
                            value={selectedTechno}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Techno");
                            }}
                            displayValue="Techno"
                            options={techno}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Teams</Label>
                          <Select
                            isMulti
                            name="teams"
                            value={selectedTeams}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Teams");
                            }}
                            displayValue="Teams"
                            options={teamOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Qt.No</Label>
                          <AvField
                            style={customStyles}
                            name="quotation_no"
                            placeholder="Qt. No."
                            type="text"
                            className="form-control"
                            // errorMessage="Enter Qt. No."
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.quotation_no}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Invoice No</Label>
                          <AvField
                            style={customStyles}
                            name="invoice_no"
                            placeholder="Invoice No."
                            type="text"
                            className="form-control"
                            // errorMessage="Enter Invoice Name"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.invoice_no}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              Kick Off
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              name="kick_off"
                              value={addProjectObject?.kick_off}
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              End
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              name="end"
                              value={addProjectObject?.end}
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Project Status</Label>
                          <Select
                            name="Status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Status");
                            }}
                            options={[
                              {
                                label: "Hold",
                                value: 1,
                              },
                              {
                                label: "Completed",
                                value: 2,
                              },
                              {
                                label: "Pending",
                                value: 3,
                              },
                              {
                                label: "Suspended",
                                value: 4,
                              },
                              {
                                label: "Rejected",
                                value: 5,
                              },
                              {
                                label: "Live",
                                value: 6,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Billing Status</Label>
                          <Select
                            name="billing_status"
                            value={selectedBillingStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "billing_status");
                            }}
                            options={billingStatus}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Hr.</Label>
                          <AvField
                            style={customStyles}
                            name="hours"
                            placeholder="HR"
                            type="number"
                            className="form-control"
                            // errorMessage="Enter Hr"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.hours}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Budget</Label>
                          <AvField
                            style={customStyles}
                            name="rupees"
                            placeholder="Rs"
                            type="number"
                            className="form-control"
                            // errorMessage="Enter Rs"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.rupees}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Project Manager</Label>
                          <Select
                            name="Client"
                            value={selectedManager}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Manager");
                            }}
                            options={managerOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Team Leader</Label>
                          <Select
                            name="Client"
                            value={selectedLeader}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Leader");
                            }}
                            options={leaderOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedclient === null ? errors.clientType : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Remark</Label>
                          <AvField
                            style={customStyles1}
                            name="remarks"
                            placeholder="Remark"
                            type="textarea"
                            className="form-control"
                            rows="2"
                            // errorMessage="Enter Remark"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={addProjectObject?.remarks}
                            onChange={handleChangeAddProject}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* Table Fields */}
                    <Row>
                      <Col className="col-12">
                        <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                          {/* Add Person start*/}
                          <Row>
                            <Col xl="12">
                              <Table
                                style={{ textAlign: "center" }}
                                id="waste_id"
                                className="table table-bordered dataTable"
                              >
                                <TableHead>
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell
                                      style={{
                                        width: "10px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      No.
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "150px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Product Name
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "200px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Product Details
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "50px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Validity
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "100px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Validity Unit
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "30px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      Renewable
                                    </TableCell>

                                    {values.length !== 1 ? (
                                      <TableCell
                                        style={{
                                          width: "10px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Action
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values &&
                                    values.map((item, index) => (
                                      <TableRow>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{ textAlign: "center" }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            name="product_id"
                                            value={item.selectedproduct}
                                            onChange={(selected) => {
                                              handleTableInputchange(selected, "Product", index);
                                            }}
                                            options={productOptions}
                                            classNamePrefix="select2-selection"
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="details"
                                            value={item.product_details}
                                            type="text"
                                            className="form-control"
                                            id="validationCustom05"
                                            onChange={(selected) => {
                                              handleTableInputchange(selected, "Details", index);
                                            }}
                                          />
                                        </TableCell>

                                        <TableCell>
                                          <AvField
                                            name="validity"
                                            value={item.product_validity_no}
                                            type="text"
                                            className="form-control"
                                            id="validationCustom05"
                                            onChange={(selected) => {
                                              handleTableInputchange(selected, "validity no", index);
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            name="product_validity_unit"
                                            errorMessage="Select validity Type"
                                            value={item.selectedValType}
                                            onChange={(selected) => {
                                              handleTableInputchange(selected, "validity unit", index);
                                            }}
                                            options={[
                                              {
                                                label: "Year",
                                                value: "Year",
                                              },
                                              {
                                                label: "Month",
                                                value: "Month",
                                              },
                                              {
                                                label: "Day",
                                                value: "Day",
                                              },
                                              {
                                                label: "Quarter",
                                                value: "Quarter",
                                              },
                                              {
                                                label: "Hour",
                                                value: "Hour",
                                              },
                                              {
                                                label: "Second",
                                                value: "Second",
                                              },
                                            ]}
                                            classNamePrefix="select2-selection"
                                          />
                                        </TableCell>
                                        <TableCell style={{ textAlign: "center" }}>
                                          <input
                                            type="checkbox"
                                            className="mb-2"
                                            name="product_renewable"
                                            checked={item.isRenewable}
                                            onClick={(e) => {
                                              handleProductRenewable(e, index);
                                            }}
                                          />
                                        </TableCell>

                                        {values.length !== 1 && (
                                          <i
                                            className="uil uil-times"
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                              paddingLeft: "5px",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                            onClick={() => handleRemove(index)}
                                          ></i>
                                        )}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </Col>
                          </Row>
                          <span
                            onClick={() => addMore()}
                            style={{ width: "190px", cursor: "pointer" }}
                          >
                            {" "}
                            + Add New Product{" "}
                          </span>
                          <br />
                          {/*Add Person End*/}
                        </CardBody>
                      </Col>
                    </Row>
                    {/* Table Fields */}
                    <Col md="3">
                      <div className="mb-2" style={{ paddingTop: "25px" }}>
                        {addProjectIdTobeUpdated ? (
                          <Button
                            color="primary"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            disabled={addingAddProject ? true : false}
                          >
                            {addingAddProject ? "Updating" : "Update"}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            disabled={addingAddProject ? true : false}
                          >
                            {addingAddProject ? "Adding" : "Submit"}
                          </Button>
                        )}
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          {"  "}
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  {/* <Label>View - Manage Projects</Label> */}
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                          name="Types"
                          value={selectedTypeSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "Types");
                          }}
                          displayValue="Lead"
                          options={typeSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          name="ProjectStatus"
                          value={selectedStatusSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "ProjectStatus");
                          }}
                          options={[
                            {
                              label: "Hold",
                              value: 1,
                            },
                            {
                              label: "Completed",
                              value: 2,
                            },
                            {
                              label: "Pending",
                              value: 3,
                            },
                            {
                              label: "Suspended",
                              value: 4,
                            },
                            {
                              label: "Rejected",
                              value: 5,
                            },
                            {
                              label: "Live",
                              value: 6,
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => handleSearch()}
                        >
                          Filter
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="AddProjectTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProject;