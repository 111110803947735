import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Container, Progress, Input, CardHeader, Badge } from "reactstrap";
import axios from "axios";
import toastr from "toastr";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
const Notification = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [list, setList] = useState([]);
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 90,
            },
            {
                label: "Title",
                field: "title",
                sort: "asc",
                width: 90,
            },
            {
                label: "Message",
                field: "body",
                sort: "asc",
                width: 90,
            },
            {
                label: "Status",
                field: "read_status",
                sort: "asc",
                width: 90,
            },
        ],
        rows: list,
    };
    const getData = () => {
        axios.get(API_URL + "notification/get-all", {
            headers: {
                'x-access-token': accessToken
            }
        }).
            then((res) => {
                var data = res.data.data;
                data && data.map((item, index) => {
                    item.id = index + 1;
                    item.date = moment(item?.date).format("DD-MM-YYYY hh:mm a");
                    if (item?.read_status == 0)
                        item.read_status = (
                            <Badge className="bg bg-danger">UnRead</Badge>
                        )
                    else
                        item.read_status = (
                            <Badge className="bg bg-success">Readed</Badge>
                        )
                })
                setList(data);
            })
    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb breadcrumbItem="Notifications" />
                    <Card>
                        <CardBody>
                            <MDBDataTable
                                id="notificationTable"
                                responsive
                                bordered
                                data={data}
                                disableRetreatAfterSorting={true}
                                entries={100}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Notification;