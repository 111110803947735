import React,{ useState, useEffect, useRef } from "react";
import { Card,CardBody,CardHeader,Row,Col,Progress} from "reactstrap";
import axios from "axios";
const TVEventDashboard = (props)=>{
    const { accessToken,code } = props.match.params;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [details, setDetails] = useState({});
    const [totEvents,settotEvents] = useState(null);
    const [counter, setCounter] = useState(1);
    const handleTableData = () => {
        axios
          .get(`${API_URL}tv/event_view`, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            let result = res.data.data;
            settotEvents(res.data.count);
             if(result.length>0)
             {
                setDetails(result[0]);
             }         
          });
      };
      function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          let id = setInterval(() => {
            savedCallback.current();
          }, delay);
          return () => clearInterval(id);
        }, [delay]);
    }
    useEffect(()=>{
      handleTableData();
    },[])
    useInterval(() => {
      handleTableData();
      setCounter(counter + 1);
      if(counter==totEvents)
      {
        window.location.href="/tv-project-dashboard/"+accessToken+"/"+code
      }
    }, 10000);
    return(
        <React.Fragment>
          <img src={`${API_URL}uploads/events/${details.image}`} height="550px"></img>               
        </React.Fragment>
    )
}
export default TVEventDashboard;