import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./TaskChecklist.scss";
import { Grid } from "@mui/material";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const TaskRules = () => {
  const [taskRulesObject, setTaskRulesObject] = useState({});
  const [taskRulesIdTobeUpdated, setTaskRulesIdToBeUpdated] = useState(null);
  const [taskRulesIdToBeDeleted, setTaskRulesIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [taskRulesForTable, setTaskRulesForTable] = useState([]);
  const [taskRulesValue, setTaskRulesValue] = useState("");

  const {
    districts,
    addingTaskRules,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}taskchecklist/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let taskRulesData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTaskRules(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setTaskRulesIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          item.checklist_name = item.checklist_name
          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          taskRulesData.push(item);
        });
        setTaskRulesForTable(taskRulesData);
      });
  }

  let preUpdateTaskRules = (item) => {
    setTaskRulesValue(item?.name);

    setTaskRulesIdToBeUpdated(item._id);
    setTaskRulesObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task Checklist",
        field: "checklist_name",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Weightage",
      //   field: "weightage",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: taskRulesForTable,
  };

  function handleChangeTaskRules(e) {
    let name = e.target.name;
    let value = e.target.value;
    setTaskRulesValue(value);
    setTaskRulesObject({ ...taskRulesObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (taskRulesIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}taskchecklist`, taskRulesObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Task Checklist Name updated successfully");
            setTaskRulesValue("");
            formRef.current.reset();
            handleTableData();
            setTaskRulesObject({});
            setTaskRulesIdToBeUpdated(null);
            handleTableData();
          } else {
            toastr.error("Failed to update Task Checklist Name");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}taskchecklist`, taskRulesObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Task Checklist name added succesfully");

            setTaskRulesValue("");
            formRef.current.reset();
            setTaskRulesObject({});
            handleTableData();
          } else {
            toastr.error("Failed to add into Task Checklist");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const reset = () => {
    formRef.current.reset();
  };
  // const customStyles = {
  //   lineHeight: "1.8",
  // };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}taskchecklist` + "/" + taskRulesIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Task Checklist Name deleted successfully");
                    if (
                      taskRulesObject &&
                      taskRulesObject._id === taskRulesIdToBeDeleted
                    ) {
                      setTaskRulesValue("");
                      formRef.current.reset();
                      setTaskRulesObject({});
                      setTaskRulesIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Task Checklist Name"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Task Checklist" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Task Checklist
                            </Label>
                            <AvField
                              // style={customStyles}
                              name="checklist_name"
                              placeholder="Task Checklist"
                              type="text"
                              errorMessage="Enter Task Checklist Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={taskRulesObject?.checklist_name}
                              onChange={handleChangeTaskRules}
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Weightage
                            </Label>
                            <AvField
                              // style={customStyles}
                              name="weightage"
                              placeholder="Weightage"
                              type="number"
                              errorMessage="Enter Task Weightage Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={taskRulesObject?.weightage}
                              onChange={handleChangeTaskRules}
                            />
                          </div>
                        </Col> */}

                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {taskRulesIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingTaskRules ? true : false}
                              >
                                {addingTaskRules ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingTaskRules ? true : false}
                              >
                                {addingTaskRules ? "Adding" : "Submit"}
                              </Button>
                            )}
                            {/* <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button> */}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="TaskRulesTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default TaskRules;