import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import defaultProfile from "../../../assets/images/person-icon.png";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./ViewClient.scss";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { getUsers } from "../../../store/actions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, getCompany } from "../../../helpers/globalFunctions";

const ViewClient = () => {
  const formRef = useRef();

  const [viewClientObject, setViewClientObject] = useState({});
  const [viewClientValue, setViewClientValue] = useState("");
  const [currentLocalbody, setCurrentLocalbody] = useState(getLocalbody());
  const [currentCompany, setCurrectCompany] = useState(getCompany());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUsers());
    handleTableData();
  }, [currentLocalbody]);
  function handleTableData(search = "") {
    var url = `${API_URL}client/client_view?search=` + search;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;
          // item.localbody_name =  getLocalbody();
          // item.type = item?.cust_type;
          // item.district_name = item?.district;
          // item.ward_name = item?.ward;

          // item.image = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //       src={`${API_URL}public/uploads${item.popup_image}`}
          //       height="160"
          //       width="120"
          //     />
          //   </div>
          // );
        });

        setTableData(result);
      });
  }
  function handleChangeOurProject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setViewClientValue(value);
    setViewClientObject({ ...viewClientObject, [name]: value });
  }

  const handleSearch = () => {
    let search = viewClientObject?.search ? viewClientObject.search : "";

    handleTableData(search);
  };

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    handleTableData();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Client" />
          <Row>
            <Col xl="12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <AvField
                            style={customStyles}
                            name="search"
                            placeholder="Search Name/Mobile"
                            type="text"
                            errorMessage="Search something"
                            className="form-control"
                            id="validationCustom02"
                            value={viewClientObject?.search}
                            onChange={handleChangeOurProject}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            onClick={() => handleSearch()}
                          >
                            Filter
                          </Button>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {tableData?.map((item) => (
              <div className="col-sm-6 col-xl-3">
                <div
                  className="text-center card"
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <div className="float-end dropdown">
                      <a
                        aria-haspopup="true"
                        className="text-body font-size-16 dropdown-toggle"
                        aria-expanded="false"
                      >
                        {/* <i className="uil uil-ellipsis-h"></i> */}
                      </a>
                      <div
                        tabindex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu-end dropdown-menu"
                      >
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Action
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Remove
                        </a>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    {item?.logo ? (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          src={`${API_URL}uploads/client_images/${item?.logo}`}
                          alt="/"
                        />
                      </div>
                    ) : (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          src={defaultProfile}
                          alt="/"
                        />
                      </div>
                    )}

                    <h5 className="font-size-16 mb-1">
                      <a className="text-dark">{item.short_name}</a>
                    </h5>

                    <p className="text-muted mb-2">{item.mobile}</p>
                    <a
                      type="button"
                      className="text-truncate btn btn-btn-outline-light"
                      style={{ textAlign: "left" }}
                      //   onClick={() => {
                      //     history.push("/view_clients/" + item._id);
                      //     // window.location.assign("/staff/" + item._id);
                      //   }}
                    >
                      <i className="uil uil-user me-1"></i>
                      Profile
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewClient;