import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col, UncontrolledAlert } from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMenusOptions } from "../../store/menu/actions";
import { useHistory } from "react-router-dom";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import MegaMenu from "../MegaMenu/MegaMenu";
import logoSm from "../../assets/images/logo-sm.svg";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-dark.png";
// import Select from "react-select";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Select from "react-select";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType, getAllModules } from "../../store/actions";
import "./cust-style.scss";
import { minWidth } from "@mui/system";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [askForToken, setAskForToken] = useState("none");
  const [socialDrp, setsocialDrp] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [modules, setModules] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dispatch = useDispatch();
  const history = useHistory();
  const { privilegeModules } = useSelector((state) => state.Privilages);
  const [filteredData, setFilteredData] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const customStyles = {
    groupHeading: {
      color: "#1a71b5",
      fontWeight: "bold",
      fontSize: "12px",
    },
  };
  const formatGroupLabel = (data) => <div style={customStyles.groupHeading}>{data.label}</div>;

  const handleSelectSearch = (e) => {
    setFilteredData([]);
    //setKeyWord(e);
    if (e.status == "1") {
      history.push("/project/" + e.permalink);
      localStorage.setItem("defaultModule", "62ecd2df8242de0ee4dc469e");
      localStorage.setItem("module_redirect", "/project-dashboard");
      localStorage.setItem("module_name", "Project");
      if (localStorage.getItem("authUser")) {
        var data = localStorage.getItem("authUser");
        var user_obj = JSON.parse(data);
        dispatch(getMenusOptions(user_obj._id, "62ecd2df8242de0ee4dc469e"));
        $(".module_box").attr("style", "background-color:none");
        $("#moduleProject").attr("style", "background-color:#dddced");
      }
    } else {
      history.push("/view-clients/" + e.permalink);

      localStorage.setItem("defaultModule", "62e25228eaf3463da3c2906e");
      localStorage.setItem("module_redirect", "/dashboard");
      localStorage.setItem("module_name", "CRM");
      if (localStorage.getItem("authUser")) {
        var data = localStorage.getItem("authUser");
        var user_obj = JSON.parse(data);
        dispatch(getMenusOptions(user_obj._id, "62e25228eaf3463da3c2906e"));
        $(".module_box").attr("style", "background-color:none");
        $("#moduleCRM").attr("style", "background-color:#dddced");
      }
    }
  };
  const handleSearchChange = (e) => {
    setFilteredData([]);
    if (e.length >= 3) {
      axios
        .post(
          API_URL + "search",
          { keyword: e },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          var data = res.data.data;
          var list = [
            {
              label: "Clients",
              options: [],
            },
            {
              label: "Projects",
              options: [],
            },
          ];
          data &&
            data.map((item, index) => {
              if (item.status == "1")
                list[1].options.push({ label: item.name + "(" + item.shortcode + ")", value: item._id, permalink: item.unique_id, status: item.status });
              else {
                if (item?.mobile) {
                  list[0].options.push({ label: item.name + ", " + item.mobile, value: item._id, permalink: item.unique_id, status: item.status });
                } else {
                  list[0].options.push({ label: item.name, value: item._id, permalink: item.unique_id, status: item.status });
                }
              }
            });
          setFilteredData(list);
        });
    }
  };

  useEffect(() => {
    checkCurrentToken();
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      dispatch(getAllModules(user_obj?.privilage));
    }
  }, []);

  useEffect(() => {
    let data = privilegeModules[0]?.alloted_modules;
    setModules(data);
  }, [privilegeModules]);

  const checkCurrentToken = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    axios
      .post(
        `${API_URL}user/get_current_token`,
        { user_id: user_obj._id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var tken = res.data.data.browserToken;
        if (tken != localStorage.getItem("browserToken")) {
          localStorage.setItem("mismatchBrowserToken", true);
          setAskForToken("block");
        } else {
          localStorage.setItem("mismatchBrowserToken", false);
          setAskForToken("none");
        }
      });
  };

  const enableNotify = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    axios
      .post(
        `${API_URL}user/enable_token`,
        { user_id: user_obj._id, browser_token: localStorage.getItem("browserToken") },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        user_obj.browserToken = localStorage.getItem("browserToken");
        localStorage.setItem("authUser", JSON.stringify(user_obj));
        setAskForToken("none");
      });
  };

  function toggleFullscreen() {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    if (!isMobile) {
      var body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  function toggleMegaMenu() {
    setShowMegaMenu(!showMegaMenu);
  }
  function handleModule(id, redirect_url, div_id, name) {
    localStorage.setItem("defaultModule", id);
    localStorage.setItem("module_redirect", redirect_url);
    localStorage.setItem("module_name", name);
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      dispatch(getMenusOptions(user_obj._id, id));
      history.push(redirect_url);
      $(".module_box").attr("style", "background-color:none");
      $("#" + div_id).attr("style", "background-color:#dddced");
    }
  }
  return (
    <React.Fragment>
      {showMegaMenu ? <MegaMenu /> : null}
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>

            <Form className="app-search d-none d-lg-block" style={{ minWidth: "23rem" }}>
              <div className="position-relative" style={{ paddingLeft: "8px" }}>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search Client name/Client mobile/Projects")}
                  value={keyWord}
                  onChange={handleSearchChange}
                /> */}
                <Select
                  menuPosition="fixed"
                  placeholder="Search Client name, Client mobile, Projects"
                  id="main-search"
                  name="subcategory"
                  value={keyWord}
                  onInputChange={(selected) => {
                    handleSearchChange(selected);
                  }}
                  onChange={handleSelectSearch}
                  options={filteredData}
                  formatGroupLabel={formatGroupLabel}
                  classNamePrefix="select2-selection"
                  noOptionsMessage={() => null}
                />
                <span className="uil-search"></span>
              </div>
            </Form>

            <UncontrolledAlert
              color="success"
              className="alert-dismissible fade show"
              role="alert"
              style={{ width: "338px", margin: "14px", display: askForToken }}>
              <i class="uil uil-exclamation-octagon me-2"></i>{" "}
              <strong style={{ cursor: "pointer" }} onClick={() => enableNotify()}>
                Click here{" "}
              </strong>
              to enable desktop notifications
            </UncontrolledAlert>
          </div>

          <div className="d-flex">
            <a
              href={API_URL + `uploads/app/srv-staff-app.zip`}
              target="_blank"
              className="btn btn-sm"
              style={{
                height: "36px",
                width: "110px",
                marginTop: "17px",
                backgroundColor: "#1a7ee0",
                border: "none",
                color: "white",
                paddingTop: "10px",
                cursor: "pointer",
                fontSize: "12px",
              }}>
              <i class="fa fa-download"></i> Download
            </a>
            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search);
              }}
              type="button">
              <DropdownToggle className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" tag="button">
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}>
              {modules && modules.length > 0 && (
                <DropdownToggle className="btn header-item noti-icon waves-effect" tag="button">
                  <i className="uil-apps"></i>
                </DropdownToggle>
              )}

              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" right>
                <div className="px-lg-2">
                  <Row className="g-0">
                    {modules &&
                      modules.map((el, index) => {
                        let color = "";
                        if (localStorage.getItem("defaultModule") == el._id) {
                          color = "#dddced";
                        }
                        return (
                          <Col md="4" id={"module" + el?.moduleName} className="module_box" style={{ backgroundColor: color }}>
                            <Link
                              className="dropdown-icon-item"
                              to="#"
                              onClick={() => handleModule(el._id, el.redirect_url, "module" + el?.moduleName, el?.moduleName)}>
                              <img src={API_URL + "uploads/module_images/" + el.icon} alt="" />
                              <span>{el?.moduleName}</span>
                            </Link>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen">
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            <NotificationDropdown />

            {/* <div
              // onClick={() => {
              //   props.showRightSidebarAction(!props.showRightSidebar);
              // }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="uil-cog"></i>
              </button>
            </div> */}

            <ProfileMenu />

            {/* <div
            className="btn header-item noti-icon right-bar-toggle waves-effect"
            onClick={logout}
            style={{ cursor: "pointer",paddingTop: "25px"}}
          >
           <i
                  class="fas fa-power-off"
                  style={{
                    fontSize: "1.2em",
                  }}
                ></i>
          </div> */}
            <Link to="/logout">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i
                  className="fas fa-power-off"
                  style={{
                    fontSize: "1.2em",
                  }}></i>
              </button>
            </Link>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
