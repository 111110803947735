import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./ViewStaff.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import { withRouter, useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { borderColor } from "@mui/system";

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [manageStaffObject, setManageStaffObject] = useState({});
  const [manageStaffIdTobeUpdated, setManageStaffIdToBeUpdated] =
    useState(null);
  const [manageStaffIdToBeDeleted, setManageStaffIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [manageStaffForTable, setManageStaffForTable] = useState([]);
  const [manageStaffValue, setManageStaffValue] = useState("");

  const [selectedBranch, setSelectedBranch] = useState(null);

  const [selectedDesig, setSelectedDesig] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  let history = useHistory();

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Branch":
        setSelectedBranch(selected);
        setManageStaffObject({
          ...manageStaffObject,
          branch: selected.label,
        });
        break;
      case "Designation":
        setSelectedDesig(selected);
        setManageStaffObject({
          ...manageStaffObject,
          desig: selected.label,
        });
        break;
      case "Status":
        setSelectedStatus(selected);
        setManageStaffObject({
          ...manageStaffObject,
          status: selected.label,
        });
        break;
      default:
        break;
    }
  };

  let closeModal = () => {
    setShowModal(false);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "staff_id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch",
        field: "staff_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "staff_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Designation",
        field: "staff_desig",
        sort: "asc",
        width: 150,
      },
      {
        label: "Join",
        field: "join_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email_1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mob_1",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        staff_id: "ADMIN",
        name: "SRV IT Hub Private Limited",
        staff_type: "SRV",
        staff_type: "Admin",
        staff_desig: "Creative designer	",
        join_date: "11-10-2008",
        status: "Active",
        email_1: "abc@srvifotech.in",
        mob_1: "8587456985",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/view_manage_staff/" + "ViewStaffid");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        staff_id: "SRV003",
        name: "SRV IT Hub Private Limited",
        staff_type: "SRV",
        staff_type: "Admin",
        staff_desig: "Creative designer	",
        join_date: "11-10-2008",
        status: "Active",
        email_1: "pqr@srvifotech.in",
        mob_1: "8587456985",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/view_manage_staff/" + "ViewStaffid");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "3",
        staff_id: "SRV008S",
        name: "Nideesh",
        staff_type: "SRV",
        staff_type: "Admin",
        staff_desig: "Web Designer",
        join_date: "11-10-2008",
        status: "Active",
        email_1: "Nideesh@srvifotech.in",
        mob_1: "8587456985",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/view_manage_staff/" + "ViewStaffid");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "4",
        staff_id: "SRV010",
        name: "Preeshna",
        staff_type: "SRV",
        staff_type: "Admin",
        staff_desig: "Office Administrator	",
        join_date: "11-10-2008",
        status: "Active",
        email_1: "Preeshna@srvifotech.in",
        mob_1: "8587456985",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/view_manage_staff/" + "ViewStaffid");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "5",
        staff_id: "SRV0131",
        name: "Binoy",
        staff_type: "SRV",
        staff_type: "Admin",
        staff_desig: "Marketing Executive",
        join_date: "11-10-2008",
        status: "Active",
        email_1: "Binoy@srvifotech.in",
        mob_1: "8587456985",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/view_manage_staff/" + "ViewStaffid");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available(days)",
        field: "ALeaves",
        sort: "asc",
        width: 270,
      },
    ],
    rows1: [
      {
        id: "1",
        name: "Anitha",
        Salarytype: "",
      },
      {
        id: "1",
        LType: "Sick Leave",

        ALeaves: "12",
      },
      {
        id: "2",
        LType: "Casual Leave",

        ALeaves: "5",
      },
    ],
  };

  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      options: [
        { label: "Admin", value: "admin" },
        { label: "Cashier", value: "cashier" },
        { label: "Receptionist", value: "receptionist" },
        { label: "Hr", value: "hr" },
        { label: "Pr", value: "pr" },
      ],
    },
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };

  const decreamentCount = () => {
    setCount1(count1 - 1);
  };

  const decreamentCount1 = () => {
    setCount2(count2 - 1);
  };

  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const customStyles = {
    lineHeight: "1.8",
  };
  const textAreaStyles = {
    borderColor: "#c0c0c099",
  };
  return (
    <React.Fragment>
      {/* Open Modal */}
      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          // onValidSubmit={() => {
          //   handleValidSubmitPassword();
          // }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    // value={passwordObject.password}
                    // onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    // value={passwordObject.confirmPassword}
                    // onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: "1rem" }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      {/* Close Modal */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View/Manage Staff" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Staff Name</Label>

                          <AvField
                            style={customStyles}
                            name="staff_name"
                            placeholder="Staff Name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Staff ID</Label>

                          <AvField
                            style={customStyles}
                            name="staff_id"
                            placeholder="Staff ID"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff Branch</Label>
                          <Select
                            name="Branch"
                            value={selectedBranch}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Branch");
                            }}
                            options={[
                              { label: "SRV IT Hub Private Limited", value: 0 },
                              { label: "SRV IT Hub", value: 1 },
                              { label: "SRV IT Hub Private Limited", value: 2 },
                              { label: "myKit E-commerce Pvt Ltd", value: 3 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff Designation</Label>
                          <Select
                            name="Designation"
                            value={selectedDesig}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Designation");
                            }}
                            options={[
                              { label: "Sr. PHP Developer", value: 0 },
                              { label: "Jr. PHP Developer", value: 1 },
                              { label: "Managing Director", value: 2 },
                              { label: "Sr.Creative Designer", value: 3 },
                              { label: "Web Designer", value: 4 },
                              { label: "Creative designer", value: 5 },
                              { label: "Marketing Executive", value: 6 },
                              { label: "Receptionist", value: 7 },
                              { label: "Office Administrator", value: 8 },
                              { label: "Project Manager", value: 9 },
                              { label: "QA", value: 10 },
                              { label: "SEO Analyst", value: 11 },
                              {
                                label: "Android Developer Trainee",
                                value: 12,
                              },
                              { label: "Operations Manager", value: 13 },
                              { label: "Tech Support", value: 14 },
                              {
                                label: "Creative Designer Trainee",
                                value: 15,
                              },
                              { label: "Android Developer", value: 16 },
                              { label: "Director", value: 17 },
                              { label: "General Manager", value: 18 },
                              { label: "iOS Developer Trainee", value: 19 },
                              { label: "SEO Analyst Trainee", value: 20 },
                              {
                                label: "Digital Marketing Manager",
                                value: 21,
                              },
                              {
                                label: "Digital Marketing trainee",
                                value: 22,
                              },
                              { label: "Video graphics editor", value: 23 },
                              { label: "Flutter developer", value: 24 },
                              {
                                label: "Flutter developer trainee",
                                value: 24,
                              },
                              { label: "Dot Net developer", value: 25 },
                              { label: "Node developer", value: 26 },
                              { label: "Reactjs developer", value: 27 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Staff Status</Label>
                          <Select
                            name="Status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Status");
                            }}
                            options={[
                              { label: "Active", value: 0 },
                              { label: "DeActive", value: 1 },
                              { label: "Resigned", value: 2 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                          >
                            {/* <Save></Save> */}
                            {"  "}
                            Filter
                          </Button>
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="viewStaffTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Staff Salary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack direction="row" spacing={2} style={{ flexDirection: "row" }}>
              <div style={{ padding: "20px" }}>
                <b>Staff:</b>
                <p>Mythree</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Salary Type:</b>
                <p>Monthly</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Basic Salary : </b>
                <p>100000</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Cost : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Rate : </b>
                <p>300</p>
              </div>

              <div style={{ padding: "20px" }}>
                <b>Hourly Salary: </b>
                <p>300</p>
              </div>
            </Stack>
            {/* Modal Body Start */}
            <MDBDataTable responsive bordered data={data1} />
            {/* Modal Body End */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseModal}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
