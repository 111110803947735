import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./ManageTechnology.scss";
import { Grid } from "@mui/material";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const Designation = () => {
  const [techObject, setTechObject] = useState({});
  const [techIdTobeUpdated, setTechIdToBeUpdated] = useState(null);
  const [techIdToBeDeleted, setTechIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [techForTable, setTechForTable] = useState([]);
  const [techValue, settechValue] = useState("");
  const [uploadProgress, setUploadProgress] = useState();

  const {
    districts,
    addingtech,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}technology/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let techData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTech(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setTechIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.from = moment(item.from).format("YYYY-MM-DD");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }
          if(typeof item.icon!="undefined" && item.icon!="")
          {
            item.Img = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/technology/${item.icon}`}
                    style={{ height:"25px"}}
                  />
                </div>
              );
          }
          else
          {
            item.Img = "";
          }
          

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          techData.push(item);
        });
        setTechForTable(techData);
      });
  }

  let preUpdateTech = (item) => {
    console.log("itemupdate", item);
    // item.from = moment(item?.from).format("YYYY-MM-DD");
    settechValue(item);
    setTechIdToBeUpdated(item?._id);
    setTechObject(item);
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("icon", e.target.files[0]);
    axios
      .post(`${API_URL}technology/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setTechObject({
            ...techObject,
            [`icon`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteTechImage = () => {
    setTechObject({
      ...techObject,
      [`icon`]: "",
    });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "icon",
        field: "Img",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: techForTable,
  };

  function handleChangeTech(e) {
    let name = e.target.name;
    let value = e.target.value;
    settechValue(value);

    setTechObject({ ...techObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (techIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}technology`, techObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Technology updated successfully");
            settechValue("");

            formRef.current.reset();
            handleTableData();
            setTechObject({});
            setTechIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Technology");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}technology`, techObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Technology added succesfully");
            handleTableData();
            settechValue("");

            formRef.current.reset();
            setTechObject({});
          } else {
            toastr.error("Failed to add Technology");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}technology` + "/" + techIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Technology Deleted successfully");
                    if (techObject && techObject._id === techIdToBeDeleted) {
                      settechValue("");

                      formRef.current.reset();
                      setTechObject({});
                      setTechIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Technology"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Technology" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Technology Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={techObject?.name}
                              onChange={handleChangeTech}
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">From</Label>
                            <input
                              name="from"
                              className="form-control"
                              type="date"
                              id="from_date"
                              value={giftValue?.from}
                              onChange={handleChangeGift}
                            />
                          </div>
                        </Col> */}

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Icon</Label>
                            {techObject?.icon ? (
                              <div div className="img-wraps">
                                {techObject.icon &&
                                techObject.icon.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${techObject.icon}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}uploads/technology/${techObject?.icon}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteTechImage}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                            )}
                            {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                            {/* <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master.cust_image ? errors.imageError : ""}
                          </p> */}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {techIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingtech ? true : false}
                              >
                                {addingtech ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingtech ? true : false}
                              >
                                {addingtech ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="TechTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Designation;