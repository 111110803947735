import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./People.scss";
import { Checkbox, Grid } from "@mui/material";

import accessToken from "../../helpers/jwt-token-access/accessToken";

import moment from "moment";

import { withRouter, useHistory } from "react-router-dom";

const People = () => {
  const [peopleObject, setPeopleObject] = useState({});
  const [peopleIdTobeUpdated, setPeopleIdToBeUpdated] = useState(null);
  const [peopleIdToBeDeleted, setPeopleIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [peopleForTable, setPeopleForTable] = useState([]);
  const [peopleValue, setpeopleValue] = useState("");

  const [admin, setAdmin] = useState(false);
  const [billing, setBilling] = useState(false);
  const [support, setSupport] = useState(false);

  const [pjl, setPjl] = useState([]);

  let history = useHistory();

  const {
    districts,
    addingPeople,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}people/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let peopleData = [];
        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   history.push("/add_staff/" + item._id);
                // }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdatePeople(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setPeopleIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          peopleData.push(item);
        });
        setPeopleForTable(peopleData);
      });
  }

  let preUpdatePeople = (item) => {
    formRef.current.reset();
    setAdmin(false);
    setBilling(false);
    setSupport(false);
    setPeopleIdToBeUpdated(item._id);
    setpeopleValue(item?.name);
    axios
      .get(`${API_URL}people?contact_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        console.log("Contact<>", result);
        let Admin = 0;
        let Billing = 0;
        let Support = 0;
        result.preferences &&
          result.preferences.map((cont) => {
            console.log("data<>", cont);
            if (cont == "Admin") {
              Admin = 1;
            }
            if (cont == "Billing") {
              Billing = 1;
            }
            if (cont == "Support") {
              Support = 1;
            }
          });
        if (Admin === 1) {
          setAdmin(true);
        }
        if (Billing === 1) {
          setBilling(true);
        }
        if (Support === 1) {
          setSupport(true);
        }
        setPjl(item.preferences);
        setPeopleObject(item);
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Contact ID",
        field: "contact_id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: peopleForTable,
  };

  function handleChangePeople(e) {
    let name = e.target.name;
    let value = e.target.value;
    setpeopleValue(value);
    setPeopleObject({ ...peopleObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (peopleIdTobeUpdated) {
      axios
        .put(`${API_URL}people`, peopleObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Designation updated successfully");
            setpeopleValue("");
            formRef.current.reset();
            handleTableData();
            setPeopleObject({});
            setPjl([]);
            setAdmin(false);
            setBilling(false);
            setSupport(false);
            setPeopleIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Designation");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}people`, peopleObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Designation added succesfully");
            handleTableData();
            setpeopleValue("");
            formRef.current.reset();
            setPeopleObject({});
            setPjl([]);
            // debugger;
            setAdmin(false);
            setBilling(false);
            setSupport(false);
          } else {
            toastr.error("Failed to add Designation");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const handleCheckboxChange = (e) => {
    let data = pjl;
    if (admin === true) {
      setAdmin(false);
      data.pop(e.target.value);
    } else {
      setAdmin(true);
      data.push(e.target.value);
    }

    // data.push(e.target.value);
    setPeopleObject({
      ...peopleObject,
      ["preferences"]: data,
    });
  };

  const handleCheckboxChange1 = (e) => {
    let data = pjl;
    if (billing === true) {
      setBilling(false);
      data.pop(e.target.value);
    } else {
      setBilling(true);
      data.push(e.target.value);
    }

    setPeopleObject({
      ...peopleObject,
      ["preferences"]: data,
    });
  };

  const handleCheckboxChange2 = (e) => {
    let data = pjl;
    if (support === true) {
      setSupport(false);
      data.pop(e.target.value);
    } else {
      setSupport(true);
      data.push(e.target.value);
    }

    setPeopleObject({
      ...peopleObject,
      ["preferences"]: data,
    });
  };

  const reset = () => {
    formRef.current.reset();
    setAdmin(false);
    setBilling(false);
    setSupport(false);
  };
  const customStyles = {
    lineHeight: "1.8",
  };
  function Dog(name) {
    this.name = name;
  }
  const dog1 = new Dog("Gabby");
  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}people` + "/" + peopleIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Designation deleted successfully");
                    if (
                      peopleObject &&
                      peopleObject._id === peopleIdToBeDeleted
                    ) {
                      setpeopleValue("");
                      formRef.current.reset();
                      setPeopleObject({});
                      setPeopleIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Designation"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add People" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Name</Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={peopleObject?.name}
                              onChange={handleChangePeople}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Designation
                            </Label>
                            <AvField
                              style={customStyles}
                              name="designation"
                              placeholder="Designation"
                              type="text"
                              className="form-control"
                              // errorMessage="Enter Designation"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={peopleObject?.designation}
                              onChange={handleChangePeople}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Mobile</Label>
                            <AvField
                              style={customStyles}
                              name="mobile"
                              placeholder="Mobile"
                              type="tel"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Mobile number",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage: "Enter valid 10 digit number",
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage: "Enter valid 10 digit number",
                                },
                              }}
                              id="validationCustom01"
                              value={peopleObject?.mobile}
                              onChange={handleChangePeople}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Land Line
                            </Label>
                            <AvField
                              style={customStyles}
                              name="landline"
                              placeholder="Land Line No."
                              type="phone"
                              minLength={11}
                              maxLength={11}
                              className="form-control"
                              // errorMessage="Enter valid 11 digit number"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={peopleObject?.landline}
                              onChange={handleChangePeople}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Email</Label>
                            <AvField
                              style={customStyles}
                              name="email"
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              // errorMessage="Enter Email"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={peopleObject?.email}
                              onChange={handleChangePeople}
                            />
                          </div>
                        </Col>

                        <Col md="1">
                          <div className="mt-3" id="people-pref">
                            <Label
                              htmlFor="validationCustom03"
                              style={{ marginRight: "5%" }}
                            >
                              Admin
                            </Label>
                            <input
                              type="checkbox"
                              className="mb-2"
                              name="admin"
                              value="Admin"
                              id="admin"
                              checked={admin}
                              control={<Checkbox />}
                              onClick={(e) => handleCheckboxChange(e)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="mt-3" id="people-pref">
                            <Label
                              htmlFor="validationCustom03"
                              style={{ marginRight: "5%" }}
                            >
                              Billing
                            </Label>
                            <input
                              type="checkbox"
                              className="mb-2"
                              name="billing"
                              id="billing"
                              value="Billing"
                              checked={billing}
                              control={<Checkbox />}
                              onClick={(e) => handleCheckboxChange1(e)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="mt-3" id="people-pref">
                            <Label
                              htmlFor="validationCustom03"
                              style={{ marginRight: "5%" }}
                            >
                              Support
                            </Label>
                            <input
                              type="checkbox"
                              className="mb-2"
                              name="support"
                              value="Support"
                              id="support"
                              checked={support}
                              control={<Checkbox />}
                              onClick={(e) => handleCheckboxChange2(e)}
                              style={{
                                cursor: "pointer",
                                marginRight: "-12%",
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {peopleIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingPeople ? true : false}
                              >
                                {addingPeople ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingPeople ? true : false}
                              >
                                {addingPeople ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="AddPeopleTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default People;
