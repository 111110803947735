import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { userForgetPassword } from "../../store/actions";
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-dark.png";
import toastr from "toastr";
// import accessToken from "../../helpers/jwt-token-access/accessToken";
// import axios from "axios";
const VerifyOTP = (props) => {
  const { userId } = props.location.state;
  const { otp } = props.location.state;
  const { mobile } = props.location.state;
  const [master, setMaster] = useState({});
  const [data, setData] = useState({
    userId: userId,
    otp: otp,
    mobile: mobile,
  });
  //   const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const history = useHistory();
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    if (master?.otp === data?.otp) {
      history.push("/reset-password", { userId: data?.userId });
    } else {
      toastr.error("OTP is incorrect");
      return;
    }
  }
  const handleInputChange = (e) => {
    setMaster({
      [e.target.name]: Number(e.target.value),
    });
  };
  //   const resendOTP = () => {
  //     let values = {
  //       mobile: data?.mobile,
  //       app_signature: "",
  //     };
  //     axios
  //       .post(`${API_URL}api/staff/login`, values, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         let result = res.data;
  //         if (res.data.success === true) {
  //           toastr.success("Otp Send Successfully");
  //           setData({
  //             ...data,
  //             otp: result?.otp,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         toastr.error(err.response.data.message);
  //         return;
  //       });
  //   };
  return (
    <React.Fragment>
      <div className="account-pages-container">
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            {/* <i className="mdi mdi-home-variant h2"></i> */}
          </Link>
        </div>

        <div className="account-pages my-5  pt-sm-5">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div>
                  <Card>
                    <CardBody className="p-4">
                      <div className="row">
                        <a href="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="22"
                            className="logo logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="22"
                            className="logo logo-light"
                          />
                        </a>
                      </div>
                      <div className="text-center mt-2">
                        <h5 className="text-primary">OTP Verification</h5>
                        <p className="text-muted">
                          Enter the OTP sent to +91{data?.mobile}
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        >
                          <div className="mb-3">
                            <AvField
                              name="otp"
                              className="form-control"
                              placeholder=""
                              type="number"
                              errorMessage="Please enter OTP"
                              validate={{ required: { value: true } }}
                              id="validationCustom04"
                              value={master?.otp}
                              onChange={handleInputChange}
                            />
                          </div>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify
                              </button>
                            </Col>
                          </Row>
                          {/* <div className="mt-4 text-center">
                            <p className="mb-0">
                              Dont receive the OTP?{" "}
                              <Link
                                onClick={() => resendOTP()}
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Resend OTP{" "}
                              </Link>
                            </p>
                          </div> */}
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-3 text-center text-dark">
                  <p>
                    © {new Date().getFullYear()} <a href="https://srvinfotech.com" target="_blank"> SRV IT Hub Private Limited. </a>All Rights Reserved.
                  </p>
                </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(VerifyOTP)
);
