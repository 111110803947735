import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import {
  getAllFundSource,
  addFundTransfer,
  getAllFundTransfer,
} from "../../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./style.scss";
const Fund = (props) => {
  const [fundTransferList, setFundTransferList] = useState([]);
  const [master, setMaster] = useState({
    localbody_id: getLocalbody(),
  });
  const [selectedFundSourceFrom, setSelectedFundSourceFrom] = useState(null);
  const [selectedFundSourceTo, setSelectedFundSourceTo] = useState(null);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [userId, setUserId] = useState(1);
  const {
    fundTransfers,
    addingFundTransfer,
    addFundTransferResponse,
    fundSource,
    error,
  } = useSelector((state) => state.fundTransfers);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    dispatch(getAllFundSource(currentLocalbody));
    dispatch(getAllFundTransfer(currentLocalbody));
  }, [currentLocalbody]);

  useEffect(() => {
    console.log("fundTransfers: ", fundTransfers);
    if (addFundTransferResponse.type === "success") {
      toastr.success(addFundTransferResponse.message);
      dispatch(getAllFundTransfer(currentLocalbody));
    } else if (addFundTransferResponse.type === "failure") {
      toastr.error(error.data.message, addFundTransferResponse.message);
    }
  }, [addFundTransferResponse]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name + " - " + item.acc_type_id.acc_type_name,
          value: item._id,
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  useEffect(() => {
    let fundTransferData = JSON.parse(JSON.stringify(fundTransfers));

    let data = [];
    fundTransferData.map((item, index) => {
      item.x_User = item.fname;
      item.fname = "";
      if (item.fund_transfer_added_by) {
        item.fname = item.fund_transfer_added_by.username;
      }
      item.auth_userid = userId;
      item.id = index + 1;
      item.fund_transfer_amount = parseFloat(item.fund_transfer_amount).toFixed(
        2
      );
      item.found_from_name = "";
      item.found_to_name = "";
      if (item.fund_transfer_from) {
        item.found_from_name = item.fund_transfer_from.acc_chart_name;
      }
      if (item.fund_transfer_to) {
        item.found_to_name = item.fund_transfer_to.acc_chart_name;
      }
      if (item.fund_transfer_approve_status === 0) {
        item.status = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                updateApproveStatus(item, 1);
              }}
            >
              Pending
            </Button>
          </div>
        );
      } else if (item.fund_transfer_approve_status === 1) {
        item.status = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="success" size="sm" disabled>
              Approved
            </Button>
          </div>
        );
      } else if (item.fund_transfer_approve_status === 2) {
        item.status = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="danger" size="sm" disabled>
              Rejected
            </Button>
          </div>
        );
      }

      data.push(item);
    });
    setFundTransferList(data);
  }, [fundTransfers]);

  const updateApproveStatus = (item, status) => {
    let user_id = "";

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      user_id = obj._id;
    }

    let data = {
      user_id: user_id,
      localbody_id: currentLocalbody,
      status: status,
      id: item?._id,
    };
    axios
      .post(`${API_URL}api/staff/accept_fund_transfer`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success(res.data.message);
          dispatch(getAllFundTransfer(currentLocalbody));
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      ["auth_userid"]: userId,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedFundSource = (selected, name) => {
    switch (name) {
      case "from":
        setSelectedFundSourceFrom(selected);
        setMaster({
          ...master,
          ["fund_transfer_from"]: selected.value,
        });
        break;
      case "to":
        setSelectedFundSourceTo(selected);
        setMaster({
          ...master,
          ["fund_transfer_to"]: selected.value,
        });
        break;
      default:
        break;
    }
  };
  const handleValidSubmit = (e, v) => {
    if (selectedFundSourceFrom == null) {
      toastr.error("From fund source is required");
      return;
    }
    if (selectedFundSourceTo == null) {
      toastr.error("To fund source is required");
      return;
    }

    axios
      .post(`${API_URL}fund_transfer`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Fund transfer added successfully");
          formRef.current.reset();
          setSelectedFundSourceFrom(null);
          setSelectedFundSourceTo(null);
          setMaster({
            ["auth_userid"]: userId,
            ["localbody_id"]: getLocalbody(),
          });
          dispatch(getAllFundTransfer(currentLocalbody));
        } else {
          toastr.error("Failed to add fund transfer");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
    //dispatch(addFundTransfer(master));

    // formRef.current.reset();
    // setSelectedFundSourceFrom(null);
    // setSelectedFundSourceTo(null);
    // setMaster({ ["auth_userid"]: userId, ["localbody_id"]: getLocalbody() });
  };
  const reset = () => {
    formRef.current.reset();
    setMaster({ ["auth_userid"]: userId, ["localbody_id"]: getLocalbody() });
    setSelectedFundSourceFrom(null);
    setSelectedFundSourceTo(null);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reference ID",
        field: "fund_transfer_unqid",
        sort: "asc",
        width: 400,
      },
      {
        label: "Transfer From",
        field: "found_from_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Transfer To",
        field: "found_to_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "fund_transfer_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comment",
        field: "fund_transfer_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: fundTransferList,
    // rows:[{
    //   id:1,
    //   fund_transfer_unqid:"F253665844D",
    //   found_from_name:"Admin-bank",
    //   found_to_name:"Srv-bank",
    //   fund_transfer_amount:"25,000",
    //   fund_transfer_comment:"personal",
    //   fname:"admin"
    // },
    // {
    //   id:2,
    //   fund_transfer_unqid:"F245888568K",
    //   found_from_name:"Admin-bank",
    //   found_to_name:"GeethaP-bank",
    //   fund_transfer_amount:"50,000",
    //   fund_transfer_comment:"personal",
    //   fname:"admin"
    // }]
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fund Transfer" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Fund Source</Label>
                          <Select
                            name="From_Fund_Source"
                            value={selectedFundSourceFrom}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "from");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Fund Source</Label>
                          <Select
                            name="To_Fund_Source"
                            value={selectedFundSourceTo}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "to");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="fund_transfer_amount"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            min="0"
                            value={master?.fund_transfer_amount}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="fund_transfer_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingFundTransfer ? true : false}
                          >
                            {addingFundTransfer ? "Adding" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="table_fund_transfer"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {};

export default Fund;

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
