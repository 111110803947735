import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./Leads.scss";
import { Stack } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import $ from "jquery";
import { getDate, getFirstday } from "../../helpers/globalFunctions";

import accessToken from "../../helpers/jwt-token-access/accessToken";
import { isNull } from "lodash";

const Leads = () => {
  const [leadsObject, setLeadsObject] = useState({});
  const [leadsIdTobeUpdated, setLeadsIdToBeUpdated] = useState(null);
  const [leadsIdToBeDeleted, setLeadsIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [leadsForTable, setLeadsForTable] = useState([]);
  const [leadsValue, setLeadsValue] = useState("");

  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});

  const [mode, setMode] = useState([]);
  const [selectedMode, setSelectedMode] = useState(null);

  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [timePeriod, setTimePeriod] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);

  const [feedback, setFeedBack] = useState([]);
  const [selectedFeedBack, setSelectedFeedBack] = useState(null);

  const [learnBy, setLearnBy] = useState([]);
  const [selectedLearnBy, setSelectedLearnBy] = useState(null);

  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(null);

  const [leadFor, setLeadFor] = useState([]);
  const [selectedLeadFor, setSelectedLeadFor] = useState(null);

  const [searchData, setSearchData] = useState({});

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableListData(date1, date2);
  };

  const {
    districts,
    addingLeads,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableListData();
    fetch_all_Countries();
    fetch_all_Modes();
    fetch_all_Status();
    fetch_all_TimePeriod();
    fetch_all_Feedback();
    fetch_all_LearnBy();
    fetch_all_Lead();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData() {
    var url = `${API_URL}leads/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let LeadsData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateLeads(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setLeadsIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.up_date && item?.up_time) {
            item.date =
              moment(item.up_date).format("DD-MM-YYYY") +
              "  " +
              moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.date =
              moment(item.date).format("DD-MM-YYYY") +
              "  " +
              moment(item.time, "HHmmss").format("hh:mm a");
          }

          // if (item?.up_date) {
          //   item.date = moment(item.up_date).format("DD-MM-YYYY");
          // } else {
          //   item.date = moment(item.date).format("DD-MM-YYYY");
          // }

          // if (item?.up_time) {
          //   item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          // } else {
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // }

          if (item.status === 0) {
            item.Status = "Cancelled";
          } else if (item.status === 1) {
            item.Status = "Customer";
          } else if (item.status === 2) {
            item.Status = "Follow Up";
          } else if (item.status === 3) {
            item.Status = "New";
          } else if (item.status === 4) {
            item.Status = "Processing";
          } else if (item.status === 5) {
            item.Status = "Quated";
          } else {
            item.Status = "";
          }

          let LeadsValue = item?.leads_for || [];

          item.LeadsForTbl = "";
          LeadsValue &&
            LeadsValue.map((el) => {
              if (item.LeadsForTbl === "") item.LeadsForTbl = el?.name;
              else item.LeadsForTbl = item.LeadsForTbl + "," + el?.name;
            });

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          LeadsData.push(item);
        });
        setLeadsForTable(LeadsData);
      });
  }

  function handleTableListData(
    comp_name = "",
    from_date = "",
    to_date = "",
    mobile_no = ""
  ) {
    var url = `${API_URL}leads/viewlist?`;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url =
      url +
      "&comp_name=" +
      comp_name +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&mobile_no=" +
      mobile_no;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        console.log("FilterData>>>", result);
        let LeadsData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateLeads(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setLeadsIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.up_date && item?.up_time) {
            item.date =
              moment(item.up_date).format("DD-MM-YYYY") +
              "  " +
              moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.date =
              moment(item.date).format("DD-MM-YYYY") +
              "  " +
              moment(item.time, "HHmmss").format("hh:mm a");
          }

          // if (item?.up_date) {
          //   item.date = moment(item.up_date).format("DD-MM-YYYY");
          // } else {
          //   item.date = moment(item.date).format("DD-MM-YYYY");
          // }

          // if (item?.up_time) {
          //   item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          // } else {
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // }

          if (item.status === 0) {
            item.Status = "Cancelled";
          } else if (item.status === 1) {
            item.Status = "Customer";
          } else if (item.status === 2) {
            item.Status = "Follow Up";
          } else if (item.status === 3) {
            item.Status = "New";
          } else if (item.status === 4) {
            item.Status = "Processing";
          } else if (item.status === 5) {
            item.Status = "Quated";
          } else {
            item.Status = "";
          }

          let LeadsValue = item?.leads_for || [];

          item.LeadsForTbl = "";
          LeadsValue &&
            LeadsValue.map((el) => {
              if (item.LeadsForTbl === "") item.LeadsForTbl = el?.name;
              else item.LeadsForTbl = item.LeadsForTbl + "," + el?.name;
            });

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          LeadsData.push(item);
        });
        setLeadsForTable(LeadsData);
      });
  }

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}leads/data?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result.date = moment(result.date).format("DD-MM-YYYY");

        if (result?.time)
          result.time = moment(result.time, "HHmmss").format("hh:mm a");
        else result.time = "";

        result.up_date = moment(result.up_date).format("DD-MM-YYYY");

        if (result?.up_time)
          result.up_time = moment(result.up_time, "HHmmss").format("hh:mm a");
        else result.up_time = "";

        let Modes = result?.mode;
        result.mode = Modes.name;

        let LeadsValue = item?.leads_for || [];
        result.LeadsForTbl = "";
        LeadsValue &&
          LeadsValue.map((el) => {
            if (result.LeadsForTbl === "") result.LeadsForTbl = el?.name;
            else result.LeadsForTbl = result.LeadsForTbl + "," + el?.name;
          });

        let addedBy = result.addedby || {};
        result.addedBy = addedBy?.firstName;

        let updatedBy = result.addedby || {};
        result.updatedBy = updatedBy?.firstName;

        setPopupData(result);
        setPopupView(true);
      });
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setLeadsObject({
      ...leadsObject,
      [e.target.name]: e.target.value,
    });
  };

  function fetch_all_Countries() {
    axios
      .get(`${API_URL}leads/Countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var CountryType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCountries([{ options: CountryType }]);
      });
  }

  function fetch_all_Modes() {
    axios
      .get(`${API_URL}leadsFor/Options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ModesType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setMode([{ options: ModesType }]);
      });
  }

  function fetch_all_Status() {
    axios
      .get(`${API_URL}leads/leadsStatus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var StatusType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStatus([{ options: StatusType }]);
      });
  }
  function fetch_all_TimePeriod() {
    axios
      .get(`${API_URL}leads/leadsTimeperiod`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var PeriodType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setTimePeriod([{ options: PeriodType }]);
      });
  }
  function fetch_all_Feedback() {
    axios
      .get(`${API_URL}leads/leadsFeedback`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var FeedbackType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setFeedBack([{ options: FeedbackType }]);
      });
  }

  function fetch_all_LearnBy() {
    axios
      .get(`${API_URL}leads/leadsLearnby`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var LearnbyType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setLearnBy([{ options: LearnbyType }]);
      });
  }

  function fetch_all_Lead() {
    axios
      .get(`${API_URL}item/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var LeadType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setLeadFor([{ options: LeadType }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Modes":
        setSelectedMode(selected);
        setLeadsObject({
          ...leadsObject,
          mode: selected.value,
        });
        break;

      case "Status":
        setSelectedStatus(selected);
        setLeadsObject({
          ...leadsObject,
          leads_status: selected.label,
        });
        break;
      case "TimePeriod":
        setSelectedTimePeriod(selected);
        setLeadsObject({
          ...leadsObject,
          time_period: selected.label,
        });
        break;
      case "FeedBack":
        setSelectedFeedBack(selected);
        setLeadsObject({
          ...leadsObject,
          feedback: selected.label,
        });
        break;
      case "LearnBy":
        setSelectedLearnBy(selected);
        setLeadsObject({
          ...leadsObject,
          learn_by: selected.label,
        });
        break;
      case "Country":
        setSelectedCountries(selected);
        setLeadsObject({
          ...leadsObject,
          country: selected.label,
        });
        break;
      case "Lead":
        setSelectedLeadFor(selected);
        let LeadArray = [];
        selected &&
          selected.forEach((element) => {
            LeadArray.push(element.value);
          });
        setLeadsObject({
          ...leadsObject,
          ["leads_for"]: LeadArray,
        });
        break;

      default:
        break;
    }
  };

  let preUpdateLeads = (item) => {
    setLeadsIdToBeUpdated(item._id);
    // setLeadsValue(item?.name);

    axios
      .get(`${API_URL}leads/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        console.log("Update Data", result);
        // let zone = result?.zone ? result.zone : {};
        // let ZoneValue = {
        //   label: zone?.zone_name,
        //   value: zone?._id,
        // };

        let CountryName = {
          label: result?.country,
          value: result?._id,
        };

        // let ModeValue = {
        //   label: result?.mode,
        //   value: result?._id,
        // };

        let StatusValue = {
          label: result?.leads_status,
          value: result?._id,
        };

        let TimePeriodValue = {
          label: result?.time_period,
          value: result?._id,
        };

        let FeedbackValue = {
          label: result?.feedback,
          value: result?._id,
        };

        let LearnValue = {
          label: result?.learn_by,
          value: result?._id,
        };

        // let Lead = result?.leads_for[0] || {};
        // let LeadsValue = {
        //   label: Lead?.name,
        //   value: Lead?._id,
        // };

        let Mode = result?.mode ? result.mode : {};
        let ModesValue = {
          label: Mode?.name,
          value: Mode?._id,
        };

        setSelectedMode(ModesValue);

        let LeadArray = [];
        result.leads_for &&
          result.leads_for.map((el) => {
            let val = {};
            val.label = el.name;
            val.value = el._id;
            LeadArray.push(val);
          });

        setSelectedLeadFor(LeadArray);
        setSelectedLearnBy(LearnValue);
        setSelectedFeedBack(FeedbackValue);
        setSelectedTimePeriod(TimePeriodValue);
        setSelectedStatus(StatusValue);
        // setSelectedMode(ModeValue);
        setSelectedCountries(CountryName);

        // setSelectedState(StateValue);
        // setSelectedZone(ZoneValue);
        setLeadsObject(result);
      });
  };

  function handleChangeLeads(e) {
    let name = e.target.name;
    let value = e.target.value;
    setLeadsValue(value);
    setLeadsObject({ ...leadsObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (leadsIdTobeUpdated) {
      axios
        .put(`${API_URL}leads`, leadsObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Leads updated successfully");
            formRef.current.reset();
            setLeadsIdToBeUpdated(null);
            formRef.current.reset();
            setLeadsValue("");

            handleTableData();
            handleClickOpenForm(data);
            setLeadsObject({
              ["follow_ups"]: "",
            });

            setSelectedLeadFor(null);
            // handleChangeDate(null);
            setSelectedCountries(null);
            setSelectedMode(null);
            setSelectedStatus(null);
            setSelectedTimePeriod(null);
            setSelectedFeedBack(null);
            setSelectedLearnBy(null);
            handleTableData();
          } else {
            toastr.error("Failed to update Leads");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}leads`, leadsObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Leads added Successfully");
            handleClickOpenForm(data);
            setLeadsValue("");
            formRef.current.reset();
            setLeadsObject({
              ["follow_ups"]: "",
            });
            setSelectedCountries(null);
            setSelectedMode(null);
            setSelectedStatus(null);
            setSelectedTimePeriod(null);
            setSelectedFeedBack(null);
            setSelectedLearnBy(null);
            setSelectedLeadFor(null);
            handleTableData();
          } else {
            toastr.error("Failed to add Leads");
            return;
          }
        })
        .catch((err) => {
          // toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date & Time",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Name",
        field: "full_name",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Company",
      //   field: "comp_name",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Mobile",
        field: "mobile_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },

      {
        label: "Leads",
        field: "LeadsForTbl",
        sort: "asc",
        width: 150,
      },

      // {
      //   label: "FollowUp",
      //   field: "follow_ups",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "leads_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: leadsForTable,
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available Leaves(days)",
        field: "ALeaves",
        sort: "asc",
        width: 270,
      },
    ],
    rows1: [
      {
        id: "1",
        name: "Anitha",
        Salarytype: "",
      },
      {
        id: "1",
        LType: "Sick Leave",

        ALeaves: "12",
      },
      {
        id: "2",
        LType: "Casual Leave",

        ALeaves: "5",
      },
    ],
  };

  const customStyles = {
    lineHeight: "1.8",
  };
  const customStyles1 = {
    borderColor: "#e5e5e5",
    height: "auto",
  };

  const reset = () => {
    formRef.current.reset();
    setSearchData({
      from_date: 0,
      to_date: 0,
    });
    setSelectedCountries(null);
    setSelectedFeedBack(null);
    setSelectedLeadFor(null);
    setSelectedLearnBy(null);
    setSelectedMode(null);
    setSelectedStatus(null);
    setSelectedTimePeriod(null);

    handleTableListData();
  };

  const handleSearch = () => {
    let compName = leadsObject?.comp_name ? leadsObject.comp_name : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let MobileNo = leadsObject?.mobile_no ? leadsObject.mobile_no : "";

    handleTableListData(compName, date1, date2, MobileNo);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}leads` + "/" + leadsIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Leads deleted successfully");
                  if (leadsObject && leadsObject._id === leadsIdToBeDeleted) {
                    setLeadsValue("");
                    formRef.current.reset();
                    setLeadsObject({});
                    setLeadsIdToBeUpdated(null);
                    // setSelectedSubCat(null);
                  }
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Leads");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Leads" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Leads For</Label>
                          <Select
                            isMulti
                            name="lead"
                            value={selectedLeadFor}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Lead");
                            }}
                            displayValue="Lead"
                            options={leadFor}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Country</Label>
                          <Select
                            name="country"
                            value={selectedCountries}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Country");
                            }}
                            options={countries}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Mode</Label>
                          <Select
                            name="modes"
                            value={selectedMode}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Modes");
                            }}
                            options={mode}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Name</Label>
                          <AvField
                            style={customStyles}
                            name="full_name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.full_name}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Mobile</Label>
                          <AvField
                            style={customStyles}
                            name="mobile_no"
                            placeholder="Mobile"
                            type="tel"
                            minLength={10}
                            maxLength={10}
                            errorMessage="Enter valid 10 digit number"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            id="validationCustom02"
                            value={leadsObject?.mobile_no}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile</Label>
                          <AvField
                            name="mobile_no"
                            placeholder="Mobile Number"
                            type="phone"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            // validate={{
                            //   required: { value: true },
                            //   pattern: { value: "/^[6-9][0-9]{9}$/" },
                            // }}
                            id="validationCustom03"
                            value={leadsObject?.mobile_no}
                            onChange={handleChangeLeads}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Email</Label>
                          <AvField
                            style={customStyles}
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.email}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Company</Label>
                          <AvField
                            style={customStyles}
                            name="comp_name"
                            placeholder="Company"
                            type="text"
                            errorMessage="Enter Company"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.comp_name}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Business Type
                          </Label>
                          <AvField
                            style={customStyles}
                            name="bus_type"
                            placeholder="Business Type"
                            type="text"
                            errorMessage="Enter Business Type"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.bus_type}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Landline No.
                          </Label>
                          <AvField
                            style={customStyles}
                            name="landline_no"
                            placeholder="Landline No."
                            type="phone"
                            minLength={11}
                            maxLength={11}
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.landline_no}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Status");
                            }}
                            options={status}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Time Period</Label>
                          <Select
                            name="timeperiod"
                            value={selectedTimePeriod}
                            onChange={(selected) => {
                              handleSelectChange(selected, "TimePeriod");
                            }}
                            options={timePeriod}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Your Website
                          </Label>
                          <AvField
                            style={customStyles}
                            name="website"
                            Website="Your Website"
                            placeholder="Your Website"
                            type="text"
                            errorMessage="Enter Your Website"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.website}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Feedback</Label>
                          <Select
                            name="feedback"
                            value={selectedFeedBack}
                            onChange={(selected) => {
                              handleSelectChange(selected, "FeedBack");
                            }}
                            options={feedback}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Where did you learn about SRV?</Label>
                          <Select
                            name="learnBy"
                            value={selectedLearnBy}
                            onChange={(selected) => {
                              handleSelectChange(selected, "LearnBy");
                            }}
                            options={learnBy}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              Follow Up On
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              name="follow_ups"
                              value={leadsObject?.follow_ups}
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Comments</Label>
                          <AvField
                            style={customStyles1}
                            name="comments"
                            placeholder="Comments"
                            type="textarea"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.comments}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Address</Label>
                          <AvField
                            style={customStyles1}
                            name="address"
                            placeholder="Address"
                            type="textarea"
                            errorMessage="Enter Address"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={leadsObject?.address}
                            onChange={handleChangeLeads}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          {leadsIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingLeads ? true : false}
                            >
                              {addingLeads ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addingLeads ? true : false}
                            >
                              {addingLeads ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Company</Label>
                        <input
                          name="comp_name"
                          placeholder="Company"
                          className="form-control"
                          type="text"
                          errorMessage="Enter valid Company"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={searchData?.comp_name}
                          onChange={handleChangeLeads}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">Mobile</Label>
                        <input
                          name="mobile_no"
                          placeholder="Mobile"
                          className="form-control"
                          type="tel"
                          minLength={10}
                          maxLength={10}
                          errorMessage="Enter valid 10 digit number"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={searchData?.mobile_no}
                          onChange={handleChangeLeads}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>

                    <Col md="3" className="mt-4" style={{ paddingTop: "4px" }}>
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => handleSearch()}
                        >
                          Filter
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="LeadsForTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* Modal Form Start */}
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Leads Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Full Name :
                  </td>
                  <td>{popupData?.full_name}</td>
                  <td>Company Name :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.comp_name}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>Date :</td>
                  <td>{popupData?.date}</td>
                  <td>Time :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.time}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Mobile :
                  </td>
                  <td>{popupData?.mobile_no}</td>
                  <td>Status :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.leads_status}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Email :
                  </td>
                  <td>{popupData?.email}</td>
                  <td>Leads For :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.LeadsForTbl}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Time Period :
                  </td>
                  <td>{popupData?.time_period}</td>
                  <td>Follow Up Date :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.follow_ups}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>Mode :</td>
                  <td>{popupData?.mode}</td>
                  <td>Country :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.country}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Business Type :
                  </td>
                  <td>{popupData?.bus_type}</td>
                  <td>Address :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.address}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Website :
                  </td>
                  <td>{popupData?.website}</td>
                  <td>Feedback :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.feedback}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Comments :
                  </td>
                  <td>{popupData?.comments}</td>
                  <td>Learn By :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.learn_by}</td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "left" }}>
                    Added By :
                  </td>
                  <td>{popupData?.addedBy}</td>
                  <td>Updated By :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.updatedBy}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </AvForm>
      </Modal>
      {/* Modal Form End */}
    </React.Fragment>
  );
};

export default Leads;