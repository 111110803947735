import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SweetAlert from "react-bootstrap-sweetalert";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";

import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import { BreadcrumbItem } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Checkbox, Grid } from "@mui/material";

import accessToken from "../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const ClientDashboard = (props) => {
    const { clientID } = props.match.params;
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col md="6">
                            <div className="page-title-box d-flex align-items-center" style={{ paddingBottom: "0px" }}>
                                <h4 >{clientID}</h4>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="page-title-box d-flex float-end">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="/dashboard">{localStorage.getItem('module_name')}</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="/view-clients">Clients</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link>{clientID}</Link>
                                        </BreadcrumbItem>
                                        {/* <BreadcrumbItem active>
                      <Link to="#">{props.breadcrumbItem}</Link>
                    </BreadcrumbItem> */}
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ClientDashboard