import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { userForgetPassword } from "../../store/actions";
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-dark.png";
import toastr from "toastr";
import axios from "axios";
const ResetPassword = (props) => {
  const { userId } = props.location.state;
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [data, setData] = useState({
    userId: userId,
  });
  const [accessToken, setAccessToken] = useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  //   const history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    generate_token();
  }, []);
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const generate_token = () => {
    let value = {
      app_version: "",
      user_id: data?.userId,
    };
    axios
      .post(`${API_URL}api/staff/generate_token`, value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setAccessToken(res.data.token);
        }
      });
  };
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: data?.userId,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            formRef.current.reset();
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            window.location.assign("/login");
            // history.push("/login");
          } else {
            toastr.error(res.data.message);
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  return (
    <React.Fragment>
      <div className="account-pages-container">
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            {/* <i className="mdi mdi-home-variant h2"></i> */}
          </Link>
        </div>

        <div className="account-pages my-5  pt-sm-5">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div>
                  <Card>
                    <CardBody className="p-4">
                      <div className="row">
                        <a href="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="22"
                            className="logo logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="22"
                            className="logo logo-light"
                          />
                        </a>
                      </div>
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Reset Password</h5>
                      </div>
                      <div className="p-2 mt-4">
                        <AvForm
                          ref={formRef}
                          className="form-horizontal"
                          onValidSubmit={(e, v) =>
                            handleValidSubmitPassword(e, v)
                          }
                        >
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Passoword"
                              placeholder="Password"
                              type="password"
                              errorMessage=" Please provide a valid password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={passwordObject.password}
                              onChange={handleChangePassword}
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="confirmPassword"
                              label="Confirm Password"
                              placeholder="Confirm Password"
                              type="password"
                              errorMessage=" Please confirm the password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={passwordObject.confirmPassword}
                              onChange={handleChangePassword}
                            />
                          </div>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-3 text-center text-dark">
                  <p>
                    © {new Date().getFullYear()} <a href="https://srvinfotech.com" target="_blank"> SRV IT Hub Private Limited. </a>All Rights Reserved.
                  </p>
                </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ResetPassword)
);
