import React, { useState, useEffect, useRef } from "react";
import "./Manageleads.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
// import { Scrollbars } from 'react-scrollbars-custom';
import { MDBDataTable } from "mdbreact";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, CardHeader, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { getLocalbody, getDate, getFirstday } from "../../../../helpers/globalFunctions";
import $ from "jquery";
import Select from "react-select";
import toastr from "toastr";

function ManageleadsCopy() {
  const [masterObject, setmasterObject] = useState({ crm: { country: 101 } });
  const [leadTypes] = useState([
    { label: "General", value: 1 },
    { label: "Job", value: 2 },
    { label: "Lead", value: 3 },
  ]);

  const [columns, setColumns] = useState({});
  const [upDateId, setupDateId] = useState({});
  const [dateChnage, setdateChnage] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [leadDetails, setleadDetails] = useState([]);
  const [followupObject, setFollowupObject] = useState({});
  const [followupTableData, setFollowupTableData] = useState([]);
  const [leadIdToBeDeleted, setleadIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [searchObject, setSearchObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
    lead_source: "",
    lead_ref_form_id: "",
    followup_status: "",
  });
  const [selectedLeadFor, setSelectedLeadFor] = useState("");
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [leadIdTobeUpdated, setLeadIdTobeUpdated] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({ label: "India", value: 101 });
  const [selectedBtype, setSelectedBtype] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedFrom, setSelectedFrom] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState("");
  const [selectedSource, setSelectedSource] = useState(null);
  const [followupstatusError, setfollowupstatusError] = useState("");
  const [selectedMobile, setselectedMobile] = useState(null);
  const [selectedName, setselectedName] = useState(null);
  const [followupStatus, setFollowupStatus] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [checkBoxName, setCheckBoxName] = useState();
  const [checkBox, setcheckBox] = useState([
    "62c7d7427333e58969b3de0d",
    "62c7d7287333e58969b3de0c",
    "62c7d6597333e58969b3de08",
    "62c7d7117333e58969b3de0b",
    "6392cbeb2d917dfcd065310b",
    "6392cbc22d917dfcd0653108",
  ]);
  const formRef = useRef();
  const formRef1 = useRef();

  function fetch_status() {
    axios
      .get(`${API_URL}leads/leadsStatus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        let from_date = searchObject?.from_date ? searchObject.from_date : "";
        let to_date = searchObject?.to_date ? searchObject.to_date : "";
        let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
        let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
        let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";

        handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, data);
        setFollowupStatus([{ options: data }]);
      });
  }

  useEffect(() => {
    fetch_status();
  }, []);

  useEffect(() => {
    axios.get(`${API_URL}leads/leadsStatus`).then((res) => {
      setCheckBoxName(res.data.data);
    });
  }, []);

  const followup_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Next Followup",
        field: "next_followup",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ref.No",
        field: "ref_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comments",
        field: "comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "username",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followupTableData,
  };

  const followreset = () => {
    formRef1.current.reset();
    setFollowupObject({
      ...followupObject,
      status: null,
      status_id: null,
      ref_no: "",
      comment: "",
    });
  };

  function handleTableData(from_date, to_date, lead_source = "", lead_ref_form_id = "", followup_status = "", data) {
    var url = `${API_URL}newLeads/list?from_date=${from_date}&to_date=${to_date}&lead_source=${lead_source}&lead_ref_form_id=${lead_ref_form_id}&followup_status=${followup_status}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          if (item?.lead_ref_form_id) {
            item.formtype = item.lead_ref_form_id?.form_type_name;
          }
          let exportItem = {};
          if (item.formtype == "CRM") {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>

                {item?.crm ? (
                  <i
                    className="far fa-edit"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.1rem",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      toTop();
                      preUpdateLead(item);
                    }}></i>
                ) : null}

                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setleadIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </div>
            );
          } else {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setleadIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </div>
            );
          }

          item.id = index + 1;
          if (item?.up_date) {
            item.day = moment(item.up_date).format("DD-MM-YYYY"); //+" "+( moment(item.up_time, "HHmmss").format("hh:mm a"));
            item.time = moment(item.up_time, "HH:mm:ss").format("hh:mm a"); //+" "+( moment(item.up_time, "HHmmss").format("hh:mm a"));
            //item.day =item.day+" "+item.time;
          } else {
            item.day = moment(item.date).format("DD-MM-YYYY"); //+" "+( moment(item.time, "HHmmss").format("hh:mm a"));
            item.time = moment(item.time, "HH:mm:ss").format("hh:mm a");
            //item.day =item.day+" "+item.time;
          }
          if (item?.lead_reg_id) {
            item.name = item.lead_reg_id?.reg_name;
            item.mobile = item.lead_reg_id?.reg_mobile;
            item.email = item.lead_reg_id?.reg_email;
          }

          if (item?.lead_source) {
            item.source = item.lead_source?.name;
          }

          var selectedStat = {};
          if (item.latest_followp_status) {
            var status = item?.latest_followp_status;
            var label = status?.name;
            var value = status?._id;
            var colorclass = status?.color;
            item.up_followup_status_id = status?._id;
            selectedStat = {
              label: label,
              value: value,
            };
          } else {
            var label = "New";
            var colorclass = "#00ccff";
          }

          item.followup_status = (
            <span className="badge" style={{ fontSize: "12px", background: colorclass }}>
              {label}
            </span>
          );
          // item.followup_status = (
          //   <div className="mb-3">
          //     <Select
          //       menuPosition="fixed"
          //       name="followup_status"
          //        value={selectedStat}
          //       onChange={(selected) => {
          //         updateStatus(selected, item);
          //      }}
          //       classNamePrefix="select2-selection"
          //       options={data}
          //     />
          //   </div>
          // );

          let followon = item?.followup ? item.followup[0] : [];
          let foll = followon?.followup_on;
          item.up_followup_on = foll;
          item.followup_on = (
            <>
              <input
                name="followup_on"
                value={foll}
                style={{ textAlign: "left" }}
                className="form-control"
                onChange={(selected) => {
                  updateDate(selected, item);
                }}
                type="date"
              />
            </>
          );
          exportItem.id = item.id;
          exportItem.Date = item.day;
          exportItem.Name = item.name;
          exportItem.Mobile = item.mobile;
          exportItem.Type = item.formtype;
          exportItem.Source = item.source;
          exportItem.Status = item?.followup?.[0]?.followup_status?.name ? item.followup[0].followup_status.name : "";
          exportItem.FollowupOn = item?.followup?.[0]?.followup_on ? item.followup[0].followup_on : "";
          dataToBeExported.push(exportItem);
          resData.push(item);
        });
        setTableData(resData);
        setDataToBeExported(dataToBeExported);
      });
  }

  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}newLeads/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setFollowupObject({ lead_id: item._id });
        let result = res.data.data;
        var formtype = "";
        let Update = result.updatedby || {};
        result.edited = (Update.firstName || "") + (Update.lastName || "");
        if (result?.lead_type) {
          result.leadtypeObject = leadTypes[result?.lead_type - 1];
        } else {
          result.leadtypeObject = null;
        }

        let Add = result.addedby || {};
        result.add = (Add.firstName || "") + (Add.lastName || "");

        let Source = result.lead_source || {};
        result.source = Source?.name;

        let Form = result.lead_ref_form_id || {};
        result.form = Form?.form_type_name;
        var lead_cmt = "";
        if (Form._id.toString() == "635617667a108495c6360e58") formtype = 1; //crm
        else if (Form._id.toString() == "6333e2f539b31c90e7348a71") formtype = 2; //sale
        else if (Form._id.toString() == "6333e2f539b31c90e7348a75") formtype = 3; //support
        else if (Form._id.toString() == "6333e2f539b31c90e7348a76") formtype = 4; //contact
        else if (Form._id.toString() == "6333e2f539b31c90e7348a77") formtype = 5; //general
        else if (Form._id.toString() == "6333e2f539b31c90e7348a73") formtype = 6; //quote
        else if (Form._id.toString() == "6333e2f539b31c90e7348a72") formtype = 7; //quote
        result.formtypeid = formtype;
        if (result.pages) {
          var link = result.pages.split("https://srvinfotech.com/");
          result.pages = link[1];
        }

        let upTime = result.up_time || {};
        let upDate = result.up_date || {};
        result.update = moment(upDate).format("DD-MM-YYYY") + " " + moment(upTime, "HHmmss").format("hh:mm a");

        let Time = result.time || {};
        let Date = result.date || {};
        result.day = moment(Date).format("DD-MM-YYYY") + " " + moment(Time, "HHmmss").format("hh:mm a");

        let Basic = result.lead_reg_id || {};
        result.name = Basic?.reg_name;
        result.email = Basic?.reg_email;
        result.mobile = Basic?.reg_mobile;
        result.reg_unique_id = Basic?.reg_unique_id;
        let reg_cit = Basic?.reg_city;
        if (reg_cit != "NULL") {
          result.reg_city = reg_cit;
        }

        let Message1 = result.talk_to_support || {};
        result.message1 = Message1?.support_message;
        let tsag = result.talk_to_support || {};
        let tsag1 = tsag?.support_agree_terms_condition;
        if (tsag1 === 1) {
          result.tsag = "Yes";
        } else if (tsag1 === 0) {
          result.tsag = "No";
        }
        if (result.formtypeid == 3) lead_cmt = result.message1;

        let Message3 = result.contactaccount || {};
        result.Message3 = Message3?.contact_account_message;
        if (result.formtypeid == 4) lead_cmt = result.Message3;

        // let Message4 =result.brings_idea || {}
        // result.message4 =Message4?.idea_message;

        let Message5 = result.schedule_demo || {};
        result.message5 = Message5?.demo_message;
        let sdr1 = Message5?.demo_requirements;
        result.sdr = sdr1;
        if (result.formtypeid == 7) lead_cmt = result.sdr;

        let Message6 = result.general || {};
        result.Message6 = Message6?.general_message;
        let gr1 = Message6?.general_requirements;
        result.gr = gr1;
        if (result.formtypeid == 5) lead_cmt = result.Message6;

        let qte = result.quote || {};
        let qbtype1 = qte?.quote_business_type;
        if (qbtype1 != "NULL") {
          result.qbtype = qbtype1;
        }
        let com1 = qte?.quote_company;
        if (com1 != "NULL") {
          result.com = com1;
        }
        let cweb1 = qte?.quote_competition_website;
        if (cweb1 != "NULL") {
          result.cweb = cweb1;
        }
        let pbudget1 = qte?.quote_project_budget;
        if (pbudget1 != "NULL") {
          result.pbudget = pbudget1;
        }
        let wurl1 = qte?.quote_website_url;
        if (wurl1 != "NULL") {
          result.wurl = wurl1;
        }
        let tm1 = qte?.qt_timeline;
        if (tm1 != "NULL") {
          result.tm = tm1?.name;
        }
        let src1 = qte?.qt_source;
        if (src1 != "NULL") {
          result.src = src1?.name;
        }
        if (result.formtypeid == 6) lead_cmt = result.pbudget;

        let Message2 = result.sale_data || {};
        result.message2 = Message2?.sale_message;
        let sag = result.sale_data || {};
        let sag1 = sag?.sale_agree_terms_condition;
        if (sag1 === 1) {
          result.sag = "Yes";
        } else if (sag1 === 0) {
          result.sag = "No";
        }
        if (formtype == 2) lead_cmt = result.message2;

        let crm = result.crm || {};
        let compy1 = crm?.companyname;
        result.compy = compy1;
        let btp = crm?.businesstype;
        result.btp = btp?.client_category;
        result.adds = crm?.address;
        result.land = crm?.landline;
        result.webi = crm?.website;
        let cat = crm.category || [];

        if (cat.length != 0) {
          result.cate = (
            <>
              {result?.crm?.category &&
                result?.crm?.category.map((el) => {
                  let indus = el?.name || {};

                  return <span className="badge bg-secondary badge-enq-category">{indus}</span>;
                })}
            </>
          );
        }

        let tpm = crm?.timeperiod;
        result.timep = tpm?.name;
        let lfrm = crm?.learnfrom;
        result.lfrom = lfrm?.name;
        let fee = crm?.feedback;
        result.feed = fee?.name;
        let coty = result?.crmctry?.[0] || [];
        result.cotys = coty?.name;

        result.cmts = crm?.comment;
        if (formtype == 1) lead_cmt = result.cmts;
        result?.followup.sort(function (a, b) {
          var x = a._id;
          var y = b._id;
          // factor -1 because you want them sorted DESC
          return -1 * (x < y ? -1 : x > y ? 1 : 0);
        });
        var old_followup = [];
        result?.followup &&
          result?.followup.map((el, index) => {
            if (typeof el.next_followup !== "undefined" && el.next_followup != "") var next_followup = moment(el?.next_followup).format("DD-MM-YYYY");
            else var next_followup = "";
            old_followup.push({
              id: index + 1,
              date: moment(el.date).format("DD-MM-YYYY") + " " + moment(el.time, "HH:mm:ss").format("hh:mm a"),
              username: el?.addedby?.firstName,
              status:
                typeof el.followup_status.name !== "undefined" ? (
                  <span className="badge" style={{ background: el.followup_status.color, fontSize: "12px" }}>
                    {el.followup_status.name}
                  </span>
                ) : (
                  ""
                ),
              ref_no: el?.ref_no,
              comment: el?.comments,
              next_followup: next_followup,
            });
          });

        old_followup.push({
          id: old_followup.length + 1,
          date: result?.day,
          username: result?.add,
          status: (
            <span className="badge" style={{ background: "#00ccff", fontSize: "12px" }}>
              New
            </span>
          ),
          ref_no: "",
          comment: lead_cmt,
          next_followup: "",
        });
        setFollowupTableData(old_followup);

        setShowPopup(true);
        setleadDetails(result);
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateLead = (item) => {
    setLeadIdTobeUpdated(item._id);

    axios
      .get(`${API_URL}newLeads/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let cat = [];
        result?.crm?.category &&
          result?.crm?.category.map((el) => {
            let array = {
              label: el.name,
              value: el._id,
            };
            cat.push(array);
          });
        setSelectedLeadFor(cat);

        let cty = result?.crmctry?.[0];
        let country = {
          label: cty?.name,
          value: cty?._id,
        };
        setSelectedCountry(country);

        let btype = result?.crm?.businesstype;
        let bustype = {
          label: btype?.client_category,
          value: btype?._id,
        };
        setSelectedBtype(bustype);

        let time = result?.crm?.timeperiod;
        let timep = {
          label: time?.name,
          value: time?._id,
        };
        setSelectedTime(timep);
        let learn = result?.crm?.learnfrom;
        let lefrom = {
          label: learn?.name,
          value: learn?._id,
        };
        setSelectedFrom(lefrom);

        let back = result?.crm?.feedback;
        let feed = {
          label: back?.name,
          value: back?._id,
        };
        setSelectedFeedback(feed);

        let source = result?.lead_source;
        let src = {
          label: source?.name,
          value: source?._id,
        };
        setSelectedSource(src);
        result.reg_mobile = result?.lead_reg_id?.reg_mobile;
        result.reg_name = result?.lead_reg_id?.reg_name;
        result.reg_email = result?.lead_reg_id?.reg_email;
        setselectedMobile({ label: result.reg_mobile, value: result.reg_mobile });
        setselectedName({ label: result.reg_name, value: result.reg_name });
        setmasterObject(result);
      });
  };

  const updateDate = (selected, item) => {
    let foll = item?.up_followup_status_id ? item.up_followup_status_id : null;
    let data = {
      _id: item._id,
      followup_on: selected.target.value,
      followup_status: foll,
    };

    axios
      .put(`${API_URL}newLeads/followup`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let from_date = searchObject?.from_date ? searchObject.from_date : "";
          let to_date = searchObject?.to_date ? searchObject.to_date : "";
          let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
          let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
          let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
          let option = followupStatus;
          handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option);
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };

  const handleFollowupData = (e, type) => {
    if (type == "followup_status") {
      setFollowupObject({
        ...followupObject,
        status_id: e.value,
        status: e,
      });
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    } else if (type == "ref_no") {
      setFollowupObject({
        ...followupObject,
        ref_no: e.target.value,
      });
    } else if (type == "comment") {
      setFollowupObject({
        ...followupObject,
        comment: e.target.value,
      });
    } else if (type == "next_followup") {
      setFollowupObject({
        ...followupObject,
        next_followup: e.target.value,
      });
    }
  };

  const handleFollowupSubmit = () => {
    if (typeof followupObject.status !== "undefined") {
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    } else {
      $("#followupstatus").attr("class", "select2-selection border border-danger");
      setfollowupstatusError("Select Status");
      return;
    }
    axios
      .put(`${API_URL}newLeads/followup`, followupObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        formRef1.current.reset();
        setFollowupObject({
          ...followupObject,
          status: null,
          status_id: null,
          ref_no: "",
          comment: "",
        });
        // handleClickOpen({ _id: followupObject.lead_id });
        toastr.success("Submitted Successfully");
        setShowPopup(false);
        fetch_Lead(checkBox, dateChnage);
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });
  };

  useEffect(() => {
    fetch_Lead(checkBox, dateChnage);
    // const checkBoxQueryString = checkBox.join(',');
    // axios.get(`https://erpapi.srvinfotech.com/newleads/list/kanban?followup_status=${checkBoxQueryString}`).then((res) => {
    //   console.log(res?.data?.data);
    //   const result = res.data.data
    //   const newData = result?.reduce((el, item) => {
    //     const itemId = item._id;
    //     el[itemId] = {
    //       name: item.name,
    //       items: item.items,
    //     };
    //     return el;
    //   }, {});
    //   setColumns(newData);
    // })
  }, [checkBox, dateChnage]);

  function handleFollowupDate(e) {
    console.log("name", e.target.name);
    console.log("value", e.target.value);

    setdateChnage({ ...dateChnage, [e.target.name]: e.target.value });
  }
  // ==============================kanban
  function fetch_Lead(checkBox, { from_date = "", to_date = "" }) {
    const checkBoxQueryString = checkBox.join(",");
    axios.get(`${API_URL}newleads/list/kanban?from_date=${from_date || ""}&to_date=${to_date || ""}&followup_status=${checkBoxQueryString}`).then((res) => {
      console.log(res?.data?.data, "first data");
      const result = res.data.data;
      const newData = result?.reduce((el, item) => {
        const itemId = item._id;
        el[itemId] = {
          name: item.name,
          items: item.items,
        };
        return el;
      }, {});
      console.log(newData, "seconddd");
      setColumns(newData);
    });
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      //  console.log("sourcecolum,",sourceColumn);
      //  console.log("destcolumn",destColumn);
      //  console.log("sourceitem",sourceItems);
      //  console.log("destitem",destItems);

      const cardId = removed._id;
      const destinationBoxId = destination.droppableId;

      console.log("Card ID:", cardId);
      console.log("Destination Box ID:", destinationBoxId);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      axios
        .put(
          `${API_URL}newleads/list/kanban`,
          {
            lead_id: cardId,
            status: destinationBoxId,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          fetch_Lead(checkBox, dateChnage);
          // Handle the response
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const cardId = removed._id;
      const destinationBoxId = destination.droppableId;
      console.log("Card ID:", cardId);
      console.log("Destination Box ID:", destinationBoxId);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });

      axios
        .put(
          `${API_URL}newleads/list/kanban`,
          {
            lead_id: cardId,
            status: destinationBoxId,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          fetch_Lead(checkBox, dateChnage);
          // Handle the response
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    }
  };
  function checkboxclick(e) {
    const { checked, value } = e.target;

    if (checked) {
      // Checkbox is checked, add the value (object ID) to the checkBox array
      setcheckBox((prevCheckBox) => {
        if (!prevCheckBox.includes(value)) {
          return [...prevCheckBox, value];
        }
        return prevCheckBox;
      });
    } else {
      // Checkbox is unchecked, remove the value (object ID) from the checkBox array
      setcheckBox((prevCheckBox) => prevCheckBox.filter((id) => id !== value));
    }
  }
  const handleChangeType = (selected, id) => {
    axios
      .post(
        `${API_URL}newLeads/change-lead-type`,
        { id: id, type: selected.value },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        handleClickOpen(leadDetails);
        fetch_Lead(checkBox, dateChnage);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <div className="container-fluid">
          <div className="mt-2" style={{ display: "flex", alignItems: "center" }}>
            {checkBoxName?.map((item, index) => {
              const isChecked = checkBox.includes(item?._id);

              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input name={item?.name} onClick={checkboxclick} key={index} type="checkbox" checked={isChecked} value={item?._id} />{" "}
                  <p className="mx-3 mb-0">{item?.name}</p>
                </div>
              );
            })}
            <div>
              <AvForm style={{ display: "flex" }}>
                <div style={{ display: "flex" }} className="mx-2">
                  <label style={{ marginTop: ".5rem" }}>From</label>
                  <AvField
                    style={{ width: "115px", marginLeft: "10px" }}
                    name="from_date"
                    type="date"
                    onChange={(e) => {
                      handleFollowupDate(e);
                    }}
                  />
                </div>

                <div style={{ display: "flex" }} className="mx-2">
                  <label style={{ marginTop: ".5rem" }}>To</label>
                  <AvField
                    style={{ width: "115px", marginLeft: "10px" }}
                    name="to_date"
                    type="date"
                    onChange={(e) => {
                      handleFollowupDate(e);
                    }}
                  />
                </div>
              </AvForm>
            </div>
          </div>

          <div className="kanboard mt-3">
            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
              {Object.entries(columns)?.map(([columnId, column], index) => {
                return (
                  <div key={columnId} className={`kanboard_child `}>
                    <div className="kanboard_header">
                      <div className="board_header_left">{column?.name}</div>
                      <div className="board_header_right">
                        <h6 className="card_icon kanboard_close">{column?.items?.length}</h6>
                        <h6 className="card_icon kanboard_view">
                          <i class="fa fa-angle-right"></i>
                        </h6>
                      </div>
                    </div>
                    <div className="kanboard_body">
                      <h6 className="board_name">{column?.name}</h6>

                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                // background: snapshot.isDraggingOver
                                //   ? "lightblue"
                                //   : "lightgrey",
                                //   padding: 4,
                                width: 215,
                                minHeight: 200,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}>
                              {column?.items?.map((item, index) => {
                                console.log("cardmao", item);
                                return (
                                  <Draggable key={item._id} draggableId={item._id} index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          onClick={() => {
                                            handleClickOpen(item);
                                          }}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            //   userSelect: "none",
                                            //   padding: 16,
                                            //   margin: "0 0 8px 15px",
                                            //   minHeight: "50px",
                                            //   backgroundColor: snapshot.isDragging
                                            //     ? "#263B4A"
                                            //     : "#456C86",
                                            //   color: "white",
                                            ...provided.draggableProps.style,
                                          }}
                                          class="kanboard_card">
                                          <h5 className="mb-0">{item?.lead_reg_id?.reg_name}</h5>
                                          <p className="mb-0">{item?.crm?.companyname}</p>
                                          <div>
                                            <p className="mb-0">{item.crm?.category[0]?.name}</p>
                                          </div>
                                          <div className="flex_align">
                                            <p className="mb-0">{moment(item?.followup?.date || item?.date).format("DD-MM-YYYY")}</p>
                                            <p className="mb-0">{item?.followup?.addedby?.firstName || item?.addedby?.firstName}</p>
                                            <i style={{ cursor: "pointer" }} className="fa fa-eye icon_eye"></i>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                      {/* <div class="kanboard_card">
                <h5 className="mb-0">Sreedarsh</h5>
                <p className="mb-0">Improve application performance fwjnkfrnrweojfpj</p>
                <div className="flex_align">
                  <p className="mb-0">23/01/2023</p>
                  <i className="fa fa-eye icon_eye"></i>
                </div>
              </div> */}
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        </div>
      </div>
      <Modal show={showPopup} size="xl" centered={true} className="modal-fullscreen">
        <div className="modal-header">
          <div className="modal-title">
            Enquiry <b>#{leadDetails?.lead_unique_id}</b> On <b>{leadDetails?.day}</b>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
              handleTableData(getFirstday(new Date()), getDate(new Date()), "", "", "", followupStatus);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <table id="enquiryModalTable">
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>: {leadDetails?.name}</td>
                      <td>
                        <b>Mobile</b>
                      </td>
                      <td>: {leadDetails?.mobile}</td>
                      <td>
                        <b>Email</b>
                      </td>
                      <td>: {leadDetails?.email}</td>
                      {/* <td><b>City</b></td><td>: {leadDetails?.reg_city}</td> */}
                      <td>
                        <b>Lead Type</b>
                      </td>
                      <td style={{ minWidth: "105px" }}>
                        <Select
                          name="lead_type"
                          value={leadDetails?.leadtypeObject}
                          options={leadTypes}
                          classNamePrefix="select2-selection"
                          onChange={(selected) => {
                            handleChangeType(selected, leadDetails?._id);
                          }}
                        />
                      </td>
                    </tr>
                    {/* </table>
                </CardBody>
                <CardHeader><b>Enquiry Details</b></CardHeader>
                <CardBody>
                <table id="enquiryModalTable"> */}
                    {leadDetails?.formtypeid == 1 ? (
                      <>
                        <tr>
                          <td>
                            <b>Source</b>
                          </td>
                          <td>: {leadDetails?.source}</td>
                          <td>
                            <b>Enquiry Category</b>
                          </td>
                          <td>: {leadDetails?.form}</td>
                          <td>
                            <b>Category</b>
                          </td>
                          <td>: {leadDetails?.cate}</td>
                          <td>
                            <b>Country</b>
                          </td>
                          <td>: {leadDetails?.cotys}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Landline No</b>
                          </td>
                          <td>: {leadDetails?.land}</td>
                          <td>
                            <b>Address</b>
                          </td>
                          <td>: {leadDetails?.adds}</td>
                          <td>
                            <b>Website</b>
                          </td>
                          <td>: {leadDetails?.webi}</td>
                          <td>
                            <b>Company</b>
                          </td>
                          <td>: {leadDetails?.compy}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Business Type</b>
                          </td>
                          <td>: {leadDetails?.btp}</td>
                          <td>
                            <b>Timeperiod</b>
                          </td>
                          <td>: {leadDetails?.timep}</td>
                          <td>
                            <b>Learn From</b>
                          </td>
                          <td>: {leadDetails?.lfrom}</td>
                          <td>
                            <b>Feedback</b>
                          </td>
                          <td>: {leadDetails?.feed}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                    {leadDetails?.formtypeid == 2 || leadDetails?.formtypeid == 3 || leadDetails?.formtypeid == 4 ? (
                      <>
                        <tr>
                          <td>
                            <b>Source</b>
                          </td>
                          <td>: {leadDetails?.source}</td>
                          <td>
                            <b>Enquiry Category</b>
                          </td>
                          <td>: {leadDetails?.form}</td>
                          <td>
                            <b>Source Page</b>
                          </td>
                          <td>: {leadDetails?.pages}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                    {leadDetails?.formtypeid == 5 ? (
                      <>
                        <tr>
                          <td>
                            <b>Source</b>
                          </td>
                          <td>: {leadDetails?.source}</td>
                          <td>
                            <b>Enquiry Category</b>
                          </td>
                          <td>: {leadDetails?.form}</td>
                          <td>
                            <b>Requirement</b>
                          </td>
                          <td>: {leadDetails?.general?.general_requirement}</td>
                          <td>
                            <b>Source Page</b>
                          </td>
                          <td>: {leadDetails?.pages}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                    {leadDetails?.formtypeid == 6 ? (
                      <>
                        <tr>
                          <td>
                            <b>Source</b>
                          </td>
                          <td>: {leadDetails?.source}</td>
                          <td>
                            <b>Category</b>
                          </td>
                          <td>: {leadDetails?.form}</td>
                          <td>
                            <b>Source Page</b>
                          </td>
                          <td>: {leadDetails?.pages}</td>
                          <td>
                            <b>How did find us</b>
                          </td>
                          <td>: {leadDetails?.quote?.qt_source}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Business</b>
                          </td>
                          <td>: {leadDetails?.quote?.quote_business_type}</td>
                          <td>
                            <b>Website</b>
                          </td>
                          <td>: {leadDetails?.quote?.quote_website_url}</td>
                          <td>
                            <b>Timeline</b>
                          </td>
                          <td>: {leadDetails?.quote?.qt_timeline}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Company</b>
                          </td>
                          <td colspan="3">: {leadDetails?.quote?.quote_company}</td>
                          <td>
                            <b>Competition Website</b>
                          </td>
                          <td colspan="3">: {leadDetails?.quote?.quote_competition_website}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                    {leadDetails?.formtypeid == 7 ? (
                      <>
                        <tr>
                          <td>
                            <b>Source</b>
                          </td>
                          <td>: {leadDetails?.source}</td>
                          <td>
                            <b>Enquiry Category</b>
                          </td>
                          <td>: {leadDetails?.form}</td>
                          <td>
                            <b>Source Page</b>
                          </td>
                          <td>: {leadDetails?.pages}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Company</b>
                          </td>
                          <td colSpan={7}>: {leadDetails?.schedule_demo?.demo_message}</td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody style={{ padding: "10px" }}>
                  <AvForm
                    ref={formRef1}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleFollowupSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="2">
                        <Label>Status</Label>
                        <Select
                          id="followupstatus"
                          name="followupstatus"
                          value={followupObject?.status}
                          onChange={(selected) => {
                            handleFollowupData(selected, "followup_status");
                          }}
                          options={followupStatus}
                          classNamePrefix="select2-selection"
                          menuPosition="fixed"
                        />
                        <div className="text-danger" style={{ fontSize: "11px" }}>
                          {followupstatusError != "" ? followupstatusError : ""}
                        </div>
                      </Col>
                      <Col
                        md="2"
                        style={{
                          display:
                            followupObject?.status_id == "62c7d7117333e58969b3de0b" || followupObject?.status_id == "6392cbe02d917dfcd065310a"
                              ? "block"
                              : "none",
                        }}>
                        <Label>Ref.No</Label>
                        <AvField
                          type="text"
                          className="form-control"
                          name="ref_no"
                          value={followupObject?.ref_no}
                          placeholder="Ref. No"
                          onChange={(e) => {
                            handleFollowupData(e, "ref_no");
                          }}
                          // validate={{ required: { value: true } }}
                          errorMessage="Enter Ref.No"
                        />
                      </Col>
                      <Col md="2">
                        <Label>Next Followup</Label>
                        <AvField
                          type="date"
                          className="form-control"
                          name="next_followup"
                          value={followupObject?.nextfollowup}
                          placeholder="Next Followup"
                          onChange={(e) => {
                            handleFollowupData(e, "next_followup");
                          }}
                          // validate={{ required: { value: true } }}
                        />
                      </Col>
                      <Col md="3">
                        <Label>Comments</Label>
                        <AvField
                          name="follow_comment"
                          placeholder="Comments"
                          type="textarea"
                          className="form-control"
                          value={followupObject?.comment}
                          onChange={(selected) => {
                            handleFollowupData(selected, "comment");
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter your comments"
                          rows="1"
                        />
                      </Col>
                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button color="primary" type="submit" style={{ marginRight: "10px" }}>
                          Submit
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => {
                            followreset();
                          }}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="followupModalTable"
                    responsive
                    bordered
                    data={followup_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ManageleadsCopy;
