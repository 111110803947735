import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";

import { AvForm, AvField } from "availity-reactstrap-validation";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./manageLeave.scss";
import { Stack } from "react-bootstrap";

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "FTime",
        sort: "asc",
        width: 100,
      },
      {
        label: "To",
        field: "TTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "SID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "SName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Applied",
        field: "ADate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Approved",
        field: "Aby",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      {
        id: "1",
        type: "P",
        date: "23-07-2022",
        FTime: "17:00 PM",
        TTime: "17:30 PM",
        SID: "SRV045",
        SName: "Nihal",
        reason: "Personal reasons",
        status: "Applied",
        ADate: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "From",
        field: "fromdate",
        sort: "asc",
        width: 270,
      },
      {
        label: (
          <>
            <WatchLaterOutlinedIcon />
          </>
        ),
        field: "from",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "Todate",
        sort: "asc",
        width: 270,
      },
      {
        label: (
          <>
            <WatchLaterOutlinedIcon />
          </>
        ),
        field: "to",
        sort: "asc",
        width: 270,
      },
      {
        label: "Duration",
        field: "duration",
        sort: "asc",
        width: 270,
      },
      {
        label: "ID",
        field: "SID",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "SName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 270,
      },
      {
        label: "Applied",
        field: "AOn",
        sort: "asc",
        width: 270,
      },
      {
        label: "Approved",
        field: "Aby",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      },
    ],
    rows: [
      {
        id: "1",
        LType: "SL",
        fromdate: "22-07-2022",
        from: "F",
        Todate: "22-07-2022",
        to: "A",
        duration: "1 Day",
        SID: "SRVINT036",
        SName: "Sabeel",
        reason: "Headache",
        status: "Applied",
        AOn: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "2",
        LType: "SL",
        fromdate: "22-07-2022",
        from: "F",
        Todate: "22-07-2022",
        to: "A",
        duration: "1 Day",
        SID: "SRVINT045",
        SName: "Naseem",
        reason: "Headache",
        status: "Applied",
        AOn: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "3",
        LType: "SL",
        fromdate: "22-07-2022",
        from: "F",
        Todate: "22-07-2022",
        to: "A",
        duration: "1 Day",
        SID: "SRVINT05",
        SName: "Jamsheer",
        reason: "Headache",
        status: "Applied",
        AOn: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "4",
        LType: "SL",
        fromdate: "22-07-2022",
        from: "F",
        Todate: "22-07-2022",
        to: "A",
        duration: "1 Day",
        SID: "SRVINT046",
        SName: "Abhirag",
        reason: "Headache",
        status: "Applied",
        AOn: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
      {
        id: "5",
        LType: "SL",
        fromdate: "22-07-2022",
        from: "F",
        Todate: "22-07-2022",
        to: "A",
        duration: "1 Day",
        SID: "SRVINT031",
        SName: "Namitha",
        reason: "Headache",
        status: "Applied",
        AOn: "23-07-2022",
        Aby: "",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const [open, setOpen] = React.useState(false);
  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View/Manage Leave" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Employee ID
                          </Label>

                          <AvField
                            style={customStyles}
                            name="amount"
                            placeholder="Employee ID"
                            type="text"
                            errorMessage="Enter Employee ID"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Staff</Label>
                          <Select
                            name="type"
                            errorMessage="Select Category Type"
                            validate={{ required: { value: true } }}
                            // value={selectedType}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected, "type");
                            // }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              From
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              To
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Select Status
                          </Label>
                          <Select
                            name="type"
                            errorMessage="Select Category Type"
                            validate={{ required: { value: true } }}
                            // value={selectedType}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected, "type");
                            // }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Leave Type</Label>
                          <Select
                            name="type"
                            errorMessage="Select Category Type"
                            validate={{ required: { value: true } }}
                            // value={selectedType}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected, "type");
                            // }}
                            options={[
                              {
                                label: "Services",
                                value: 0,
                              },
                              {
                                label: "Product",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                          >
                            {/* <Save></Save> */}
                            {"  "}
                            Filter
                          </Button>
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>Manage Permissions</Label>

                  <MDBDataTable
                    id="manageLeaveTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>Manage Leaves</Label>

                  <MDBDataTable
                    id="manageLeave1TableId"
                    responsive
                    
                    bordered
                    data={data1}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div></div>
    </React.Fragment>
  );
};

export default DatatableTables;
