import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import toastr from "toastr";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";
// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-dark.png";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";

const ForgetPasswordPage = (props) => {
  const [master, setMaster] = useState({
    app_signature: "",
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const history = useHistory();
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    // props.userForgetPassword(values, props.history);
    axios
      .post(`${API_URL}api/staff/login`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        if (res.data.success === true) {
          toastr.success("OTP Send Successfully");
          history.push("/verify-otp", {
            userId: result?.user_id,
            otp: result?.otp,
            mobile: master?.mobile,
          });
        } else {
          toastr.error(res.data.message);
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }
  const handleInputChange = (e) => {
    setMaster({
      [e.target.name]: e.target.value,
    });
  };
  return (
    <React.Fragment>
      <div className="account-pages-container">
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            {/* <i className="mdi mdi-home-variant h2"></i> */}
          </Link>
        </div>

        <div className="account-pages my-5  pt-sm-5">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div>
                  <Card>
                    <CardBody className="p-4">
                      <div className="row">
                        <a href="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="22"
                            className="logo logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="22"
                            className="logo logo-light"
                          />
                        </a>
                      </div>
                      <div className="text-center mt-2">
                        <h5 className="text-primary">OTP Verification</h5>
                        <p className="text-muted">
                          We will send you an OTP on this mobile number
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <div
                          className="alert alert-success text-center mb-4"
                          role="alert"
                        >
                          {/* Enter your Email and instructions will be sent to you! */}
                          Enter Your Mobile Number
                        </div>
                        {props.forgetError && props.forgetError ? (
                          <Alert
                            color="danger"
                            className="text-center mb-4"
                            style={{ marginTop: "13px" }}
                          >
                            {props.forgetError}
                          </Alert>
                        ) : null}
                        {props.forgetSuccessMsg ? (
                          <Alert
                            color="success"
                            className="text-center mb-4"
                            style={{ marginTop: "13px" }}
                          >
                            {props.forgetSuccessMsg}
                          </Alert>
                        ) : null}

                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        >
                          <div className="mb-3">
                            <AvField
                              name="mobile"
                              label="Mobile"
                              className="form-control"
                              placeholder="Enter mobile"
                              type="tel"
                              errorMessage="Please provide a valid mobile."
                              validate={{ required: { value: true } }}
                              id="validationCustom04"
                              value={master?.mobile}
                              onChange={handleInputChange}
                            />
                          </div>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Request OTP
                              </button>
                            </Col>
                          </Row>
                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              Remember It ?{" "}
                              <Link
                                to="/login"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Signin{" "}
                              </Link>
                            </p>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-3 text-center text-dark">
                  <p>
                    © {new Date().getFullYear()} <a href="https://srvinfotech.com" target="_blank"> SRV IT Hub Private Limited. </a>All Rights Reserved.
                  </p>
                </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
);
