import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./SubCategory.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const SubCategory = () => {
  const [subCategoryObject, setSubCategoryObject] = useState({});
  const [subCategoryIdTobeUpdated, setSubCategoryIdToBeUpdated] =
    useState(null);
  const [subCategoryIdToBeDeleted, setSubCategoryIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [subCategoryForTable, setSubCategoryForTable] = useState([]);
  const [subCategoryValue, setsubCategoryValue] = useState("");

  const [subCatType, setSubCatType] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState(null);

  const [taxPer, setTaxPer] = useState([]);
  const [selectedTaxPer, setSelectedTaxPer] = useState([]);

  const [errors, setErrors] = useState({
    subCatError: "",
    taxeError: "",
  });

  const {
    districts,
    addingsubCategory,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_taxPer();
    fetch_all_subCatType();
  }, []);

  const dispatch = useDispatch();

  function handleTableData() {
    var url = `${API_URL}subCateg/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let subCategoryData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateSubCategory(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setSubCategoryIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.subCategoryup_date)
            item.date = moment(item.subCategoryup_date).format("DD-MM-YYYY");
          else item.date = moment(item.subCategory_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          let TaxValue = item?.taxper || {};
          item.taxs = TaxValue?.tax;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          subCategoryData.push(item);
        });
        setSubCategoryForTable(subCategoryData);
      });
  }
  let preUpdateSubCategory = (item) => {
    setSubCategoryIdToBeUpdated(item._id);
    setsubCategoryValue(item?._id);

    axios
      .get(`${API_URL}subCateg/?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let categoryValue = {
          label: result?.maincategoryname ? result.maincategoryname : "",
          value: result?.maincategory ? result.maincategory : "",
        };
        let TaxValue = item?.taxper ? item.taxper : "";

        let taxs = TaxValue?.tax;

        let taxPerValue = {
          label: taxs,
          value: result?._id,
        };
        setSelectedTaxPer(taxPerValue);
        setSelectedSubCat(categoryValue);
        setSubCategoryObject(result);
      });
  };

  function fetch_all_subCatType() {
    axios
      .get(`${API_URL}category/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var subCatType_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setSubCatType([{ options: subCatType_data }]);
      });
  }

  function fetch_all_taxPer() {
    axios
      .get(`${API_URL}tax/Option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var taxPer_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.tax,
              value: el._id,
            };
          });
        setTaxPer([{ options: taxPer_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "name":
        setSelectedSubCat(selected);
        setSubCategoryObject({
          ...subCategoryObject,
          maincategory: selected.value,
        });
        setErrors({
          ...errors,
          subCatError: "",
        });
        break;
      case "tax":
        setSelectedTaxPer(selected);
        setSubCategoryObject({
          ...subCategoryObject,
          taxper: selected.value,
        });
        setErrors({
          ...errors,
          taxeError: "",
        });
        break;
      default:
        break;
    }
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Subcategory Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "maincategoryname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category Type",
        field: "maincategtype",
        sort: "asc",
        width: 150,
      },
      {
        label: "Tax",
        field: "taxs",
        sort: "asc",
        width: 150,
      },
      {
        label: "HSN/SAC",
        field: "hsn_sac",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: subCategoryForTable,
  };

  function handleChangeSubCategory(e) {
    let name = e.target.name;
    let value = e.target.value;
    setsubCategoryValue(value);
    setSubCategoryObject({
      ...subCategoryObject,
      [name]: value,
    });
  }

  const handleValidSubmit = () => {
    // console.log("sub<<>>", selectedSubCat);
    // console.log("tax<<>>", selectedTaxPer.length);
    // if (selectedTaxPer.length == 0) console.log("Please Select Tax");
    // return;

    if (selectedSubCat === null || selectedTaxPer.length == 0) {
      let errorVal = errors;
      if (selectedSubCat === null)
        errorVal.subCatError = "Please Select Category";

      if (selectedTaxPer.length == 0) errorVal.taxeError = "Please Select Tax";

      setErrors({
        ...errorVal,
      });
    } else {
      if (subCategoryIdTobeUpdated) {
        // let bodydata = {
        //   id: designationIdTobeUpdated,
        //   name: designationObject.name,
        // };
        axios
          .put(`${API_URL}subCateg`, subCategoryObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Subcategory updated successfully");
              setsubCategoryValue("");
              formRef.current.reset();
              setSelectedSubCat(null);
              setSelectedTaxPer(null);
              setSubCategoryObject({});
              setSubCategoryIdToBeUpdated(null);
              setErrors({
                subCatError: "",
                taxeError: "",
              });
              handleTableData();
            } else {
              toastr.error("Failed to update Subcategory");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}subCateg`, subCategoryObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Subcategory added successfully");
              setsubCategoryValue("");
              formRef.current.reset();
              setSelectedSubCat(null);
              setSelectedTaxPer(null);
              setSubCategoryObject({});
              setSubCategoryIdToBeUpdated(null);
              setErrors({
                subCatError: "",
                taxeError: "",
              });
              handleTableData();
            } else {
              toastr.error("Failed to add Subcategory");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}subCateg` + "/" + subCategoryIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Sub Category deleted successfully");
                    if (
                      subCategoryObject &&
                      subCategoryObject._id === subCategoryIdToBeDeleted
                    ) {
                      setsubCategoryValue("");
                      formRef.current.reset();
                      setSelectedSubCat(null);
                      setSubCategoryObject({});
                      setSubCategoryIdToBeUpdated(null);
                      setErrors({
                        subCatError: "",
                        taxeError: "",
                      });
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Sub Category"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage SubCategory" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Subcategory Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Subcategory name"
                              type="text"
                              errorMessage="Enter Subcategory Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={subCategoryObject?.name}
                              onChange={handleChangeSubCategory}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Category</Label>
                            <Select
                              name="name"
                              errorMessage="Select Category"
                              value={selectedSubCat}
                              onChange={(selected) => {
                                handleSelectChange(selected, "name");
                              }}
                              options={subCatType}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedSubCat === null
                                ? errors.subCatError
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Tax</Label>
                            <Select
                              name="tax"
                              errorMessage="Select Tax"
                              value={selectedTaxPer}
                              onChange={(selected) => {
                                handleSelectChange(selected, "tax");
                              }}
                              options={taxPer}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedTaxPer == 0 ? errors.taxeError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">HSN/SAC</Label>
                            <AvField
                              style={customStyles}
                              name="hsn_sac"
                              placeholder="HSN SAC"
                              type="number"
                              errorMessage="Enter HSN SAC"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={subCategoryObject?.hsn_sac}
                              onChange={handleChangeSubCategory}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {subCategoryIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingsubCategory ? true : false}
                              >
                                {addingsubCategory ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingsubCategory ? true : false}
                              >
                                {addingsubCategory ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="SubCategoryTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default SubCategory;