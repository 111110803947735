import React, { useState, useEffect,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import Select from "react-select";
import "./style.scss"




function Taskrunninglog() {

  const formRef=useRef()

  useEffect(() => {
    fetch_all_team();
    fetch_all_staff();
    handleTableData() ;
    fetch_task();
    

  }, [])
  

  const [searchData, setsearchData] = useState({});
  const [details, setdetails] = useState([]);
  const [teamOptions, setteamOptions] = useState([]);
  const [selectedteam, setselectedteam] = useState(null);
  const [staffOptions, setstaffOptions] = useState([]);
  const [selecetdStaff, setselecetdStaff] = useState(null);
  const [task, setTask] = useState([]);
  const [flow, setFlow] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


  function fetch_all_team(){
    axios
    .get(`${API_URL}project_dashboard/team_options`,{
      headers:{
        "x-access-token":accessToken,
      },
    })
    .then((res)=>{
      console.log("team",res);
      var team =
      res.data.data &&
      res.data.data.map((el)=>{
        return{
          label:el.name,
          value:el._id
        }
      });
      setteamOptions([{options:team}])
    })

  }

  function fetch_all_staff(team_id=""){
    axios
    .get(`${API_URL}project_dashboard/staff_options?team=` + team_id,{
     headers:{
      "x-access-token":accessToken,
     }
    })
    .then((res)=>{
      var staff_op=
      res.data.data &&
      res.data.data.map((sta)=>{
       console.log("sta",sta);
       return{
        label:sta.firstName,
        value:sta._id
       }
      })
      setstaffOptions([{options:staff_op }])
    })
  }

  function fetch_task(){
    axios
    .get(`${API_URL}taskqa/taskonly/options`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res)=>{
      console.log(res)
      var Task=
       res.data.data &&
       res.data.data.map((el)=>{ 
        return{
          label:(el.task_subcategory_id.subcat_name || '') +"-"+ (el.task_name || ''),//el.task_name,
          value:el._id
        };
       });
       setTask([{options:Task}]) 
    });
  }





const handleSelectChange = (selected,name)=>{
  console.log("sele",selected);
  console.log("na",name);
  // let  from_date=searchData?.from_date? searchData.from_date:"";
  // let  to_date=searchData?.to_date? searchData.to_date:"";
  // let  team=searchData?.team? searchData.team:"";
  // let  staff=searchData?.staff? searchData.staff:"";
  // let task =searchData?.task? searchData.task:"";

  switch (name){
    case "team":
        setselectedteam(selected);
          fetch_all_staff(selected.value)
        setsearchData({
          ...searchData,
          ["team"]:selected.value
        })
      // handleTableData(staff,selected.value,from_date,to_date,task)
      break;
    case "staff" :
      setselecetdStaff(selected);
      setsearchData({
        ...searchData,
        ["staff"]:selected.value
      }) 
      // handleTableData(selected.value,team,from_date,to_date,task)
     break;
     case "task" :
      setSelectedTask(selected);
      setSelectedFlow(null);
      axios
      .get(`${API_URL}taskqa/flow/options?task_id=`+selected.value, {
        headers: {
          "x-access-token": accessToken,
          },
      })
.then((res)=>{
  if (res.data && res.data.status === 200) {
  var Flow=
   res.data.data &&
   res.data.data.map((el)=>{ 
    return{
      label:el.flow_name,
      value:el.flow_id
    };
   });
  }else{
    Flow=[];
  }
   setFlow([{options:Flow}]) 
});
      setsearchData({
        ...searchData,
        ["task"]:selected.value
      }) 
      // handleTableData(selected.value,team,from_date,to_date,task)
     break;
     case "flow" :
      setSelectedFlow(selected);
      setsearchData({
        ...searchData,
        ["flow"]:selected.value
      }) 
      // handleTableData(selected.value,team,from_date,to_date,task)
     break;



     default:
      break;
  }

}
  

  
  const handleInputChange = (e,name)=>{
    console.log(e);
    console.log(name);
 
    // let  team=searchData?.team? searchData.team:"";
    // let  staff=searchData?.staff? searchData.staff:"";
    // let  from_date=searchData?.from_date? searchData.from_date:"";
    // let  to_date=searchData?.to_date? searchData.to_date:"";
    // let task_id =searchData?.task? searchData.task:"";
    switch(name){
     
        case "FromDate":
          setsearchData({
            ...searchData,
            [e.target.name]:e.target.value
          })
          // handleTableData(staff,team,e.target.value,to_date,task_id)
          break;

        case "ToDate":
          setsearchData({
            ...searchData,
            [e.target.name]:e.target.value
          })
          // handleTableData(staff,team,from_date,e.target.value,task_id)
          break;
          // case "task" :
          //   setsearchData({
          //     ...searchData,
          //     [e.target.name]:e.target.value
          //   })
          //   // handleTableData(staff,team,from_date,to_date,e.target.value)
          //   break
          default:
          break;
    }

  }

  const handleSearch= () => {
    let  team=searchData?.team? searchData.team:"";
    let  staff=searchData?.staff? searchData.staff:"";
    let  from_date=searchData?.from_date? searchData.from_date:"";
    let  to_date=searchData?.to_date? searchData.to_date:"";
    let task_id =searchData?.task? searchData.task:"";
    let flow =searchData?.flow? searchData.flow:"";
       handleTableData(staff,team,from_date,to_date,task_id,flow)

  }

  const handleTableData =(staff="",team="",from_date="",to_date="",task="",flow="")=>{

    axios
    .get(
      `${API_URL}taskassign/tasktimeline/list?user_id=` +
      staff+
      "&team_id="+
      team+
      "&from_date="+
      from_date +
      "&to_date="+
      to_date+
      "&task_id="+
      task+
      "&flow_id="+
      flow,
      {
        headers:{
          "x-access-token":accessToken,
        }
      }   
    )
     .then((res)=>{
      console.log(res);
      let result = res.data.data
      let tabledata=[]
      result &&
      result.map((item,index)=>{
        item.id=index+1

        if(item?.date){
          item.date1=
          moment(item.date).format("DD-MM-YYYY")
        }else{
          item.date1=
          moment(item.date1).format("DD-MM-YYYY")
        }

       if(!item.project){
        item.project = "Non-Project"
       }    

       if(item?.status===1){
        item.status1=(
        <div style={{display:"flex",justifyContent:"center"}}>
          <span className="badge rounded-pill bg-primary">
            Start
          </span>
        </div>
        
        )
       }

       if(item?.status===2){
        
        item.status1=(
          <div style={{display:"flex",justifyContent:"center"}}>
            <span className="badge rounded-pill bg-danger">
              Stop
            </span>
          </div>
          
          )

       }


        tabledata.push(item)
      })
     setdetails(tabledata) 
     })
  }

const reset =()=>{

  formRef.current.reset();
  setsearchData({})
  setselecetdStaff(null)
  setselectedteam(null)
  setSelectedTask(null)
  setSelectedFlow(null)
  fetch_all_staff() ;
  fetch_all_team();
  fetch_task();
  setFlow([]); 
   handleTableData();

}
  const data ={
    columns:[
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150
      },
      {
        label: "Staff",
        field: "user_details",
        sort: "asc",
        width: 150
      },
      {
        label: "Flow",
        field: "flow_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Task",
        field: "task_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Project",
        field: "project",
        sort: "asc",
        width: 150
      },
    ],
    rows:details
  }



  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Task Log" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation"
                  ref={formRef}
                  
                  >
                    <Row>
                    <Col md="2">
                        <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          value={searchData?.from_data}
                          id="from_date"
                          name="from_date"
                          onChange={(e)=>handleInputChange(e, "FromDate")}

                        />
                      </Col>
                      <Col md="2">
                        <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_data}

                          onChange={(e)=>handleInputChange(e, "ToDate")}
                        />
                      </Col>
                      <Col md="2">
                        <div>
                          <Select
                          placeholder="Team"
                          name="team"
                          value={selectedteam}
                          onChange={(selected)=>{
                            handleSelectChange(selected,"team")
                          }}
                          options={teamOptions}
                          menuPosition={"fixed"}
                          classNamePrefix="select2-selection"/>
                          
                        </div>
                      </Col>
                      <Col md="2">
                          <Select
                          placeholder="Staff"
                          name="staff"
                          value={selecetdStaff}
                          onChange={(selected)=>{
                            handleSelectChange(selected,"staff")
                          }}
                          options={staffOptions}
                          menuPosition={"fixed"}
                          classNamePrefix="select2-selection"/>
                      </Col>
                      {/* <Col md="2">
                          <AvField
                          placeholder="Task name..."
                          name="task"
                          type="text"
                          value={searchData?.task}
                          onChange={(e)=>handleInputChange(e, "task")}
                          />
                      </Col> */}
                       <Col md="2">
                          <Select
                          placeholder="Task"
                          name="Task"
                          value={selectedTask}
                          onChange={(selected)=>{
                            handleSelectChange(selected,"task")
                          }}
                          options={task}
                          menuPosition={"fixed"}
                          classNamePrefix="select2-selection"/>
                      </Col>
                      <Col md="2">
                          <Select
                          placeholder="Flow"
                          name="Flow"
                          value={selectedFlow}
                          onChange={(selected)=>{
                            handleSelectChange(selected,"flow")
                          }}
                          options={flow}
                          menuPosition={"fixed"}
                          classNamePrefix="select2-selection"/>
                      </Col>
                    
                      <Col md="2" style={{ paddingTop: "10px",paddingBottom:"10px" }}>
                   
                           <Button
                           color="success"
                           type="submit"
                           style={{marginRight:"10px"}}
                           onClick={()=>handleSearch()}
                          >
                            {" "}
                            Filter
                           </Button>
                           <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <Row>
                     <MDBDataTable
                     responsive
                     id="tasktable"
                     bordered
                     searching={true}
                     entries={20}
                     data={data}
                     
                     />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Taskrunninglog