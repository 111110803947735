import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Resete from "@mui/icons-material/ResetTvRounded";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

//Dialogue box content's
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

//Dialogue box table content's
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Label, CardHeader } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Tax.scss";
import { Grid } from "@mui/material";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DatePicker from "@mui/lab/DatePicker";

import Modal from "react-bootstrap/Modal";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const Tax = () => {
  const [taxObject, setTaxObject] = useState({});
  const [taxIdTobeUpdated, setTaxIdToBeUpdated] = useState(null);
  const [taxIdToBeDeleted, setTaxIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [taxForTable, setTaxForTable] = useState([]);
  const [taxValue, settaxValue] = useState("");

  const [type, setType] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const [errors, setErrors] = useState({
    taxError: "",
  });

  const {
    districts,
    addingTax,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}tax/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let taxData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTax(item);
                }}></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setTaxIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName) item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName) item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          taxData.push(item);
        });
        setTaxForTable(taxData);
      });
  }

  let preUpdateTax = (item) => {
    console.log("Tax", item);
    settaxValue(item?.tax);

    let Type = item?.tax_type ? item.tax_type : "";
    let Types = {
      label: Type,
      value: Type?._id,
    };
    setSelectedType(Types);
    setTaxIdToBeUpdated(item._id);
    setTaxObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Tax Type",
        field: "tax_type",
        sort: "asc",
        width: 270,
      },
      {
        label: "Tax Name",
        field: "tax_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Tax",
        field: "tax",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: taxForTable,
  };

  function handleChangeTax(e) {
    let name = e.target.name;
    let value = e.target.value;
    settaxValue(value);
    setTaxObject({ ...taxObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (selectedType === null) {
      let errorVal = errors;
      if (selectedType === null) errorVal.taxError = "Please Select Tax Type";
      setErrors({
        ...errorVal,
      });
    } else {
      if (taxIdTobeUpdated) {
        // let bodydata = {
        //   id: designationIdTobeUpdated,
        //   name: designationObject.name,
        // };

        axios
          .put(`${API_URL}tax`, taxObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Tax updated successfully");
              settaxValue("");
              formRef.current.reset();
              handleTableData();
              setTaxObject({});
              setTaxIdToBeUpdated(null);
              setSelectedType(null);
              setErrors({
                taxError: "",
              });
            } else {
              toastr.error("Failed to update Tax");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}tax`, taxObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Tax added successfully");
              handleTableData();
              settaxValue("");
              formRef.current.reset();
              setTaxObject({});
              setSelectedType(null);
              setErrors({
                taxError: "",
              });
            } else {
              toastr.error("Failed to add Tax");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Type":
        setSelectedType(selected);
        setTaxObject({
          ...taxObject,
          tax_type: selected.label,
        });
        setErrors({
          ...errors,
          taxError: "",
        });
        break;

      default:
        break;
    }
  };

  // const [designation, setDesignation] = useState([]);
  // const getDesignation = () => {
  //   axios
  //     .post("https://erpapi.srvinfotech.com/designation")
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setDesignation(response.data);
  //         console.log(response.data);
  //       } else {
  //         toastr.error("Designation Data");
  //       }
  //     });
  // };
  // useEffect(() => getDesignation(), []);

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}tax` + "/" + taxIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Tax deleted successfully");
                    if (taxObject && taxObject._id === taxIdToBeDeleted) {
                      settaxValue("");
                      formRef.current.reset();
                      setTaxObject({});
                      setTaxIdToBeUpdated(null);
                      setErrors({
                        taxError: "",
                      });
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Tax");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}>
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Tax" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Tax Type</Label>
                            <Select
                              name="Type"
                              value={selectedType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Type");
                              }}
                              options={[
                                { label: "GST", value: 0 },
                                { label: "TDS", value: 1 },
                                { label: "Cess", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedType === null ? errors.taxError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Tax Name</Label>
                            <AvField
                              style={customStyles}
                              name="tax_name"
                              placeholder="Tax Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Tax Name"
                              value={taxObject?.tax_name}
                              onChange={handleChangeTax}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Tax</Label>
                            <AvField
                              style={customStyles}
                              name="tax"
                              placeholder="Tax"
                              type="number"
                              className="form-control"
                              id="validationCustom01"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Tax"
                              value={taxObject?.tax}
                              onChange={handleChangeTax}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {taxIdTobeUpdated ? (
                              <Button color="primary" type="submit" disabled={addingTax ? true : false}>
                                {addingTax ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button color="primary" type="submit" disabled={addingTax ? true : false}>
                                {addingTax ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}

                    {/* <Row>
                      <Col className="col-2" style={{ paddingTop: "4px" }}>
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                          >
                            
                            {"  "}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable id="taxTableId" responsive bordered data={data} searching={true} info={true} disableRetreatAfterSorting={true} entries={20} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Tax;
