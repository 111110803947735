import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, Collapse, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import Select from "react-select";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import { MDBDataTable } from "mdbreact";
import {
    getDate,
    getFirstday,
} from "../../../helpers/globalFunctions";
import "./worklog.scss";
const WorkLog = (props) => {
    const [details, setDetails] = useState([]);
    const [searchData, setSearchData] = useState({
        from_date: getDate(new Date()),
        to_date: getDate(new Date()),
        status: "",
        statusObject: { label: "All", value: "" }
    });
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    /** pagination */
    const [searchValue, setSearchValue] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(20);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
        currentPage * PerPageCount
    );

    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
        indexOfLastTransfer - PerPageCount
    );
    const paginate = (event, value) => {
        setCurrentPage(value);
        const indexOfLastTransfer = value * PerPageCount;
        const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);

        handleTableData(searchValue, searchData?.from_date, searchData?.to_date, searchData?.status, indexOfFirstTransfer, indexOfLastTransfer);
    };
    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = 20;
        const indexOfFirstTransfer = 0;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }
    const handleSearch = (value) => {
        setSearchValue(value)
        resetPaginate();
        handleTableData(value, searchData?.from_date, searchData?.to_date, searchData?.status);
    }
    useEffect(() => {
        if (details.length == 0 && indexOfFirstTransfer != 0) {
            resetPaginate();
            handleTableData(searchValue, searchData?.from_date, searchData?.to_date, searchData?.status);
        }

    }, [details])
    /** end pagination */
    const data = {
        columns: [
            {
                label: "ID",
                field: "userId",
                sort: "asc",
                width: 400,
            },
            {
                label: "Name",
                field: "firstName",
                sort: "asc",
                width: 200,
            },
            {
                label: "In",
                field: "first_in",
                sort: "asc",
                width: 200,
            },
            {
                label: "Out",
                field: "last_out",
                sort: "asc",
                width: 200,
            },
            {
                label: "Duration",
                field: "duration",
                sort: "asc",
                width: 200,
            },
            {
                label: "Efficiency",
                field: "efficiency",
                sort: "asc",
                width: 200,
            },
            {
                label: "Activity",
                field: "activity",
                sort: "asc",
                width: 200,
            },
            {
                label: "Project",
                field: "project_name",
                sort: "asc",
                width: 200,
            },

            {
                label: "Module",
                field: "category_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Task",
                field: "task_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Flow",
                field: "flow_name",
                sort: "asc",
                width: 200,
            },
        ],
        rows: details,
    };
    useEffect(() => {
        handleTableData(searchValue);
    }, []);
    let handleDate = (e, type = "") => {
        if (type == "") {
            var status = searchData?.status;
            if (e.target.name == "from_date") {
                var date1 = e.target.value;
                var date2 = searchData?.to_date;
            }
            else {
                var date1 = searchData?.from_date;
                var date2 = e.target.value;
            }
            let toDate = moment(date1);
            let fromDate = moment(date2);
            let result = fromDate.diff(toDate, "days");
            resetPaginate();

            if (result + 1 <= 0) {
                setSearchData({
                    ...searchData,
                    [e.target.name]: e.target.value,
                    ["to_date"]: "",
                });
                date2 = "";
            } else {
                setSearchData({
                    ...searchData,
                    [e.target.name]: e.target.value,
                });
            }
        }
        else {
            var date1 = searchData?.from_date;
            var date2 = searchData?.to_date;
            var status = e.value;
            setSearchData({
                ...searchData,
                ["statusObject"]: e,
                ["status"]: status,
            });
        }

        handleTableData(searchValue, date1, date2, status);
    };

    function handleTableData(search = "", fromDate = "", toDate = "", status = "", start = 0, end = 20) {
        var url = `${API_URL}activity/all_staff_work_log`;
        if (fromDate === "" && toDate === "") {
            fromDate = fromDate ? fromDate : getDate(new Date());
            toDate = toDate ? toDate : getDate(new Date());
        }
        url =
            url +
            "?from_date=" +
            fromDate +
            "&to_date=" +
            toDate + "&search=" + search + "&start=" + start + "&end=" + end + "&status=" + status;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let result = res.data.data;
                const modifiedData = result.map(item => {
                    if (item.className == 'highlight-row') {
                        return {
                            ...item,
                            firstName: (<span className="badge rounded-pill highlight-row"> {item?.firstName}</span >),
                        };
                    }
                    return item;
                });
                setTotalPages(res.data.limit);
                console.log(modifiedData)
                setDetails(modifiedData);
            });
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Staff Work Time" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <Row className="mt-3 me-2" style={{ justifyContent: "right" }}>
                                    <Col md="2">
                                        <Select
                                            name="status"
                                            value={searchData?.statusObject}
                                            onChange={(selected) => {
                                                handleDate(selected, "status");
                                            }}
                                            options={[{ "label": "Active", value: "1" }, { "label": "In active", value: "2" }, { "label": "All", value: "" }]}

                                            classNamePrefix="select2-selection"
                                        />
                                    </Col>
                                    <Col md="2">
                                        <input type="date"
                                            value={searchData?.from_date}
                                            name="from_date"
                                            className="form-control"
                                            onChange={(e) => handleDate(e)}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <input type="date"
                                            value={searchData?.to_date}
                                            name="to_date"
                                            className="form-control"
                                            onChange={(e) => handleDate(e)}
                                        />
                                    </Col>
                                </Row>
                                <CardBody>
                                    <MDBDataTable
                                        id="activityTimeReport"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={false}
                                        disableRetreatAfterSorting={true}
                                        paging={false}
                                        //onSearch={(value) => { handleSearch(value) }}
                                        entries={PerPageCount}
                                    />
                                    {totalPages > 20 && (
                                        <div className="pagination-div">
                                            <Pagination
                                                color="standard"
                                                shape="rounded"
                                                defaultPage={1}
                                                count={Math.ceil(totalPages / PerPageCount)}
                                                page={currentPage}
                                                onChange={paginate}
                                                size="large"
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default WorkLog;