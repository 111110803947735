import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Taskflow.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";


const Taskflow = () => {
  
  const [masterObject, setmasterObject] = useState({});
  const [flowIdTobeUpdated, setflowIdToBeUpdated] = useState(null);
  const [flowIdToBeDeleted, setflowIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [listForTable, setlistForTable] = useState([]);
  const [subcategValue, setSubcategValue] = useState("");
  const [checklist, setChecklist] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [flow, setFlow] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [values, setValues] = useState([
    {
      checklist_id: "",
      weightage:"",
      // priority: "",
    },
  ]);

  const [errors, setErrors] = useState({
    checklistValid: "",
  });

  const {
    districts,
    addingSubcategory,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
 
 
 
  useEffect(() => {
    handleTableData();
    fetch_checklist_id();
  }, []);

  function fetch_checklist_id() {
    axios
      .get(`${API_URL}taskchecklist/option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ChecklistID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.checklist_name,
              value: el._id,
            };
          });
        setChecklist([{ options: ChecklistID }]);
      });
  }
  function handleTableData() {
    var url = `${API_URL}taskflow/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
 
        let itemData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateFlow(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setflowIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          item.checklist = (
            <div>
              <ul style={{listStyleType: "none",paddingInlineStart:"0px"}}>
                {item.item_checklist &&
                  item.item_checklist.map((el) => {
                    let Check = el?.checklist_id || {};
                    return (
                      <li className="mb-2">
                        <span>
                          {Check?.checklist_name}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );
          
          item.weightage = (
            <div>
              <ul style={{ listStyleType: "none" ,paddingInlineStart:"0px"}}>
                {item.item_checklist &&
                  item.item_checklist.map((el) => {
                    return (
                      <li className="mb-2">
                        <span>
                          {el?.weightage}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          );


             if (item?.updatedBy) {
            let values = item?.updatedBy || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.createdBy || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          itemData.push(item);
        });

        setlistForTable(itemData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "item_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Checklist",
        field: "checklist",
        sort: "asc",
        width: 150,
      },

      {
        label: "Weightage",
        field: "weightage",
        sort: "asc",
        width: 150,
      },
      

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: listForTable,
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateFlow = (item) => {
    setflowIdToBeUpdated(item._id);
    axios
    .get(`${API_URL}taskflow/data?_id=` + item._id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      let result = res.data.data;
      let tableData = [];
      result[0].item_checklist &&
        result[0].item_checklist.map((cont, index) => {
          let val = {};
          let Flow = cont?.checklist_id || {};
         val.checklist_name = Flow?.checklist_name;
         val.checklist_id = Flow?._id;
         val.weightage = cont?.weightage;
          tableData.push(val);
        });
      setValues(tableData);
    });
    setmasterObject(item);
  };

  function handleChangeItem(e) {
    let name = e.target.name;
    let value = e.target.value;
    // setSubcategValue(value);
    setmasterObject({ ...masterObject, [name]: value });
  }
  function handleTableInputchange(e, index) {
    // console.log("e",e)
    // console.log(e.target.value)
    // const { name, value } = e.target;
    const list = [...values];
    list[index]["weightage"] = e.target.value;
    setValues(list);
    setmasterObject({ ...masterObject, item_checklist: list });
  }
  function handleChangeSelectItem(selected, index) {
    const list = [...values];
    list[index]["checklist_name"] = selected.label;
    //list[index]["checklist_name_id"] = selected.value;
    list[index]["checklist_id"] = selected.value;
    setValues(list);
    // setErrors({
    //   ...errors,
    //   checklistValid: "",
    // });
    setmasterObject({ ...masterObject, item_checklist: list });
   // console.log("list",list)
   setSelectedChecklist(selected.value)
  }
  const handleValidSubmit = () => {
 
    let total_weight =masterObject.item_checklist.reduce((n, {weightage}) => n + Number(weightage), 0)
    let checklist = true;
    let weightage = true;
    values &&
      values.forEach((element) => {
        if (element.checklist_id === "") {
            checklist = false;
        } else if (element.weightage === "") {
            weightage = false;
        }
      });

      if( checklist === false|| weightage === false){
        
        if (checklist === false) {
          toastr.error("Checklist required");
          return;
        } else if(weightage === false){
          toastr.error("Weightage required");
          return;
         }else{
          toastr.error("Total Weightage must be minimum 50%");
          return;
         }
      
    }
    
    else {
   
      if (flowIdTobeUpdated) {

        axios
          .put(`${API_URL}taskflow/`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task Flow updated successfully");
              handleTableData();
              formRef.current.reset();
              setValues([
                {
                  checklist_id: "",
                  weightage:"",
                },
              ]);
              setmasterObject({});
              setflowIdToBeUpdated(null);
            } else {
              toastr.error("Failed to update Task Flow");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}taskflow/`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task flow added succesfully");
              handleTableData();
              formRef.current.reset();
              setValues([
                {
                  checklist_id: "",
                  weightage:"",
                },
              ]);
              setmasterObject({});
            
            } else {
              toastr.error("Failed to add Task Flow");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
     }
    }
  };
  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let checkArray = [];
    list &&
      list.forEach((element) => {
        checkArray.push({checklist_id:element.checklist_id,weightage:element.weightage}); 
      });
      setmasterObject({ ...masterObject, 
      "item_checklist": checkArray});
    setValues(list);
  };

  const addMore = () => {
    setValues([
      ...values,
      { 
      checklist_id: "",
      weightage:"",},
    ]);
  };
  const reset = () => {
    handleTableData();
    formRef.current.reset();
    //   setSelectedChecklist(null);
      setValues([{
        checklist_id: "",
        weightage:"",
      }]);
      setmasterObject({});
    };
    const customStyles = {
        lineHeight: "1.8",
      };
  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}taskflow` + "/" + flowIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Task Flow deleted successfully");
                    if (
                      masterObject &&
                      masterObject._id === flowIdToBeDeleted
                    ) {
                     formRef.current.reset();
                     setmasterObject({});
                     setflowIdToBeUpdated(null);
                     setValues([{ 
                        checklist_id: "",
                        weightage:"",}]);
                      //setSubcategValue("");
                      
                      
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Task Flow"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Task Flow Items" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Item Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="item_name"
                              placeholder="Item Name"
                              type="text"
                              errorMessage="Enter Flow Item Name"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={masterObject?.item_name}
                              onChange={handleChangeItem}
                            />
                          </div>
                        </Col>

                        {/* Table Fields */}
                        {/* <Row> */}
                        <Col className="col-12">
                          <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            {/* Add Person start*/}
                            <Row>
                              <Col xl="12">
                                <Table
                                  style={{ textAlign: "center"}}
                                  id="waste_id"
                                  className="table table-bordered dataTable"
                                >
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color:"#495057",
                                        }}
                                      >
                                        No.
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "90px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color:"#495057",
                                        }}
                                      >
                                        Checklist
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color:"#495057",
                                        }}
                                      >
                                       Weightage
                                      </TableCell>
                                      {/* <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color:"#495057",
                                        }}
                                      >
                                        Priority
                                      </TableCell> */}
                                      {values.length !== 1 ? (
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "500",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057",
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values &&
                                      values.map((item, index) => (
                                        <TableRow>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{ textAlign: "center", fontSize: "12.819px",
                                            fontFamily: "IBM Plex Sans, sans-serif",
                                            color:"#495057", fontWeight: "500" }}
                                          >
                                            {index + 1}
                                          </TableCell>

                                          <TableCell>
                                            <Select
                                              onChange={(selected) =>
                                                handleChangeSelectItem(
                                                  selected,
                                                  index
                                                )
                                              }
                                              name="checklist_id"
                                              classNamePrefix="select2-selection"
                                              options={checklist}
                                              value={{
                                                label: item.checklist_name,
                                                value: item.checklist_id,
                                              }}
                                              id={`validationCustom04${index}`}
                                            />
                                           {/* <span
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                           >
                                          
                                          {selectedChecklist === null
                                          ? errors.checklistValid
                                          : ""}
                                         
                                            </span> */}

                                          </TableCell>
                                          <TableCell>
                                            <AvField
                                              name="weightage"
                                              value={item.weightage}
                                              type="number"
                                            //   validate={{
                                            //     required: { value: true },
                                            //   }}
                                            //   errorMessage="Enter Weightage"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>
                                          
                                          {values.length !== 1 && (
                                            <i
                                              className="uil uil-times"
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                paddingLeft: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                              onClick={() =>
                                                handleRemove(index)
                                              }
                                            ></i>
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </Col>
                            </Row>
                            <span
                              onClick={() => addMore()}
                              style={{ width: "190px", cursor: "pointer", fontSize: "12.819px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                              color:"#495057", fontWeight: "500"  }}
                            >
                              {" "}
                              + Add New {" "}
                            </span>
                            <br />
                            {/*Add Person End*/}
                          </CardBody>
                        </Col>
                        {/* </Row> */}
                        {/* Table Fields */}
                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {flowIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingSubcategory ? true : false}
                              >
                                {addingSubcategory ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingSubcategory ? true : false}
                              >
                                {addingSubcategory ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() =>
                                reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <MDBDataTable
                      id="TaskFlowTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Taskflow;