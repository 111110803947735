import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from 'gantt-task-react';

const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked }) => {
  return (
    <div className="ViewContainer mb-3 " style={{ display: "flex", justifyContent: "flex-end" }}>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Hour
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        1/4 of Day
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        1/2 of Day
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        Day
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Week
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Month
      </button>
      <button
        className="Button"
        style={{ marginRight: "10px", backgroundColor: "#cccccc", border: "none", color: "black" }}
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        Year
      </button>

      <div className="Switch" style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
        <label className="Switch_Toggle" style={{ marginRight: "10px" }}>
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
      </div>
      <span style={{ marginTop: "8px" }}>Show Task</span>
    </div>
  );
};

export default ViewSwitcher;
