import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./AddClient.scss";
import { Checkbox, Grid } from "@mui/material";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const AddClient = () => {
  const [addClientObject, setAddClientObject] = useState({});
  const [addClientIdTobeUpdated, setAddClientIdToBeUpdated] = useState(null);
  const [addClientIdToBeDeleted, setAddClientIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [addClientForTable, setAddClientForTable] = useState([]);
  const [addClientValue, setaddClientValue] = useState("");

  const [addClient, setAddClient] = useState([]);
  const [selectedAddClient, setSelectedAddClient] = useState(null);

  const [clientName, setClientName] = useState([]);
  const [selectedClientName, setSelectedClientName] = useState(null);

  const [clientCate, setClientCate] = useState([]);
  const [selectedClientCate, setSelectedClientCate] = useState(null);

  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [state, setState] = useState([]);
  const [selectedSate, setSelectedState] = useState(null);

  const [selectedType, setSelectedType] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [uploadProgress, setUploadProgress] = useState();

  const [desigItemOptions, setDesigItemOptions] = useState([]);
  const [categItemOptions, setCategItemOptions] = useState([]);

  const [admin, setAdmin] = useState(false);
  const [billing, setBilling] = useState(false);
  const [support, setSupport] = useState(false);

  const [pjl, setPjl] = useState([]);

  const {
    districts,
    addingAddClient,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  let history = useHistory();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    // fetch_all_ClientName();
    fetch_all_ClientCate();
    fetch_all_Country();
    fetch_Categ_item_list();
  }, []);

  const [values, setValues] = useState([
    {
      names: "",
      designations: "",
      mobiles: "",
      landlines: "",
      emails: "",
      ["preferences"]: [],
    },
  ]);

  const dispatch = useDispatch();

  function handleTableData() {
    var url = `${API_URL}client/clientlist`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let ProductData = [];

        result.map((item, index) => {
          if(typeof item.reg_date!="undefined" && item.reg_date!="")
          {
            item.reg_date = moment(item.reg_date,'YYYY-MM-DD').format("DD-MM-YYYY")
          }
          

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/add_client/" + item._id);
                }}
              ></i> */}
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateAddClient(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  setAddClientIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          // let name_value = item?.name[0] || {};
          // item.name = name_value.name;

          // let contact = item?.contact[0] || {};
          // item.contacts = contact?.name;

          // let cate_value = item?.client_categ[0] || {};
          // item.category = cate_value.client_category;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }
          ProductData.push(item);
        });
        setAddClientForTable(ProductData);
      });
  }
  let preUpdateAddClient = (item) => {
    formRef.current.reset();
    setAdmin(false);
    setBilling(false);
    setSupport(false);
    setAddClientIdToBeUpdated(item._id);
    setaddClientValue(item?.name);

    axios
      .get(`${API_URL}client/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((element, index) => {
            let dept = element?.department ? element.department : {};
            let departmentValue = {
              label: dept?.depart_Name,
              value: dept?._id,
            };

            let ClientStat = element?.client_current_status;
            let clientStatu = {
              label: ClientStat,
              value: ClientStat?._id,
            };

            let ClientType = element?.client_type;
            let clientTypes = {
              label: ClientType,
              value: ClientType?._id,
            };

            let countr = element?.country ? element.country[0] : {};
            let Country = countr?.name;
            let Countrys = {
              label: Country,
              value: countr?.id,
            };
            
            let State = element?.state[0] || {};
            let states = State?.name;
            let Stat = {
              label: states,
              value: State?.id,
            };
            element.state = State?.id;
            element.country = countr?.id;

            let ClientCateg = element?.client_cat_id[0] || {};
            let ClientCate = ClientCateg?.client_category;
            let ClientCat = {
              label: ClientCate,
              value: ClientCate?._id,
            };
            element.client_cat_id = ClientCateg?._id;
            let tableData = [];
            element.contact &&
              element.contact.map((cont, index) => {
                let val = {};

                val.names = element.contact[index]?.name;
                val.designations = element.contact[index]?.designation;
                val.mobiles = element.contact[index]?.mobile;
                val.landlines = element.contact[index]?.landline;
                val.emails = element.contact[index]?.email;

                let Admin = 0;
                let Billing = 0;
                let Support = 0;
                cont.preferences &&
                  cont.preferences.map((check) => {
                    // console.log("Pref:", check);
                    if (check == "Admin") Admin = 1;

                    if (check == "Billing") Billing = 1;

                    if (check == "Support") Support = 1;
                  });
                val.preferences = cont.preferences;
                val.admin = Admin;
                val.billing = Billing;
                val.support = Support;

                setPjl(cont.preferences);
                tableData.push(val);
              });
              element.contact_persons = tableData;
            setValues(tableData);
            setSelectedState(Stat);
            setSelectedCountry(Countrys);
            setSelectedClientCate(ClientCat);
            setSelectedType(clientTypes);
            setSelectedStatus(clientStatu);

            

            // setSelectedClientCate(categoryValue);

            setSelectedAddClient(departmentValue);
          });
        setAddClientObject(...result);
      });
  };

  // function fetch_all_ClientName() {
  //   axios
  //     .get(`${API_URL}client/options`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       var clientName_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el.client_name,
  //             value: el._id,
  //           };
  //         });
  //       setClientName([{ options: clientName_data }]);
  //     });
  // }
  function fetch_all_ClientCate() {
    axios
      .get(`${API_URL}client/category`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var clientCate_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.client_category,
              value: el._id,
            };
          });
        setClientCate([{ options: clientCate_data }]);
      });
  }

  function fetch_all_Country() {
    axios
      .get(`${API_URL}leads/Countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Country_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });
        setCountry([{ options: Country_data }]);
      });
  }

  const fetch_all_states = (item) => {
    axios
      .get(`${API_URL}states/list?country_id=` + item.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var State_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setState([{ options: State_data }]);
      });
  };

  // const fetch_all_states=(item)=> {
  //   axios
  //     .get(`${API_URL}states/list?country_id=`+ item.value, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       var State_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el.name,
  //             value: el._id,
  //           };
  //         });
  //       setState([{ options: State_data }]);
  //     });
  // }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "ClientName":
      //   setSelectedClientName(selected);
      //   setAddClientObject({
      //     ...addClientObject,
      //     client_name: selected.value,
      //   });
      //   break;
      case "ClientCate":
        setSelectedClientCate(selected);
        setAddClientObject({
          ...addClientObject,
          client_cat_id: selected.value,
        });
        break;
      case "Country":
        setSelectedCountry(selected);
        fetch_all_states(selected);
        setAddClientObject({
          ...addClientObject,
          country: selected.value,
        });
        break;
      case "State":
        setSelectedState(selected);
        setAddClientObject({
          ...addClientObject,
          state: selected.value,
        });
        break;
      case "Type":
        setSelectedType(selected);
        setAddClientObject({
          ...addClientObject,
          client_type: selected.label,
        });
        break;
      case "Status":
        setSelectedStatus(selected);
        setAddClientObject({
          ...addClientObject,
          client_current_status: selected.label,
        });
        break;
      default:
        break;
    }
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Date",
      //   field: "reg_date",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Short Name",
        field: "short_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Domain",
        field: "domain",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Category",
      //   field: "category",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Type",
        field: "client_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Email",
      //   field: "email",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Status",
        field: "client_current_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: addClientForTable,
  };

  function handleChangeProduct(e) {
    let name = e.target.name;
    let value = e.target.value;
    setaddClientValue(value);
    setAddClientObject({ ...addClientObject, [name]: value });
  }

  const handleValidSubmit = () => {
    // console.log(addClientObject);
    // return;
    if (addClientIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}client/`, addClientObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })

        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Client updated successfully");
            formRef.current.reset();
            setaddClientValue("");
            setSelectedType("");//
        
            setClientCate("");//
            setAddClientObject({})
           // formRef.current.reset();
            handleTableData();
            setValues([
              {
                names: "",
                designations: "",
                mobiles: "",
                landlines: "",
                emails: "",
                ["preferences"]: [],
              },
            ]);
           
            setAddClientObject({
              ["reg_date"]: "",
              ["end_date"]: "",
              ["renewal_date"]: "",
            });
            setAddClientObject({
              date: "",
            });
            setAdmin(false);
            setBilling(false);
            setSupport(false);
            setAddClientIdToBeUpdated(null);
            setSelectedClientCate("")
            setSelectedStatus(null);
            setSelectedCountry(null);
            setSelectedState(null);
            setSelectedAddClient(null);
            setSelectedClientName(null);
          } else {
            toastr.error("Failed to update Client");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      // console.log(addClientObject);
      axios
        .post(`${API_URL}client`, addClientObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Client added successfully");
            formRef.current.reset();
            handleTableData();
            setaddClientValue("");
            setValues([
              {
                names: "",
                designations: "",
                mobiles: "",
                landlines: "",
                emails: "",
                ["preferences"]: [],
              },
            ]);
         
            setAddClientObject({
              ["reg_date"]: 0,
              ["end_date"]: 0,
              ["renewal_date"]: 0,
            });
  
            // setAddClientObject({
            //   date: "",
            // });
            setAdmin(false);
            setBilling(false);
            setSupport(false);
            setClientCate(null);
            setSelectedType(null);
            setSelectedClientCate(null);
            setSelectedStatus(null);
            setSelectedCountry(null);
            setSelectedState(null);
            setSelectedAddClient(null);
            setSelectedClientName(null);
          } else {
            toastr.error("Failed to add Client");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const customStyles = {
    lineHeight: "1.8",
    borderColor: "#70606030",
  };
  const customStyles1 = {
    borderColor: "#e5e5e5",
    height: "auto",
  };

  const reset = () => {
    formRef.current.reset();
  };

  const addMore = () => {
    setValues([
      ...values,
      {
        names: "",
        designations: "",
        mobiles: "",
        landlines: "",
        emails: "",
        ["preferences"]: [],
      },
    ]);
  };
  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];
    let freebagArray = [];

    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
        freebagArray.push(element.freeBag);
      });

    setValues(list);
  };

  const uploadScreenshot = (e) => {
    const fd = new FormData();
    fd.append("client_image", e.target.files[0]);
    axios
      .post(`${API_URL}client/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAddClientObject({
            ...addClientObject,
            [`logo`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteScreenshot = () => {
    setAddClientObject({
      ...addClientObject,
      [`logo`]: "",
    });
  };


  const fetch_Categ_item_list = () => {
    axios
      .get(`${API_URL}client/category`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        var categ_data =
          result &&
          result.map((el) => {
            return {
              label: el.client_category,
              value: el._id,
            };
          });

        setCategItemOptions([
          {
            options: categ_data,
          },
        ]);
      });
  };

  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target;
      const list = [...values];
    
    list[index][name] = checked === true ? 1 : 0;
    setValues(list);

    let data = pjl;
    var olf_pref = list[index]["preferences"];
    // console.log("olf_pref:", olf_pref);
    if (list[index][name] == 1) {
      var flag = 0;
      for (var i = 0; i < olf_pref.length; i++) {
        if (olf_pref[i] == "Admin") {
          flag = 1;
        }
      }
      if (flag != 1) {
        olf_pref.push("Admin");
      }
    } else {
      olf_pref.pop("Admin");
    }

    // const list = [...values];

    // let data = pjl;
    // if (admin === true) {
    //   setAdmin(false);
    //   data.pop(e.target.value);
    // } else {
    //   setAdmin(true);
    //   data.push(e.target.value);
    // }
    // list[index]["preferences"] = data;
    list[index]["preferences"] = olf_pref;
    // console.log(olf_pref);
    setAddClientObject({ ...addClientObject, contact_persons: list });
  };
  const handleCheckboxChange1 = (e, index) => {
    const { name, checked } = e.target;
    const list = [...values];
    list[index][name] = checked === true ? 1 : 0;
    setValues(list);
    let data = pjl;

    var olf_pref = list[index]["preferences"];

    if (list[index][name] == 1) {
      var flag = 0;
      for (var i = 0; i < olf_pref.length; i++) {
        if (olf_pref[i] == "Billing") {
          flag = 1;
        }
      }
      if (flag != 1) {
        olf_pref.push("Billing");
      }
    } else {
      olf_pref.pop("Billing");
    }
    // const list = [...values];
    // let data = pjl;
    // if (billing === true) {
    //   setBilling(false);
    //   data.pop(e.target.value);
    // } else {
    //   setBilling(true);
    //   data.push(e.target.value);
    // }
    // list[index]["preferences"] = data;
    list[index]["preferences"] = olf_pref;
    // console.log(olf_pref);
    setAddClientObject({ ...addClientObject, contact_persons: list });
  };
  const handleCheckboxChange2 = (e, index) => {
    const { name, checked } = e.target;
    const list = [...values];
    list[index][name] = checked === true ? 1 : 0;
    setValues(list);

    let data = pjl;
    var olf_pref = list[index]["preferences"];

    if (list[index][name] == 1) {
      var flag = 0;
      for (var i = 0; i < olf_pref.length; i++) {
        if (olf_pref[i] == "Support") {
          flag = 1;
        }
      }
      if (flag != 1) {
        olf_pref.push("Support");
      }
    } else {
      olf_pref.pop("Support");
    }
    // if (support === true) {
    //   setSupport(false);
    //   data.pop(e.target.value);
    // } else {
    //   setSupport(true);
    //   data.push(e.target.value);
    // }
    list[index]["preferences"] = olf_pref;
    // console.log(olf_pref);
    setAddClientObject({ ...addClientObject, contact_persons: list });
  };

  function handleChangeSelectItem(selected, index) {
    const list = [...values];

    list[index]["desigName"] = selected.label;
    list[index]["desigName_id"] = selected.value;
    list[index]["designation"] = selected.value;

    // list[index]["categName"] = selected.label;
    // list[index]["categName_id"] = selected.value;
    // list[index]["categ"] = selected.label;
    // setValues(list);
    // let valueArray = [];
    // list &&
    //   list.forEach((element) => {
    //     valueArray.push(element.wastename_id);
    //   });

    setAddClientObject({ ...addClientObject, contact_persons: list });
  }

  function handleTableInputchange(e, index) {
    const { name, value } = e.target;

    const list = [...values];

    list[index][name] = value;

    setValues(list);

    // let valueArray = [];
    // list &&
    //   list.forEach((element) => {
    //     valueArray.push(element.freeBag);
    //   });
    setAddClientObject({ ...addClientObject, contact_persons: list });
  }
  // console.log("Value:", values);

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}client` + "/" + addClientIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Client deleted successfully");
                    if (
                      addClientObject &&
                      addClientObject._id === addClientIdToBeDeleted
                    ) {
                      setaddClientValue("");
                      formRef.current.reset();

                      setAddClientObject({});
                      setAddClientIdToBeUpdated(null);
                      setSelectedAddClient(null);
                      setSelectedClientName(null);
                    }
                    setAddClientIdToBeDeleted(null);
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Client");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add Client" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label>Client Name</Label>
                            <Select
                              name="client_name"
                              value={selectedClientName}
                              onChange={(selected) => {
                                handleSelectChange(selected, "category");
                              }}
                              options={clientName}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Registration Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="reg_date"
                              name="reg_date"
                              required={true}
                              value={addClientObject?.reg_date}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              End Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="end_date"
                              name="end_date"
                              required={true}
                              value={addClientObject?.end_date}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Renewal Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="renewal_date"
                              name="renewal_date"
                              required={true}
                              value={addClientObject?.renewal_date}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                             Client Priority
                            </Label>    
                             <AvField
                              style={customStyles}
                              name="client_priority"
                              placeholder="Priority"
                              type="number"
                              errorMessage="Enter Priority"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.client_priority}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Client Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Client Name"
                              type="text"
                              errorMessage="Enter Client Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.name}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Short Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="short_name"
                              placeholder="Short Name"
                              type="text"
                              errorMessage="Enter Short Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.short_name}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Domain Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="domain"
                              placeholder="Domain Name"
                              type="text"
                              errorMessage="Enter Domain Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.domain}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Client Category</Label>
                            <Select
                              name="ClientCate"
                              value={selectedClientCate}
                              onChange={(selected) => {
                                handleSelectChange(selected, "ClientCate");
                              }}
                              options={clientCate}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Client Status</Label>
                            <Select
                              name="Status"
                              value={selectedStatus}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Status");
                              }}
                              options={[
                                { label: "Active", value: 0 },
                                { label: "In-Active", value: 1 },
                                { label: "Suspended", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Client Type</Label>
                            <Select
                              name="Type"
                              value={selectedType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Type");
                              }}
                              options={[
                                {
                                  label: "Own",
                                  value: 0,
                                },
                                {
                                  label: "Outsource",
                                  value: 1,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Mobile Number
                            </Label>
                            <AvField
                              style={customStyles}
                              name="mobile"
                              placeholder="Mobile Number"
                              type="text"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Mobile number",
                                },
                              }}
                              id="validationCustom01"
                              value={addClientObject?.mobile}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Landline Number
                            </Label>
                            <AvField
                              style={customStyles}
                              name="landline"
                              placeholder="Landline Number"
                              type="text"
                            //   minLength={11}
                            //   maxLength={11}
                              className="form-control"
                              // errorMessage="Enter valid 11 digit number"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.landline}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Email</Label>
                            <AvField
                              style={customStyles}
                              name="email"
                              placeholder="Email"
                              type="email"
                              errorMessage="Enter Email"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.email}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Country</Label>
                            <Select
                              name="Country"
                              value={selectedCountry}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Country");
                              }}
                              options={country}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>State</Label>
                            <Select
                              name="State"
                              value={selectedSate}
                              onChange={(selected) => {
                                handleSelectChange(selected, "State");
                              }}
                              options={state}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">City</Label>
                            <AvField
                              style={customStyles}
                              name="city"
                              placeholder="City"
                              type="text"
                              errorMessage="Enter City"
                              className="form-control"
                             // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.city}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">PIN/ZIP</Label>
                            <AvField
                              style={customStyles}
                              name="pin"
                              placeholder="PIN/ZIP"
                              type="number"
                              errorMessage="Enter PIN/ZIP"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.pin}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Latitude</Label>
                            <AvField
                              style={customStyles}
                              name="latitude"
                              placeholder="latitude"
                              type="number"
                              errorMessage="Enter latitude"
                              className="form-control"
                             // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.latitude}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Longitude
                            </Label>
                            <AvField
                              style={customStyles}
                              name="longitude"
                              placeholder="Longitude"
                              type="number"
                              errorMessage="Enter Longitude"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.longitude}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Upload Photo
                            </Label>
                            {addClientObject?.logo ? (
                              <div div className="img-wraps">
                                {addClientObject.logo &&
                                addClientObject.logo.startsWith(
                                  "http"
                                ) ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${addClientObject.logo}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}/uploads/client_images/${addClientObject?.logo}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteScreenshot}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadScreenshot}
                                rows="1"
                              />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Address</Label>
                            <AvField
                              style={customStyles1}
                              name="address"
                              placeholder="Address"
                              type="textarea"
                              errorMessage="Enter Address"
                              className="form-control"
                              //validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={addClientObject?.address}
                              onChange={handleChangeProduct}
                            />
                          </div>
                        </Col>

                        {/* Table Fields */}
                        {/* <Row> */}
                        <Col className="col-12">
                          <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            {/* Add Person start*/}
                            <Row>
                              <Col xl="12">
                                <Table
                                  style={{ textAlign: "center" }}
                                  id="waste_id"
                                  className="table table-bordered dataTable"
                                >
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                        }}
                                      >
                                        No.
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "90px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Designation
                                      </TableCell>
                                      {/* <TableCell
                                          style={{
                                            width: "123px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Category
                                        </TableCell> */}
                                      <TableCell
                                        style={{
                                          width: "80px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Mobile No
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "80px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Landline No
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "80px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Email ID
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "120px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Preference
                                      </TableCell>

                                      {values.length !== 1 ? (
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values &&
                                      values.map((item, index) => (
                                        <TableRow>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{ textAlign: "center" }}
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell>
                                            <AvField
                                              name="names"
                                              value={item.names}
                                              type="text"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              // errorMessage="Enter Name"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>
                                          {/* <TableCell>
                                            <Select
                                              onChange={(selected) =>
                                                handleChangeSelectItem(
                                                  selected,
                                                  index
                                                )
                                              }
                                              name="desigName"
                                              classNamePrefix="select2-selection"
                                              options={desigItemOptions}
                                              value={{
                                                label: item.desigName,
                                                value: item.desigName_id,
                                              }}
                                              id={`validationCustom04${index}`}
                                            />
                                          </TableCell> */}
                                          <TableCell>
                                            <AvField
                                              name="designations"
                                              value={item.designations}
                                              type="text"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              // errorMessage="Enter Designation"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>

                                          <TableCell>
                                            <AvField
                                              name="mobiles"
                                              value={item.mobiles}
                                              type="text"
                                              // validate={{
                                              //   required: {
                                              //     value: true,
                                              //     errorMessage:
                                              //       "Enter Mobile number",
                                              //   },
                                              //   minLength: {
                                              //     value: 10,
                                              //     errorMessage:
                                              //       "Enter valid 10 digit number",
                                              //   },
                                              //   maxLength: {
                                              //     value: 10,
                                              //     errorMessage:
                                              //       "Enter valid 10 digit number",
                                              //   },
                                              // }}
                                              className="form-control"
                                              id="validationCustom01"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <AvField
                                              name="landlines"
                                              value={item.landlines}
                                              type="text"
                                              className="form-control"
                                              id="validationCustom01"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <AvField
                                              name="emails"
                                              value={item.emails}
                                              type="email"
                                              // validate={{
                                              //   required: { value: true },
                                              // }}
                                              // errorMessage="Enter Email ID"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>

                                          <TableCell>
                                            <div
                                              style={{
                                                textAlign: "center",
                                                fontSize: "small",
                                              }}
                                            >
                                              <Label
                                                style={{
                                                  fontSize: "smaller",
                                                  paddingRight: "1%",
                                                }}
                                              >
                                                Admin
                                              </Label>
                                              <input
                                                type="checkbox"
                                                className="mb-2"
                                                name="admin"
                                                id="admin"
                                                // value="Admin"
                                                checked={
                                                  item?.admin === 1
                                                    ? true
                                                    : false
                                                }
                                                // control={<Checkbox />}
                                                onChange={(e) =>
                                                  handleCheckboxChange(e, index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                              />

                                              <Label
                                                style={{
                                                  fontSize: "smaller",
                                                  paddingRight: "1%",
                                                }}
                                              >
                                                Billing
                                              </Label>
                                              <input
                                                type="checkbox"
                                                className="mb-2"
                                                name="billing"
                                                id="billing"
                                                // value="Billing"
                                                checked={
                                                  item?.billing === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange1(
                                                    e,
                                                    index
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                              />
                                              {/* </div> */}

                                              {/* <div style={{ textAlign: "start" }}> */}
                                              <Label
                                                style={{
                                                  fontSize: "smaller",
                                                  paddingRight: "1%",
                                                }}
                                              >
                                                Support
                                              </Label>
                                              <input
                                                type="checkbox"
                                                className="mb-2"
                                                name="support"
                                                // value="Support"
                                                id="support"
                                                checked={
                                                  item?.support === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange2(
                                                    e,
                                                    index
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                              />
                                            </div>
                                          </TableCell>
                                          {values.length !== 1 && (
                                            <i
                                              className="uil uil-times"
                                              style={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                                paddingLeft: "5px",
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                              onClick={() =>
                                                handleRemove(index)
                                              }
                                            ></i>
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </Col>
                            </Row>
                            <span
                              onClick={() => addMore()}
                              style={{ width: "190px", cursor: "pointer" }}
                            >
                              {" "}
                              + Add New Person{" "}
                            </span>
                            <br />
                            {/*Add Person End*/}
                          </CardBody>
                        </Col>
                        {/* </Row> */}
                        {/* Table Fields */}

                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {addClientIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingAddClient ? true : false}
                              >
                                {addingAddClient ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingAddClient ? true : false}
                              >
                                {addingAddClient ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="AddClientTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default AddClient;