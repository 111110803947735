import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import $ from "jquery";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./RenewalReport.scss";

import { CSVLink } from "react-csv";
import {
  getDate
} from "../../../helpers/globalFunctions";
const RenewalReport = () => {
  const [searchData, setSearchData] = useState({ year: new Date().getFullYear() })
  const [details, setDetails] = useState([]);
  const [yearOptions, setYearOption] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_year();
    handleTableData();
  }, []);

  const reset = () => {
    setSelectedStatus(null);
    setSelectedView(null);
    setSelectedYear(
      {
        label: new Date().getFullYear(),
        value: new Date().getFullYear()
      }
    );
    setSearchData({
      year: new Date().getFullYear(),
      status: ""
    });
    handleTableData(new Date().getFullYear(), "");
  };

  const fetch_year = () => {
    var list = [];
    axios
      .get(`${API_URL}client/renewalyear`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var yearData =
          res.data.data &&
          res.data.data.map((el) => {
            list.push(el._id);
            return {
              label: el._id,
              value: el._id
            };
          });
        list.sort();
        if (list.indexOf(new Date().getFullYear().toString()) >= 0) {
          var firstYear = new Date().getFullYear()
        }
        else {
          var firstYear = list[0];
        }
        setYearOption([{ options: yearData }]);

        let status = searchData?.status ? searchData.status : "";
        if (yearData && yearData.length > 0) {
          setSelectedYear({ label: firstYear, value: firstYear });
          setSearchData({
            ...searchData,
            ["year"]: firstYear,
          });
          handleTableData(firstYear, status);
        } else handleTableData(new Date().getFullYear(), status)
      });
  }

  const handleTableData = (year = new Date().getFullYear(), status = "", view = "") => {

    var url = `${API_URL}client/renewal`;
    url =
      url +
      "?year=" +
      year +
      "&status=" +
      status;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let itemTable = [];
        let dataToBeExported = [];

        result.map((item, index) => {
          let exportItem = {};

          item.id = item._id;


          item.jan = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {

                  if (el.month == "01") {

                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }
            </ul>
          )
          item.feb = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "02") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>)
          item.mar = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "03") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>)
          item.apr = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "04") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>)
          item.may = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "05") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>)
          item.jun = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "06") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }
            </ul>)
          item.jul = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "07") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>)
          item.aug = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "08") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>
          )
          item.sep = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "09") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }</ul>
          )
          item.oct = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "10") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }
            </ul>
          )
          item.nov = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "11") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }
            </ul>
          )
          item.dec = (
            <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
              {item.data &&
                item.data.map((el) => {
                  if (el.month == "12") {
                    if (view === "Name") {
                      return (
                        el.name && el.name.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        })
                      )
                    }
                    else if (view === "Domain") {
                      return (
                        el.domain && el.domain.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                    else {
                      return (
                        el.client && el.client.map((e) => {
                          return (
                            <li className="mb-2">
                              <span>
                                {e}
                              </span>
                            </li>
                          );
                        }))

                    }
                  }
                }
                )
              }
            </ul>
          )


          //export data
          exportItem.id = item.id;
          let January = (
            item?.data &&
            item?.data.map((el) => {
              if (el.month == "01") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.January = January.filter(Boolean)
          let February = (
            item?.data &&
            item?.data.map((el) => {
              if (el?.month === "02") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.February = February.filter(Boolean)
          let March = (
            item?.data &&
            item?.data.map((el) => {
              if (el?.month === "03") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.March = March.filter(Boolean)

          let April = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "04") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.April = April.filter(Boolean)
          let May = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "05") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.May = May.filter(Boolean)

          let June = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "06") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.June = June.filter(Boolean)

          let July = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "07") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.July = July.filter(Boolean)

          let August = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "08") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.August = August.filter(Boolean)

          let September = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "09") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.September = September.filter(Boolean)

          let October = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "10") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.October = October.filter(Boolean)

          let November = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "11") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.November = November.filter(Boolean)

          let December = (
            item.data &&
            item.data.map((el) => {
              if (el.month == "12") {
                if (view === "Name") { return (el?.name) }
                else if (view === "Domain") { return (el?.domain) }
                else { return (el?.client) }
              }
            }
            ));
          exportItem.December = December.filter(Boolean)

          itemTable.push(item);
          dataToBeExported.push(exportItem);
        });
        setDetails(itemTable)
        setDataToBeExported(dataToBeExported);
      });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "status":
        let year = searchData?.year ? searchData.year : new Date().getFullYear();
        let view1 = searchData?.view ? searchData.view : "";
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          ["status"]: selected.value,
        });
        handleTableData(year, selected.value, view1);
        break;
      case "year":
        let status = searchData?.status ? searchData.status : "";
        let view = searchData?.view ? searchData.view : "";;
        setSelectedYear(selected);
        setSearchData({
          ...searchData,
          ["year"]: selected.value,
        })
        handleTableData(selected.value, status);
        break;
      case "view":
        let year1 = searchData?.year ? searchData.year : new Date().getFullYear();
        let status1 = searchData?.status ? searchData.status : "";
        setSelectedView(selected);
        setSearchData({
          ...searchData,
          ["view"]: selected.label,
        })
        handleTableData(year1, status1, selected.label);
        break;
      default:
        break;
    }
  }
  const data = {
    columns: [
      {
        label: "Day",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jan",
        field: "jan",
        sort: "asc",
        width: 150,
      },
      {
        label: "Feb",
        field: "feb",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mar",
        field: "mar",
        sort: "asc",
        width: 150,
      },
      {
        label: "Apr",
        field: "apr",
        sort: "asc",
        width: 150,
      },

      {
        label: "May",
        field: "may",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jun",
        field: "jun",
        sort: "asc",
        width: 270,
      },
      {
        label: "Jul",
        field: "jul",
        sort: "asc",
        width: 150,
      },

      {
        label: "Aug",
        field: "aug",
        sort: "asc",
        width: 150,
      },

      {
        label: "Sep",
        field: "sep",
        sort: "asc",
        width: 150,
      },
      {
        label: "Oct",
        field: "oct",
        sort: "asc",
        width: 270,
      },
      {
        label: "Nov",
        field: "nov",
        sort: "asc",
        width: 150,
      },

      {
        label: "Dec",
        field: "dec",
        sort: "asc",
        width: 150,
      },

    ],
    rows: details
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Renewal Report" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <Select
                              name="status"
                              placeholder="Status"
                              value={selectedStatus}
                              classNamePrefix="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "status");
                              }}
                              options={[
                                { label: "Active", value: 0 },
                                { label: "In-Active", value: 1 },
                                { label: "Suspended", value: 2 },
                              ]}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Select
                              name="year"
                              placeholder="Year"
                              value={selectedYear}
                              options={yearOptions}
                              classNamePrefix="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "year");
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Select
                              name="view"
                              placeholder="View Type"
                              value={selectedView}
                              classNamePrefix="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "view");
                              }}
                              options={[
                                { label: "Name", value: 0 },
                                { label: "Shortname", value: 1 },
                                { label: "Domain", value: 2 },
                              ]}
                            />
                          </div>
                        </Col>
                        <Col md="1">

                          <Button color="success" type="submit">
                            {/* <i className="mdi mdi-microsoft-excel"></i> */}
                            <CSVLink
                              data={dataToBeExported}

                              filename={
                                "Renewal_plan_" + getDate(new Date()) + ".xls"
                              }
                              style={{ color: "white" }}
                            >
                              Export
                            </CSVLink>
                          </Button>

                        </Col>
                        <Col md="1">

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>

                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable paging={false} sortable={false} searching={false} id="renew_report" responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default RenewalReport;