import firebase from "firebase/compat/app";
import { getMessaging,getToken } from "firebase/messaging";
import { setBrowserToken } from "./helpers/globalFunctions";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_NOTI_APIKEY,
  authDomain: process.env.REACT_APP_NOTI_AUTHDOMAIN,
  projectId: process.env.REACT_APP_NOTI_PROJECTID,
  storageBucket: process.env.REACT_APP_NOTI_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_NOTI_MSG_SENDER,
  appId: process.env.REACT_APP_NOTI_APPID,
  measurementId: process.env.REACT_APP_NOTI_MEASUREMENTID,
};
function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const app = firebase.initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging,{vapidKey:process.env.REACT_APP_NOTI_VAPIDKEY})
        .then((currentToken)=>{
            setBrowserToken(currentToken);
            // if(currentToken)
            // {
            //     console.log("token: "+currentToken);
            // }
            // else{
            //     console.log("Cannot get token");
            // }
        })
      }
      else{
        console.log('Do not have permission');
      }
    })
}
var location = window.location.href;
if(location.indexOf("/tv-project-dashboard/")<0 && location.indexOf("/tv-staff-dashboard/")<0 && location.indexOf("/tv-event-dashboard/")<0)
requestPermission();