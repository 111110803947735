import {
  GET_MENTIONS,
  GET_MENTIONS_FAIL,
  GET_MENTIONS_SUCCESS,
} from './actionTypes';

export const getAllMenstions = () => ({
  type: GET_MENTIONS
});

export const getAllMenstionsSuccess = (menstion) => ({
  type: GET_MENTIONS_SUCCESS,
  payload: menstion,
});

export const getAllMenstionsFail = (error) => ({
  type: GET_MENTIONS_FAIL,
  payload: error,
});