import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Progress,
  PopoverBody,
  UncontrolledPopover,
  Button,
  Input,
} from "reactstrap";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { useHistory } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import "./style.scss";
import $ from "jquery";
import { MDBDataTable } from "mdbreact";
import {
  timeSince,
  timeFromNumber,
  timeInMinute,
} from "../../helpers/globalFunctions";
import moment from "moment";
import badge1 from "../../assets/images/badge/efficiency.svg";
import badge2 from "../../assets/images/badge/productivity.svg";
import badge3 from "../../assets/images/badge/time.svg";
import badge4 from "../../assets/images/badge/quality.svg";
import badge5 from "../../assets/images/badge/adaptability.svg";
import personIcon from "../../assets/images/person-icon.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import {Button, TextField} from '@material-ui/core';
import { Button as MaterialUIButton, TextField } from "@material-ui/core";
import { AvForm } from "availity-reactstrap-validation";
import Todolist from "../Todo/todolist";
import io from 'socket.io-client';
const ProjectDashboard = (props) => {
  const useRid = JSON.parse(localStorage.getItem("authUser"))._id;

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [userId, setUserId] = useState();
  const [tasks, setTasks] = useState([]);
  const [startedTasks, setStartedTasks] = useState({});
  const [taskStage, setTaskStage] = useState(null);
  const [runningTask, setRunningTask] = useState(null);
  let history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [attachCount, setAttachCount] = useState("");
  const [msgObject, setMsgObject] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatboxData, setChatboxData] = useState({});
  const [isProjectChat, setisProjectChat] = useState(false);
  const [details, setDetails] = useState({ productivity: 0 });
  const [userImage, SetUserImage] = useState(null);

  const bottomRef = useRef();

  const handleShow = (item, index) => {
    setMsgObject({
      ...msgObject,
      ["assign_id"]: item._id,
      ["flow_id"]: item.task_flow_id,
      ["task_id"]: item.task_id,
      ["project_id"]: item.project_id,
      ["subcategory_id"]: item.task_subcategory_id,
    });
    axios
      .get(
        `${API_URL}taskcomment/list?assign_id=` +
          item._id +
          "&task_id=" +
          item.task_id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((response) => {
        var data = response.data.data;
        setisProjectChat(response.data.non_project);
        setMessages(response.data.data);
        if (typeof response.data.project_info.assignees !== "undefined") {
          response.data.project_info.assignees.map((item, index) => {
            item.id = index + 1;
            if (typeof item.assign_time !== "undefined")
              item.assign_time1 = timeFromNumber(item.assign_time);
            else item.assign_time1 = "00:00";

            if (typeof item.assign_effort_hr !== "undefined")
              item.assign_effort_hr1 = item.assign_effort_hr.substring(
                0,
                item.assign_effort_hr.lastIndexOf(":")
              );
            else item.assign_effort_hr1 = "00:00";
            if (item.assign_effort_h1r == "") item.assign_effort_hr1 = "00:00";
          });
        }
        setChatboxData(response.data.project_info);
        setShow(true);
        scrollTop();
      });
  };
  const chat_flow_count_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 90,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 90,
      },
      {
        label: "Assignee",
        field: "name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Plan",
        field: "assign_time1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Effort",
        field: "assign_effort_hr1",
        sort: "asc",
        width: 90,
      },
    ],
    rows: chatboxData.assignees,
  };
  const showTask = (index) => {
    var list = [...tasks];
    list[index].expand = "show";
    setTasks(list);
  };
  const hideTask = (index) => {
    var list = [...tasks];
    list[index].expand = "hidden";
    setTasks(list);
  };
  const fetchMyTask = () => {
    axios
      .get(`${API_URL}project_dashboard/my_tasks`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.startedTask) {
          setRunningTask(res.data.startedTask);
        }
        setTasks(res.data.data);
        setTaskStage(res.data.stage);

        let result = res.data.staff_profile;
        var productivity_count = res.data.productivity_count;
        if (productivity_count > 0)
          result.productivity = (
            (productivity_count / result.tot_tasks) *
            100
          ).toFixed(2);
        else result.productivity = 0;
        if (
          typeof result.user_image !== "undefined" &&
          result.user_image != ""
        ) {
          var image = (
            <img
              src={`${API_URL}uploads/user_images/${result.user_image}`}
              style={{ height: "100px" }}
            />
          );
        } else {
          var image = <img src={personIcon} style={{ height: "100px" }} />;
        }
        SetUserImage(image);
        setDetails(result);
      });
  };
  const handleStartTask = (item, index) => {
    if (runningTask) {
      if (item._id.toString() !== runningTask._id.toString()) {
        toastr.error("Please stop running task before start new one");
        return;
      }
    }
    var list = [...tasks];
    localStorage.setItem("running_task", item._id);
    localStorage.setItem("running_time", new Date());
    if (typeof startedTasks.index !== "undefined") {
      list[startedTasks.index].action = "Play";
      axios
        .post(
          `${API_URL}taskassign/stop_task`,
          { assign_id: startedTasks.item._id },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          var effort = res.data.data;
          list[startedTasks.index].assign_effort_hr = effort;
          list[startedTasks.index].isClickable = true;
        });
    }
    list[index].action = "Pause";
    list[index].isClickable = false;
    setTasks(list);
    setStartedTasks({ item: item, index: index });

    axios
      .post(
        `${API_URL}taskassign/start_task`,
        { assign_id: item._id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setRunningTask(item);
      });
  };
  const handleStopTask = (item, index) => {
    var list = [...tasks];
    list[index].action = "Play";
    axios
      .post(
        `${API_URL}taskassign/stop_task`,
        { assign_id: item._id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var effort = res.data.data;
        list[index].assign_effort_hr = effort;
        list[index].isClickable = true;
        setTasks(list);
      });

    setStartedTasks({});
    setRunningTask(null);
  };
  const handleRuleClick = (e, item, index, rules, subindex) => {
    var list = [...tasks];
    if (e.target.checked === true) {
      list[index].assign_complete_percentage =
        list[index].assign_complete_percentage + rules.weightage;
      list[index].checklist[subindex].selectedWeightage = 100;
      list[index].checklist[subindex].isChecked = true;
    } else {
      list[index].assign_complete_percentage =
        list[index].assign_complete_percentage - rules.weightage;
      list[index].checklist[subindex].selectedWeightage = 0;
      list[index].checklist[subindex].isChecked = false;
    }
    setTasks(list);
    axios
      .post(
        `${API_URL}taskassign/update_checklist`,
        {
          assign_id: item._id,
          checklist_id: rules._id,
          check_status: e.target.checked,
          percentage: list[index].assign_complete_percentage,
          weightage: rules.weightage,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  const uploadAttachment = (e) => {
    const fd = new FormData();
    for (var i = 0; i < e.target.files.length; i++) {
      fd.append("images", e.target.files[i]);
    }
    axios
      .post(`${API_URL}taskcomment/images/`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAttachCount(response.data.file.length);
          setMsgObject({
            ...msgObject,
            ["images"]: response.data.file,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleMessage = (e) => {
    setMsgObject({
      ...msgObject,
      ["comment"]: e.target.value,
    });
  };
  const handleSendMessage = () => {
    axios
      .post(`${API_URL}taskcomment/`, msgObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var new_message = response.data.data;
        var messages_list = [...messages];
        messages_list.push(new_message);
        setMessages(messages_list);
        setMsgObject({
          ...msgObject,
          ["comment"]: "",
          ["images"]: [],
        });
        setAttachCount("");
        scrollTop();
      });
  };
  function scrollTop() {
    const curr = bottomRef.current;
    if (curr) {
      curr.scrollTop = 560 * 1000;
    }
  }
  /*##################### Completed Task List ######################*/
  const [partialTasksTable, setPartialTasksTable] = useState([]);
  const [partialTasksIds, setPartialTasksIds] = useState([]);
  const [assignTobeCompleted, setassignTobeCompleted] = useState({});
  const [confirmCompleteAlert, setconfirmCompleteAlert] = useState(null);
  const [confirmAllCompleteAlert, setconfirmAllCompleteAlert] = useState(null);
  const [allCheckbox, setAllCheckbox] = useState(null);
  const partial_tasks_data = {
    columns: [
      {
        label: (
          <>
            <Row>
              <Col md="1">
                <input
                  type="checkbox"
                  onClick={(e) => {
                    if (e.target.checked) {
                      setAllCheckbox(e);
                      setconfirmAllCompleteAlert(true);
                    }
                  }}
                />
              </Col>
              <Col>Task</Col>
            </Row>
          </>
        ),
        field: "task_name",
        sort: "asc",
        width: 90,
      },
    ],
    rows: partialTasksTable,
  };
  const fetchReportingTasks = () => {
    axios
      .get(`${API_URL}project_dashboard/partialy_complete_tasks`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data.data &&
          res.data.data.map((item, index) => {
            list.push(item._id);
            item.task_name = (
              <>
                <Row class="reported_tasks_list">
                  <Col md="1">
                    <input
                      type="checkbox"
                      checked={item?.checked}
                      onClick={(e) => {
                        if (e.target.checked == true) {
                          setassignTobeCompleted({ id: item._id, elemet: e });
                          setconfirmCompleteAlert(true);
                        }
                      }}
                    ></input>
                  </Col>
                  <Col>
                    <button
                      class="homeTaskLink"
                      id={"tasks-" + index + item._id}
                    >
                      {item.task_name}
                    </button>
                  </Col>
                  <UncontrolledPopover
                    trigger="focus"
                    target={"tasks-" + index + item._id}
                    placement="left"
                  >
                    <PopoverBody>
                      <tabel class="table table-md" id="myTaskHomePopTable">
                        <tbody>
                          <tr>
                            <th style={{ textAlign: "left" }}>Project</th>
                            <td>{item.project_name}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Staff</th>
                            <td>{item.name}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Start</th>
                            <td>{item.assign_startdate}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Deadline</th>
                            <td>{item.assign_enddate}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Planned</th>
                            <td>{timeFromNumber(item.assign_time)}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Effort</th>
                            <td>
                              {item.assign_effort_hr.substring(
                                0,
                                item.assign_effort_hr.lastIndexOf(":")
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Percentage</th>
                            <td>{item.assign_complete_percentage}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "left" }}>Efficiency</th>
                            <td>0</td>
                          </tr>
                        </tbody>
                      </tabel>
                    </PopoverBody>
                  </UncontrolledPopover>
                </Row>
              </>
            );
          });
        setPartialTasksIds(list);
        setPartialTasksTable(res.data.data);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserId(obj._id);
    }
    fetchMyTask();
    fetchReportingTasks();
  }, []);
  useLayoutEffect(() => {
    scrollTop();
  });
  // to do functions

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(-1);
  const [toDO, settoDO] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [masterobje, setmasterobje] = useState({});
  const [confirmDeleteAlertTodo, setconfirmDeleteAlertTodo] = useState(false);
  const [todoIdToBeDeleted, settodoIdToBeDeleted] = useState(null);
  const [completedTodo, setcompletedTodo] = useState([]);
  useEffect(() => {
    fetch_all_to_do();
  }, []);

  function fetch_all_to_do() {
    axios
      .get(`${API_URL}todo/incomplete`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        console.log("result", result);
        settoDO(res.data);
      });
  }

  const handleInputChange = (event) => {
    const text = event.target.value;
    setInputValue(text);
    setmasterobje({ ...masterobje, description: text });

    if (selectedEmail) {
      // An email is already selected, don't make an API call.
      setOptions([]);
      return;
    }

    const hashtags = text.match(/#(\w+)/g); // Extract hashtags from the text.
    if (hashtags && hashtags.length > 0) {
      // Extract the last hashtag in the text.
      const lastHashtag = hashtags[hashtags.length - 1];
      const searchTerm = lastHashtag.substring(1);
      // console.log("search term", searchTerm);

      // Make an API call using Axios to get options based on the searchTerm.
      axios
        .get(`${API_URL}todo/userSearch?query=${searchTerm}`, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          setOptions(response.data);
          setSelectedEmailIndex(-1);
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      setOptions([]);
      setSelectedEmailIndex(-1);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    const emailToAdd = selectedOption.firstName + selectedOption.lastName;
    const objectIdToAdd = selectedOption._id;
    const updatedValue = inputValue.replace(/#(\w+)$/, `#${emailToAdd} `);
    // const updatedValue = inputValue.replace(/#(\w+)$/, '') + emailToAdd;
    // const updatedValue = inputValue.trim() === '' ? emailToAdd : `${inputValue} ${emailToAdd}`;
    setInputValue(updatedValue);
    setSelectedEmail(emailToAdd);
    setOptions([]);
    setSelectedEmailIndex(-1);
    setmasterobje({
      ...masterobje,
      description: updatedValue,
      addedFor: [objectIdToAdd],
    });
  };

  const handleKeyDown = (event) => {
    if (options.length === 0 && event.key === "Enter") {
      axios
        .post(`${API_URL}todo`, masterobje, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          toastr.success("Task added successfully");
          fetch_all_to_do();
          setmasterobje({});
          setSelectedEmail("");
          setSelectedEmailIndex(-1);
          setInputValue("");
        })
        .catch((err) => {
          toastr.error("Faild to add task");
        });
    }

    if (event.key === "ArrowDown") {
      // Move selection down.
      setSelectedEmailIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
      event.preventDefault(); // Prevent page scrolling with down arrow.
    } else if (event.key === "ArrowUp") {
      // Move selection up.
      setSelectedEmailIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      event.preventDefault(); // Prevent page scrolling with up arrow.
    } else if (event.key === "Enter" && selectedEmailIndex >= 0) {
      // If an email is selected using arrow keys, choose it on Enter.
      handleOptionSelect(options[selectedEmailIndex]);
    }
  };

  const clearInput = () => {
    setInputValue("");
    setOptions([]);
    setSelectedEmail("");
    setSelectedEmailIndex(-1);
  };

  function deletetodoList(item) {
    setconfirmDeleteAlertTodo(true);
    settodoIdToBeDeleted(item._id);
  }

  // function handleStatusChange(id){
  //   axios.put(`${API_URL}todo/${id}`,{
  //     headers:{
  //       "x-access-token":accessToken
  //     }
  //   }).then((res)=>{
  //     console.log("resupdate",res);
  //     fetch_all_to_do()
  //   })
  // }

  function handleStatusChange(id) {
    axios
      .put(
        `${API_URL}todo/${id}`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        fetch_all_to_do();
        fetch_all_complete();
      });
  }

  function fetch_all_complete() {
    axios
      .get(`${API_URL}todo/completed`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res.data);
        setcompletedTodo(res.data);
      });
  }

  useEffect(() => {
    fetch_all_complete();
  }, []);


  




  console.log("completed", completedTodo);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Project"
            path="/project-dashboard"
            breadcrumbItem="Dashboard"
          />
          <Row>
            <Col md="5">
              <Row>
                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.tot_modules}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">Total</p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span className={"text-primary me-1"}>
                          <i className={"fas fa-laptop-code me-1"}></i>
                          {details.tot_tasks}
                        </span>{" "}
                        Tasks
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.completed_modules}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">Completed</p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span className={"text-success me-1"}>
                          <i className={"fas fa-tasks me-1"}></i>
                          {details.completed_tasks}
                        </span>{" "}
                        Tasks
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.incompleted_modules}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">
                          InCompleted
                        </p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span style={{ color: "#f1b44c" }} className={"me-1"}>
                          <i className={"fas fa-times me-1"}></i>
                          {details.incompleted_tasks}
                        </span>{" "}
                        Tasks
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.overdue_modules}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">Over Due</p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span style={{ color: "#2f88d3" }} className={"me-1"}>
                          <i className={"fas fa-calendar-alt me-1"}></i>
                          {details.overdue_tasks}
                        </span>{" "}
                        Tasks
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.overeffort_modules}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">
                          Over Effort
                        </p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span className={"text-danger me-1"}>
                          <i className={"fas fa-fire me-1"}></i>
                          {details.overeffort_tasks}
                        </span>{" "}
                        Tasks
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4} xl={4} style={{ cursor: "pointer" }}>
                  <Card>
                    <CardBody style={{ padding: "10px 10px" }}>
                      <div>
                        <h4 className="mb-1 mt-1 h4-title">
                          <span>{details.today_tasks}</span>
                        </h4>
                        <p className="text-dark mb-0 h4-subtitle">Today</p>
                      </div>
                      <p className="text-dark mt-3 mb-0 h4-para">
                        <span className={"text-info me-1"}>
                          <i className={"mdi mdi-arrow-down-bold me-1"}></i>
                          {details.today_completed}
                        </span>{" "}
                        Completed
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="7">
              <Card>
                <CardBody style={{ padding: "5px" }}>
                  <Row>
                    <Col md="3" style={{ textAlign: "center" }}>
                      {userImage}
                    </Col>
                    <Col md="9">
                      <table
                        id="TVstaffDashboardProfile"
                        style={{ width: "100%" }}
                      >
                        <tr>
                          <th colspan="4" style={{ textAlign: "center" }}>
                            {details.firstName} {details.lastName} -{" "}
                            {details.userId}
                          </th>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              <i className="uil-envelope"></i>
                            </b>
                          </td>
                          <td> {details.email_2} </td>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              <i className="dripicons-user-group"></i>
                            </b>
                          </td>
                          <td> {details.team_name} </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              <i className="dripicons-user-id"></i>
                            </b>
                          </td>
                          <td> {details.designation_name} </td>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              <i className="mdi mdi-av-timer"></i>
                            </b>
                          </td>
                          <td>
                            {" "}
                            {details.working_frm} to {details.working_to}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              P <i className="mdi mdi-alarm-check"></i>
                            </b>
                          </td>
                          <td>
                            {" "}
                            <span
                              className="badge bg-primary"
                              style={{ fontSize: "12px" }}
                            >
                              {details.tot_plan}/200 hrs
                            </span>{" "}
                          </td>
                          <td style={{ fontSize: "16px" }}>
                            <b>
                              E <i className="mdi mdi-fire"></i>
                            </b>
                          </td>
                          <td>
                            {" "}
                            <span
                              className="badge bg-primary"
                              style={{ fontSize: "12px" }}
                            >
                              {Math.round(details.tot_effort)} hrs
                            </span>{" "}
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <center>
                    <table width="100%" id="tableBadges">
                      <tr>
                        {details.tot_plan > 0 ? (
                          <td>
                            <Card className="badge-col">
                              <CardBody
                                style={{ padding: "2px", textAlign: "center" }}
                              >
                                <img src={badge1} className="badge-image" />
                                <br></br>
                                <b>Efficiency</b>
                                <br></br>
                                <span className="badge-percenatge">
                                  {100 +
                                    (1 -
                                      Math.round(
                                        details.tot_effort / details.tot_plan
                                      )) *
                                      100}
                                  %
                                </span>
                              </CardBody>
                            </Card>
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          <Card className="badge-col">
                            <CardBody
                              style={{ padding: "2px", textAlign: "center" }}
                            >
                              <img src={badge2} className="badge-image" />
                              <br></br>
                              <b>Productivity</b>
                              <br></br>
                              <span className="badge-percenatge">
                                {Math.round(details.productivity)}%
                              </span>
                            </CardBody>
                          </Card>
                        </td>
                        {Math.round(details.tot_effort) >= 200 ? (
                          <td>
                            <Card className="badge-col">
                              <CardBody
                                style={{ padding: "2px", textAlign: "center" }}
                              >
                                <img src={badge3} className="badge-image" />
                                <br></br>
                                <b>Time Hero</b>
                                <br></br>
                                <span className="badge-percenatge">
                                  {Math.round((details.tot_effort / 200) * 100)}
                                  %
                                </span>
                              </CardBody>
                            </Card>
                          </td>
                        ) : (
                          ""
                        )}
                        {/* <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge4} className="badge-image"/><br></br><b>Quality of O/P</b><br></br><span className="badge-percenatge">YES</span></CardBody></Card></td>
                              <td><Card className="badge-col"><CardBody style={{ padding:"2px", textAlign:"center"}}><img src={badge5} className="badge-image"/><br></br><b>Adaptability</b><br></br><span className="badge-percenatge">NO</span></CardBody></Card></td> */}
                      </tr>
                    </table>
                  </center>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader style={{ padding: "4px", verticalAlign: "middle" }}>
                  <h6>
                    <i className="fa fa-history fa-w blue"></i> Tasks
                  </h6>
                </CardHeader>
                <CardBody>
                  <table class="table table-bordered" id="myTaskTable">
                    <tbody>
                      {tasks.map((item, index) => {
                        var effort = item.assign_effort_hr;
                        var effort_in_min = timeInMinute(effort);
                        var plan = timeFromNumber(item.assign_time);
                        var plan_in_min = timeInMinute(plan + ":00");
                        if (effort_in_min && plan_in_min)
                          var workeffort = (effort_in_min / plan_in_min) * 100;
                        else var workeffort = 0;
                        if (item.assign_complete_percentage && workeffort != 0)
                          var efficiency = (
                            (item.assign_complete_percentage / workeffort) *
                            100
                          ).toFixed(2);
                        else var efficiency = 0;
                        if (efficiency > 100) efficiency = 100;
                        return (
                          <>
                            <tr>
                              {/* <td >
                                                    {item.expand=="hidden"?(
                                                                <>
                                                                <svg onClick={()=>showTask(index)} aria-hidden="true" style={{ height:"15px", width:"15px",}} focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" id="expandPoint">
                                                                    <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                </svg>
                                                                </>
                                                            ):(
                                                                <>
                                                                <svg onClick={()=>hideTask(index)} aria-hidden="true" style={{ height:"15px", width:"15px",}} focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="collapsePoint">
                                                                    <path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                                                </svg>
                                                                </>
                                                            )}
                                                            {item.project_name}
                                                    </td> */}
                              <td style={{ textAlign: "left" }}>
                                <Row>
                                  <Col md="1">
                                    {item.expand == "hidden" ? (
                                      <>
                                        <svg
                                          onClick={() => showTask(index)}
                                          aria-hidden="true"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="angle-right"
                                          class="svg-inline--fa fa-angle-right fa-w-8 fa-fw "
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 256 512"
                                          id="expandPoint"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          onClick={() => hideTask(index)}
                                          aria-hidden="true"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="sort-down"
                                          class="svg-inline--fa fa-sort-down fa-w-10 fa-fw "
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 320 512"
                                          id="collapsePoint"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
                                          ></path>
                                        </svg>
                                      </>
                                    )}
                                  </Col>
                                  <Col>
                                    <button
                                      class="homeTaskLink"
                                      id={"task" + index + item._id}
                                    >
                                      {item.task_name}
                                    </button>
                                  </Col>
                                </Row>

                                <UncontrolledPopover
                                  trigger="focus"
                                  target={"task" + index + item._id}
                                  placement="right"
                                >
                                  <PopoverBody>
                                    <tabel
                                      class="table table-md"
                                      id="myTaskHomePopTable"
                                    >
                                      <tbody>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Project
                                          </th>
                                          <td>{item.project_name}</td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Start
                                          </th>
                                          <td>{item.assign_startdate}</td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Deadline
                                          </th>
                                          <td>{item.assign_enddate}</td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Planned
                                          </th>
                                          <td>
                                            {timeFromNumber(item.assign_time)}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Effort
                                          </th>
                                          <td>
                                            {item.assign_effort_hr.substring(
                                              0,
                                              item.assign_effort_hr.lastIndexOf(
                                                ":"
                                              )
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Percentage
                                          </th>
                                          <td>
                                            {item.assign_complete_percentage}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th style={{ textAlign: "left" }}>
                                            Efficiency
                                          </th>
                                          <td>{efficiency}</td>
                                        </tr>
                                      </tbody>
                                    </tabel>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </td>
                              <td width={"100px"}>{item.assign_startdate}</td>
                              <td width={"100px"}>{item.assign_enddate}</td>
                              {taskStage == 2 || taskStage == 1 ? (
                                <>
                                  <td>{item.staff_name}</td>
                                </>
                              ) : (
                                <></>
                              )}

                              <td width={"100px"}>
                                <div>
                                  <Progress
                                    color="primary"
                                    style={{
                                      height: "14px",
                                      border: item.due_status,
                                    }}
                                    value={item.assign_complete_percentage}
                                  >
                                    {item.assign_complete_percentage}%
                                  </Progress>
                                </div>
                              </td>
                              <td width={"30px"}>
                                {item.action == "Play" ? (
                                  <>
                                    <i
                                      class="fas fa-play playButton"
                                      onClick={() =>
                                        handleStartTask(item, index)
                                      }
                                    ></i>
                                  </>
                                ) : item.action != "No" ? (
                                  <>
                                    <i
                                      class="fas fa-pause pauseButton"
                                      onClick={() =>
                                        handleStopTask(item, index)
                                      }
                                    ></i>
                                  </>
                                ) : (
                                  <>
                                    {/* <i class="fas fa-calendar-check"></i> */}
                                  </>
                                )}
                              </td>
                              <td width={"30px"}>
                                {item?.unread_comments > 0 ? (
                                  <div class="chat-icon">
                                    <i
                                      className="uil-comment-alt-lines"
                                      onClick={() => handleShow(item, index)}
                                    ></i>
                                    <span class="badge bg-danger rounded-pill">
                                      {item.unread_comments}
                                    </span>
                                  </div>
                                ) : item?.total_comments > 0 ? (
                                  <i
                                    className="uil-comment-alt-lines"
                                    onClick={() => handleShow(item, index)}
                                  ></i>
                                ) : (
                                  <i
                                    className="uil-comment-alt"
                                    onClick={() => handleShow(item, index)}
                                  ></i>
                                )}
                              </td>
                            </tr>
                            {item.checklist.map((rules, subIndex) => {
                              if (runningTask) {
                                if (
                                  item._id.toString() ==
                                  runningTask._id.toString()
                                ) {
                                  item.isClickable = false;
                                }
                              }
                              return (
                                <>
                                  <tr class={item.expand}>
                                    {taskStage == 2 || taskStage == 1 ? (
                                      <>
                                        <td></td>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <td>
                                      <Row>
                                        <Col md="1"></Col>
                                        <Col style={{ paddingLeft: "12px" }}>
                                          {rules.checklist_name}
                                        </Col>
                                      </Row>

                                      {/* <div><Progress color="primary" style={{ height:"14px"}} value={rules?.selectedWeightage}>{rules.weightage}%</Progress></div> */}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        onClick={(e) =>
                                          handleRuleClick(
                                            e,
                                            item,
                                            index,
                                            rules,
                                            subIndex
                                          )
                                        }
                                        checked={rules?.isChecked}
                                        disabled={item.isClickable}
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                      <tr>
                        <td colSpan={5} align="center">
                          <a
                            href="#"
                            onClick={() => {
                              history.push("/all-tasks");
                            }}
                            className="btn btn-sm btn-primary"
                          >
                            View All Tasks
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>

            <Col md="4">
             <Todolist/>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader
                      style={{ padding: "4px", verticalAlign: "middle" }}
                    >
                      <h6>
                        <i className="fa fa-history fa-w blue"></i> Reported
                        Tasks
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <MDBDataTable
                        id="partialTasksTableID"
                        responsive
                        bordered
                        data={partial_tasks_data}
                        searching={false}
                        disableRetreatAfterSorting={true}
                        paging={false}
                        info={false}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={"end"}
        style={{ width: "1200px" }}
      >
        <Row>
          <Col md="7">
            {/* <Offcanvas.Header closeButton style={{ padding:"5px"}}>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header> */}
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  {isProjectChat ? (
                    <>
                      <table id="projectInfoTable" width={"100%"}>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Project Name
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.project_name}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Shortcode
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.shortcode}
                          </td>
                        </tr>
                        {/* <tr>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                  Client
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  : {chatboxData?.clients}
                                </td>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                Type
                                </td>
                                <td style={{ textAlign: "left" }}>
                                : {chatboxData?.types}
                                </td>
                              </tr> */}

                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Technologies
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.TechForTbl}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Domain
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <a
                              target="_blank"
                              href={chatboxData?.domain}
                              style={{ paddingLeft: "0%" }}
                            >
                              : {chatboxData?.domain}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Quotation No
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.quotation_no}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Invoice No
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.invoice_no}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Kick Off
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.kickoff}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            End
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.End}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Project Status
                          </td>
                          <td style={{ textAlign: "left" }}>
                            :
                            <p
                              className="badge rounded-pill "
                              style={{
                                fontSize: "initial",
                                marginBottom: "auto",
                              }}
                            >
                              {chatboxData?.ProjectStatus}
                            </p>
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Billing Status
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.bstatus}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Manager
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_manager?.firstName}{" "}
                            {chatboxData.project_manager?.lastName}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Team Leader
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_leader?.firstName}{" "}
                            {chatboxData.project_leader?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Hours
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.hours}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500" }}>
                            Remark
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.remarks}
                          </td>
                        </tr>
                      </table>
                    </>
                  ) : (
                    ""
                  )}

                  <MDBDataTable
                    id="flowCountTableIds"
                    responsive
                    bordered
                    data={chat_flow_count_data}
                    searching={false}
                    disableRetreatAfterSorting={true}
                    paging={true}
                    info={false}
                    entriesOptions={[5, 10]}
                  />
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  <div className="chat-input-section">
                    <Row>
                      <div className="px-lg-2">
                        <div className="chat-conversation pt-2">
                          <ScrollBar
                            style={{ height: "455px" }}
                            className="list-unstyled mb-0"
                            containerRef={(el) => (bottomRef.current = el)}
                          >
                            {messages.map((message, index) => {
                              if (message.comment) {
                                return (
                                  <>
                                    <li
                                      key={"test_k" + index}
                                      className={
                                        message.createdBy._id.toString() ==
                                        userId.toString()
                                          ? "right"
                                          : ""
                                      }
                                    >
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content">
                                            <h5 className="font-size-14 conversation-name">
                                              <Link
                                                to="#"
                                                className="text-dark"
                                              >
                                                {message.createdBy.firstName}
                                              </Link>
                                              <span className="d-inline-block font-size-12 text-muted ms-2">
                                                {moment(
                                                  message.createdAt
                                                ).format(
                                                  "DD-MM-YYYY hh:mm a"
                                                )}{" "}
                                                -{" "}
                                                {timeSince(
                                                  new Date(
                                                    moment(
                                                      message.createdAt
                                                    ).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )
                                                  ),
                                                  new Date()
                                                )}
                                              </span>
                                            </h5>
                                            <p className="mb-0 remarks-comment">
                                              {message.comment}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-alt text-dark"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = (
                                          <img
                                            src={`${API_URL}uploads/taskcomment/${attach}`}
                                            height="100"
                                          />
                                        );
                                      } else if (
                                        extension.toLowerCase() == "pdf"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "docx" ||
                                        extension.toLowerCase() == "doc"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-word text-primary"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "xlsx" ||
                                        extension.toLowerCase() == "xls"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-excel text-success"></i>
                                        );
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={
                                              message.createdBy._id.toString() ==
                                              userId.toString()
                                                ? "right"
                                                : ""
                                            }
                                          >
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link
                                                      to="#"
                                                      className="text-dark"
                                                    >
                                                      {
                                                        message.createdBy
                                                          .firstName
                                                      }
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(
                                                        new Date(
                                                          moment(
                                                            message.createdAt
                                                          ).format(
                                                            "YYYY-MM-DD HH:mm:ss"
                                                          )
                                                        ),
                                                        new Date()
                                                      )}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a
                                                      href={`${API_URL}uploads/taskcomment/${attach}`}
                                                      target="_blank"
                                                      download
                                                    >
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split(".").pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-alt text-dark"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "png" ||
                                        extension.toLowerCase() == "jpg" ||
                                        extension.toLowerCase() == "jpeg" ||
                                        extension.toLowerCase() == "svg"
                                      ) {
                                        attchment = (
                                          <img
                                            src={`${API_URL}uploads/taskcomment/${attach}`}
                                            height="100"
                                          />
                                        );
                                      } else if (
                                        extension.toLowerCase() == "pdf"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "docx" ||
                                        extension.toLowerCase() == "doc"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-word text-primary"></i>
                                        );
                                      } else if (
                                        extension.toLowerCase() == "xlsx" ||
                                        extension.toLowerCase() == "xls"
                                      ) {
                                        attchment = (
                                          <i className="chat-fa-icon fas fa-file-excel text-success"></i>
                                        );
                                      }
                                      return (
                                        <>
                                          <li
                                            key={"test_k" + index + "" + index1}
                                            className={
                                              message.createdBy._id.toString() ==
                                              userId.toString()
                                                ? "right"
                                                : ""
                                            }
                                          >
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link
                                                      to="#"
                                                      className="text-dark"
                                                    >
                                                      {
                                                        message.createdBy
                                                          .firstName
                                                      }
                                                    </Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">
                                                      {timeSince(
                                                        new Date(
                                                          moment(
                                                            message.createdAt
                                                          ).format(
                                                            "YYYY-MM-DD HH:mm:ss"
                                                          )
                                                        ),
                                                        new Date()
                                                      )}
                                                    </span>
                                                  </h5>
                                                  <p className="mb-0">
                                                    <a
                                                      href={`${API_URL}uploads/taskcomment/${attach}`}
                                                      target="_blank"
                                                      download
                                                    >
                                                      {attchment}
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })}
                          </ScrollBar>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <Input
                            value={msgObject?.comment}
                            type="textarea"
                            id="comment"
                            name="comment"
                            className="form-control chat-input rounded"
                            onChange={(e) => handleMessage(e)}
                            rows="1"
                            placeholder="Enter Message..."
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div class="image-upload">
                          <label for="file-input">
                            {attachCount}{" "}
                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                          <input
                            id="file-input"
                            type="file"
                            multiple
                            onChange={uploadAttachment}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={handleSendMessage}
                          className="chat-send waves-effect waves-light"
                        >
                          <i className="mdi mdi-send float-end"></i>
                        </Button>
                      </div>
                    </Row>
                  </div>
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Offcanvas>
      {confirmCompleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Sumbit"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            var id = assignTobeCompleted.id;
            axios
              .post(
                `${API_URL}taskassign/update_assign_completion`,
                { assign_id: id, status: 1, workeffort: 0, tot_workeffor: 0 },
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((response) => {
                setassignTobeCompleted({});
                toastr.success("Updated Successfully");
                assignTobeCompleted.elemet.target.checked = false;
                fetchReportingTasks();
              });
            setconfirmCompleteAlert(false);
          }}
          onCancel={() => {
            setconfirmCompleteAlert(false);
            assignTobeCompleted.elemet.target.checked = false;
          }}
        >
          Are you sure?
        </SweetAlert>
      ) : null}

      {confirmAllCompleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Sumbit"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            var id = [...partialTasksIds];
            axios
              .post(
                `${API_URL}taskassign/update_all_assign_completion`,
                { assign_id: id },
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((response) => {
                allCheckbox.target.checked = false;
                toastr.success("Updated Successfully");
                fetchReportingTasks();
              });
            setconfirmAllCompleteAlert(false);
          }}
          onCancel={() => {
            setconfirmAllCompleteAlert(false);
            allCheckbox.target.checked = false;
          }}
        >
          Are you sure?
        </SweetAlert>
      ) : null}

      {confirmDeleteAlertTodo ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}todo/${todoIdToBeDeleted}`, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                console.log("delete", res);
              });
          }}
        >
          Are you sure?
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};
export default ProjectDashboard;
