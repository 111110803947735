import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Progress,
  Input,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import "./kanban.scss";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";

import { Link } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getRouteName, timeFromNumber, addTimes, timeInMinute, timeSince } from "../../../../helpers/globalFunctions";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import $ from "jquery";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";

import SweetAlert from "react-bootstrap-sweetalert";
import { unset } from "lodash";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { setValue } from "@syncfusion/ej2-base";
// Tab UI
// import List from "././component/List/List";
// import Dashboard from "././Dashboard1/index";
import KanbanTask from "./KanbanTask/KanbanTask";
import GanttChart from "./Time/TimeLine";

import Offcanvas from "react-bootstrap/Offcanvas";
import ScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { stubTrue } from "lodash";
const ProjectDetails = (props) => {
  const { addProject } = props.match.params;
  const [totalTask, setTotalTask] = useState({
    tot: 0,
    complete: 0,
    overeffort: 0,
    incomplete: 0,
    overdue: 0,
    totalsub: 0,
    completed_tasks: 0,
    incomplete_tasks: 0,
    overeffor_tasks: 0,
    due_tasks: 0,
    unassigned_tasks: 0,
    tot_unassigned: 0,
  });
  const [details, setDetails] = useState({});
  const [ClientDetails, setClientDetails] = useState({});
  const [tableData, setTableData] = useState([]);

  const [productForTable, setProductForTable] = useState([]);
  const [flowCountTable, setflowCountTable] = useState([]);

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setShow(false);

  const [staffDetaObject, setStaffDetaObject] = useState({
    user_id: addProject,
  });
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [staffDetaIdToBeDeleted, setStaffDetaIdToBeDeleted] = useState();

  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [staffDetaValue, setstaffDetaValue] = useState("");

  const [designation, setDesignation] = useState(null);
  const [selectedDesig, setSelectedDesig] = useState(null);

  const [selectedSocial, setSelectedSocial] = useState(null);

  const [leaveType, setLeaveType] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);

  const [selectedLeaveFrom, setSelectedLeaveFrom] = useState(null);
  const [selectedLeaveTo, setSelectedLeaveTo] = useState(null);

  const [selectedSalaryFrom, setselectedSalaryFrom] = useState(null);
  const [selectedSalaryTo, setselectedSalaryTo] = useState(null);

  //############################ QA ###########################################
  const [qaDataForTable, setQaDataForTable] = useState([]);
  const [qaTask, setQaTask] = useState([]);
  const [selectedQaTask, setSelectedQaTask] = useState(null);
  const [taskAssignPermission, settaskAssignPermission] = useState("none");
  const [qaStatus, setQaStatus] = useState([]);
  const [selectedQaStatus, setSelectedQaStatus] = useState(null);
  const [qaFlow, setQaFlow] = useState([]);
  const [selectedQaFlow, setSelectedQaFlow] = useState(null);
  const [masterQaObject, setMasterQaObject] = useState([]);
  const [errors, setErrors] = useState({ taskError: "", flowError: "", statusError: "", imageError: "" });
  const [uploadProgress, setUploadProgress] = useState();
  const [qaIdTobeUpdated, setQaIdTobeUpdated] = useState(null);
  const [qaIdTobeDeleted, setQaIdTobeDeleted] = useState({ task_id: "", task_qa: "" });
  const [confirmQaDeleteAlert, setConfirmQaDeleteAlert] = useState(null);
  const [showQaPopup, setShowQaPopup] = useState(false);
  const [viewQaDetails, setViewQaDetails] = useState({}); //modelview
  //############################ QA ###########################################

  //task assign
  const [taskCategoryOptions, setTaskCategoryOptions] = useState([]);
  const [taskAssigneeOptions, setTaskAssigneeOptions] = useState([]);
  const [taskRows, setTaskRows] = useState([]);
  const [taskPermission, SetTaskPermission] = useState(true);
  const [taskIdToBeDeleted, setTaskIdToBeDeleted] = useState({});
  const [confirmDeleteTaskAlert, setConfirmDeleteTaskAlert] = useState(null);
  const [assignIdToBeDeleted, setAssignIdToBeDeleted] = useState({});
  const [confirmDeleteAssignAlert, setConfirmDeleteAssignAlert] = useState(null);
  const [confirmDisableFlow, setConfirmDisableFlow] = useState(false);

  const [isExpand, setIsExpand] = useState(true);
  const [isExpandSub, setisExpandSub] = useState(false);

  //all task
  const [alltasktableData, setalltaskTableData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [optionProject, setOptionProject] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [optionStaff, setOptionStaff] = useState([]);
  const [userId, setUserId] = useState();
  const [taskName, setTaskName] = useState("");
  const [flowOptions, setFlowOptions] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState({ label: "All Task", value: 1 });
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [optionTeam, setOptionTeam] = useState([]);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);

  const [canvasshow, setCanvasShow] = useState(false);
  const handleClose = () => setCanvasShow(false);
  const [attachCount, setAttachCount] = useState("");
  const [msgObject, setMsgObject] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatboxData, setChatboxData] = useState({});
  const bottomRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const {
    districts,
    addingUser,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const [routeName, setRouteName] = useState(getRouteName(props));

  const formRef = useRef();
  let history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (obj.allow_assign) settaskAssignPermission("block");
      setUserId(obj._id);
    }
    if (addProject) {
      handleTableData(addProject);
      fetchTaskAssignee();
      fetchTaskCategory();
      handleTaskTable(addProject);
      OtherProjects();
      handleQaTableData(addProject);
      fetch_qaTask(addProject);
      fetch_qaStatus();
    }
  }, [addProject]);

  const handleTableData = (id) => {
    axios
      .get(`${API_URL}project/view?unique_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalTask({
          ...totalTask,
          tot: res.data.tot_task,
          complete: res.data.tot_complete,
          overeffort: res.data.tot_effort,
          incomplete: res.data.tot_task - res.data.tot_complete,
          overdue: res.data.tot_overdue,
          totalsub: res.data.grand_tot_flow,
          completed_tasks: res.data.completed_tasks,
          incomplete_tasks: res.data.grand_tot_flow - res.data.completed_tasks,
          overeffor_tasks: res.data.overeffor_tasks,
          due_tasks: res.data.due_tasks,
          tot_unassigned: res.data.tot_unassigned,
          unassigned_tasks: res.data.unassigned_tasks,
        });
        res.data.flow_data[res.data.flow_data.length] = {
          index: "",
          item_name: (
            <>
              <b>Total</b>
            </>
          ),
          total: (
            <>
              <b>{res.data.grand_tot_flow}</b>
            </>
          ),
          completed: (
            <>
              <b>{res.data.completed_tasks}</b>
            </>
          ),
          incomplete: (
            <>
              <b>{res.data.grand_tot_flow - res.data.completed_tasks}</b>
            </>
          ),
          unassigned: (
            <>
              <b>{res.data.unassigned_tasks}</b>
            </>
          ),
          overdue: (
            <>
              <b>{res.data.due_tasks}</b>
            </>
          ),
          overeffort: (
            <>
              <b>{res.data.overeffor_tasks}</b>
            </>
          ),
        };
        setflowCountTable(res.data.flow_data);
        result &&
          result.map((item, index) => {
            if (item.kick_off) item.kickoff = moment(item.kick_off).format("DD-MM-YYYY");
            else item.kickoff = "";

            if (item.end) item.End = moment(item.end).format("DD-MM-YYYY");
            else item.End = "";

            let Client = item?.client || {};
            item.clients = Client?.name;

            let Bill = item?.billing_status || {};
            item.bstatus = Bill?.name;

            let Type = item?.type || {};
            item.types = Type?.name;

            let Technology = item?.technologies[0] || {};
            item.techno = Technology?.name;

            let TechValue = item?.technologies || [];
            item.TechForTbl = "";
            TechValue &&
              TechValue.map((el) => {
                if (item.TechForTbl === "") item.TechForTbl = el?.name;
                else item.TechForTbl = item.TechForTbl + "," + el?.name;
              });

            if (item.prj_status === 1) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-primary" style={{ margin: "3px 0px 0px -10px" }}>
                    Hold
                  </span>
                </div>
              );
            } else if (item.prj_status === 2) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-success" style={{ margin: "3px 0px 0px -10px" }}>
                    Completed
                  </span>
                </div>
              );
            } else if (item.prj_status === 3) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-info" style={{ margin: "3px 0px 0px -10px" }}>
                    Pending
                  </span>
                </div>
              );
            } else if (item.prj_status === 4) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-warning" style={{ margin: "3px 0px 0px -10px" }}>
                    Suspended
                  </span>
                </div>
              );
            } else if (item.prj_status === 5) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-danger" style={{ margin: "3px 0px 0px -10px" }}>
                    Rejected
                  </span>
                </div>
              );
            }
            if (item.prj_status === 6) {
              item.ProjectStatus = (
                <div>
                  <span className="badge rounded-pill bg-primary" style={{ margin: "3px 0px 0px -10px" }}>
                    Live
                  </span>
                </div>
              );
            } else {
              item.ProjectStatus = "";
            }

            let ProductTbl = [];
            item.products &&
              item.products.map((product, index) => {
                let val = {};
                product.id = index + 1;
                let Product_ID = product?.product_id || {};
                product.ProductID = Product_ID?.name;
                product.product_validity = product.product_validity_no + " " + product.product_validity_unit;
                ProductTbl.push(product);
              });
            setProductForTable(ProductTbl);
            setDetails(item);
            //fetch_QA_Details(item?._id);
            fetch_Client_Details(item.client?._id);
          });
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Product",
        field: "ProductID",
        sort: "asc",
        width: 90,
      },
      {
        label: "Details",
        field: "product_details",
        sort: "asc",
        width: 90,
      },
      // {
      //   label: "Product Amount",
      //   field: "product_amount",
      //   sort: "asc",
      //   width: 90,
      // },
      {
        label: "Validity",
        field: "product_validity",
        sort: "asc",
        width: 90,
      },
      {
        label: "Renewable",
        field: "product_renewable",
        sort: "asc",
        width: 90,
      },
    ],
    rows: productForTable,
  };
  const flow_count_data = {
    columns: [
      {
        label: "#",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Flow",
        field: "item_name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 90,
      },
      {
        label: "Completed",
        field: "completed",
        sort: "asc",
        width: 90,
      },
      {
        label: "Incomplete",
        field: "incomplete",
        sort: "asc",
        width: 90,
      },
      {
        label: "Unassigned",
        field: "unassigned",
        sort: "asc",
        width: 90,
      },
      {
        label: "Over Due",
        field: "overdue",
        sort: "asc",
        width: 90,
      },
      {
        label: "Over Effort",
        field: "overeffort",
        sort: "asc",
        width: 90,
      },
    ],
    rows: flowCountTable,
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const fetch_Client_Details = (id) => {
    axios
      .get(`${API_URL}client/data?_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            if (item?.up_date) {
              item.date = moment(item.up_date).format("DD-MM-YYYY");
            } else {
              item.date = moment(item.date).format("DD-MM-YYYY");
            }

            if (item?.up_time) {
              item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
            } else {
              item.time = moment(item.time, "HHmmss").format("hh:mm a");
            }

            item.DataTime = item.date + "  " + item.time;

            let Category = item?.client_cat_id[0] || {};
            item.Categ = Category?.name;

            let Country = item?.state[0] || {};
            item.country = Country?.name;

            let State = item?.state[0] || {};
            item.state = State?.name;

            let addedBy = item?.addedby[0] || {};
            if (addedBy?.lastName) item.staff = addedBy.firstName + " " + addedBy.lastName;
            else item.staff = addedBy?.firstName;

            let updatedBy = item?.updatedby[0] || {};
            if (updatedBy) {
              if (updatedBy?.lastName) item.staff1 = updatedBy.firstName + " " + updatedBy.lastName;
              else item.staff1 = updatedBy?.firstName;
            } else {
              // check addedby
            }
          });
        setClientDetails(...result);
      });
  };
  function OtherProjects() {
    var url = `${API_URL}project/other_projects` + "/" + addProject;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;

          if (item.prj_status === 1) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-primary">Hold</span>
              </div>
            );
          } else if (item.prj_status === 2) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-success">Completed</span>
              </div>
            );
          } else if (item.prj_status === 3) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-info">Pending</span>
              </div>
            );
          } else if (item.prj_status === 4) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-warning">Suspended</span>
              </div>
            );
          } else if (item.prj_status === 5) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-danger">Rejected</span>
              </div>
            );
          } else if (item.prj_status === 6) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-primary">Live</span>
              </div>
            );
          } else {
            item.ProjectStatus = "";
          }
          if (typeof item.kick_off != "undefined" && item.kick_off != "" && item.kick_off != null) {
            item.kick_off = moment(item.kick_off).format("DD-MM-YYYY");
          } else {
            item.kick_off = "";
          }
          if (typeof item.end != "undefined" && item.end != "" && item.end != null) {
            item.end = moment(item.end).format("DD-MM-YYYY");
          } else {
            item.end = "";
          }
        });

        setTableData(result);
      });
  }

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    if (newValue == "10") {
      handlesupportTicketTableData();
    }
    setTab3(newValue);
  };
  const handleDeleteTask = (id, index) => {
    axios
      .post(
        `${API_URL}taskassign/delete_task`,
        { id: id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var list = [...taskRows];
        list.splice(index, 1);
        setTaskRows(list);
        setConfirmDeleteTaskAlert(false);
        handleStaffOptions();
        toastr.success("Task deleted successfully");
      });
  };
  const handleDeleteAssign = (id, index1, index) => {
    axios
      .post(
        `${API_URL}taskassign/delete_assign`,
        { id: id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var list = [...taskRows];
        list[index].flows[index1].assign_id = null;
        list[index].flows[index1].assignee = null;
        list[index].flows[index1].assign_time = 0;
        list[index].flows[index1].assign_startdate = "";
        list[index].flows[index1].assign_enddate = "";
        // var start_date = [];
        // var end_date = [];
        // list[index].flows && list[index].flows.map((item, indexs) => {
        //   if (item?.assign_startdate && indexs != index1)
        //     start_date.push(item.assign_startdate);
        //   if (item?.assign_enddate && indexs != index1)
        //     end_date.push(item.assign_enddate);
        // });
        // start_date.sort();
        // end_date.sort();
        // list[index].task_start_date = start_date[0];
        // list[index].task_end_date = end_date[end_date.length - 1];
        setTaskRows(list);
        setConfirmDeleteAssignAlert(false);
        handleStaffOptions();
        //handleTaskTable(addProject);
        setAssignIdToBeDeleted({});
        toastr.success("Task deleted successfully");
      });
  };

  //############################ QA ###########################################
  function fetch_qaTask(id) {
    axios
      .get(`${API_URL}taskqa/task/options?unique_id=` + id, {
        //check
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Task =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.task_name,
              value: el._id,
            };
          });
        setQaTask([{ options: Task }]);
      });
  }
  function fetch_qaStatus() {
    axios
      .get(`${API_URL}taskqa/status/options/`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Status =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setQaStatus([{ options: Status }]);
      });
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "task_id":
        setSelectedQaTask(selected);

        setSelectedQaFlow(null);
        axios
          .get(`${API_URL}taskqa/flow/options?task_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data && res.data.status === 200) {
              var Flow =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.flow_name,
                    value: el.flow_id,
                  };
                });
            } else {
              Flow = [];
            }
            setQaFlow([{ options: Flow }]);
          });

        setMasterQaObject({
          ...masterQaObject,
          task_id: selected.value,
        });
        setErrors({
          ...errors,
          taskError: "",
        });
        break;
      case "qa_flow_id":
        setSelectedQaFlow(selected);
        setMasterQaObject({
          ...masterQaObject,
          qa_flow_id: selected.value,
        });
        setErrors({
          ...errors,
          flowError: "",
        });
        break;
      case "qa_status":
        setSelectedQaStatus(selected);
        setMasterQaObject({
          ...masterQaObject,
          qa_status: selected.value,
        });
        setErrors({
          ...errors,
          statusError: "",
        });
        break;
      default:
        break;
    }
  };
  const uploadQaImages = (e) => {
    const fd = new FormData();
    fd.append("qa_attachments", e.target.files[0]);
    axios
      .post(`${API_URL}taskqa/image`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let arr = typeof masterQaObject.qa_attachments != "undefined" ? masterQaObject.qa_attachments : [];
          if (arr.length > 4) {
            toastr.error("Image upload limit exceeded");
            return;
          } else {
            arr.push(response.data.file[0]?.filename);
            setMasterQaObject({
              ...masterQaObject,
              qa_attachments: arr,
            });
            setErrors({
              ...errors,
              imageError: "",
            });
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const deleteQaImage = (index) => {
    const image = masterQaObject.qa_attachments;
    image.splice(index, 1);
    setMasterQaObject({
      ...masterQaObject,
      qa_attachments: image,
    });
  };
  const handleQaInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setMasterQaObject({ ...masterQaObject, [name]: value });
  };
  const qaReset = () => {
    formRef.current.reset();
    setSelectedQaFlow(null);
    setSelectedQaStatus(null);
    setSelectedQaTask(null);
    setErrors({
      ...errors,
      taskError: "",
      flowError: "",
      statusError: "",
      imageError: "",
    });
    setMasterQaObject({
      taskqa_id: "",
      task_id: "",
      qa_status: "",
      qa_message: "",
      qa_attachments: [],
      qa_flow_id: "",
      qa_priority: null,
    });
    handleQaTableData(addProject);
  };
  const handleQaValidSubmit = () => {
    if (selectedQaTask === null || selectedQaStatus === null) {
      // selectedQaFlow === null ||typeof masterQaObject.qa_attachments === "undefined"
      let errorVal = errors;
      if (selectedQaTask === null) {
        errorVal.taskError = "Please Select Task";
        setErrors({
          ...errorVal,
        });
      }
      if (selectedQaFlow === null) {
        errorVal.flowError = "Please Select Flow";
        setErrors({
          ...errorVal,
        });
      }
      if (selectedQaStatus === null) {
        errorVal.statusError = "Please Select Status";
        setErrors({
          ...errorVal,
        });
      }

      if (typeof masterQaObject.qa_attachments === "undefined") {
        errorVal.imageError = "Please Select Image";
        setErrors({
          ...errorVal,
        });
      }
    } else {
      if (qaIdTobeUpdated) {
        axios
          .put(`${API_URL}taskqa`, masterQaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task Qa updated successfully");
              formRef.current.reset();
              setQaIdTobeUpdated(null);
              setSelectedQaFlow(null);
              setSelectedQaStatus(null);
              setSelectedQaTask(null);
              setErrors({
                ...errors,
                taskError: "",
                flowError: "",
                statusError: "",
                imageError: "",
              });
              setMasterQaObject({
                taskqa_id: "",
                task_id: "",
                qa_status: "",
                qa_message: "",
                qa_attachments: [],
                qa_flow_id: "",
                qa_priority: null,
              });
              handleQaTableData(addProject);
            } else {
              toastr.error("Failed to update Task Qa");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}taskqa`, masterQaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task Qa added successfully");
              formRef.current.reset();
              setSelectedQaFlow(null);
              setSelectedQaStatus(null);
              setSelectedQaTask(null);
              setErrors({
                ...errors,
                taskError: "",
                flowError: "",
                statusError: "",
                imageError: "",
              });
              setMasterQaObject({
                taskqa_id: "",
                task_id: "",
                qa_status: "",
                qa_message: "",
                qa_attachments: [],
                qa_flow_id: "",
                qa_priority: null,
              });
              handleQaTableData(addProject);
            } else {
              toastr.error("Failed to add Task Qa");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  const handleQaClickOpen = (item) => {
    axios
      .get(`${API_URL}taskqa/data?task_id=${item?.task_id}&taskqa_id=${item?.task_qa?._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data.task_qa[0];
        let resul = res.data.data;

        result.update = moment(result?.up_date).format("DD-MM-YYYY") + " " + moment(result?.up_time, "HHmmss").format("hh:mm a");
        result.day = moment(result?.date).format("DD-MM-YYYY") + " " + moment(result?.time, "HHmmss").format("hh:mm a");
        let Update = result.updatedBy || {};
        result.edited = (Update.firstName || "") + (Update.lastName || "");
        let Add = result.createdBy || {};
        result.add = (Add.firstName || "") + (Add.lastName || "");
        result.task = resul?.task_name;
        let Flow = result.qa_flow_id || {};
        result.flow = Flow?.item_name;
        let Status = result.qa_status || {};
        result.status = Status?.name;
        let iType = result?.qa_attachments || [];
        result.images = iType.map((image) => {
          return (
            <img
              src={`${API_URL}uploads/qa/${image}`}
              style={{ width: "75px", height: "75px", cursor: "pointer", paddingRight: "10px" }}
              onClick={() => window.open(`${API_URL}uploads/qa/${image}`, "_blank")}
            />
          );
        });
        setViewQaDetails(result);
        setShowQaPopup(true);
      });
  };
  const handleQaTableData = (id) => {
    var url = `${API_URL}taskqa/list?unique_id=` + id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let qatData = [];
        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleQaClickOpen(item);
                }}></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTaskQa(item);
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setQaIdTobeDeleted({ task_id: item.task_id, taskqa_id: item.task_qa._id });
                  setConfirmQaDeleteAlert(true);
                }}></i>
            </div>
          );

          item.id = index + 1;

          if (item?.task_qa?.up_date) {
            item.day = moment(item.task_qa.up_date).format("DD-MM-YYYY") + " " + moment(item.task_qa.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.day = moment(item.task_qa.date).format("DD-MM-YYYY") + " " + moment(item.task_qa.time, "HHmmss").format("hh:mm a");
          }

          if (item?.task_name) {
            item.task = item.task_name;
          } else {
            item.task = "";
          }

          if (item?.task_qa?.qa_flow_id[0]) {
            let flowD = item?.task_qa?.qa_flow_id[0] || [];
            item.flow = flowD.item_name;
          } else {
            item.flow = [];
          }

          if (item?.task_qa?.qa_priority) {
            item.qa_priority = item.task_qa.qa_priority;
          } else {
            item.qa_priority = null;
          }

          if (item?.task_qa?.qa_status[0]) {
            let statusD = item?.task_qa?.qa_status[0] || [];
            item.status = statusD.name;
          } else {
            item.status = [];
          }

          if (item?.task_qa?.updatedBy[0]) {
            let values = item?.task_qa?.updatedBy[0] || [];
            if (values?.lastName) item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.task_qa?.createdBy[0] || [];
            if (values?.lastName) item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          qatData.push(item);
        });
        setQaDataForTable(qatData);
      });
  };
  let preUpdateTaskQa = (item) => {
    setQaIdTobeUpdated(item?.task_qa?._id);
    axios
      .get(`${API_URL}taskqa/data?task_id=${item?.task_id}&taskqa_id=${item?.task_qa?._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data.task_qa[0];
        let resul = res.data.data;
        let task = {
          label: resul?.task_name,
          value: resul?._id,
        };
        setSelectedQaTask(task);
        let flow = {
          label: result?.qa_flow_id?.item_name,
          value: result?.qa_flow_id?._id,
        };
        setSelectedQaFlow(flow);
        let status = {
          label: result?.qa_status?.name,
          value: result?.qa_status?._id,
        };
        setSelectedQaStatus(status);
        result.task_id = resul?._id;
        result.taskqa_id = result?._id;
        setMasterQaObject(result);
      });
  };
  const qaData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date", //date&timetogether
        field: "day",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 150,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 150,
      },
      {
        label: "Priority",
        field: "qa_priority",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: qaDataForTable,
  };
  //############################ QA ###########################################

  // Task List Code Start
  const [drp_secondary_sm, setDrp_secondary_sm] = useState(false);
  const [manageTaskType, setManageTaskType] = useState("All task");
  const handleexpandAll = (val) => {
    setIsExpand(val);
    setisExpandSub(false);
  };
  const fetchTaskCategory = () => {
    axios
      .get(`${API_URL}taskassign/category/options?project_id=` + addProject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
              };
            });
        } else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
                isDisabled: true,
              };
            });
        }

        setTaskCategoryOptions([{ options: SearchType_data }]);
      });
  };
  const fetchTaskAssignee = () => {
    axios
      .get(`${API_URL}taskassign/staff/options?project_id=` + addProject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
              };
            });
        } else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
                isDisabled: true,
              };
            });
        }
        setTaskAssigneeOptions([{ options: SearchType_data }]);
      });
  };
  const addNewTaskRow = () => {
    setTaskRows([
      ...taskRows,
      {
        subcategory: null,
        task_name: "",
        task_tot_time: 0,
        task_start_date: "",
        task_end_date: "",
        expand: "hidden",
        flows: [],
      },
    ]);
  };
  const showTask = (index) => {
    setisExpandSub(true);
    var tasks = [...taskRows];
    tasks[index].expand = "show";
    setTaskRows(tasks);
  };
  const hideTask = (index) => {
    setisExpandSub(true);
    var tasks = [...taskRows];
    tasks[index].expand = "hidden";
    setTaskRows(tasks);
  };
  const handleTaskTable = (id, type = "") => {
    axios
      .get(`${API_URL}taskassign/` + id + "?type=" + type, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        SetTaskPermission(res.data.permission);
        if (data.length == 0) {
          setTaskRows([
            {
              subcategory: null,
              task_name: "",
              expand: "hidden",
              flows: [],
            },
          ]);
        } else {
          var tasks = [];
          data.map((item, index) => {
            item.subcategory = { label: item.subcat_name, value: item.task_subcategory_id };
            item.expand = "show";
            if (typeof item.flows === "undefined") {
              item.flows = [];
            } else {
              item.task_tot_time = 0;
              item.task_start_date = "";
              item.task_end_date = "";
              var start_date = [];
              var end_date = [];

              var total_assign = 0;
              // var status_priority = [];
              // var status_name = [];
              // var status_count = [];
              if (typeof item.task_flows == "undefined" || item.task_flows == null) {
                var task_flows_avail = [];
              } else {
                var task_flows_avail = item.task_flows;
              }
              item.flows.map((subItem, index) => {
                if (typeof item.task_flows == "undefined" || item.task_flows == null) {
                  task_flows_avail.push(subItem.flow_id);
                }
                if (task_flows_avail.includes(subItem.flow_id)) subItem.flow_exists = true;
                else subItem.flow_exists = false;
                total_assign++;
                // if(subItem.assign_current_status_name)
                // {
                //   if(status_priority.indexOf(subItem.assign_current_status_priority)>=0)
                //   {
                //     status_count[subItem.assign_current_status_priority]++;
                //   }
                //   else
                //   {
                //     status_count[subItem.assign_current_status_priority]=1;
                //   }
                //   status_priority.push(subItem.assign_current_status_priority);
                //   status_name.push(subItem.assign_current_status_name);
                // }
                if (subItem.assign_startdate) start_date.push(subItem.assign_startdate);
                if (subItem.assign_enddate) end_date.push(subItem.assign_enddate);
                // subItem.assign_time = subItem.assign_time ? subItem.assign_time : subItem.duration;
                subItem.assign_time = subItem.assign_time ? subItem.assign_time : 0;
                subItem.assign_time = parseFloat(subItem.assign_time);
                var assign_time = subItem.assign_time ? subItem.assign_time : 0;
                item.task_tot_time = item.task_tot_time + assign_time;
                var status_options = [];
                subItem.assignee = { label: subItem.firstName, value: subItem.staff_id };
                subItem.flow_status.map((statusItem) => {
                  if (!res.data.permission) status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority });
                  else status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority, isDisabled: true });
                });
                subItem.statusOption = status_options;
                subItem.currentstatus = { label: subItem?.assign_current_status_name, value: subItem?.assign_current_status };
              });
              item.task_flows = task_flows_avail;
              start_date.sort();
              end_date.sort();
              //console.log(end_date);
              item.task_start_date = start_date[0] ? start_date[0] : "";
              item.task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";

              // if(status_priority.length>0)
              // {
              //   var max_status = Math.max(...status_priority);
              //   var max_status_index = status_priority.indexOf(max_status);
              //   var max_status_name = status_name[max_status_index];
              //   item.task_max_status_priority = max_status;
              //   item.task_max_status_name = max_status_name;
              //   item.task_max_status_count = status_count[max_status];
              //   item.task_tot_assign = total_assign;
              //   item.task_status_label = item.task_max_status_count+"/"+total_assign+" "+max_status_name;
              // }
              // else
              // {
              //   item.task_tot_assign = total_assign;
              //   item.task_status_label = total_assign+"/"+total_assign+" Pending";
              // }
            }
            tasks.push(item);
          });
          setTaskRows(tasks);
        }
      });
  };
  const handleFlowCheckbox = (e, index, index1) => {
    var list = [...taskRows];
    if (e.target.checked == false) {
      if (typeof list[index].flows[index1].assign_id !== "undefined" && list[index].flows[index1].assign_id != null) {
        setConfirmDisableFlow(true);
        setAssignIdToBeDeleted({ aid: list[index].flows[index1].assign_id, index1: index1, index: index, checked: e.target.checked, flow_id: e.target.value });
      } else handleFlowCheckboxClick(e.target.checked, e.target.value, index, index1);
    } else {
      handleFlowCheckboxClick(e.target.checked, e.target.value, index, index1);
    }
  };
  const handleFlowCheckboxClick = (checked, flow_id, index, index1) => {
    var list = [...taskRows];
    var task_flows_avail = list[index].task_flows;
    var flows = list[index].flows;
    var task_id = list[index]._id;
    if (checked) {
      if (!task_flows_avail.includes(flow_id)) {
        task_flows_avail.push(flow_id);
      }
      flows[index1].flow_exists = true;
    } else {
      console.log(flow_id, task_flows_avail, flows[index1]);
      task_flows_avail.splice(task_flows_avail.indexOf(flow_id), 1);
      console.log(flow_id, task_flows_avail, flows[index1]);
      flows[index1].flow_exists = false;
      if (typeof list[index].flows[index1].assign_id !== "undefined" && list[index].flows[index1].assign_id != null)
        handleDeleteAssign(list[index].flows[index1].assign_id, index1, index);
    }
    list[index].task_flows = task_flows_avail;
    list[index].flows = flows;

    setTaskRows(list);
    axios
      .post(
        API_URL + "taskassign/add_delete_flow",
        { task_id: task_id, flow: task_flows_avail },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        //toastr.success("Updated Successfully");
      })
      .catch((err) => {
        toastr.error("Failed to update");
      });
  };
  const fetchTaskFlows = (task_id, category_id, index, flows_avail) => {
    axios
      .get(`${API_URL}taskassign/getFlows/` + task_id + `/` + category_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tasks = [...taskRows];
        tasks[index].task_tot_time = 0;
        var start_date = [];
        var end_date = [];
        if (flows_avail == null) {
          tasks[index].task_flows = [];
        }
        res.data.data.map((item, index1) => {
          if (flows_avail == null) {
            item.flow_exists = true;
            tasks[index].task_flows.push(item?.flow_id);
          } else if (flows_avail.includes(item?.flow_id)) item.flow_exists = true;
          else item.flow_exists = false;
          start_date.push(item.assign_startdate);
          end_date.push(item.assign_enddate);
          // item.assign_time = item.assign_time ? item.assign_time : item.duration;
          item.assign_time = item.assign_time ? item.assign_time : 0;
          item.assign_time = parseFloat(item.assign_time);
          var assign_time = item.assign_time;
          tasks[index].task_tot_time = tasks[index].task_tot_time + assign_time;
          item.assignee = { label: item?.firstName, value: item?.staff_id };
          var status_options = [];
          item.flow_status.map((statusItem) => {
            status_options.push({ label: statusItem.type_name, value: statusItem._id });
          });
          item.statusOption = status_options;
          item.currentstatus = { label: item?.assign_current_status_name, value: item?.assign_current_status, priority: item.type_priority };
        });
        start_date.sort();
        end_date.sort();
        tasks[index].task_start_date = start_date[0] ? start_date[0] : "";
        tasks[index].task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";
        tasks[index].flows = res.data.data;
        setTaskRows(tasks);
      });
  };

  const handleAddTask = (selected, index, item, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    if (type == "task_name") {
      var task_name = selected.target.value;
      tasks[index].task_name = task_name;
    } else {
      var category_id = selected.value;
      tasks[index].task_subcategory_id = category_id;
      tasks[index].subcategory = selected;
      tasks[index].flows = [];
      tasks[index].expand = "show";
    }
    setTaskRows(tasks);

    if (typeof item._id !== "undefined") {
      if (type == "task_name") {
        var parameters = { task_id: item._id, user_id: user_id, task_name: task_name };
        axios
          .put(`${API_URL}taskassign/update_task`, parameters, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {});
      } else {
        var parameters = { task_id: item._id, user_id: user_id, task_subcategory_id: category_id, task_flows: item.task_flows };
        axios
          .put(`${API_URL}taskassign/update_task`, parameters, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var tasks = [...taskRows];
            tasks[index]._id = res.data.data._id;
            tasks[index].task_flows = res.data.data.task_flows;
            setTaskRows(tasks);
            fetchTaskFlows(tasks[index]._id, category_id, index, tasks[index].task_flows);
          });
      }
    } else {
      if (item.subcategory) {
        if (type == "task_name") {
          var parameters = { project_id: addProject, user_id: user_id, task_name: task_name, task_subcategory_id: item.subcategory?.value };
          axios
            .post(`${API_URL}taskassign/add_task`, parameters, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var tasks = [...taskRows];
              tasks[index]._id = res.data.data._id;
              setTaskRows(tasks);
              handleStaffOptions();
            });
        } else {
          var parameters = {
            project_id: addProject,
            user_id: user_id,
            task_subcategory_id: category_id,
            task_name: item.task_name,
            task_flows: item.task_flows,
          };
          axios
            .post(`${API_URL}taskassign/add_task`, parameters, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var tasks = [...taskRows];
              tasks[index]._id = res.data.data._id;
              //tasks[index].task_flows = null;
              setTaskRows(tasks);
              fetchTaskFlows(res.data.data._id, category_id, index, null);
              handleStaffOptions();
            });
        }
      }
    }
  };

  const handleChangeTask = (selected, index, item, type) => {
    var tasks = [...taskRows];

    var task_name = selected.target.value;
    tasks[index].task_name = task_name;
    setTaskRows(tasks);

    setTaskRows(tasks);
  };

  const handleAssignTask = (selected, index, index1, item, subItem, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    var parameters = {
      task_id: item._id,
      user_id: user_id,
      task_subcategory_id: item.task_subcategory_id,
      task_flow_id: subItem.flow_id,
      assign_id: subItem?.assign_id,
    };
    var logdate = {};
    var worklog = {};
    if (type == "staff") {
      var value = selected.value;
      tasks[index].flows[index1].staff_id = value;
      tasks[index].flows[index1].assignee = selected;
      parameters.staff_id = value;
      parameters.assign_time = tasks[index].flows[index1].assign_time;
    } else if (type == "time") {
      var value = selected.target.value;
      if (value != "") {
        var old_time = tasks[index].flows[index1].assign_time;
        if (typeof old_time === "undefined" || old_time == "") {
          old_time = 0;
        }
        var diff = parseFloat(value) - old_time;
        tasks[index].flows[index1].assign_time = value;
        tasks[index].task_tot_time = tasks[index].task_tot_time + parseFloat(diff);
      } else {
        var old_time = tasks[index].flows[index1].assign_time;
        tasks[index].task_tot_time = tasks[index].task_tot_time - parseFloat(old_time);
        tasks[index].flows[index1].assign_time = value;
      }

      parameters.assign_time = value;
    } else if (type == "start") {
      var value = selected.target.value;
      var end_date = tasks[index].flows[index1].assign_enddate;
      if (value > end_date) {
        tasks[index].flows[index1].assign_enddate = value;
        parameters.assign_enddate = value;
      }
      tasks[index].flows[index1].assign_startdate = value;
      if (tasks[index].task_start_date > value) {
        tasks[index].task_start_date = value;
      } else {
        var start = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_startdate) start.push(item.assign_startdate);
        });
        start.sort();
        tasks[index].task_start_date = start[0];
      }
      parameters.assign_startdate = value;
    } else if (type == "end") {
      var value = selected.target.value;
      tasks[index].flows[index1].assign_enddate = value;
      if (tasks[index].task_end_date < value) {
        tasks[index].task_end_date = value;
      } else {
        var end = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_enddate) end.push(item.assign_enddate);
        });
        end.sort();
        tasks[index].task_end_date = end[end.length - 1];
      }
      parameters.assign_enddate = value;
    } else if (type == "status") {
      var value = selected.value;
      tasks[index].flows[index1].assign_current_status = value;
      tasks[index].flows[index1].currentstatus = selected;
      parameters.assign_current_status = value;
    }
    parameters.flow_row = tasks[index].flows[index1];
    axios
      .post(`${API_URL}taskassign/`, parameters, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tasks = [...taskRows];
        tasks[index].flows[index1].assign_id = res.data.data._id;
        setTaskRows(tasks);
        handleStaffOptions();
      });

    // logdate = parameters.flow_row
    // axios
    // .post(`${API_URL}taskassign/work/logdate`,logdate, {

    //   headers: {
    //     "x-access-token": accessToken,
    //   },
    // })
    // .then((res) => {

    //     let datevalue=moment(res.data.data.date,"YYYY-MM-DD").format("YYYY-MM-DD");
    //      tasks[index].flows[index1].assign_startdate = datevalue;
    //      parameters.flow_row.assign_startdate = datevalue;
    // });

    // console.log("parameters",parameters)
    // let log = parameters.flow_row
    // console.log("logdate22",log)

    // worklog.staff_id=log.staff_id;
    // worklog.assign_time=log.assign_time;
    // worklog.assign_startdate=parameters.flow_row.assign_startdate;
    // worklog.assign_enddate=log.assign_enddate;
    // worklog.assign_id=log.assign_id;

    // console.log("worklog",worklog)
    // axios
    // .post(`${API_URL}taskassign/work/log`,worklog, {

    //   headers: {
    //     "x-access-token": accessToken,
    //   },
    // })
    // .then((res) => {
    //   if (res.data.status === 200) {

    //   }
    //   // console.log("res.data",res.data)
    // else {
    //               toastr.error("Free slot not available");
    //               return;
    //             }

    // })
    // .catch((err) => {
    //   toastr.error(err.response.data.message);
    //   return;
    // });

    setTaskRows(tasks);
  };
  //################## ALL TASK ########################
  const handleAllTaskTableData = (
    project_id = "",
    staff_id = "",
    from_date = "",
    to_date = "",
    task_name = "",
    flow = [],
    team = "",
    staff_arr = [],
    status = "",
    task_type = "",
    type = ""
  ) => {
    var flows = [];
    if (flow && flow.length > 0) {
      if (type != "reset") {
        flow.map((item) => {
          flows.push(item.value);
        });
      }
    }
    axios
      .post(
        `${API_URL}project_dashboard/all_tasks`,
        {
          project_id: project_id,
          staff_id: staff_id,
          from_date: from_date,
          to_date: to_date,
          project_no: addProject,
          task_name: task_name,
          flow: flows,
          staff_arr: staff_arr,
          team: team,
          status: status,
          task_type: task_type,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        var list = [];
        // data.map((item,index)=>{
        //     if(item.start_date)
        //     item.start_date = moment(item.start_date,"YYYY-MM-DD").format("DD-MM-YYYY")
        //     if(item.end_date)
        //     item.end_date = moment(item.end_date,"YYYY-MM-DD").format("DD-MM-YYYY")

        //     var efforts = [];
        //     item.flows_all.map((subItem,index1)=>{
        //       var effort = subItem.assign_effort_hr;
        //         var effort_in_min = timeInMinute(effort);
        //         var plan = timeFromNumber(subItem.assign_time);
        //         var plan_in_min = timeInMinute(plan+":00");
        //         if(effort_in_min && plan_in_min)
        //         var workeffort = (effort_in_min/plan_in_min)*100;
        //         else
        //         var workeffort = 0;
        //         subItem.workeffort=workeffort;
        //         if(subItem.assign_complete_percentage && workeffort!=0)
        //         var efficiency = ((subItem.assign_complete_percentage/workeffort)*100).toFixed(2);
        //         else
        //         var efficiency = 0;
        //         if(efficiency>100)
        //         {
        //           efficiency=100;
        //         }
        //         subItem.efficiency = efficiency;

        //         if(subItem.assign_startdate)
        //         subItem.assign_startdate = moment(subItem.assign_startdate,"YYYY-MM-DD").format("DD-MM-YYYY")
        //         if(subItem.assign_enddate)
        //         subItem.assign_enddate = moment(subItem.assign_enddate,"YYYY-MM-DD").format("DD-MM-YYYY")
        //         if(subItem.assign_time)
        //         subItem.assign_time = timeFromNumber(subItem.assign_time);
        //         else
        //         subItem.assign_time = "00:00";
        //         if(typeof subItem.assign_effort_hr!=="undefined")
        //         {
        //             if(subItem.assign_effort_hr!="0")
        //             {
        //                 efforts.push(subItem.assign_effort_hr);
        //             }
        //             subItem.assign_effort_hr = subItem.assign_effort_hr.substring(0,subItem.assign_effort_hr.lastIndexOf(":"));
        //         }
        //         else
        //         {
        //             subItem.assign_effort_hr="00:00";
        //         }
        //         if(subItem.assign_effort_hr=="")
        //         subItem.assign_effort_hr="00:00";

        //      })
        //      item.tot_percenatge = Math.round(item.tot_percenatge);
        //      item.tot_effort= addTimes(efforts);
        //       var effort = item.tot_effort;
        //         var effort_in_min = timeInMinute(effort);
        //         var plan = timeFromNumber(item.tot_plan);
        //         var plan_in_min = timeInMinute(plan+":00");
        //         if(effort_in_min && plan_in_min)
        //         var workeffort = (effort_in_min/plan_in_min)*100;
        //         else
        //         var workeffort = 0;
        //         item.workeffort=workeffort;
        //         if(item.tot_percenatge && workeffort!=0)
        //         var efficiency = ((item.tot_percenatge/workeffort)*100).toFixed(2);
        //         else
        //         var efficiency = 0;
        //         if(efficiency>100)
        //         {
        //           efficiency=100;
        //         }
        //         item.efficiency = efficiency;

        //      item.tot_effort= item.tot_effort.substring(0,item.tot_effort.lastIndexOf(":"));
        //      item.tot_plan = timeFromNumber(item.tot_plan);
        //      list.push(item);
        // });
        setalltaskTableData(data);
      });
  };
  const handleProjectOptions = () => {
    axios
      .get(`${API_URL}project_dashboard/project_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var list = [];
        data.map((item, index) => {
          list.push({ label: item.shortcode, value: item._id });
        });
        setOptionProject(list);
      });
  };
  function handleStaffOptions(team = "") {
    axios
      .get(`${API_URL}project_dashboard/staff_options?team=` + team, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var list = [];
        var data = response.data.data;
        data.map((item, index) => {
          if (data.length == 1) {
            setSelectedStaff({ label: item.firstName, value: item._id });
            setSelectedUser({ label: item.firstName, value: item._id });
          }
          list.push({ label: item.firstName, value: item._id });
        });
        setOptionStaff(list);
        if (data.length == 1) {
          handleAllTaskTableData("", data[0]._id);
        } else {
          handleAllTaskTableData(
            selectedProject?.value,
            selectedStaff?.value,
            from_date,
            to_date,
            taskName,
            selectedFlow?.selected,
            team,
            list,
            selectedFilterStatus?.value,
            selectedTaskType.value
          );
        }
      });
  }
  function handleTeamOptions() {
    axios
      .get(`${API_URL}project_dashboard/team_options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var list = [];
        var data = response.data.data;
        data.map((item, index) => {
          list.push({ label: item.name, value: item._id });
        });
        setOptionTeam(list);
      });
  }
  const handleInputChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (name == "from_date") {
      handleAllTaskTableData(
        selectedProject?.value,
        selectedStaff?.value,
        value,
        to_date,
        taskName,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
      setFromDate(value);
    } else if (name == "to_date") {
      setToDate(value);
      handleAllTaskTableData(
        selectedProject?.value,
        selectedStaff?.value,
        from_date,
        value,
        taskName,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
    } else if (name == "taskname") {
      handleAllTaskTableData(
        selectedProject?.value,
        selectedStaff?.value,
        from_date,
        to_date,
        value,
        selectedFlow?.selected,
        selectedTeam?.value,
        optionStaff,
        selectedFilterStatus?.value,
        selectedTaskType.value
      );
      setTaskName(value);
    }
  };
  // const handleSearch = ()=>{
  //   //console.log(optionStaff);
  //   handleAllTaskTableData(selectedProject?.value,selectedStaff?.value,from_date,to_date,taskName);
  // }
  const reset = () => {
    setSelectedProject(null);
    setSelectedFlow(null);
    setSelectedFilterStatus(null);
    setSelectedTeam(null);
    setFromDate("");
    setToDate("");
    setTaskName("");
    if (!selectedUser) {
      setSelectedStaff(null);
      handleAllTaskTableData("", "", "", "", "", "", "", [], "", "", "reset");
    } else {
      handleAllTaskTableData("", selectedUser?.value, "", "", "", "", "", [], "", "", "reset");
    }
  };
  const handleSelectProject = (e) => {
    setSelectedProject(e);
  };
  const handleSelectStaff = (e) => {
    setSelectedStaff(e);
    handleAllTaskTableData(
      selectedProject?.value,
      e.value,
      from_date,
      to_date,
      taskName,
      selectedFlow?.selected,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      selectedTaskType.value
    );
  };
  const handleSelectFlow = (e) => {
    setSelectedFlow(e);
    handleAllTaskTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      e,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      selectedTaskType.value
    );
  };
  const handleSelectTaskType = (e) => {
    setSelectedTaskType(e);
    handleAllTaskTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      selectedFlow?.selected,
      selectedTeam?.value,
      optionStaff,
      selectedFilterStatus?.value,
      e.value
    );
  };
  const handleSelectFilterStatus = (e) => {
    setSelectedFilterStatus(e);
    handleAllTaskTableData(
      selectedProject?.value,
      selectedStaff?.value,
      from_date,
      to_date,
      taskName,
      e,
      selectedTeam?.value,
      optionStaff,
      e.value,
      selectedTaskType.value
    );
  };
  const handleSelectTeam = (e) => {
    setSelectedTeam(e);
    if (!selectedUser) {
      setSelectedStaff(null);
    }
    handleStaffOptions(e.value);
  };
  const showAllTask = (index) => {
    var tasks = [...alltasktableData];
    tasks[index].expand = "show";
    setalltaskTableData(tasks);
  };
  const hideAllTask = (index) => {
    var tasks = [...alltasktableData];
    tasks[index].expand = "hidden";
    setalltaskTableData(tasks);
  };
  const handleShow = (item, index) => {
    setMsgObject({
      ...msgObject,
      ["assign_id"]: item._id,
      ["flow_id"]: item.task_flow_id,
      ["task_id"]: item._id,
      ["project_id"]: item.project_id,
      ["subcategory_id"]: item.task_subcategory_id,
    });
    axios
      .get(`${API_URL}taskcomment/list?task_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var data = response.data.data;
        setMessages(response.data.data);
        if (typeof response.data.project_info.assignees !== "undefined") {
          response.data.project_info.assignees.map((item, index) => {
            item.id = index + 1;
            if (item.assign_time) item.assign_time1 = timeFromNumber(item.assign_time);
            else item.assign_time1 = "00:00";

            if (item.assign_effort_hr) item.assign_effort_hr1 = item.assign_effort_hr.substring(0, item.assign_effort_hr.lastIndexOf(":"));
            else item.assign_effort_hr1 = "00:00";
            if (item.assign_effort_h1r == "") item.assign_effort_hr1 = "00:00";
          });
        }
        setChatboxData(response.data.project_info);
        setCanvasShow(true);
        scrollTop();
      });
  };
  const chat_flow_count_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 90,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 90,
      },
      {
        label: "Assignee",
        field: "name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Plan",
        field: "assign_time1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Effort",
        field: "assign_effort_hr1",
        sort: "asc",
        width: 90,
      },
    ],
    rows: chatboxData.assignees,
  };
  const uploadAttachment = (e) => {
    const fd = new FormData();
    for (var i = 0; i < e.target.files.length; i++) {
      fd.append("images", e.target.files[i]);
    }
    axios
      .post(`${API_URL}taskcomment/images/`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAttachCount(response.data.file.length);
          setMsgObject({
            ...msgObject,
            ["images"]: response.data.file,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleMessage = (e) => {
    setMsgObject({
      ...msgObject,
      ["comment"]: e.target.value,
    });
  };
  const handleSendMessage = () => {
    axios
      .post(`${API_URL}taskcomment/`, msgObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var new_message = response.data.data;
        var messages_list = [...messages];
        messages_list.push(new_message);
        setMessages(messages_list);
        setMsgObject({
          ...msgObject,
          ["comment"]: "",
          ["images"]: [],
        });
        setAttachCount("");
        scrollTop();
      });
  };
  function scrollTop() {
    const curr = bottomRef.current;
    if (curr) {
      curr.scrollTop = 560 * 1000;
    }
  }
  const handleAssignComplete = (e, index, index1, item, subItem) => {
    if (e.target.checked === true) {
      var list = [...alltasktableData];
      list[index].flows_all[index1].assign_complete_percentage = 100;
      list[index].flows_all[index1].assign_complete_status = 1;

      axios
        .post(
          `${API_URL}taskassign/update_assign_completion`,
          { assign_id: list[index].flows_all[index1].assign_id, status: 1, workeffort: subItem.workeffort, tot_workeffor: item.workeffort },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          list[index].tot_percenatge = response.data.data.total_percentage;
          if (response.data.data.efficiency > 100) response.data.data.efficiency = 100;
          list[index].flows_all[index1].efficiency = response.data.data.efficiency;
          if (response.data.data.tot_efficiency > 100) response.data.data.tot_efficiency = 100;
          list[index].efficiency = response.data.data.tot_efficiency;
          setalltaskTableData(list);
        });
    } else {
      var list = [...alltasktableData];
      axios
        .post(
          `${API_URL}taskassign/update_assign_completion`,
          { assign_id: list[index].flows_all[index1].assign_id, status: 0, workeffort: subItem.workeffort, tot_workeffor: item.workeffort },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((response) => {
          list[index].flows_all[index1].assign_complete_percentage = response.data.data.prev_per;
          if (response.data.data.efficiency > 100) response.data.data.efficiency = 100;
          list[index].flows_all[index1].efficiency = response.data.data.efficiency;
          list[index].flows_all[index1].assign_complete_status = 0;
          list[index].tot_percenatge = response.data.data.total_percentage;
          if (response.data.data.tot_efficiency > 100) response.data.data.tot_efficiency = 100;
          list[index].efficiency = response.data.data.tot_efficiency;
          setalltaskTableData(list);
        });
    }
  };
  function fetchFlow() {
    axios
      .get(`${API_URL}taskFlow/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var FlowID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.item_name,
              value: el._id,
            };
          });
        setFlowOptions([{ options: FlowID }]);
      });
  }
  const handleCountClick = (value) => {
    setalltaskTableData([]);
    var status = "";
    if (value == 1) {
      setSelectedFilterStatus(null);
    } else if (value == 2) {
      status = 1;
      setSelectedFilterStatus({ label: "Completed", value: 1 });
    } else if (value == 3) {
      status = 2;
      setSelectedFilterStatus({ label: "In Completed", value: 2 });
    } else if (value == 4) {
      status = 3;
      setSelectedFilterStatus({ label: "Unassigned", value: 3 });
    } else if (value == 5) {
      status = 4;
      setSelectedFilterStatus({ label: "Over Due", value: 4 });
    } else if (value == 6) {
      status = 5;
      setSelectedFilterStatus({ label: "Over Effort", value: 5 });
    }
    setSelectedProject(null);
    setSelectedFlow(null);
    setSelectedTeam(null);
    setFromDate("");
    setToDate("");
    setTaskName("");
    if (!selectedUser) {
      setSelectedStaff(null);
      handleAllTaskTableData("", "", "", "", "", "", "", [], status, "", selectedTaskType.value, "reset");
      setTab3("3");
    } else {
      handleAllTaskTableData("", selectedUser?.value, "", "", "", "", "", [], status, "", selectedTaskType.value, "reset");
      setTab3("3");
    }
  };
  useEffect(async () => {
    handleTeamOptions();
    handleProjectOptions();
    handleStaffOptions();
    fetchFlow();
  }, [addProject]);
  const customFieldStyle = { lineHeight: "1.7rem" };

  // ########################## support ticket #########################
  const [supporttickettableData, setSupportTicketTableData] = useState([]);
  const [followupObject, setFollowupObject] = useState({});
  const [followupstatusError, setfollowupstatusError] = useState("");
  const [followupTableData, setFollowupTableData] = useState([]);
  const [selectedBill, setSelectedBill] = useState({ label: "Unpaid", value: 0 });
  const [showPopup, setShowPopup] = useState(false);
  const [supportDetails, setsupportDetails] = useState({});
  const formRef1 = useRef();
  function handlesupportTicketTableData() {
    var url = `${API_URL}support_ticket/list?project=` + addProject;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-comment-medical"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickSupportOpen(item._id);
                }}></i>
              {/* <i 
          className="far fa-edit"
          style={{
            fontSize: "1em",
            cursor: "pointer",
            marginLeft: "0.1rem",
            marginRight: "0.5rem",
          }}
          onClick={() => {
            toTop();
            preUpdateTicket(item);
          }}
      >
        </i>   

        <i
          className="far fa-trash-alt"
          style={{ fontSize: "1em", cursor: "pointer" }}
          onClick={()=>{
              setsupportIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
          }}
          >
        </i> */}
            </div>
          );
          item.id = index + 1;
          if (item?.date_and_time) {
            item.day = moment(item.date_and_time).format("DD-MM-YYYY");
            item.time = moment(item.date_and_time).format("hh:mm a");
          }
          item.client_name = (
            <a href="/client" target={"_blank"}>
              {item.client_name}
            </a>
          );
          item.project_name = (
            <a href={"/project/" + item.project_unq_id} target={"_blank"}>
              {item.project_name}
            </a>
          );
          if (item.status == 0) {
            var status_name = "New";
            var color = "#527dff";
          } else if (item.status == 2) {
            var status_name = "Pending";
            var color = "#f19696";
          } else if (item.status == 3) {
            var status_name = "Completed";
            var color = "#34c38f";
          } else if (item.status == 4) {
            var status_name = "Hold";
            var color = "#e69900";
          } else if (item.status == 5) {
            var status_name = "Solved";
            var color = "#00ccff";
          } else if (item.status == 6) {
            var status_name = "Closed";
            var color = "#c70a0a";
          } else if (item.status == 7) {
            var status_name = "No Issue";
            var color = "#ab7e2b";
          } else if (item.status == 8) {
            var status_name = "Informed";
            var color = "#8695e7";
          }

          item.support_status = (
            <span className="badge" style={{ background: color, fontSize: "12px" }}>
              {status_name}
            </span>
          );

          if (item?.tasks == 0) {
            item.task_status = "No Task";
            //item.support_status = "New";
          } else if (item?.tot_completed == item?.tot_assign) {
            item.task_status = item?.tot_completed + "/" + item?.tot_assign + " Completed";
            //item.support_status = "Completed";
          } else if (item?.tot_unassigned > 0) {
            item.task_status = item?.tot_unassigned + "/" + item?.tot_assign + " Unassigned";
            //item.support_status = "Pending";
          } else if (item?.tot_unassigned == 0) {
            var pending = item?.tot_assign - item?.tot_completed;
            item.task_status = pending + "/" + item?.tot_assign + " Pending";
            //item.support_status = "Pending";
          }
          var start_date = new Date(item.date_and_time);
          if (typeof item.last_followup !== "undefined" && item.status == 6) {
            var end_date = new Date(item?.last_followup.date + " " + item?.last_followup.time);
          } else {
            var end_date = new Date();
          }
          item.tat = (
            <span className="badge bg-dark" style={{ fontSize: "12px" }}>
              {timeSince(start_date, end_date)}
            </span>
          );

          resData.push(item);
        });
        setSupportTicketTableData(resData);
      });
  }
  const handleClickSupportOpen = (id) => {
    setFollowupObject({
      support_id: id,
    });
    axios
      .get(`${API_URL}support_ticket/detailed-view?support_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let item = res.data.data;
        var all_followup = res.data.followup;
        if (item?.date_and_time) {
          item.day = moment(item.date_and_time).format("DD-MM-YYYY");
          item.time = moment(item.date_and_time).format("hh:mm a");
        }

        if (item?.bill_status == 0) {
          item.bill_status1 = "Unpaid";
          setSelectedBill({ label: "Unpaid", value: 0 });
        }
        if (item?.bill_status == 1) {
          item.bill_status1 = "Paid";
          setSelectedBill({ label: "Paid", value: 1 });
        }
        item.edited = item?.updatedby_name;
        item.add = item?.addedby_name;

        let upTime = item?.up_time || {};
        let upDate = item?.up_date || {};
        item.update = moment(upDate).format("DD-MM-YYYY") + " " + moment(upDate, "HHmmss").format("hh:mm a");

        let Time = item.time || {};
        let Dates = item.date || {};
        item.create = moment(Dates).format("DD-MM-YYYY") + " " + moment(Time, "HHmmss").format("hh:mm a");

        if (item.status == 0) {
          var status_name = "New";
          var color = "#527dff";
        } else if (item.status == 2) {
          var status_name = "Pending";
          var color = "#f19696";
        } else if (item.status == 3) {
          var status_name = "Completed";
          var color = "#34c38f";
        } else if (item.status == 4) {
          var status_name = "Hold";
          var color = "#e69900";
        } else if (item.status == 5) {
          var status_name = "Solved";
          var color = "#00ccff";
        } else if (item.status == 6) {
          var status_name = "Closed";
          var color = "#c70a0a";
        } else if (item.status == 7) {
          var status_name = "No Issue";
          var color = "#ab7e2b";
        } else if (item.status == 8) {
          var status_name = "Informed";
          var color = "#8695e7";
        }

        item.support_status = (
          <span className="badge" style={{ background: color, fontSize: "12px", padding: "3px" }}>
            {status_name}
          </span>
        );
        if (item?.tasks == 0) {
          item.task_status = "No Task";
          //item.support_status = "New";
        } else if (item?.tot_completed == item?.tot_assign) {
          item.task_status = item?.tot_completed + "/" + item?.tot_assign + " Completed";
          // item.support_status = "Completed";
        } else if (item?.tot_unassigned > 0) {
          item.task_status = item?.tot_unassigned + "/" + item?.tot_assign + " Unassigned";
          //item.support_status = "Pending";
        } else if (item?.tot_unassigned == 0) {
          var pending = item?.tot_assign - item?.tot_completed;
          item.task_status = pending + "/" + item?.tot_assign + " Pending";
          //item.support_status = "Pending";
        }

        var start_date = new Date(item.date_and_time);
        if (typeof item.last_followup !== "undefined" && item.status == 6) {
          var end_date = new Date(item?.last_followup.date + " " + item?.last_followup.time);
        } else {
          var end_date = new Date();
        }
        item.tat = (
          <span className="badge bg-dark" style={{ fontSize: "12px", padding: "3px" }}>
            {timeSince(start_date, end_date)}
          </span>
        );
        setShowPopup(true);
        setsupportDetails(item);
        var followups = [];
        all_followup.sort(function (a, b) {
          var x = a._id;
          var y = b._id;
          // factor -1 because you want them sorted DESC
          return -1 * (x < y ? -1 : x > y ? 1 : 0);
        });
        all_followup.map((el, index) => {
          if (el.followup_status == 0) {
            var status_name = "New";
            var color = "#527dff";
          } else if (el.followup_status == 2) {
            var status_name = "Pending";
            var color = "#f19696";
          } else if (el.followup_status == 3) {
            var status_name = "Completed";
            var color = "#34c38f";
          } else if (el.followup_status == 4) {
            var status_name = "Hold";
            var color = "#e69900";
          } else if (el.followup_status == 5) {
            var status_name = "Solved";
            var color = "#00ccff";
          } else if (el.followup_status == 6) {
            var status_name = "Closed";
            var color = "#c70a0a";
          } else if (el.followup_status == 7) {
            var status_name = "No Issue";
            var color = "#ab7e2b";
          } else if (item.status == 8) {
            var status_name = "Informed";
            var color = "#8695e7";
          }

          followups.push({
            id: index + 1,
            date: moment(el.date).format("DD-MM-YYYY"),
            time: moment(el.time, "HH:mm:ss").format("hh:mm a"),
            username: el?.addedby?.firstName,
            status: (
              <span className="badge" style={{ background: color, fontSize: "12px" }}>
                {status_name}
              </span>
            ),
            comment: el?.comments,
          });
        });
        setFollowupTableData(followups);
      });
  };
  const handleSelectBill = (selected) => {
    setSelectedBill(selected);
    axios
      .post(
        `${API_URL}support_ticket/update-bill-status`,
        { status: selected.value, support_id: followupObject.support_id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        handleClickSupportOpen(followupObject.support_id);
        setsupportDetails({
          ...supportDetails,
          bill_status: selected.value,
        });
        toastr.success("Submitted Successfully");
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });
  };
  const followreset = () => {
    formRef1.current.reset();
    setFollowupObject({
      ...followupObject,
      status_id: null,
      status: null,
      comment: "",
    });
  };
  const handleFollowupSubmit = () => {
    if (typeof followupObject.status !== "undefined") {
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    } else {
      $("#followupstatus").attr("class", "select2-selection border border-danger");
      setfollowupstatusError("Select Status");
      return;
    }
    axios
      .post(`${API_URL}support_ticket/add-followup`, followupObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        formRef1.current.reset();
        setFollowupObject({
          ...followupObject,
          status: null,
          status_id: null,
          comment: "",
        });
        handleClickSupportOpen(followupObject.support_id);
        toastr.success("Submitted Successfully");
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });
  };
  const handleFollowupData = (e, type) => {
    if (type == "followup_status") {
      setFollowupObject({
        ...followupObject,
        status_id: e.value,
        status: e,
      });
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    } else if (type == "comment") {
      setFollowupObject({
        ...followupObject,
        comment: e.target.value,
      });
    }
  };
  const support_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "day",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Contact",
        field: "support_contact_person",
        sort: "asc",
        width: 150,
      },
      {
        label: "Client",
        field: "client_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Project",
        field: "project_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mode",
        field: "mode_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "support_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "TAT",
        field: "tat",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: supporttickettableData,
  };
  const followup_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comments",
        field: "comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "username",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followupTableData,
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteTaskAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              handleDeleteTask(taskIdToBeDeleted.id, taskIdToBeDeleted.index);
            }}
            onCancel={() => setConfirmDeleteTaskAlert(false)}>
            Are you sure you want to delete this task?
          </SweetAlert>
        ) : null}
        {confirmDeleteAssignAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              handleDeleteAssign(assignIdToBeDeleted?.aid, assignIdToBeDeleted?.index1, assignIdToBeDeleted?.index);
            }}
            onCancel={() => setConfirmDeleteAssignAlert(false)}>
            Are you sure you want to delete this assign?
          </SweetAlert>
        ) : null}
        {confirmDisableFlow ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              handleFlowCheckboxClick(assignIdToBeDeleted?.checked, assignIdToBeDeleted?.flow_id, assignIdToBeDeleted?.index, assignIdToBeDeleted?.index1);
              setConfirmDisableFlow(false);
            }}
            onCancel={() => setConfirmDisableFlow(false)}>
            If you delete this flow, this assign task is also deleted. Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md="6">
                <div className="page-title-box d-flex align-items-center" style={{ paddingBottom: "0px" }}>
                  <h4>{details?.project_name}</h4>
                  &nbsp;
                  <h4>-</h4>
                  &nbsp;
                  <h4>{details?.unique_id}</h4>
                  &emsp;
                  <h4
                    // style={{
                    //   marginTop: "1%",
                    // }}
                    className="badge rounded-pill ">
                    {details?.ProjectStatus}
                  </h4>
                  &emsp;
                  <h4>{details?.hours} Hrs</h4>
                </div>
              </Col>
              <Col md="6">
                <div className="page-title-box d-flex float-end">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="/dashboard">CRM</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to="/view-projects">Project</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link>{details?.unique_id}</Link>
                      </BreadcrumbItem>
                      {/* <BreadcrumbItem active>
                      <Link to="#">{props.breadcrumbItem}</Link>
                    </BreadcrumbItem> */}
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                {/* First Dialogue */}

                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-xl-12" style={{ marginTop: "-17px" }}>
                        <Box sx={{ width: "100%" }}>
                          <TabContext value={tab3}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                              <TabList
                                style={{ padding: "0px 0px 0px 0px" }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleChangeTab}
                                aria-label="lab API tabs example">
                                <Tab
                                  // icon={
                                  //   <DashboardIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Dashboard"
                                  value="1"
                                  size="small"
                                  style={{
                                    paddingTop: "26px",
                                    marginLeft: "18px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />

                                <Tab
                                  // icon={
                                  //   <AccountBalanceIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Board Design"
                                  value="2"
                                  size="small"
                                  style={{
                                    paddingTop: "26px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  // icon={
                                  //   <TransferWithinAStationIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Timeline"
                                  value="3"
                                  style={{
                                    paddingTop: "26px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  // icon={
                                  //   <TransferWithinAStationIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Manage Task"
                                  value="4"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    display: taskAssignPermission,
                                    paddingTop: "26px",
                                  }}
                                />
                                <Tab
                                  // icon={
                                  //   <CreditCardIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="All Task"
                                  value="5"
                                  style={{
                                    paddingTop: "26px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  label="QA"
                                  value="6"
                                  style={{
                                    paddingTop: "26px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />

                                {/* <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Checklist"
                                  value="7"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                /> */}
                                {/* <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Support"
                                  value="8"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                /> */}
                                <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Client"
                                  value="9"
                                  style={{
                                    paddingTop: "26px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Support Ticket"
                                  value="10"
                                  style={{
                                    paddingTop: "26px",
                                    marginRight: "18px",
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                {/* <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="Manage Ticket"
                                  value="11"
                                  style={{
                                    
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                /> */}
                                {/* <Tab
                                  // icon={
                                  //   <ConnectWithoutContactIcon
                                  //     style={{ fontSize: "20px" }}
                                  //   />
                                  // }
                                  label="All Ticket"
                                  value="12"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                /> */}
                              </TabList>
                            </Box>
                            <TabPanel value="1" style={{ padding: "14px 0px 0px 0px" }}>
                              {/* <Dashboard /> */}
                              <Row>
                                <Col md="4">
                                  <Row>
                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(1)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.tot} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">Total Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span className={"text-primary me-1"}>
                                              <i className={"fas fa-laptop-code me-1"}></i>
                                              {totalTask.totalsub}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(2)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.complete} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">Completed Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span className={"text-success me-1"}>
                                              <i className={"fas fa-tasks me-1"}></i>
                                              {totalTask.completed_tasks}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(3)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.incomplete} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">InComplete Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span style={{ color: "#f1b44c" }} className={"me-1"}>
                                              <i className={"fas fa-times me-1"}></i>
                                              {totalTask.incomplete_tasks}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(4)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.tot_unassigned} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">Unassigned Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span style={{ color: "#b96e96" }} className={"me-1"}>
                                              <i className={"fas fa-user-alt-slash me-1"}></i>
                                              {totalTask.unassigned_tasks}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(5)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.overdue} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">Over Due Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span style={{ color: "#2f88d3" }} className={"me-1"}>
                                              <i className={"fas fa-calendar-alt me-1"}></i>
                                              {totalTask.due_tasks}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>

                                    <Col md={6} xl={6} style={{ cursor: "pointer" }} onClick={() => handleCountClick(6)}>
                                      <Card>
                                        <CardBody style={{ padding: "10px 10px" }}>
                                          <div>
                                            <h4 className="mb-1 mt-1">
                                              <span>
                                                <CountUp end={totalTask.overeffort} separator="" prefix="" suffix="" decimals={0} />
                                              </span>
                                            </h4>
                                            <p className="text-muted mb-0">Over Effort Modules</p>
                                          </div>
                                          <p className="text-muted mt-3 mb-0">
                                            <span className={"text-danger me-1"}>
                                              <i className={"fas fa-fire me-1"}></i>
                                              {totalTask.overeffor_tasks}
                                            </span>{" "}
                                            Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="8">
                                  <Card>
                                    <CardBody style={{ padding: "7px" }}>
                                      <div className="mb-3">
                                        {/* <h5></h5> */}
                                        {taskPermission === false ? (
                                          <>
                                            <table id="projectInfoTable" width={"100%"}>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Client</td>
                                                <td style={{ textAlign: "left" }}>: {details?.clients}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Type</td>
                                                <td style={{ textAlign: "left" }}>: {details?.types}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Project Name</td>
                                                <td style={{ textAlign: "left" }}>: {details?.project_name}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Shortcode</td>
                                                <td style={{ textAlign: "left" }}>: {details?.shortcode}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Technologies</td>
                                                <td style={{ textAlign: "left" }}>: {details?.TechForTbl}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Domain</td>
                                                <td style={{ textAlign: "left" }}>
                                                  <a target="_blank" href={details?.domain} style={{ paddingLeft: "0%" }}>
                                                    : {details?.domain}
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Quotation No</td>
                                                <td style={{ textAlign: "left" }}>: {details?.quotation_no}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Invoice No</td>
                                                <td style={{ textAlign: "left" }}>: {details?.invoice_no}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Kick Off</td>
                                                <td style={{ textAlign: "left" }}>: {details?.kickoff}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>End</td>
                                                <td style={{ textAlign: "left" }}>: {details?.End}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Project Status</td>
                                                <td style={{ textAlign: "left" }}>
                                                  :
                                                  <p
                                                    className="badge rounded-pill "
                                                    style={{
                                                      fontSize: "initial",
                                                      marginBottom: "auto",
                                                      paddingLeft: "15px",
                                                    }}>
                                                    {details?.ProjectStatus}
                                                  </p>
                                                </td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Billing Status</td>
                                                <td style={{ textAlign: "left" }}>: {details?.bstatus}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Manager</td>
                                                <td style={{ textAlign: "left" }}>
                                                  : {details.project_manager?.firstName} {details.project_manager?.lastName}
                                                </td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Team Leader</td>
                                                <td style={{ textAlign: "left" }}>
                                                  : {details.project_leader?.firstName} {details.project_leader?.lastName}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Hours</td>
                                                <td style={{ textAlign: "left" }}>: {details?.hours}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Remark</td>
                                                <td style={{ textAlign: "left" }}>: {details?.remarks}</td>
                                                {/* <td style={{ padding: "10px", fontWeight: "500", }}>
                                              Budget
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              : {details?.rupees}
                                            </td> */}
                                              </tr>
                                            </table>
                                          </>
                                        ) : (
                                          <>
                                            <table id="projectInfoTable" width={"100%"}>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Client</td>
                                                <td style={{ textAlign: "left" }}>: {details?.clients}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Project Name</td>
                                                <td style={{ textAlign: "left" }}>: {details?.project_name}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Type</td>
                                                <td style={{ textAlign: "left" }}>: {details?.types}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Technologies</td>
                                                <td style={{ textAlign: "left" }}>: {details?.TechForTbl}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Kick Off</td>
                                                <td style={{ textAlign: "left" }}>: {details?.kickoff}</td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>End</td>
                                                <td style={{ textAlign: "left" }}>: {details?.End}</td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Manager</td>
                                                <td style={{ textAlign: "left" }}>
                                                  : {details.project_manager?.firstName} {details.project_manager?.lastName}
                                                </td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Team Leader</td>
                                                <td style={{ textAlign: "left" }}>
                                                  : {details.project_leader?.firstName} {details.project_leader?.lastName}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Domain</td>
                                                <td style={{ textAlign: "left" }}>
                                                  <a target="_blank" href={details?.domain} style={{ paddingLeft: "0%" }}>
                                                    : {details?.domain}
                                                  </a>
                                                </td>
                                                <td style={{ padding: "10px", fontWeight: "500" }}>Remark</td>
                                                <td style={{ textAlign: "left" }}>: {details?.remarks}</td>
                                              </tr>
                                            </table>
                                          </>
                                        )}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col md="12">
                                  {/* <Card>
                                    <CardBody> */}

                                  <MDBDataTable
                                    id="flowCountTableId"
                                    responsive
                                    bordered
                                    data={flow_count_data}
                                    searching={false}
                                    disableRetreatAfterSorting={true}
                                    paging={false}
                                    info={false}
                                  />

                                  {/* </CardBody>
                                  </Card> */}
                                </Col>
                                {
                                  // taskPermission===false?(
                                  //   <>
                                  //       <Col className="col-12" style={{ paddingRight:"unset"}}>
                                  //         {/* <Card>
                                  //           <CardBody> */}
                                  //           <MDBDataTable
                                  //           id="projectDashboardTableId"
                                  //           responsive
                                  //           bordered
                                  //           data={data}
                                  //           searching={false}
                                  //           info={false}
                                  //           disableRetreatAfterSorting={true}
                                  //           paging={false}
                                  //         />
                                  //           {/* </CardBody>
                                  //         </Card> */}
                                  //       </Col>
                                  //   </>
                                  // ):(
                                  //   <></>
                                  // )
                                }
                              </Row>
                            </TabPanel>

                            <TabPanel value="4" style={{ padding: "14px 0px 0px 0px" }}>
                              {isExpand == null || isExpand == false ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    style={{ marginBottom: "8px" }}
                                    onClick={(e) => handleexpandAll(true)}>
                                    Expand
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    style={{ marginBottom: "8px" }}
                                    onClick={(e) => handleexpandAll(null)}>
                                    Shrink
                                  </button>
                                </>
                              )}
                              <div className="btn-group me-1 mt-2">
                                <ButtonDropdown
                                  isOpen={drp_secondary_sm}
                                  toggle={(e) => {
                                    setDrp_secondary_sm(!drp_secondary_sm);
                                  }}
                                  className="btn-sm "
                                  style={{ marginBottom: "14px" }}>
                                  <DropdownToggle caret color="primary" className="btn btn-primary btn-sm">
                                    {manageTaskType}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={(e) => {
                                        setManageTaskType("All Task");
                                        handleTaskTable(addProject, 1);
                                      }}>
                                      All Task
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        setManageTaskType("Work Updates");
                                        handleTaskTable(addProject, 2);
                                      }}>
                                      Work Updates
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        setManageTaskType("Main Task");
                                        handleTaskTable(addProject, 3);
                                      }}>
                                      Main Task
                                    </DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>

                              {/* <BootstrapTreeTable columns={treeColumns} tableData={[...taskRows]} control={treeControls}/>  */}
                              <div>
                                <table class="table table-bordered" id="taskListTable">
                                  <thead>
                                    <tr>
                                      {/* <th>#</th> */}
                                      <th width="200px">Category</th>
                                      <th width="250px">TaskName</th>
                                      <th width="130px">Flow</th>
                                      <th>Assignee</th>
                                      <th width="60px">Time</th>
                                      <th width="95px">Start</th>
                                      <th width="95px">End</th>
                                      {/* <th>Status</th> */}
                                      <th width="55px"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {taskRows.map((item, index) => (
                                      <>
                                        <tr class="shown">
                                          <td class="">
                                            <Row>
                                              <Col md="1" style={{ cursor: "pointer", paddingTop: "5px", paddingLeft: "8px", minWidth: "12px" }}>
                                                <span>
                                                  {item.flows.length > 1 ? (
                                                    <>
                                                      {(isExpand == true && isExpandSub == false) || (isExpandSub == true && item?.expand == "show") ? (
                                                        <>
                                                          <svg
                                                            onClick={() => hideTask(index)}
                                                            aria-hidden="true"
                                                            style={{ height: "15px", width: "15px" }}
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="sort-down"
                                                            class="svg-inline--fa fa-sort-down fa-w-10 fa-fw "
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 320 512"
                                                            id="collapsePoint">
                                                            <path
                                                              fill="currentColor"
                                                              d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                                          </svg>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <svg
                                                            onClick={() => showTask(index)}
                                                            aria-hidden="true"
                                                            style={{ height: "15px", width: "15px" }}
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="angle-right"
                                                            class="svg-inline--fa fa-angle-right fa-w-8 fa-fw "
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 256 512"
                                                            id="expandPoint">
                                                            <path
                                                              fill="currentColor"
                                                              d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                          </svg>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </Col>
                                              <Col md="11" style={{ minWidth: "150px" }}>
                                                <Select
                                                  menuPosition="fixed"
                                                  name="subcategory"
                                                  value={item.subcategory}
                                                  onChange={(selected) => {
                                                    handleAddTask(selected, index, item, "category");
                                                  }}
                                                  options={taskCategoryOptions}
                                                  classNamePrefix="select2-selection"
                                                  isDisabled={taskPermission}
                                                />
                                              </Col>
                                            </Row>
                                          </td>
                                          <td class="">
                                            <input
                                              disabled={taskPermission}
                                              type="text"
                                              name="task_name"
                                              class="form-control"
                                              value={item.task_name}
                                              onChange={(e) => handleChangeTask(e, index, item, "task_name")}
                                              onBlur={(e) => handleAddTask(e, index, item, "task_name")}></input>
                                          </td>
                                          <td class="">
                                            <div class="form-check">
                                              <input
                                                disabled={taskPermission}
                                                class="form-check-input"
                                                type="checkbox"
                                                id="defaultCheck2"
                                                value={item.flows[0]?.flow_id}
                                                checked={item.flows[0]?.flow_exists ? "true" : ""}
                                                onClick={(e) => {
                                                  handleFlowCheckbox(e, index, 0);
                                                }}
                                              />
                                              <label class="form-check-label" for="defaultCheck2">
                                                {item.flows[0]?.flow_name}
                                              </label>
                                            </div>
                                          </td>
                                          <td class="">
                                            <Select
                                              menuPosition="fixed"
                                              name="staff_id"
                                              placeholder=""
                                              value={item.flows[0]?.assignee}
                                              onChange={(selected) => {
                                                handleAssignTask(selected, index, 0, item, item.flows[0], "staff");
                                              }}
                                              options={taskAssigneeOptions}
                                              classNamePrefix="select2-selection"
                                              isDisabled={taskPermission == true ? taskPermission : item.flows[0]?.flow_exists ? "" : "true"}
                                            />
                                          </td>
                                          {/* <td class=""><input type="text" name="task_tot_time" class="form-control" value={item?.task_tot_time} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_start_date" class="form-control" value={item?.task_start_date} style={{ width: '115px' }} readOnly></input></td>
                                                        <td class=""><input type='date' name="task_end_date" class="form-control" value={item?.task_end_date} style={{ width: '115px' }} readOnly></input></td>
                                                         */}
                                          <td class="">
                                            <input
                                              disabled={taskPermission == true ? taskPermission : item.flows[0]?.flow_exists ? "" : "true"}
                                              name="assign_time"
                                              class="form-control"
                                              value={item.flows[0]?.assign_time}
                                              onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "time")}></input>
                                          </td>
                                          <td class="">
                                            <input
                                              disabled={taskPermission == true ? taskPermission : item.flows[0]?.flow_exists ? "" : "true"}
                                              min={moment().format("YYYY-MM-DD")}
                                              type="date"
                                              name="assign_startdate"
                                              class="form-control"
                                              value={item.flows[0]?.assign_startdate}
                                              onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "start")}
                                              style={{ width: "115px" }}></input>
                                          </td>
                                          <td class="">
                                            <input
                                              disabled={taskPermission == true ? taskPermission : item.flows[0]?.flow_exists ? "" : "true"}
                                              min={item.flows[0]?.assign_startdate ? item.flows[0]?.assign_startdate : moment().format("YYYY-MM-DD")}
                                              type="date"
                                              name="assign_enddate"
                                              class="form-control"
                                              value={item.flows[0]?.assign_enddate}
                                              onChange={(e) => handleAssignTask(e, index, 0, item, item.flows[0], "end")}
                                              style={{ width: "115px" }}></input>
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            {item._id && taskPermission == false ? (
                                              <Row>
                                                <Col md="4">
                                                  {item?.unread_comments > 0 ? (
                                                    <div class="chat-icon">
                                                      <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                      <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                    </div>
                                                  ) : item?.total_comments > 0 ? (
                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                  ) : (
                                                    <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                  )}
                                                </Col>
                                                <Col md="4">
                                                  <i
                                                    className="fas fa-trash"
                                                    title="Delete Assign"
                                                    onClick={() => {
                                                      setAssignIdToBeDeleted({ aid: item.flows[0].assign_id, index1: 0, index: index });
                                                      setConfirmDeleteAssignAlert(true);
                                                    }}></i>
                                                </Col>
                                                <Col md="4">
                                                  <i
                                                    className="fas fa-trash text-danger"
                                                    title="Delete Main Task"
                                                    onClick={() => {
                                                      setTaskIdToBeDeleted({ id: item._id, index: index });
                                                      setConfirmDeleteTaskAlert(true);
                                                    }}></i>
                                                </Col>
                                              </Row>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                        {item.flows &&
                                          item.flows.map((subItem, index1) => {
                                            if (index1 != 0)
                                              return (
                                                <>
                                                  <tr
                                                    class={
                                                      (isExpand == true && isExpandSub == false) || (isExpandSub == true && item?.expand == "show")
                                                        ? "show"
                                                        : "hidden"
                                                    }>
                                                    <td class=""></td>
                                                    <td class=""></td>
                                                    <td class="">
                                                      <div class="form-check">
                                                        <input
                                                          disabled={taskPermission}
                                                          class="form-check-input"
                                                          type="checkbox"
                                                          id="defaultCheck2"
                                                          value={subItem?.flow_id}
                                                          checked={subItem?.flow_exists ? "true" : ""}
                                                          onClick={(e) => {
                                                            handleFlowCheckbox(e, index, index1);
                                                          }}
                                                        />
                                                        <label class="form-check-label" for="defaultCheck2">
                                                          {subItem?.flow_name}
                                                        </label>
                                                      </div>
                                                    </td>
                                                    <td class="">
                                                      <Select
                                                        menuPosition="fixed"
                                                        name="staff_id"
                                                        placeholder=""
                                                        value={subItem?.assignee}
                                                        onChange={(selected) => {
                                                          handleAssignTask(selected, index, index1, item, subItem, "staff");
                                                        }}
                                                        options={taskAssigneeOptions}
                                                        classNamePrefix="select2-selection"
                                                        isDisabled={taskPermission == true ? taskPermission : subItem?.flow_exists ? "" : "true"}
                                                      />
                                                    </td>
                                                    <td class="">
                                                      <input
                                                        disabled={taskPermission == true ? taskPermission : subItem?.flow_exists ? "" : "true"}
                                                        name="assign_time"
                                                        class="form-control"
                                                        value={subItem?.assign_time}
                                                        onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "time")}></input>
                                                    </td>
                                                    <td class="">
                                                      <input
                                                        disabled={taskPermission == true ? taskPermission : subItem?.flow_exists ? "" : "true"}
                                                        min={moment().format("YYYY-MM-DD")}
                                                        type="date"
                                                        name="assign_startdate"
                                                        class="form-control"
                                                        value={subItem?.assign_startdate}
                                                        onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "start")}
                                                        style={{ width: "115px" }}></input>
                                                    </td>
                                                    <td class="">
                                                      <input
                                                        disabled={taskPermission == true ? taskPermission : subItem?.flow_exists ? "" : "true"}
                                                        min={subItem?.assign_startdate ? subItem?.assign_startdate : moment().format("YYYY-MM-DD")}
                                                        type="date"
                                                        name="assign_enddate"
                                                        class="form-control"
                                                        value={subItem?.assign_enddate}
                                                        onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "end")}
                                                        style={{ width: "115px" }}></input>
                                                    </td>

                                                    <td>
                                                      {taskPermission ? (
                                                        ""
                                                      ) : (
                                                        <i
                                                          className="fas fa-trash"
                                                          title="Delete Assign"
                                                          onClick={() => {
                                                            setAssignIdToBeDeleted({ aid: subItem.assign_id, index1: index1, index: index });
                                                            setConfirmDeleteAssignAlert(true);
                                                          }}></i>
                                                      )}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                          })}
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {taskPermission === false ? (
                                <>
                                  <button type="button" className="btn btn-primary btn-sm" style={{ marginBottom: "8px" }} onClick={(e) => addNewTaskRow()}>
                                    Add Task
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </TabPanel>
                            <TabPanel value="5" style={{ padding: "14px 0px 0px 0px" }}>
                              <Row>
                                <Col md="2">
                                  <input
                                    type="text"
                                    value={taskName}
                                    name="taskname"
                                    placeholder="Task name"
                                    className="form-control"
                                    onChange={(e) => handleInputChange(e)}
                                  />
                                </Col>
                                <Col md="2">
                                  <input type="date" value={from_date} name="from_date" className="form-control" onChange={(e) => handleInputChange(e)} />
                                </Col>
                                <Col md="2">
                                  <input type="date" name="to_date" className="form-control" value={to_date} onChange={(e) => handleInputChange(e)} />
                                </Col>
                                <Col md="2">
                                  <Select
                                    placeholder="Team"
                                    value={selectedTeam}
                                    onChange={(e) => {
                                      handleSelectTeam(e);
                                    }}
                                    options={optionTeam}
                                    classNamePrefix="select2-selection"
                                    menuPosition="fixed"
                                  />
                                </Col>
                                <Col md="2">
                                  <Select
                                    placeholder="Staff"
                                    value={selectedStaff}
                                    onChange={(e) => {
                                      handleSelectStaff(e);
                                    }}
                                    options={optionStaff}
                                    classNamePrefix="select2-selection"
                                    menuPosition="fixed"
                                  />
                                </Col>
                                <Col md="2">
                                  <Select
                                    placeholder="Status"
                                    value={selectedFilterStatus}
                                    onChange={(e) => {
                                      handleSelectFilterStatus(e);
                                    }}
                                    options={[
                                      { label: "Completed", value: 1 },
                                      { label: "In completed", value: 2 },
                                      { label: "Unassigned", value: 3 },
                                      { label: "Over Due", value: 4 },
                                      { label: "Over Effort", value: 5 },
                                      { label: "Running", value: 6 },
                                      { label: "Not Running", value: 7 },
                                    ]}
                                    classNamePrefix="select2-selection"
                                    menuPosition="fixed"
                                  />
                                </Col>

                                <Col md="3">
                                  <Select
                                    placeholder="Flows"
                                    value={selectedFlow}
                                    onChange={(e) => {
                                      handleSelectFlow(e);
                                    }}
                                    options={flowOptions}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    menuPosition="fixed"
                                  />
                                </Col>
                                <Col md="2">
                                  <Select
                                    placeholder="All Task"
                                    value={selectedTaskType}
                                    onChange={(e) => {
                                      handleSelectTaskType(e);
                                    }}
                                    options={[
                                      { label: "All Task", value: 1 },
                                      { label: "Work Updates", value: 2 },
                                      { label: "Main Task", value: 3 },
                                    ]}
                                    classNamePrefix="select2-selection"
                                    menuPosition="fixed"
                                  />
                                </Col>
                                {/* <Col md="2">
                              <Select
                              placeholder="Project"
                              value={selectedProject}
                              onChange={(e) => {
                                handleSelectProject(e);
                              }}
                              options={optionProject}
                              classNamePrefix="select2-selection"
                            />
                              </Col> */}

                                <Col md="3">
                                  <div className="mb-2">
                                    {/* <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                  onClick={handleSearch}
                                >
                            
                                  {"  "}
                                  Filter
                                </Button> */}
                                    <Button color="danger" type="submit" onClick={reset}>
                                      {"  "}
                                      Reset
                                    </Button>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <table class="table table-bordered" id="allTaskListTables">
                                    <thead>
                                      <tr>
                                        {/* <th>#</th> */}
                                        <th>TaskName</th>
                                        <th>Flow</th>
                                        {/* <th>Project</th> */}
                                        <th style={{ width: "100px" }}>Assignee</th>
                                        <th style={{ width: "50px" }}>
                                          <i className="fa fa-clock" title="Plan" style={{ fontSize: "10px" }}></i> P
                                        </th>
                                        <th style={{ width: "50px" }}>
                                          <i className="fa fa-clock" title="Effort" style={{ fontSize: "10px" }}></i> E
                                        </th>
                                        {/* <th><i className='fas fa-user-cog' title="Efficiency" style={{ fontSize:"13px"}}></i></th> */}
                                        <th style={{ width: "100px" }}>Start</th>
                                        <th style={{ width: "100px" }}>End</th>
                                        <th>%</th>
                                        <th style={{ width: "46px" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {alltasktableData?.map((project, k) => {
                                        var i = 0;
                                        return (
                                          <>
                                            {project.tasks?.map((item, index) => {
                                              var j = 0;
                                              return (
                                                <>
                                                  {item.flows_all?.map((subItem, index1) => {
                                                    i++;
                                                    j++;
                                                    if (subItem.firstName == "0") subItem.firstName1 = "";
                                                    else
                                                      subItem.firstName1 = (
                                                        <a target={"_blank"} href={"/add_staff/" + subItem?.staff_id}>
                                                          {subItem?.firstName}
                                                        </a>
                                                      );
                                                    if (subItem?.assign_startdate)
                                                      subItem.assign_startdate1 = moment(subItem?.assign_startdate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                                    if (subItem?.assign_enddate)
                                                      subItem.assign_enddate1 = moment(subItem?.assign_enddate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                                    if (subItem?.assign_time) subItem.assign_time1 = timeFromNumber(subItem?.assign_time);
                                                    else subItem.assign_time1 = timeFromNumber(parseFloat(0));
                                                    if (typeof subItem.assign_effort_hr !== "undefined") {
                                                      subItem.assign_effort_hr1 = subItem?.assign_effort_hr.substring(
                                                        0,
                                                        subItem?.assign_effort_hr.lastIndexOf(":")
                                                      );
                                                    } else {
                                                      subItem.assign_effort_hr1 = "00:00";
                                                    }
                                                    if (subItem?.assign_effort_hr1 == "") subItem.assign_effort_hr1 = "00:00";

                                                    if (i == 1 && index == 0) {
                                                      return (
                                                        <>
                                                          <tr>
                                                            {/* <td rowSpan={project.count}>{project.project_name}</td> */}
                                                            <td rowSpan={item?.flows_all?.length}>{item?.task_name}</td>
                                                            <td>{subItem?.flow_name}</td>
                                                            <td>{subItem?.firstName1}</td>
                                                            <td>{subItem?.assign_time1}</td>
                                                            <td>{subItem?.assign_effort_hr1}</td>
                                                            <td>{subItem?.assign_startdate1}</td>
                                                            <td>{subItem?.assign_enddate1}</td>
                                                            <td>
                                                              <Progress
                                                                color="primary"
                                                                style={{ height: "14px", border: subItem.due_status }}
                                                                value={subItem?.assign_complete_percentage}>
                                                                {subItem?.assign_complete_percentage}%
                                                              </Progress>
                                                            </td>
                                                            <td rowSpan={item?.flows_all.length}>
                                                              <div class="chat-icon">
                                                                {item?.unread_comments > 0 ? (
                                                                  <>
                                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                    <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                                  </>
                                                                ) : item?.total_comments > 0 ? (
                                                                  <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                ) : (
                                                                  <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    } else if (j == 1) {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td rowSpan={item.flows_all.length}>{item.task_name}</td>
                                                            <td>{subItem?.flow_name}</td>
                                                            <td>{subItem?.firstName1}</td>
                                                            <td>{subItem?.assign_time1}</td>
                                                            <td>{subItem?.assign_effort_hr1}</td>
                                                            <td>{subItem?.assign_startdate1}</td>
                                                            <td>{subItem?.assign_enddate1}</td>
                                                            <td>
                                                              <Progress
                                                                color="primary"
                                                                style={{ height: "14px", border: subItem?.due_status }}
                                                                value={subItem?.assign_complete_percentage}>
                                                                {subItem?.assign_complete_percentage}%
                                                              </Progress>
                                                            </td>
                                                            <td rowSpan={item?.flows_all.length}>
                                                              <div class="chat-icon">
                                                                {item?.unread_comments > 0 ? (
                                                                  <>
                                                                    <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                    <span class="badge bg-danger rounded-pill">{item?.unread_comments}</span>
                                                                  </>
                                                                ) : item?.total_comments > 0 ? (
                                                                  <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                                                ) : (
                                                                  <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                                                )}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    } else {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td>{subItem?.flow_name}</td>
                                                            <td>{subItem?.firstName1}</td>
                                                            <td>{subItem?.assign_time1}</td>
                                                            <td>{subItem?.assign_effort_hr1}</td>
                                                            <td>{subItem?.assign_startdate1}</td>
                                                            <td>{subItem?.assign_enddate1}</td>
                                                            <td>
                                                              <Progress
                                                                color="primary"
                                                                style={{ height: "14px", border: subItem.due_status }}
                                                                value={subItem.assign_complete_percentage}>
                                                                {subItem.assign_complete_percentage}%
                                                              </Progress>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      );
                                                    }
                                                  })}
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            </TabPanel>
                            <TabPanel value="2" style={{ padding: "14px 0px 0px 0px" }}>
                              <KanbanTask projectDetails={details} />
                            </TabPanel>

                            <TabPanel value="3" style={{ padding: "0px", marginTop: "1rem" }}>
                              <GanttChart projectDetails={details} />
                            </TabPanel>
                            {/* //############################ QA ########################################### */}
                            <TabPanel value="6">
                              {/* Fifth Tab Panel */}

                              {confirmQaDeleteAlert ? (
                                <SweetAlert
                                  title=""
                                  showCancel
                                  confirmButtonText="Delete"
                                  confirmBtnBsStyle="success"
                                  cancelBtnBsStyle="danger"
                                  onConfirm={() => {
                                    axios
                                      .delete(`${API_URL}taskqa/?task_id=${qaIdTobeDeleted.task_id}&taskqa_id=${qaIdTobeDeleted.taskqa_id}`, {
                                        headers: {
                                          "x-access-token": accessToken,
                                        },
                                      })
                                      .then((res) => {
                                        if (res.data.status === 200) {
                                          toastr.success("Task Qa deleted successfully");
                                          handleQaTableData(addProject);
                                          if (masterQaObject && masterQaObject.taskqa_id === qaIdTobeDeleted.taskqa_id) {
                                            formRef.current.reset();
                                            setQaIdTobeUpdated(null);
                                            setSelectedQaFlow(null);
                                            setSelectedQaStatus(null);
                                            setSelectedQaTask(null);
                                            setErrors({
                                              ...errors,
                                              taskError: "",
                                              flowError: "",
                                              statusError: "",
                                              imageError: "",
                                            });
                                            setMasterQaObject({
                                              taskqa_id: "",
                                              task_id: "",
                                              qa_status: "",
                                              qa_message: "",
                                              qa_attachments: [],
                                              qa_flow_id: "",
                                              qa_priority: null,
                                            });
                                          }
                                        } else {
                                          toastr.error(res.data.message, "Failed to delete Task Qa");
                                          return;
                                        }
                                      });
                                    setConfirmQaDeleteAlert(false);
                                  }}
                                  onCancel={() => setConfirmQaDeleteAlert(false)}>
                                  Are you sure you want to delete it?
                                </SweetAlert>
                              ) : null}

                              <Modal show={showQaPopup} size="lg" centered={true}>
                                <div className="modal-header">
                                  <h5 className="modal-title mt-0">Task QA Details</h5>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowQaPopup(false);
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"></button>
                                </div>
                                <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
                                  <div className="modal-body">
                                    <table id="product_available_day" className="table table-bordered dataTable">
                                      <tr>
                                        <td style={{ padding: "10px" }}>Date & Time</td>
                                        <td>: {viewQaDetails?.day}</td>
                                        <td>Added By</td>
                                        <td style={{ textAlign: "left" }}>: {viewQaDetails?.add}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "10px" }}>Task</td>
                                        <td>: {viewQaDetails?.task}</td>
                                        <td>Flow</td>
                                        <td style={{ textAlign: "left" }}>: {viewQaDetails?.flow}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: "184px", padding: "10px" }}>Priority</td>
                                        <td>: {viewQaDetails?.qa_priority}</td>
                                        <td> Status</td>
                                        <td style={{ textAlign: "left" }}>: {viewQaDetails?.status}</td>
                                      </tr>
                                      <tr hidden={!viewQaDetails?.edited}>
                                        <td style={{ padding: "10px" }}>Updated At</td>
                                        <td>: {viewQaDetails?.update}</td>
                                        <td>Updated By</td>
                                        <td style={{ textAlign: "left" }}>: {viewQaDetails?.edited}</td>
                                      </tr>

                                      <tr>
                                        <td style={{ padding: "10px" }}>Message</td>
                                        <td colSpan={3} style={{ textAlign: "left" }}>
                                          : {viewQaDetails?.qa_message}
                                        </td>
                                      </tr>

                                      <tr hidden={!viewQaDetails?.images}>
                                        <td style={{ padding: "10px" }}>Images</td>
                                        <td colSpan={3} style={{ textAlign: "left" }}>
                                          {viewQaDetails?.images}
                                          {/* <img
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    paddingLeft: "0px",
                                                    cursor: "pointer" 
                                                  }}
                                                  src={`${API_URL}uploads/qa/${viewQaDetails?.image}`}
                                                  onClick={()=> window.open(`${API_URL}uploads/qa/${viewQaDetails.image}`, "_blank")}
                                                ></img> */}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </AvForm>
                              </Modal>
                              <Row>
                                <Col xl="12" style={{ padding: "0px 0px 2px 0px" }}>
                                  <AvForm
                                    ref={formRef}
                                    className="needs-validation"
                                    onValidSubmit={(e, v) => {
                                      handleQaValidSubmit(e, v);
                                    }}>
                                    <Row>
                                      <Col md="3">
                                        <div className="mb-3">
                                          <Label>Task</Label>
                                          <Select
                                            name="task_id"
                                            value={selectedQaTask}
                                            onChange={(selected) => {
                                              handleSelectChange(selected, "task_id");
                                            }}
                                            size="small"
                                            options={qaTask}
                                            classNamePrefix="select2-selection"
                                          />
                                          <p className="text-danger" style={{ fontSize: "11px" }}>
                                            {selectedQaTask === null ? errors.taskError : ""}
                                          </p>
                                        </div>
                                      </Col>

                                      <Col md="3">
                                        <div className="mb-3">
                                          <Label>Flow</Label>
                                          <Select
                                            name="qa_flow_id"
                                            value={selectedQaFlow}
                                            onChange={(selected) => {
                                              handleSelectChange(selected, "qa_flow_id");
                                            }}
                                            size="small"
                                            options={qaFlow}
                                            classNamePrefix="select2-selection"
                                          />
                                          {/* <p
                                            className="text-danger"
                                            style={{ fontSize: "11px" }}
                                          >
                                            {selectedQaFlow === null? errors.flowError:""}
                                          </p> */}
                                        </div>
                                      </Col>
                                      <Col md="3">
                                        <div className="mb-3">
                                          <Label htmlFor="validationCustom03">Priority</Label>
                                          <AvField
                                            style={customFieldStyle}
                                            name="qa_priority"
                                            placeholder="Priority"
                                            type="number"
                                            errorMessage="Enter Priority"
                                            className="form-control"
                                            validate={{
                                              required: {
                                                value: true,
                                              },
                                            }}
                                            id="validationCustom03"
                                            value={masterQaObject?.qa_priority}
                                            onChange={handleQaInputChange}
                                          />
                                        </div>
                                      </Col>
                                      <Col md="3">
                                        <div className="mb-3">
                                          <Label>Status</Label>
                                          <Select
                                            name="qa_status"
                                            value={selectedQaStatus}
                                            onChange={(selected) => {
                                              handleSelectChange(selected, "qa_status");
                                            }}
                                            size="small"
                                            options={qaStatus}
                                            classNamePrefix="select2-selection"
                                          />
                                          <p className="text-danger" style={{ fontSize: "11px" }}>
                                            {selectedQaStatus === null ? errors.statusError : ""}
                                          </p>
                                        </div>
                                      </Col>

                                      <Col md="6">
                                        <div className="mb-3">
                                          <Label htmlFor="validationCustom03">Message</Label>
                                          <AvField
                                            name="qa_message"
                                            placeholder="Message"
                                            type="textarea"
                                            errorMessage="Enter Message"
                                            className="form-control"
                                            validate={{
                                              required: {
                                                value: true,
                                              },
                                            }}
                                            id="validationCustom03"
                                            value={masterQaObject?.qa_message}
                                            onChange={handleQaInputChange}
                                          />
                                        </div>
                                      </Col>
                                      <Col md="3">
                                        <div className="mb-3">
                                          <Label htmlFor="validationCustom03">Attachments</Label>
                                          <AvField
                                            name="qa_attachments"
                                            type="file"
                                            className="form-control"
                                            id="validationCustom03"
                                            onChange={uploadQaImages}
                                            rows="1"
                                          />
                                          {/* <p
                                                className="text-danger"
                                                style={{ fontSize: "11px" }}
                                              >
                                                {!masterQaObject.qa_attachments ? errors.imageError : ""}
                                              </p> */}
                                          <Row>
                                            {masterQaObject?.qa_attachments &&
                                              masterQaObject?.qa_attachments.map((images, index) => {
                                                return (
                                                  <>
                                                    <Col md="3" style={{ paddingTop: "13px" }}>
                                                      <div className="mb-3">
                                                        <img alt="" width="45" height="45" src={`${API_URL}uploads/qa/${images}`} />
                                                        <button type="button" className="upload_image_close" onClick={() => deleteQaImage(index)}></button>
                                                      </div>
                                                    </Col>
                                                  </>
                                                );
                                              })}
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md="3">
                                        <div
                                          // className="mb-2"
                                          style={{ paddingTop: "1.7rem" }}>
                                          {qaIdTobeUpdated ? (
                                            <Button
                                              color="primary"
                                              type="submit"
                                              style={{
                                                marginRight: "5%",
                                              }}
                                              disabled={addingUser ? true : false}>
                                              {addingUser ? "Updating" : "Update"}
                                            </Button>
                                          ) : (
                                            <Button
                                              color="primary"
                                              type="submit"
                                              style={{
                                                marginRight: "5%",
                                              }}
                                              disabled={addingUser ? true : false}>
                                              {addingUser ? "Adding" : "Submit"}
                                            </Button>
                                          )}
                                          <Button color="danger" type="reset" onClick={() => qaReset()}>
                                            {"  "}
                                            Reset
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row></Row>
                                  </AvForm>
                                  <div className="mt-3">
                                    <MDBDataTable
                                      id="qaDataTableID"
                                      responsive
                                      bordered
                                      data={qaData}
                                      searching={true}
                                      info={true}
                                      disableRetreatAfterSorting={true}
                                      entries={20}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPanel>
                            {/* //############################ QA ########################################### */}
                            <TabPanel value="7">{/* Sixth Tab Panel */}</TabPanel>
                            <TabPanel value="8">{/* Seventh Tab Panel */}</TabPanel>
                            <TabPanel value="9" style={{ padding: "unset" }}>
                              <Row style={{ paddingTop: "14px" }}>
                                {/* <h4>Personal Details</h4> */}
                                <Col md="12">
                                  <div className="mb-3">
                                    {/* <h5></h5> */}
                                    <table id="product_available_day" className="table table-bordered dataTable">
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Name
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.name}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Short Name
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.short_name}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Domain
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.domain}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Status
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.client_current_status}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Type
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.client_type}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Email
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.email}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Mobile
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.mobile}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Landline
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.landline}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          City
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.city}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Address
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.address}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          PIN
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.pin}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Category
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.Categ}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Date
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.DataTime}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Country
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.country}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          State
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.state}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Added By
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.staff}</td>
                                        <td
                                          style={{
                                            padding: "10px",
                                            fontWeight: "500",
                                          }}>
                                          Updated By
                                        </td>
                                        <td style={{ textAlign: "left" }}>: {ClientDetails?.staff1}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </Col>
                              </Row>
                              {/* Project Grid Start */}
                              <CardBody
                                style={{
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  padding: "14px 0px 0px 0px",
                                }}>
                                <Row>
                                  {tableData?.map((item) => (
                                    <div className="col-sm-6 col-xl-4">
                                      <div
                                        className="text-center card"
                                        style={{
                                          borderRadius: "5px",
                                          padding: "0px 0px 2px 0px",
                                        }}>
                                        <div
                                          className="card-body"
                                          style={{
                                            padding: "10px 11px 2px",
                                          }}>
                                          <div className="float-end dropdown">
                                            <a aria-haspopup="true" className="text-body font-size-16 dropdown-toggle" aria-expanded="false">
                                              {/* <i className="uil uil-ellipsis-h"></i> */}
                                            </a>
                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu-end dropdown-menu">
                                              <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                                                Edit
                                              </a>
                                              <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                                                Action
                                              </a>
                                              <a href="/" tabindex="0" role="menuitem" className="dropdown-item">
                                                Remove
                                              </a>
                                            </div>
                                          </div>

                                          <div className="clearfix"></div>

                                          <h5 className="font-size-16 mb-1">
                                            <a className="text-dark">{item.project_name}</a>
                                          </h5>
                                          <Row
                                            style={{
                                              padding: "10px 0px",
                                            }}>
                                            <Col
                                              xl={9}
                                              style={{
                                                textAlign: "left",
                                              }}>
                                              <a target="_blank" href={item.domain}>
                                                {item.domain}
                                              </a>
                                            </Col>
                                            <Col
                                              xl={3}
                                              style={{
                                                textAlign: "right",
                                              }}>
                                              {item.hours ? item.hours : 0} Hrs
                                            </Col>
                                          </Row>
                                          <Row
                                            style={{
                                              padding: "10px 0px",
                                            }}>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "left",
                                              }}>
                                              {item.unique_id}
                                            </Col>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "right",
                                              }}>
                                              {item.Type}
                                            </Col>
                                          </Row>
                                          <Row
                                            style={{
                                              padding: "10px 0px",
                                            }}>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "left",
                                              }}>
                                              Kickoff : {item.kick_off}
                                            </Col>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "right",
                                              }}>
                                              End : {item.end}
                                            </Col>
                                          </Row>
                                          <Row
                                            style={{
                                              padding: "10px 0px",
                                            }}>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "left",
                                              }}>
                                              <a
                                                type="button"
                                                style={{
                                                  textAlign: "left",
                                                  borderColor: "#fff",
                                                }}
                                                href={"/project/" + item.unique_id}

                                                // onClick={() => {
                                                //   history.push(
                                                //     "/add_Project/" +
                                                //       item.unique_id
                                                //   );
                                                // }}
                                              >
                                                View Details
                                              </a>
                                            </Col>
                                            <Col
                                              xl={6}
                                              style={{
                                                textAlign: "right",
                                              }}>
                                              {item.ProjectStatus}
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </Row>
                              </CardBody>
                            </TabPanel>
                            {/* ############################# Support ticket ######################## */}
                            <TabPanel value="10" style={{ padding: "14px 0px 0px 0px" }}>
                              <Row>
                                <Col className="col-12">
                                  {/* <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody> */}

                                  <MDBDataTable
                                    id="SupportTicketTableID"
                                    responsive
                                    bordered
                                    data={support_data}
                                    searching={true}
                                    info={true}
                                    disableRetreatAfterSorting={true}
                                    entries={20}
                                  />
                                  {/* </CardBody>
                  </Card> */}
                                </Col>
                              </Row>
                              <Modal show={showPopup} size="xl" centered={true} className="modal-fullscreen">
                                <div className="modal-header">
                                  <div>
                                    Support Ticket <b>#{supportDetails?.unique_id}</b>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowPopup(false);
                                      handlesupportTicketTableData();
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"></button>
                                </div>

                                <div className="modal-body">
                                  <Row>
                                    <Col md="3">
                                      <Card>
                                        <CardBody style={{ padding: "0px" }}>
                                          <table id="support_view" className="table">
                                            <tr>
                                              <td>
                                                <b>TAT</b>
                                              </td>
                                              <td>{supportDetails?.tat}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Date</b>
                                              </td>
                                              <td>{supportDetails?.day}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Time</b>
                                              </td>
                                              <td>{supportDetails?.time}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Client</b>
                                              </td>
                                              <td>{supportDetails?.client_name} </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Contact Person</b>
                                              </td>
                                              <td>{supportDetails?.support_contact_person} </td>
                                            </tr>
                                            <tr>
                                              {" "}
                                              <td>
                                                <b>Project</b>
                                              </td>
                                              <td>{supportDetails?.project_name}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Mode</b>
                                              </td>
                                              <td>{supportDetails?.mode_name}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Team</b>
                                              </td>
                                              <td>{supportDetails?.team_name} </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Bill Status</b>
                                              </td>
                                              <td>{supportDetails?.bill_status1}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Status</b>
                                              </td>
                                              <td>{supportDetails?.support_status}</td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <b>Task Status</b>
                                              </td>
                                              <td>{supportDetails?.task_status}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Created At</b>
                                              </td>
                                              <td>{supportDetails?.create}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>CreatedBy</b>
                                              </td>
                                              <td>{supportDetails?.add} </td>
                                            </tr>
                                            <tr>
                                              {" "}
                                              <td>
                                                <b>UpdatedBy</b>
                                              </td>
                                              <td>{supportDetails?.edited}</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <b>Comment</b>
                                              </td>
                                              <td>
                                                <div style={{ width: "132px", padding: "0px" }}>{supportDetails?.support_issue}</div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={2}>
                                                <b>Bill Status</b>
                                                <Select
                                                  name="bill_status"
                                                  value={selectedBill}
                                                  options={[
                                                    { label: "Unpaid ", value: 0 },
                                                    { label: "Paid", value: 1 },
                                                  ]}
                                                  classNamePrefix="select2-selection"
                                                  onChange={(selected) => {
                                                    handleSelectBill(selected);
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </table>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md="9">
                                      <Card>
                                        <CardBody>
                                          <AvForm
                                            ref={formRef1}
                                            className="needs-validation"
                                            onValidSubmit={(e, v) => {
                                              handleFollowupSubmit(e, v);
                                            }}>
                                            <Row>
                                              <Col md="2">
                                                <Label>Status</Label>
                                                <Select
                                                  id="followupstatus"
                                                  name="followupstatus"
                                                  value={followupObject?.status}
                                                  onChange={(selected) => {
                                                    handleFollowupData(selected, "followup_status");
                                                  }}
                                                  options={[
                                                    { label: "New", value: 0 },
                                                    { label: "Pending", value: 2 },
                                                    { label: "Completed", value: 3 },
                                                    { label: "Hold", value: 4 },
                                                    { label: "Solved", value: 5 },
                                                    { label: "Informed", value: 8 },
                                                    { label: "Closed", value: 6 },
                                                    { label: "No Issue", value: 7 },
                                                  ]}
                                                  classNamePrefix="select2-selection"
                                                  menuPosition="fixed"
                                                />
                                                <div className="text-danger" style={{ fontSize: "11px" }}>
                                                  {followupstatusError != "" ? followupstatusError : ""}
                                                </div>
                                              </Col>
                                              <Col md="6">
                                                <Label>Comments</Label>
                                                <AvField
                                                  name="follow_comment"
                                                  placeholder="Comments"
                                                  type="textarea"
                                                  className="form-control"
                                                  value={followupObject?.comment}
                                                  onChange={(selected) => {
                                                    handleFollowupData(selected, "comment");
                                                  }}
                                                  validate={{ required: { value: true } }}
                                                  errorMessage="Enter your comments"
                                                  rows="1"
                                                />
                                              </Col>
                                              <Col md="3" style={{ marginTop: "26px" }}>
                                                <Button color="primary" type="submit" style={{ marginRight: "10px" }}>
                                                  Submit
                                                </Button>
                                                <Button
                                                  color="danger"
                                                  type="reset"
                                                  onClick={() => {
                                                    followreset();
                                                  }}>
                                                  Reset
                                                </Button>
                                              </Col>
                                            </Row>
                                          </AvForm>
                                          <br></br>
                                          <MDBDataTable
                                            id="supportfollowupModalTable"
                                            responsive
                                            bordered
                                            data={followup_data}
                                            searching={true}
                                            info={false}
                                            disableRetreatAfterSorting={true}
                                            entries={20}
                                          />
                                        </CardBody>
                                      </Card>
                                      <Card>
                                        <CardBody>
                                          <table class="table table-bordered" id="taskListTable">
                                            <thead>
                                              <tr>
                                                {/* <th>#</th> */}
                                                <th width="250px" style={{ textAlign: "center" }}>
                                                  TaskName
                                                </th>
                                                <th width="100px" style={{ textAlign: "center" }}>
                                                  Flow
                                                </th>
                                                <th width={"100px"} style={{ textAlign: "center" }}>
                                                  Assignee
                                                </th>
                                                <th width={"50px"} style={{ textAlign: "center" }}>
                                                  <i className="fa fa-clock" title="Plan" style={{ fontSize: "10px" }}></i> P
                                                </th>
                                                <th width={"50px"} style={{ textAlign: "center" }}>
                                                  <i className="fa fa-clock" title="Effort" style={{ fontSize: "10px" }}></i> E
                                                </th>
                                                {/* <th><i className='fas fa-user-cog' title="Efficiency" style={{ fontSize:"13px"}}></i></th> */}
                                                <th width={"100px"} style={{ textAlign: "center" }}>
                                                  Start
                                                </th>
                                                <th width={"100px"} style={{ textAlign: "center" }}>
                                                  End
                                                </th>
                                                <th width={"100px"} style={{ textAlign: "center" }}>
                                                  %
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {supportDetails.all_tasks?.map((item, index) => {
                                                var i = 0;
                                                return (
                                                  <>
                                                    {item.assigns.map((subItem, index1) => {
                                                      if (subItem.staff_name?.length > 0)
                                                        subItem.firstName1 = (
                                                          <a target={"_blank"} href={"/add_staff/" + subItem.staff_id}>
                                                            {subItem.staff_name[0]}
                                                          </a>
                                                        );
                                                      if (subItem.assign_startdate)
                                                        subItem.assign_startdate1 = moment(subItem.assign_startdate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                                      if (subItem.assign_enddate)
                                                        subItem.assign_enddate1 = moment(subItem.assign_enddate, "YYYY-MM-DD").format("DD-MM-YYYY");
                                                      if (subItem.assign_time) subItem.assign_time1 = timeFromNumber(subItem.assign_time);
                                                      else subItem.assign_time1 = "00:00";
                                                      if (typeof subItem.assign_effort_hr !== "undefined") {
                                                        subItem.assign_effort_hr1 = subItem.assign_effort_hr.substring(
                                                          0,
                                                          subItem.assign_effort_hr.lastIndexOf(":")
                                                        );
                                                      } else {
                                                        subItem.assign_effort_hr1 = "00:00";
                                                      }
                                                      if (subItem.assign_effort_hr1 == "") subItem.assign_effort_hr1 = "00:00";
                                                      if (index1 == 0) {
                                                        return (
                                                          <tr>
                                                            <td rowSpan={item.assigns?.length}>{item.task_name}</td>
                                                            <td>{subItem.flow_name?.length > 0 ? subItem.flow_name[0] : ""}</td>
                                                            <td>{subItem.firstName1}</td>
                                                            <td>{subItem.assign_time1}</td>
                                                            <td>{subItem.assign_effort_hr1}</td>
                                                            <td>{subItem.assign_startdate1}</td>
                                                            <td>{subItem.assign_enddate1}</td>
                                                            <td>
                                                              <Progress style={{ height: "14px" }} color="primary" value={subItem.assign_complete_percentage}>
                                                                {subItem.assign_complete_percentage}%
                                                              </Progress>
                                                            </td>
                                                          </tr>
                                                        );
                                                      } else {
                                                        return (
                                                          <tr>
                                                            <td>{subItem.flow_name?.length > 0 ? subItem.flow_name[0] : ""}</td>
                                                            <td>{subItem.firstName1}</td>
                                                            <td>{subItem.assign_time1}</td>
                                                            <td>{subItem.assign_effort_hr1}</td>
                                                            <td>{subItem.assign_startdate1}</td>
                                                            <td>{subItem.assign_enddate1}</td>
                                                            <td>
                                                              <Progress style={{ height: "14px" }} color="primary" value={subItem.assign_complete_percentage}>
                                                                {subItem.assign_complete_percentage}%
                                                              </Progress>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    })}
                                                  </>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              </Modal>
                            </TabPanel>
                            {/* ############################# Support ticket assign######################## */}
                            <TabPanel value="11" style={{ padding: "14px 0px 0px 0px" }}>
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom01">Support Ticket</Label>
                                    <Select
                                      name="support_id"
                                      // value={selectedTeam}
                                      // options={team}
                                      classNamePrefix="select2-selection"
                                      // onChange={(selected) => {
                                      //   handleSelectChange(selected, "team_id");
                                      // }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* {(isExpand==null || isExpand==false)?(
                                <>
                                 <button type="button"
                              className="btn btn-primary btn-sm"
                              style={{ marginBottom:"8px"}}
                              onClick={(e) => handleexpandAll(true) }
                              >Expand</button>
                              </>
                              ):(
                                <>
                                 <button type="button"
                              className="btn btn-primary btn-sm"
                              style={{ marginBottom:"8px"}}
                              onClick={(e) => handleexpandAll(false) }
                              >Shrink</button>
                                </>
                              )} */}

                              {/* <BootstrapTreeTable columns={treeColumns} tableData={[...taskRows]} control={treeControls}/>  */}
                              {/* <div className="table-responsive">
                            <table class="table table-bordered" id="taskListTable">
                                <thead>
                                <tr>
                                {/* <th>#</th> */}
                              {/* <th width="200px">Category</th>
                                <th width="250px">TaskName</th>
                                <th>Assignee</th>
                                <th width="60px">Time</th>
                                <th width="95px">Start</th>
                                <th width="95px">End</th>
                                <th>Status</th>
                                <th width="55px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  taskRows.map((item,index)=>(
                                    <>
                                  <tr class="shown">
                                <td class="">
                                  <Row>
                                    <Col md="1" style={{ cursor:"pointer", paddingTop:"5px", paddingLeft:"8px", minWidth:"12px"}}>
                                    <span >
                                  {
                                    (item.flows.length>0)?(
                                      <>
                                      {((isExpand==null && item.expand==="hidden")||(isExpand==false))?(
                                        <>
                                          <svg onClick={()=>showTask(index)} aria-hidden="true" style={{ height:"15px", width:"15px",}} focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" id="expandPoint">
                                            <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                          </svg>
                                        </>
                                      ):(
                                        <>
                                          <svg onClick={()=>hideTask(index)} aria-hidden="true" style={{ height:"15px", width:"15px",}} focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="collapsePoint">
                                            <path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                          </svg>
                                        </>
                                      )}
                                      </>
                                    ):""
                                  }
                                </span>
                                    </Col>
                                    <Col md="11" style={{ minWidth:"150px"}}>
                                    <Select
                                    menuPosition="fixed"
                                  name="subcategory"
                                  value={item.subcategory}
                                  onChange={(selected) => {
                                    handleAddTask(selected,index,item,'category');
                                  }}
                                  options={taskCategoryOptions}
                                  classNamePrefix="select2-selection"
                                  disabled={taskPermission}
                                />
                                    </Col>
                                  </Row>
                                
                               
                                </td>
                                <td class="" ><input disabled={taskPermission} type="text" name="task_name" class="form-control" value={item.task_name} onChange={(e)=>handleAddTask(e,index,item,'task_name')}></input></td>
                                <td class=""></td>
                                <td class=""><input  type="text" name="task_tot_time" class="form-control" value={item?.task_tot_time} readOnly></input></td>
                                <td class=""><input  type='date' name="task_start_date" class="form-control" value={item?.task_start_date} style={{ width:'115px'}} readOnly></input></td>
                                <td class=""><input  type='date' name="task_end_date" class="form-control" value={item?.task_end_date} style={{ width:'115px'}} readOnly></input></td>
                                <td class="">
                                  {/* {item.task_status_label} */}
                              {/*  </td>
                                <td style={{ textAlign:"center"}}>
                                  {item._id?(
                                    <Row>
                                      <Col>
                                      {
                                                        (item?.unread_comments>0)?(
                                                        <div class="chat-icon">
                                                        <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                        <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                        </div> 
                                                        ):((item?.total_comments>0)?(
                                                            <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                        ):(
                                                            <i className="uil-comment-alt" onClick={()=>handleShow(item,index)}></i>
                                                        ))
                                                    }
                                      </Col>
                                      <Col>
                                      <i className="fas fa-trash"                                    
                                    onClick={() => {
                                      setTaskIdToBeDeleted({id:item._id,index:index});
                                      setConfirmDeleteTaskAlert(true);
                                    }}></i>
                                      </Col>
                                    </Row>
                                    
                                    
                                     
                                  ):""}
                                  
                                </td>
                                </tr>
                                      {
                                         item.flows&&item.flows.map((subItem,index1)=>(
                                          <>
                                            <tr class={((isExpand==null && item.expand==="hidden")||(isExpand==false))?"hidden":"show"}>
                                            <td class=""></td>
                                              <td class="" style={{ paddingLeft:"16px"}}>{subItem?.flow_name}</td>
                                              <td class="">
                                              <Select
                                                menuPosition="fixed"
                                                name="staff_id"
                                                placeholder=""
                                                value={subItem?.assignee}
                                                onChange={(selected) => {
                          
                                                  handleAssignTask(selected,index,index1,item,subItem,"staff");
                                                }}
                                                options={taskAssigneeOptions}
                                                classNamePrefix="select2-selection"
                                              />
                                              </td>
                                              <td class=""><input disabled={taskPermission} name="assign_time" class="form-control" value={subItem?.assign_time} onChange={(e)=>handleAssignTask(e,index,index1,item,subItem,"time")}></input></td>
                                              <td class=""><input disabled={taskPermission} type='date' name="assign_startdate" class="form-control" value={subItem?.assign_startdate} onChange={(e)=>handleAssignTask(e,index,index1,item,subItem,"start")} style={{ width:'115px'}}></input></td>
                                              <td class=""><input disabled={taskPermission} type='date' name="assign_enddate" class="form-control" value={subItem?.assign_enddate} onChange={(e)=>handleAssignTask(e,index,index1,item,subItem,"end")} style={{ width:'115px'}}></input></td>
                                              <td class="">
                                              <Select
                                                menuPosition="fixed"
                                                name="assign_current_status"
                                                placeholder=""
                                                value={subItem?.currentstatus}
                                                onChange={(selected) => {
                                                  handleAssignTask(selected,index,index1,item,subItem,"status");
                                                }}
                                                options={subItem.statusOption}
                                                classNamePrefix="select2-selection"
                                                disabled
                                              />
                                              </td>
                                              <td></td>
                                            </tr>
                                          </>
                                        ))
                                      }

                                </>
                                  )
                                  )
                                }
                                </tbody>
                                </table>
                                </div> */}
                              {/* {
                                  taskPermission===false?(
                                    <>
                                    <button type="button"
                              className="btn btn-primary btn-sm"
                              style={{ marginBottom:"8px"}}
                              onClick={(e) => addNewTaskRow() }
                              >Add Task</button>
                                    </>
                                  ):(
                                    <></>
                                  )
                                } */}
                              <div className="table-responsive">
                                <table class="table table-bordered" id="taskListTable">
                                  <thead>
                                    <tr>
                                      <th width="200px">Category</th>
                                      <th width="250px">TaskName</th>
                                      <th>Assignee</th>
                                      <th width="60px">Time</th>
                                      <th width="95px">Start</th>
                                      <th width="95px">End</th>
                                      <th>Status</th>
                                      <th width="55px"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="shown">
                                      <td class="">
                                        <Row>
                                          <Col md="1" style={{ cursor: "pointer", paddingTop: "5px", paddingLeft: "8px", minWidth: "12px" }}>
                                            <span>
                                              <>
                                                <svg
                                                  aria-hidden="true"
                                                  style={{ height: "15px", width: "15px" }}
                                                  focusable="false"
                                                  data-prefix="fas"
                                                  data-icon="angle-right"
                                                  class="svg-inline--fa fa-angle-right fa-w-8 fa-fw "
                                                  role="img"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 256 512"
                                                  id="expandPoint">
                                                  <path
                                                    fill="currentColor"
                                                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                </svg>
                                              </>
                                            </span>
                                          </Col>
                                          <Col md="11" style={{ minWidth: "150px" }}>
                                            <Select
                                              menuPosition="fixed"
                                              name="subcategory"
                                              //   value={item.subcategory}
                                              //   onChange={(selected) => {
                                              //     handleAddTask(selected,index,item,'category');
                                              //   }}
                                              options={taskCategoryOptions}
                                              classNamePrefix="select2-selection"
                                              //   disabled={taskPermission}
                                            />
                                          </Col>
                                        </Row>
                                      </td>

                                      <td class="">
                                        <input type="text" name="task_name" class="form-control"></input>
                                      </td>
                                      <td class=""></td>
                                      <td class="">
                                        <input type="text" name="task_tot_time" class="form-control" readOnly></input>
                                      </td>
                                      <td class="">
                                        <input type="date" name="task_start_date" class="form-control" style={{ width: "115px" }} readOnly></input>
                                      </td>
                                      <td class="">
                                        <input type="date" name="task_end_date" class="form-control" style={{ width: "115px" }} readOnly></input>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{ marginBottom: "8px", marginLeft: "10px", width: "auto" }}
                                //   onClick={(e) => addNewTaskRow() }
                              >
                                Add Task
                              </button>
                            </TabPanel>

                            {/* ############################# View Support ticket list ######################## */}
                            <TabPanel value="12" style={{ padding: "14px 0px 0px 0px" }}>
                              <Row>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <Select
                                      placeholder="Support Ticket"
                                      // value={selectedTeam}
                                      // onChange={(e) => {
                                      //   handleSelectTeam(e);
                                      // }}
                                      // options={optionTeam}
                                      classNamePrefix="select2-selection"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <input
                                      type="text"
                                      style={customFieldStyle}
                                      value={taskName}
                                      name="taskname"
                                      placeholder="Task name"
                                      className="form-control"
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <input
                                      type="date"
                                      style={customFieldStyle}
                                      value={from_date}
                                      name="from_date"
                                      className="form-control"
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <input
                                      type="date"
                                      style={customFieldStyle}
                                      name="to_date"
                                      className="form-control"
                                      value={to_date}
                                      onChange={(e) => handleInputChange(e)}
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <Select
                                      placeholder="Team"
                                      value={selectedTeam}
                                      onChange={(e) => {
                                        handleSelectTeam(e);
                                      }}
                                      options={optionTeam}
                                      classNamePrefix="select2-selection"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <Select
                                      placeholder="Staff"
                                      value={selectedStaff}
                                      onChange={(e) => {
                                        handleSelectStaff(e);
                                      }}
                                      options={optionStaff}
                                      classNamePrefix="select2-selection"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </Col>
                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <Select
                                      placeholder="Status"
                                      value={selectedFilterStatus}
                                      onChange={(e) => {
                                        handleSelectFilterStatus(e);
                                      }}
                                      options={[
                                        { label: "Completed", value: 1 },
                                        { label: "In completed", value: 2 },
                                        { label: "Unassigned", value: 3 },
                                        { label: "Over Due", value: 4 },
                                        { label: "Over Effort", value: 5 },
                                        { label: "Running", value: 6 },
                                        { label: "Not Running", value: 7 },
                                      ]}
                                      classNamePrefix="select2-selection"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </Col>

                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    <Select
                                      placeholder="Flows"
                                      value={selectedFlow}
                                      onChange={(e) => {
                                        handleSelectFlow(e);
                                      }}
                                      options={flowOptions}
                                      isMulti
                                      classNamePrefix="select2-selection"
                                      menuPosition="fixed"
                                    />
                                  </div>
                                </Col>
                                {/* <Col md="2">
                              <Select
                              placeholder="Project"
                              value={selectedProject}
                              onChange={(e) => {
                                handleSelectProject(e);
                              }}
                              options={optionProject}
                              classNamePrefix="select2-selection"
                            />
                              </Col> */}

                                <Col md="2" style={{ marginBottom: "1rem" }}>
                                  <div className="mb-2">
                                    {/* <Button
                                  color="primary"
                                  type="submit"
                                  style={{ marginRight: "5%" }}
                                  onClick={handleSearch}
                                >
                            
                                  {"  "}
                                  Filter
                                </Button> */}
                                    <Button color="danger" type="submit" onClick={reset}>
                                      {"  "}
                                      Reset
                                    </Button>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <table class="table table-bordered" id="allTaskListTables">
                                    <thead>
                                      <tr>
                                        {/* <th>#</th> */}
                                        <th>Support Ticket</th>
                                        <th>TaskName</th>
                                        <th>Flow</th>
                                        {/* <th>Project</th> */}
                                        <th style={{ width: "100px" }}>Assignee</th>
                                        <th style={{ width: "50px" }}>
                                          <i className="fa fa-clock" title="Plan" style={{ fontSize: "10px" }}></i> P
                                        </th>
                                        <th style={{ width: "50px" }}>
                                          <i className="fa fa-clock" title="Effort" style={{ fontSize: "10px" }}></i> E
                                        </th>
                                        {/* <th><i className='fas fa-user-cog' title="Efficiency" style={{ fontSize:"13px"}}></i></th> */}
                                        <th style={{ width: "100px" }}>Start</th>
                                        <th style={{ width: "100px" }}>End</th>
                                        <th>%</th>
                                        <th style={{ width: "46px" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {
                                 alltasktableData.map((project,k)=>{
                                  var i=0;
                                  return (
                                    <>
                                   
                                    {
                                     
                                      project.tasks.map((item,index)=>{
                                       var j=0;
                                          return (
                                            <>
                                            {
                                              item.flows_all.map((subItem,index1)=>
                                              {
                                                i++;
                                                j++;
                                                if(subItem.firstName=="0")
                                                subItem.firstName1="";
                                                else
                                                subItem.firstName1=<a target={"_blank"} href={"/add_staff/"+subItem.staff_id}>{subItem?.firstName}</a>
                                                if(subItem.assign_startdate)
                                                subItem.assign_startdate1 = moment(subItem.assign_startdate,"YYYY-MM-DD").format("DD-MM-YYYY")
                                                if(subItem.assign_enddate)
                                                subItem.assign_enddate1 = moment(subItem.assign_enddate,"YYYY-MM-DD").format("DD-MM-YYYY")
                                                if(subItem.assign_time)
                                                subItem.assign_time1 = timeFromNumber(subItem.assign_time);
                                                else
                                                subItem.assign_time1 = "00:00";
                                                if(typeof subItem.assign_effort_hr!=="undefined")
                                                {
                                                    subItem.assign_effort_hr1 = subItem.assign_effort_hr.substring(0,subItem.assign_effort_hr.lastIndexOf(":"));
                                                }
                                                else
                                                {
                                                    subItem.assign_effort_hr1="00:00";
                                                }
                                                if(subItem.assign_effort_h1r=="")
                                                subItem.assign_effort_hr1="00:00";

                                                if(i==1 && index==0)
                                                {
                                                  return (
                                                  <>
                                                  <tr>
                                                  <td ></td>
                                                  {/* <td rowSpan={project.count}>{project.project_name}</td> */}
                                      {/* <td rowSpan={item.flows_all.length}>{item.task_name}</td>
                                                  <td>{subItem.flow_name}</td>
                                                  <td>{subItem.firstName1}</td>
                                                  <td>{subItem.assign_time1}</td>
                                                  <td>{subItem.assign_effort_hr1}</td>
                                                  <td>{subItem.assign_startdate1}</td>
                                                  <td>{subItem.assign_enddate1}</td>
                                                  <td><Progress color="primary" style={{ height:"14px",border:subItem.due_status}} value={subItem.assign_complete_percentage}>{subItem.assign_complete_percentage}%</Progress></td>
                                                  <td rowSpan={item.flows_all.length}>
                                                  <div class="chat-icon">
                                                    {
                                                                              (item?.unread_comments>0)?(
                                                                              <>
                                                                              <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                                              <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                                              </>
                                                                              ):((item?.total_comments>0)?(
                                                                                
                                                                                  <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                                              ):(
                                                                                  <i className="uil-comment-alt" onClick={()=>handleShow(item,index)}></i>
                                                                              ))
                                                    }
                                                    </div>
                                                  </td>
                                                  </tr>
                                                  </>
                                                )
                                                }
                                                else if(j==1)
                                                {
                                                  return (
                                                  <>
                                                  <tr>
                                                  <td></td>
                                                  <td rowSpan={item.flows_all.length}>{item.task_name}</td>
                                                  <td>{subItem.flow_name}</td>
                                                  <td>{subItem.firstName1}</td>
                                                  <td>{subItem.assign_time1}</td>
                                                  <td>{subItem.assign_effort_hr1}</td>
                                                  <td>{subItem.assign_startdate1}</td>
                                                  <td>{subItem.assign_enddate1}</td>
                                                  <td><Progress color="primary" style={{ height:"14px",border:subItem.due_status}} value={subItem.assign_complete_percentage}>{subItem.assign_complete_percentage}%</Progress></td>
                                                  <td rowSpan={item.flows_all.length}>
                                                  <div class="chat-icon">
                                                    {
                                                                              (item?.unread_comments>0)?(
                                                                              <>
                                                                              <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                                              <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                                                              </>
                                                                              ):((item?.total_comments>0)?(
                                                                                
                                                                                  <i className="uil-comment-alt-lines" onClick={()=>handleShow(item,index)}></i>
                                                                              ):(
                                                                                  <i className="uil-comment-alt" onClick={()=>handleShow(item,index)}></i>
                                                                              ))
                                                    }
                                                    </div>
                                                  </td>
                                                  </tr>
                                                  </>
                                                )
                                                }
                                                else
                                                {
                                                  return (
                                                    <>
                                                    <tr>
                                                    <td></td>
                                                    <td>{subItem.flow_name}</td>
                                                    <td>{subItem.firstName1}</td>
                                                    <td>{subItem.assign_time1}</td>
                                                    <td>{subItem.assign_effort_hr1}</td>
                                                    <td>{subItem.assign_startdate1}</td>
                                                  <td>{subItem.assign_enddate1}</td>
                                                  <td><Progress color="primary" style={{ height:"14px",border:subItem.due_status}} value={subItem.assign_complete_percentage}>{subItem.assign_complete_percentage}%</Progress></td>
                                                    </tr>
                                                    </>
                                                  )
                                                }
                                               
                                                
                                              })
                                            }                                         
                                            </>
                                          )
                                        
                                       
                                        
                                      })
                                      
                                    }
                                    </>
                                    
                                  )
                                  
                                 
                                 })
                                  
                                } */}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {/* End */}
              </Col>
            </Row>
          </div>
        </div>
        <Offcanvas show={canvasshow} onHide={handleClose} placement={"end"} style={{ width: "1200px" }}>
          <Row>
            <Col md="7">
              {/* <Offcanvas.Header closeButton style={{ padding:"5px"}}>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header> */}
              <Card style={{ height: "100%" }}>
                <CardBody>
                  <Offcanvas.Body>
                    <table id="projectInfoTable" width={"100%"}>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Project Name</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.project_name}</td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Shortcode</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.shortcode}</td>
                      </tr>
                      {/* <tr>
                                        <td style={{ padding: "10px", fontWeight: "500", }}>
                                          Client
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          : {chatboxData?.clients}
                                        </td>
                                        <td style={{ padding: "10px", fontWeight: "500", }}>
                                        Type
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                        : {chatboxData?.types}
                                        </td>
                                      </tr> */}

                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Technologies</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.TechForTbl}</td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Domain</td>
                        <td style={{ textAlign: "left" }}>
                          <a target="_blank" href={chatboxData?.domain} style={{ paddingLeft: "0%" }}>
                            : {chatboxData?.domain}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Quotation No</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.quotation_no}</td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Invoice No</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.invoice_no}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Kick Off</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.kickoff}</td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>End</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.End}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Project Status</td>
                        <td style={{ textAlign: "left" }}>
                          :
                          <p
                            className="badge rounded-pill "
                            style={{
                              fontSize: "initial",
                              marginBottom: "auto",
                            }}>
                            {chatboxData?.ProjectStatus}
                          </p>
                        </td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Billing Status</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.bstatus}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Manager</td>
                        <td style={{ textAlign: "left" }}>
                          : {chatboxData.project_manager?.firstName} {chatboxData.project_manager?.lastName}
                        </td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Team Leader</td>
                        <td style={{ textAlign: "left" }}>
                          : {chatboxData.project_leader?.firstName} {chatboxData.project_leader?.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Hours</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.hours}</td>
                        <td style={{ padding: "10px", fontWeight: "500" }}>Remark</td>
                        <td style={{ textAlign: "left" }}>: {chatboxData?.remarks}</td>
                      </tr>
                    </table>
                    <MDBDataTable
                      id="flowCountTableIds"
                      responsive
                      bordered
                      data={chat_flow_count_data}
                      searching={false}
                      disableRetreatAfterSorting={true}
                      paging={true}
                      info={false}
                      entriesOptions={[5, 10]}
                    />
                  </Offcanvas.Body>
                </CardBody>
              </Card>
            </Col>
            <Col md="5">
              <Card style={{ height: "100%" }}>
                <CardBody>
                  <Offcanvas.Body>
                    <div className="chat-input-section">
                      <Row>
                        <div className="px-lg-2">
                          <div className="chat-conversation pt-2">
                            <ScrollBar style={{ height: "455px" }} className="list-unstyled mb-0" containerRef={(el) => (bottomRef.current = el)}>
                              {messages.map((message, index) => {
                                if (message.comment) {
                                  return (
                                    <>
                                      <li key={"test_k" + index} className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                        <div className="conversation-list">
                                          <div className="ctext-wrap">
                                            <div className="ctext-wrap-content">
                                              <h5 className="font-size-14 conversation-name">
                                                <Link to="#" className="text-dark">
                                                  {message.createdBy.firstName}
                                                </Link>
                                                <span className="d-inline-block font-size-12 text-muted ms-2">
                                                  {moment(message.createdAt).format("DD-MM-YYYY hh:mm a")} -{" "}
                                                  {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                </span>
                                              </h5>
                                              <p className="mb-0 remarks-comment">{message.comment}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      {message.images.map((attach, index1) => {
                                        var attchment = "";
                                        var extension = attach.split(".").pop();
                                        if (extension.toLowerCase() == "txt") {
                                          attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                        } else if (
                                          extension.toLowerCase() == "png" ||
                                          extension.toLowerCase() == "jpg" ||
                                          extension.toLowerCase() == "jpeg" ||
                                          extension.toLowerCase() == "svg"
                                        ) {
                                          attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                        } else if (extension.toLowerCase() == "pdf") {
                                          attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                        } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                          attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                        } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                          attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                        }
                                        return (
                                          <>
                                            <li
                                              key={"test_k" + index + "" + index1}
                                              className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content">
                                                    <h5 className="font-size-14 conversation-name">
                                                      <Link to="#" className="text-dark">
                                                        {message.createdBy.firstName}
                                                      </Link>
                                                      <span className="d-inline-block font-size-12 text-muted ms-2">
                                                        {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                      </span>
                                                    </h5>
                                                    <p className="mb-0">
                                                      <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                        {attchment}
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      {message.images.map((attach, index1) => {
                                        var attchment = "";
                                        var extension = attach.split(".").pop();
                                        if (extension.toLowerCase() == "txt") {
                                          attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>;
                                        } else if (
                                          extension.toLowerCase() == "png" ||
                                          extension.toLowerCase() == "jpg" ||
                                          extension.toLowerCase() == "jpeg" ||
                                          extension.toLowerCase() == "svg"
                                        ) {
                                          attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />;
                                        } else if (extension.toLowerCase() == "pdf") {
                                          attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>;
                                        } else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                          attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>;
                                        } else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                          attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>;
                                        }
                                        return (
                                          <>
                                            <li
                                              key={"test_k" + index + "" + index1}
                                              className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                              <div className="conversation-list">
                                                <div className="ctext-wrap">
                                                  <div className="ctext-wrap-content">
                                                    <h5 className="font-size-14 conversation-name">
                                                      <Link to="#" className="text-dark">
                                                        {message.createdBy.firstName}
                                                      </Link>
                                                      <span className="d-inline-block font-size-12 text-muted ms-2">
                                                        {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}
                                                      </span>
                                                    </h5>
                                                    <p className="mb-0">
                                                      <a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                        {attchment}
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                }
                              })}
                            </ScrollBar>
                          </div>
                        </div>
                        <div className="col">
                          <div className="position-relative">
                            <Input
                              value={msgObject?.comment}
                              type="textarea"
                              id="comment"
                              name="comment"
                              className="form-control chat-input rounded"
                              onChange={(e) => handleMessage(e)}
                              rows="1"
                              placeholder="Enter Message..."
                            />
                          </div>
                        </div>
                        <div className="col-auto">
                          <div class="image-upload">
                            <label for="file-input">
                              {attachCount} <i class="fa fa-paperclip" aria-hidden="true"></i>
                            </label>
                            <input id="file-input" type="file" multiple onChange={uploadAttachment} />
                          </div>
                        </div>
                        <div className="col-auto">
                          <Button type="submit" color="primary" onClick={handleSendMessage} className="chat-send waves-effect waves-light">
                            <i className="mdi mdi-send float-end"></i>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </Offcanvas.Body>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Offcanvas>
      </React.Fragment>
    </>
  );
};

export default ProjectDetails;
// const mapStateToProps = (state) => {};

// export default withRouter(connect(mapStateToProps, { apiError })(StaffDetails));
