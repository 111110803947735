import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Resete from "@mui/icons-material/ResetTvRounded";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

//Dialogue box content's
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

//Dialogue box table content's
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Form from "react-bootstrap/Form";

import $ from "jquery";

import Calculated_Salary from "../Calculated Salary/Calculated_Salary";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Salary_Calculation.scss";
import { Grid } from "@mui/material";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DatePicker from "@mui/lab/DatePicker";

import {
  getLocalbody,
  setAssignCust,
  getAssignCust,
} from "../../../helpers/globalFunctions";

//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const rows = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const CheckinCheckout = () => {
  const [show, setShow] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const tableStyle = {
    paddingRight: "14%",
  };
  const tableStyle1 = {
    paddingRight: "25%",
  };

  const [details, setDetails] = useState([
    {
      checkBox: (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTableCheckboxChange(e)}
          ></input>
        </>
      ),
      id: "1",
      name: "Anitha",
      Salarytype: "Online",
      basicSalary: "5000/Month",
      attendance: (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>10</td>
                      <td style={tableStyle1}>0</td>
                    </tr>
                  </thead>
                </table>
                {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
              </Row>

              {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
            </Col>
          </Row>
        </>
      ),
      lop: "2500",
      "calc.Salary": "4800",
      "pre.advance": "1000",
      advanceDue: "2000",
      extraPayment: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      incentive: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      toPay: "5000",
    },
    {
      checkBox: (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTableCheckboxChange(e)}
          ></input>
        </>
      ),
      id: "2",
      name: "Beena",
      Salarytype: "Online",
      basicSalary: "5000/Month",
      attendance: (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>10</td>
                      <td style={tableStyle1}>0</td>
                    </tr>
                  </thead>
                </table>
                {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
              </Row>

              {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
            </Col>
          </Row>
        </>
      ),
      lop: "2500",
      "calc.Salary": "4800",
      "pre.advance": "1000",
      advanceDue: "2000",
      extraPayment: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      incentive: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      toPay: "5000",
    },
    {
      checkBox: (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTableCheckboxChange(e)}
          ></input>
        </>
      ),
      id: "3",
      name: "Shobha",
      Salarytype: "Online",
      basicSalary: "5000/Month",
      attendance: (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>10</td>
                      <td style={tableStyle1}>0</td>
                    </tr>
                  </thead>
                </table>
                {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
              </Row>

              {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
            </Col>
          </Row>
        </>
      ),
      lop: "2500",
      "calc.Salary": "4800",
      "pre.advance": "1000",
      advanceDue: "2000",
      extraPayment: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      incentive: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      toPay: "5000",
    },
    {
      checkBox: (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTableCheckboxChange(e)}
          ></input>
        </>
      ),
      id: "4",
      name: "Mayur",
      Salarytype: "Online",
      basicSalary: "5000/Month",
      attendance: (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>10</td>
                      <td style={tableStyle1}>0</td>
                    </tr>
                  </thead>
                </table>
                {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
              </Row>

              {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
            </Col>
          </Row>
        </>
      ),
      lop: "2500",
      "calc.Salary": "4800",
      "pre.advance": "1000",
      advanceDue: "2000",
      extraPayment: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      incentive: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      toPay: "5000",
    },
    {
      checkBox: (
        <>
          <input
            type="checkbox"
            id="tableCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTableCheckboxChange(e)}
          ></input>
        </>
      ),
      id: "5",
      name: "nizam",
      Salarytype: "Online",
      basicSalary: "5000/Month",
      attendance: (
        <>
          <Row>
            <Col xl="12">
              <Row>
                <table>
                  <thead>
                    <tr>
                      {/* <td>LOP</td> */}
                      <td style={tableStyle}>10</td>
                      <td style={tableStyle1}>0</td>
                    </tr>
                  </thead>
                </table>
                {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
              </Row>

              {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
            </Col>
          </Row>
        </>
      ),
      lop: "2500",
      "calc.Salary": "4800",
      "pre.advance": "1000",
      advanceDue: "2000",
      extraPayment: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      incentive: (
        <>
          <input
            defaultValue="45000"
            style={{ textAlign: "right" }}
            className="form-control"
            type="text"
          />
        </>
      ),
      toPay: "5000",
    },
    {
      incentive: (
        <>
          <b>Total</b>
        </>
      ),
      toPay: "25000",
    },
  ]);
  const handleChange = (selected) => {
    setType(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [type1, setType1] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleChange1 = (selected) => {
    setType1(selected);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const [value1, setValue1] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate1 = (newValue1) => {
    setValue1(newValue1);
  };

  const [form, setForm] = React.useState(false);

  const handleClickOpenForm = () => {
    setForm(true);
  };

  const handleCloseForm = () => {
    setForm(false);
  };
  const reset = () => {
    setValue("");
    setType(null);
    setType1(null);
  };

  const handleAllCheckboxChange = (e) => {
    let data = [...details];
    let values = [];
    if (e.target.checked === true) {
      $("input[type=checkbox]").prop("checked", true);
      data &&
        data.map((item) => {
          values.push(item._id);
        });
      // setAssignCust(values);
    } else {
      $("input[type=checkbox]").prop("checked", false);
      values = [];
      // setAssignCust("");
    }
    // setAssignCustomer(values);
  };
  const handleTableCheckboxChange = (e) => {
    $('input[type="checkbox"][name="headerCheckbox"]')
      .prop("checked", false)
      .trigger("change");

    // let dataIndex = null;
    // let val = getAssignCust();
    // let arr = null;
    // if (val) {
    //   arr = val.split(",");
    // }
    // // console.log("arr: ", arr);
    // let values = [];
    // // console.log("values:- ", values);
    // if (arr !== null) {
    //   values = arr;
    // }

    // if (e.target.checked === true) {
    //   values.push(item._id);
    // } else {
    //   values &&
    //     values.map((value, index) => {
    //       if (value === item?._id) dataIndex = index;
    //     });
    //   values.splice(dataIndex, 1);
    // }
    // setAssignCustomer(values);
    // setAssignCust(values);
  };

  const data = {
    columns: [
      {
        label: (
          <input
            type="checkbox"
            name="headerCheckbox"
            id="headerCheckbox"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleAllCheckboxChange(e)}
          ></input>
        ),
        field: "checkBox",
        sort: "asc",
        width: 150,
      },
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Basic",
        field: "basicSalary",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">Attendance</th>
                      </tr>
                      <tr>
                        {/* <td>LOP</td> */}
                        <td>P</td>
                        <td>L</td>
                      </tr>
                    </thead>
                  </table>
                  {/* <Col md="3">
                    <Label className="flabel">Attendance</Label>
                    {"  "}
                    <Label>LOP</Label>
                  </Col> */}
                </Row>

                {/* <Label>P</Label>
                {"  "}
                <Label>L</Label>
                {"  "}
                <Label>H</Label>
                {"  "} */}
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: "Calculated",
        field: "calc.Salary",
        sort: "asc",
        width: 150,
      },

      {
        label: "Advance",
        field: "advanceDue",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra Payment",
        field: "extraPayment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentive",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pay",
        field: "toPay",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Calculate Salary" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Col xl="12">
                        <Row>
                          <Col md="3">
                            <div className="mb-3">
                              <Stack direction="row" spacing={2}>
                                <Label
                                  htmlFor="validationCustom05"
                                  style={{ marginTop: "auto" }}
                                >
                                  Month
                                </Label>
                                <input
                                  className="form-control"
                                  type="month"
                                  value={value}
                                  defaultValue="mm/dd/yyyy"
                                  id="example-date-input"
                                  onChange={handleChangeDate}
                                />
                              </Stack>
                            </div>
                          </Col>
                          <Col md="5">
                            <div className="mb-3">
                              <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label
                                  column
                                  sm={4}
                                  style={{ marginRight: "inherit" }}
                                >
                                  No. Of. Working Days
                                </Form.Label>
                                <Col sm={8}>
                                  <Form.Control
                                    style={{ width: "82%" }}
                                    type="text"
                                    placeholder="No. Of. Working Days"
                                  />
                                </Col>
                              </Form.Group>
                              {/* <Stack direction="row" spacing={2}>
                                <Label htmlFor="validationCustom05">
                                  No.of.Working Days
                                </Label>
                                <AvField
                                  name="No.of.Working Days"
                                  placeholder="No.of.Working Days"
                                  type="text"
                                  errorMessage="Enter No.of.Working Days"
                                  validate={{ required: { value: true } }}
                                  className="form-control"
                                  id="validationCustom01"
                                  
                                />
                              </Stack> */}
                            </div>
                          </Col>
                          <Col md="4" className="mt-0">
                            <div className="mb-1">
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                onClick={() => setShow(true)}
                              >
                                {/* <Save></Save> */}
                                {"  "}
                                Calculate
                              </Button>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={() => reset()}
                              >
                                {"  "}
                                Reset
                              </Button>
                            </div>
                          </Col>

                          {/* <div className="App">
                          {show ? <h1>Hello World !</h1> : null}
                       
                          <button onClick={() => setShow(true)}>Show</button>
                          <button onClick={() => setShow(false)}>Hide</button>
                         </div> */}

                          {/* <Col md="3" style={{ width: "13%" }}>
                          <div className="mb-3 mt-2">
                            <Button color="primary" type="submit">
                              <CalculateOutlinedIcon></CalculateOutlinedIcon>
                              {"  "}
                              Calculate
                            </Button>
                          </div>
                        </Col>
                        <Col md="3" style={{ width: "12%" }}>
                          <div className="mb-3 mt-2">
                            <Button color="danger" type="submit">
                              <Resete></Resete>
                              {"  "}
                              Resete
                            </Button>
                          </div>*/}
                        </Row>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {show ? (
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}

                      <MDBDataTable
                        id="salaryCalcTableId"
                        responsive
                        
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />

                      <Row>
                        <Col
                          className="col-12"
                          style={{
                            paddingTop: "4px",
                            textAlign: "-webkit-right",
                          }}
                        >
                          <div className="mb-2">
                            <Button color="primary" type="submit" size="lg">
                              {"  "}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
            <Calculated_Salary />
          </div>
        </div>
      </React.Fragment>

      <div>
        <Dialog open={form} onClose={handleCloseForm}>
          <DialogTitle style={{ textAlign: "center" }}>
            Check In/Check Out Log Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Check In Date:</TableCell>
                      <TableCell align="left">08-11-2021</TableCell>
                      <TableCell align="left">Check In Time:</TableCell>
                      <TableCell align="left">23:26:45</TableCell>
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                        <TableCell align="left">{row.fat}</TableCell>
                        <TableCell align="left">{row.carbs}</TableCell>
                        <TableCell align="left">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="success">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default CheckinCheckout;
