import React, { useState, useEffect, useRef } from "react";
import {useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Taskstatus.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const Taskstatus = () => {
  const [taskObject, setTaskObject] = useState({});

  const [taxIdTobeUpdated, setTaskIdToBeUpdated] = useState(null);

  const [taskIdToBeDeleted, setTaskIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [taskForTable, setTaskForTable] = useState([]);

  const [taskValue, settaskValue] = useState("");
  
  const [type, setType] = useState([]);//options fetching 
  const [selectedType, setSelectedType] = useState(null);//selecting option

  const [flow, setFlow] = useState([]);//options fetching 
  const [selectedFlow, setSelectedFlow] = useState(null);//selecting option

  const [errors, setErrors] = useState({
    taskError: "",
  });

  const {
    districts,
    addingTax,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_category();
    fetch_flow();
  }, []);

  function handleTableData() {
    var url = `${API_URL}taskstatus/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let taskData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTask(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setTaskIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          if(item?.type_flow_id){
            let value=item?.type_flow_id || {};
            if(value?.item_name){
              item.item_name=value.item_name
            }
          }

          taskData.push(item);
        });
        setTaskForTable(taskData);
      });
  }

  let preUpdateTask = (item) => {
  //  console.log("Task", item);
   // settaskValue(item?.type_priority);
    item.type_priority=item?.type_priority;
    let Type = item?.type_flow_id? item.type_flow_id : "";
    let Types = {
      label: Type?.item_name,
      value: Type?._id,
    };
    setSelectedType(Types);
    setTaskIdToBeUpdated(item._id);
    setTaskObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "type_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Task Flow",
        field: "item_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Priority",
        field: "type_priority",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: taskForTable,
  };

  function handleChangeTask(e) {
    let name = e.target.name;
    let value = e.target.value;
    settaskValue(value);
    setTaskObject({ ...taskObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (selectedType === null) {
      let errorVal = errors;
      if (selectedType === null) errorVal.taskError = "Please Select Task Flow";
      setErrors({
        ...errorVal,
      });
    } else {
      if (taxIdTobeUpdated) {
        axios
          .put(`${API_URL}taskstatus`, taskObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task Status updated successfully");
              settaskValue("");
              formRef.current.reset();
              handleTableData();
              setTaskObject({});
              setTaskIdToBeUpdated(null);
              setSelectedType(null);
              setErrors({
                taskError: "",
              });
            } else {
              toastr.error("Failed to update Task Status");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}taskstatus`, taskObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Task Status added successfully");
              handleTableData();
              settaskValue("");
              formRef.current.reset();
              setTaskObject({});
              setSelectedType(null);
              setErrors({
                taskError: "",
              });
            } else {
              toastr.error("Failed to add Task Status");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  function fetch_category(){
    axios
    .get(`${API_URL}taskFlow/options/`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res)=>{
      var Category=
       res.data.data &&
       res.data.data.map((el)=>{
        return{
          label:el.item_name,
          value:el._id
        };
       });
       setType([{options:Category}]) 
    });
}

const reset = () => {
  handleTableData();
  setSelectedFlow("");
  setTaskObject({
    ...taskObject,
    flow_id: "",
  });
};
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Type":
        setSelectedType(selected);
        setTaskObject({
          ...taskObject,
          type_flow_id:selected.value,
        });
        setErrors({
          ...errors,
          taskError: "",
        });
        break;
      case "flow":
          setSelectedFlow(selected);
          setTaskObject({
            ...taskObject,
            flow_id:selected.value,
          });
          break;


      default:
        break;
    }
  };

  function fetch_flow(){
    axios
    .get(`${API_URL}taskFlow/options/`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res)=>{
      var Category=
       res.data.data &&
       res.data.data.map((el)=>{
        return{
          label:el.item_name,
          value:el._id
        };
       });
       setFlow([{options:Category}]) 
    });
}
  
const handleSearch = () => {
  let flow = taskObject?.flow_id ? taskObject.flow_id : "";
  handleTableListData(flow);
}; 

function handleTableListData(flow = "") {
  var url = `${API_URL}taskstatus/list?flow_id=` + flow;

  axios
    .get(url, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      var result = res.data.data;
      let statusData = [];

      result.map((item, index) => {
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                toTop();
                preUpdateTask(item);
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => {
                setTaskIdToBeDeleted(item._id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
        item.id = index + 1;
        if (item?.up_date) {
          item.date = moment(item.up_date).format("DD-MM-YYYY");
        } else {
          item.date = moment(item.date).format("DD-MM-YYYY");
        }

        if (item?.up_time) {
          item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
        } else {
          item.time = moment(item.time, "HHmmss").format("hh:mm a");
        }

        if (item?.updatedby) {
          let values = item?.updatedby || {};
          if (values?.lastName)
            item.staff = values.firstName + " " + values.lastName;
          else item.staff = values?.firstName;
        } else {
          let values = item?.addedby || {};
          if (values?.lastName)
            item.staff = values.firstName + " " + values.lastName;
          else item.staff = values?.firstName;
        }

        if(item?.type_flow_id){
          let value=item?.type_flow_id || {};
          if(value?.item_name){
            item.item_name=value.item_name
          }
        }
     

        statusData.push(item);
      });

      setTaskForTable(statusData);
    });
}


  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}taskstatus` + "/" + taskIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Task Status deleted successfully");
                    if (taskObject && taskObject._id === taskIdToBeDeleted) {
                      settaskValue("");
                      formRef.current.reset();
                      setTaskObject({});
                      setTaskIdToBeUpdated(null);
                      setErrors({
                        taxError: "",
                      });
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Task Status");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Task Status" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Name</Label>
                            <AvField
                              style={customStyles}
                              name="type_name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Task Status Name"
                              value={taskObject?.type_name}
                              onChange={handleChangeTask}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Task Flow</Label>
                            <Select
                              name="Type"
                              value={selectedType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Type");
                              }}
                             
                              options={type}//getting options
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedType === null ? errors.taskError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Priority</Label>
                            <AvField
                              style={customStyles}
                              name="type_priority"
                              placeholder="Priority"
                              type="number"
                              className="form-control"
                              id="validationCustom01"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Task Status Priority"
                              value={taskObject?.type_priority}
                              onChange={handleChangeTask}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {taxIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingTax ? true : false}
                              >
                                {addingTax ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingTax ? true : false}
                              >
                                {addingTax ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Subject</Label>
                        <Select
                          name="cust_type_id"
                          value={selectedCustomerTypeSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Task Flow
                          </Label>
                          <Select
                            name="flow"
                            value={selectedFlow}
                            onChange={(selected) => {
                              handleSelectChange(selected, "flow");
                            }}
                           options={flow}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                           onClick={() => handleSearch()}
                          >
                            {"  "}
                            Filter
                          </Button>
                          <Button
                            color="danger"
                            type="submit"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {/* <MDBDataTable
                      id="holidayTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody> */}
                    <MDBDataTable
                      id="taxTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Taskstatus;