import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import Select from "react-select";

import { withRouter, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import accessToken from "../../helpers/jwt-token-access/accessToken";

import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import { AvForm, AvField } from "availity-reactstrap-validation";

import defaultProfile from "../../assets/images/person-icon.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./OurProject.scss";
import { red } from "@mui/material/colors";
import { Divider } from "@mui/material";

const Followups = () => {
  const formRef = useRef();

  const [ourProjectObject, setOurProjectObject] = useState({});
  const [ourProjetcIdTobeUpdated, setOurProjectIdToBeUpdated] = useState(null);
  const [ourProjectIdToBeDeleted, setOurProjectIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [ourProjectForTable, setOurProjectForTable] = useState([]);
  const [ourProjectValue, setOurProjectValue] = useState("");

  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [selectedStatusSearch, setSelectedStatusSearch] = useState(null);

  const [type, setType] = useState([]);
  const [selectedTypeSearch, setSelectedTypeSearch] = useState(null);

  const [tableData, setTableData] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  let history = useHistory();

  const {
    districts,
    addingOurProject,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  useEffect(() => {
    handleTableData();
    fetch_all_Branch();
    fetch_all_Types();
  }, []);

  function fetch_all_Branch() {
    axios
      .get(`${API_URL}branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setBranch([{ options: Branch_data }]);
      });
  }

  function fetch_all_Types() {
    axios
      .get(`${API_URL}project/projectoption`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Type_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setType([{ options: Type_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Branch":
        setSelectedBranch(selected);
        setOurProjectObject({
          ...ourProjectObject,
          branch: selected.value,
        });
        break;
      case "Type":
        setSelectedTypeSearch(selected);
        setOurProjectObject({
          ...ourProjectObject,
          type: selected.value,
        });
        break;

      default:
        break;
    }
  };

  function handleTableData(
    prj_status = "",
    type = "",
    branch = "",
    project_Name = ""
  ) {
    var url =
      `${API_URL}project/view-projects?prj_status=` +
      prj_status +
      "&type=" +
      type +
      "&branch=" +
      branch +
      "&project_name=" +
      project_Name;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        result.map((item, index) => {
          item.id = index + 1;

          if (item.prj_status === 1) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-primary">Working</span>
              </div>
            );
          } else if (item.prj_status === 2) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-success">Completed</span>
              </div>
            );
          } else if (item.prj_status === 3) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-info">Pending</span>
              </div>
            );
          }else if (item.prj_status === 4) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-warning">Suspended</span>
              </div>
            );
          }else if (item.prj_status === 5) {
            item.ProjectStatus = (
              <div>
                <span className="badge rounded-pill bg-danger">Rejected</span>
              </div>
            );
          } else {
            item.ProjectStatus = "";
          }

          if(typeof item.kick_off!="undefined" && item.kick_off!="" && item.kick_off!=null)
          {
            item.kick_off = moment(item.kick_off).format("DD-MM-YYYY");
          }
          else
          {
            item.kick_off="";
          }
          if(typeof item.end!="undefined" && item.end!="" && item.end!=null)
          {
            item.end = moment(item.end).format("DD-MM-YYYY");
          }
          else
          {
            item.end="";
          }

          // item.localbody_name =  getLocalbody();
          // item.type = item?.cust_type;
          // item.district_name = item?.district;
          // item.ward_name = item?.ward;

          // item.image = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //       src={`${API_URL}public/uploads${item.popup_image}`}
          //       height="160"
          //       width="120"
          //     />
          //   </div>
          // );
        });

        setTableData(result);
      });
  }

  const handleSelectedForSearch = (selected, name) => {
    switch (name) {
      case "Types":
        setSelectedTypeSearch(selected);
        setOurProjectObject({
          ...ourProjectObject,
          type: selected.value,
        });
        break;
      case "ProjectStatus":
        setSelectedStatusSearch(selected);
        setOurProjectObject({
          ...ourProjectObject,
          prj_status: selected.value,
        });
        break;

      default:
        break;
    }
  };

  function handleChangeOurProject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setOurProjectValue(value);
    setOurProjectObject({ ...ourProjectObject, [name]: value });
  }

  const handleSearch = () => {
    let Status = ourProjectObject?.prj_status
      ? ourProjectObject.prj_status
      : "";
    let Type = ourProjectObject?.type ? ourProjectObject.type : "";

    let Branch = ourProjectObject?.branch ? ourProjectObject.branch : "";

    let Project_Name = ourProjectObject?.project_name
      ? ourProjectObject.project_name
      : "";

    handleTableData(Status, Type, Branch, Project_Name);
  };

  const customStyles = {
    lineHeight: "1.8",
  };
  const customStyles1 = {
    borderColor: "#e5e5e5",
    height: "auto",
  };

  const reset = () => {
    formRef.current.reset();
    handleTableData();
    setSelectedTypeSearch(null);
    setSelectedStatusSearch(null);
    setSelectedBranch(null);
    setOurProjectObject({});
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Our Projects" />
          <Row>
            <Col xl="12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            menuPosition="fixed"
                            name="Branch"
                            value={selectedBranch}
                            onChange={(selected) => {
                              handleSelectChange(selected, "Branch");
                            }}
                            options={branch}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Project Name
                          </Label>
                          <AvField
                            style={customStyles}
                            name="project_name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Project Name"
                            className="form-control"
                            id="validationCustom02"
                            value={ourProjectObject?.project_name}
                            onChange={handleChangeOurProject}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            menuPosition="fixed"
                            name="Type"
                            displayValue="Type"
                            value={selectedTypeSearch}
                            onChange={(selected) => {
                              handleSelectedForSearch(selected, "Types");
                            }}
                            options={type}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="Status"
                            menuPosition="fixed"
                            value={selectedStatusSearch}
                            onChange={(selected) => {
                              handleSelectedForSearch(
                                selected,
                                "ProjectStatus"
                              );
                            }}
                            options={[
                              {
                                label: "Working",
                                value: 1,
                              },
                              {
                                label: "Completed",
                                value: 2,
                              },
                              {
                                label: "Pending",
                                value: 3,
                              },
                              {
                                label: "Suspended",
                                value: 4,
                              },
                              {
                                label: "Rejected",
                                value: 5,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            onClick={() => handleSearch()}
                          >
                            Filter
                          </Button>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {tableData?.map((item) => (
              <div className="col-sm-6 col-xl-4" style={{ cursor:"pointer"}}>
                <div
                  className="text-center card"
                  style={{ borderRadius: "5px", padding: "0px 0px 2px 0px" }}
                  onClick={() => {
                    history.push("/project/" + item.unique_id);
                  }}
                >
                  <div
                    className="card-body"
                    style={{ padding: "10px 11px 2px" }}
                  >
                    <div className="float-end dropdown">
                      <a
                        aria-haspopup="true"
                        className="text-body font-size-16 dropdown-toggle"
                        aria-expanded="false"
                      >
                        {/* <i className="uil uil-ellipsis-h"></i> */}
                      </a>
                      <div
                        tabindex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu-end dropdown-menu"
                      >
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Action
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Remove
                        </a>
                      </div>
                    </div>

                    <div className="clearfix"></div>

                    <h5 className="font-size-16 mb-1">
                      <a className="text-dark">{item.shortcode}</a>
                    </h5>
                    <Row style={{ padding: "10px 0px" }}>
                      <Col xl={9} style={{ textAlign: "left" }}>
                        <a target="_blank" href={item.domain}>
                          {item.domain}
                        </a>
                      </Col>
                      <Col xl={3} style={{ textAlign: "right" }}>
                      {item.hours?item.hours:0} Hrs
                      </Col>
                    </Row>
                    <Row style={{ padding: "10px 0px" }}>
                      <Col xl={6} style={{ textAlign: "left" }}>
                        {item.unique_id}
                      </Col>
                      <Col xl={6} style={{ textAlign: "right" }}>
                        {item.Type}
                      </Col>
                    </Row>
                    <Row style={{ padding: "10px 0px" }}>
                      <Col xl={6} style={{ textAlign: "left" }}>
                      Kickoff : {item.kick_off}
                      </Col>
                      <Col xl={6} style={{ textAlign: "right" }}>
                      End : {item.end}
                      </Col>
                    </Row>
                    <Row style={{ padding: "10px 0px" }}>
                      {/* <Col xl={6} style={{ textAlign: "left" }}>
                        <a
                          type="button"
                          style={{ textAlign: "left", borderColor: "#fff" }}
                          onClick={() => {
                            history.push("/project/" + item.unique_id);
                          }}
                        >
                          View Details
                        </a>
                      </Col> */}
                      <Col xl={6} style={{ textAlign: "left" }}>
                        {item.ProjectStatus}
                      </Col>
                    </Row>
                    {/* <div class="d-flex justify-content-around">
                      <div class="p-2">
                        {" "}
                        <a target="_blank" href={item.domain}>
                          {item.domain}
                        </a>
                      </div>
                      <div class="p-2">{item.hours}&nbsp;Hrs</div>
                    </div>

                    <div class="d-flex justify-content-around">
                      <div class="p-2">{item.unique_id}</div>

                      <div class="p-2">{item.Type}</div>
                    </div>

                    <div class="d-flex justify-content-around">
                      <div class="p-2">{item.kick_off}</div>

                      <div class="p-2">{item.end}</div>
                    </div>
                    <div class="d-flex justify-content-around">
                      <div class="p-2">
                        <a
                          type="button"
                          className="text-truncate btn btn-btn-outline-light"
                          style={{ textAlign: "left", borderColor: "#fff" }}
                          // onClick={() => {
                          //   history.push("/view_client/" + item._id);
                          //   // window.location.assign("/staff/" + item._id);
                          // }}
                        >
                          View Details
                        </a>
                      </div>

                      <div class="p-2">
                        {" "}
                        <button
                          type="button"
                          className="text-truncate btn btn-btn-outline-light"
                          style={{
                            borderColor: "#fff",
                          }}
                        >
                          <i classname="uil uil-envelope-alt me-1"></i>

                          {item.ProjectStatus}
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Followups;