import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
// import Table from "react-bootstrap/Table";
// import Select from "react-select";
import { apiError } from "../../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import "./StaffDetails.scss";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
// import TurnRightIcon from "@mui/icons-material/TurnRight";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import Select from "react-select";

import {
  formatMoney,
  getLocalbody,
  getRouteName,
  getDate,
  getFirstday,
} from "../../../../../helpers/globalFunctions";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import accessToken from "../../../../../helpers/jwt-token-access/accessToken";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import defaultProfile from "../../../../../assets/images/person-icon.png";
import moment from "moment";

import SweetAlert from "react-bootstrap-sweetalert";

const StaffDetails = (props) => {
  const { AddStaffid } = props.match.params;
  const [details, setDetails] = useState({});

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setShow(false);

  const [staffDetaObject, setStaffDetaObject] = useState({
    user_id: AddStaffid,
  });
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [staffDetaIdToBeDeleted, setStaffDetaIdToBeDeleted] = useState();

  const [staffLeaveIdToBeDeleted, setStaffLeaveIdToBeDeleted] = useState();

  const [staffSalaryIdToBeDeleted, setStaffSalaryIdToBeDeleted] = useState();

  const [staffSocialIdToBeDeleted, setStaffSocialIdToBeDeleted] = useState();

  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [confirmLeaveDeleteAlert, setConfirmLeaveDeleteAlert] = useState(null);
  const [confirmSalaryDeleteAlert, setConfirmSalaryDeleteAlert] =
    useState(null);
  const [confirmSocialDeleteAlert, setConfirmSocialDeleteAlert] =
    useState(null);

  const [staffDetaForTable, setStaffDetaForTable] = useState([]);
  const [staffDetaValue, setstaffDetaValue] = useState("");

  const [designation, setDesignation] = useState(null);
  const [selectedDesig, setSelectedDesig] = useState(null);

  const [selectedSocial, setSelectedSocial] = useState(null);

  const [leaveType, setLeaveType] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);

  const [socialTable, setSocialTable] = useState([]);

  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);

  const [selectedLeaveFrom, setSelectedLeaveFrom] = useState(null);
  const [selectedLeaveTo, setSelectedLeaveTo] = useState(null);

  const [selectedSalaryFrom, setselectedSalaryFrom] = useState(null);
  const [selectedSalaryTo, setselectedSalaryTo] = useState(null);

  const [popupBankData, setPopupBankData] = useState({});
  const [popupBankView, setPopupBankView] = useState(false);

  const [errors, setErrors] = useState({
    socialType: "",
    leaveFrom: "",
    leaveTo: "",
    leaveType: "",
    salaryFrom: "",
    salaryTo: "",
    staffDesignation: "",
  });


  const {
    districts,
    addingUser,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const [showModal, setShowModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [userActivityLog, setUserActivityLog] = useState([]);
  const [leaveTable, setLeaveTable] = useState([]);
  const [salaryTable, setSalaryTable] = useState([]);
  const [userResponseData, setUserResponseData] = useState([]);
  const [staffBankData, setStaffBankData] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [routeName, setRouteName] = useState(getRouteName(props));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (AddStaffid) {
      handleTableData(AddStaffid);
    }
    fetch_Bank_Details();
    fetch_Leave_Details();
    fetch_Salary_Details();
    fetch_all_Designation();
    fetch_Social_Details();
    fetch_all_LeaveType();
  }, []);

  const handleTableData = (id) => {
    axios
      .get(`${API_URL}user/single/?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let StaffData = [];

        let Privilage = result?.privilage || {};
        result.privilage = Privilage.privilege_name;

        let Branch = result?.branch || {};
        result.branch = Branch.name;

        let Quali = result?.quali || {};
        result.quali = Quali.qualification_name;

        let Staff_Desig = result?.user_desig || {};
        result.designation = Staff_Desig.name;
        result.designID = Staff_Desig._id;

     
      
        setSelectedDesig([
          {
            label: result.designation,
            value: result.designID,
          },
        ]);
        setStaffDetaObject({
          designation: result.designID,
        });

        let primary =result?.primary_staff
        if(primary.lastName){
        result.prime=primary?.firstName+" "+primary?.lastName;}
        else{result.prime=primary?.firstName;}

        let secondary =result?.secondary_staff
        if(secondary.lastName){  result.sec=secondary?.firstName+" "+secondary?.lastName;}
        else{result.sec=secondary?.firstName;}
console.log(result)
        setDetails(result);

        fetch_response();
        fetch_Bank_Details(result?._id);
        fetch_Leave_Details(result?._id);
        fetch_Salary_Details(result?._id);
        fetch_Social_Details(result?._id);
      });
  };
  const fetch_Bank_Details = (id) => {
    axios
      .get(`${API_URL}user/bankdetailssinglelist?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;

            let BankDeta = item?.bank_details;

            if (item?.bank_details?.up_date) {
              item.Dates = moment(item.bank_details.up_date).format("DD-MM-YYYY");
            } else {
              item.Dates = moment(item.bank_details.date).format("DD-MM-YYYY");
            }
  
            if (item?.bank_details?.up_time) {
              item.Times = moment(item.bank_details.up_time, "HHmmss").format("hh:mm a");
            } else {
              item.Times = moment(item.bank_details.time, "HHmmss").format("hh:mm a");
            }
  
           
            // item.date = BankDeta?.date;
            // item.Dates = moment(item.date).format("DD-MM-YYYY");
            // item.time = BankDeta?.time;
            // item.Times = moment(item.time, "HHmmss").format("hh:mm a");
            
            
            item.bank_name = BankDeta?.bank_name;
            item.bank_branch = BankDeta?.bank_branch;
            item.bank_acc = BankDeta?.bank_acc;
            item.bank_ifsc = BankDeta?.bank_ifsc;

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenBankForm(item);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateBankDetails(item);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setStaffDetaIdToBeDeleted(item.bank_details._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            if (item?.bank_details?.updated_by[0]) {
              let values = item?.bank_details?.updated_by[0] || {};

              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            } else {
              let values = item?.bank_details?.created_by[0] || {};
              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            }
          });
        setUserActivityLog(result);
      });
  };

  const fetch_Leave_Details = (id) => {
    axios
      .get(`${API_URL}user/leavedetailssinglelist?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;
            let LeaveData = item?.leave_details;

            if (LeaveData?.up_date) {
              item.date = LeaveData?.up_date;
              item.dates1 = moment(LeaveData.up_date).format("DD-MM-YYYY");
              item.time = LeaveData?.up_time;
              item.time = moment(LeaveData.up_time, "HHmmss").format("hh:mm a"); // 24hours


            } else {
              item.date = LeaveData?.date;
              item.dates1 = moment(LeaveData.date).format("DD-MM-YYYY");
              item.time = LeaveData?.time;
              item.time = moment(LeaveData.time, "HHmmss").format("hh:mm a"); // 24hours


            }
            item.Dates = (
              <span>
                {item.dates1}<br></br>{item.time}</span>
            );
            item.leave_from = LeaveData?.leave_from;
            item.Leave_From = moment(item.leave_from).format("DD-MM-YYYY");

            item.leave_to = LeaveData?.leave_to;
            item.Leave_To = moment(item.leave_to).format("DD-MM-YYYY");
            item.Leave_Type = LeaveData?.leave_type;
            item.TotalNoOf_Daya = LeaveData?.total_no_days;
            let Leave = LeaveData?.leave_type[0] || {};
            item.leave = Leave?.name;

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateLeaveDetails(item.leave_details);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setStaffLeaveIdToBeDeleted(item.leave_details._id);

                    setConfirmLeaveDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            if (item?.leave_details?.updated_by[0]) {
              let values = item?.leave_details?.updated_by[0] || {};

              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            } else {
              let values = item?.leave_details?.created_by[0] || {};
              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            }
          });

        setLeaveTable(result);
      });
  };

  const fetch_Salary_Details = (id) => {
    axios
      .get(`${API_URL}user/salarydetailssinglelist?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;
            let SalaryData = item?.salary_details;

            // item.date = SalaryData?.date;
            // item.Dates = moment(item.date).format("DD-MM-YYYY");
            // item.time = SalaryData?.time;
            // item.Times = moment(item.time, "HHmmss").format("hh:mm a");

            if (item?.salary_details?.up_date) {
              item.Dates = moment(item.salary_details.up_date).format("DD-MM-YYYY");
            } else {
              item.Dates = moment(item.salary_details.date).format("DD-MM-YYYY");
            }
  
            if (item?.salary_details?.up_time) {
              item.Times = moment(item.salary_details.up_time, "HHmmss").format("hh:mm a");
            } else {
              item.Times = moment(item.salary_details.time, "HHmmss").format("hh:mm a");
            }

            item.salary_from = SalaryData?.salary_from;
            item.Salary_From = moment(item.salary_from).format("DD-MM-YYYY");

            item.salary_to = SalaryData?.salary_to;
            item.Salary_to = moment(item.salary_to).format("DD-MM-YYYY");
            item.hourly_cost = SalaryData?.hourly_cost;
            item.hourly_rate = SalaryData?.hourly_rate;
            item.hourly_salary = SalaryData?.hourly_salary;
            item.basic_salary = SalaryData?.basic_salary;

            let Designation = SalaryData?.designation[0] || {};
            item.designation = Designation?.name;

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenSalaryForm(item);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateSalaryDetails(item.salary_details);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setStaffSalaryIdToBeDeleted(item.salary_details._id);
                    setConfirmSalaryDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            if (item?.salary_details?.updated_by[0]) {
              let values = item?.salary_details?.updated_by[0] || {};

              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            } else {
              let values = item?.salary_details?.created_by[0] || {};
              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            }
          });

        setSalaryTable(result);
      });
  };

  const handleClickOpenSalaryForm = (item) => {
    item.Date = moment(item.date).format("DD-MM-YYYY");
    item.Times = moment(item.time, "HHmmss").format("hh:mm a");

    item.salary_from = item?.salary_details.salary_from;
    item.Salary_From = moment(item.salary_details.salary_from).format(
      "DD-MM-YYYY"
    );

    item.salary_to = item?.salary_details.salary_to;
    item.Salary_to = moment(item.salary_details.salary_to).format("DD-MM-YYYY");

    item.hrCost = item?.salary_details.hourly_cost;
    item.hrRate = item?.salary_details.hourly_rate;
    item.hrSalary = item?.salary_details.hourly_salary;
    item.Basic = item?.salary_details.basic_salary;

    let Designation = item?.salary_details.designation[0] || {};
    item.Desig = Designation?.name;

    let createdBy = item?.salary_details.created_by[0] || {};
    if (createdBy?.lastName)
      item.staff1 = createdBy.firstName + "" + createdBy.lastName;
    else item.staff1 = createdBy?.firstName;

    let updatedBy = item?.salary_details.updated_by[0] || {};
    if (updatedBy) {
      if (updatedBy?.lastName)
        item.staff2 = updatedBy.firstName + "" + updatedBy.lastName;
      else item.staff2 = updatedBy?.firstName;
    } else {
    }

    let updateDate = item?.salary_details.up_date;
    if (updateDate) {
      item.Update = moment(updateDate).format("DD-MM-YYYY");
    } else {
    }

    let UpdateTime = item?.salary_details.up_time;
    if (UpdateTime) {
      item.UpTime = moment(UpdateTime, "HHmmss").format("hh:mm a");
    } else {
    }
    if (item?.Update && item?.UpTime) {
      item.UpDatesTimes = item?.Update + " " + item?.UpTime;
    } else {
    }

    setPopupData(item);
    setPopupView(true);
  };

  const handleClickOpenBankForm = (item) => {
    let BankDeta = item?.bank_details;
    item.Date = moment(BankDeta.date).format("DD-MM-YYYY");
    item.Times = moment(BankDeta.time, "HHmmss").format("hh:mm a");
    item.bank_name = BankDeta?.bank_name;
    item.bank_branch = BankDeta?.bank_branch;
    item.bank_acc = BankDeta?.bank_acc;
    item.bank_ifsc = BankDeta?.bank_ifsc;

    let createdBy = BankDeta?.created_by[0] || {};
    if (createdBy?.lastName)
      item.staff1 = createdBy.firstName + "" + createdBy.lastName;
    else item.staff1 = createdBy?.firstName;

    let updatedBy = BankDeta?.updated_by[0] || {};
    if (updatedBy) {
      if (updatedBy?.lastName)
        item.staff2 = updatedBy.firstName + "" + updatedBy.lastName;
      else item.staff2 = updatedBy?.firstName;
    } else {
    }


    let updateDate = BankDeta?.up_date;
    if (updateDate) {
      item.Update = moment(updateDate).format("DD-MM-YYYY");
    } else {
    }

    let UpdateTime = BankDeta?.up_time;
    if (UpdateTime) {
      item.UpTime = moment(UpdateTime, "HHmmss").format("hh:mm a");
    } else {
    }
    if (item?.Update && item?.UpTime) {
      item.UpDatesTimes = item?.Update + " " + item?.UpTime;
    } else {
    }

    setPopupBankData(item);
    setPopupBankView(true);
  };

  const fetch_Social_Details = (id) => {
    axios
      .get(`${API_URL}user/socialmediasinglelist?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;

            let SocialDeta = item?.social_media;

            // item.date = SocialDeta?.date;
            // item.Dates = moment(item.date).format("DD-MM-YYYY");
            // item.time = SocialDeta?.time;
            // item.Times = moment(item.time, "HHmmss").format("hh:mm a");
            if (item?.social_media?.up_date) {
              item.Dates = moment(item.social_media.up_date).format("DD-MM-YYYY");
            } else {
              item.Dates = moment(item.social_media.date).format("DD-MM-YYYY");
            }
  
            if (item?.social_media?.up_time) {
              item.Times = moment(item.social_media.up_time, "HHmmss").format("hh:mm a");
            } else {
              item.Times = moment(item.social_media.time, "HHmmss").format("hh:mm a");
            }
            item.socialmedia_type = SocialDeta?.socialmedia_type;
            // if (SocialDeta.socialmedia_type == 0) {
            //   item.Social = "Facebook";
            // } else if (SocialDeta.socialmedia_type == 1) {
            //   item.Social = "Twitter";
            // } else if (SocialDeta.socialmedia_type == 2) {
            //   item.Social = "LinkedIn";
            // } else if (SocialDeta.socialmedia_type == 3) {
            //   item.Social = "Instagram";
            // } else {
            //   item.Social = "";
            // }
            item.socialmedia_id = SocialDeta?.socialmedia_id;

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateSocialDetails(item.social_media);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setStaffSocialIdToBeDeleted(item.social_media._id);
                    setConfirmSocialDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            if (item?.social_media?.updated_by[0]) {
              let values = item?.social_media?.updated_by[0] || {};

              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            } else {
              let values = item?.social_media?.created_by[0] || {};
              if (values?.lastName)
                item.staff = values.firstName + " " + values.lastName;
              else item.staff = values?.firstName;
            }
          });
        setSocialTable(result);
      });
  };

  function fetch_all_Designation() {
    axios
      .get(`${API_URL}designation/option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setDesignation([{ options: DesigType }]);
      });
  }

  function fetch_all_LeaveType() {
    axios
      .get(`${API_URL}user/leavetype`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setLeaveType([{ options: DesigType }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Desigg":
        setSelectedDesig(selected);
        setStaffDetaObject({
          ...staffDetaObject,
          designation: selected.value,
        });
        break;
      case "Social":
        setSelectedSocial(selected);
        setStaffDetaObject({
          ...staffDetaObject,
          socialmedia_type: selected.label,
        });
        setErrors({
          ...errors,
          socialType: "",
        });
        break;
      case "LeaveType":
        setSelectedLeaveType(selected);
        setStaffDetaObject({
          ...staffDetaObject,
          leave_type: selected.value,
        });
        break;
      default:
        break;
    }
  };

  const fetch_response = (id) => {
    axios
      .get(`${API_URL}response/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((el, index) => {
            el.id = index + 1;
            el.time = moment(el?.time, "hhmmss").format("hh:mm a");
            el.date = moment(el?.date).format("DD-MM-YYYY");
          });
        setUserResponseData(result);
      });
  };
  const formRef = useRef();
  const reset = () => {
    formRef.current.reset();
    setStaffDetaObject({
      ["salary_from"]:""
    })
    setSelectedLeaveType(null)
  };
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setstaffDetaValue(value);
    setStaffDetaObject({
      ...staffDetaObject,
      [name]: value,
      user_id: AddStaffid,
    });
  };

  // Bank Update Post
  const handleValidSubmit = (item) => {
    if (userIdTobeUpdated) {
      axios
        .put(`${API_URL}user/bankdetails/update`, staffDetaObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Bank updated successfully");
            setstaffDetaValue("");
            formRef.current.reset();
            handleTableData();
            fetch_Bank_Details(AddStaffid);
            setStaffDetaObject({});
            setUserIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update Bank");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}user/bankdetails/add`, staffDetaObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Bank added Successfully");
            setstaffDetaValue("");
            formRef.current.reset();
            handleTableData();
            fetch_Bank_Details(AddStaffid);
            setStaffDetaObject({});
          } else {
            toastr.error("Failed to add Bank");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  // Leave Update Post
  const handleValidSubmitLeave = (item) => {
    if (
      selectedLeaveType === null ||
      !staffDetaObject.leave_from ||
      !staffDetaObject.leave_to
    ) {
      let errorVal = errors;
      if (selectedLeaveType === null)
        errorVal.leaveType = "Please Select Leave Type";
      if (!staffDetaObject?.leave_from)
        errorVal.leaveFrom = "Please Select Leave From Date";
      if (!staffDetaObject.leave_to)
        errorVal.leaveTo = "Please Select Leave To Date";
      setErrors({
        ...errorVal,
      });
    } else {
      if (userIdTobeUpdated) {
        axios
          .put(`${API_URL}user/leavedetails/update`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Leaves updated successfully");
              setstaffDetaValue("");
              formRef.current.reset();
              handleTableData();
              fetch_Leave_Details(AddStaffid);
              setStaffDetaObject({
                ["leave_from"]: 0,
                ["leave_to"]: 0,
              });
              setUserIdToBeUpdated(null);
              setSelectedLeaveType(null);
            } else {
              toastr.error("Failed to update Leaves");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}user/leavedetails/add`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Leave added Successfully");
              setstaffDetaValue("");
              formRef.current.reset();
              handleTableData();
              fetch_Leave_Details(AddStaffid);
              setStaffDetaObject({
                ["leave_from"]: 0,
                ["leave_to"]: 0,
              });
              setSelectedLeaveType(null);
            } else {
              toastr.error("Failed to add Leave");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  // Salary Update Post
  const handleValidSubmitSalary = (item) => {
    if (
      selectedDesig === null ||
      !staffDetaObject.salary_from ||
      !staffDetaObject.salary_to
    ) {
      let errorVal = errors;
      if (selectedDesig === null)
        errorVal.staffDesignation = "Please Select Staff Designation";
      if (!staffDetaObject?.salary_from)
        errorVal.salaryFrom = "Please Select Salary From Date";
      if (!staffDetaObject?.salary_to)
        errorVal.salaryTo = "Please Select Salary To Date";
      setErrors({
        ...errorVal,
      });
    } else {
      if (userIdTobeUpdated) {
        axios
          .put(`${API_URL}user/salarydetails/update`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Salary updated successfully");
              setstaffDetaValue("");
              formRef.current.reset();
              handleTableData(AddStaffid);
              // handleClickOpenForm(data);
              setStaffDetaObject({
                ["salary_from"]: "",
                ["salary_to"]: "",
              });
              setErrors({
                salaryFrom: "",
                salaryTo: "",
                staffDesignation: "",
              });
              setUserIdToBeUpdated(null);

              setSelectedDesig(null);
              fetch_Salary_Details(AddStaffid);
            } else {
              toastr.error("Failed to update Salary");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}user/salarydetails/add`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Salary added Successfully");
              // handleClickOpenForm(data);
              setstaffDetaValue("");
              formRef.current.reset();
              handleTableData(AddStaffid);
              setStaffDetaObject({
                ["salary_from"]: "",
                ["salary_to"]: "",
              });
              setErrors({
                salaryFrom: "",
                salaryTo: "",
                staffDesignation: "",
              });

              setSelectedDesig(null);
              fetch_Salary_Details(AddStaffid);
            } else {
              toastr.error("Failed to add Salary");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };


  // Social Update Post
  const handleValidSubmitSocial = (item) => {
    if (selectedSocial === null) {
      let errorVal = errors;
      if (selectedSocial === null)
        errorVal.socialType = "Please Select Social Media Type";
      setErrors({
        ...errorVal,
      });
    } else {
      if (userIdTobeUpdated) {
        axios
          .put(`${API_URL}user/socialmedia/update`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Social Media updated successfully");
              setstaffDetaValue("");
              formRef.current.reset();
              // handleClickOpenForm(data);
              setStaffDetaObject({});
              setUserIdToBeUpdated(null);

              handleTableData();
              setSelectedSocial(null);
              fetch_Social_Details(AddStaffid);
            } else {
              toastr.error("Failed to update Social Media");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}user/socialmedia/add`, staffDetaObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Social Media added Successfully");
              // handleClickOpenForm(data);
              setstaffDetaValue("");
              formRef.current.reset();
              setStaffDetaObject({});

              handleTableData();
              setSelectedSocial(null);
              fetch_Social_Details(AddStaffid);
            } else {
              toastr.error("Failed to add Social Media");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const preUpdateBankDetails = (item) => {
    item.user_id = AddStaffid;
    item.bankdetails_id = item.bank_details._id;
    setUserIdToBeUpdated(item._id);
    setstaffDetaValue(item?.name);
    setStaffDetaObject(item);
  };

  const preUpdateLeaveDetails = (item) => {
    const {
      created_by,
      date,
      // leave_type,
      leave_from,
      leave_to,
      leave_type,
      status,
      time,
      total_no_days,
      up_date,
      up_time,
      updated_by,
      _id,
    } = item;
    let xyz = {
      leavedetails_id: _id,
      user_id: AddStaffid,
      leave_from: leave_from,
      leave_to: leave_to,
      leave_type: leave_type,
      total_no_days: total_no_days,
      leave_type: leave_type[0]._id,
    };

    // item.user_id = AddStaffid;
    // item.leavedetails_id = item._id;

    // item.total_no_days = item.total_no_days;
    // item.leave_type = item.leave_type;

    let Leave = item?.leave_type[0] || {};
    let LeaveType = Leave?.name;
    let Design = {
      label: LeaveType,
      value: LeaveType?._id,
    };
    setSelectedLeaveType(Design);

    setUserIdToBeUpdated(item._id);
    setstaffDetaValue(item?.name);
    setStaffDetaObject(xyz);
  };

  const preUpdateSalaryDetails = (item) => {
    const {
      basic_salary,

      created_by,

      date,

      designation,

      hourly_cost,

      hourly_rate,

      hourly_salary,

      salary_from,

      salary_to,

      status,

      time,

      updated_by,

      _id,
    } = item;

    let lmn = {
      user_id: AddStaffid,
      salarydetails_id: _id,
      salary_from: salary_from,
      salary_to: salary_to,
      hourly_cost: hourly_cost,
      hourly_rate: hourly_rate,
      hourly_salary: hourly_salary,
      basic_salary: basic_salary,
      designation: designation[0]._id,
    };

    // item.user_id = AddStaffid;
    // item.salarydetails_id = item.salary_details._id;

    let Desig = item?.designation[0] || {};
    let Design = {
      label: Desig?.name,
      value: Desig?._id,
    };
    setSelectedDesig(Design);

    setUserIdToBeUpdated(item._id);
    setstaffDetaValue(item?.name);
    setStaffDetaObject(lmn);
  };

  let preUpdateSocialDetails = (item) => {
    const {
      socialmedia_id,

      socialmedia_type,
      _id,
    } = item;
    let social = {
      user_id: AddStaffid,
      socialmediadetails_id: _id,
      socialmedia_type: socialmedia_type,
      socialmedia_id: socialmedia_id,
    };
    setUserIdToBeUpdated(item._id);
    setstaffDetaValue(item?.name);

    // item.user_id = AddStaffid;
    // item.socialmediadetails_id = item.social_media._id;

    // social_media.socialmedia_type
    // console.log(item?.social_media.socialmedia_type);
    let Type = item?.socialmedia_type ? item.socialmedia_type : "";

    let Types = {
      label: Type,
      // value: Type?._id,
    };
    setSelectedSocial(Types);
    setStaffDetaObject(social);
  };

  const BankData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "Dates",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "Times",
        sort: "asc",
        width: 200,
      },
      {
        label: "Bank",
        field: "bank_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Branch",
        field: "bank_branch",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Account",
      //   field: "bank_acc",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "IFSC",
      //   field: "bank_ifsc",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Added",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userActivityLog,
  };

  const LeaveData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "Dates",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Time",
      //   field: "Times",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "From",
        field: "Leave_From",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "Leave_To",
        sort: "asc",
        width: 200,
      },

      {
        label: "Type",
        field: "leave",
        sort: "asc",
        width: 200,
      },
      {
        label: "Days",
        field: "TotalNoOf_Daya",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Designation",
      //   field: "designation",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: leaveTable,
  };

  const SalaryData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "Dates",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "Times",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "Salary_From",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "Salary_to",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Hr.Cost",
      //   field: "hourly_cost",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Hr.Rate",
      //   field: "hourly_rate",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Hr.Salary",
      //   field: "hourly_salary",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Basic",
        field: "basic_salary",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Designation",
      //   field: "designation",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Added",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: salaryTable,
  };

  const SocialData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "Dates",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "Times",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "socialmedia_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID",
        field: "socialmedia_id",
        sort: "asc",
        width: 200,
      },

      {
        label: "Added",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: socialTable,
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };

  const handleChangeDate = (e) => {
    setStaffDetaObject({
      ...staffDetaObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      leaveFrom: "",
    });
  };
  const handleChangeDate2 = (e) => {
    setStaffDetaObject({
      ...staffDetaObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      leaveTo: "",  
    });
  };


  const handleChangeDate1 = (e) => {
    setStaffDetaObject({
      ...staffDetaObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      salaryTo: "",
    });

  };
  const handleChangeDate3 = (e) => {
    setStaffDetaObject({
      ...staffDetaObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      salaryFrom: "",
    });
  };


  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            setUserIdToBeUpdated(null);
            closeModal();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  const customFieldStyle = { lineHeight: "1.7rem" };
  return (
    <>
      <React.Fragment>
        {/* Delete Bank */}
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}user/delete/bankAccount?user_id=` +
                  AddStaffid +
                  "&bankdetails_id=" +
                  staffDetaIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Bank deleted successfully");

                    if (
                      staffDetaObject &&
                      staffDetaObject._id === staffDetaIdToBeDeleted
                    ) {
                      setstaffDetaValue("");
                      formRef.current.reset();
                      setStaffDetaObject({});
                      setUserIdToBeUpdated(null);
                    }
                    handleTableData();
                    fetch_Bank_Details(AddStaffid);
                  } else {
                    toastr.error(res.data.message, "Failed to delete Bank");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}

        {/* Delete Leave */}

        {confirmLeaveDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}user/delete/leavedetails?user_id=` +
                  AddStaffid +
                  "&leavedetails_id=" +
                  staffLeaveIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Leave deleted successfully");

                    if (
                      staffDetaObject &&
                      staffDetaObject._id === staffLeaveIdToBeDeleted
                    ) {
                      // setConfirmLeaveDeleteAlert(false)
                      setstaffDetaValue("");
                      formRef.current.reset();
                      setStaffDetaObject({});
                      setUserIdToBeUpdated(null);
                    }
                    handleTableData();
                    fetch_Leave_Details(AddStaffid);
                  } else {
                    toastr.error(res.data.message, "Failed to delete Leave");
                    return;
                  }
                });
              setConfirmLeaveDeleteAlert(false);
            }}
            onCancel={() => setConfirmLeaveDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}

        {/* Delete Salary */}

        {confirmSalaryDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}user/delete/salarydetails?user_id=` +
                  AddStaffid +
                  "&salarydetails_id=" +
                  staffSalaryIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Salary deleted successfully");

                    if (
                      staffDetaObject &&
                      staffDetaObject._id === staffSalaryIdToBeDeleted
                    ) {
                      setstaffDetaValue("");
                      formRef.current.reset();
                      setStaffDetaObject({});
                      setUserIdToBeUpdated(null);
                    }
                    handleTableData();
                    fetch_Salary_Details(AddStaffid);
                  } else {
                    toastr.error(res.data.message, "Failed to delete Salary");
                    return;
                  }
                });
              setConfirmSalaryDeleteAlert(false);
            }}
            onCancel={() => setConfirmSalaryDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}

        {/* Delete Social */}

        {confirmSocialDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}user/delete/socialmedia?user_id=` +
                  AddStaffid +
                  "&socialmediadetails_id=" +
                  staffSocialIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Social deleted successfully");

                    if (
                      staffDetaObject &&
                      staffDetaObject._id === staffSocialIdToBeDeleted
                    ) {
                      setstaffDetaValue("");
                      formRef.current.reset();
                      setStaffDetaObject({});
                      setUserIdToBeUpdated(null);
                    }
                    handleTableData();
                    fetch_Social_Details(AddStaffid);
                  } else {
                    toastr.error(res.data.message, "Failed to delete Social");
                    return;
                  }
                });
              setConfirmSocialDeleteAlert(false);
            }}
            onCancel={() => setConfirmSocialDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}

        {/* Salary popup */}
        <Modal show={popupView} size="lg" centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Salary Details </h5>
            <button
              type="button"
              onClick={() => {
                setPopupView(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px" }}>Date :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Date}</td>
                  <td style={{ width: "184px", padding: "10px" }}>Time :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Times}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Salary from :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.Salary_From}
                  </td>
                  <td style={{ width: "184px", padding: "10px" }}>
                    Salary To :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.Salary_to}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Hr. Cost :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.hrCost}</td>
                  <td style={{ width: "184px", padding: "10px" }}>
                    Hr. Rate :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.hrRate}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Hr. Salary :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.hrSalary}</td>
                  <td style={{ width: "184px", padding: "10px" }}>
                    Basic Salary :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.Basic}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Designation :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Desig}</td>
                  <td style={{ padding: "10px" }}>Created By :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.staff1}</td>
                </tr>

                <tr>
                  <td
                    style={{ padding: "10px" }}
                    hidden={!popupData?.UpDatesTimes}
                  >
                    Updated Date & Time :
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.UpDatesTimes}
                  </td>

                  <td
                    style={{ width: "184px", padding: "10px" }}
                    hidden={!popupData?.staff2}
                  >
                    Updated By :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.staff2}</td>
                </tr>
                {/* </div> */}
              </table>
            </div>
          </AvForm>
        </Modal>
{/* Bank popup */}
<Modal show={popupBankView} size="lg" centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Bank Details </h5>
            <button
              type="button"
              onClick={() => {
                setPopupBankView(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm
            ref={formRef}
            className="needs-validation"
            onValidSubmit={(e, v) => {}}
          >
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px" }}>Date :</td>
                  <td style={{ textAlign: "left" }}>{popupBankData?.Date}</td>
                  <td style={{ width: "184px", padding: "10px" }}>Time :</td>
                  <td style={{ textAlign: "left" }}>{popupBankData?.Times}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Bank Name :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupBankData?.bank_name}
                  </td>
                  <td style={{ width: "184px", padding: "10px" }}>
                    Bank Account :
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {popupBankData?.bank_acc}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Bank IFSC :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupBankData?.bank_ifsc}
                  </td>
                  <td style={{ padding: "10px" }}>Bank Branch :</td>
                  <td style={{ textAlign: "left" }}>{popupBankData?.bank_branch}</td>
                </tr>
                <tr>
                <td style={{ padding: "10px" }}>Created By :</td>
                  <td style={{ textAlign: "left" }}>{popupBankData?.staff1}</td>
                  <td style={{ padding: "10px" }} hidden={!popupBankData?.Update}>Updated Date :</td>
                  <td style={{ textAlign: "left" }} >{popupBankData?.Update}</td>
                </tr>
                <tr>
                  <td
                    style={{ padding: "10px" }}
                    hidden={!popupBankData?.UpTime}
                  >
                    Updated Time :
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {popupBankData?.UpTime}
                  </td>

                  <td
                    style={{ width: "184px", padding: "10px" }}
                    hidden={!popupBankData?.staff2}
                  >
                    Updated By :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupBankData?.staff2}</td>
                </tr>

                {/* </div> */}
              </table>
            </div>
          </AvForm>
        </Modal>

        <Modal
          show={showModal}
          toggle={() => {
            closeModal();
          }}
          centered={true}
          size="md"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm
            className="needs-validation"
            onValidSubmit={() => {
              handleValidSubmitPassword();
            }}
          >
            <div className="modal-body">
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Password</Label>
                    <AvField
                      name="password"
                      placeholder="Password"
                      type="password"
                      errorMessage=" Please provide a valid password"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom05"
                      value={passwordObject.password}
                      onChange={handleChangePassword}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Confirm Password</Label>
                    <AvField
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      errorMessage="Please confirm the password"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom05"
                      value={passwordObject.confirmPassword}
                      onChange={handleChangePassword}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-dark"
                style={{ marginRight: "1rem" }}
                onClick={closeModal}
              >
                Close
              </button>
              <button className="btn btn-primary" type="submit">
                Confirm
              </button>
            </div>
          </AvForm>
        </Modal>

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Home"
              breadcrumbItem={
                routeName === "user" || routeName === "profile"
                  ? "User" + " " + "-" + " " + details?.userId
                  : "Staff" + " " + "-" + " " + details?.userId
              }
            />
            <Row>
              <Col xl="12">
                {/* First Dialogue */}

                <div>
                  <div className="row">
                    <div className="col-xl-3">
                      <div
                        className="card"
                        style={{
                          // width: "fit-content",
                          // width: "351px",
                          height: "max-content",
                          boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        }}
                      >
                        <div className="card-body">
                          <div className="float-end dropdown">
                            <a
                              aria-haspopup="true"
                              className="text-body font-size-16 ddropdown-toggle"
                              aria-expanded="false"
                            >
                              {/* </OverlayTrigger> */}
                            </a>
                            <div
                              tabindex="-1"
                              role="menu"
                              aria-hidden="true"
                              className="dropdown-menu-end dropdown-menu"
                            >
                              <a
                                to="/"
                                tabindex="0"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Edit
                              </a>
                              <a
                                to="/"
                                tabindex="1"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Action
                              </a>
                              <a
                                to="/"
                                tabindex="2"
                                role="menuitem"
                                className="dropdown-item"
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                          {/* Customer Details Tab start */}

                          {details?.user_image ? (
                            <Stack
                              direction="row"
                              spacing={2}
                              style={{ justifyContent: "center" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={`${API_URL}uploads/user_images/${details?.user_image}`}
                                sx={{ width: 56, height: 56 }}
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  marginBottom: "auto",
                                }}
                              />
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              spacing={2}
                              style={{ justifyContent: "center" }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={defaultProfile}
                                sx={{ width: 56, height: 56 }}
                                style={{
                                  width: "6rem",
                                  height: "6rem",
                                  marginBottom: "auto",
                                }}
                              />
                            </Stack>
                          )}
                          <i
                            className="fas fa-key"
                            style={{
                              fontSize: "1em",
                              position: "relative",
                              float: "right",
                              bottom: "83px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowModal(true);
                              setUserIdToBeUpdated(details?._id);
                            }}
                          ></i>
                          <h5
                            className="mt-3 mb-1"
                            style={{
                              textAlign: "center",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            {details?.firstName} {details?.lastName}
                          </h5>
                          <div
                            className="text-muted"
                            style={{
                              textAlign: "center",
                              color: "green !important",
                              fontFamily: "IBM Plex Sans,sans-serif",
                              fontSize: "14px",
                              marginBottom: "3px",
                            }}
                          >
                            {details?.mobile}
                          </div>
                          <div
                            className="text-muted"
                            style={{
                              textAlign: "center",
                              color: "green !important",
                              fontFamily: "IBM Plex Sans,sans-serif",
                              fontSize: "14px",
                              marginBottom: "3px",
                            }}
                          >
                            {details?.designation}
                          </div>

                          <p
                            className="text-muted"
                            style={{
                              textAlign: "center",
                              color: "green !important",
                              fontFamily: "IBM Plex Sans,sans-serif",
                              fontSize: "15px",
                              marginBottom: "0",
                            }}
                          >
                            {/* {details?.domain_name} */}
                          </p>

                          <div
                            className="mt-1 mb-2"
                            style={{
                              textAlign: "center",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          ></div>
                          <Divider />
                          <div className="mt-2" hidden={!details?.branch}>
                            <p className="mb-1">Branch:</p>
                            <h5 className="font-size-14">{details?.branch}</h5>
                          </div>
                          <div className="mt-2" hidden={!details?.userId}>
                            <p className="mb-1">Staff ID:</p>
                            <h5 className="font-size-14">{details?.userId}</h5>
                          </div>
                          <div className="mt-2" hidden={!details?.userId}>
                            <p className="mb-1">DOB:</p>
                            <h5 className="font-size-14">{details?.user_dob?moment(details.user_dob).format("DD-MM-YYYY"):""}</h5>
                          </div>
                          <div className="mt-2" hidden={!details?.userId}>
                            <p className="mb-1">GENDER:</p>
                            <h5 className="font-size-14">{details?.sex}</h5>
                          </div>
                          
                          <div className="mt-2" hidden={!details?.privilage}>
                            <p className="mb-1">Staff Type:</p>
                            <h5 className="font-size-14">
                              {details?.privilage}
                            </h5>
                          </div>
                          <div className="mt-2" hidden={!details?.email}>
                            <p className="mb-1">Personal Email:</p>
                            <h5 className="font-size-14">{details?.email}</h5>
                          </div>
                          <div className="mt-2" hidden={!details?.designation}>
                            <p className="mb-1">Designation:</p>
                            <h5 className="font-size-14">
                              {details?.designation}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={!details?.user_joining_date}
                          >
                            <p className="mb-1">Join Date:</p>
                            <h5 className="font-size-14">
                            {details?.user_joining_date?moment(details.user_joining_date).format("DD-MM-YYYY"):""}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={!details?.user_resigning_date}
                          >
                            <p className="mb-1">Resign Date:</p>
                            <h5 className="font-size-14">
                            {details?.user_resigning_date?moment(details.user_resigning_date).format("DD-MM-YYYY"):""}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={!details?.working_status}
                          >
                            <p className="mb-1">Staff Status:</p>
                            <h5 className="font-size-14">
                              {details?.working_status}
                            </h5>
                          </div>

                          <div className="mt-2">
                            <p className="mb-1">User Type:</p>
                            {details?.user_dob === 1 ? (
                              <h5 className="font-size-14">{"App"}</h5>
                            ) : (
                              <h5 className="font-size-14">{"CRM"}</h5>
                            )}
                          </div>
                          <div
                            className="mt-2"
                            hidden={details?.app_user !== 1}
                          >
                            <p className="mb-1">Language:</p>
                            <h5 className="font-size-14">
                              {details?.app_language === 1
                                ? "English"
                                : "Malayalam"}
                            </h5>
                          </div>
                          <div
                            className="mt-2"
                            hidden={
                              details?.app_user !== 1 || !details?.app_version
                            }
                          >
                            <p className="mb-1">Version:</p>
                            <h5 className="font-size-14">
                              {details?.app_version}
                            </h5>
                          </div>
                          <div hidden={!details?.user_documents}>
                            <p className="mb-1">Documents:</p>
                            <img
                              style={{ height: "162px" }}
                              src={`${API_URL}uploads/user_images/${details?.user_documents}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                      <div
                        className="mb-0 card"
                        style={{
                          boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                          width: "100%",

                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <TabContext value={tab3}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            
                            >
                              <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleChangeTab}
                                aria-label="lab API tabs example"
                             
                              >
                                <Tab
                                  icon={
                                    <DashboardIcon
                                      style={{ fontSize: "20px"}}
                                    />
                                  }
                                  label="Profile"
                                  value="1"
                                  size="small"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    marginTop:"5px",
                                    marginBottom:"10px"
                                  }}
                                />
                                <Tab
                                  icon={
                                    <AccountBalanceIcon
                                    style={{ fontSize: "20px"}}
                                    />
                                  }
                                  label="Bank"
                                  value="2"
                                  size="small"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    marginTop:"5px",
                                    marginBottom:"10px"
                                  }}
                                />
                                <Tab
                                  icon={
                                    <TransferWithinAStationIcon
                                    style={{ fontSize: "20px"}}
                                    />
                                  }
                                  label="Leave"
                                  value="3"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    marginTop:"5px",
                                    marginBottom:"10px"
                                  }}
                                />
                                <Tab
                                  icon={
                                    <CreditCardIcon
                                    style={{ fontSize: "20px"}}
                                    />
                                  }
                                  label="Salary"
                                  value="4"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    marginTop:"5px",
                                    marginBottom:"10px"
                                  }}
                                />
                                <Tab
                                  icon={
                                    <ConnectWithoutContactIcon
                                    style={{ fontSize: "20px"}}
                                    />
                                  }
                                  label="Social Media"
                                  value="5"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                    marginTop:"5px",
                                    marginBottom:"10px"
                                  }}
                                />
                              </TabList>
                            </Box>
                            <TabPanel value="1">
                              <Row>
                                {/* //<Col xl="12"  style={{ fontFamily: "IBM Plex Sans,sans-serif"}}> */}
                                <CardBody style={{ fontFamily: "IBM Plex Sans,sans-serif" }} >
                                  <Row>
                                    {/* <h4>Personal Details</h4> */}
                                    <Col md="6">
                                      <div className="mb-3">
                                        <h5>Personal Details</h5>
                                        <table
                                          id="product_available_day"
                                          className="table table-bordered dataTable"
                                        >
                                          <tr>
                                            <td style={{ padding: "10px" }}>
                                              Name:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {" "}
                                              {details?.firstName}{" "}
                                              {details?.lastName}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.email}>
                                            <td style={{ padding: "10px" }} >
                                              Personal Email:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.email}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.sex}>
                                            <td style={{ padding: "10px" }} >
                                              Gender:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.sex}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.user_dob}>
                                            <td style={{ padding: "10px" }}  >
                                              DOB:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.user_dob}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.quali}>
                                            <td style={{ padding: "10px" }} >
                                              Qualification:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.quali}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.experience}>
                                            <td style={{ padding: "10px" }}>
                                              Experience:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.experience}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.blood_gp}>
                                            <td style={{ padding: "10px" }}  >
                                              Blood Group:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.blood_gp}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.marital_status}>
                                            <td style={{ padding: "10px" }}  >
                                              Status:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.marital_status}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </Col>

                                    {/* <h4>Personal Details</h4> */}
                                    <Col md="6">
                                      <div className="mb-3">
                                        <h5>Contact Details</h5>
                                        <table
                                          id="product_available_day"
                                          className="table table-bordered dataTable"
                                        >
                                          <tr hidden={!details?.land_no}>
                                            <td style={{ padding: "10px" }} >
                                              Landline:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.land_no}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.mobile}>
                                            <td style={{ padding: "10px" }} >
                                              Personal Mobile:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.mobile}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.mob_2}>
                                            <td style={{ padding: "10px" }} >
                                              Guardian Mobile:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.mob_2}
                                            </td>
                                          </tr>
                                          <tr hidden={!details?.email_2}>
                                            <td style={{ padding: "10px" }} >
                                              Company Email:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.email_2}
                                            </td>
                                          </tr>

                                          {/* <tr>
                                              <td style={{ padding: "10px" }}>
                                                Facebook:
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {details?.facebook_id}
                                              </td>
                                            </tr> */}
                                          {/* <tr>
                                              <td style={{ padding: "10px" }}>
                                                Twitter:
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {details?.twitter_id}
                                              </td>
                                            </tr> */}
                                          {/* <tr>
                                              <td style={{ padding: "10px" }}>
                                                Linkedin:
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {details?.linkdln_id}
                                              </td>
                                            </tr> */}
                                          <tr hidden={!details?.address}>
                                            <td style={{ padding: "10px" }}  >
                                              Address:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.address}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    {/* <h4>Personal Details</h4> */}
                                    <Col md="12">
                                      <div className="mb-3">
                                        <h5>Staff Details</h5>
                                        <table
                                          id="product_available_day"
                                          className="table table-bordered dataTable"
                                        >
                                          <tr>
                                            <td style={{ padding: "10px" }} >
                                              Branch:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.branch}
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              Staff Type:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.privilage}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td style={{ padding: "10px" }}  >
                                              Staff Designation:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.designation}
                                            </td>
                                            <td style={{ padding: "10px" }} >
                                              Join Date:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                            {details?.user_joining_date?moment(details.user_joining_date).format("DD-MM-YYYY"):""}
                                            </td>
                                          </tr>

                                          <tr>

                                            <td style={{ padding: "10px" }} >
                                              Working From:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.working_frm}
                                            </td>
                                            <td style={{ padding: "10px" }}>
                                              Working To:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.working_to}
                                            </td>
                                          </tr>

                                          <tr>

                                            <td style={{ padding: "10px" }} >
                                              Machine ID:
                                            </td>
                                            <td style={{ textAlign: "left" }} >
                                              {details?.machine_id}
                                            </td>
                                            <td style={{ padding: "10px" }} >
                                              Swipe Card ID:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                              {details?.swipe_card}
                                            </td>
                                          </tr>

                                          <tr>

                                          <td style={{ padding: "10px" }} >
                                            Primary Reporting :
                                          </td>
                                          <td style={{ textAlign: "left" }} >
                                            {details?.prime}
                                          </td>
                                          <td style={{ padding: "10px" }} >
                                           Secondary Reporting:
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {details?.sec}
                                          </td>
                                          </tr>

                                          <tr hidden={!details?.user_resigning_date}>

                                            <td style={{ padding: "10px" }}>
                                              Resign Date:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                            {details?.user_resigning_date?moment(details.user_resigning_date).format("DD-MM-YYYY"):""}
                                            </td>
                                          </tr>
                                        </table>

                                        <table
                                          id="product_available_day"
                                          className="table table-bordered dataTable"
                                        >
                                          <tr hidden={!details?.browserToken}>
                                            <td style={{ padding: "10px" }}>
                                              Browser Token:
                                            </td>

                                            <td
                                              scope="row"
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              {details?.browserToken}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </Col>

                                    {/* <h4>Personal Details</h4> */}
                                  </Row>
                                </CardBody>
                                {/* //</Col> */}
                              </Row>
                            </TabPanel>
                            <TabPanel value="2">
                              <Row>
                                <Col className="col-12" style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                                  {/* <CardBody> */}
                                  <Row>
                                    <Col xl="12">
                                      <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                          handleValidSubmit(e, v);
                                        }}
                                      >
                                        <Row>
                                          <Col md="3" >
                                            <div className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                Bank Name
                                              </Label>
                                              <AvField
                                                style={customFieldStyle}
                                                name="bank_name"
                                                placeholder="Bank Name"
                                                type="text"
                                                errorMessage="Enter Bank Name"
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                id="validationCustom03"
                                                value={
                                                  staffDetaObject?.bank_name
                                                }
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <div className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                Branch Name
                                              </Label>
                                              <AvField
                                                style={customFieldStyle}
                                                name="bank_branch"
                                                placeholder="Branch Name"
                                                type="text"
                                                errorMessage="Enter Branch Name"
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                id="validationCustom03"
                                                value={
                                                  staffDetaObject?.bank_branch
                                                }
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <div className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                Account No.
                                              </Label>
                                              <AvField
                                                style={customFieldStyle}
                                                name="bank_acc"
                                                placeholder="Account No."
                                                type="number"
                                                errorMessage="Enter Account No."
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                id="validationCustom03"
                                                value={
                                                  staffDetaObject?.bank_acc
                                                }
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <div className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                Bank IFSC
                                              </Label>
                                              <AvField
                                                style={customFieldStyle}
                                                name="bank_ifsc"
                                                placeholder="Bank IFSC"
                                                type="text"
                                                errorMessage="Enter Bank IFSC"
                                                className="form-control"
                                                validate={{
                                                  required: {
                                                    value: true,
                                                  },
                                                }}
                                                id="validationCustom03"
                                                value={
                                                  staffDetaObject?.bank_ifsc
                                                }
                                                onChange={handleInputChange}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <div
                                            // className="mb-2"
                                            // style={{ paddingTop: "25px" }}
                                            >
                                              {userIdTobeUpdated ? (
                                                <Button
                                                  color="primary"
                                                  type="submit"
                                                  style={{
                                                    marginRight: "5%",
                                                  }}
                                                  disabled={
                                                    addingUser ? true : false
                                                  }
                                                >
                                                  {addingUser
                                                    ? "Updating"
                                                    : "Update"}
                                                </Button>
                                              ) : (
                                                <Button
                                                  color="primary"
                                                  type="submit"
                                                  style={{
                                                    marginRight: "5%",
                                                  }}
                                                  disabled={
                                                    addingUser ? true : false
                                                  }
                                                >
                                                  {addingUser
                                                    ? "Adding"
                                                    : "Submit"}
                                                </Button>
                                              )}
                                              <Button
                                                color="danger"
                                                type="reset"
                                                onClick={() => reset()}
                                              >
                                                {"  "}
                                                Reset
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                      <div className="mt-3">
                                        <MDBDataTable
                                          id="staffBankTableID"
                                          responsive
                                          bordered
                                          data={BankData}
                                          searching={true}
                                          info={true}
                                          disableRetreatAfterSorting={true}
                                          entries={20}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* </CardBody> */}
                                  {/* </CardBody> */}
                                </Col>
                              </Row>
                            </TabPanel>
                            <TabPanel value="3">
                              <Row>
                                <Col className="col-12" style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                                  {/* <CardBody> */}
                                  <Row>
                                    {/* <div className="mt-3"> */}
                                    <AvForm
                                      ref={formRef}
                                      className="needs-validation"
                                      onValidSubmit={(e, v) => {
                                        handleValidSubmitLeave(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="validationCustom05"
                                              style={{
                                                marginTop: "auto",
                                              }}
                                            >
                                              Leave From
                                            </Label>
                                            <input
                                              style={customFieldStyle}
                                              className="form-control"
                                              type="date"
                                              id="income_expense_date"
                                              name="leave_from"
                                              value={
                                                staffDetaObject?.leave_from
                                              }
                                              onChange={handleChangeDate}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedLeaveFrom === null
                                                ? errors.leaveFrom
                                                : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="validationCustom05"
                                              style={{
                                                marginTop: "auto",
                                              }}
                                            >
                                              Leave To
                                            </Label>
                                            <input
                                              style={customFieldStyle}
                                              className="form-control"
                                              type="date"
                                              id="income_expense_date"
                                              name="leave_to"
                                              value={
                                                staffDetaObject?.leave_to
                                              }
                                              onChange={handleChangeDate2}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedLeaveTo === null ? errors.leaveTo : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label>Leave Type</Label>
                                            <Select
                                              // style={customFieldStyle}
                                              name="LeaveType"
                                              value={selectedLeaveType}
                                              onChange={(selected) => {
                                                handleSelectChange(
                                                  selected,
                                                  "LeaveType"
                                                );
                                              }}
                                              size="small"
                                              options={leaveType}
                                              classNamePrefix="select2-selection"
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedLeaveType === null
                                                ? errors.leaveType
                                                : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Total No. Of Days
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="total_no_days"
                                              placeholder="Total No. Days"
                                              type="number"
                                              errorMessage="Enter Days"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.total_no_days
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>
                                        {/* <Col md="3">
                                              <div className="mb-3">
                                                <Label>Staff Designation</Label>
                                                <Select
                                                  name="Desigg"
                                                  value={selectedDesig}
                                                  onChange={(selected) => {
                                                    handleSelectChange(
                                                      selected,
                                                      "Desigg"
                                                    );
                                                  }}
                                                  options={designation}
                                                  classNamePrefix="select2-selection"
                                                />
                                              </div>
                                            </Col> */}
                                        <Col md="3">
                                          <div
                                            // className="mb-2"
                                            // style={{ paddingTop: "25px" }}
                                            style={{ paddingBottom: "14px" }}
                                          >
                                            {userIdTobeUpdated ? (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Updating"
                                                  : "Update"}
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Adding"
                                                  : "Submit"}
                                              </Button>
                                            )}
                                            <Button
                                              color="danger"
                                              type="reset"
                                              onClick={() => reset()}
                                            >
                                              {"  "}
                                              Reset
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <MDBDataTable
                                      id="staffBankTableID"
                                      responsive
                                      bordered
                                      data={LeaveData}
                                      searching={true}
                                      info={true}
                                      disableRetreatAfterSorting={true}
                                      entries={20}
                                    />
                                    {/* </div> */}
                                  </Row>
                                  {/* </CardBody> */}
                                </Col>
                              </Row>
                            </TabPanel>
                            <TabPanel value="4">
                              <Row>
                                <Col className="col-12" style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                                  {/* <CardBody> */}
                                  <Row>
                                    {/* <div className="mt-3"> */}
                                    <AvForm
                                      ref={formRef}
                                      className="needs-validation"
                                      onValidSubmit={(e, v) => {
                                        handleValidSubmitSalary(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="validationCustom05"
                                              style={{
                                                marginTop: "auto",
                                              }}
                                            >
                                              Salary From
                                            </Label>
                                            <input
                                              style={customFieldStyle}
                                              className="form-control"
                                              type="date"
                                              id="income_expense_date"
                                              name="salary_from"
                                              value={
                                                staffDetaObject?.salary_from
                                              }
                                              onChange={handleChangeDate3}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedSalaryFrom === null
                                                ? errors.salaryFrom
                                                : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="validationCustom05"
                                              style={{
                                                marginTop: "auto",
                                              }}
                                            >
                                              Salary To
                                            </Label>
                                            <input
                                              style={customFieldStyle}
                                              className="form-control"
                                              type="date"
                                              id="income_expense_date"
                                              name="salary_to"
                                              value={
                                                staffDetaObject?.salary_to
                                              }
                                              onChange={handleChangeDate1}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedSalaryTo === null
                                                ? errors.salaryTo
                                                : ""}
                                            </p>
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Hourly Cost
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="hourly_cost"
                                              placeholder="Hourly Cost"
                                              type="number"
                                              errorMessage="Enter Hourly Cost"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.hourly_cost
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Hourly Rate
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="hourly_rate"
                                              placeholder="Hourly Rate"
                                              type="number"
                                              errorMessage="Enter Hourly Rate"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.hourly_rate
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Hourly Salary
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="hourly_salary"
                                              placeholder="Hourly Salary"
                                              type="number"
                                              errorMessage="Enter Hourly Salary"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.hourly_salary
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Basic Salary
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="basic_salary"
                                              placeholder="Basic Salary"
                                              type="number"
                                              errorMessage="Enter Basic Salary"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.basic_salary
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label>Staff Designation</Label>
                                            <Select
                                              name="Desigg"
                                              value={selectedDesig}
                                              onChange={(selected) => {
                                                handleSelectChange(
                                                  selected,
                                                  "Desigg"
                                                );
                                              }}
                                              options={designation}
                                              classNamePrefix="select2-selection"
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div
                                            className="mb-2"
                                            style={{ paddingTop: "25px" }}
                                          >
                                            {userIdTobeUpdated ? (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Updating"
                                                  : "Update"}
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Adding"
                                                  : "Submit"}
                                              </Button>
                                            )}
                                            <Button
                                              color="danger"
                                              type="reset"
                                              onClick={() => reset()}
                                            >
                                              {"  "}
                                              Reset
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <MDBDataTable
                                      id="staffSalaryTableID"
                                      responsive
                                      bordered
                                      data={SalaryData}
                                      searching={true}
                                      info={true}
                                      disableRetreatAfterSorting={true}
                                      entries={20}
                                    />
                                    {/* </div> */}
                                  </Row>
                                  {/* </CardBody> */}
                                </Col>
                              </Row>
                            </TabPanel>
                            <TabPanel value="5">
                              <Row>
                                <Col className="col-12" style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                                  {/* <CardBody> */}
                                  <Row>
                                    {/* <div className="mt-3"> */}
                                    <AvForm
                                      ref={formRef}
                                      className="needs-validation"
                                      onValidSubmit={(e, v) => {
                                        handleValidSubmitSocial(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label>Social Media</Label>
                                            <Select
                                              name="Social"
                                              value={selectedSocial}
                                              onChange={(selected) => {
                                                handleSelectChange(
                                                  selected,
                                                  "Social"
                                                );
                                              }}
                                              options={[
                                                {
                                                  label: "Facebook",
                                                  value: 0,
                                                },
                                                {
                                                  label: "Twitter",
                                                  value: 1,
                                                },

                                                {
                                                  label: "LinkedIn",
                                                  value: 2,
                                                },
                                                {
                                                  label: "Instagram",
                                                  value: 3,
                                                },
                                              ]}
                                              classNamePrefix="select2-selection"
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedSocial === null
                                                ? errors.socialType
                                                : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom03">
                                              Social Media ID
                                            </Label>
                                            <AvField
                                              style={customFieldStyle}
                                              name="socialmedia_id"
                                              placeholder="Social Media ID"
                                              type="text"
                                              errorMessage="Enter Social Media ID"
                                              className="form-control"
                                              validate={{
                                                required: {
                                                  value: true,
                                                },
                                              }}
                                              id="validationCustom03"
                                              value={
                                                staffDetaObject?.socialmedia_id
                                              }
                                              onChange={handleInputChange}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div
                                            className="mb-2"
                                            style={{ paddingTop: "25px" }}
                                          >
                                            {userIdTobeUpdated ? (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Updating"
                                                  : "Update"}
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                type="submit"
                                                style={{
                                                  marginRight: "5%",
                                                }}
                                                disabled={
                                                  addingUser ? true : false
                                                }
                                              >
                                                {addingUser
                                                  ? "Adding"
                                                  : "Submit"}
                                              </Button>
                                            )}
                                            <Button
                                              color="danger"
                                              type="reset"
                                              onClick={() => reset()}
                                            >
                                              {"  "}
                                              Reset
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <MDBDataTable
                                      id="staffBankTableID"
                                      responsive
                                      bordered
                                      data={SocialData}
                                      searching={true}
                                      info={true}
                                      disableRetreatAfterSorting={true}
                                      entries={20}
                                    />
                                    {/* </div> */}
                                  </Row>
                                  {/* </CardBody> */}
                                </Col>
                              </Row>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End */}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(StaffDetails));
