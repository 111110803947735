import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import InputLabel from "@mui/material/InputLabel";

import axios from "axios";
import toastr from "toastr";
import moment from "moment";

//Dialogue box content's
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { withRouter, useHistory } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

//Dialogue box table content's
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  getDateTime,
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import accessToken from "../../../helpers/jwt-token-access/accessToken";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./addStaff.scss";
import { Grid, Hidden } from "@mui/material";

//Dialogue table content's
function createData(name, calories, fat, carbs, protien) {
  return { name, calories, fat, carbs, protien };
}

const rows = [
  createData(
    "Check In Latitude:",
    11.9193786,
    "Check In Longitude :",
    75.4419379
  ),
  createData(
    "Check Out Date :",
    "08-11-2021",
    "Check Out Longitude:",
    75.4419379
  ),
  createData("Check Out Latitude :", 262, "Check Out Longitude :", 24),
  createData("Type :", "Automatic", "Staff : :", "SRV"),
  createData(
    "Status :",
    <InputLabel style={{ color: "green" }}>Check In</InputLabel>
  ),
];

const AddStaff = () => {
  const [staffObject, setStaffObject] = useState({
    marital_status: "Un-Married",
    sex: "Male",
    working_status: "Active",
  });
  const [staffIdTobeUpdated, setStaffIdToBeUpdated] = useState(null);
  const [staffIdToBeDeleted, setStaffIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [staffForTable, setStaffForTable] = useState([]);
  const [staffValue, setstaffValue] = useState("");
  const [uploadProgress, setUploadProgress] = useState();

  const [uploadProgresss, setUploadProgresss] = useState();

  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [searchBranch, setSearchBranch] = useState(null);
  const [selectedSearchBranch, setSelectedSearchBranch] = useState(null);

  const [searchDesig, setSearchDesig] = useState(null);
  const [selectedSearchDesig, setSelectedSearchDesig] = useState(null);

  const [searchStatus, setSearchStatus] = useState(null);
  const [selectedSearchStatus, setSelectedSearchStatus] = useState(null);

  const [qualification, setQualification] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState(null);

  const [staffType, setStaffType] = useState([]);
  const [selectedStaffType, setSelectedStaffType] = useState(null);

  const [selectedSex, setSelectedSex] = useState([{ label: "Male", value: 0 }]);

  const [selectedBlood, setSelectedBlood] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState([
    { label: "Active", value: 0 },
  ]);

  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([
    { label: "Un-Married", value: 1 },
  ]);

  const [designation, setDesignation] = useState(null);
  const [selectedDesig, setSelectedDesig] = useState(null);

  const [modules, setModules] = useState(null);
  const [selectedModules, setSelectedModules] = useState(null);

  const [searchData, setSearchData] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [selectedJoinDate, setSelectedJoinDate] = useState(null);
  const [selectedWorkingFrom, setSelectedWorkingFrom] = useState(null);
  const [selectedWorkingTo, setSelectedWorkingTo] = useState(null);

  const[selectedPrimaryStaff, setSelectedPrimaryStaff]=useState(null)
  const[selectedSecondaryStaff, setSelectedSecondaryStaff]=useState(null)
  const[staff,setStaff]=useState([])

  const [errors, setErrors] = useState({
    staffType: "",
    staffDesignation: "",
    staffModule: "",
    staffBranch: "",
    joinDate: "",
    workingFrom: "",
    workingTO: "",
    secondaryStaff: "",
    primaryStaff:""
  });

  const {
    districts,
    addingStaff,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  let history = useHistory();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_qualification();
    fetch_all_branch();
    fetch_all_StaffType();
    fetch_all_Designation();
    fetch_all_Modules();
    fetch_all_Searchbranch();
    fetch_all_Search_Designation();
    fetch_staff();
  }, []);
  function handleTableData(
    firstName = "",
    userId = "",
    branchid = "",
    desig_id = "",
    status = ""
  ) {
    var url =
      `${API_URL}user/?firstName=` +
      firstName +
      "&userId=" +
      userId +
      "&branchid=" +
      branchid +
      "&desig_id=" +
      desig_id +
      "&status=" +
      status;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let giftData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/add_staff/" + item._id);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateStaff(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setStaffIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.from = moment(item.from).format("YYYY-MM-DD");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }
          item.user_joining_date = moment(item.user_joining_date).format(
            "DD-MM-YYYY"
          );
          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          item.img = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={`${API_URL}uploads/gift_images/${item.image}`}
                height="50"
                width="50"
              />
            </div>
          );

          // let values = item?.created_by[0] || {};
          // // console.log("created by", values);
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updated_by[0]) {
            let values = item?.updated_by[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.created_by[0] || {};
            // console.log("Created By<<>>", values);
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          giftData.push(item);
        });
        setStaffForTable(giftData);
      });
  }

  function fetch_all_qualification() {
    axios
      .get(`${API_URL}qualification/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var QualiType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.qualification_name,
              value: el._id,
            };
          });
        setQualification([{ options: QualiType }]);
      });
  }

  function fetch_all_branch() {
    axios
      .get(`${API_URL}branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var BranchType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name + "-" + el.company.company_name,
              value: el._id,
            };
          });
        setBranch([{ options: BranchType }]);
      });
  }

  function fetch_all_Searchbranch() {
    axios
      .get(`${API_URL}branch/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var BranchType =
          res.data.data &&
          res.data.data.map((el) => {

            let Branch = el?.company || ({} && el?.name);
            el.branch1 = el?.name + "-" + Branch?.company_name;
            // console.log(el.branch1)
            return {
              label: el?.branch1,
              value: el?._id,
              displayLabel: el?.company_name,
            };
          });
        // console.log(BranchType)
        setSearchBranch([{ options: BranchType }]);
      });
  }

  function fetch_all_StaffType() {
    axios
      .get(`${API_URL}privilage/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var BranchType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });
        setStaffType([{ options: BranchType }]);
      });
  }

  function fetch_all_Designation() {
    axios
      .get(`${API_URL}designation/option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setDesignation([{ options: DesigType }]);
      });
  }

  function fetch_all_Search_Designation() {
    axios
      .get(`${API_URL}designation/option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var DesigType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setSearchDesig([{ options: DesigType }]);
      });
  }

  function fetch_all_Modules() {
    axios
      .get(`${API_URL}modules/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ModulesType =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.moduleName,
              value: el._id,
            };
          });
        setModules([{ options: ModulesType }]);
      });
  }

  function fetch_staff() {
    axios
      .get(`${API_URL}user/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
     
        var staff =
          res.data.data &&
          res.data.data.map((el) => {
           if(el.lastName)
            return {
              label: el?.firstName  + " " + el?.lastName ,
              value: el?._id,
            };
            else{
              return {
                label: el?.firstName ,
                value: el?._id,
              };
            }
          });
       
        setStaff([{ options: staff }]);
      });
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "Quali":
        setSelectedQualification(selected);
        setStaffObject({
          ...staffObject,
          quali: selected.value,
        });
        break;

      case "Branch":
        setSelectedBranch(selected);
        setStaffObject({
          ...staffObject,
          branch: selected.value,
        });
        setErrors({
          ...errors,
          staffBranch: "",
        });
        break;
      case "SEX":
        setSelectedSex(selected);
        setStaffObject({
          ...staffObject,
          sex: selected.label,
        });
        break;
      case "Blood":
        setSelectedBlood(selected);
        setStaffObject({
          ...staffObject,
          blood_gp: selected.label,
        });
        break;

      case "Status":
        setSelectedStatus(selected);
        setStaffObject({
          ...staffObject,
          working_status: selected.label,
        });
        break;
      case "MaritalStatus":
        setSelectedMaritalStatus(selected);
        setStaffObject({
          ...staffObject,
          marital_status: selected.label,
        });
        break;

      case "Type":
        setSelectedStaffType(selected);
        setStaffObject({
          ...staffObject,
          privilage: selected.value,
        });
        setErrors({
          ...errors,
          staffType: "",
        });
        break;
      case "Desigg":
        setSelectedDesig(selected);
        setStaffObject({
          ...staffObject,
          user_desig: selected.value,
        });
        setErrors({
          ...errors,
          staffDesignation: "",
        });
        break;
      case "Designation":
        setSelectedDesig(selected);
        setStaffObject({
          ...staffObject,
          desig: selected.label,
        });
        break;
      case "Modules":
        setSelectedModules(selected);
        setStaffObject({
          ...staffObject,
          modules: selected.value,
        });
        break;
        case "primary_staff":
          setSelectedPrimaryStaff(selected);
          setStaffObject({
            ...staffObject,
            primary_staff: selected.value,
          });
          setErrors({
            ...errors,
            primaryStaff: "",
          });
          break;
        case "secondary_staff":
          setSelectedSecondaryStaff(selected);
          setStaffObject({
            ...staffObject,
            secondary_staff: selected.value,
          });
          setErrors({
            ...errors,
            secondaryStaff: "",
          });
          break;
      default:
        break;
    }
  };

  const handleChangeDate = (e) => {
    setStaffObject({
      ...staffObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      joinDate: "",

    });
  };


  const handleChangeDate1 = (e) => {
    setStaffObject({
      ...staffObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      workingFrom: "",

    });
  };


  const handleChangeDate2 = (e) => {
    setStaffObject({
      ...staffObject,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      workingTO: "",
    });
  };

  let preUpdateStaff = (item) => {
    formRef.current.reset();
    setStaffIdToBeUpdated(item._id);
    setstaffValue(item?.name);

    axios
      .get(`${API_URL}user/single/?user_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let quali = result?.quali ? result.quali : {};
        let qualiValue = {
          label: quali?.qualification_name,
          value: quali?._id,
        };

        let Sex = result.sex;
        let SexValue = {
          label: Sex,
        };

        let Blood = result.blood_gp;
        let BloodValue = {
          label: Blood,
        };

        let Marital = result?.marital_status;
        let MaritalValue = {
          label: Marital,
        };

        let StaffBranch = result?.branch ? result.branch : {};
        let BranchValue = {
          label: StaffBranch?.name,
          value: StaffBranch?._id,
        };

        let StaffType = result?.privilage ? result.privilage : {};
        let StaffTypeValue = {
          label: StaffType?.privilege_name,
          value: StaffType?._d,
        };

        let Desig = result?.user_desig ? result.user_desig : {};
        let DesigValue = {
          label: Desig?.name,
          value: Desig?._id,
        };

        let StaffStatus = result?.working_status;
        let StaffStatusValue = {
          label: StaffStatus,
        };

        let Modules = result?.modules;
        let Moduless = {
          label: Modules?.moduleName,
          value: Modules?._id,
        };

        result.user_id = result?._id;

        setSelectedModules(Moduless);

        setSelectedStatus(StaffStatusValue);

        setSelectedDesig(DesigValue);

        setSelectedStaffType(StaffTypeValue);

        setSelectedBranch(BranchValue);

        setSelectedMaritalStatus(MaritalValue);

        setSelectedBlood(BloodValue);

        setSelectedSex(SexValue);

        setSelectedQualification(qualiValue);

        setStaffObject({ ...result, password: null });
      });
  };

  const [type, setType] = useState("");

  const [form, setForm] = useState(false);

  const handleCloseForm = () => {
    setForm(false);
  };

  function handleChangeLeads(e) {
    let name = e.target.name;
    let value = e.target.value;
    setstaffValue(value);
    setStaffObject({ ...staffObject, [name]: value });
  }


  function handleChangeLeads1(e) {
    let name = e.target.name;
    let value = e.target.value;
    let name1 = "machine_id";
    let name2 = "swipe_card";
    setstaffValue(value);
    setStaffObject({
      ...staffObject,
      [name]: value,
      [name1]: value,
      [name2]: value,
    });
  }

  function handleChangeStaff(e) {
    let name = e.target.name;
    let value = e.target.value;
    setstaffValue(value);

    setStaffObject({ ...staffObject, [name]: value });
  }

  const reset = () => {
    formRef.current.reset();
    setStaffObject({
      ["remarks"]: "",
    });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "fullname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "privilage",
        sort: "asc",
        width: 150,
      },
      {
        label: "Designation",
        field: "desig_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Join",
        field: "user_joining_date",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Qualification",
      //   field: "quali",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Email",
        field: "email_2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 270,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      },
    ],
    rows: staffForTable,
  };

  const customStyles1 = {
    borderColor: "#e5e5e5",
    height: "auto",
  };

  const handleValidSubmit = () => {
    if (
      selectedDesig === null ||
      selectedStaffType === null ||
      selectedBranch === null ||
      !staffObject.user_joining_date ||
      !staffObject.working_frm ||
      !staffObject.working_to ||
      selectedModules === null ||
      selectedPrimaryStaff === null ||
      selectedSecondaryStaff === null
    ) {
      let errorVal = errors;
      if (selectedStaffType === null)
        errorVal.staffType = "Please Select Staff Type";
      if (selectedDesig === null)
        errorVal.staffDesignation = "Please Select Staff Designation";
      if (selectedBranch === null)
        errorVal.staffBranch = "Please Select Staff Branch";
      if (!staffObject?.user_joining_date)
        errorVal.joinDate = "Please Select Joining Date";
      if (!staffObject.working_frm)
        errorVal.workingFrom = "Please Select Working From Time";
      if (!staffObject.working_to)
        errorVal.workingTO = "Please Select Working To";
      if (selectedModules === null)
        errorVal.staffModule = "Please Select Staff Default Module";
      if(selectedPrimaryStaff === null)
      errorVal.primaryStaff="Please Select Primary Staff";
      if(selectedSecondaryStaff === null)
      errorVal.secondaryStaff="Please Select Secondary Staff";
    
      setErrors({
        ...errorVal,
      });
    } else {

      if (staffIdTobeUpdated) {
        axios
          .put(`${API_URL}user/`, staffObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Staff updated successfully");
              setstaffValue("");
              formRef.current.reset();
              // handleClickOpenForm(data);
              setStaffObject({
                ["user_dob"]: "",
                ["user_joining_date"]: "",
                ["user_resigning_date"]: "",
                ["working_frm"]: "",
                ["working_to"]: "",
              });

              setErrors({
                staffType: "",
                staffDesignation: "",
                staffModule: "",
                staffBranch: "",
                joinDate: "",
                secondaryStaff: "",
                primaryStaff:""
              });
              setStaffIdToBeUpdated(null);
              setSelectedQualification(null);
              setSelectedSex(null);
              setSelectedBlood(null);
              setSelectedMaritalStatus(null);
              setSelectedBranch(null);
              setSelectedStaffType(null);
              setSelectedDesig(null);
              setSelectedModules(null);
              setSelectedStatus(null);

              handleTableData();
            } else {
              toastr.error("Failed to update Leads");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}user/`, staffObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Staff added Successfully");
              // handleClickOpenForm(data);
              setstaffValue("");
              formRef.current.reset();
              setStaffObject({
                ["user_dob"]: "",
                ["user_joining_date"]: "",
                ["user_resigning_date"]: "",
                ["working_frm"]: "",
                ["working_to"]: "",
              });

              setErrors({
                staffType: "",
                staffDesignation: "",
                staffModule: "",
                staffBranch: "",
                joinDate: "",
                secondaryStaff: "",
                primaryStaff:""
              });
              // setStaffIdToBeUpdated(null);
              setSelectedQualification(null);
              setSelectedSex(null);
              setSelectedBlood(null);
              setSelectedMaritalStatus(null);
              setSelectedBranch(null);
              setSelectedStaffType(null);
              setSelectedDesig(null);
              setSelectedModules(null);
              setSelectedStatus(null);
              handleTableData();
            } else {
              toastr.error("Failed to add Leads");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  // User image upload
  const uploadScreenshot = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setStaffObject({
            ...staffObject,
            [`user_image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteScreenshot = () => {
    setStaffObject({
      ...staffObject,
      [`user_image`]: "",
    });
  };

  // user documents upload
  const uploadDocument = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgresss(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setStaffObject({
            ...staffObject,
            [`user_documents`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const deleteDocument = () => {
    setStaffObject({
      ...staffObject,
      [`user_documents`]: "",
    });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const handleSelectedForSearch = (selected, name) => {
    switch (name) {
      case "BranchSearch":
        setSelectedSearchBranch(selected);
        setStaffObject({
          ...staffObject,
          branchid: selected.value,
        });
        break;
      case "DesignationSearch":
        setSelectedSearchDesig(selected);
        setStaffObject({
          ...staffObject,
          desig_id: selected.value,
        });
        break;
      case "StatusSearch":
        setSelectedSearchStatus(selected);
        setStaffObject({
          ...staffObject,
          status: selected.label,
        });
        break;
      default:
        break;
    }
  };

  const handleSearch = () => {
    let staffName = staffObject?.firstName ? staffObject.firstName : "";
    let staffID = staffObject?.userId ? staffObject.userId : "";
    let branchID = staffObject?.branchid ? staffObject.branchid : "";
    let desig = staffObject?.desig_id ? staffObject.desig_id : "";
    let status = staffObject?.status ? staffObject.status : "";

    handleTableData(staffName, staffID, branchID, desig, status);
  };

  const searchReset = () => {
    formRef.current.reset();
    setSelectedSearchBranch(null);
    setSelectedSearchDesig(null);
    setSelectedSearchStatus(null);
    handleTableData();
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}user` + "/" + staffIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Staff deleted successfully");

                    if (staffObject && staffObject._id === staffIdToBeDeleted) {
                      setstaffValue("");
                      formRef.current.reset();
                      setStaffObject({});
                      setStaffIdToBeUpdated(null);
                    }
                    handleTableData();
                  } else {
                    console.log("Error", res.data.message);
                    toastr.error(res.data.message, "Failed to delete Staff");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add Staff" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        {/* <h4>Personal Details</h4> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              First Name
                            </Label>
                            <AvField
                              name="firstName"
                              placeholder="First Name"
                              type="text"
                              errorMessage="Enter First Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.firstName}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Last Name
                            </Label>
                            <AvField
                              name="lastName"
                              placeholder="Last Name"
                              type="text"
                              className="form-control"
                              // errorMessage="Enter Last Name"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.lastName}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Username</Label>
                            <AvField
                              name="username"
                              placeholder="Username"
                              type="text"
                              className="form-control"
                              errorMessage="Enter Username"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.username}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        {staffIdTobeUpdated ? null : (
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Password
                              </Label>
                              <AvField
                                name="password"
                                placeholder="Password"
                                type="text"
                                className="form-control"
                                errorMessage="Enter Password"
                                validate={{ required: { value: true } }}
                                id="validationCustom03"
                                value={staffObject?.password}
                                onChange={handleChangeLeads}
                              />
                            </div>
                          </Col>
                        )}

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Qualification</Label>
                            <Select
                              name="Quali"
                              value={selectedQualification}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Quali");
                              }}
                              options={qualification}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">DOB</Label>
                            {/* <div className="col-md-10"> */}
                            <input
                              className="form-control"
                              type="date"
                              id="date"
                              name="user_dob"
                              value={staffObject?.user_dob}
                              onChange={handleChangeLeads}
                            />
                            {/* </div> */}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Gender</Label>
                            <Select
                              name="SEX"
                              value={selectedSex}
                              onChange={(selected) => {
                                handleSelectChange(selected, "SEX");
                              }}
                              // placeholder={"Male"}
                              options={[
                                { label: "Male", value: 0 },
                                { label: "Female", value: 1 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Blood Group</Label>
                            <Select
                              name="Blood"
                              value={selectedBlood}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Blood");
                              }}
                              options={[
                                { label: "A +ve", value: 0 },
                                { label: "A -ve", value: 1 },
                                { label: "B +ve", value: 2 },
                                { label: "B -ve", value: 3 },
                                { label: "AB +ve", value: 4 },
                                { label: "AB -ve", value: 5 },
                                { label: "O +ve", value: 6 },
                                { label: "O -ve", value: 7 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <Select
                              name="MaritalStatus"
                              value={selectedMaritalStatus}
                              onChange={(selected) => {
                                handleSelectChange(selected, "MaritalStatus");
                              }}
                              placeholder={"Un-Married"}
                              options={[
                                { label: "Married", value: 0 },
                                { label: "Un-Married", value: 1 },
                                // { label: "Resigned", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Experience
                            </Label>
                            <AvField
                              name="experience"
                              placeholder="Experience"
                              type="text"
                              className="form-control"
                              // errorMessage="Enter Experience"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.experience}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        {/* <h4>Contact Details</h4> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Personal Mobile
                            </Label>
                            <AvField
                              name="mobile"
                              placeholder="Personal Mobile"
                              type="text"
                              // minLength={10}
                              // maxLength={10}
                              // errorMessage="Enter Mobile Number"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Mobile number",
                                },
                                // minLength: {
                                //   value: 10,
                                //   errorMessage: "Enter valid 10 digit number",
                                // },
                                // maxLength: {
                                //   value: 10,
                                //   errorMessage: "Enter valid 10 digit number",
                                // },
                              }}
                              id="validationCustom03"
                              value={staffObject?.mobile}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Guardian Mobile</Label>
                            <AvField
                              name="mob_2"
                              placeholder="Guardian Mobile"
                              type="text"
                              // minLength={10}
                              // maxLength={10}
                              // errorMessage="Enter valid 10 digit number"
                              // validate={{
                              //   required: {
                              //     value: true,
                              //     errorMessage: "Enter Mobile number",
                              //   },
                              //   minLength: {
                              //     value: 10,
                              //     errorMessage: "Enter valid 10 digit number",
                              //   },
                              //   maxLength: {
                              //     value: 10,
                              //     errorMessage: "Enter valid 10 digit number",
                              //   },
                              // }}
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Mobile number",
                                },
                              }}
                              id="validationCustom03"
                              value={staffObject?.mob_2}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Land Line
                            </Label>
                            <AvField
                              name="land_no"
                              placeholder="Land Line No."
                              type="text"
                              className="form-control"
                              // errorMessage="Enter valid 11 digit number"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.land_no}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Personal Email</Label>
                            <AvField
                              name="email"
                              placeholder="Personal Email"
                              type="email"
                              className="form-control"
                              // errorMessage="Enter email"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.email}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Company Email</Label>
                            <AvField
                              name="email_2"
                              placeholder="Company Email"
                              type="email"
                              className="form-control"
                              // errorMessage="Enter email2"
                              // validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.email_2}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Facebook ID
                            </Label>
                            <AvField
                              name="facebook_id"
                              placeholder="Facebook ID"
                              type="text"
                              errorMessage="Enter Facebook ID"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.facebook_id}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              LinkedIn ID
                            </Label>
                            <AvField
                              name="linkdln_id"
                              placeholder="LinkedIN ID"
                              type="text"
                              errorMessage="Enter LinkedIN ID"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.linkdln_id}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Twitter ID
                            </Label>
                            <AvField
                              name="twitter_id"
                              placeholder="Twitter ID"
                              type="text"
                              errorMessage="Enter Twitter ID"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.twitter_id}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Address</Label>
                            <AvField
                              style={customStyles1}
                              name="address"
                              placeholder="Address"
                              type="textarea"
                              className="form-control"
                              // errorMessage="Enter Address"
                              // validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={staffObject?.address}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>

                        {/* <h4>Bank Details</h4>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Bank Name
                            </Label>
                            <AvField
                              name="bank_name"
                              placeholder="Bank Name"
                              type="text"
                              errorMessage="Enter Bank Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.bank_name}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Branch Name
                            </Label>
                            <AvField
                              name="bank_branch"
                              placeholder="Branch Name"
                              type="text"
                              errorMessage="Enter Branch Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.bank_branch}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Account No.
                            </Label>
                            <AvField
                              name="bank_acc"
                              placeholder="Account No."
                              type="text"
                              errorMessage="Enter Account No."
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.bank_acc}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Bank IFSC
                            </Label>
                            <AvField
                              name="bank_ifsc"
                              placeholder="Bank IFSC"
                              type="text"
                              errorMessage="Enter Bank IFSC"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.bank_ifsc}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col> */}

                        {/* <h4>Staff Details</h4> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff Branch</Label>
                            <Select
                              name="Branch"
                              value={selectedBranch}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Branch");

                              }}
                              options={branch}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedBranch === null
                                ? errors.staffBranch
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff Type</Label>
                            <Select
                              name="Type"
                              value={selectedStaffType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Type");
                              }}
                              options={staffType}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedStaffType === null
                                ? errors.staffType
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff Designation</Label>
                            <Select
                              name="Desigg"
                              value={selectedDesig}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Desigg");
                              }}
                              options={designation}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedDesig === null
                                ? errors.staffDesignation
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Default Module</Label>
                            <Select
                              name="Modules"
                              value={selectedModules}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Modules");
                              }}
                              // placeholder="HRMS"
                              options={modules}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedModules === null
                                ? errors.staffModule
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Joining Date
                            </Label>

                            <input
                              className="form-control"
                              type="date"
                              id="date"
                              name="user_joining_date"
                              value={staffObject?.user_joining_date}
                              onChange={handleChangeDate}
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedJoinDate === null ? errors.joinDate : ""}
                            </p>

                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Resigning Date
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              id="date"
                              name="user_resigning_date"
                              value={staffObject?.user_resigning_date}
                              onChange={handleChangeDate}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Staff ID</Label>
                            <AvField
                              name="userId"
                              placeholder="Staff ID"
                              type="text"
                              className="form-control"
                              errorMessage="Enter Staff ID"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.userId}
                              onChange={handleChangeLeads1}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Working From
                            </Label>

                            <input
                              className="form-control"
                              type="time"
                              id="time"
                              name="working_frm"
                              value={staffObject?.working_frm}
                              onChange={handleChangeDate1}
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedWorkingFrom === null
                                ? errors.workingFrom
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Working To
                            </Label>
                            {/* <div className="col-md-10"> */}
                            <input
                              className="form-control"
                              type="time"
                              id="time"
                              name="working_to"
                              value={staffObject?.working_to}
                              onChange={handleChangeDate2}
                            />
                            {/* </div> */}
                            <p className="text-danger"
                              style={{ fontSize: "11px" }} > {selectedWorkingTo === null ? errors.workingTO : ""}</p>

                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Casual Leaves
                            </Label>
                            <AvField
                              name="casual_lvs"
                              placeholder="Casual leaves"
                              type="number"
                              errorMessage="Enter Casual leaves"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.casual_lvs}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Sick Leaves
                            </Label>
                            <AvField
                              name="sick_lvs"
                              placeholder="Sick leaves"
                              type="number"
                              errorMessage="Enter Sick leaves"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.sick_lvs}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Permission
                            </Label>
                            <AvField
                              name="permissions"
                              placeholder="Permission"
                              type="number"
                              errorMessage="Enter Permission"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.permissions}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Basic Salary
                            </Label>
                            <AvField
                              name="basic_salary"
                              placeholder="Basic Salary"
                              type="number"
                              errorMessage="Enter Basic Salary"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.basic_salary}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Machine ID
                            </Label>
                            <AvField
                              name="machine_id"
                              placeholder="Machine ID"
                              type="text"
                              className="form-control"
                              errorMessage="Enter Machine ID"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.machine_id}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Swipe Card
                            </Label>
                            <AvField
                              name="swipe_card"
                              placeholder="Swipe Card"
                              type="text"
                              className="form-control"
                              errorMessage="Enter Swipe Card"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.swipe_card}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff Status</Label>
                            <Select
                              name="staff_status"
                              value={selectedStatus}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Status");
                              }}
                              // placeholder="Active"
                              options={[
                                { label: "Active", value: 0 },
                                { label: "Deactive", value: 1 },
                                { label: "Resigned", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Hourly Cost
                            </Label>
                            <AvField
                              name="hourly_cost"
                              placeholder="Hourly Cost"
                              type="number"
                              errorMessage="Enter Hourly Cost"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.hourly_cost}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Hourly Rate
                            </Label>
                            <AvField
                              name="hourly_rate"
                              placeholder="Hourly Rate"
                              type="number"
                              errorMessage="Enter Hourly Rate"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.hourly_rate}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Hourly Salary
                            </Label>
                            <AvField
                              name="hourly_salary"
                              placeholder="Hourly Salary"
                              type="number"
                              errorMessage="Enter Hourly Salary"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom03"
                              value={staffObject?.hourly_salary}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col> */}

                        {/* User Image Upload */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Upload Photo
                            </Label>
                            {staffObject?.user_image ? (
                              <div div className="img-wraps">
                                {staffObject.user_image &&
                                  staffObject.user_image.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${staffObject.user_image}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}/uploads/user_images/${staffObject?.user_image}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteScreenshot}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadScreenshot}
                                rows="1"
                              />
                            )}
                          </div>
                        </Col>

                        {/* User Document Upload */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Upload Document
                            </Label>
                            {staffObject?.user_documents ? (
                              <div div className="img-wraps">
                                {staffObject.user_documents &&
                                  staffObject.user_documents.startsWith(
                                    "http"
                                  ) ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${staffObject.user_documents}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}/uploads/user_images/${staffObject?.user_documents}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteDocument}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadDocument}
                                rows="1"
                              />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Primary Reporting</Label>
                            <Select
                              name="primary_staff"
                              value={selectedPrimaryStaff}
                              onChange={(selected) => {
                                handleSelectChange(selected, "primary_staff");

                              }}
                               options={staff}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedPrimaryStaff === null
                                ? errors.primaryStaff
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Secondary Reporting</Label>
                            <Select
                              name="secondary_staff"
                               value={selectedSecondaryStaff}
                              onChange={(selected) => {
                                handleSelectChange(selected, "secondary_staff");

                              }}
                               options={staff}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedSecondaryStaff === null
                                ? errors.secondaryStaff
                                : ""}
                            </p>
                          </div>
                        </Col>


                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {staffIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingStaff ? true : false}
                              >
                                {addingStaff ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingStaff ? true : false}
                              >
                                {addingStaff ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* Filter Fields */}
                    <AvForm>
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Staff Name
                            </Label>

                            <AvField
                              style={customStyles}
                              name="firstName"
                              placeholder="Staff Name"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              value={searchData?.firstName}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Staff ID</Label>

                            <AvField
                              style={customStyles}
                              name="userId"
                              placeholder="Staff ID"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              value={searchData?.userId}
                              onChange={handleChangeLeads}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff Branch</Label>
                            <Select
                              name="BranchSearch"
                              value={selectedSearchBranch}
                              onChange={(selected) => {
                                handleSelectedForSearch(
                                  selected,
                                  "BranchSearch"
                                );
                              }}
                              options={searchBranch}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff Designation</Label>
                            <Select
                              name="DesignationSearch"
                              value={selectedSearchDesig}
                              onChange={(selected) => {
                                handleSelectedForSearch(
                                  selected,
                                  "DesignationSearch"
                                );
                              }}
                              options={searchDesig}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff Status</Label>
                            <Select
                              name="StatusSearch"
                              value={selectedSearchStatus}
                              onChange={(selected) => {
                                handleSelectedForSearch(
                                  selected,
                                  "StatusSearch"
                                );
                              }}
                              options={[
                                { label: "Active", value: 0 },
                                { label: "Deactive", value: 1 },
                                { label: "Resigned", value: 2 },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col
                          md="2"
                          className="mt-4"
                          style={{ paddingTop: "4px" }}
                        >
                          <div className="mb-2">
                            <Button
                              color="success"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              onClick={() => handleSearch()}
                            >
                              {"  "}
                              Filter
                            </Button>
                            <Button
                              color="danger"
                              type="submit"
                              onClick={() => searchReset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* Filter Fields End */}
                    <MDBDataTable
                      id="addStaffTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      <div>
        <Dialog open={form} onClose={handleCloseForm}>
          <DialogTitle style={{ textAlign: "center" }}>
            Check In/Check Out Log Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Check In Date:</TableCell>
                      <TableCell align="left">08-11-2021</TableCell>
                      <TableCell align="left">Check In Time:</TableCell>
                      <TableCell align="left">23:26:45</TableCell>
                      {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.calories}</TableCell>
                        <TableCell align="left">{row.fat}</TableCell>
                        <TableCell align="left">{row.carbs}</TableCell>
                        <TableCell align="left">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="success">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddStaff;
