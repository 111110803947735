import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./rules.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
const DatatableTables = () => {
  const [details, setDetails] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    fetch_options();
    fetch_all_privilege();
  }, []);

  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }
  const fetch_options = (data = [], selectedPrivilege = {}) => {
    var detailsData = data;
    axios
      .get(`${API_URL}rules/option_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let value = [];
        result &&
          result.map((item) => {
            let status = false;
            detailsData &&
              detailsData.map((mainmenu) => {
                let submenu = mainmenu.sub_menu;

                submenu &&
                  submenu.map((submenu) => {
                    let submenuOptions = submenu.sub_menu_options;
                    submenuOptions &&
                      submenuOptions.map((submenuOptions) => {
                        if (submenuOptions === item?._id) status = true;
                      });
                  });
              });
            let data = {
              label: (
                <>
                  <ul style={{ listStyleType: "none", marginBottom: "0" }}>
                    <li>
                      <input
                        type="checkbox"
                        className="mb-2"
                        style={{
                          cursor: "pointer",
                        }}
                        checked={status === true ? true : false}
                        onChange={(e) =>
                          handleAllmenuCheckboxChange(
                            e,
                            item,
                            selectedPrivilege
                          )
                        }
                      />
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        {item?.option_name}
                      </span>
                    </li>
                  </ul>
                </>
              ),
              field: item?.option_name,
              sort: "asc",
              width: 400,
              id: item?._id,
            };
            value.push(data);
          });
        setOptions(value);
      });
  };
  const data = {
    columns: [
      {
        label: "Module",
        field: "module_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Menu",
        field: "mmenu",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sub Menu",
        field: "smenu",
        sort: "asc",
        width: 150,
      },
      ...options,
    ],
    rows: details,
  };

  const handleSelectChange = (selected) => {
    setSelectedPrivilege(selected);

    let data = {
      privilege: selected.value,
    };
    axios
      .post(`${API_URL}rules/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        result &&
          result.map((item, index) => {
            let submenus = item.sub_menu;

            item.sub_menu &&
              item.sub_menu.map((opt) => {
                opt.mainmenu_id = item._id;
              });

            item.mmenu = (
              <div>
                <ul style={{ listStyleType: "none" }}>
                  <li className="mb-2">
                    <input
                      type="checkbox"
                      className="mb-2"
                      checked={item.allocation_status === 1 ? true : false}
                      onChange={(e) =>
                        handleMainmenuCheckboxChange(e, item, selected)
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <span
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {item.main_menuname}
                    </span>
                  </li>
                </ul>
              </div>
            );
            item.smenu = (
              <div>
                <ul style={{ listStyleType: "none" }}>
                  {submenus.map((element, index) => {
                    return (
                      <li className="mb-2">
                        <input
                          type="checkbox"
                          checked={
                            element.allocation_status === 1 ? true : false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(e, element, item, selected)
                          }
                          className="mb-2"
                          style={{ cursor: "pointer" }}
                        />
                        <span
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {element.sub_name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );

            options &&
              options.map((el) => {
                return (item[el?.field] = (
                  <div>
                    <ul style={{ listStyleType: "none", padding: "0" }}>
                      {submenus.map((element, index) => {
                        let status = false;

                        element.sub_menu_options &&
                          element.sub_menu_options.map((submenuOpt) => {
                            if (submenuOpt === el?.id) {
                              status = true;
                            }
                          });
                        return (
                          <li className="mb-2" style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              className="mb-2"
                              checked={status === true ? true : false}
                              onChange={(e) =>
                                handleOptionCheckboxChange(
                                  e,
                                  el,
                                  element,
                                  selected
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <span
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              {""}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ));
              });
          });
        setDetails(result);
        fetch_options(result, selected);
      });
  };
  const handleMainmenuCheckboxChange = (e, item, selected) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selected?.value,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}rules/menu`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selected);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleCheckboxChange = (e, element, item, selected) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selected?.value,
      submenu_id: element._id,
      status: e.target.checked === true ? 1 : 0,
    };
    axios
      .post(`${API_URL}rules/add`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selected);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleOptionCheckboxChange = (e, el, element, selected) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: el?.id,
      privilege: selected?.value,
      sub_menu: element?._id,
      main_menu: element?.mainmenu_id,
    };
    axios
      .post(`${API_URL}rules/menu_option`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selected);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          toastr.error(err.response.data.message);
          return;
        }
      });
  };
  const handleAllmenuCheckboxChange = (e, item, selected) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: item._id,
      privilege: selected?.value,
    };
    axios
      .post(`${API_URL}rules/options`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selected);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Rules" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Privilege
                          </Label>
                          <Select
                            name="rules"
                            value={selectedPrivilege}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            classNamePrefix="select2-selection"
                            options={privilegeOptions}
                          />
                        </div>
                      </Col>

                      {/* <Col md="3">
                        <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                            className="rules"
                          >
                            <Settings></Settings>
                            {"  "}Set Rules
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="rulesTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
