import React,{ useState, useEffect, useRef } from "react";
import { Card,CardBody,CardHeader,Row,Col,Progress} from "reactstrap";
import CountUp from 'react-countup';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import moment from "moment";
import "./style.scss";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import {timeFromNumber} from "../../helpers/globalFunctions";
const CRMTVProjectDashboard = (props)=>{
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const code = 3;
    const [totalTask, setTotalTask] = useState({tot:0,complete:0,overeffort:0,incomplete:0,overdue:0,totalsub:0,completed_tasks:0,incomplete_tasks:0,overeffor_tasks:0,due_tasks:0,unassigned_tasks:0,tot_unassigned:0});
    const [taskPermission,SetTaskPermission]=useState(false);
    const [details, setDetails] = useState({});
    const [allProjectID, setAllProjectID] = useState([]);
    const [flowCountTable, setflowCountTable] =useState([]);
    const [assigneeDataTable, setassigneeDataTable] =useState([]);
    var counter = 0;
    const flow_count_data = {
        columns: [
          // {
          //   label: "#",
          //   field: "index",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Flow",
            field: "item_name",
            sort: "disabled",
            width: 90,
          },
          {
            label: "Tot",
            field: "total",
            sort: "disabled",
            width: 90,
          },
          {
            label: "Cmp",
            field: "completed",
            sort: "disabled",
            width: 90,
          },
          {
            label: "Icmp",
            field: "incomplete",
            sort: "disabled",
            width: 90,
          },
          {
            label: "UA",
            field: "unassigned",
            sort: "disabled",
            width: 90,
          },
          {
            label: "OD",
            field: "overdue",
            sort: "disabled",
            width: 90,
          },
          {
            label: "OE",
            field: "overeffort",
            sort: "disabled",
            width: 90,
          },
        ],
        rows: flowCountTable,
      };
    const chat_flow_count_data = {
        columns: [
          // {
          //   label: "#",
          //   field: "id",
          //   sort: "asc",
          //   width: 150,
          // },
          {
            label: "Task",
            field: "task",
            sort: "asc",
            width: 90,
          },
          {
            label: "Flow",
            field: "flow",
            sort: "asc",
            width: 90,
          },
          {
            label: "Staff",
            field: "name",
            sort: "asc",
            width: 90,
          },
          {
            label: "Plan",
            field: "assign_time1",
            sort: "asc",
            width: 90,
          },
          {
            label: "Effort",
            field: "assign_effort_hr1",
            sort: "asc",
            width: 90,
          },
        ],
        rows: assigneeDataTable,
    };
    // const allProjects = () => {
    //     axios
    //       .get(`${API_URL}tv/all_projects`, {
    //         headers: {
    //           "x-access-token": accessToken,
    //         },
    //       })
    //       .then((res) => {
    //         let result = res.data.data;
    //         if(result.length>0)
    //         {
    //             if(result[0].ids.length>0)
    //             {
    //                 setAllProjectID(result[0].ids);
    //                 handleTableData(result[0].ids[counter])
    //             }
    //         }
            
            
    //       });
    //   };
    const handleTableData = () => {
        axios
          .get(`${API_URL}tv/project_view?code=`+code, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            let result = res.data.data;
            setTotalTask({
              ...totalTask,
              "tot":res.data.tot_task,
              "complete":res.data.tot_complete,
              "overeffort":res.data.tot_effort,
              "incomplete":(res.data.tot_task - res.data.tot_complete),
              "overdue":res.data.tot_overdue,
              "totalsub":res.data.grand_tot_flow,
              "completed_tasks":res.data.completed_tasks,
              "incomplete_tasks":(res.data.grand_tot_flow - res.data.completed_tasks),
              "overeffor_tasks":res.data.overeffor_tasks,
              "due_tasks":res.data.due_tasks,
              "tot_unassigned":res.data.tot_unassigned,
              "unassigned_tasks":res.data.unassigned_tasks
            });
            res.data.flow_data[res.data.flow_data.length]={index:"",
                                                            item_name:(<><b>Total</b></>),
                                                            total:(<><b>{res.data.grand_tot_flow}</b></>),
                                                            completed:(<><b>{res.data.completed_tasks}</b></>),
                                                            incomplete:(<><b>{(res.data.grand_tot_flow-res.data.completed_tasks)}</b></>),
                                                            unassigned:(<><b>{res.data.unassigned_tasks}</b></>),
                                                            overdue:(<><b>{res.data.due_tasks}</b></>),
                                                            overeffort:(<><b>{res.data.overeffor_tasks}</b></>)};
            setflowCountTable(res.data.flow_data);
            var percent = 0;
            result[0].badge="badge bg-info";
            if(res.data.tot_task>0)
            {
              percent = (res.data.tot_complete/res.data.tot_task)*100;
            }

            result[0].percentage = percent;
            setDetails(result[0]);
                  // res.data.assingee_data.map((item,index)=>{
                  //     item.id=index+1;
                  //     if(typeof item.assign_time!=="undefined")
                  //     item.assign_time1 = timeFromNumber(item.assign_time);
                  //     else
                  //     item.assign_time1 = "00:00";
  
                  //     if(typeof item.assign_effort_hr!=="undefined")
                  //     item.assign_effort_hr1 = item.assign_effort_hr.substring(0,item.assign_effort_hr.lastIndexOf(":"));
                  //     else
                  //     item.assign_effort_hr1="00:00";
                  //     if(item.assign_effort_h1r=="")
                  //     item.assign_effort_hr1="00:00";
                  // });
                  setassigneeDataTable(res.data.assingee_data);                
          });
      };
    useEffect(()=>{
      handleTableData();
    },[])
    useEffect(()=>{
        const interval = setInterval(() => {
                handleTableData();
          }, 10000);
    },[])

    return (
        <React.Fragment>
            <div className="page-content">
            <div className="container-fluid">
                <Row>
                    <Col>
                        <h2 style={{ fontWeight:"bold", textAlign:"center"}}>{details?.project_name} <span className="badge bg-dark">T <i class="fas fa-clock"></i> {details?.hours}</span> <span className="badge bg-dark">P <i class="fas fa-clock"></i> {details?.tot_assign}</span> <span className="badge bg-dark">E <i class="fas fa-clock"></i> {Math.round(details?.tot_effort)}</span> <span className={details.badge}>{Math.round(details?.percentage)}%</span></h2>
                        <Row>
                                  <Col md={2} xl={2} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.tot} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Total</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-primary me-1"}><i className={"fas fa-laptop-code me-1"}></i>{totalTask.totalsub}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  <Col md={2} xl={2} style={{ cursor:"pointer"}} >
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.complete} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Completed</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-success me-1"}><i className={"fas fa-tasks me-1"}></i>{totalTask.completed_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={2} xl={2} style={{ cursor:"pointer"}} >
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.incomplete} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">InCompleted</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span style={{color: "#f1b44c"}} className={"me-1"}><i className={"fas fa-times me-1"}></i>{totalTask.incomplete_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={2} xl={2} style={{ cursor:"pointer"}} >
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.tot_unassigned} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Unassigned</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span style={{ color:"#b96e96"}} className={"me-1"}><i className={"fas fa-user-alt-slash me-1"}></i>{totalTask.unassigned_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col md={2} xl={2} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.overdue} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Over Due</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span style={{ color: "#2f88d3"}} className={"me-1"}><i className={"fas fa-calendar-alt me-1"}></i>{totalTask.due_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                                    <Col md={2} xl={2} style={{ cursor:"pointer"}}>
                                      <Card>
                                        <CardBody style={{ padding:"10px 10px"}}>
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title"><span><CountUp end={totalTask.overeffort} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                            <p className="text-dark mb-0 h4-subtitle">Over Effort</p>
                                          </div>
                                          <p className="text-dark mt-3 mb-0 h4-para"><span className={"text-danger me-1"}><i className={"fas fa-fire me-1"}></i>{totalTask.overeffor_tasks}</span>{" "}Tasks
                                          </p>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                <Col md="4">
                                  <Card>
                                    <CardBody>
                                    <MDBDataTable
                                              id="flowCountTableId"
                                              responsive
                                              bordered
                                              data={flow_count_data}
                                              searching={false}
                                              disableRetreatAfterSorting={true}
                                              paging={false}
                                              info={false}
                                            /> 
                                    </CardBody>
                                  </Card>
 
                                </Col>
                                <Col md="8">
                                 

                                  <Card>
                                    <CardBody>
                                    <table>
                                    <tr>
                                      <td><div style={{ padding:"7px",backgroundColor:"#2fa77b54"}}></div></td><td>Completed</td>
                                      <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#40a17d8f"}}></div></td><td>Reported</td>
                                      <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#c5b150c2"}}></div></td><td>Over Effort</td>
                                      <td style={{paddingLeft:"4px"}}><div style={{ padding:"7px",backgroundColor:"#b73838b8"}}></div></td><td>Over Due</td>
                                    </tr>
                                    </table>
                                      {assigneeDataTable.length>0?(
                                        <table class="table table-bordered" id="AssignlistTableId">
                                        <thead>
                                          <tr>
                                          <th>Task</th>
                                          <th>Flow</th>
                                          <th>Staff</th>
                                          <th>Plan</th>
                                          <th>Effort</th>
                                          <th>%</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            assigneeDataTable.map((item,index)=>{
                                                  item.id=index+1;
                                                  if(typeof item.assign_time!=="undefined")
                                                  item.assign_time1 = timeFromNumber(item.assign_time);
                                                  else
                                                  item.assign_time1 = "00:00";
                                                  var backgroundColor = "";
                                                  if(item.assign_start_stop==1)
                                                  backgroundColor="#3f51b52e";
                                                  if(typeof item.assign_effort_hr!=="undefined")
                                                  item.assign_effort_hr1 = item.assign_effort_hr.substring(0,item.assign_effort_hr.lastIndexOf(":"));
                                                  else
                                                  item.assign_effort_hr1="00:00";
                                                  if(item.assign_effort_h1r=="")
                                                  item.assign_effort_hr1="00:00";
                                                  var colors="#5b73e8";
                                                  if(item.isovereffort==1)
                                                  {
                                                    colors="#c5b150c2";
                                                  }
                                                  else if(item.isoverdue==1)
                                                  {
                                                    colors="#b73838b8";
                                                  }
                                                  else if(item.assign_complete_status==1)
                                                  {
                                                    colors="#40a17d8f";
                                                  }
                                                  else if(item.iscompleted==1)
                                                  {
                                                    colors="#2fa77b54";
                                                  }
                                                  if(item.assign_complete_percentage>0)
                                                  var padding="7px";
                                                  else
                                                  var padding="0px";
                                              return(
                                                <>
                                                <tr style={{backgroundColor:backgroundColor}}>
                                                  <td>{item.task}</td>
                                                  <td>{item.flow}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.assign_time1}</td>
                                                  <td>{item.assign_effort_hr1}</td>
                                                  <td>
                                                  <div class="progress" style={{ height:"14px", border:`${colors} thin solid`}}><div class="progress-bar"  role="progressbar" aria-valuenow={item.assign_complete_percentage} aria-valuemin="0" aria-valuemax="100" style={{width:`${item.assign_complete_percentage}%`,padding:padding, backgroundColor:colors}}>{item.assign_complete_percentage}%</div></div>
                                                  </td> 
                                                </tr>
                                                </>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </table>
                                      ):(
                                        <table class="table table-bordered">
                                        <thead>
                                          <tr>
                                          <th>Task</th>
                                          <th>Flow</th>
                                          <th>Staff</th>
                                          <th>Plan</th>
                                          <th>Effort</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                         <tr>
                                          <td colSpan={5} align={"center"}>No matching records found</td>
                                        </tr>
                                        </tbody>
                                      </table>
                                      )}
                                      
                                    {/* <MDBDataTable
                                  id="flowCountTableIds"
                                  responsive
                                  bordered
                                  data={chat_flow_count_data}
                                  searching={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  info={false}
                              /> */}
                                    </CardBody>
                                  </Card>
  
                                </Col>
                               
                                
                        </Row>
                    </Col>
                </Row>
                
            </div>
            </div>
        </React.Fragment>
    )
}
export default CRMTVProjectDashboard;