import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./leaveSummary.scss";
import { Stack } from "react-bootstrap";

const DatatableTables = () => {
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (e) => {
    setValue(e.target.value);
  };

  const tableStyle = {
    paddingRight: "14%",
  };
  const tableStyle1 = {
    paddingRight: "25%",
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch Code",
        field: "BCode",
        sort: "asc",
        width: 150,
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">CL</th>
                      </tr>
                      <tr>
                        <td>Eligible</td>
                        <td>Available</td>
                        <td>Availed</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">SL</th>
                      </tr>
                      <tr>
                        <td>Eligible</td>
                        <td>Available</td>
                        <td>Availed</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3">P</th>
                      </tr>
                      <tr>
                        <td>Eligible</td>
                        <td>Available</td>
                        <td>Availed</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        field: "attendance",
        sort: "asc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        BCode: "SRV",
        name: "Anitha",

        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <td style={tableStyle}>3.5</td>
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        lop: "0",
      },
      {
        id: "2",
        BCode: "SRVIT",
        name: "Naseem",

        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <td style={tableStyle}>3.5</td>
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        lop: "0",
      },
      {
        id: "3",
        BCode: "SRV",
        name: "Sanjay",

        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <td style={tableStyle}>3.5</td>
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        lop: "0",
      },
      {
        id: "4",
        BCode: "SRVIT",
        name: "JItu",

        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <td style={tableStyle}>3.5</td>
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        lop: "0",
      },
      {
        id: "5",
        BCode: "SRV",
        name: "Mithun",

        attendance: (
          <>
            <Row>
              <Col xl="12">
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <td style={tableStyle}>3.5</td>
                        <td style={tableStyle}>10</td>
                        <td style={tableStyle1}>0</td>
                      </tr>
                    </thead>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ),
        lop: "0",
      },
    ],
  };

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Leave Summary" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              From
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              To
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                          >
                            {/* <Save></Save> */}
                            {"  "}
                            Filter
                          </Button>
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="leaveSummaryTableId"
                    responsive
                    
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div></div>
    </React.Fragment>
  );
};

export default DatatableTables;
