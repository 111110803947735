import React, { useState, useEffect, useRef, useDebugValue } from "react";
import "./KanbanSupportTicket.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
function KanbanSupportTicket({ projectDetails }) {
  const [columns, setColumns] = useState({});
  const [dateChnage, setdateChnage] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [checkBoxName, setCheckBoxName] = useState();
  const [isModalVisible, setModalVisible] = useState(false);
  // const [checkBox, setcheckBox] = useState([
  //     "64e056238a3a0d5dc3e48191",
  //     "64e0563a8a3a0d5dc3e48192",
  //     "64e056428a3a0d5dc3e48193",
  //     "64e056548a3a0d5dc3e48194",
  //     "64e0565c8a3a0d5dc3e48195",
  //     "64e056658a3a0d5dc3e48196"
  // ])
  // const statusOrder = {
  //     "64e056238a3a0d5dc3e48191": 0,
  //     "64e0563a8a3a0d5dc3e48192": 1,
  //     "64e056428a3a0d5dc3e48193": 2,
  //     "64e056548a3a0d5dc3e48194": 3,
  //     "64e0565c8a3a0d5dc3e48195": 4,
  //     "64e056658a3a0d5dc3e48196": 5,
  // };

  const [dataLoaded, setDataLoaded] = useState(false);
  // useEffect(() => {
  //     axios
  //         .get(`${API_URL}task-kanban/status`, {
  //             headers: {
  //                 "x-access-token": accessToken,
  //             }
  //         })
  //         .then((res) => {
  //             setCheckBoxName(res.data.data)
  //         })
  // }, []);
  // const projectId = projectDetails._id;
  // useEffect(() => {
  //     fetch_Lead(checkBox, dateChnage)
  // }, [checkBox, dateChnage, dataLoaded]);

  // function handleFollowupDate(e) {
  //     console.log("name", e.target.name);
  //     console.log("value", e.target.value);

  //     setdateChnage({ ...dateChnage, [e.target.name]: e.target.value })

  // }

  // function fetch_Lead(checkBox, { from_date = "", to_date = "" }) {
  //     const checkBoxQueryString = checkBox.join(',');
  //     let url = `${API_URL}task-kanban?from_date=${from_date || ""}&to_date=${to_date || ""}&status=${checkBoxQueryString}&project_id=""`;

  //     axios.get(url, {
  //         headers: {
  //             "x-access-token": accessToken,
  //         },
  //     })
  //         .then((res) => {
  //             const result = res.data.data;

  //             const newData = checkBox.reduce((el, item) => {
  //                 const matchingStatus = result.find(statusId => statusId._id === item);
  //                 const statusName = matchingStatus ? matchingStatus.name : getCheckBoxName(item);

  //                 el[statusOrder[item]] = {
  //                     name: statusName,
  //                     items: matchingStatus ? matchingStatus.items : [],
  //                 };

  //                 return el;
  //             }, []);

  //             setColumns(newData);
  //             setDataLoaded(true);
  //         });
  // }

  // function getCheckBoxName(statusId) {
  //     const foundStatus = checkBoxName?.find(status => status._id === statusId);
  //     return foundStatus ? foundStatus.name : 'Status Not Found';
  // }

  // const onDragEnd = (result, columns, setColumns) => {
  //     if (!result.destination) return;
  //     const { source, destination } = result;

  //     const statusOrder = {
  //         0: "64e056238a3a0d5dc3e48191",
  //         1: "64e0563a8a3a0d5dc3e48192",
  //         2: "64e056428a3a0d5dc3e48193",
  //         3: "64e056548a3a0d5dc3e48194",
  //         4: "64e0565c8a3a0d5dc3e48195",
  //         5: "64e056658a3a0d5dc3e48196",
  //     };

  //     if (source.droppableId !== destination.droppableId) {
  //         const sourceColumn = columns[source.droppableId];
  //         const destColumn = columns[destination.droppableId];
  //         const sourceItems = [...sourceColumn.items];
  //         const destItems = [...destColumn.items];
  //         const [removed] = sourceItems.splice(source.index, 1);
  //         destItems.splice(destination.index, 0, removed);

  //         const cardId = removed._id;
  //         const destinationBoxId = statusOrder[destination.droppableId];

  //         console.log("Card ID:", cardId);
  //         console.log("Destination Box ID:", destinationBoxId);

  //         setColumns({
  //             ...columns,
  //             [source.droppableId]: {
  //                 ...sourceColumn,
  //                 items: sourceItems
  //             },
  //             [destination.droppableId]: {
  //                 ...destColumn,
  //                 items: destItems
  //             }
  //         });
  //         axios.post(`${API_URL}task-kanban/status-update`,
  //             {
  //                 task_id: cardId,
  //                 status: destinationBoxId,
  //             },
  //             {
  //                 headers: {
  //                     "x-access-token": accessToken,
  //                 },
  //             }
  //         )
  //             .then((response) => {
  //                 fetch_Lead(checkBox, dateChnage)
  //             })
  //             .catch((error) => {
  //                 console.error(error);
  //             });
  //     } else {
  //         const column = columns[source.droppableId];
  //         const copiedItems = [...column.items];
  //         const [removed] = copiedItems.splice(source.index, 1);
  //         copiedItems.splice(destination.index, 0, removed);
  //         const cardId = removed._id;
  //         const destinationBoxId = statusOrder[destination.droppableId];
  //         console.log("Card ID:", cardId);
  //         console.log("Destination Box ID:", destinationBoxId);
  //         setColumns({
  //             ...columns,
  //             [source.droppableId]: {
  //                 ...column,
  //                 items: copiedItems
  //             }
  //         });
  //         axios.post(`${API_URL}task-kanban/status-update`,
  //             {
  //                 task_id: cardId,
  //                 status: destinationBoxId,
  //             },
  //             {
  //                 headers: {
  //                     "x-access-token": accessToken,
  //                 },
  //             }
  //         )
  //             .then((response) => {
  //                 fetch_Lead(checkBox, dateChnage)
  //             })
  //             .catch((error) => {
  //                 console.error(error);
  //             });

  //     }
  // };
  // function checkboxclick(e) {
  //     const { checked, value } = e.target;
  //     if (checked) {
  //         setcheckBox((prevCheckBox) => {
  //             if (!prevCheckBox.includes(value)) {
  //                 return [...prevCheckBox, value];
  //             }
  //             return prevCheckBox;
  //         });
  //     } else {
  //         setcheckBox((prevCheckBox) => prevCheckBox.filter((id) => id !== value));
  //     }
  // }
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  return (
    <React.Fragment>
      {/* {dataLoaded ? ( */}
      <div className="page-content" style={{ background: "white" }}>
        <div className="container-fluid" style={{ padding: "0px" }}>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-6rem",
            }}
          >
            {/* {checkBoxName?.map((item, index) => {
                            const isChecked = checkBox.includes(item?._id);
                            return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input  type="checkbox"   /> <p className="mx-3 mb-0">New</p>
                                </div>
                            )
                        })} */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">New</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Pending</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Completed</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Hold</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Solved</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Informed</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" /> <p className="mx-3 mb-0">Closed</p>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
                            <input type="checkbox" /> <p className="mx-3 mb-0">No issue</p>
                        </div> */}

            <div style={{ marginLeft: "52px" }}>
              <AvForm style={{ display: "flex" }}>
                <div style={{ display: "flex" }} className="mx-2">
                  <label style={{ marginTop: ".5rem" }}>From</label>
                  <AvField
                    style={{ width: "115px", marginLeft: "10px" }}
                    name="from_date"
                    type="date"
                    // onChange={(e) => {
                    //     handleFollowupDate(e,);
                    // }}
                  />
                </div>
                <div style={{ display: "flex" }} className="mx-2">
                  <label style={{ marginTop: ".5rem" }}>To</label>
                  <AvField
                    style={{ width: "115px", marginLeft: "10px" }}
                    name="to_date"
                    type="date"
                    // onChange={(e) => {
                    //     handleFollowupDate(e,);
                    // }}
                  />
                </div>
              </AvForm>
            </div>
          </div>
          <div className="kanboard mt-3">
            <div className={`kanboard_child `}>
              <div className="kanboard_header">
                <div className="board_header_left">
                  {/* {column?.name} */} New
                </div>
                <div className="board_header_right">
                  <h6 className="card_icon kanboard_close">1</h6>
                  <h6 className="card_icon kanboard_view">
                    <i class="fa fa-angle-right"></i>
                  </h6>
                </div>
              </div>
              <div className="kanboard_body">
                <h6 className="board_name">Name</h6>

                <div
                  style={{
                    width: 215,
                    minHeight: 200,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <div class="kanboard_card" onClick={toggleModal}>
                    <h5 className="mb-0">Project</h5>
                    <p className="mb-0">Client Name</p>
                    <p className="mb-0">Date and Time</p>

                    <div className="flex_align">
                      <p className="mb-0">Status</p>

                      <p className="mb-0 badge bg-dark text-white">TAT</p>

                      {/* <i style={{ cursor: "pointer" }} className="fa fa-eye icon_eye"></i> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // ) : (
            //     <p>Loading...</p>
            // )} */}
      {/* Popup */}
      {/* {isModalVisible && (
                <div className="modal-backdrop" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', display: isModalVisible ? 'block' : 'none' }}>
                    <div className="modal modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Task Name</h5>
                                <button type="button" className="close" onClick={toggleModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                               
                                <p>Status : Assigned</p>
                                <p>Staff Name : Ajith</p>
                                <p>Start Date : 01-09-2023</p>
                                <p>End Date : 02-09-2023</p>
                            </div>
                        </div>
                    </div>
                </div>

            )} */}
    </React.Fragment>
  );
}

export default KanbanSupportTicket;
