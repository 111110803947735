import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./applyLeave.scss";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Select from "react-select";
import { borderColor } from "@mui/system";

const ApplyLeaves = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const showCasualLeave = () => {
    setShow(true);
    setShow1(false);
    setShow2(false);
    setShow3(false);
  };
  const showSickLeave = () => {
    setShow(false);
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };
  const lopLeave = () => {
    setShow(false);
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };
  const pleave = () => {
    setShow(false);
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };
  const customStyles = {
    lineHeight: "1.8",
    borderColor: "#c0c0c052",
  };
  const linkbtnStyle = {
    color: "blue",
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "LType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Eligible",
        field: "eligible",
        sort: "asc",
        width: 150,
      },
      {
        label: "Available",
        field: "available",
        sort: "asc",
        width: 150,
      },
      {
        label: "Availed",
        field: "availed",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        LType: "CL",
        eligible: "3.5",
        available: "3.5",
        availed: (
          <>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ marginTop: "revert" }}>0</p>
              <Button
                variant="link"
                color="default"
                style={linkbtnStyle}
                onClick={() => showCasualLeave()}
              >
                Apply
              </Button>
            </div>
          </>
        ),
      },
      {
        id: "2",
        LType: "SL",
        eligible: "3.5",
        available: "3.5",
        availed: (
          <>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ marginTop: "revert" }}>0</p>
              <Button
                variant="link"
                color="default"
                style={linkbtnStyle}
                onClick={() => showSickLeave()}
              >
                Apply
              </Button>
            </div>
          </>
        ),
      },
      {
        id: "3",
        LType: "LOP",
        eligible: "3.5",
        available: "3.5",
        availed: (
          <>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ marginTop: "revert" }}>0</p>
              <Button
                variant="link"
                color="default"
                style={linkbtnStyle}
                onClick={() => lopLeave()}
              >
                Apply
              </Button>
            </div>
          </>
        ),
      },
      {
        id: "4",
        LType: "P",
        eligible: "	2 ( per month)",
        available: "0",
        availed: (
          <>
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ marginTop: "revert" }}>0</p>
              <Button
                variant="link"
                color="default"
                style={linkbtnStyle}
                onClick={() => pleave()}
              >
                Apply
              </Button>
            </div>
          </>
        ),
      },
    ],
  };

  const tableStyle = {
    paddingRight: "14%",
  };
  const tableStyle1 = {
    paddingRight: "25%",
  };

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "From",
        field: "FTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "To",
        field: "TTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "SID",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "SName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Applied",
        field: "ADate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        type: "CL",
        date: "18-07-2022",
        FTime: "11:35 pm",
        TTime: "6:00 pm",
        SID: "SRVINT020",
        SName: "Nihal",
        reason: "Personal Issue",
        status: "Approved",
        ADate: "18-07-2022",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "From",
        field: "FDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "To",
        field: "TDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Duration",
        field: "duration",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff ID",
        field: "SID",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "SName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Applied",
        field: "ADate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        type: "CL",
        FDate: "18-07-2022",
        TDate: "18-07-2022",
        duration: "1 Day",
        SID: "SRVINT020",
        SName: "Nihal",
        reason: "Personal Issue",
        status: "Approved",
        ADate: "18-07-2022",
        action: (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   toTop();
                //   preUpdateCategory(item);
                // }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                // onClick={() => {
                //   setCategoryIdToBeDeleted(item._id);
                //   setConfirmDeleteAlert(true);
                // }}
              ></i>
            </div>
          </>
        ),
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Apply Leave" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Label>Apply</Label>
                      <MDBDataTable
                        id="leaveSummaryTableId"
                        responsive
                        bordered
                        data={data}
                        paging={false}
                        searching={false}
                      />

                      {/* CL Start */}
                      {show ? (
                        <Row>
                          <Col className="col-12">
                            {/* <Card> */}
                            <CardBody>
                              <AvForm className="needs-validation">
                                <Row>
                                  <h6>Application Type CL</h6>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          From
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          To
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Reason
                                      </Label>
                                      <textarea
                                        style={customStyles}
                                        name="amount"
                                        placeholder="Reason"
                                        type="text"
                                        errorMessage="Enter Reason"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    md="3"
                                    className="mt-4"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <div className="mb-2">
                                      <Button
                                        color="success"
                                        type="submit"
                                        style={{ marginRight: "5%" }}
                                      >
                                        {/* <Save></Save> */}
                                        {"  "}
                                        Submit
                                      </Button>
                                      <Button color="danger" type="submit">
                                        {"  "}
                                        Reset
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      ) : null}
                      {/* CL End */}

                      {/* SL Start */}
                      {show1 ? (
                        <Row>
                          <Col className="col-12">
                            {/* <Card> */}
                            <CardBody>
                              <AvForm className="needs-validation">
                                <Row>
                                  <h6>Application Type SL</h6>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          From
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          To
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Reason
                                      </Label>
                                      <textarea
                                        style={customStyles}
                                        name="amount"
                                        placeholder="Reason"
                                        type="text"
                                        errorMessage="Enter Reason"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    md="3"
                                    className="mt-4"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <div className="mb-2">
                                      <Button
                                        color="success"
                                        type="submit"
                                        style={{ marginRight: "5%" }}
                                      >
                                        {/* <Save></Save> */}
                                        {"  "}
                                        Submit
                                      </Button>
                                      <Button color="danger" type="submit">
                                        {"  "}
                                        Reset
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      ) : null}
                      {/* SL End */}
                      {/* LOP Start */}
                      {show2 ? (
                        <Row>
                          <Col className="col-12">
                            {/* <Card> */}
                            <CardBody>
                              <AvForm className="needs-validation">
                                <Row>
                                  <h6>Application Type LOP</h6>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          From
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          To
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Reason
                                      </Label>
                                      <textarea
                                        style={customStyles}
                                        name="amount"
                                        placeholder="Reason"
                                        type="text"
                                        errorMessage="Enter Reason"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    md="3"
                                    className="mt-4"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <div className="mb-2">
                                      <Button
                                        color="success"
                                        type="submit"
                                        style={{ marginRight: "5%" }}
                                      >
                                        {/* <Save></Save> */}
                                        {"  "}
                                        Submit
                                      </Button>
                                      <Button color="danger" type="submit">
                                        {"  "}
                                        Reset
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      ) : null}
                      {/* LOP End */}
                      {/* P Start */}
                      {show3 ? (
                        <Row>
                          <Col className="col-12">
                            {/* <Card> */}
                            <CardBody>
                              <AvForm className="needs-validation">
                                <Row>
                                  <h6>Application Type P</h6>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Stack direction="row" spacing={2}>
                                        <Label
                                          htmlFor="validationCustom05"
                                          style={{ marginTop: "auto" }}
                                        >
                                          Date
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          // value={value}
                                          defaultValue="mm/dd/yyyy"
                                          id="example-date-input"
                                          // onChange={handleChangeDate}
                                        />
                                      </Stack>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        From Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>

                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        To Time
                                      </Label>
                                      <Select
                                        name="type"
                                        errorMessage="Select Category Type"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        // value={selectedType}
                                        // onChange={(selected) => {
                                        //   handleSelectChange(selected, "type");
                                        // }}
                                        options={[
                                          {
                                            label: "Forenoon",
                                            value: 0,
                                          },
                                          {
                                            label: "Afternoon",
                                            value: 1,
                                          },
                                        ]}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label htmlFor="validationCustom02">
                                        Reason
                                      </Label>
                                      <textarea
                                        style={customStyles}
                                        name="amount"
                                        placeholder="Reason"
                                        type="text"
                                        errorMessage="Enter Reason"
                                        className="form-control"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        id="validationCustom02"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    md="3"
                                    className="mt-4"
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <div className="mb-2">
                                      <Button
                                        color="success"
                                        type="submit"
                                        style={{ marginRight: "5%" }}
                                      >
                                        {/* <Save></Save> */}
                                        {"  "}
                                        Submit
                                      </Button>
                                      <Button color="danger" type="submit">
                                        {"  "}
                                        Reset
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      ) : null}
                      {/* P End */}

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              From
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Stack direction="row" spacing={2}>
                            <Label
                              htmlFor="validationCustom05"
                              style={{ marginTop: "auto" }}
                            >
                              To
                            </Label>
                            <input
                              className="form-control"
                              type="date"
                              value={value}
                              defaultValue="mm/dd/yyyy"
                              id="example-date-input"
                              onChange={handleChangeDate}
                            />
                          </Stack>
                        </div>
                      </Col>

                      <Col
                        md="3"
                        className="mt-4"
                        style={{ paddingTop: "4px" }}
                      >
                        <div className="mb-2">
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                          >
                     
                            {"  "}
                            Submit
                          </Button>
                          <Button color="danger" type="submit">
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col> */}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="leaveSummary1TableId"
                    responsive
                    
                    bordered
                    data={data1}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="leaveSummary2TableId"
                    responsive
                    
                    bordered
                    data={data2}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div></div>
    </React.Fragment>
  );
};

export default ApplyLeaves;
