import React, { useState, useEffect, useRef, useCallback } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Lead.scss";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import $ from "jquery";

import SweetAlert from "react-bootstrap-sweetalert";
const Newleads = () => {
  const [masterObject, setmasterObject] = useState({ crm: { country: 101, } });
  const [followupObject, setFollowupObject] = useState({});
  const [followupTableData, setFollowupTableData] = useState([]);
  const [followupstatusError, setfollowupstatusError] = useState("");
  const [nameError, setNameError] = useState("");
  const [mobError, setMobError] = useState("");
  const [leadtypeErr, setleadtypeErr] = useState("");
  const [source, setSource] = useState([])
  const [selectedSearchSource, setSelectedSearchSource] = useState("")
  const [selectedsearchType, setselectedsearchType] = useState("")
  const [form, setForm] = useState([])
  const [tableData, setTableData] = useState([]);
  const [temptableData, setTempTableData] = useState([]);
  const [followupStatus, setFollowupStatus] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [leadDetails, setleadDetails] = useState([]);
  const [selectedSearchForm, setSelectedSearchForm] = useState("")
  const [selectedSearchStatus, setSelectedSearchStatus] = useState("")
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [searchObject, setSearchObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
    lead_source: "",
    lead_ref_form_id: "",
    followup_status: ""
  });
  //auto completed select
  const [selectedMobile, setselectedMobile] = useState(null);
  const [leadTypes] = useState([{ label: "General", value: 1 }, { label: "Job", value: 2 }, { label: "Lead", value: 3 }])
  const [selectedName, setselectedName] = useState(null);
  const [searchMobileListOptions, setsearchMobileListOptions] = useState([]);
  const [searchNameListOptions, setsearchNameListOptions] = useState([])
  const handleCreateMobile = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue, label: inputValue };
      setsearchMobileListOptions([...searchMobileListOptions, newValue]);
      setselectedMobile(newValue);
      //setselectedName(null);
      $("#reg_mobile").attr("class", "select2-selection");
      setMobError("");
      setmasterObject({
        ...masterObject,
        reg_mobile: inputValue,
      })
    },
    [searchMobileListOptions, masterObject]
  );
  const handleCreateName = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue, label: inputValue };
      setsearchNameListOptions([...searchNameListOptions, newValue]);
      setselectedName(newValue);
      $("#reg_name").attr("class", "select2-selection");
      setNameError("");
      //setselectedMobile(null);
      setmasterObject({
        ...masterObject,
        reg_name: inputValue,
      })
    },
    [searchNameListOptions, masterObject]
  );
  const searchProfile = () => {
    axios
      .get(`${API_URL}newLeads/profileOptions`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var mobiles = [];
        var names = [];
        res.data.data.map((el, index) => {
          mobiles.push({
            label: el.reg_mobile,
            value: el.reg_mobile,
            id: el._id,
            name: el.reg_name,
            mobile: el.reg_mobile,
            email: el.reg_email,
            city: el.reg_city
          });
          names.push({
            label: el.reg_name,
            value: el.reg_name,
            id: el._id,
            name: el.reg_name,
            mobile: el.reg_mobile,
            email: el.reg_email,
            city: el.reg_city
          });
        })

        setsearchMobileListOptions([{ options: mobiles }])
        setsearchNameListOptions([{ options: names }])
      });
  }

  const [leadIdTobeUpdated, setLeadIdTobeUpdated] = useState(null)
  const [selectedSource, setSelectedSource] = useState(null)
  const [selectedForm, setSelectedForm] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState({ label: "India", value: 101 },)
  const [country, setCountry] = useState([])
  const [selectedFeedback, setSelectedFeedback] = useState("")
  const [feedback, setFeedback] = useState([])
  const [selectedFrom, setSelectedFrom] = useState("")
  const [from, setFrom] = useState([])
  const [selectedLeadFor, setSelectedLeadFor] = useState("")
  const [selectedLeadType, setselectedLeadType] = useState(null);
  const [leadFor, setLeadFor] = useState([])
  const [selectedBtype, setSelectedBtype] = useState("")
  const [btype, setBtype] = useState([])
  const [selectedTime, setSelectedTime] = useState("")
  const [time, setTime] = useState([])
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [leadIdToBeDeleted, setleadIdToBeDeleted] = useState(null)
  const addLeads = useSelector((state) => state.addLeads);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const formRef1 = useRef();
  useEffect(() => {

    fetch_status();
    fetch_form();
    fetch_source();
    fetch_country();
    fetch_learnfrom();
    fetch_feedback();
    fetch_for();
    fetch_btype();
    fetch_time();
    searchProfile();
  },
    []
  );
  const handleChangeType = (selected, id) => {
    axios
      .post(`${API_URL}newLeads/change-lead-type`, { id: id, type: selected.value }, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        handleSearch();
      })
  }
  function fetch_form() {
    axios
      .get(`${API_URL}newLeads/flow`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.form_type_name,
              value: el._id
            }
          })

        setForm([{ options: data }])
      })
  }
  function fetch_source() {
    axios
      .get(`${API_URL}leadsFor/Options`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setSource([{ options: data }])
      })
  }
  function fetch_status() {
    axios
      .get(`${API_URL}leads/leadsStatus`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })
        let from_date = searchObject?.from_date ? searchObject.from_date : "";
        let to_date = searchObject?.to_date ? searchObject.to_date : "";
        let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
        let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
        let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
        let type = searchObject?.lead_type ? searchObject.lead_type : "";

        handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, data, type);
        setFollowupStatus([{ options: data }])


      })
  }
  function fetch_country() {

    axios
      .get(`${API_URL}leads/Countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id
            }
          })

        setCountry([{ options: data }])
      })
  }
  function fetch_learnfrom() {

    axios
      .get(`${API_URL}leads/leadsLearnby`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setFrom([{ options: data }])
      })
  }
  function fetch_feedback() {

    axios
      .get(`${API_URL}leads/leadsFeedback`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setFeedback([{ options: data }])
      })
  }
  function fetch_for() {

    axios
      .get(`${API_URL}category/options`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setLeadFor([{ options: data }])
      })
  }
  function fetch_btype() {

    axios
      .get(`${API_URL}client/category`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.client_category,
              value: el._id
            }
          })

        setBtype([{ options: data }])
      })
  }
  function fetch_time() {

    axios
      .get(`${API_URL}leads/leadsTimeperiod`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            }
          })

        setTime([{ options: data }])
      })
  }
  const handleSelectSearchChange = (selected, name) => {
    switch (name) {
      case "lead_type":

        setselectedsearchType(selected);
        setSearchObject({
          ...searchObject,
          lead_type: selected.value,
        });
        break;
      case "lead_source":

        setSelectedSearchSource(selected);
        setSearchObject({
          ...searchObject,
          lead_source: selected.value,
        });
        break;
      case "lead_ref_form_id":

        setSelectedSearchForm(selected);
        setSearchObject({
          ...searchObject,
          lead_ref_form_id: selected.value,
        });
        break;
      case "followup_status":

        setSelectedSearchStatus(selected);
        setSearchObject({
          ...searchObject,
          followup_status: selected.value,
        });
        break;

      default:
        break;
    }

  }
  const handleFollowupData = (e, type) => {
    if (type == "followup_status") {
      setFollowupObject({
        ...followupObject,
        status_id: e.value,
        status: e
      });
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    }
    else if (type == "ref_no") {
      setFollowupObject({
        ...followupObject,
        ref_no: e.target.value,
      });
    }
    else if (type == "comment") {
      setFollowupObject({
        ...followupObject,
        comment: e.target.value,
      });
    }
    else if (type == "next_followup") {
      setFollowupObject({
        ...followupObject,
        next_followup: e.target.value,
      });
    }

  }
  let handleDate = (e) => {

    var from_date = $("#from_date").val();
    var to_date = $("#to_date").val();
    let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
    let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
    let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
    let type = searchObject?.lead_type ? searchObject.lead_type : "";
    let option = followupStatus
    let toDate = moment(from_date);
    let fromDate = moment(to_date);
    let result = fromDate.diff(toDate, "days");
    if (result + 1 <= 0) {
      setSearchObject({
        ...searchObject,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      to_date = "";
    } else {
      setSearchObject({
        ...searchObject,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);
  };
  const handleSearch = () => {
    let from_date = searchObject?.from_date ? searchObject.from_date : "";
    let to_date = searchObject?.to_date ? searchObject.to_date : "";
    let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
    let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
    let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
    let type = searchObject?.lead_type ? searchObject.lead_type : "";
    let option = followupStatus
    handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);
  }
  const updateStatus = (selected, item) => {
    let follow = item?.up_followup_on ? item.up_followup_on : null
    let data = {
      _id: item._id,
      followup_status: selected.value,
      followup_on: follow
    };
    axios
      .put(`${API_URL}newLeads/followup`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        if (res.data.status === 200) {

          let from_date = searchObject?.from_date ? searchObject.from_date : "";
          let to_date = searchObject?.to_date ? searchObject.to_date : "";
          let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
          let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
          let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
          let type = searchObject?.lead_type ? searchObject.lead_type : "";
          let option = followupStatus;
          handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);


        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };
  const updateDate = (selected, item) => {

    let foll = item?.up_followup_status_id ? item.up_followup_status_id : null
    let data = {
      _id: item._id,
      followup_on: selected.target.value,
      followup_status: foll,
    };

    axios
      .put(`${API_URL}newLeads/followup`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {

          let from_date = searchObject?.from_date ? searchObject.from_date : "";
          let to_date = searchObject?.to_date ? searchObject.to_date : "";
          let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
          let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
          let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
          let option = followupStatus;
          let type = searchObject?.lead_type ? searchObject.lead_type : "";
          handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);


        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}newLeads/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setFollowupObject({ lead_id: item._id });
        let result = res.data.data;
        var formtype = "";
        let Update = result.updatedby || {};
        result.edited = (Update.firstName || '') + (Update.lastName || '')

        let Add = result.addedby || {};
        result.add = (Add.firstName || '') + (Add.lastName || '')

        let Source = result.lead_source || {};
        result.source = Source?.name;

        let Form = result.lead_ref_form_id || {};
        result.form = Form?.form_type_name;
        var lead_cmt = "";
        if (Form._id.toString() == "635617667a108495c6360e58")
          formtype = 1;//crm
        else if (Form._id.toString() == "6333e2f539b31c90e7348a71")
          formtype = 2;//sale
        else if (Form._id.toString() == "6333e2f539b31c90e7348a75")
          formtype = 3;//support
        else if (Form._id.toString() == "6333e2f539b31c90e7348a76")
          formtype = 4;//contact
        else if (Form._id.toString() == "6333e2f539b31c90e7348a77")
          formtype = 5;//general
        else if (Form._id.toString() == "6333e2f539b31c90e7348a73")
          formtype = 6;//quote
        else if (Form._id.toString() == "6333e2f539b31c90e7348a72")
          formtype = 7;//quote
        result.formtypeid = formtype;
        if (result.pages) {
          var link = result.pages.split("https://srvinfotech.com/");
          result.pages = link[1];
        }


        let upTime = result.up_time || {};
        let upDate = result.up_date || {};
        result.update = (moment(upDate).format("DD-MM-YYYY")) + " " + (moment(upTime, "HHmmss").format("hh:mm a"));

        let Time = result.time || {};
        let Date = result.date || {};
        result.day = (moment(Date).format("DD-MM-YYYY")) + " " + (moment(Time, "HHmmss").format("hh:mm a"));

        let Basic = result.lead_reg_id || {};
        result.name = Basic?.reg_name;
        result.email = Basic?.reg_email;
        result.mobile = Basic?.reg_mobile;
        result.reg_unique_id = Basic?.reg_unique_id;
        let reg_cit = Basic?.reg_city;
        if (reg_cit != "NULL") { result.reg_city = reg_cit }


        let Message1 = result.talk_to_support || {}
        result.message1 = Message1?.support_message;
        let tsag = result.talk_to_support || {}
        let tsag1 = tsag?.support_agree_terms_condition
        if (tsag1 === 1) { result.tsag = "Yes" } else if (tsag1 === 0) { result.tsag = "No" };
        if (result.formtypeid == 3)
          lead_cmt = result.message1;

        let Message3 = result.contactaccount || {}
        result.Message3 = Message3?.contact_account_message;
        if (result.formtypeid == 4)
          lead_cmt = result.Message3;

        // let Message4 =result.brings_idea || {}
        // result.message4 =Message4?.idea_message;

        let Message5 = result.schedule_demo || {}
        result.message5 = Message5?.demo_message;
        let sdr1 = Message5?.demo_requirements
        result.sdr = sdr1
        if (result.formtypeid == 7)
          lead_cmt = result.sdr;


        let Message6 = result.general || {}
        result.Message6 = Message6?.general_message;
        let gr1 = Message6?.general_requirements
        result.gr = gr1
        if (result.formtypeid == 5)
          lead_cmt = result.Message6;


        let qte = result.quote || {}
        let qbtype1 = qte?.quote_business_type
        if (qbtype1 != "NULL") { result.qbtype = qbtype1 }
        let com1 = qte?.quote_company
        if (com1 != "NULL") { result.com = com1 }
        let cweb1 = qte?.quote_competition_website
        if (cweb1 != "NULL") { result.cweb = cweb1 }
        let pbudget1 = qte?.quote_project_budget
        if (pbudget1 != "NULL") { result.pbudget = pbudget1 }
        let wurl1 = qte?.quote_website_url
        if (wurl1 != "NULL") { result.wurl = wurl1 }
        let tm1 = qte?.qt_timeline
        if (tm1 != "NULL") { result.tm = tm1?.name }
        let src1 = qte?.qt_source
        if (src1 != "NULL") { result.src = src1?.name }
        if (result.formtypeid == 6)
          lead_cmt = result.pbudget;

        let Message2 = result.sale_data || {}
        result.message2 = Message2?.sale_message;
        let sag = result.sale_data || {}
        let sag1 = sag?.sale_agree_terms_condition
        if (sag1 === 1) { result.sag = "Yes" } else if (sag1 === 0) { result.sag = "No" };
        if (formtype == 2)
          lead_cmt = result.message2;

        let crm = result.crm || {}
        let compy1 = crm?.companyname
        result.compy = compy1;
        let btp = crm?.businesstype
        result.btp = btp?.client_category;
        result.adds = crm?.address;
        result.land = crm?.landline;
        result.webi = crm?.website;
        let cat = crm.category || []

        if (cat.length != 0) {

          result.cate = (

            <>
              {
                result?.crm?.category &&
                result?.crm?.category.map((el) => {
                  let indus = el?.name || {}

                  return (
                    <span className="badge bg-secondary badge-enq-category">
                      {indus}
                    </span>
                  )
                })
              }
            </>
          );
        }

        let tpm = crm?.timeperiod
        result.timep = tpm?.name;
        let lfrm = crm?.learnfrom
        result.lfrom = lfrm?.name;
        let fee = crm?.feedback
        result.feed = fee?.name;
        let coty = result?.crmctry?.[0] || []
        result.cotys = coty?.name;

        result.cmts = crm?.comment;
        if (formtype == 1)
          lead_cmt = result.cmts;
        result?.followup.sort(function (a, b) {
          var x = a._id;
          var y = b._id;
          // factor -1 because you want them sorted DESC
          return -1 * ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        var old_followup = []
        result?.followup &&
          result?.followup.map((el, index) => {
            if (typeof el.next_followup !== "undefined" && el.next_followup != "")
              var next_followup = moment(el?.next_followup).format("DD-MM-YYYY");
            else
              var next_followup = "";
            old_followup.push({
              id: index + 1,
              date: moment(el.date).format("DD-MM-YYYY") + " " + moment(el.time, "HH:mm:ss").format("hh:mm a"),
              username: el?.addedby?.firstName,
              status: (typeof el.followup_status.name !== "undefined") ? (
                <span className="badge" style={{ background: el.followup_status.color, fontSize: "12px" }}>{el.followup_status.name}</span>
              ) : "",
              ref_no: el?.ref_no,
              comment: el?.comments,
              next_followup: next_followup
            })
          });

        old_followup.push({
          id: old_followup.length + 1,
          date: result?.day,
          username: result?.add,
          status: (
            <span className="badge" style={{ background: "#00ccff", fontSize: "12px" }}>New</span>
          ),
          ref_no: "",
          comment: lead_cmt,
          next_followup: ""
        })
        setFollowupTableData(old_followup);

        setShowPopup(true);
        setleadDetails(result);


      });

  };

  const resetSearch = () => {
    setSelectedSearchSource("");
    setSelectedSearchForm("");
    setSelectedSearchStatus("");
    setselectedsearchType("");
    setSearchObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
      lead_source: "",
      lead_ref_form_id: "",
      followup_status: ""
    });
    let option1 = followupStatus
    handleTableData(getFirstday(new Date()), getDate(new Date()), "", "", "", option1)

  }
  function handleTableData(from_date, to_date, lead_source = "", lead_ref_form_id = "", followup_status = "", data, type = "") {
    var url = `${API_URL}newLeads/list?from_date=${from_date}&to_date=${to_date}&lead_source=${lead_source}&lead_ref_form_id=${lead_ref_form_id}&followup_status=${followup_status}&lead_type=${type}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let resData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          if (item?.lead_ref_form_id) {
            item.formtype = item.lead_ref_form_id?.form_type_name
          }
          let exportItem = {};
          item.formtype1 = item.formtype != "CRM" ? item.formtype : "";
          if (item.formtype == "CRM") {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}
                ></i>



                {item?.crm ? (

                  <i
                    className="far fa-edit"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.1rem",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      toTop();
                      preUpdateLead(item);
                    }}
                  >
                  </i>

                ) : null}

                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setleadIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                >
                </i>
              </div>

            )
          }
          else {
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpen(item);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setleadIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                >
                </i>
              </div>

            )
          }

          item.id = index + 1;
          if (item?.up_date) {
            item.day = (moment(item.up_date).format("DD-MM-YYYY"))//+" "+( moment(item.up_time, "HHmmss").format("hh:mm a"));
            item.time = (moment(item.up_time, "HH:mm:ss").format("hh:mm a"))//+" "+( moment(item.up_time, "HHmmss").format("hh:mm a"));
            //item.day =item.day+" "+item.time;
          } else {
            item.day = (moment(item.date).format("DD-MM-YYYY"))//+" "+( moment(item.time, "HHmmss").format("hh:mm a")); 
            item.time = (moment(item.time, "HH:mm:ss").format("hh:mm a"))
            //item.day =item.day+" "+item.time;
          }
          if (item?.lead_reg_id) {
            item.name = item.lead_reg_id?.reg_name
            item.mobile = item.lead_reg_id?.reg_mobile
            item.email = item.lead_reg_id?.reg_email
          }

          if (item?.lead_source) {
            item.source = item.lead_source?.name
          }

          var selectedStat = {};
          if (item.latest_followp_status) {
            var status = item?.latest_followp_status;
            var label = status?.name
            var value = status?._id
            var colorclass = status?.color
            item.up_followup_status_id = status?._id
            selectedStat = {
              label: label,
              value: value
            };
          }
          else {
            var label = "New";
            var colorclass = "#00ccff";
          }

          item.followup_status = (
            <span className="badge" style={{ fontSize: "12px", background: colorclass }}>{label}</span>
          )
          // item.followup_status = (
          //   <div className="mb-3">
          //     <Select
          //       menuPosition="fixed"
          //       name="followup_status"
          //        value={selectedStat}
          //       onChange={(selected) => {
          //         updateStatus(selected, item);
          //      }}
          //       classNamePrefix="select2-selection"
          //       options={data}
          //     />
          //   </div>
          // );

          let followon = item?.followup ? item.followup[0] : []
          let foll = followon?.followup_on
          item.up_followup_on = foll
          item.followup_on =
            (
              <>
                <input
                  name="followup_on"
                  value={foll}
                  style={{ textAlign: "left" }}
                  className="form-control"
                  onChange={(selected) => {
                    updateDate(selected, item);
                  }}
                  type="date"
                />
              </>
            )
          exportItem.id = item.id;
          exportItem.Date = item.day;
          exportItem.Name = item.name;
          exportItem.Mobile = item.mobile;
          exportItem.Type = item.formtype;
          exportItem.Source = item.source;
          exportItem.Status = item?.followup?.[0]?.followup_status?.name ? item.followup[0].followup_status.name : "";
          exportItem.FollowupOn = item?.followup?.[0]?.followup_on ? item.followup[0].followup_on : "";

          dataToBeExported.push(exportItem);
          if (item?.lead_type) {
            item.leadtypeObject = leadTypes[item?.lead_type - 1]
          }
          else {
            item.leadtypeObject = null;
          }
          item.lead_type = (
            <>
              <Select
                name="lead_type"
                value={item?.leadtypeObject}
                options={leadTypes}
                classNamePrefix="select2-selection"
                onChange={(selected) => {
                  handleChangeType(selected, item?._id);
                }}
              />
            </>
          )

          resData.push(item)
        })
        setTableData(resData);
        setTempTableData(resData);
        setDataToBeExported(dataToBeExported);
      });

  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateLead = (item) => {
    setLeadIdTobeUpdated(item._id)

    axios
      .get(`${API_URL}newLeads/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let cat = [];
        result?.crm?.category &&
          result?.crm?.category.map((el) => {
            let array = {
              label: el.name,
              value: el._id,
            }
            cat.push(array)
          })
        setSelectedLeadFor(cat);

        let cty = result?.crmctry?.[0]
        let country = {
          label: cty?.name,
          value: cty?._id
        };
        setSelectedCountry(country);

        let btype = result?.crm?.businesstype
        let bustype = {
          label: btype?.client_category,
          value: btype?._id
        };
        setSelectedBtype(bustype);

        let time = result?.crm?.timeperiod
        let timep = {
          label: time?.name,
          value: time?._id
        };
        setSelectedTime(timep);
        let learn = result?.crm?.learnfrom
        let lefrom = {
          label: learn?.name,
          value: learn?._id
        };
        setSelectedFrom(lefrom);

        let back = result?.crm?.feedback
        let feed = {
          label: back?.name,
          value: back?._id
        };
        setSelectedFeedback(feed);

        let source = result?.lead_source
        let src = {
          label: source?.name,
          value: source?._id
        };
        setSelectedSource(src);
        result.reg_mobile = result?.lead_reg_id?.reg_mobile;
        result.reg_name = result?.lead_reg_id?.reg_name;
        result.reg_email = result?.lead_reg_id?.reg_email;
        setselectedMobile({ label: result.reg_mobile, value: result.reg_mobile });
        setselectedName({ label: result.reg_name, value: result.reg_name });

        if (result?.lead_type) {
          let option = leadTypes[result.lead_type - 1];
          setselectedLeadType(option);
        }
        else {

          setselectedLeadType(null);
        }
        setmasterObject(result);
      });



  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "day",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Category",
      //   field: "formtype1",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Type",
        field: "lead_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "followup_status",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Follow Up On",
      //   field: "followup_on",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  };
  const followup_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Next Followup",
        field: "next_followup",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ref.No",
        field: "ref_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comments",
        field: "comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "username",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followupTableData
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "lead_source":
        setSelectedSource(selected);
        setmasterObject({
          ...masterObject,
          lead_source: selected.value
        });

        break;
      case "lead_type":
        setselectedLeadType(selected);
        setmasterObject({
          ...masterObject,
          lead_type: selected.value
        });
        $("#lead_type").attr("class", "select2-selection");
        setleadtypeErr("");

        break;
      case "country":
        setSelectedCountry(selected);
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, country: selected.value }

        });
        break;
      case "category":
        setSelectedLeadFor(selected);
        let arr = []
        selected && selected.map((element) => {
          arr.push(element.value)
        });
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, category: arr }
        });
        break;
      case "businesstype":
        setSelectedBtype(selected);
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, businesstype: selected.value }
        });
        break;
      case "timeperiod":
        setSelectedTime(selected);
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, timeperiod: selected.value }
        });
        break;
      case "feedback":
        setSelectedFeedback(selected);
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, feedback: selected.value }
        });
        break;
      case "learnfrom":
        setSelectedFrom(selected);
        setmasterObject({
          ...masterObject,
          crm: { ...masterObject.crm, learnfrom: selected.value }
        });
        break;
      case "mobile":
        setselectedMobile(selected);

        if (selected) {
          setselectedName({ label: selected.name, value: selected.name });
          if (typeof selected.id !== "undefined") {
            setmasterObject({
              ...masterObject,
              reg_mobile: selected.value,
              reg_name: selected.name,
              reg_email: selected.email,
              reg_city: selected.city,
            });
          }
          else {
            setmasterObject({
              ...masterObject,
              reg_mobile: selected.value,
            });
          }

          $("#reg_mobile").attr("class", "select2-selection");
          setMobError("");
        }
        else {
          setmasterObject({
            ...masterObject,
            reg_mobile: "",
          });
          $("#reg_mobile").attr("class", "select2-selection border border-danger");
          setMobError("Mobile is required");
        }

        break;
      case "name":

        setselectedName(selected);
        if (selected) {
          setselectedMobile({ label: selected.mobile, value: selected.mobile });
          if (typeof selected.id !== "undefined") {
            setmasterObject({
              ...masterObject,
              reg_mobile: selected.mobile,
              reg_name: selected.value,
              reg_email: selected.email,
              reg_city: selected.city,
            });
          }
          else {
            setmasterObject({
              ...masterObject,
              reg_name: selected.value,
            });
          }

          $("#reg_name").attr("class", "select2-selection");
          setNameError("");
        }
        else {
          setmasterObject({
            ...masterObject,
            reg_name: "",
          });
          $("#reg_name").attr("class", "select2-selection border border-danger");
          setNameError("Name is required");
        }

        break;
      default:
        break;
    }

  }
  const handleChangeBasic = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    axios
      .get(`${API_URL}newLeads/basic?reg_mobile=${value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data
        if (data != null) {
          setmasterObject({
            ...masterObject,
            reg_mobile: data?.reg_mobile,
            reg_name: data?.reg_name,
            reg_email: data?.reg_email
          });
        } else {
          setmasterObject({
            ...masterObject,
            [name]: value
          });
        }

      })

  }
  const handleChangeCrm = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({
      ...masterObject,
      crm: { ...masterObject.crm, [name]: value }
    });
  }
  const handleChangeLead = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({
      ...masterObject,
      [name]: value
    });
  }

  const handleValidSubmit = () => {
    var block = 0;
    if (typeof masterObject.reg_name !== "undefined" && masterObject.reg_name != "") {
      $("#reg_name").attr("class", "select2-selection");
      setNameError("");
    }
    else {
      $("#reg_name").attr("class", "select2-selection border border-danger");
      setNameError("Name is required");
      block = 1;
    }
    if (typeof masterObject.reg_mobile !== "undefined" && masterObject.reg_mobile != "") {
      $("#reg_mobile").attr("class", "select2-selection");
      setMobError("");
    }
    else {
      $("#reg_mobile").attr("class", "select2-selection border border-danger");
      setMobError("Mobile is required");
      block = 1;
    }

    if (!selectedLeadType) {
      $("#lead_type").attr("class", "select2-selection border border-danger");
      setleadtypeErr("Type is required");
      block = 1;
    }
    if (block == 1) {
      return;
    }
    if (leadIdTobeUpdated) {
      axios
        .put(
          `${API_URL}newLeads`,
          masterObject,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Lead updated successfully");
            // handleTableData();
            reset();
            setLeadIdTobeUpdated(null);
            let from_date = searchObject?.from_date ? searchObject.from_date : "";
            let to_date = searchObject?.to_date ? searchObject.to_date : "";
            let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
            let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
            let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
            let type = searchObject?.lead_type ? searchObject.lead_type : "";
            let option = followupStatus;
            handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);
          } else {
            toastr.error("Failed to update Lead");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}newLeads`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Lead added succesfully");
            reset()
            let from_date = searchObject?.from_date ? searchObject.from_date : "";
            let to_date = searchObject?.to_date ? searchObject.to_date : "";
            let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
            let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
            let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
            let option = followupStatus;
            let type = searchObject?.lead_type ? searchObject.lead_type : "";
            handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);

          } else {
            toastr.error("Failed to add Lead");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handleFollowupSubmit = () => {
    if (typeof followupObject.status !== "undefined") {
      $("#followupstatus").attr("class", "select2-selection");
      setfollowupstatusError("");
    }
    else {
      $("#followupstatus").attr(
        "class",
        "select2-selection border border-danger"
      );
      setfollowupstatusError("Select Status");
      return;
    }
    axios
      .put(`${API_URL}newLeads/followup`, followupObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        formRef1.current.reset();
        setFollowupObject({
          ...followupObject,
          status: null,
          status_id: null,
          ref_no: "",
          comment: ""
        })
        handleClickOpen({ _id: followupObject.lead_id });
        toastr.success("Submitted Successfully");
      })
      .catch((err) => {
        toastr.error("Something went wrong");
      });;

  }
  const followreset = () => {
    formRef1.current.reset();
    setFollowupObject({
      ...followupObject,
      status: null,
      status_id: null,
      ref_no: "",
      comment: ""
    })
  }
  const reset = () => {
    setselectedMobile(null);
    setselectedName(null);
    setSelectedSource(null);
    setSelectedStatus(null);
    setSelectedFeedback(null);
    setSelectedFrom(null);
    setSelectedLeadFor(null);
    setSelectedBtype(null);
    setSelectedTime(null);
    setselectedLeadType(null);
    setSelectedCountry({ label: "India", value: 101 });
    formRef.current.reset();
    setmasterObject({ crm: { country: 101 } });

  }
  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}newLeads` + "/" + leadIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Lead deleted successfully");
                  if (
                    masterObject &&
                    masterObject._id === leadIdToBeDeleted
                  ) {
                    formRef.current.reset();
                    setmasterObject({ crm: { country: 101 } });
                    setSelectedSource(null);
                    setSelectedStatus(null);
                    setSelectedFeedback(null);
                    setSelectedFrom(null);
                    setSelectedLeadFor(null);
                    setSelectedBtype(null);
                    setSelectedTime(null);
                    setSelectedCountry({ label: "India", value: 101 });
                  }
                  let from_date = searchObject?.from_date ? searchObject.from_date : "";
                  let to_date = searchObject?.to_date ? searchObject.to_date : "";
                  let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
                  let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
                  let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
                  let option = followupStatus;
                  let type = searchObject?.lead_type ? searchObject.lead_type : "";
                  handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);
                } else {
                  toastr.error(
                    res.data.message,
                    "Failed to delete Lead"
                  );
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Leads" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Leads Category
                          </Label>
                          <Select
                            isMulti
                            name="category"
                            value={selectedLeadFor}
                            options={leadFor}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "category");
                            }}
                          />

                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Leads Type
                          </Label>
                          <Select
                            name="lead_type"
                            id="lead_type"
                            value={selectedLeadType}
                            options={leadTypes}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "lead_type");
                            }}
                          />
                          <div className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {leadtypeErr != ""
                              ? leadtypeErr
                              : ""}
                          </div>
                        </div>

                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Country
                          </Label>
                          <Select
                            name="country"
                            value={selectedCountry}
                            options={country}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "country");
                            }}
                          />

                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Mobile
                          </Label>
                          {/* <AvField
                             style={customStyles}
                              name="reg_mobile"     
                              placeholder="Mobile Number"
                              type="number"
                              errorMessage="Enter Mobile Number"
                              className="form-control"
                            //  validate={{ tel: true }}                      
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.reg_mobile}
                              // onChange={handleChangeLead}
                              onChange={handleChangeBasic}
                            /> */}
                          <CreatableSelect
                            searchable={true}
                            openOnClick={false}
                            openMenuOnClick={false}
                            name="reg_mobile"
                            id="reg_mobile"
                            value={selectedMobile}
                            options={searchMobileListOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "mobile");
                            }}
                            onCreateOption={handleCreateMobile}
                            isClearable
                          />
                          <div className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {mobError != ""
                              ? mobError
                              : ""}
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Name
                          </Label>
                          {/* <AvField
                             style={customStyles}
                              name="reg_name"
                              placeholder="Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.reg_name}
                             onChange={handleChangeLead}
                            /> */}
                          <CreatableSelect
                            searchable={true}
                            openOnClick={false}
                            openMenuOnClick={false}
                            name="reg_name"
                            id="reg_name"
                            value={selectedName}
                            options={searchNameListOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "name");
                            }}
                            onCreateOption={handleCreateName}
                            isClearable
                          />
                          <div className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {nameError != ""
                              ? nameError
                              : ""}
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Email
                          </Label>
                          <AvField
                            style={customStyles}
                            name="reg_email"
                            placeholder="Email"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={masterObject?.reg_email}
                            onChange={handleChangeLead}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Landline
                          </Label>
                          <AvField
                            style={customStyles}
                            name="landline"
                            placeholder="LandLine Number"
                            type="number"
                            className="form-control"
                            //  validate={{ tel: true }}                      
                            id="validationCustom01"
                            value={masterObject?.crm?.landline}
                            onChange={handleChangeCrm}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Address
                          </Label>
                          <AvField
                            style={customStyles}
                            name="address"
                            placeholder="Address"
                            type="textarea"
                            className="form-control"
                            //  validate={{ tel: true }}                      
                            id="validationCustom01"
                            //  
                            value={masterObject?.crm?.address}
                            onChange={handleChangeCrm}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Website
                          </Label>
                          <AvField
                            style={customStyles}
                            name="website"
                            placeholder="Website"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={masterObject?.crm?.website}
                            onChange={handleChangeCrm}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Company Name
                          </Label>
                          <AvField
                            style={customStyles}
                            name="companyname"
                            placeholder="Company Name"
                            type="text"
                            className="form-control"
                            //  validate={{ tel: true }}                      
                            id="validationCustom01"
                            value={masterObject?.crm?.companyname}
                            onChange={handleChangeCrm}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Business Type
                          </Label>
                          <Select
                            name="businesstype"
                            value={selectedBtype}
                            options={btype}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "businesstype");
                            }}
                          />
                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Timeperiod
                          </Label>
                          <Select
                            name="timeperiod"
                            value={selectedTime}
                            options={time}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "timeperiod");
                            }}
                          />
                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Where did you learn about SRV?
                          </Label>
                          <Select
                            name="learnfrom"
                            value={selectedFrom}
                            options={from}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "learnfrom");
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Feedback
                          </Label>
                          <Select
                            name="feedback"
                            value={selectedFeedback}
                            options={feedback}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "feedback");
                            }}
                          />
                        </div>
                      </Col>




                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Source
                          </Label>
                          <Select
                            name="lead_source"
                            value={selectedSource}
                            options={source}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "lead_source");
                            }}
                          />

                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Comments
                          </Label>
                          <AvField
                            style={customStyles}
                            name="comment"
                            placeholder="Comments"
                            type="textarea"
                            className="form-control"
                            id="validationCustom01"
                            value={masterObject?.crm?.comment}
                            onChange={handleChangeCrm}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          {leadIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addLeads ? true : false}
                            >
                              {addLeads ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                              disabled={addLeads ? true : false}
                            >
                              {addLeads ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() =>
                              reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <Row className="mb-3">
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          From
                        </Label>
                        <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchObject?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          To
                        </Label>
                        <input
                          style={customStyles}
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchObject?.to_date}
                          min={searchObject?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Type
                        </Label>
                        <Select
                          name="lead_type"
                          value={selectedsearchType}
                          onChange={(selected) => {
                            handleSelectSearchChange(selected, "lead_type");
                          }}
                          options={leadTypes}

                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Source
                        </Label>
                        <Select
                          name="lead_source"
                          value={selectedSearchSource}
                          onChange={(selected) => {
                            handleSelectSearchChange(selected, "lead_source");
                          }}
                          options={source}

                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          From
                        </Label>
                        <Select
                          name="lead_ref_form_id"
                          value={selectedSearchForm}
                          onChange={(selected) => {
                            handleSelectSearchChange(selected, "lead_ref_form_id");
                          }}
                          options={form}

                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">
                          Status
                        </Label>
                        <Select
                          name="followup_status"
                          value={selectedSearchStatus}
                          onChange={(selected) => {
                            handleSelectSearchChange(selected, "followup_status");
                          }}
                          options={followupStatus}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col
                      md="2"
                      // className="mt-4"
                      style={{ paddingTop: "25px" }}
                    >
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => handleSearch()}
                        >
                          {"  "}
                          Filter
                        </Button>
                        <Button
                          color="danger"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => resetSearch()}
                        >
                          {"  "}
                          Reset
                        </Button>

                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3" >
                        <Button
                          color="success"
                          type="submit"
                          style={{ float: "right" }}
                        >
                          <CSVLink
                            data={dataToBeExported}

                            filename={
                              "leads_report_" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            {"  "}
                            Export
                          </CSVLink>
                        </Button>
                      </div>

                    </Col>

                  </Row>
                  <MDBDataTable
                    id="leadsTableID"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={showPopup} size="xl" centered={true} className="modal-fullscreen">
        <div className="modal-header">
          <div className="modal-title">Enquiry <b>#{leadDetails?.lead_unique_id}</b> On <b>{leadDetails?.day}</b></div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
              let from_date = searchObject?.from_date ? searchObject.from_date : "";
              let to_date = searchObject?.to_date ? searchObject.to_date : "";
              let lead_source = searchObject?.lead_source ? searchObject.lead_source : "";
              let followup_status = searchObject?.followup_status ? searchObject.followup_status : "";
              let lead_ref_form_id = searchObject?.lead_ref_form_id ? searchObject.lead_ref_form_id : "";
              let type = searchObject?.lead_type ? searchObject.lead_type : "";
              let option = followupStatus;
              //handleTableData(getFirstday(new Date()), getDate(new Date()), "", "", "", followupStatus)
              handleTableData(from_date, to_date, lead_source, lead_ref_form_id, followup_status, option, type);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <table id="enquiryModalTable">
                    <tr>
                      <td><b>Name</b></td><td>: {leadDetails?.name}</td>
                      <td><b>Mobile</b></td><td>: {leadDetails?.mobile}</td>
                      <td><b>Email</b></td><td>: {leadDetails?.email}</td>
                      <td><b>City</b></td><td>: {leadDetails?.reg_city}</td>
                    </tr>
                    {/* </table>
                </CardBody>
                <CardHeader><b>Enquiry Details</b></CardHeader>
                <CardBody>
                <table id="enquiryModalTable"> */}
                    {
                      (leadDetails?.formtypeid) == 1 ? (
                        <>
                          <tr>
                            <td><b>Source</b></td><td>: {leadDetails?.source}</td>
                            <td><b>Enquiry Category</b></td><td>: {leadDetails?.form}</td>
                            <td><b>Category</b></td><td>: {leadDetails?.cate}</td>
                            <td><b>Country</b></td><td>: {leadDetails?.cotys}</td>
                          </tr>
                          <tr>

                            <td><b>Landline No</b></td><td>: {leadDetails?.land}</td>
                            <td><b>Address</b></td><td>: {leadDetails?.adds}</td>
                            <td><b>Website</b></td><td>: {leadDetails?.webi}</td>
                            <td><b>Company</b></td><td>: {leadDetails?.compy}</td>
                          </tr>
                          <tr>
                            <td><b>Business Type</b></td><td>: {leadDetails?.btp}</td>
                            <td><b>Timeperiod</b></td><td>: {leadDetails?.timep}</td>
                            <td><b>Learn From</b></td><td>: {leadDetails?.lfrom}</td>
                            <td><b>Feedback</b></td><td>: {leadDetails?.feed}</td>
                          </tr>
                        </>
                      ) : ""
                    }
                    {
                      ((leadDetails?.formtypeid == 2) || (leadDetails?.formtypeid == 3) || (leadDetails?.formtypeid == 4)) ? (
                        <>
                          <tr>
                            <td><b>Source</b></td><td>: {leadDetails?.source}</td>
                            <td><b>Enquiry Category</b></td><td>: {leadDetails?.form}</td>
                            <td><b>Source Page</b></td><td>: {leadDetails?.pages}</td>
                          </tr>
                        </>
                      ) : ""
                    }
                    {
                      (leadDetails?.formtypeid == 5) ? (
                        <>
                          <tr>
                            <td><b>Source</b></td><td>: {leadDetails?.source}</td>
                            <td><b>Enquiry Category</b></td><td>: {leadDetails?.form}</td>
                            <td><b>Requirement</b></td><td>: {leadDetails?.general?.general_requirement}</td>
                            <td><b>Source Page</b></td><td>: {leadDetails?.pages}</td>
                          </tr>
                        </>
                      ) : ""
                    }
                    {
                      (leadDetails?.formtypeid == 6) ? (
                        <>
                          <tr>
                            <td><b>Source</b></td><td>: {leadDetails?.source}</td>
                            <td><b>Category</b></td><td>: {leadDetails?.form}</td>
                            <td><b>Source Page</b></td><td>: {leadDetails?.pages}</td>
                            <td><b>How did find us</b></td><td>: {leadDetails?.quote?.qt_source}</td>
                          </tr>
                          <tr>
                            <td><b>Business</b></td><td>: {leadDetails?.quote?.quote_business_type}</td>
                            <td><b>Website</b></td><td>: {leadDetails?.quote?.quote_website_url}</td>
                            <td><b>Timeline</b></td><td>: {leadDetails?.quote?.qt_timeline}</td>
                          </tr>
                          <tr>
                            <td><b>Company</b></td><td colspan="3">: {leadDetails?.quote?.quote_company}</td>
                            <td><b>Competition Website</b></td><td colspan="3">: {leadDetails?.quote?.quote_competition_website}</td>
                          </tr>
                        </>
                      ) : ""
                    }
                    {
                      (leadDetails?.formtypeid == 7) ? (
                        <>
                          <tr>
                            <td><b>Source</b></td><td>: {leadDetails?.source}</td>
                            <td><b>Enquiry Category</b></td><td>: {leadDetails?.form}</td>
                            <td><b>Source Page</b></td><td>: {leadDetails?.pages}</td>
                          </tr>
                          <tr>
                            <td><b>Company</b></td><td colSpan={7}>: {leadDetails?.schedule_demo?.demo_message}</td>

                          </tr>
                        </>
                      ) : ""
                    }

                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody style={{ padding: "10px" }}>
                  <AvForm
                    ref={formRef1}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleFollowupSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="2">
                        <Label>Status</Label>
                        <Select
                          id="followupstatus"
                          name="followupstatus"
                          value={followupObject?.status}
                          onChange={(selected) => {
                            handleFollowupData(selected, "followup_status");
                          }}
                          options={followupStatus}
                          classNamePrefix="select2-selection"
                          menuPosition="fixed"
                        />
                        <div className="text-danger"
                          style={{ fontSize: "11px" }}
                        >
                          {followupstatusError != ""
                            ? followupstatusError
                            : ""}
                        </div>
                      </Col>
                      <Col md="2" style={{ display: (followupObject?.status_id == "62c7d7117333e58969b3de0b" || followupObject?.status_id == "6392cbe02d917dfcd065310a") ? "block" : "none" }}>
                        <Label>Ref.No</Label>
                        <AvField type="text" className="form-control" name="ref_no" value={followupObject?.ref_no} placeholder="Ref. No"
                          onChange={(e) => {
                            handleFollowupData(e, "ref_no");
                          }}
                          // validate={{ required: { value: true } }}
                          errorMessage="Enter Ref.No" />
                      </Col>
                      <Col md="2" >
                        <Label>Next Followup</Label>
                        <AvField type="date" className="form-control" name="next_followup" value={followupObject?.nextfollowup} placeholder="Next Followup"
                          onChange={(e) => {
                            handleFollowupData(e, "next_followup");
                          }}
                        // validate={{ required: { value: true } }}
                        />
                      </Col>
                      <Col md="3">
                        <Label>Comments</Label>
                        <AvField
                          name="follow_comment"
                          placeholder="Comments"
                          type="textarea"
                          className="form-control"
                          value={followupObject?.comment}
                          onChange={(selected) => {
                            handleFollowupData(selected, "comment");
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage="Enter your comments"
                          rows="1"
                        />
                      </Col>
                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "10px" }}
                        >
                          Submit
                        </Button>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => {
                            followreset();
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>

            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="followupModalTable"
                    responsive
                    bordered
                    data={followup_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
};



export default Newleads;