import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Items.scss";

import Modal from "react-bootstrap/Modal";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";
import { result } from "lodash";

const Items = () => {
  const [itemsObject, setItemsObject] = useState({});
  const [itemsIdTobeUpdated, setItemsIdToBeUpdated] = useState(null);
  const [itemsIdToBeDeleted, setItemsIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [itemsForTable, setItemsForTable] = useState([]);
  const [itemsValue, setitemsValue] = useState("");

  const [selectedValType, setSelectedValType] = useState(null);

  const [subCatType, setSubCatType] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState(null);

  const [selectedRenType, setSelectedRenType] = useState(null);

  const [selectedStatusType, setSelectedStatusType] = useState(null);

  const [taxPer, setTaxPer] = useState([]);
  const [selectedTaxPer, setSelectedTaxPer] = useState([]);

  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);

  const [errors, setErrors] = useState({
    subCatetError: "",
    taxError: "",
    validityError: "",
    renewableError: "",
    statusError: "",
  });

  const {
    districts,
    addingItems,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_subCatType();
    fetch_all_taxPer();
  }, []);

  function handleTableData() {
    var url = `${API_URL}item/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let itemsData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateItems(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setItemsIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          item.Validity = item.validity_no + "  " + item.validity;
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.itemsup_date)
            item.date = moment(item.itemsup_date).format("DD-MM-YYYY");
          else item.date = moment(item.items_date).format("DD-MM-YYYY");

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a");
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          }

          // if (item?.time)
          //   item.time = moment(item.time, "HHmmss").format("hh:mm a");
          // else item.time = "";

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          itemsData.push(item);
        });
        setItemsForTable(itemsData);
      });
  }

  let preUpdateItems = (item) => {
    setitemsValue(item?.name);

    axios
      .get(`${API_URL}item/?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let SubCate = result?.subcategory ? result.subcategory : {};
        let subCateg = SubCate?.name;
        let MainVate = result?.maincateg ? result.maincateg : {};
        let MainCateg = MainVate?.name;

        let bothCateg = subCateg + "-" + MainCateg;

        let itemsValue = {
          label: bothCateg,
          hsn_sac: result?.hsn_sac,
          taxper: result?.taxper,
        };

        let TaxPerValue = result?.taxper ? result.taxper : "" || {};
        let TaxPer = TaxPerValue?.tax;

        let taxValue = {
          label: TaxPer,
          value: result?._id,
        };

        let ItemStatus = result?.productstatus ? result.productstatus : "";

        let itemValue = {
          label: ItemStatus,
          value: result?._id,
        };

        // result?.validity_no = result?.validity_no;

        setSelectedStatusType(itemValue);

        setSelectedTaxPer(taxValue);

        setSelectedSubCat(itemsValue);
      });

    let values = {};
    if (item?.validity === "Year") {
      values = {
        label: "Year",
        value: 0,
      };
    } else if (item?.validity === "Month") {
      values = {
        label: "Month",
        value: 1,
      };
    } else if (item?.validity === "Day") {
      values = {
        label: "Day",
        value: 2,
      };
    }
    setSelectedValType(values);

    let Renvalues = {};
    if (item?.renewable === "Yes") {
      Renvalues = {
        label: "Yes",
        value: 0,
      };
    } else if (item?.renewable === "No") {
      Renvalues = {
        label: "No",
        value: 1,
      };
    }
    setSelectedRenType(Renvalues);

    let Statusvalues = {};
    if (item?.productstatus === "active") {
      Statusvalues = {
        label: "active",
        value: 0,
      };
    } else if (item?.productstatus === "inactive") {
      Statusvalues = {
        label: "inactive",
        value: 1,
      };
    } else if (item?.productstatus === "expired") {
      Statusvalues = {
        label: "expired",
        value: 2,
      };
    }
    setSelectedStatusType(Statusvalues);

    setItemsIdToBeUpdated(item._id);
    setItemsObject(item);
  };

  function fetch_all_subCatType() {
    axios
      .get(`${API_URL}subCateg/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var subCatType_data =
          res.data.data &&
          res.data.data.map((el) => {
            let SubCate = el?.maincategory || ({} && el?.name);
            el.subcateg = el?.name + "-" + SubCate?.name;

            let TaxPer = el?.taxper || {};
            let Typ = {
              label: TaxPer?.tax,
              value: TaxPer?._id,
            };

            return {
              label: el?.subcateg,
              value: el?._id,
              hsn_sac: el?.hsn_sac,
              taxper: Typ,
              displayLabel: el?.name,
            };
          });
        setSubCatType([{ options: subCatType_data }]);
      });
  }

  function fetch_all_taxPer() {
    axios
      .get(`${API_URL}tax/Option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var taxPer_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.tax,
              value: el._id,
            };
          });
        setTaxPer([{ options: taxPer_data }]);
      });
  }

  const handleSelectChange = (selected, name) => {
    // let subCate_id =
    switch (name) {
      case "validity":
        setSelectedValType(selected);
        setItemsObject({
          ...itemsObject,
          validity: selected.label,
        });
        setErrors({
          ...errors,
          validityError: "",
        });

        break;
      case "name":
        let data = {
          label: selected?.label,
          value: selected?.value,
          hsn_sac: selected?.hsn_sac,
          // taxper: selected?.taxper,
        };
        setSelectedSubCat(data);
        setSelectedTaxPer(selected?.taxper);
        setItemsObject({
          ...itemsObject,
          hsn_sac: selected?.hsn_sac,
          taxper: selected?.taxper.value,
          subcategory: selected.value,
        });
        setErrors({
          ...errors,
          subCatetError: "",
        });
        break;
      case "Renewable":
        setSelectedRenType(selected);
        setItemsObject({
          ...itemsObject,
          renewable: selected.label,
        });
        setErrors({
          ...errors,
          renewableError: "",
        });
        break;
      case "Productstatus":
        setSelectedStatusType(selected);
        setItemsObject({
          ...itemsObject,
          productstatus: selected.label,
        });
        setErrors({
          ...errors,
          statusError: "",
        });
        break;
      case "tax":
        setSelectedTaxPer(selected);
        setItemsObject({
          ...itemsObject,
          taxper: selected.value,
        });
        setErrors({
          ...errors,
          taxError: "",
        });
        break;

      default:
        break;
    }
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Product ID",
        field: "productcode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sub Category",
        field: "subcategory_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Validity",
        field: "Validity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Renewable",
        field: "renewable",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "productstatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: itemsForTable,
  };

  function handleChangeItems(e) {
    let name = e.target.name;
    let value = e.target.value;
    setitemsValue(value);
    setItemsObject({ ...itemsObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (
      selectedSubCat === null ||
      selectedTaxPer === null ||
      selectedValType === null ||
      selectedRenType === null ||
      selectedStatusType === null
    ) {
      let errorVal = errors;
      if (selectedSubCat === null)
        errorVal.subCatetError = "Please Select Subcategory";
      if (selectedTaxPer === null) errorVal.taxError = "Please Select Tax";
      if (selectedValType === null)
        errorVal.validityError = "Please Select Validity";
      if (selectedRenType === null)
        errorVal.renewableError = "Please Select Renewable";
      if (selectedStatusType === null)
        errorVal.statusError = "Please Select Status";
      setErrors({
        ...errorVal,
      });
    } else {
      if (itemsIdTobeUpdated) {
        // let bodydata = {
        //   id: designationIdTobeUpdated,
        //   name: designationObject.name,
        // };

        axios
          .put(`${API_URL}item`, itemsObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Items updated successfully");
              setitemsValue("");
              formRef.current.reset();
              handleTableData();
              setItemsObject({});
              setItemsIdToBeUpdated(null);
              setSelectedValType(null);
              setSelectedRenType(null);
              setSelectedStatusType(null);
              setSelectedSubCat(null);
              setSelectedTaxPer(null);
              setErrors({
                subCatetError: "",
                taxError: "",
                validityError: "",
                renewableError: "",
                statusError: "",
              });
            } else {
              toastr.error("Failed to update Items");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}item`, itemsObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Items added succesfully");
              handleTableData();
              setitemsValue("");
              formRef.current.reset();
              setItemsObject({});
              setSelectedValType(null);
              setSelectedRenType(null);
              setSelectedStatusType(null);
              setSelectedSubCat(null);
              setSelectedTaxPer(null);
              setErrors({
                subCatetError: "",
                taxError: "",
                validityError: "",
                renewableError: "",
                statusError: "",
              });
            } else {
              toastr.error("Failed to add Items");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}item/?_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        if (result?.up_date)
          result.date1 = moment(result.up_date).format("DD-MM-YYYY");
        else result.date1 = moment(result.date).format("DD-MM-YYYY");

        console.log("Date<>", item?.date);

        let addedBy = result?.addedby || {};
        if (addedBy?.lastName)
          result.staff = addedBy.firstName + " " + addedBy.lastName;
        else result.staff = addedBy?.firstName;

        let TaxPerValue = result?.taxper || {};
        result.Taxper = TaxPerValue?.tax;

        // let updatedBy = result?.updatedby || {};
        // if (updatedBy?.lastName)
        //   result.staff1 = updatedBy.firstName + " " + updatedBy.lastName;
        // else result.staff1 = updatedBy?.firstName;
        let updatedBy = result?.updatedby || {};
        if (updatedBy) {
          if (updatedBy?.lastName)
            result.staff1 = updatedBy.firstName + " " + updatedBy.lastName;
          else result.staff1 = updatedBy?.firstName;
        } else {
          // check addedby
        }

        setPopupData(result);
        setPopupView(true);
      });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {/* View Modal Start */}
        <Modal show={popupView} size="lg" centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Item Details </h5>
            <button
              type="button"
              onClick={() => {
                setPopupView(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px" }}>Product ID :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.productcode}
                  </td>
                  <td style={{ width: "184px", padding: "10px" }}>
                    Item Name :
                  </td>
                  <td style={{ textAlign: "left" }}>{popupData?.name}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Subcategory Name:</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.subcategory_name}
                  </td>
                  <td style={{ padding: "10px" }}>Tax :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.Taxper}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Validity No. :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.validity_no}
                  </td>
                  <td style={{ padding: "10px" }}>Validity :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.validity}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Renewable :</td>
                  <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                    {popupData?.renewable}
                  </td>
                  <td style={{ padding: "10px" }}>Cost :</td>
                  <td
                    style={{
                      textAlign: "left",
                      width: "189.97px",
                      wordBreak: "break-all",
                    }}
                  >
                    {popupData?.cost}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>MRP :</td>
                  <td>{popupData?.mrp}</td>
                  <td style={{ padding: "10px" }}>Reseller Price :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.resellerprice}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Rate :</td>
                  <td>{popupData?.rate}</td>
                  <td style={{ padding: "10px" }}>HSN/SAC :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.hsn_sac}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px" }}>Product Status :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupData?.productstatus}
                  </td>
                  <td style={{ padding: "10px" }}>Date :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.date1}</td>
                </tr>

                <tr>
                  <td style={{ padding: "10px" }}>Added By Staff :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
                  <td style={{ padding: "10px" }}>Updated By Staff :</td>
                  <td style={{ textAlign: "left" }}>{popupData?.staff1}</td>
                </tr>
                <tr hidden={popupData?.application_current_status !== 3}>
                  <td style={{ padding: "10px" }}>Status :</td>
                  <td>{"Declined"}</td>
                  <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                  <td
                    style={{
                      textAlign: "left",
                      width: "189.97px",
                      wordBreak: "break-all",
                    }}
                  >
                    {popupData?.decline_remark}
                  </td>
                </tr>
              </table>
            </div>
          </AvForm>
        </Modal>
        {/* View Modal End */}
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}item` + "/" + itemsIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Items deleted successfully");
                    if (itemsObject && itemsObject._id === itemsIdToBeDeleted) {
                      setitemsValue("");
                      formRef.current.reset();
                      setItemsObject({});
                      setItemsIdToBeUpdated(null);
                      setSelectedSubCat(null);
                      // setErrors({
                      //   subCatetError: "",
                      //   taxError: "",
                      //   validityError: "",
                      //   renewableError: "",
                      //   statusError: "",
                      // });
                    }
                    handleTableData();
                  } else {
                    toastr.error(res.data.message, "Failed to delete Items");
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Items" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Item Code
                            </Label>
                            <AvField
                              style={customStyles}
                              name="productcode"
                              placeholder="Item Code"
                              type="text"
                              errorMessage="Enter Product Code"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.productcode}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Item Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Item name"
                              type="text"
                              errorMessage="Enter Item Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={itemsObject?.name}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Subcategory</Label>
                            <Select
                              name="name"
                              errorMessage="Select Category"
                              value={selectedSubCat}
                              onChange={(selected) => {
                                handleSelectChange(selected, "name");
                              }}
                              options={subCatType}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedSubCat === null
                                ? errors.subCatetError
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">HSN/SAC</Label>
                            <AvField
                              style={customStyles}
                              name="hsn_sac"
                              placeholder="HSN/SAC"
                              type="number"
                              errorMessage="Enter HSN/SAC"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              onChange={handleChangeItems}
                              value={
                                selectedSubCat?.hsn_sac
                                  ? selectedSubCat.hsn_sac
                                  : ""
                              }
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Tax</Label>
                            <AvField
                              style={customStyles}
                              name="taxper"
                              placeholder="taxper"
                              type="text"
                              errorMessage="Enter taxper"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              onChange={handleChangeItems}
                              value={
                                selectedSubCat?.taxper
                                  ? selectedSubCat.taxper
                                  : ""
                              }
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Tax</Label>
                            <Select
                              name="tax"
                              errorMessage="Select Tax"
                              value={selectedTaxPer ? selectedTaxPer : ""}
                              onChange={(selected) => {
                                handleSelectChange(selected, "tax");
                              }}
                              options={taxPer}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedTaxPer === null ? errors.taxError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Validity No.
                            </Label>
                            <AvField
                              style={customStyles}
                              name="validity_no"
                              placeholder="validity No."
                              type="number"
                              errorMessage="Enter validity No."
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.validity_no}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Validity</Label>
                            <Select
                              name="validity"
                              errorMessage="Select validity Type"
                              value={selectedValType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "validity");
                              }}
                              options={[
                                {
                                  label: "Year",
                                  value: 0,
                                },
                                {
                                  label: "Month",
                                  value: 1,
                                },
                                {
                                  label: "Day",
                                  value: 2,
                                },
                                {
                                  label: "Quarter",
                                  value: 3,
                                },
                                {
                                  label: "Hour",
                                  value: 4,
                                },
                                {
                                  label: "Second",
                                  value: 5,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedValType === null
                                ? errors.validityError
                                : ""}
                            </p>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Renewable</Label>
                            <Select
                              name="Renewable"
                              errorMessage="Select Renewable Type"
                              value={selectedRenType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Renewable");
                              }}
                              options={[
                                {
                                  label: "Yes",
                                  value: 0,
                                },
                                {
                                  label: "No",
                                  value: 1,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedRenType === null
                                ? errors.renewableError
                                : ""}
                            </p>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">MRP</Label>
                            <AvField
                              style={customStyles}
                              name="mrp"
                              placeholder="MRP"
                              type="number"
                              errorMessage="Enter MRP"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.mrp}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Cost</Label>
                            <AvField
                              style={customStyles}
                              name="cost"
                              placeholder="Cost"
                              type="number"
                              errorMessage="Enter Cost"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.cost}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Rate</Label>
                            <AvField
                              style={customStyles}
                              name="rate"
                              placeholder="Rate"
                              type="number"
                              errorMessage="Enter Rate"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.rate}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Reseller Price
                            </Label>
                            <AvField
                              style={customStyles}
                              name="resellerprice"
                              placeholder="Reseller Price"
                              type="number"
                              errorMessage="Enter Reseller Price"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={itemsObject?.resellerprice}
                              onChange={handleChangeItems}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Item Status</Label>
                            <Select
                              name="Productstatus"
                              errorMessage="Select Product Status"
                              value={selectedStatusType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "Productstatus");
                              }}
                              options={[
                                {
                                  label: "Active",
                                  value: 0,
                                },
                                {
                                  label: "Inactive",
                                  value: 1,
                                },
                                {
                                  label: "Expired",
                                  value: 2,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedStatusType === null
                                ? errors.statusError
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {itemsIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingItems ? true : false}
                              >
                                {addingItems ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingItems ? true : false}
                              >
                                {addingItems ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}

                    {/* <Row>
                      <Col className="col-2" style={{ paddingTop: "4px" }}>
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="itemTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Items;