import React,{ useState, useEffect, useRef } from "react";
import { Card,CardBody,CardHeader,Row,Col,Progress} from "reactstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
const TVEventDashboard = (props)=>{
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [details, setDetails] = useState({});
    const [totEvents,settotEvents] = useState(null);
    const [counter, setCounter] = useState(1);
    const handleTableData = () => {
        axios
        .get(`${API_URL}tv/event_view`, {
          headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            let result = res.data.data;
            settotEvents(res.data.count);
             if(result.length>0)
             {
                setDetails(result[0]);
             }         
          });
      };

    useEffect(()=>{
      handleTableData();
    },[])
    useEffect(()=>{
      const interval = setInterval(() => {
              handleTableData();
        }, 10000);
  },[])
    return(
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col md="12" style={{marginBottom:"10px"}}>
                                    <center>
                                        <img src={`${API_URL}uploads/events/${details.image}`} className="event-image"></img>
                                    </center>
                               
                        </Col>
                    </Row>
                </div>
            </div>

        </React.Fragment>
    )
}
export default TVEventDashboard;