import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import toastr from "toastr";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Testimonial.scss";
import { Grid } from "@mui/material";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

const Testimonial = () => {
  const [masterObject, setmasterObject] = useState({});
  const [techIdTobeUpdated, setTechIdToBeUpdated] = useState(null);
  const [techIdToBeDeleted, setTechIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [techForTable, setTechForTable] = useState([]);
  const [techValue, settechValue] = useState("");
  const [uploadProgress, setUploadProgress] = useState();
  
  const [client, setClient] = useState([]);//options fetching 
  const [selectedClient, setSelectedClient] = useState(null);//selecting option
  
  const [errors, setErrors] = useState({
    clientError: "",
    imageError:"",
  });//client+image

  const [showPopup, setShowPopup] = useState(false);//for model
  const [testDetails, settestDetails] = useState({});//modelview
  const {
    districts,
    addingtech,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_client();
  }, []);

function fetch_client(){
    axios
    .get(`${API_URL}client/options/`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res)=>{
      var Client=
       res.data.data &&
       res.data.data.map((el)=>{
        return{
          label:el.name,
          value:el._id
        };
       });
       setClient([{options:Client}]) 
    });
}

const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}testimonials/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let Update = result.updatedby || {};
        result.edited =(Update.firstName || '') + (Update.lastName || '')
        let Add = result.addedby || {};
        result.add =(Add.firstName || '') + (Add.lastName || '')
        let Client = result.client_id || {};
        result.client = Client?.name;
        let upTime = result.up_time || {};
        let upDate =result.up_date || {};
        result.update = (moment(upDate).format("DD-MM-YYYY") )+" "+( moment(upTime, "HHmmss").format("hh:mm a"));
        let Time = result.time || {};
        let Date =result.date || {};
        result.day = (moment(Date).format("DD-MM-YYYY") )+" "+( moment(Time, "HHmmss").format("hh:mm a"));
        settestDetails(result);
            setShowPopup(true);
          
      });
       
  };

  function handleTableData() {
    var url = `${API_URL}testimonials/testimonialslist`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let testiData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
            <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpen(item);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateTech(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setTechIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
     
          item.id = index + 1;
        
   if (item?.client_id) {
            item.client = item.client_id.name;
   }
        
          if(typeof item.image!="undefined" && item.image!="")
          {
            item.Img = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={`${API_URL}uploads/testimonials_images/${item.image}`}
                    style={{ height:"25px", cursor: "pointer" }}
                    onClick={()=> window.open(`${API_URL}uploads/testimonials_images/${item.image}`, "_blank")}
                  />
                </div>
              );
          }
          else
          {
            item.Img = "";
          }
          
          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          testiData.push(item);
        });
        setTechForTable(testiData);
      });
  }

  let preUpdateTech = (item) => {
    //console.log("itemupdate", item);
    
   let cli=item?.client_id
    let clientop = {
        label: cli?.name,
        value: cli?._id,
      };
    setSelectedClient(clientop);
    settechValue(item);
    setTechIdToBeUpdated(item?._id);
    setmasterObject(item);
    // axios
    // .get(`${API_URL}testimonials/data?_id=` + item._id, {
    //   headers: {
    //     "x-access-token": accessToken,
    //   },
    // })
    // .then((res) => {
    //   let result = res.data.data;
    // //   let tableData = [];
    // //   result[0].item_checklist &&
    // //     result[0].item_checklist.map((cont, index) => {
    //       let val = {};
    //      let cli = result?.client_id || {};
    //     //   val.checklist_name = Flow?.checklist_name;
    //       val.client_id = cli?._id;
          
    //     //  val.checklist_id = Flow?._id;
    //     //  val.weightage = cont?.weightage;      
    //     //   tableData.push(val);
    //     // });
    //     settechValue(val);
    //     console.log( techValue)

     
    // });
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("testimonials_images", e.target.files[0]);
    axios
      .post(`${API_URL}testimonials/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`image`]: response.data.file.filename,
          });
          setErrors({
            ...errors,
            imageError: "",
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteTechImage = () => {
    setmasterObject({
      ...masterObject,
      [`image`]: "",
    });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Client",
        field: "client",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },

      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 150,
      },

      {
        label: "Image",
        field: "Img",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: techForTable,
  };

  function handleChangeTech(e) {
    let name = e.target.name;
    let value = e.target.value;
    settechValue(value);

    setmasterObject({ ...masterObject, [name]: value });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "client_id":
        setSelectedClient(selected);
        setmasterObject({
          ...masterObject,
          client_id:selected.value,
        });
        setErrors({
          ...errors,
          clientError: "",
        });
        break;
  


      default:
        break;
    }
  };

  const handleValidSubmit = () => {
    if(selectedClient === null){
      let errorVal = errors;
      errorVal.clientError ="Please Select Client";
      setErrors({
        ...errorVal,
      });
    }
    else if(!masterObject.image){
      let errorVal1 = errors;
      errorVal1.imageError ="Please Select Image";
      setErrors({
        ...errorVal1,
      });
    }
    else{
    if (techIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}testimonials`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Testimonial updated successfully");
            settechValue("");

            formRef.current.reset();
            handleTableData();
            setmasterObject({});
            setTechIdToBeUpdated(null);
            setSelectedClient(null);
            setErrors({
              ...errors,
              clientError: "",
              imageError:""
            });
          } else {
            toastr.error("Failed to update Testimonial");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}testimonials`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Testimonial added succesfully");
            handleTableData();
            settechValue("");
            setSelectedClient(null);

            formRef.current.reset();
            setmasterObject({});
            setErrors({
              ...errors,
              clientError: "",
              imageError:""
            });
          } else {
            toastr.error("Failed to add Testimonial");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  }
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}testimonials` + "/" + techIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                 // console.log("res: ", res);
                  if (res.data.status === 200) {
                    toastr.success("Testimonial Deleted successfully");
                    if (masterObject && masterObject._id === techIdToBeDeleted) {
                      settechValue("");

                      formRef.current.reset();
                      setmasterObject({});
                      setTechIdToBeUpdated(null);
                      setSelectedClient(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Testimonial"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <Modal show={showPopup} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Testimonial Details</h5>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
                <tr>
                <td style={{ padding: "10px"}}>Date & Time:</td>
                <td>{testDetails?.day}</td>
                <td>Added By :</td>
                <td style={{ textAlign: "left" }}>{testDetails?.add}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px"}}>
                  Client :
                </td>
                <td>{testDetails?.client}</td>
                <td>Name :</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.name}
                </td>
              </tr>
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                Designation :
                </td>
                <td>{testDetails?.designation}</td>
                <td> URL:</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.url}
                </td>
              </tr>
              <tr hidden={!testDetails?.updatedby}>
                <td style={{ padding: "10px"}}>Updated At :</td>
                <td>{testDetails?.update}</td>
                <td>Updated By :</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.edited}
                </td>
              </tr>
             
              <tr>
                <td style={{padding: "10px"}}>Message :</td> 
                <td colSpan={3} style={{ textAlign: "left" }}>{testDetails?.content}</td>
              </tr>

             
              <tr hidden={!testDetails?.image}>
                <td style={{ padding: "10px"}}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      paddingLeft: "0px",
                      cursor: "pointer" 
                    }}
                    src={`${API_URL}uploads/testimonials_images/${testDetails?.image}`}
                    onClick={()=> window.open(`${API_URL}uploads/testimonials_images/${testDetails.image}`, "_blank")}
                  ></img>
                </td>
              </tr>
             
            </table>
          </div>
        </AvForm>
      </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Testimonials" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                       
                       
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.name}
                              onChange={handleChangeTech}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Designation 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="designation"
                              placeholder="Designation"
                              type="text"
                              errorMessage="Enter Designation"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.designation}
                              onChange={handleChangeTech}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Client  
                            </Label>
                            <Select
                              name="client_id"
                              value={selectedClient}
                              options={client}
                              classNamePrefix ="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "client_id");
                              }}
                            />
                              <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedClient === null ? errors.clientError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              URL 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="url"
                              placeholder="URL"
                              type="text"
                              errorMessage="Enter URL"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.url}
                              onChange={handleChangeTech}
                            />
                          </div>
                        </Col>
                       
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Message 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="content"
                              placeholder="Message"
                              type="textarea"
                              errorMessage="Enter Message"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.content}
                              onChange={handleChangeTech}
                            />
                          </div>
                        </Col>
                        
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {masterObject?.image ? (
                              <div div className="img-wraps">
                                {masterObject.image &&
                                masterObject.image.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${masterObject.image}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}uploads/testimonials_images/${masterObject?.image}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteTechImage}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="testimonials_images"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                              
                            )}
                            {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                            <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!masterObject.image ? errors.imageError : ""}
                          </p>
                          </div>
                        </Col>

                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {techIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingtech ? true : false}
                              >
                                {addingtech ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingtech ? true : false}
                              >
                                {addingtech ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <Row className="mb-3">
                      <MDBDataTable
                        id="testiTableId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default Testimonial;