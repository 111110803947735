import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Container, Progress, Input, CardHeader } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import Offcanvas from 'react-bootstrap/Offcanvas';
import ScrollBar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Link } from "react-router-dom";
import {
  getRouteName,
  timeFromNumber, addTimes, timeInMinute, timeSince
} from "../../helpers/globalFunctions";
import moment from "moment";
const OtherTasks = (props) => {
  const [userId, setUserId] = useState();
  const [taskCategoryOptions, setTaskCategoryOptions] = useState([]);
  const [taskAssigneeOptions, setTaskAssigneeOptions] = useState([]);
  const [taskRows, setTaskRows] = useState([]);
  const [taskIdToBeDeleted, setTaskIdToBeDeleted] = useState({});
  const [confirmDeleteTaskAlert, setConfirmDeleteTaskAlert] = useState(null);
  const [canvasshow, setCanvasShow] = useState(false);
  const handleClose = () => setCanvasShow(false);
  const [attachCount, setAttachCount] = useState("");
  const [msgObject, setMsgObject] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatboxData, setChatboxData] = useState({});
  const [isProjectChat, setisProjectChat] = useState(false);
  const [assignIdToBeDeleted, setAssignIdToBeDeleted] = useState({});
  const [confirmDeleteAssignAlert, setConfirmDeleteAssignAlert] = useState(null);
  const bottomRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const fetchTaskCategory = () => {
    axios
      .get(`${API_URL}other-tasks/category/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
              };
            });
        }
        else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.subcat_name,
                value: el._id,
              };
            });
        }

        setTaskCategoryOptions([{ options: SearchType_data }]);
      });
  }
  const fetchTaskAssignee = () => {
    axios
      .get(`${API_URL}other-tasks/staff/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var permission = res.data.permission;
        if (permission) {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
              };
            });
        }
        else {
          var SearchType_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName,
                value: el._id,
              };
            });
        }
        setTaskAssigneeOptions([{ options: SearchType_data }]);
      });
  }
  const addNewTaskRow = () => {
    setTaskRows([...taskRows, {
      subcategory: null,
      task_name: "",
      task_tot_time: 0,
      task_start_date: "",
      task_end_date: "",
      expand: "hidden",
      flows: []
    }])
  }
  const showTask = (index) => {
    var tasks = [...taskRows];
    tasks[index].expand = "show";
    setTaskRows(tasks);
  }
  const hideTask = (index) => {
    var tasks = [...taskRows];
    tasks[index].expand = "hidden";
    setTaskRows(tasks);
  }
  const handleTaskTable = () => {
    axios
      .get(`${API_URL}other-tasks/`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        if (data.length == 0) {
          setTaskRows([{
            subcategory: null,
            task_name: "",
            expand: "hidden",
            flows: []
          }])
        }
        else {
          var tasks = [];
          data.map((item, index) => {
            item.subcategory = { label: item.subcat_name, value: item.task_subcategory_id };
            item.expand = "hidden";
            if (typeof item.flows === "undefined") {
              item.flows = [];
            }
            else {
              item.task_tot_time = 0;
              item.task_start_date = "";
              item.task_end_date = "";
              var start_date = [];
              var end_date = [];

              var total_assign = 0;
              // var status_priority = [];
              // var status_name = [];
              // var status_count = [];
              item.flows.map((subItem, index) => {

                total_assign++;
                // if(subItem.assign_current_status_name)
                // {
                //   if(status_priority.indexOf(subItem.assign_current_status_priority)>=0)
                //   {
                //     status_count[subItem.assign_current_status_priority]++;
                //   }
                //   else
                //   {
                //     status_count[subItem.assign_current_status_priority]=1;
                //   }
                //   status_priority.push(subItem.assign_current_status_priority);
                //   status_name.push(subItem.assign_current_status_name);
                // }
                if (subItem.assign_startdate)
                  start_date.push(subItem.assign_startdate);
                if (subItem.assign_enddate)
                  end_date.push(subItem.assign_enddate);
                // subItem.assign_time = subItem.assign_time ? subItem.assign_time : subItem.duration;
                subItem.assign_time = subItem.assign_time ? subItem.assign_time : 0;
                subItem.assign_time = parseFloat(subItem.assign_time);
                var assign_time = subItem.assign_time ? subItem.assign_time : 0;
                item.task_tot_time = item.task_tot_time + assign_time;
                var status_options = [];
                subItem.assignee = { label: subItem.firstName, value: subItem.staff_id };
                subItem.flow_status.map((statusItem) => {
                  if (!res.data.permission)
                    status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority });
                  else
                    status_options.push({ label: statusItem.type_name, value: statusItem._id, priority: statusItem.type_priority });
                })
                subItem.statusOption = status_options;
                subItem.currentstatus = { label: subItem?.assign_current_status_name, value: subItem?.assign_current_status };
              });
              start_date.sort();
              end_date.sort();
              console.log(end_date);
              item.task_start_date = start_date[0] ? start_date[0] : "";
              item.task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";

              // if(status_priority.length>0)
              // {
              //   var max_status = Math.max(...status_priority);
              //   var max_status_index = status_priority.indexOf(max_status);
              //   var max_status_name = status_name[max_status_index];
              //   item.task_max_status_priority = max_status;
              //   item.task_max_status_name = max_status_name;
              //   item.task_max_status_count = status_count[max_status];
              //   item.task_tot_assign = total_assign;
              //   item.task_status_label = item.task_max_status_count+"/"+total_assign+" "+max_status_name;
              // }
              // else
              // {
              //   item.task_tot_assign = total_assign;
              //   item.task_status_label = total_assign+"/"+total_assign+" Pending";
              // }

            }
            tasks.push(item);
          });
          console.log(tasks);
          setTaskRows(tasks);
        }

      });
  }
  const fetchTaskFlows = (task_id, category_id, index) => {
    axios
      .get(`${API_URL}other-tasks/getFlows/` + task_id + `/` + category_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tasks = [...taskRows];
        tasks[index].task_tot_time = 0;
        var start_date = [];
        var end_date = [];
        res.data.data.map((item, index1) => {
          start_date.push(item.assign_startdate);
          end_date.push(item.assign_enddate);
          item.assign_time = item.assign_time ? item.assign_time : item.duration;
          item.assign_time = parseFloat(item.assign_time);
          var assign_time = item.assign_time ? item.assign_time : 0;
          tasks[index].task_tot_time = tasks[index].task_tot_time + assign_time;
          item.assignee = { label: item?.firstName, value: item?.staff_id };
          var status_options = [];
          item.flow_status.map((statusItem) => {
            status_options.push({ label: statusItem.type_name, value: statusItem._id });
          })
          item.statusOption = status_options;
          item.currentstatus = { label: item?.assign_current_status_name, value: item?.assign_current_status, priority: item.type_priority };
        })
        start_date.sort();
        end_date.sort();
        tasks[index].task_start_date = start_date[0] ? start_date[0] : "";
        tasks[index].task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";
        tasks[index].flows = res.data.data;
        setTaskRows(tasks);
      });
  }
  const handleAddTask = (selected, index, item, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    if (type == "task_name") {
      var task_name = selected.target.value;
      tasks[index].task_name = task_name;
    }
    else {
      var category_id = selected.value;
      tasks[index].task_subcategory_id = category_id;
      tasks[index].subcategory = selected;
      tasks[index].flows = [];
    }
    setTaskRows(tasks);

    if (typeof item._id !== "undefined") {
      if (type == "task_name") {
        var parameters = { task_id: item._id, user_id: user_id, task_name: task_name }
        axios
          .put(`${API_URL}other-tasks/update_task`, parameters, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
          });
      }
      else {
        var parameters = { task_id: item._id, user_id: user_id, task_subcategory_id: category_id }
        axios
          .put(`${API_URL}other-tasks/update_task`, parameters, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var tasks = [...taskRows];
            tasks[index]._id = res.data.data._id;
            setTaskRows(tasks);
            fetchTaskFlows(tasks[index]._id, category_id, index)
          });
      }
    }
    else {
      if (item.subcategory) {
        if (type == "task_name") {
          var parameters = { user_id: user_id, task_name: task_name, task_subcategory_id: item.subcategory?.value }
          axios
            .post(`${API_URL}other-tasks/add_task`, parameters, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var tasks = [...taskRows];
              tasks[index]._id = res.data.data._id;
              setTaskRows(tasks);
            });
        }
        else {
          var parameters = { user_id: user_id, task_subcategory_id: category_id, task_name: item.task_name }
          axios
            .post(`${API_URL}other-tasks/add_task`, parameters, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var tasks = [...taskRows];
              tasks[index]._id = res.data.data._id;
              setTaskRows(tasks);
              fetchTaskFlows(res.data.data._id, category_id, index)
            });
        }

      }
    }

  }
  const handleAssignTask = (selected, index, index1, item, subItem, type) => {
    var token = localStorage.getItem("authUser");
    var user_obj = JSON.parse(token);
    var user_id = user_obj._id;

    var tasks = [...taskRows];
    var parameters = { task_id: item._id, user_id: user_id, task_subcategory_id: item.task_subcategory_id, task_flow_id: subItem.flow_id, assign_id: subItem?.assign_id };
    if (type == "staff") {
      var value = selected.value;
      tasks[index].flows[index1].staff_id = value;
      tasks[index].flows[index1].assignee = selected;
      parameters.staff_id = value;
      parameters.assign_time = tasks[index].flows[index1].assign_time;
    }
    else if (type == "time") {
      var value = selected.target.value;
      if (value != "") {

        var old_time = tasks[index].flows[index1].assign_time;
        if (typeof old_time === "undefined" || old_time == "") {
          old_time = 0;
        }
        var diff = parseFloat(value) - old_time;
        tasks[index].flows[index1].assign_time = value;
        tasks[index].task_tot_time = tasks[index].task_tot_time + parseFloat(diff);
      }
      else {
        var old_time = tasks[index].flows[index1].assign_time;
        tasks[index].task_tot_time = tasks[index].task_tot_time - parseFloat(old_time);
        tasks[index].flows[index1].assign_time = value;
      }

      parameters.assign_time = value;
    }
    else if (type == "start") {
      var value = selected.target.value;
      var end_date = tasks[index].flows[index1].assign_enddate;
      if (value > end_date) {
        tasks[index].flows[index1].assign_enddate = value;
        parameters.assign_enddate = value;
      }
      tasks[index].flows[index1].assign_startdate = value;
      if (tasks[index].task_start_date > value) {
        tasks[index].task_start_date = value;
      }
      else {
        var start = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_startdate)
            start.push(item.assign_startdate);
        });
        start.sort();
        tasks[index].task_start_date = start[0];
      }
      parameters.assign_startdate = value;
    }
    else if (type == "end") {
      var value = selected.target.value;
      tasks[index].flows[index1].assign_enddate = value;
      if (tasks[index].task_end_date < value) {
        tasks[index].task_end_date = value;
      }
      else {
        var end = [];
        tasks[index].flows.map((item, index) => {
          if (item.assign_enddate)
            end.push(item.assign_enddate);
        });
        end.sort();
        tasks[index].task_end_date = end[end.length - 1];
      }
      parameters.assign_enddate = value;
    }
    else if (type == "status") {
      var value = selected.value;
      tasks[index].flows[index1].assign_current_status = value;
      tasks[index].flows[index1].currentstatus = selected;
      parameters.assign_current_status = value;
    }
    parameters.flow_row = tasks[index].flows[index1];
    axios
      .post(`${API_URL}other-tasks/`, parameters, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var tasks = [...taskRows];
        tasks[index].flows[index1].assign_id = res.data.data._id;
        setTaskRows(tasks);
      });

    setTaskRows(tasks);
    console.log(tasks);
  }
  const handleShow = (item, index) => {

    setMsgObject({
      ...msgObject,
      //["assign_id"]: item._id,
      //["flow_id"]: item.task_flow_id,
      ["task_id"]: item._id,
      ["project_id"]: item.project_id,
      ["subcategory_id"]: item.task_subcategory_id,
    });
    axios
      .get(`${API_URL}taskcomment/list?task_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var data = response.data.data;
        setisProjectChat(response.data.non_project);
        setMessages(response.data.data);
        if (typeof response.data.project_info.assignees !== "undefined") {
          response.data.project_info.assignees.map((item, index) => {
            item.id = index + 1;
            if (typeof item.assign_time !== "undefined")
              item.assign_time1 = timeFromNumber(item.assign_time);
            else
              item.assign_time1 = "00:00";

            if (typeof item.assign_effort_hr !== "undefined")
              item.assign_effort_hr1 = item.assign_effort_hr.substring(0, item.assign_effort_hr.lastIndexOf(":"));
            else
              item.assign_effort_hr1 = "00:00";
            if (item.assign_effort_h1r == "")
              item.assign_effort_hr1 = "00:00";
          })

        }
        setChatboxData(response.data.project_info);
        setCanvasShow(true);
        scrollTop()
      });



  }
  const handleDeleteAssign = (id, index1, index) => {
    axios
      .post(`${API_URL}taskassign/delete_assign`, { id: id }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [...taskRows];
        list[index].flows[index1].assign_id = null;
        list[index].flows[index1].assign_startdate = "";
        list[index].flows[index1].assign_enddate = "";
        var start_date = [];
        var end_date = [];
        list[index].flows && list[index].flows.map((item, indexs) => {
          if (item?.assign_startdate && indexs != index1)
            start_date.push(item.assign_startdate);
          if (item?.assign_enddate && indexs != index1)
            end_date.push(item.assign_enddate);
        });
        start_date.sort();
        end_date.sort();
        console.log(start_date)
        list[index].task_start_date = start_date[0] ? start_date[0] : "";
        list[index].task_end_date = end_date[end_date.length - 1] ? end_date[end_date.length - 1] : "";
        setTaskRows(list);
        setConfirmDeleteAssignAlert(false);
        // handleStaffOptions();
        handleTaskTable();
        setAssignIdToBeDeleted({});
        toastr.success("Task deleted successfully");
      })
  }
  const chat_flow_count_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Task",
        field: "task",
        sort: "asc",
        width: 90,
      },
      {
        label: "Flow",
        field: "flow",
        sort: "asc",
        width: 90,
      },
      {
        label: "Assignee",
        field: "name",
        sort: "asc",
        width: 90,
      },
      {
        label: "Plan",
        field: "assign_time1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Effort",
        field: "assign_effort_hr1",
        sort: "asc",
        width: 90,
      },
    ],
    rows: chatboxData.assignees,
  };
  const uploadAttachment = (e) => {
    const fd = new FormData();
    for (var i = 0; i < e.target.files.length; i++) {
      fd.append("images", e.target.files[i]);
    }
    axios
      .post(`${API_URL}taskcomment/images/`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAttachCount(response.data.file.length);
          setMsgObject({
            ...msgObject,
            ["images"]: response.data.file,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  }
  const handleMessage = (e) => {
    setMsgObject({
      ...msgObject,
      ["comment"]: e.target.value,
    });
  }
  const handleSendMessage = () => {
    axios
      .post(`${API_URL}taskcomment/`, msgObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        var new_message = response.data.data;
        var messages_list = [...messages];
        messages_list.push(new_message);
        setMessages(messages_list);
        setMsgObject({
          ...msgObject,
          ["comment"]: "",
          ["images"]: []
        });
        setAttachCount("");
        scrollTop();
      });
  }
  function scrollTop() {
    const curr = bottomRef.current
    if (curr) {
      curr.scrollTop = 560 * 1000
    }
  }
  const handleDeleteTask = (id, index) => {
    axios
      .post(`${API_URL}taskassign/delete_task`, { id: id }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [...taskRows];
        list.splice(index, 1);
        setTaskRows(list);
        setConfirmDeleteTaskAlert(false);
        toastr.success("Task deleted successfully");
      })
  }
  const handleBlur = (selected, index, item, type) => {
    console.log(item)
  }
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserId(obj._id);
    }
    handleTaskTable();
    fetchTaskAssignee();
    fetchTaskCategory();
  }, []);
  return (
    <React.Fragment>
      {confirmDeleteAssignAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDeleteAssign(assignIdToBeDeleted?.aid, assignIdToBeDeleted?.index1, assignIdToBeDeleted?.index)
          }}
          onCancel={() => setConfirmDeleteAssignAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb breadcrumbItem="Other Tasks" />
          <Card>
            <CardBody>
              <div className="">
                <table class="table table-bordered" id="taskListTable">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th width="200px">Category</th>
                      <th width="300px">TaskName</th>
                      <th>Assignee</th>
                      <th width="60px">Time</th>
                      <th width="95px">Start</th>
                      <th width="95px">End</th>
                      {/* <th>Status</th> */}
                      <th width="55px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      taskRows.map((item, index) => (
                        <>
                          <tr class="shown">
                            <td class="">
                              <Row>
                                <Col md="1" style={{ cursor: "pointer", paddingTop: "5px", paddingLeft: "8px", minWidth: "12px" }}>
                                  <span >
                                    {
                                      (item.flows.length > 0) ? (
                                        <>
                                          {item.expand === "hidden" ? (
                                            <>
                                              <svg onClick={() => showTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" id="expandPoint">
                                                <path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                              </svg>
                                            </>
                                          ) : (
                                            <>
                                              <svg onClick={() => hideTask(index)} aria-hidden="true" style={{ height: "15px", width: "15px", }} focusable="false" data-prefix="fas" data-icon="sort-down" class="svg-inline--fa fa-sort-down fa-w-10 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="collapsePoint">
                                                <path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
                                              </svg>
                                            </>
                                          )}
                                        </>
                                      ) : ""
                                    }
                                  </span>
                                </Col>
                                <Col md="11" style={{ minWidth: "150px" }}>
                                  <Select
                                    menuPosition="fixed"
                                    name="subcategory"
                                    value={item.subcategory}
                                    onChange={(selected) => {
                                      handleAddTask(selected, index, item, 'category');
                                    }}
                                    options={taskCategoryOptions}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                              </Row>


                            </td>
                            <td class="" ><input type="text" name="task_name" class="form-control" value={item.task_name} onChange={(e) => handleAddTask(e, index, item, 'task_name')} onBlur={(e) => handleBlur(e, index, item, 'task_name')}></input></td>
                            <td class=""></td>
                            <td class=""><input type="text" name="task_tot_time" class="form-control" value={item?.task_tot_time} readOnly></input></td>
                            <td class=""><input type='date' name="task_start_date" class="form-control" value={item?.task_start_date} style={{ width: '115px' }} readOnly></input></td>
                            <td class=""><input type='date' name="task_end_date" class="form-control" value={item?.task_end_date} style={{ width: '115px' }} readOnly></input></td>
                            {/* <td class="">
                                 
                                </td> */}
                            <td style={{ textAlign: "center" }}>
                              {item._id ? (
                                <Row>
                                  <Col>
                                    {
                                      (item?.unread_comments > 0) ? (
                                        <div class="chat-icon">
                                          <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                          <span class="badge bg-danger rounded-pill">{item.unread_comments}</span>
                                        </div>
                                      ) : ((item?.total_comments > 0) ? (
                                        <i className="uil-comment-alt-lines" onClick={() => handleShow(item, index)}></i>
                                      ) : (
                                        <i className="uil-comment-alt" onClick={() => handleShow(item, index)}></i>
                                      ))
                                    }
                                  </Col>
                                  <Col>
                                    <i className="fas fa-trash"
                                      onClick={() => {
                                        setTaskIdToBeDeleted({ id: item._id, index: index });
                                        setConfirmDeleteTaskAlert(true);
                                      }}></i>
                                  </Col>
                                </Row>



                              ) : ""}

                            </td>
                          </tr>
                          {
                            item.flows && item.flows.map((subItem, index1) => (
                              <>
                                <tr class={item.expand}>
                                  <td class=""></td>
                                  <td class="" style={{ paddingLeft: "16px" }}>{subItem?.flow_name}</td>
                                  <td class="">
                                    <Select
                                      menuPosition="fixed"
                                      name="staff_id"
                                      placeholder=""
                                      value={subItem?.assignee}
                                      onChange={(selected) => {
                                        handleAssignTask(selected, index, index1, item, subItem, "staff");
                                      }}
                                      options={taskAssigneeOptions}
                                      classNamePrefix="select2-selection"
                                    />
                                  </td>
                                  <td class=""><input name="assign_time" class="form-control" value={subItem?.assign_time} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "time")}></input></td>
                                  <td class=""><input type='date' min={moment().format("YYYY-MM-DD")} name="assign_startdate" class="form-control" value={subItem?.assign_startdate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "start")} style={{ width: '115px' }}></input></td>
                                  <td class=""><input type='date' min={subItem?.assign_startdate ? subItem?.assign_startdate : moment().format("YYYY-MM-DD")} name="assign_enddate" class="form-control" value={subItem?.assign_enddate} onChange={(e) => handleAssignTask(e, index, index1, item, subItem, "end")} style={{ width: '115px' }}></input></td>
                                  {/* <td class="">
                                              <Select
                                                menuPosition="fixed"
                                                name="assign_current_status"
                                                placeholder=""
                                                value={subItem?.currentstatus}
                                                onChange={(selected) => {
                                                  handleAssignTask(selected,index,index1,item,subItem,"status");
                                                }}
                                                options={subItem.statusOption}
                                                classNamePrefix="select2-selection"
                                              />
                                              </td> */}
                                  <td>
                                    <i className="fas fa-trash"
                                      onClick={() => {
                                        setAssignIdToBeDeleted({ aid: subItem.assign_id, index1: index1, index: index });
                                        setConfirmDeleteAssignAlert(true);
                                      }}></i>
                                  </td>
                                </tr>
                              </>
                            ))
                          }

                        </>
                      )
                      )
                    }
                  </tbody>
                </table>
              </div>
              <button type="button"
                className="btn btn-primary"
                style={{ marginBottom: "8px" }}
                onClick={(e) => addNewTaskRow()}
              >Add Task</button>
            </CardBody>
          </Card>

        </div>
      </div>
      {confirmDeleteTaskAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleDeleteTask(taskIdToBeDeleted.id, taskIdToBeDeleted.index)
          }}
          onCancel={() => setConfirmDeleteTaskAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Offcanvas show={canvasshow} onHide={handleClose} placement={"end"} style={{ width: "1200px" }}>

        <Row>
          <Col md="7">
            {/* <Offcanvas.Header closeButton style={{ padding:"5px"}}>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header> */}
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>
                  {isProjectChat ? (
                    <>
                      <table
                        id="projectInfoTable"
                        width={"100%"}
                      >
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Project Name
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.project_name}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Shortcode
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.shortcode}
                          </td>
                        </tr>
                        {/* <tr>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                  Client
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  : {chatboxData?.clients}
                                </td>
                                <td style={{ padding: "10px", fontWeight: "500", }}>
                                Type
                                </td>
                                <td style={{ textAlign: "left" }}>
                                : {chatboxData?.types}
                                </td>
                              </tr> */}

                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Technologies
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.TechForTbl}
                          </td>
                          <td
                            style={{ padding: "10px", fontWeight: "500", }}
                          >
                            Domain
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <a
                              target="_blank"
                              href={chatboxData?.domain}
                              style={{ paddingLeft: "0%" }}
                            >
                              : {chatboxData?.domain}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Quotation No
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.quotation_no}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Invoice No
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.invoice_no}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Kick Off
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.kickoff}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            End
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.End}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Project Status
                          </td>
                          <td style={{ textAlign: "left" }}>:
                            <p
                              className="badge rounded-pill "
                              style={{
                                fontSize: "initial",
                                marginBottom: "auto",
                              }}
                            >{chatboxData?.ProjectStatus}
                            </p>
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Billing Status
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.bstatus}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Manager
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_manager?.firstName} {chatboxData.project_manager?.lastName}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Team Leader
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData.project_leader?.firstName} {chatboxData.project_leader?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Hours
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.hours}
                          </td>
                          <td style={{ padding: "10px", fontWeight: "500", }}>
                            Remark
                          </td>
                          <td style={{ textAlign: "left" }}>
                            : {chatboxData?.remarks}
                          </td>

                        </tr>
                      </table>
                    </>
                  ) : ""}
                  <MDBDataTable
                    id="flowCountTableIds"
                    responsive
                    bordered
                    data={chat_flow_count_data}
                    searching={false}
                    disableRetreatAfterSorting={true}
                    paging={true}
                    info={false}
                    entriesOptions={[5, 10]}
                  />

                </Offcanvas.Body>
              </CardBody>
            </Card>

          </Col>
          <Col md="5">
            <Card style={{ height: "100%" }}>
              <CardBody>
                <Offcanvas.Body>

                  <div className="chat-input-section">
                    <Row>
                      <div className="px-lg-2">
                        <div className="chat-conversation pt-2">
                          <ScrollBar style={{ height: "455px" }} className="list-unstyled mb-0" containerRef={el => (bottomRef.current = el)}>
                            {messages.map((message, index) => {
                              if (message.comment) {
                                return (
                                  <>
                                    <li key={"test_k" + index} className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                      <div className="conversation-list">
                                        <div className="ctext-wrap">
                                          <div className="ctext-wrap-content">
                                            <h5 className="font-size-14 conversation-name">
                                              <Link to="#" className="text-dark">{message.createdBy.firstName}</Link>
                                              <span className="d-inline-block font-size-12 text-muted ms-2">{moment(message.createdAt).format("DD-MM-YYYY hh:mm a")} - {timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}</span>

                                            </h5>
                                            <p className="mb-0 remarks-comment">{message.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split('.').pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>
                                      }
                                      else if (extension.toLowerCase() == "png" || extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg" || extension.toLowerCase() == "svg") {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />
                                      }
                                      else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>
                                      }
                                      else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>
                                      }
                                      else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>
                                      }
                                      return (
                                        <>
                                          <li key={"test_k" + index + "" + index1} className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">{message.createdBy.firstName}</Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">{timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}</span>

                                                  </h5>
                                                  <p className="mb-0"><a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                    {attchment}
                                                  </a></p>
                                                </div>


                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      )
                                    })}
                                  </>
                                )
                              }
                              else {
                                return (
                                  <>
                                    {message.images.map((attach, index1) => {
                                      var attchment = "";
                                      var extension = attach.split('.').pop();
                                      if (extension.toLowerCase() == "txt") {
                                        attchment = <i className="chat-fa-icon fas fa-file-alt text-dark"></i>
                                      }
                                      else if (extension.toLowerCase() == "png" || extension.toLowerCase() == "jpg" || extension.toLowerCase() == "jpeg" || extension.toLowerCase() == "svg") {
                                        attchment = <img src={`${API_URL}uploads/taskcomment/${attach}`} height="100" />
                                      }
                                      else if (extension.toLowerCase() == "pdf") {
                                        attchment = <i className="chat-fa-icon fas fa-file-pdf text-danger"></i>
                                      }
                                      else if (extension.toLowerCase() == "docx" || extension.toLowerCase() == "doc") {
                                        attchment = <i className="chat-fa-icon fas fa-file-word text-primary"></i>
                                      }
                                      else if (extension.toLowerCase() == "xlsx" || extension.toLowerCase() == "xls") {
                                        attchment = <i className="chat-fa-icon fas fa-file-excel text-success"></i>
                                      }
                                      return (
                                        <>
                                          <li key={"test_k" + index + "" + index1} className={message.createdBy._id.toString() == userId.toString() ? "right" : ""}>
                                            <div className="conversation-list">
                                              <div className="ctext-wrap">
                                                <div className="ctext-wrap-content">
                                                  <h5 className="font-size-14 conversation-name">
                                                    <Link to="#" className="text-dark">{message.createdBy.firstName}</Link>
                                                    <span className="d-inline-block font-size-12 text-muted ms-2">{timeSince(new Date(moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")), new Date())}</span>

                                                  </h5>
                                                  <p className="mb-0"><a href={`${API_URL}uploads/taskcomment/${attach}`} target="_blank" download>
                                                    {attchment}
                                                  </a></p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      )
                                    })}
                                  </>
                                )

                              }

                            })}
                          </ScrollBar>

                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative">
                          <Input
                            value={msgObject?.comment}
                            type="textarea"
                            id="comment"
                            name="comment"
                            className="form-control chat-input rounded"
                            onChange={(e) => handleMessage(e)}
                            rows="1"
                            placeholder="Enter Message..."
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div class="image-upload">
                          <label for="file-input">
                            {attachCount} <i class="fa fa-paperclip" aria-hidden="true"></i>
                          </label>
                          <input id="file-input" type="file" multiple onChange={uploadAttachment} />
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button type="submit" color="primary" onClick={handleSendMessage} className="chat-send waves-effect waves-light"><i className="mdi mdi-send float-end"></i></Button>
                      </div>
                    </Row>
                  </div>
                </Offcanvas.Body>
              </CardBody>
            </Card>
          </Col>
        </Row>


      </Offcanvas>
    </React.Fragment>
  )
}
export default OtherTasks